import {
  APIEndpoints,
  config,
  lookupSSOProvider,
  SSOProvider,
  unauthenticatedAssemblyAPI,
} from '@assembly-web/services';
import { AssemblyLoadingIndicator } from '@assembly-web/ui';
import { Suspense } from 'react';
import type { LoaderFunctionArgs } from 'react-router-dom';
import { Await, defer, Navigate, useLoaderData } from 'react-router-dom';

export function SSOAccountCreationRoute() {
  const data = useLoaderData() as ReturnType<typeof ssoAccountCreationLoader>;

  return (
    <Suspense fallback={<AssemblyLoadingIndicator />}>
      <Await
        resolve={
          /* @ts-expect-error react router can't give us proper types for this */
          data.targetURL
        }
      >
        {(targetURL) => <Navigate to={targetURL} />}
      </Await>
    </Suspense>
  );
}

export async function ssoAccountCreationLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  return defer({
    targetURL: (async function resolveSSOAccountCreationTargetURL() {
      const searchParams = new URL(request.url).searchParams;
      const code = searchParams.get('code');
      const error = searchParams.get('error');
      const provider = lookupSSOProvider(params.provider);

      if (error) {
        return `/create-account?error=${params.provider}_${error}`;
      }

      if (
        !code ||
        !provider ||
        provider === SSOProvider.ADP ||
        provider === SSOProvider.Lifion
      ) {
        return '/create-account';
      }

      const redirectUri = `${
        config.domains.app
      }/sso-account-creation/${provider.toLowerCase()}`;

      try {
        await unauthenticatedAssemblyAPI.post(
          APIEndpoints.verifyEmailWithSSO(provider, 'signup', false),
          { code, redirectUri }
        );

        return '/workspaces';
      } catch (error) {
        return `/create-account?error=account_creation_failed`;
      }
    })(),
  });
}
