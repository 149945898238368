import { defineMessages } from 'react-intl';

import {
  deleteFlowSummaryDrawer,
  getStoresForFlowSummaryDrawer,
} from '../../../stores/flowSummaryStore';
import type { DrawerConfig } from '../configuration';
import type {
  FlowSummaryData,
  FlowSummaryDrawer as FlowSummaryDrawerProps,
} from '../types';
import { FlowSummaryDrawer } from '.';
import { FlowSummaryBody } from './FlowSummaryBody';
import { FlowSummaryHeader } from './FlowSummaryHeader';
import { FlowSummaryOverflowCard } from './FlowSummaryOverflowCard';

const messages = defineMessages({
  cta: {
    defaultMessage: 'Close summary',
    id: 'g3ma+q',
  },
  description: {
    defaultMessage:
      "Once you close this, you'll have to start over to re-configure your summary. If you'd like to copy any of the content of the conversation, go back before closing the window.",
    id: 'ZqbHlB',
  },
  label: {
    defaultMessage: 'Close summary dialog',
    id: 'RNyf8Q',
  },
  title: {
    defaultMessage: 'Are you sure you want to close this summary?',
    id: '1sCWiS',
  },
});

export const flowSummaryConfig = {
  drawer: FlowSummaryDrawer,
  body: FlowSummaryBody,
  header: FlowSummaryHeader,
  overflowCard: FlowSummaryOverflowCard,
  allowMultipleInstance: true,
  shouldConfirmOnClose: () => true,
  confirmOnCloseCopy: {
    title: messages.title,
    description: messages.description,
    cta: messages.cta,
    label: messages.label,
  },
  onCollapse: (drawer) => {
    const { drawerStore } = getStoresForFlowSummaryDrawer(drawer);

    drawerStore.onOpenChange(false);
  },
  onClose: deleteFlowSummaryDrawer,
  onOpenChange: (drawer, isNewOpen) => {
    const { drawerStore } = getStoresForFlowSummaryDrawer(drawer);

    drawerStore.onOpenChange(isNewOpen);
  },
} satisfies DrawerConfig<FlowSummaryDrawerProps, FlowSummaryData>;
