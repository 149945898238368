import type { PlanFeaturesAPIResponse } from '@assembly-web/services';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getPlanFeaturesQuery } from '../queries/getPlanFeaturesQuery';

export function useGetPlanFeaturesQuery(
  options?: UseQueryOptions<PlanFeaturesAPIResponse>
): UseQueryResult<PlanFeaturesAPIResponse> {
  return useQuery(getPlanFeaturesQuery(options));
}
