import type {
  DoraReportingCategory,
  UserDetails,
} from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { DoraSingleMessage } from '../shared/dora/DoraSingleMessage';

const messages = defineMessages({
  milestonesAdmin: {
    defaultMessage:
      "Hi {name}! Feel free to ask me about upcoming birthdays and anniversaries for anyone at your company. I can share these upcoming milestones with you for specific manager's teams, departments, and locations. Let me know how I can assist you.",
    id: 'MPdOoO',
  },
  milestonesManager: {
    defaultMessage:
      "Hi {name}! Feel free to ask me about upcoming birthdays and anniversaries for your team. I can share these upcoming milestones with you for specific manager's teams, departments, and locations. Let me know how I can assist you.",
    id: 'pZVbtv',
  },
  participationAdmin: {
    defaultMessage:
      "It's flow time, {name}! As an admin, you can ask me about all flows in your Assembly workspace. This includes the creation of flows, posts, comments, and reactions. Filter by department, location, team, or hire date to understand how different employee groups are engaging. Whether you need to produce a downloadable CSV, create charts, or seek advice on visualizing data, I'm here to assist. Let me know what I can answer for you!",
    id: '9+3CFN',
  },
  participationManager: {
    defaultMessage:
      "It's flow time, {name}! With DoraAI, you can ask about participation rates in flows you've created, which includes actions by all participants in your flows (even outside your team). Plus, track your team's engagement across the platform in flows you can view. Need to generate reports, visualize trends, or download data? I'm here to help you understand and boost engagement effectively.",
    id: 'T5Kv6w',
  },
  recognitionManager: {
    defaultMessage:
      "Let's talk recognition, {name}! As a manager, you can access details about recognition posts where your team members are either the sender or recipient. Ask me for details on recognition given or received, and use this information to foster a more appreciative workplace culture. Let's help your team feel appreciated together!",
    id: '3/9Lmw',
  },
  recognitionAdmin: {
    defaultMessage:
      "Let's talk recognition, {name}! As an admin, you have complete visibility into recognition across your Assembly workspace. You can access details on who gave or received recognition, the number of posts, {points} given and received, and core values - this does not include {points} <i>redeemed</i> for rewards. Ask me to compare data by department, location, teams, or hire date. Feel free to ask me to generate reports, download data in CSV format, or create charts to visualize trends. Need ideas on data visualization? Just ask!",
    id: 'LFmnyL',
  },
  flowResponsesAdmin: {
    defaultMessage:
      'Hey {name}! Welcome to AI Reporting for flow responses on the {flowName} flow. This is a limited beta version for our trusted partners - please share any feedback or requests you have with the Assembly team! You can ask me questions or ask for analysis on the responses for this flow - both quantitative and qualitative. What can I analyze for you? ',
    id: 'HH5PRA',
  },
  rewardRedemptions: {
    defaultMessage:
      'See recognition come full circle with Redemptions, {name}! As an admin, you can see how recognition {points} are being converted into tangible rewards. Explore details such as who redeemed {points}, the dollar value of redemptions, and the types of rewards chosen. This can be broken down by department, location, or team. Feel free to ask me to generate reports, download data in CSV format, or create visuals to uncover redemption trends. Need help with visualizations or have specific questions? I’m here to help!',
    id: 'ml86en',
  },
});

export function IntroChatMessage(props: {
  isAdmin: boolean;
  reportingCategory: DoraReportingCategory;
  shouldAnimate: boolean;
  userDetails: UserDetails;
  drawerId: string;
}) {
  const { isAdmin, reportingCategory, shouldAnimate, userDetails, drawerId } =
    props;

  const name = userDetails.member.profile.firstName;
  const points = userDetails.assembly.currency.pluralName || 'points';

  const { formatMessage } = useIntl();

  let flowName = '';

  const findDrawer = useMultiDrawerStore((store) => store.findDrawer);

  if (reportingCategory === 'flow_responses') {
    const flowData = findDrawer(drawerId);

    if (flowData?.type === 'flow_responses') {
      flowName = flowData.data.flowTitle;
    }
  }

  let message: ReactNode;

  const t = (text: MessageDescriptor) =>
    formatMessage(text, {
      name,
      points,
      flowName,
      i: (text: ReactNode) => (
        <TextStyle as="span" variant="base-italic">
          {text}
        </TextStyle>
      ),
    });

  if (isAdmin) {
    if (reportingCategory === 'users') {
      message = t(messages.milestonesAdmin);
    } else if (reportingCategory === 'flows') {
      message = t(messages.participationAdmin);
    } else if (reportingCategory === 'reward_redemption') {
      message = t(messages.rewardRedemptions);
    } else if (reportingCategory === 'recognition') {
      message = t(messages.recognitionAdmin);
    } else {
      message = t(messages.flowResponsesAdmin);
    }
  } else {
    if (reportingCategory === 'users') {
      message = t(messages.milestonesManager);
    } else if (reportingCategory === 'flows') {
      message = t(messages.participationManager);
    } else if (reportingCategory === 'recognition') {
      message = t(messages.recognitionManager);
    } else {
      message = t(messages.flowResponsesAdmin);
    }
  }

  return (
    <DoraSingleMessage shouldAnimate={shouldAnimate}>
      {message}
    </DoraSingleMessage>
  );
}
