import { TextStyle } from '@assembly-web/ui';
import { Square2StackIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';

export function OverflowMenuHeader(props: { isMultiView?: boolean }) {
  const { isMultiView } = props;
  const overflowDrawers = useMultiDrawerStore((store) =>
    store.getOverflowDrawers()
  );

  const numAsText =
    overflowDrawers.length > 9 ? '9+' : overflowDrawers.length.toString();

  return (
    <div className="flex flex-row items-center py-0.5">
      <TextStyle
        as="span"
        className="flex flex-1 items-center truncate text-left text-gray-1"
        variant="xs-medium"
      >
        <Square2StackIcon className="h-4 w-4 text-gray-1" />
        <div className="mx-2 h-4 w-[1px] bg-gray-1" />
        {isMultiView ? (
          overflowDrawers.length > 1 ? (
            <FormattedMessage
              defaultMessage="<num>{number}</num> Items"
              id="jweBMn"
              values={{
                num: (text) => (
                  <span className="mr-1 flex h-4 w-4 items-center justify-center rounded-3xl bg-gray-8">
                    {text}
                  </span>
                ),
                number: numAsText,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="<num>{number}</num> Item"
              id="ynYNuR"
              values={{
                num: (text) => (
                  <span className="mr-1 flex h-4 w-4 items-center justify-center rounded-3xl bg-gray-8">
                    {text}
                  </span>
                ),
                number: numAsText,
              }}
            />
          )
        ) : overflowDrawers.length > 1 ? (
          <FormattedMessage
            defaultMessage="<num>{number}</num> More items"
            id="0qZq0f"
            values={{
              num: (text) => (
                <span className="mr-1 flex h-4 w-4 items-center justify-center rounded-3xl bg-gray-8">
                  {text}
                </span>
              ),
              number: numAsText,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="<num>{number}</num> More item"
            id="K8Azof"
            values={{
              num: (text) => (
                <span className="mr-1 flex h-4 w-4 items-center justify-center rounded-3xl bg-gray-8">
                  {text}
                </span>
              ),
              number: numAsText,
            }}
          />
        )}
      </TextStyle>
    </div>
  );
}
