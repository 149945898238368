import { memo } from 'react';

import { DefaultBlockRow } from '../../DefaultBlockRow';
import { Description } from '../../Description';
import { ContentLimit } from './ContentLimit';
import { FormattingOptions } from './FormattingOptions';

export const OpenEndedBlock = memo(function OpenEndedBlock() {
  return (
    <DefaultBlockRow>
      <Description />
      <ContentLimit />
      <FormattingOptions />
    </DefaultBlockRow>
  );
});
