import type { PostData } from '../../../../../types/postsAndReplies';
import type { DrawerConfig } from '../configuration';
import type { PostDrawerProps } from '../types';
import { PostDrawer } from '.';
import { PostDrawerBody } from './PostDrawerBody';
import { PostDrawerHeader } from './PostDrawerHeader';
import { PostDrawerOverflowCard } from './PostDrawerOverflowCard';

export const postsAndRepliesConfig = {
  drawer: PostDrawer,
  body: PostDrawerBody,
  header: PostDrawerHeader,
  overflowCard: PostDrawerOverflowCard,
  allowMultipleInstance: false,
  shouldConfirmOnClose: () => {
    return false; // todo: handle based on draft state
  },
  getId: (data) => {
    if (data.type === 'post') {
      return data.postId;
    }
    return `${data.flowId}-${data.responseId}`;
  },
  onClose: (props) => {
    if ('onClose' in props.data && props.data.onClose) {
      props.data.onClose();
    }
  },
  resetOnCreate: true,
} satisfies DrawerConfig<PostDrawerProps, PostData>;
