import { Heading } from '@assembly-web/ui';

import type { WorkspacesSectionProps } from '../../types/workspaces';
import { WorkspaceCard } from './WorkspaceCard';

export function WorkspacesSection(props: WorkspacesSectionProps) {
  return (
    <section className="space-y-2">
      <Heading as="h6">{props.heading}</Heading>

      {props.workspaces.map(function renderWorkspaceList(workspace) {
        return (
          <WorkspaceCard
            key={workspace.inviteToken ?? workspace.assemblyId}
            workspace={workspace}
            action={props.action}
            handleWorkspaceCardClick={props.handleWorkspaceCardClick}
          />
        );
      })}
    </section>
  );
}
