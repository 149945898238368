import { mapHexCodeToEmoticon } from '@assembly-web/services';
import { PostOverflowCard } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { useGetPostAndRepliesDetails } from '../../../../../hooks/useGetPostAndRepliesDetails';
import { defaultRecognitionFlowIcon } from '../../cards/utils';
import type { Drawer } from '../types';

const messages = defineMessages({
  postedBy: {
    id: 'ZqWRjX',
    defaultMessage: 'Post by {author} in {plainFlow}',
  },
});

export const PostDrawerOverflowCard = (
  props: Drawer & {
    onClick: () => void;
    onClose: () => void;
  }
) => {
  const { onClick, onClose } = props;
  const { formatMessage } = useIntl();
  const {
    flowResponse,
    postCardRepliesDetails,
    isPostCardDataLoading,
    isPostCardRepliesDataLoading,
  } = useGetPostAndRepliesDetails({
    flowId:
      'type' in props.data && props.data.type === 'flow'
        ? props.data.flowId
        : 'recognition',
    responseId:
      'type' in props.data && props.data.type === 'flow'
        ? props.data.responseId
        : 'postId' in props.data
          ? props.data.postId
          : '',
    enabled: true,
  });

  const title = formatMessage(messages.postedBy, {
    author: flowResponse?.respondent.name ?? '',
    plainFlow: `${mapHexCodeToEmoticon(
      flowResponse?.flow.icon.value ?? defaultRecognitionFlowIcon
    )} ${flowResponse?.flow.name}`,
  });

  return (
    <PostOverflowCard
      title={title}
      lastReplyTS={postCardRepliesDetails?.lastRepliedAt ?? ''}
      avatarProps={{
        name: flowResponse?.respondent.name ?? '',
        memberID: flowResponse?.respondent.memberID ?? '',
        image: flowResponse?.respondent.image,
      }}
      replyCount={postCardRepliesDetails?.count ?? 0}
      onClick={onClick}
      onClose={onClose}
      isLoading={isPostCardDataLoading || isPostCardRepliesDataLoading}
    />
  );
};
