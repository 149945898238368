import type { AnnouncementsInsightsViewersApiResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';

export type AnnouncementInsightViewersPayload = {
  searchValue: string;
  filterType: 'seen' | 'unseen';
  announcementId: string;
};

export function getAnnouncementInsightsViewersQuery(
  payload: AnnouncementInsightViewersPayload
): UseInfiniteQueryOptions<AnnouncementsInsightsViewersApiResponse> {
  return {
    enabled: Boolean(payload.announcementId),
    queryKey: [
      'getAnnouncementInsightsViewers',
      payload.announcementId,
      payload.searchValue,
      payload.filterType,
    ],
    queryFn: async ({ pageParam }) =>
      await assemblyAPI.post(
        APIEndpoints.getAnnouncementInsightsViewers(payload.announcementId),
        { filter: payload.filterType },
        {
          params: {
            ...(payload.searchValue ? { keyword: payload.searchValue } : {}),
            ...(pageParam ? { cursor: pageParam } : {}),
          },
        }
      ),
    getNextPageParam: (page) =>
      page.data.metadata?.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page) => {
      return page.data.metadata?.pagination.cursor.previous;
    },
  };
}
