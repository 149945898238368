import { classNames } from '@assembly-web/ui';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type IFrameModalProps = {
  url: string;
  title: string;
  onClose: () => void;
  className?: string;
};

export function IFrameModal(props: IFrameModalProps) {
  return (
    <Transition.Root show as={Fragment}>
      <Dialog
        as="div"
        className="pointer-events-auto relative z-20"
        onClose={props.onClose}
        data-modal
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-secondary bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  'relative h-[100dvh] transform overflow-hidden rounded-md bg-gray-1 text-left shadow-xl-down transition-all sm:w-screen',
                  props.className
                )}
              >
                <div className="h-full">
                  <div className="h-full text-center text-gray-9">
                    <iframe
                      src={props.url}
                      title={props.title}
                      className="h-full w-full border-none"
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
