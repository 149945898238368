import { errorCatImage } from '@assembly-web/assets';
import { Button, TextStyle } from '@assembly-web/ui';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  header: {
    defaultMessage: 'Whoops! Something went wrong.',
    id: 'XvKOkW',
  },
  refresh: {
    defaultMessage: 'Refresh',
    id: 'rELDbB',
  },
  resolution: {
    defaultMessage: 'Click the refresh button to try fetching tools again.',
    id: 'HyFiKE',
  },
});

export function ErrorLoadingConnections() {
  const { formatMessage } = useIntl();

  return (
    <section className="flex h-[calc(100vh-72px)] flex-col items-center justify-center">
      <img alt="" className="h-24 w-[100px]" src={errorCatImage} />
      <div className="mt-6 text-center">
        <TextStyle as="span" className="text-gray-9" variant="base-medium">
          {formatMessage(messages.header)}
        </TextStyle>
        <TextStyle className="mt-1.5 text-gray-9">
          {formatMessage(messages.resolution)}
        </TextStyle>
        <Button
          className="mt-4 w-64"
          onClick={() => window.location.reload()}
          variation="secondaryEmphasized"
        >
          <ArrowPathIcon className="h-4 w-4" />
          {formatMessage(messages.refresh)}
        </Button>
      </div>
    </section>
  );
}
