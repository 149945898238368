import { mapHexCodeToEmoticon } from '@assembly-web/services';

type TemplateCardProps = {
  title: string;
  creator: string;
  description: string;
  templateId: string;
  icon?: {
    kind: string;
    value: string;
  };
  onClick: (id: string) => void;
  buttonText: string;
};

export const TemplateCard = (props: TemplateCardProps) => {
  const { title, creator, description, templateId, icon, onClick, buttonText } =
    props;

  return (
    <button
      className="group relative block w-full rounded-lg border-[0.5px] border-gray-5 px-4 py-3 text-neutral-secondary shadow-base-down transition-[0.2s] hover:shadow-md-down focus:outline-none focus:outline-1 focus:ring-2 focus:ring-primary-6 focus:ring-offset-[0.5] active:translate-y-1 enabled:hover:bg-gray-2"
      key={templateId}
      onClick={() => onClick(templateId)}
      data-testid={`template-${templateId}`}
    >
      <div className="flex h-10">
        {icon ? (
          <div className="flex w-10 items-center justify-center rounded-md bg-primary-2">
            {mapHexCodeToEmoticon(icon.value)}
          </div>
        ) : null}
        <div className="flex flex-col pl-2 text-left">
          <div className="line-clamp-1 overflow-hidden text-ellipsis text-sm font-medium text-gray-9">
            {title}
          </div>
          <div className="text-xs text-gray-8">{creator}</div>
        </div>
      </div>
      <div className="line-clamp-2 min-h-[48px] overflow-hidden text-ellipsis pt-2 text-left text-sm text-gray-8">
        {description}
      </div>
      <div className="gray-1 absolute bottom-2 right-2 hidden rounded-lg bg-primary-6 px-3 py-2 text-sm text-gray-1 group-hover:block group-hover:shadow-md-down group-focus:block">
        {buttonText}
      </div>
    </button>
  );
};
