import { puzzlePieceIcon } from '@assembly-web/assets';
import type { MemberAPIResponse } from '@assembly-web/services';
import { useAssemblyNavigate } from '@assembly-web/ui';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { defineMessages, useIntl } from 'react-intl';
import { useLoaderData, useLocation } from 'react-router-dom';

import { HeaderBar } from '../../../components/HeaderBar';
import { NotFoundPage } from '../../../components/NotFoundPage';
import { TextButton } from '../../../components/TextButton';
import { Root } from '../../../Root';
import { OnboardingLayout } from '../../onboarding/components/OnboardingLayout';
import { AppConnections } from '../components/AppConnections';
import { ConnectionsSkeletonLoader } from '../components/ConnectionsSkeletonLoader';
import { EmptyConnections } from '../components/EmptyConnections';
import { ErrorLoadingConnections } from '../components/ErrorLoadingConnections';
import { DisconnectModalProvider } from '../contexts/DisconnectModalContext';
import { GoogleLimitationModalProvider } from '../contexts/GoogleLimitationModalContext';
import { usePusherForConnectionStatusUpdates } from '../hooks/usePusherForConnectionStatusUpdates';
import { getConnectionsQuery } from '../queries/getConnectionsQuery';
import { trackAppConnectionsShow } from '../services/analytics';

const messages = defineMessages({
  header: {
    defaultMessage: 'Manage my apps',
    id: 'elsrhw',
  },
  pageTitle: {
    defaultMessage: 'Manage Apps',
    id: 'GucfCU',
  },
});

export function ConnectionsRoute() {
  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();
  const { key } = useLocation();
  const isFirstPageInSession = key === 'default';

  const loaderData = useLoaderData() as
    | {
        userDetailsResponse: MemberAPIResponse;
      }
    | null
    | undefined;

  const {
    data: appConnections,
    isError: errorFetchingAppConnections,
    isFetching: isFetchingAppConnections,
  } = useQuery(getConnectionsQuery());

  const firedShowEvent = useRef(false);

  useEffect(() => {
    if (!firedShowEvent.current && !isFetchingAppConnections) {
      firedShowEvent.current = true;

      trackAppConnectionsShow({
        connectedAppName: (appConnections ?? []).map(
          (connection) => connection.integrationName
        ),
      });
    }
  }, [appConnections, isFetchingAppConnections]);

  usePusherForConnectionStatusUpdates();

  if (loaderData === null) {
    return (
      <Root>
        <OnboardingLayout>
          <NotFoundPage />
        </OnboardingLayout>
      </Root>
    );
  }

  let pageContent;

  if (errorFetchingAppConnections) {
    pageContent = <ErrorLoadingConnections />;
  } else {
    pageContent = isFetchingAppConnections ? (
      <ConnectionsSkeletonLoader />
    ) : (
      <section className="px-12 py-9">
        {!appConnections?.length ? (
          <EmptyConnections />
        ) : (
          <AppConnections connections={appConnections} />
        )}
      </section>
    );
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage(messages.pageTitle)}</title>
      </Helmet>
      <GoogleLimitationModalProvider>
        <DisconnectModalProvider>
          <HeaderBar
            showCompanyName
            logoSrc={puzzlePieceIcon}
            companyName={formatMessage(messages.header)}
          >
            <HeaderBar.RightContent>
              <TextButton
                className="ml-auto"
                onClick={() => {
                  if (isFirstPageInSession) {
                    navigate('/discover');
                  } else {
                    navigate(-1);
                  }
                }}
              >
                <XMarkIcon className="h-6 w-6 text-gray-8" aria-hidden="true" />
              </TextButton>
            </HeaderBar.RightContent>
          </HeaderBar>

          {pageContent}
        </DisconnectModalProvider>
      </GoogleLimitationModalProvider>
    </>
  );
}
