import { accessDrawer, type Drawer } from '../types';

export function DrawerHeader(initialProps: Drawer) {
  return accessDrawer(initialProps, initialProps.type, (config, props) => {
    const HeaderComponent = config.header;

    return (
      <div className="flex flex-row items-center">
        <HeaderComponent {...props} />
      </div>
    );
  });
}
