import { buildSafeUrl, config, userAuthStore } from '@assembly-web/services';
import { useAssemblyNavigate } from '@assembly-web/ui';
import { useEffect, useMemo } from 'react';

import { IFrameModal } from './IFrameModal';

export type FilePreviewModalProps = {
  flowId: string;
  blockId: string;
  fileName: string;
  responseId: string;
  workspaceSlugPath: string;
  onClose: () => void;
  onUrlCallback?: (param: string) => void;
};

export function FilePreviewModal(props: FilePreviewModalProps) {
  const navigate = useAssemblyNavigate();
  const {
    flowId,
    blockId,
    onClose,
    onUrlCallback,
    fileName,
    responseId,
    workspaceSlugPath,
  } = props;

  const url = useMemo(() => {
    const { isMobileAppV3, mobilePlatform, jwtToken } =
      userAuthStore.getState();

    const localDev =
      config.isMobileDevMode && userAuthStore.getState().isMobileAppV3;

    return buildSafeUrl(
      `${config.domains.legacyApp}/${workspaceSlugPath}/file-preview/${flowId}/${responseId}/${blockId}/${fileName}`,
      {
        source: 'embedded',
        ...(localDev && {
          mobileAuthToken: jwtToken,
        }),
        ...(mobilePlatform && {
          mobilePlatform,
          isMobileApp: 'true',
          isMobileAppV3,
        }),
      }
    );
  }, [blockId, fileName, flowId, responseId, workspaceSlugPath]);

  useEffect(() => {
    function handleMessage({ data: { type } }: MessageEvent) {
      if (type === 'FILE_PREVIEW_CLOSE') {
        onClose();
      }

      if (type === 'GO_TO_POST') {
        const url = `/${workspaceSlugPath}/post/flow/flows?flowId=${flowId}&responseId=${responseId}&showReplies=true&type=flow`;
        navigate(url);
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [flowId, navigate, onClose, responseId, workspaceSlugPath]);

  useEffect(() => {
    if (url && onUrlCallback) {
      onUrlCallback(url);
    }
  }, [onUrlCallback, url]);

  return (
    <IFrameModal
      url={url}
      onClose={onClose}
      title="File Preview"
      className="w-screen max-sm:rounded-none sm:my-8 md:h-[90vh] md:max-h-[672px] md:w-[90vw] md:max-w-7xl"
    />
  );
}
