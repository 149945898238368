import type { AnnouncementsInsightsViewersApiResponse } from '@assembly-web/services';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import type { AnnouncementInsightViewersPayload } from '../queries/getAnnouncementInsightsQuery';
import { getAnnouncementInsightsViewersQuery } from '../queries/getAnnouncementInsightsQuery';

export function useAnnouncementInsightsViewersQuery(
  payload: AnnouncementInsightViewersPayload
): UseInfiniteQueryResult<AnnouncementsInsightsViewersApiResponse> {
  return useInfiniteQuery(
    getAnnouncementInsightsViewersQuery({
      announcementId: payload.announcementId,
      searchValue: payload.searchValue,
      filterType: payload.filterType,
    })
  );
}
