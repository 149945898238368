import {
  type ChallengeDetailsAPIResponse,
  logger,
  MemberState,
  type ShareCriteria,
} from '@assembly-web/services';
import { useUserDetails } from '@assembly-web/services';
import {
  CHALLENGE_DEFINITION_MODAL_MODES as MODES,
  ChallengeDefinitionModal,
  getSchema,
  useToastStore,
} from '@assembly-web/ui';
import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { z } from 'zod';

import {
  type CreateChallengePayload,
  useCreateChallengeMutation,
} from '../../hooks/useCreateChallengeMutation';
import { useGetChallengeDetailsQuery } from '../../hooks/useGetChallengeDetailsQuery';
import { useUpdateChallengeMutation } from '../../hooks/useUpdateChallengeMutation';
import {
  type ChallengeAction,
  trackChallengeAction,
  trackChallengeError,
  trackChallengeShow,
  trackSharesheetAction,
} from '../../services/analytics';
import { ShareChallengeModal } from './ShareChallengeModal';

const messages = defineMessages({
  createAndLaunchSuccess: {
    defaultMessage: 'Challenge created and launched successfully',
    id: 'W8pJqz',
  },
  updateAndLaunchSuccess: {
    defaultMessage: 'Challenge updated and launched successfully',
    id: 'XWD+MY',
  },
  createAndSaveSuccess: {
    defaultMessage: 'Challenge created and saved for later',
    id: 'J3/Uco',
  },
  updateAndSaveSuccess: {
    defaultMessage: 'Challenge updated and saved for later',
    id: '9lLRZi',
  },
  createError: {
    defaultMessage: 'Error creating challenge. Please try later',
    id: 'WJSuy9',
  },
  updateError: {
    defaultMessage: 'Error updating challenge. Please try later',
    id: 'kguQ9k',
  },
  disabledTooltipText: {
    defaultMessage: 'You cannot edit challenges that have ended',
    id: 'EKkBWd',
  },
});

export type SharingRulesType = {
  sharingRules: ShareCriteria;
};

export function ChallengeDefinition({
  isOpen,
  onClose,
  challengeId,
  isDuplicate,
}: {
  isOpen: boolean;
  onClose: () => void;
  challengeId?: string;
  isDuplicate?: boolean;
}) {
  const { formatMessage } = useIntl();
  const { data: userDetails } = useUserDetails();
  const { showSuccessToast, showErrorToast } = useToastStore();

  const [sharingRulesPayload, setSharingRulesPayload] =
    useState<SharingRulesType>();
  const [rules, setRules] = useState<SharingRulesType>();
  const [owner, setOwner] = useState<
    | {
        memberID: string;
        memberState: MemberState;
      }
    | undefined
  >();

  const schema = getSchema(formatMessage);
  type FormFields = z.infer<typeof schema>;

  useEffect(() => {
    trackChallengeShow({
      modal: 'challengeCreation',
    });
  }, []);

  const { mutate: createChallenge, isLoading: isCreatingChallenge } =
    useCreateChallengeMutation({
      onSuccess: (payload) => {
        if (payload.launchNow) {
          showSuccessToast(formatMessage(messages.createAndLaunchSuccess));
        } else {
          showSuccessToast(formatMessage(messages.createAndSaveSuccess));
        }
        onClose();
      },
      onError: (err, payload) => {
        const errorInfo = err instanceof Error ? err : undefined;
        const action = payload.duplicateOf ? 'duplicating' : 'creating';
        showErrorToast(formatMessage(messages.createError));
        onClose();
        trackChallengeError({
          action: payload.launchNow
            ? 'challengeCreatedLaunched'
            : 'challengeCreatedSaved',
        });
        logger.error(`Error in ${action} challenge`, payload, errorInfo);
      },
    });

  const { mutate: updateChallenge, isLoading: isUpdatingChallenge } =
    useUpdateChallengeMutation({
      onSuccess: (payload) => {
        if (payload.launchNow) {
          showSuccessToast(formatMessage(messages.updateAndLaunchSuccess));
        } else {
          showSuccessToast(formatMessage(messages.updateAndSaveSuccess));
        }
        onClose();
      },
      onError: (err, payload) => {
        const errorInfo = err instanceof Error ? err : undefined;
        showErrorToast(formatMessage(messages.updateError));
        onClose();
        trackChallengeError({ action: 'challengeEditChangesSaved' });
        logger.error(`Error in editing challenge`, payload, errorInfo);
      },
    });

  const { data: challengeDetails, isFetching } = useGetChallengeDetailsQuery({
    id: challengeId ?? '',
    enabled: Boolean(challengeId),
  });

  const isChallengeClosed = challengeDetails?.state === 'CLOSED';

  useEffect(() => {
    if (challengeDetails && !isDuplicate) {
      setOwner({
        memberID: challengeDetails.createdBy.memberID,
        memberState: challengeDetails.createdBy.memberState,
      });
    } else if (userDetails) {
      setOwner({
        memberState: MemberState.Active,
        memberID: userDetails.member.memberId,
      });
    }
  }, [challengeDetails, isDuplicate, userDetails]);

  const getCreateChallengePayload = (formFields: FormFields) => {
    const payload: CreateChallengePayload = {
      totalClaimLimit: formFields.totalClaimLimit,
      individualClaimLimit: formFields.individualClaimLimit,
      image: formFields.image,
      imageUrl: formFields.imageUrl,
      points: Number(formFields.points),
      proof: {
        isRequired: formFields.proof.isRequired,
        content: [],
      },
      title: formFields.title,
      ...(formFields.description?.plainText && {
        description: {
          plainText: formFields.description.plainText,
          messageTokens: formFields.description.messageTokens,
          messageHtml: formFields.description.messageHtml,
        },
      }),
      claimButtonText: formFields.claimButtonText,
      notificationSettings: {
        channels: [],
      },
      launchNow: formFields.launchNow,
      interactionSettings: formFields.interactionSettings,
    };
    const formFieldsProof = [...formFields.proof.content];

    if (formFields.proof.isRequired) {
      payload.proof.content = formFieldsProof.reduce(function (
        filtered: {
          type: string;
          promptText: string;
        }[],
        option
      ) {
        if (option.isChecked) {
          delete option.isChecked;
          filtered.push(option);
        }
        return filtered;
      }, []);
    }

    if (formFields.notifyOnEmail) {
      payload.notificationSettings.channels.push('email');
    }
    if (formFields.notifyOnMobile) {
      payload.notificationSettings.channels.push('mobile');
    }
    if (sharingRulesPayload)
      payload.sharingRules = { ...sharingRulesPayload.sharingRules };
    if (owner && owner.memberState !== MemberState.Deactivated) {
      payload.createdBy = owner.memberID;
    }
    return payload;
  };

  const trackAction = (
    actionName: ChallengeAction,
    payload?: CreateChallengePayload
  ) => {
    const { criteriaSelected, roleSelected } = getCriteriaSelected(payload);
    const params = {
      challengeName: payload?.title,
      challengeId: challengeId,
      challengeRewardValue: payload?.points,
      challengeDescription: payload?.description?.messageHtml,
      challengeTotalClaimLimit: payload?.totalClaimLimit,
      challengeIndividualClaimLimit: payload?.individualClaimLimit,
      challengeFileProofRequired: false,
      challengeTextProofRequired:
        payload?.proof.isRequired && payload.proof.content[0].type === 'TEXT',
      criteriaSelected,
      roleSelected,
    };
    trackChallengeAction(actionName, params);
  };

  const getCriteriaSelected = (payload: CreateChallengePayload | undefined) => {
    const criteriaSelected: string[] = [];
    const roleSelected: string[] = [];
    payload?.sharingRules?.include.map((rule) => {
      if (rule.field === 'everyone') {
        criteriaSelected.push('everyone');
        roleSelected.push(rule.perm);
      }
      'values' in rule &&
        rule.values.map((val) => {
          criteriaSelected.push(rule.field + val.operator);
          roleSelected.push(owner?.memberID === val.value ? 'owner' : val.perm);
        });
    });
    return { criteriaSelected, roleSelected };
  };

  const handleSubmit = (formFields: unknown) => {
    const fields = schema.safeParse(formFields);
    if (fields.success) {
      const payload = getCreateChallengePayload(fields.data);
      if (
        payload.image?.location.startsWith(`https://`) &&
        challengeDetails?.image?.original
      ) {
        payload.image = {
          ...payload.image,
          ...challengeDetails.image.original,
        };
      }

      if (challengeId && !isDuplicate) {
        if (challengeDetails?.state === 'ACTIVE') {
          trackAction('challengeEditChangesSaved', payload);
        }

        updateChallenge({
          challengeId,
          payload,
        });
      } else {
        if (isDuplicate) {
          payload.duplicateOf = challengeId;
        }

        createChallenge(payload);
        if (payload.launchNow) {
          trackAction('challengeCreatedLaunched', payload);
        } else {
          trackAction('challengeCreatedSaved', payload);
        }
      }
    }
  };

  const updateSharingRules = useCallback((rules: SharingRulesType) => {
    setSharingRulesPayload(rules);
  }, []);

  const shareChallengeNode = () => {
    if (!userDetails || !owner) {
      return null;
    }

    if (challengeId && challengeDetails && !isDuplicate) {
      return (
        <ShareChallengeModal
          disabled={isChallengeClosed}
          {...(isChallengeClosed && {
            tooltipText: formatMessage(messages.disabledTooltipText),
          })}
          challengeId={challengeId}
          currentUserDetails={userDetails}
          updateSharingRulesPayload={updateSharingRules}
          editChallenge={Boolean(challengeId)}
          challengeOwner={owner}
          setChallengeOwner={setOwner}
          setChallengeSharingRules={setRules}
          sharingRules={rules?.sharingRules ?? challengeDetails.sharingRules}
        />
      );
    }

    return (
      <ShareChallengeModal
        currentUserDetails={userDetails}
        updateSharingRulesPayload={updateSharingRules}
        editChallenge={false}
        challengeOwner={owner}
        setChallengeOwner={setOwner}
        setChallengeSharingRules={setRules}
        sharingRules={rules?.sharingRules}
      />
    );
  };

  if (!userDetails || (challengeId && isFetching)) {
    return null;
  }

  const getFlowType = () => {
    // duplicate needs to be at the top as all kinds of challenges can be duplicated
    if (isDuplicate) return MODES.DUPLICATE;
    if (isChallengeClosed) return MODES.VIEW;
    if (challengeDetails?.state === 'ACTIVE') return MODES.EDIT_ACTIVE;
    if (challengeDetails?.state === 'QUEUED') return MODES.EDIT_QUEUED;
    return MODES.CREATE;
  };

  const formatChallengeDetails = (
    challengeDetails?: ChallengeDetailsAPIResponse
  ) => {
    if (!challengeDetails) return challengeDetails;
    return {
      ...challengeDetails,
      title: isDuplicate
        ? `Copy of ${challengeDetails.title}`
        : challengeDetails.title,
    };
  };

  return (
    <ChallengeDefinitionModal
      mode={getFlowType()}
      {...(isChallengeClosed && {
        tooltipText: formatMessage(messages.disabledTooltipText),
      })}
      challengeDetails={formatChallengeDetails(challengeDetails)}
      handleSubmit={handleSubmit}
      isOpen={isOpen}
      onCloseModal={() => {
        onClose();
        trackAction('challengeCreationExited');
      }}
      isSavingChallenge={isCreatingChallenge || isUpdatingChallenge}
      userDetails={userDetails}
      shareChallengeNode={shareChallengeNode()}
      onGetHelpClicked={() => {
        trackSharesheetAction({
          actionName: 'getHelpClicked',
          props: {
            ruleType: 'simple',
            isV3sharesheet: true,
            sharesheetType: 'challenge',
          },
        });
      }}
    />
  );
}
