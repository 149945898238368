import type {
  MemberState,
  Operator,
  PermissionType,
  ShareCriteria,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

export type MemberCriteriaAPIResponse = {
  meta: {
    email: string;
    image: string;
    memberId: string;
    name: string;
    state: MemberState;
  };
  operator: Operator;
  perm: PermissionType.Viewer | PermissionType.Collaborator;
  value: string;
};

export type NonMemberCriteriaAPIResponse = {
  operator: Operator;
  perm: PermissionType.Viewer | PermissionType.Collaborator;
  value: string;
};

export type ManagerCriteriaAPIResponse = {
  operator: Operator;
  perm: PermissionType.Viewer | PermissionType.Collaborator;
  value: boolean;
};

export type GetShareCollectionCriteriaAPIResponse = ShareCriteria & {
  shouldPin: boolean;
};

function getShareCollectionCriteria({
  collectionId,
  enabled,
}: {
  collectionId: string;
  enabled: boolean;
}): UseQueryOptions<GetShareCollectionCriteriaAPIResponse> {
  return {
    enabled: enabled && Boolean(collectionId),
    queryKey: ['getShareCollectionCriteria', collectionId],
    queryFn: async () => {
      const response =
        await assemblyAPI.get<GetShareCollectionCriteriaAPIResponse>(
          APIEndpoints.getCollectionShareCriteria(collectionId)
        );
      return response.data;
    },
  };
}

export function useGetShareCollectionCriteria({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) {
  return useQuery(
    getShareCollectionCriteria({
      collectionId: id,
      enabled,
    })
  );
}
