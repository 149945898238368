import { FlowIcon, TextStyle } from '@assembly-web/ui';
import { twMerge } from 'tailwind-merge';

import type { FlowCreationDrawer } from '../types';
import { useEditorDrawerHeaderTitle } from './hooks/useEditorDrawerHeaderTitle';

export const FlowCreationDrawerHeader = (props: FlowCreationDrawer) => {
  const { data } = props;
  const title = useEditorDrawerHeaderTitle(data);

  return (
    <>
      <div
        className={twMerge(
          'mr-2 rounded bg-gray-1 p-1',
          data.view === 'chat' &&
            'bg-gradient-to-r from-[#1d3fc8] via-purple-7 to-[#a309ca]'
        )}
      >
        <FlowIcon
          className={twMerge(
            'h-4 w-4 text-gray-9',
            data.view === 'chat' && 'text-gray-1'
          )}
        />
      </div>
      <TextStyle
        as="span"
        className="flex-1 truncate text-left text-[--foreground]"
        variant="xs-medium"
      >
        {title}
      </TextStyle>
    </>
  );
};
