import { withoutDefaultEventBehavior } from '@assembly-web/services';
import { TabGroupItem, TabsGroup, TextStyle } from '@assembly-web/ui';
import {
  BookmarkIcon,
  DocumentIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import { type ReactNode, useLayoutEffect, useRef } from 'react';
import {
  defineMessages,
  FormattedDate,
  FormattedTime,
  useIntl,
} from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextButton } from '../../../../../../components/TextButton';
import type { DoraChatThreadDetail } from '../../../../hooks/dora/useChatThreadsSummary';
import type { DoraSavedPrompt } from '../../../../hooks/dora/useSavedReports';
import { trackDoraAction } from '../../../../services/analytics';
import { useScrollPersistence } from '../../../../stores/useScrollPersistence';
import { TypingEffectText } from '../TypingEffectText';
import { ConversationCardIcon } from './ConversationCardIcon';

export enum ReportingCollectionTabs {
  SavedReports = 'saved-reports',
  History = 'history',
}

const messages = defineMessages({
  title: {
    defaultMessage: 'Saved prompts and History',
    id: 'A7ih6W',
  },
  upgradeCTA: {
    defaultMessage: 'Upgrade',
    id: '0h/lPM',
  },
  savedReportsTitle: {
    defaultMessage: 'Saved',
    id: 'fsB/4p',
  },
  historyTitle: {
    defaultMessage: 'History',
    id: 'djJp6c',
  },
  savedReportsHelpText: {
    defaultMessage: 'Ask DoraAI to answer your saved prompts with a click',
    id: '0FQ1tc',
  },
  historyHelpText: {
    defaultMessage: 'Review previous conversations',
    id: 't4V1jc',
  },
  upgradeTooltip: {
    defaultMessage: 'Upgrade to access this feature',
    id: 'Vq4tEW',
  },
  conversationCardDescription: {
    defaultMessage:
      '{messageCount, plural, one {<p>{messageCount, number} message</p>} other {<p>{messageCount, number} messages</p>}}<p>Last message {date} at {time}</p>',
    id: 'cY7qaU',
  },
});

export function ReportingConversationCard({
  isActive,
  isLimitedReportingExperience,
  thread,
  onConversationClick,
}: {
  isActive: boolean;
  isLimitedReportingExperience: boolean;
  thread: DoraChatThreadDetail;
  onConversationClick: (thread: DoraChatThreadDetail) => void;
}) {
  const { formatMessage } = useIntl();

  return (
    <button
      className={twMerge(
        'inline-flex gap-2 rounded-lg p-1 hover:bg-gray-4',
        isLimitedReportingExperience && 'cursor-not-allowed',
        isActive && 'bg-gray-4'
      )}
      disabled={isLimitedReportingExperience}
      onClick={withoutDefaultEventBehavior(() => onConversationClick(thread))}
      {...(isLimitedReportingExperience && {
        title: formatMessage(messages.upgradeTooltip),
      })}
    >
      <ConversationCardIcon reportType={thread.reportType} />
      <div className="flex flex-col text-start">
        <TextStyle variant="sm-bold">{thread.threadName}</TextStyle>
        <TextStyle variant="xs-regular" subdued>
          {formatMessage(messages.conversationCardDescription, {
            messageCount: thread.messageCount,
            date: (
              <FormattedDate
                value={thread.updatedAt}
                year="numeric"
                month="long"
                day="2-digit"
              />
            ),
            time: (
              <FormattedTime
                value={thread.updatedAt}
                hour="numeric"
                minute="numeric"
                timeZoneName="short"
                hour12
              />
            ),
            p: (node: ReactNode) => <p>{node}</p>,
          })}
        </TextStyle>
      </div>
    </button>
  );
}

export function SavedPromptCard({
  savedPrompt,
  onSavedPromptClick,
  isLimitedReportingExperience,
}: {
  isLimitedReportingExperience: boolean;
  savedPrompt: DoraSavedPrompt;
  onSavedPromptClick: (savedPrompt: DoraSavedPrompt) => void;
}) {
  const { formatMessage } = useIntl();

  return (
    <button
      className={twMerge(
        'inline-flex items-center gap-2 p-1 hover:bg-gray-3',
        isLimitedReportingExperience && 'cursor-not-allowed'
      )}
      disabled={isLimitedReportingExperience}
      onClick={withoutDefaultEventBehavior(() =>
        onSavedPromptClick(savedPrompt)
      )}
      {...(isLimitedReportingExperience && {
        title: formatMessage(messages.upgradeTooltip),
      })}
    >
      <ConversationCardIcon reportType={savedPrompt.reportType} />
      <div className="flex flex-col text-start">
        <TextStyle variant="sm-bold">
          <TypingEffectText text={savedPrompt.reportName} />
        </TextStyle>
      </div>
    </button>
  );
}

export function ReportingCollectionsContainer({
  children,
  activeTab,
  setActiveTab,
  isLoadedInSidebar,
  isLimitedReportingExperience,
  openUpgradeBanner,
}: {
  children: ReactNode;
  activeTab: ReportingCollectionTabs;
  setActiveTab: (tab: ReportingCollectionTabs) => void;
  isLoadedInSidebar: boolean;
  isLimitedReportingExperience: boolean;
  openUpgradeBanner: () => void;
}) {
  const { formatMessage } = useIntl();

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollPosition = useScrollPersistence(
    (state) => state.scrollPosition
  ).get('reportingCollections');

  const updateScrollPosition = useScrollPersistence(
    (state) => state.updateScrollPosition
  );

  useLayoutEffect(() => {
    if (scrollPosition?.top) {
      scrollRef.current?.scrollTo({
        top: scrollPosition.top,
        behavior: 'instant',
      });
    }
  });

  return (
    <div
      className={twMerge(
        'flex flex-col space-y-3 rounded-lg antialiased',
        isLoadedInSidebar
          ? 'max-h-[calc(100%-20px)]'
          : 'max-h-96 border border-gray-5 bg-gray-1 p-4',
        isLimitedReportingExperience && 'bg-gray-5'
      )}
    >
      <div className="flex justify-between gap-2">
        <div className="flex items-center gap-2">
          <div className="rounded-lg bg-gray-9 p-1">
            <DocumentIcon className="h-4 w-4 text-gray-1" />
          </div>
          <TextStyle variant={isLoadedInSidebar ? 'sm-bold' : 'base-bold'}>
            {formatMessage(messages.title)}
          </TextStyle>
        </div>
        {Boolean(isLimitedReportingExperience) && (
          <div className="flex gap-1">
            <TextButton
              onClick={() => {
                trackDoraAction('upgradeOnSavedSectionClicked');
                openUpgradeBanner();
              }}
            >
              <TextStyle variant="base-bold">
                {formatMessage(messages.upgradeCTA)}
              </TextStyle>
            </TextButton>
            <LockClosedIcon className="h-5 w-5" />
          </div>
        )}
      </div>

      <TabsGroup
        type="single"
        onValueChange={(tab) => setActiveTab(tab as ReportingCollectionTabs)}
        value={activeTab}
      >
        <TabGroupItem
          value={ReportingCollectionTabs.SavedReports}
          className={twMerge(
            isLoadedInSidebar && 'max-h-8 p-0.5 px-2 text-sm',
            'data-[state=off]:border-gray-1'
          )}
        >
          <div className="flex flex-row items-center gap-2">
            <BookmarkIcon className="h-4 w-4" />
            <span>{formatMessage(messages.savedReportsTitle)}</span>
          </div>
        </TabGroupItem>
        <TabGroupItem
          value={ReportingCollectionTabs.History}
          className={twMerge(
            isLoadedInSidebar && 'max-h-8 p-0.5 px-2 text-sm',
            'data-[state=off]:border-gray-1'
          )}
        >
          {formatMessage(messages.historyTitle)}
        </TabGroupItem>
      </TabsGroup>

      <TextStyle variant="xs-regular" subdued>
        {activeTab === ReportingCollectionTabs.SavedReports
          ? formatMessage(messages.savedReportsHelpText)
          : formatMessage(messages.historyHelpText)}
      </TextStyle>

      <div
        className="flex h-full flex-col overflow-y-auto"
        ref={scrollRef}
        onScroll={withoutDefaultEventBehavior((e) => {
          updateScrollPosition('reportingCollections', {
            top: e.currentTarget.scrollTop || 0,
          });
        })}
      >
        {children}
      </div>
    </div>
  );
}
