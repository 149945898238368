import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { timeouts } from '../../../../../shared/dora/constants';
import { DoraSingleMessage } from '../../../../../shared/dora/DoraSingleMessage';
import type { CustomFocusQuestionProps } from '../../../../types';

const messages = defineMessages({
  question: {
    defaultMessage: 'What would you like to focus on?',
    id: '4t9c2E',
  },
});

export const Message = (props: CustomFocusQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useCustomFocusSetting },
  } = props;

  const { formatMessage } = useIntl();
  const isOrigQuestionSeen = useCustomFocusSetting.getState().isQuestionSeen;
  const isOrigSet = useCustomFocusSetting((store) => store.isSet);

  const markOrigQuestionSeen = useCustomFocusSetting(
    (store) => store.markQuestionSeen
  );

  const isEditQuestionSeen =
    useEditSettingsStore.getState().customFocus.isQuestionSeen;

  const isEditingCurSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'customFocus'
  );

  const isEditingOtherSetting = useEditSettingsStore((store) =>
    store.isEditingTimePeriod()
  );

  const isSeen = isEditingCurSetting ? isEditQuestionSeen : isOrigQuestionSeen;
  const isEditedSet = useEditSettingsStore((store) => store.customFocus.isSet);

  const isSet =
    (isEditingCurSetting && isEditedSet) || (!isEditingCurSetting && isOrigSet);

  const markEditQuestionSeen = useEditSettingsStore(
    (store) => store.markQuestionSeen
  );

  const requestOrigInput = useCustomFocusSetting(
    (store) => store.setShouldRequestInput
  );

  const requestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  useEffect(() => {
    if (!isOrigQuestionSeen && !isEditingCurSetting) {
      markOrigQuestionSeen();
    } else if (!isEditQuestionSeen && isEditingCurSetting) {
      markEditQuestionSeen('customFocus');
    }
  }, [
    isEditQuestionSeen,
    isEditingCurSetting,
    isOrigQuestionSeen,
    markEditQuestionSeen,
    markOrigQuestionSeen,
  ]);

  useEffect(() => {
    const requestInput = isEditingCurSetting
      ? (shouldRequestInput: boolean) =>
          requestEdit('customFocus', shouldRequestInput)
      : requestOrigInput;

    if (!isSet) {
      setTimeout(() => {
        requestInput(true);
      }, timeouts.showInput);
    }
  }, [isSet, isEditingCurSetting, requestOrigInput, requestEdit]);

  return (
    <DoraSingleMessage isFaded={isEditingOtherSetting} shouldAnimate={!isSeen}>
      <TextStyle className="text-gray-9">
        {formatMessage(messages.question)}
      </TextStyle>
    </DoraSingleMessage>
  );
};
