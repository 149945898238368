import type { ImportantActivitiesByIdApiResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function getActivityByCardIdQuery(
  cardId: string
): UseQueryOptions<ImportantActivitiesByIdApiResponse> {
  return {
    queryKey: ['activityByCardId', cardId],
    queryFn: async () => {
      const { data } =
        await assemblyAPI.get<ImportantActivitiesByIdApiResponse>(
          APIEndpoints.getActivityByCardId(cardId)
        );
      return data;
    },
  };
}
