import type { ItemToAdd } from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { useGetCollectionsQuery } from '../../hooks/useGetCollectionsQuery';
import type { SearchPayload } from '../../hooks/useSearchIndex';
import { BaseCollectionModal } from './BaseCollectionModal';

const messages = defineMessages({
  header: {
    defaultMessage: 'Add to a collection',
    id: 'gYu4QI',
  },
  pinHeader: {
    defaultMessage: 'Add item and pin Collection',
    id: 'zC56JI',
  },
  content: {
    defaultMessage: 'Add to an existing collection or create a new one',
    id: '+RnRsS',
  },
  chooseCollection: {
    defaultMessage: 'Choose an existing collection',
    id: 'XczYAd',
  },
  addToCollection: {
    defaultMessage: 'Add to collection',
    id: 'ub1kHa',
  },
  adding: {
    defaultMessage: 'Adding to collection',
    id: 'VK+1HH',
  },
});

type AddToCollectionModalProps = {
  filter: GlobalFilterOption;
  itemToAdd: ItemToAdd | null;
  onClose: () => void;
  onCreateCollection: () => void;
  open: boolean;
  pinCollection: boolean;
  searchTerm: string;
  query?: SearchPayload;
};

export function AddToCollectionModal(props: AddToCollectionModalProps) {
  const {
    pinCollection,
    onCreateCollection,
    onClose,
    open,
    itemToAdd,
    filter,
    searchTerm,
    query,
  } = props;
  const { formatMessage } = useIntl();

  const { data: collections } = useGetCollectionsQuery({ enabled: open });

  return (
    <BaseCollectionModal
      onCreateCollection={onCreateCollection}
      onClose={onClose}
      open={open}
      title={formatMessage(
        pinCollection ? messages.pinHeader : messages.header
      )}
      bodyText={formatMessage(messages.content)}
      collectionLabel={formatMessage(messages.chooseCollection)}
      ctaText={formatMessage(messages.addToCollection)}
      ctaLoadingText={formatMessage(messages.adding)}
      filter={filter}
      itemToAdd={itemToAdd}
      searchTerm={searchTerm}
      collections={collections?.data}
      pinCollection={pinCollection}
      query={query}
    />
  );
}
