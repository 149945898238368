import { memo } from 'react';

import { DefaultBlockRow } from '../../DefaultBlockRow';
import { Description } from '../../Description';
import { Range } from './Range';
import { ScaleLabels } from './ScaleLabels';

export const NPS = memo(function NPS() {
  return (
    <DefaultBlockRow>
      <Description />
      <Range />
      <ScaleLabels />
    </DefaultBlockRow>
  );
});
