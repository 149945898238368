import type { MemberAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  getUserDetailsQuery,
} from '@assembly-web/services';
import { Button, RouterForm, TextField, validateForm } from '@assembly-web/ui';
import type { QueryClient } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useActionData,
  useLoaderData,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom';
import type { Primitive } from 'type-fest';
import { z } from 'zod';

import {
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../../../services/analytics';
import {
  generateReCaptchaToken,
  GoogleReCaptchaActionTypes,
} from '../../../../../services/recaptchaServices';
import type {
  ReactRouterActionResponse,
  ReactRouterLoaderResponse,
} from '../../../../../types/libs';
import { resetNavStore } from '../../../../discover/hooks/nav/useNavStore';
import { OnboardingContainer } from '../../../components/OnboardingContainer';
import {
  months,
  UserDetailsPageDateSelector,
} from '../../../components/UserDetailsPageDateSelector';
import type { joinWorkspaceSlugLoader } from '.';

const userDetailsFormText = defineMessages({
  title: {
    defaultMessage: 'Welcome to {name}',
    id: 'Af6DSw',
  },
  description: {
    defaultMessage:
      'Get ready to work smarter, not harder. Personalize your profile to get started.',
    id: 'Xn0Cih',
  },
  fullNameInputLabel: {
    defaultMessage: 'Your full name',
    id: 'm4c/De',
  },
  fullNameHelpText: {
    defaultMessage:
      'Your name will appear on all posts and messages you create',
    id: '8bGdCL',
  },
  fullNameInvalidText: {
    defaultMessage: 'Sorry, both a first and last name is required',
    id: 'XTXF1h',
  },
  displayNameInputLabel: {
    defaultMessage: 'Display name(optional)',
    id: '0xiw+A',
  },
  displayNameHelpText: {
    defaultMessage:
      'No spaces and only “-” or “.” can be used -- we will use your full name by default but you can personalize it if you’d like.',
    id: 's6xVoN',
  },
  birthDayInputLabel: {
    defaultMessage: 'Your birth day and month (optional)',
    id: 'vib4WJ',
  },
  birthDayInputHelpText: {
    defaultMessage:
      'Optional. Only for others to wish you happy birthday on your big day!',
    id: 'Oj6JSc',
  },
  anniversaryInputLabel: {
    defaultMessage: 'Your work anniversary (optional)',
    id: 'JEXQtm',
  },
  anniversaryInputHelpText: {
    defaultMessage:
      'Optional. Only for others to wish you happy anniversary on your big day!',
    id: 'Qe1Iw2',
  },
  submitButtonText: {
    defaultMessage: 'Done',
    id: 'JXdbo8',
  },
  nextButtonText: {
    defaultMessage: 'Next',
    id: '9+Ddtu',
  },
});

export function UserDetailsRoute() {
  const { formatMessage } = useIntl();

  const workspaceLoaderData = useRouteLoaderData('join-workspace-root') as
    | ReactRouterLoaderResponse<typeof joinWorkspaceSlugLoader>
    | null
    | undefined;

  const loaderData = useLoaderData() as MemberAPIResponse['member'];

  const navigation = useNavigation();

  const isPageLoading = navigation.state === 'loading';
  const isFormSubmissionInProgress = navigation.state === 'submitting';

  const actionData = useActionData() as ReactRouterActionResponse<
    typeof userDetailsAction
  >;

  return (
    <OnboardingContainer
      title={formatMessage(userDetailsFormText.title, {
        name: workspaceLoaderData?.name,
      })}
      description={formatMessage(userDetailsFormText.description)}
    >
      <RouterForm>
        <TextField
          name="fullName"
          label={formatMessage(userDetailsFormText.fullNameInputLabel)}
          helpText={formatMessage(userDetailsFormText.fullNameHelpText)}
          defaultValue={`${loaderData.profile.firstName} ${loaderData.profile.lastName}`.trim()}
          isInvalid={Boolean(actionData?.fullName)}
          invalidText={formatMessage(userDetailsFormText.fullNameInvalidText)}
          required
        />
        <TextField
          name="displayName"
          label={formatMessage(userDetailsFormText.displayNameInputLabel)}
          helpText={formatMessage(userDetailsFormText.displayNameHelpText)}
          defaultValue={loaderData.profile.username.replace(' ', '-')}
          isInvalid={Boolean(actionData?.displayName)}
          invalidText={formatMessage(userDetailsFormText.displayNameHelpText)}
        />

        <UserDetailsPageDateSelector
          label={formatMessage(userDetailsFormText.birthDayInputLabel)}
          namePrefix="birthday"
          isInvalid={Boolean(actionData?.birthday)}
          defaultValue={
            loaderData.profile.birthday.day
              ? loaderData.profile.birthday
              : undefined
          }
          helpText={formatMessage(userDetailsFormText.birthDayInputHelpText, {
            b: (text: ReactNode[]) => <span className="font-bold">{text}</span>,
          })}
        />

        <UserDetailsPageDateSelector
          label={formatMessage(userDetailsFormText.anniversaryInputLabel)}
          showYear
          namePrefix="anniversary"
          isInvalid={Boolean(actionData?.anniversary)}
          defaultValue={
            loaderData.profile.hiredday.day
              ? loaderData.profile.hiredday
              : undefined
          }
          helpText={formatMessage(
            userDetailsFormText.anniversaryInputHelpText,
            {
              b: (text: ReactNode[]) => (
                <span className="font-bold">{text}</span>
              ),
            }
          )}
        />

        <Button
          type="submit"
          isFullWidth
          isLoading={isFormSubmissionInProgress}
          disabled={isFormSubmissionInProgress || isPageLoading}
        >
          {formatMessage(userDetailsFormText.submitButtonText)}
        </Button>
      </RouterForm>
    </OnboardingContainer>
  );
}

export function userDetailsLoader(queryClient: QueryClient) {
  return async function loader() {
    try {
      const { member } = await queryClient.fetchQuery(getUserDetailsQuery());
      return member;
    } catch (error) {
      return redirect('/login?error=server_error');
    }
  };
}

function getValidationSchema() {
  return z.object({
    fullName: z
      .string()
      .refine((fullName) => fullName.trim().match(/^.*\s.*$/)),
    displayName: z
      .string()
      .refine((str) => str.trim().replace(/[^\W_]|[-]|[.]/g, '') === '', '')
      .optional(),
    birthday: z
      .string()
      .refine((str) => {
        const [month, day] = str.trim().split('-');

        return (month === '' && day === '') || (month !== '' && day !== '');
      })
      .optional(),
    anniversary: z
      .string()
      .refine((str) => {
        const [month, day, year] = str.trim().split('-');
        return (
          (month === '' && day === '' && year === '') ||
          (month !== '' && day !== '' && year !== '')
        );
      })
      .optional(),
  });
}

export async function userDetailsAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const formSchema = getValidationSchema();

  const captchaToken = await generateReCaptchaToken(
    GoogleReCaptchaActionTypes.SignUp
  );

  try {
    const { fullName, birthday, anniversary, displayName } = validateForm(
      formSchema,
      formData
    );

    trackRegistrationAction('assemblyMemberCreated', {
      profileDetails: { fullName, birthday, anniversary, displayName },
    });
    const data: Record<string, unknown> = { fullName, captchaToken };

    if (birthday) {
      const [month, date] = birthday.split('-');

      if (month && date) {
        data.birthday = {
          date: Number.parseInt(date),
          monthNumber: months.findIndex((x) => x === month) + 1,
        };
      }
    }

    if (anniversary) {
      const [month, date, year] = anniversary.split('-');

      if (month && date && year) {
        data.hiredday = {
          date: Number.parseInt(date),
          monthNumber: months.findIndex((x) => x === month) + 1,
          year: Number.parseInt(year),
        };
      }
    }

    if (displayName) {
      data.username = displayName;
    }

    await assemblyAPI.put(APIEndpoints.updateUserDetails, data, {
      signal: request.signal,
    });

    const redirectUrl = new URL(request.url).pathname.replace(
      'user-details',
      'success'
    );

    resetNavStore();
    return redirect(redirectUrl);
  } catch (error) {
    try {
      trackRegistrationError('assemblyMemberCreated', {
        profileDetails: JSON.parse(
          JSON.stringify(Object.fromEntries(formData))
        ) as Record<string, Primitive>,
      });
    } catch {
      // Nothing to do here
    }
    if (error instanceof z.ZodError) {
      const { fieldErrors } = error.flatten() as z.inferFlattenedErrors<
        typeof formSchema
      >;
      return fieldErrors;
    }
  }
}
