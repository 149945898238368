import { zustandLocalStorage } from '@assembly-web/services';
import dayjs from 'dayjs';
import type { Draft } from 'immer';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import {
  accessDrawer,
  type CreateDrawerPayload,
  type Drawer,
  type DrawerType,
} from '../modules/discover/components/Drawers/types';

const maxDrawerCount = 50;

type MemberMultiDrawerState = {
  drawers: Drawer[];
  overflowDrawers: Drawer[];
};

type MultiDrawerState = {
  currentMemberId: string;
  members: Record<string, MemberMultiDrawerState>;
  isMultiViewDrawer: boolean;
  isOverflowOpen: boolean;
  shouldDisplayMVContent: boolean;
  createDrawer: ({ data, title, type }: CreateDrawerPayload) => string; // returns drawer id
  setMemberId: (id: string) => void;
  openDrawer: (id: string) => void; // opens Drawer from drawer dock
  openOverflowDrawer: (id: string) => void; // opens Drawer from Overflow Menu
  moveOverflowItemToTop: (id: string) => void; // opens Drawer from Overflow Menu in MultiViewDrawer
  closeDrawer: (id: string) => void; // closes Drawer in drawer dock
  deleteDrawer: (id: string) => void; // removes Drawer from drawer dock
  deleteDrawerByType: (type: DrawerType) => void; // removes all Drawers of a passed in type
  deleteOverflowDrawer: (id: string) => void; // removes Drawer from Overflow Menu
  getDrawers: () => Drawer[];
  getOverflowDrawers: () => Drawer[];
  moveDrawerToOverflow: (moveAll?: boolean) => void;
  moveOldestDrawerOutOfOverflow: () => void;
  removeOverflowDrawers: () => Drawer[];
  setIsMultiViewDrawer: (isMultiViewDrawer: boolean) => void;
  setShouldDisplayMVContent: (shouldDisplayMVContent: boolean) => void;
  toggleIsOverflowOpen: () => void;
  runCleanUp: () => void;
  findDrawer: (id: string) => Drawer | undefined;
  findAndUpdateDrawerField: (
    id: string,
    update: (draft: Draft<Drawer>) => void
  ) => void;
  updateDraftInputValue: (drawerId: string, value: string) => void;
  updateDrawerField: (
    id: string,
    update: (draft: Draft<Drawer>) => void
  ) => void;
  updateOverflowDrawerField: (
    id: string,
    update: (draft: Draft<Drawer>) => void
  ) => void;
};

const getDrawerToMove = (drawers: Drawer[]) => {
  return drawers.reduce(
    (oldest, currDrawer, currIndex) => {
      if (currDrawer.lastInteractedTS < oldest.drawer.lastInteractedTS) {
        return { drawer: currDrawer, index: currIndex };
      } else {
        return oldest;
      }
    },
    { drawer: drawers[0], index: 0 }
  );
};

const defaultState = {
  currentMemberId: '',
  members: {},
  isMultiViewDrawer: false,
  isOverflowOpen: false,
  shouldDisplayMVContent: false,
};

export const useMultiDrawerStore = create<MultiDrawerState>()(
  persist(
    immer<MultiDrawerState>((set, get) => ({
      ...defaultState,

      setMemberId(id: string) {
        set((store) => {
          store.currentMemberId = id;
          if (!store.members.hasOwnProperty.call(store.members, id)) {
            store.members[id] = {
              drawers: [],
              overflowDrawers: [],
            };
          }
        });
      },

      createDrawer({ data, title, type }: CreateDrawerPayload): string {
        const timeNow = Date.now();
        let id = timeNow.toString();
        accessDrawer(type, data, (config, args) => {
          id = config.getId?.(args) ?? id;
        });
        const {
          isMultiViewDrawer,
          openDrawer,
          openOverflowDrawer,
          moveOverflowItemToTop,
          setShouldDisplayMVContent,
          members,
          currentMemberId,
        } = get();

        //TODO:  MemberState for un set member id can be undefined. Issue occurred in mobile app.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!members[currentMemberId]) {
          return id;
        }
        const { drawers, overflowDrawers } = members[currentMemberId];

        accessDrawer(type, data, (config) => {
          if (!config.allowMultipleInstance) {
            if (isMultiViewDrawer) {
              const drawerIndex = overflowDrawers.findIndex(
                (drawer) => drawer.id === id
              );
              if (drawerIndex > -1) {
                moveOverflowItemToTop(id);
                setShouldDisplayMVContent(true);
                set((store) => {
                  store.isOverflowOpen = true;
                  if (config.resetOnCreate) {
                    store.members[currentMemberId].overflowDrawers[0].data =
                      data;
                    store.members[currentMemberId].overflowDrawers[0].title =
                      title;
                  }
                });
                return id;
              }
            } else {
              const drawerIndex = drawers.findIndex(
                (drawer) => drawer.id === id
              );
              if (drawerIndex > -1) {
                if (config.resetOnCreate) {
                  set((store) => {
                    store.members[currentMemberId].drawers[drawerIndex].data =
                      data;
                    store.members[currentMemberId].drawers[drawerIndex].title =
                      title;
                  });
                }
                openDrawer(id);
                return id;
              }
              const overflowDrawerIndex = overflowDrawers.findIndex(
                (drawer) => drawer.id === id
              );
              if (overflowDrawerIndex > -1) {
                if (config.resetOnCreate) {
                  set((store) => {
                    store.members[currentMemberId].overflowDrawers[
                      overflowDrawerIndex
                    ].data = data;
                    store.members[currentMemberId].overflowDrawers[
                      overflowDrawerIndex
                    ].title = title;
                  });
                }
                openOverflowDrawer(id);
                return id;
              }
            }
          }
          set((store) => {
            if (isMultiViewDrawer) {
              store.isOverflowOpen = true;
              store.members[currentMemberId].overflowDrawers.unshift({
                data,
                id,
                title,
                type,
                lastInteractedTS: timeNow,
                isOpen: false,
              } as Drawer);
              store.shouldDisplayMVContent = true;
            } else {
              store.members[currentMemberId].drawers.unshift({
                data,
                id,
                title,
                type,
                lastInteractedTS: timeNow,
                isOpen: true,
              } as Drawer);
            }
            if (
              store.members[currentMemberId].drawers.length +
                store.members[currentMemberId].overflowDrawers.length >
              maxDrawerCount
            ) {
              const drawer = overflowDrawers[overflowDrawers.length - 1];
              accessDrawer(drawer, drawer.type, (config, props) => {
                const onClose = config.onClose;
                onClose?.(props);
              });
              store.members[currentMemberId].overflowDrawers.pop();
            }
          });
        });
        return id;
      },

      openDrawer(id: string) {
        const { currentMemberId } = get();
        set((store) => {
          const drawer = store.members[currentMemberId].drawers.find(
            (drawer) => drawer.id === id
          );
          if (drawer) {
            drawer.lastInteractedTS = Date.now();
            drawer.isOpen = true;
          }
        });
      },

      openOverflowDrawer(id: string) {
        const { currentMemberId } = get();

        set((store) => {
          const drawerIndex = store.members[
            currentMemberId
          ].overflowDrawers.findIndex((drawer) => drawer.id === id);
          if (drawerIndex > -1) {
            store.members[currentMemberId].drawers.unshift({
              ...store.members[currentMemberId].overflowDrawers[drawerIndex],
              lastInteractedTS: Date.now(),
              isOpen: true,
            });
            store.members[currentMemberId].overflowDrawers.splice(
              drawerIndex,
              1
            );
          }
        });
      },

      moveOverflowItemToTop(id: string) {
        const { currentMemberId } = get();

        set((store) => {
          const drawerIndex = store.members[
            currentMemberId
          ].overflowDrawers.findIndex((drawer) => drawer.id === id);
          if (drawerIndex > -1) {
            const [drawer] = store.members[
              currentMemberId
            ].overflowDrawers.splice(drawerIndex, 1);
            drawer.lastInteractedTS = Date.now();
            store.members[currentMemberId].overflowDrawers.unshift(drawer);
          }
        });
      },

      closeDrawer(id: string) {
        const { currentMemberId } = get();

        set((store) => {
          const drawer = store.members[currentMemberId].drawers.find(
            (drawer) => drawer.id === id
          );
          if (drawer) {
            drawer.isOpen = false;
          }
        });
      },

      deleteDrawer(id: string) {
        const { currentMemberId } = get();
        set((store) => {
          let { drawers } = store.members[currentMemberId];
          store.members[currentMemberId].drawers = drawers.filter(
            (drawer) => drawer.id !== id
          );
        });
      },

      deleteOverflowDrawer(id: string) {
        const { currentMemberId } = get();
        set((store) => {
          let { overflowDrawers } = store.members[currentMemberId];
          store.members[currentMemberId].overflowDrawers =
            overflowDrawers.filter((drawer) => drawer.id !== id);
          if (overflowDrawers.length === 0) {
            store.isOverflowOpen = false; // reset if overflow menu gets removed
          }
        });
      },

      deleteDrawerByType(type: DrawerType) {
        const { currentMemberId, deleteDrawer, deleteOverflowDrawer, members } =
          get();

        if (currentMemberId in members) {
          members[currentMemberId].drawers.forEach((drawer) => {
            accessDrawer(drawer, type, (config, props) => {
              const onClose = config.onClose;
              onClose?.(props);
              deleteDrawer(drawer.id);
            });
          });

          members[currentMemberId].overflowDrawers.forEach((drawer) => {
            accessDrawer(drawer, type, (config, props) => {
              const onClose = config.onClose;
              onClose?.(props);
              deleteOverflowDrawer(drawer.id);
            });
          });
        }
      },

      getDrawers() {
        const { currentMemberId, members } = get();
        if (!currentMemberId) {
          return [];
        }
        return members[currentMemberId].drawers;
      },

      getOverflowDrawers() {
        const { currentMemberId, members } = get();
        if (!currentMemberId) {
          return [];
        }
        return members[currentMemberId].overflowDrawers;
      },

      moveDrawerToOverflow(moveAll?: boolean) {
        const { currentMemberId } = get();
        set((store) => {
          let { drawers, overflowDrawers } = store.members[currentMemberId];
          if (moveAll) {
            store.members[currentMemberId].drawers.sort((a, b) => {
              return b.lastInteractedTS - a.lastInteractedTS;
            });
            store.members[currentMemberId].drawers.forEach((drawer) => {
              drawer.isOpen = false;
            });
            store.members[currentMemberId].overflowDrawers.unshift(...drawers);
            store.members[currentMemberId].drawers = [];
          } else {
            if (overflowDrawers.length === 0 && drawers.length > 1) {
              const drawerToMove = getDrawerToMove(drawers);
              store.members[currentMemberId].drawers.splice(
                drawerToMove.index,
                1
              );
              store.members[currentMemberId].overflowDrawers.unshift({
                ...drawerToMove.drawer,
                isOpen: false,
              });
            }

            const drawerToMove = getDrawerToMove(drawers);
            store.members[currentMemberId].drawers.splice(
              drawerToMove.index,
              1
            );
            store.members[currentMemberId].overflowDrawers.unshift({
              ...drawerToMove.drawer,
              isOpen: false,
            });
          }
        });
      },

      moveOldestDrawerOutOfOverflow() {
        const { currentMemberId } = get();
        set((store) => {
          let { overflowDrawers } = store.members[currentMemberId];

          if (overflowDrawers.length > 2) {
            const drawerToMove =
              store.members[currentMemberId].overflowDrawers.shift();
            if (drawerToMove) {
              store.members[currentMemberId].drawers.push(drawerToMove);
            }
          } else {
            store.members[currentMemberId].drawers.push(...overflowDrawers);
            store.members[currentMemberId].overflowDrawers = [];
            store.isOverflowOpen = false;
          }
        });
      },

      removeOverflowDrawers() {
        const { currentMemberId, members } = get();
        const drawersRemoved = members[currentMemberId].overflowDrawers;

        set((store) => {
          store.members[currentMemberId].overflowDrawers = [];
          store.isOverflowOpen = false;
        });

        return drawersRemoved;
      },

      setIsMultiViewDrawer(isMultiViewDrawer: boolean) {
        set((store) => {
          store.isMultiViewDrawer = isMultiViewDrawer;
        });
      },

      setShouldDisplayMVContent(shouldDisplayMVContent: boolean) {
        set((store) => {
          store.shouldDisplayMVContent = shouldDisplayMVContent;
        });
      },

      toggleIsOverflowOpen() {
        const {
          isMultiViewDrawer,
          isOverflowOpen,
          moveOldestDrawerOutOfOverflow,
          members,
          currentMemberId,
        } = get();
        const { overflowDrawers } = members[currentMemberId];
        if (
          !isMultiViewDrawer &&
          isOverflowOpen &&
          overflowDrawers.length === 1
        ) {
          moveOldestDrawerOutOfOverflow();
        } else {
          set((store) => {
            store.isOverflowOpen = !store.isOverflowOpen;
          });
        }
      },

      updateDrawerField(id, update) {
        const { currentMemberId, members } = get();

        const idx = members[currentMemberId].drawers.findIndex(
          (drawer) => drawer.id === id
        );
        if (idx < 0) {
          return;
        }

        set((store) => {
          const drawer = store.members[currentMemberId].drawers[idx];
          update(drawer);
        });
      },

      updateOverflowDrawerField(id, update) {
        const { currentMemberId, members } = get();

        const idx = members[currentMemberId].overflowDrawers.findIndex(
          (drawer) => drawer.id === id
        );
        if (idx < 0) {
          return;
        }

        set((store) => {
          const drawer = store.members[currentMemberId].overflowDrawers[idx];
          update(drawer);
        });
      },

      findDrawer(id) {
        const { currentMemberId, members } = get();

        const drawerIdx = members[currentMemberId].drawers.findIndex(
          (drawer) => drawer.id === id
        );
        const overflowDrawerIdx = members[
          currentMemberId
        ].overflowDrawers.findIndex((drawer) => drawer.id === id);

        if (drawerIdx < 0 && overflowDrawerIdx < 0) {
          return;
        }

        if (drawerIdx > -1) {
          return members[currentMemberId].drawers[drawerIdx];
        } else {
          return members[currentMemberId].overflowDrawers[overflowDrawerIdx];
        }
      },

      findAndUpdateDrawerField(id, update) {
        const { currentMemberId, members } = get();

        const drawerIdx = members[currentMemberId].drawers.findIndex(
          (drawer) => drawer.id === id
        );
        const overflowDrawerIdx = members[
          currentMemberId
        ].overflowDrawers.findIndex((drawer) => drawer.id === id);

        if (drawerIdx < 0 && overflowDrawerIdx < 0) {
          return;
        }

        if (drawerIdx > -1) {
          set((store) => {
            const drawer = store.members[currentMemberId].drawers[drawerIdx];
            update(drawer);
          });
        } else {
          set((store) => {
            const drawer =
              store.members[currentMemberId].overflowDrawers[overflowDrawerIdx];
            update(drawer);
          });
        }
      },

      updateDraftInputValue(id, value) {
        get().findAndUpdateDrawerField(id, (draft) => {
          draft.inputDraftValue = value;
        });
      },

      runCleanUp() {
        const cleanUp = () => {
          const timeNow = dayjs();
          const {
            deleteDrawer,
            deleteOverflowDrawer,
            members,
            currentMemberId,
          } = get();

          if (!currentMemberId) {
            return;
          }

          const { drawers, overflowDrawers } = members[currentMemberId];

          drawers.forEach((drawer) => {
            if (timeNow.diff(drawer.lastInteractedTS, 'd') > 30) {
              accessDrawer(drawer, drawer.type, (config, props) => {
                const onClose = config.onClose;
                onClose?.(props);
                deleteDrawer(drawer.id);
              });
            }
          });
          if (overflowDrawers.length > 0) {
            overflowDrawers.forEach((drawer) => {
              if (timeNow.diff(drawer.lastInteractedTS, 'd') > 30) {
                accessDrawer(drawer, drawer.type, (config, args) => {
                  const onClose = config.onClose;
                  onClose?.(args);
                  deleteOverflowDrawer(drawer.id);
                });
              }
            });
          }
        };

        cleanUp();
        setInterval(cleanUp, 86400000); // Run once per day thereafter
      },
    })),
    {
      name: 'multi-drawer-store',
      storage: createJSONStorage(() => zustandLocalStorage),
      version: 3,
      migrate: (state, version) => {
        if (version < 3) {
          try {
            const localStorageKeys = Object.keys(localStorage);
            localStorageKeys.forEach((key) => {
              if (key.startsWith('doraChat-')) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const data = JSON.parse(localStorage.getItem(key)!) as {
                  version: number;
                };
                if (data.version === 0) {
                  localStorage.removeItem(key);
                }
              }
            });
          } catch {
            //   noop
          }
          return defaultState;
        }
      },
    }
  )
);

useMultiDrawerStore.getState().runCleanUp();
