import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

import { ToolbarButton } from '../../../components/Drawers/shared/ToolbarButton';

const messages = defineMessages({
  label: {
    defaultMessage: 'More menu',
    id: 'd8kDH7',
  },
  tooltipText: {
    defaultMessage: 'More',
    id: 'I5NMJ8',
  },
});

export const MoreToolbarButton = ({ onClick }: { onClick?: () => void }) => {
  const { formatMessage } = useIntl();

  return (
    <ToolbarButton
      label={formatMessage(messages.label)}
      onClick={onClick}
      tooltipText={formatMessage(messages.tooltipText)}
    >
      <EllipsisHorizontalIcon className="h-4 w-4 text-gray-1 group-hover:text-gray-2 group-focus:text-gray-2" />
    </ToolbarButton>
  );
};
