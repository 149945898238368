import { AnimatePresence } from 'framer-motion';

import type { RespondentsGroupInputProps } from '../../../../types';
import { Selector } from './Selector';

export const RespondentsGroupInput = (props: RespondentsGroupInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useRespondentsGroupSetting },
  } = props;

  const shouldRequestInput = useRespondentsGroupSetting(
    (store) => store.shouldRequestInput
  );

  const shouldRequestEdit = useEditSettingsStore(
    (store) => store.respondentsGroup.shouldRequestInput
  );

  return (
    <AnimatePresence>
      {Boolean(shouldRequestInput || shouldRequestEdit) && (
        <Selector {...props} />
      )}
    </AnimatePresence>
  );
};
