import type {
  AdditionalInfoData,
  AnswerSourcesData,
  DateRange,
  DoraChatHistoryAPIResponse,
  DoraErrorServerSideEvent,
  FileType,
  Source,
  ToolCallFuncArgs,
  UpdateData,
} from '@assembly-web/services';
import { config, DoraAPIEndpoints, logger } from '@assembly-web/services';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';

import { useGetDoraChatHistoryQuery } from '../../../../hooks/dora/useChatHistoryQuery';
import { useGenerateSuggestedQuestionsMutation } from '../../../../hooks/useGenerateSuggestedQuestionsMutation';
import { useGetPlanFeaturesQuery } from '../../../../hooks/useGetPlanFeaturesQuery';
import { getDoraChatHistoryQueryKey } from '../../../../queries/getDoraChatHistoryQuery';
import { getSuggestedQuestionsQuery } from '../../../../queries/getSuggestedQuestionsQuery';
import { trackDiscoverAction } from '../../../../services/analytics';
import {
  convertMarkDownResponseToHTML,
  getIdForDoraQuestion,
  isDoraIncludedInWorkspacePlan,
  messages as sharedDoraMessages,
  removeBlinkingCursor,
  streamingTimeout,
} from '../../../../services/dora';
import type { MemberAskDoraBlockState } from '../../../../stores/useAskDoraStore';
import { useAskDoraStore } from '../../../../stores/useAskDoraStore';
import { markdownValuesMapper } from '../utils';

type FunctionCallData = {
  name: string;
  input: {
    ask: string;
    entityTypes: string[];
    fileTypes?: FileType[];
    dateRange?: DateRange;
  };
};

export type DoraEventSourceData = {
  question: string;
  errorMessage?: string;
  getDoraResponse: (question: string, metadata?: DoraResponseMetadata) => void;
  htmlResponse: string | null;
  isAnswerSeen: boolean;
  isDoraIncludedInPlan: boolean;
  isError: boolean;
  isErrorSeen: boolean;
  isLoading: boolean;
  isLoadingSeen: boolean;
  isStreaming: boolean;
  loadingMessage?: string;
  markdownResponse: string | undefined;
  promptId: string | null;
  reset: () => void;
  sources: Source[];
  staticResponse?: MessageDescriptor;
  suggestedQuestions?: string[];
  updateBlock: (updates: Partial<MemberAskDoraBlockState>) => void;
};

export type DoraResponseMetadata = {
  dateRange?: DateRange;
  documentId?: string;
  fileTypes?: FileType[];
  isAdmin?: boolean;
  refetchPromptId?: string;
  shouldUseStaticResponse?: boolean;
  suggestedQuestionActionType?:
    | 'followUpPromptClicked'
    | 'suggestedPromptClicked';
  suggestedQuestionsHistory?: string[];
  threadId?: string;
};

const messages = defineMessages({
  doraNotIncludedInPlan: {
    defaultMessage:
      'Want to get DORA powered answers? Upgrade your account to our Standard or Premium plans and unlock this feature to elevate your experience along with many others',
    id: '3F9v3o',
  },
  notUpgradedAdminStaticAnswer: {
    defaultMessage:
      "<p>Thanks for asking! I''m here to help you find answers faster. If your workspace is upgraded, you''ll be able to ask me specific questions about the <b>contents of files</b> uploaded to Assembly, or files you have permission to view in your connected tools like Google Drive or Dropbox. Here are some examples:</p><ul><li>Instead of reading the entire employee handbook to find out how to input time off, you can ask me, <quot>how do I log PTO?</quot></li><li>Or if you have a marketing project plan in a long document, you can ask, <quot>what is the expected delivery date for phase 3 of the ABC marketing project?</quot></li><li>Note: the more specific you can be, the better my answer will be! Over time, I''ll be able to answer more complex questions and give you even more helpful insights.</li></ul><p>I can also help you find files more easily – I''ll always give you the source documents for my answers. I''m always expanding my skills and improving. If you want access to ask me anything, upgrade below:</p>",
    id: 'ZfB3aD',
  },
  notUpgradedNonAdminStaticAnswer: {
    defaultMessage:
      "<p>Thanks for asking! I''m here to help you find answers faster. If your workspace is upgraded, you''ll be able to ask me specific questions about the <b>contents of files</b> uploaded to Assembly, or files you have permission to view in your connected tools like Google Drive or Dropbox. Here are some examples:</p><ul><li>Instead of reading the entire employee handbook to find out how to input time off, you can ask me, <quot>how do I log PTO?</quot></li><li>Or if you have a marketing project plan in a long document, you can ask, <quot>what is the expected delivery date for phase 3 of the ABC marketing project?</quot></li><li>Note: the more specific you can be, the better my answer will be! Over time, I''ll be able to answer more complex questions and give you even more helpful insights.</li></ul><p>I can also help you find files more easily – I''ll always give you the source documents for my answers. I''m always expanding my skills and improving. If you want access to ask me anything, ask your workspace administrator to upgrade your workspace!</p>",
    id: 'DZ2H2P',
  },
  upgradedStaticAnswer: {
    defaultMessage:
      "<p>Thanks for asking! I''m here to help you find answers faster. For now, you can ask me specific questions about the <b>contents of files</b> uploaded to Assembly, or files you have permission to view in your connected tools like Google Drive or Dropbox. If I don''t find an answer for you, it might be because I can''t access files with the answer! You can easily connect tools by clicking <b>connect another tool</b> below. Here are some examples:</p><ul><li>Instead of reading the entire employee handbook to find out how to input time off, you can ask me, <quot>how do I log PTO?</quot></li><li>Or if you have a marketing project plan in a long document, you can ask, <quot>what is the expected delivery date for phase 3 of the ABC marketing project?</quot></li><li>Note: the more specific you can be, the better my answer will be! Over time, I''ll be able to answer more complex questions and give you even more helpful insights.</li></ul><p>I can also help you find files more easily – I''ll always give you the source documents for my answers.</p><p>Please leave me a <quot>thumbs down</quot> whenever my answer isn''t helpful. I''m always expanding my skills and improving. Happy asking!</p>",
    id: 'VcJmQa',
  },
  analyzingYourQuestion: {
    defaultMessage: 'Analyzing your question',
    id: 'bIYkMs',
  },
  lookingInFiles: {
    defaultMessage: 'Looking in files',
    id: 'x1euu1',
  },
  lookingInFilesWithDateRange: {
    defaultMessage: 'Looking in files from {dateRange}',
    id: 'Qm3KD0',
  },
  lookingInFilesWithDateRangeBefore: {
    defaultMessage: 'Looking in files from before {dateRange}',
    id: '8LoGgo',
  },
  lookingInFilesWithDateRangeAfter: {
    defaultMessage: 'Looking in files from after {dateRange}',
    id: 'ycszLR',
  },
  lookingInFileTypes: {
    defaultMessage: `Looking in {fileTypes}`,
    id: 'fnJuPV',
  },
  lookingInFileTypesWithDateRange: {
    defaultMessage: 'Looking in {fileTypes} from {dateRange}',
    id: 'RwG/iu',
  },
  lookingInFileTypesWithDateRangeBefore: {
    defaultMessage: 'Looking in {fileTypes} from before {dateRange}',
    id: 'KHJ6d3',
  },
  lookingInFileTypesWithDateRangeAfter: {
    defaultMessage: 'Looking in {fileTypes} from after {dateRange}',
    id: 'pPArhy',
  },
});

export const QALimit = 10;

export function useDoraEventSource(): DoraEventSourceData {
  const { formatMessage, locale } = useIntl();
  const queryClient = useQueryClient();
  const questionAnswerBlock = useAskDoraStore((store) =>
    store.getCurrentBlockState()
  );

  const { data: markdownResponse, ...questionAnswerBlockData } =
    questionAnswerBlock;

  const { isStreaming } = questionAnswerBlockData;
  const setSuggestedQuestions = useAskDoraStore(
    (store) => store.setSuggestedQuestions
  );
  const threadId = useAskDoraStore((store) => store.getThreadId());
  const setThreadId = useAskDoraStore((store) => store.setThreadId);
  const updateBlock = useAskDoraStore((store) => store.updateBlock);
  const resetBlockState = useAskDoraStore((store) => store.resetBlockState);

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, [abortController]);

  const reset = useCallback(() => {
    abortController?.abort();
    updateBlock({
      data: undefined,
      errorMessage: undefined,
      isAnswerSeen: false,
      isError: false,
      isErrorSeen: false,
      isLoading: false,
      isStreaming: false,
      loadingMessage: undefined,
      promptId: null,
      sources: [],
      staticResponse: undefined,
    });
  }, [abortController, updateBlock]);

  const { mutateAsync: generateSuggestedQuestions } =
    useGenerateSuggestedQuestionsMutation();

  const showSuggestionsForResponseWithNoSources = useCallback(
    async (question: string) => {
      const { data: rawQuestions } = await queryClient.fetchQuery(
        getSuggestedQuestionsQuery(question, 4)
      );

      const questionId = getIdForDoraQuestion(question, { locale });

      const questions = rawQuestions.reduce<string[]>(
        (uniqueQuestions, rawData) => {
          const curQuestionId = getIdForDoraQuestion(rawData.question, {
            locale,
          });

          if (curQuestionId !== questionId && uniqueQuestions.length < 3) {
            uniqueQuestions.push(rawData.question);
          }

          return uniqueQuestions;
        },
        []
      );

      setSuggestedQuestions(questions);
    },
    [locale, queryClient, setSuggestedQuestions]
  );

  const { data: chatHistoryData } = useGetDoraChatHistoryQuery(threadId, {
    enabled: Boolean(threadId),
  });

  const questionAnswerBlocksLength = useMemo(
    () => chatHistoryData?.filter((item) => item.role === 'user').length ?? 0,
    [chatHistoryData]
  );

  const updateChatHistoryCache = useCallback(() => {
    const currentBlock = useAskDoraStore.getState().getCurrentBlockState();
    const threadId = useAskDoraStore.getState().getThreadId();

    if (!threadId) {
      return;
    }

    const queryKey = getDoraChatHistoryQueryKey(threadId);
    const chatHistory =
      queryClient.getQueryData<DoraChatHistoryAPIResponse>(queryKey) ?? [];
    const funcArgs: ToolCallFuncArgs = { query: currentBlock.question };
    if (currentBlock.dateRange) {
      funcArgs.dateRange = currentBlock.dateRange;
    }
    if (currentBlock.fileTypes?.length) {
      funcArgs.fileTypes = currentBlock.fileTypes;
    }

    const updatedChatHistory = [
      ...chatHistory,
      {
        role: 'user',
        content: currentBlock.question,
      },
      {
        role: 'assistant',
        content: currentBlock.data,
        answerSources: currentBlock.sources,
        searchedSources: currentBlock.searchedSources,
        createdAt: new Date().toISOString(),
        promptId: currentBlock.promptId,
        toolCalls: [
          {
            function: {
              name: 'ask_question',
              arguments: funcArgs,
            },
          },
        ],
      },
    ];

    queryClient.setQueryData(queryKey, updatedChatHistory);
    resetBlockState();
  }, [queryClient, resetBlockState]);

  const getlookingForLoadingMessage = useCallback(
    (fileTypes: string[] = [], dateRange?: DateRange) => {
      const firstDate = dateRange?.gte
        ? dayjs(dateRange.gte).format('MMMM YYYY')
        : null;
      const lastDate = dateRange?.lte
        ? dayjs(dateRange.lte).format('MMMM YYYY')
        : null;
      if (fileTypes.length === 0) {
        if (dateRange?.gte || dateRange?.lte) {
          if (firstDate && !lastDate) {
            return formatMessage(messages.lookingInFilesWithDateRangeAfter, {
              dateRange: firstDate,
            });
          }
          if (!firstDate && lastDate) {
            return formatMessage(messages.lookingInFilesWithDateRangeBefore, {
              dateRange: lastDate,
            });
          }
          if (firstDate === lastDate) {
            return formatMessage(messages.lookingInFilesWithDateRange, {
              dateRange: firstDate,
            });
          }
          return formatMessage(messages.lookingInFilesWithDateRange, {
            dateRange: `${firstDate} - ${lastDate}`,
          });
        } else {
          return formatMessage(messages.lookingInFiles);
        }
      } else if (fileTypes.length > 0) {
        // todo: handle plural localization better (https://joinassembly.atlassian.net/browse/APP-12137)
        const fileTypesPlural = fileTypes.map((type) => type + 's');
        const fileTypesString = new Intl.ListFormat(locale, {
          style: 'long',
          type: 'conjunction',
        }).format(fileTypesPlural);

        if (dateRange?.gte || dateRange?.lte) {
          if (firstDate && !lastDate) {
            return formatMessage(
              messages.lookingInFileTypesWithDateRangeAfter,
              {
                dateRange: firstDate,
                fileTypes: fileTypesString,
              }
            );
          }
          if (!firstDate && lastDate) {
            return formatMessage(
              messages.lookingInFileTypesWithDateRangeBefore,
              {
                dateRange: lastDate,
                fileTypes: fileTypesString,
              }
            );
          }
          if (firstDate === lastDate) {
            return formatMessage(messages.lookingInFileTypesWithDateRange, {
              dateRange: firstDate,
              fileTypes: fileTypesString,
            });
          }
          return formatMessage(messages.lookingInFileTypesWithDateRange, {
            dateRange: `${firstDate} - ${lastDate}`,
            fileTypes: fileTypesString,
          });
        } else {
          return formatMessage(messages.lookingInFileTypes, {
            fileTypes: fileTypesString,
          });
        }
      }
    },
    [formatMessage, locale]
  );

  const getEventResponse = useCallback(
    (question: string, metadata?: DoraResponseMetadata) => {
      let streamedData = '';
      let isAnswerSourceEventReceived = false;

      updateBlock({
        isLoading: true,
        loadingMessage: formatMessage(messages.analyzingYourQuestion),
        data: '',
        isError: false,
      });
      abortController?.abort();

      let params = new URLSearchParams();
      params.append('text', question);

      if (threadId) {
        params.append('threadId', threadId);
      }
      if (metadata?.refetchPromptId) {
        params.append('refetchPromptId', metadata.refetchPromptId);
      }
      if (metadata?.documentId) {
        params.append('documentId', metadata.documentId);
      }
      if (metadata?.fileTypes) {
        params.append('fileTypes', metadata.fileTypes.join(','));
      }
      if (metadata?.dateRange?.gte) {
        params.append('startDate', metadata.dateRange.gte);
      }
      if (metadata?.dateRange?.lte) {
        params.append('endDate', metadata.dateRange.lte);
      }

      const url = `${config.domains.doraApi}${
        DoraAPIEndpoints.chatDora
      }?${params.toString()}`;

      const controller = new AbortController();
      const signal = controller.signal;

      setAbortController(controller);

      const abortTimer = setTimeout(() => {
        logger.warn(
          `Timing out Dora request for GET ${DoraAPIEndpoints.chatDora}`,
          {
            question,
            timeout: streamingTimeout,
          }
        );

        controller.abort();
        updateBlock({
          isLoading: false,
          isError: true,
          isStreaming: false,
          loadingMessage: undefined,
        });
      }, streamingTimeout);

      fetchEventSource(url, {
        credentials: 'include',

        async onopen(resp) {
          clearTimeout(abortTimer);

          if (!resp.ok) {
            let responseBody;

            if (resp.headers.get('content-type') === 'application/json') {
              responseBody = await resp.json();
            } else {
              responseBody = await resp.text();
            }

            logger.error(
              `Dora request for GET ${DoraAPIEndpoints.chatDora} failed`,
              { question, responseBody, statusCode: resp.status, url: resp.url }
            );

            throw new Error('Dora Q/A');
          }
        },

        onerror(err) {
          updateBlock({
            isError: true,
            isLoading: false,
            isStreaming: false,
          });
          throw err;
        },

        async onmessage(msg) {
          if (msg.event === 'error') {
            const response = JSON.parse(msg.data) as DoraErrorServerSideEvent;

            logger.error(
              `Error event received from GET ${DoraAPIEndpoints.chatDora}`,
              { data: response, message: msg, question }
            );

            if (response.error === 'RATE_LIMIT_ERROR') {
              updateBlock({
                errorMessage: formatMessage(sharedDoraMessages.rateLimitError),
              });
            }

            updateBlock({
              isError: true,
              isLoading: false,
              isStreaming: false,
            });
          } else if (msg.event === 'function_call') {
            const response = JSON.parse(msg.data) as FunctionCallData;
            const fileTypes = response.input.fileTypes;
            const dateRange = response.input.dateRange;

            const loadingMessage = getlookingForLoadingMessage(
              fileTypes,
              dateRange
            );
            updateBlock({ loadingMessage, dateRange, fileTypes });
          } else if (msg.event === 'answer_sources') {
            const response = JSON.parse(msg.data) as AnswerSourcesData;
            const answerSources = response.data.answer_sources;
            const searchedSources = response.data.searched_sources;
            if (answerSources.length) {
              updateBlock({ sources: answerSources, searchedSources });
              isAnswerSourceEventReceived = true;
            } else {
              showSuggestionsForResponseWithNoSources(question);
            }
          } else if (msg.event === 'additional_info') {
            const response = JSON.parse(msg.data) as AdditionalInfoData;
            updateBlock({
              promptId: response.data.prompt_id,
            });
            setThreadId(response.data.thread_id);
            const hasCurrentSources =
              useAskDoraStore.getState().getCurrentBlockState().sources.length >
              0;
            if (questionAnswerBlocksLength < QALimit && hasCurrentSources) {
              const questions = await generateSuggestedQuestions({
                threadId: response.data.thread_id,
              });
              setSuggestedQuestions(questions);
            }
          } else if (msg.event === 'update') {
            const response = JSON.parse(msg.data) as UpdateData;
            streamedData = streamedData + response.data;

            updateBlock({
              data: streamedData ? streamedData : response.data,
              isLoading: false,
              loadingMessage: undefined,
              isStreaming: true,
            });
          } else if (msg.event === 'end') {
            abortController?.abort();

            updateBlock({
              isStreaming: false,
            });
            updateChatHistoryCache();
            trackDiscoverAction('answerGenerated', {
              answerText: streamedData,
            });

            if (metadata?.suggestedQuestionActionType) {
              trackDiscoverAction(metadata.suggestedQuestionActionType, {
                answerText: streamedData,
                searchQuery: question,
              });
            }

            if (!isAnswerSourceEventReceived) {
              showSuggestionsForResponseWithNoSources(question);
            }
          }
        },

        openWhenHidden: true,
        signal,
      });
    },
    [
      abortController,
      formatMessage,
      generateSuggestedQuestions,
      getlookingForLoadingMessage,
      questionAnswerBlocksLength,
      setSuggestedQuestions,
      setThreadId,
      showSuggestionsForResponseWithNoSources,
      threadId,
      updateBlock,
      updateChatHistoryCache,
    ]
  );

  const { data: planFeatureDetails } = useGetPlanFeaturesQuery();

  const isDoraIncludedInPlan =
    isDoraIncludedInWorkspacePlan(planFeatureDetails);

  const getDoraResponse = useCallback(
    (query: string, metadata?: DoraResponseMetadata) => {
      const { isAdmin = false, shouldUseStaticResponse = false } =
        metadata ?? {};

      if (shouldUseStaticResponse) {
        updateBlock({ isLoading: true });
        setTimeout(() => {
          const staticAnswer = isDoraIncludedInPlan
            ? messages.upgradedStaticAnswer
            : isAdmin
              ? messages.notUpgradedAdminStaticAnswer
              : messages.notUpgradedNonAdminStaticAnswer;

          const answerText = formatMessage(
            isDoraIncludedInPlan
              ? messages.upgradedStaticAnswer
              : isAdmin
                ? messages.notUpgradedAdminStaticAnswer
                : messages.notUpgradedNonAdminStaticAnswer,
            markdownValuesMapper
          );

          updateBlock({ isLoading: false, staticResponse: staticAnswer });

          trackDiscoverAction('whatCanDoraDoClicked', {
            answerText,
            searchQuery: query,
          });
        }, 2000);
      } else if (isDoraIncludedInPlan) {
        getEventResponse(query, metadata);
      } else {
        updateBlock({
          isLoading: false,
          data: formatMessage(messages.doraNotIncludedInPlan),
        });
      }
    },
    [formatMessage, getEventResponse, isDoraIncludedInPlan, updateBlock]
  );

  const htmlResponse = useMemo(() => {
    if (!markdownResponse) {
      return null;
    }

    const html = convertMarkDownResponseToHTML(markdownResponse) as string;

    if (!isStreaming) {
      return removeBlinkingCursor(html);
    }
    return html;
  }, [markdownResponse, isStreaming]);

  return {
    ...questionAnswerBlockData,
    htmlResponse,
    isDoraIncludedInPlan,
    getDoraResponse,
    markdownResponse,
    reset,
    updateBlock,
  };
}
