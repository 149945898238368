import { SVGReactComponents } from '@assembly-web/assets';
import { defineMessages, useIntl } from 'react-intl';

import { ToolbarButton } from '../shared/ToolbarButton';

const messages = defineMessages({
  label: {
    defaultMessage: 'Collapse drawer',
    id: '+ZMRPu',
  },
  tooltipText: {
    defaultMessage: 'Minimize',
    id: 'TuSL+Z',
  },
});

export const CollapseToolbarButton = ({
  onClick,
}: {
  onClick?: () => void;
}) => {
  const { formatMessage } = useIntl();

  return (
    <ToolbarButton
      label={formatMessage(messages.label)}
      onClick={onClick}
      tooltipText={formatMessage(messages.tooltipText)}
    >
      <SVGReactComponents.CollapseDrawerIcon className="h-4 w-4 text-[--foreground] group-hover:text-[--foreground-alt] group-focus:text-[--foreground-alt]" />
    </ToolbarButton>
  );
};
