import type { CollectionItemsAPIResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { arrayMoveMutable } from 'array-move';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { defineMessages, useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import type { NavItemsResponse } from './nav/useNavData';
import { navItemsQueryKey } from './nav/useNavData';

type Payload = {
  collectionId: string;
  itemId: string;
  beforeId?: string;
  afterId?: string;
  newLocationIndex: number;
};

const messages = defineMessages({
  failedToReorderItem: {
    defaultMessage: 'Failed to reorder item. Please try again later.',
    id: '3GJE7H',
  },
});

export function useCollectionItemReorderMutation() {
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastStore();
  const { formatMessage } = useIntl();

  return useMutation(
    async (payload: Payload) => {
      const { beforeId, afterId, collectionId, itemId } = payload;
      await assemblyAPI.put(
        APIEndpoints.reorderCollectionItems(collectionId, itemId),
        omitBy({ beforeId, afterId }, isNil)
      );
    },
    {
      onMutate: (payload: Payload) => {
        const collectionItemsQueryKey = [
          'collectionItems',
          payload.collectionId,
        ];
        const navCacheData =
          queryClient.getQueryData<NavItemsResponse>(navItemsQueryKey);

        if (navCacheData?.pages.length) {
          const pageIndexWithCollection = navCacheData.pages.findIndex((page) =>
            page.data.find((item) => item.id === payload.collectionId)
          );

          if (pageIndexWithCollection > -1) {
            const collections =
              navCacheData.pages[pageIndexWithCollection].data;

            const newCollectionData = cloneDeep(collections);
            const collection = newCollectionData.find(
              (item) => item.id === payload.collectionId
            );
            invariant(collection?.listItems, 'listItems should exist');

            arrayMoveMutable(
              collection.listItems,
              collection.listItems.findIndex(
                (item) => item.id === payload.itemId
              ),
              payload.newLocationIndex
            );

            queryClient.setQueryData(navItemsQueryKey, () => ({
              pages: navCacheData.pages.map((page, index) => ({
                ...page,
                data:
                  index === pageIndexWithCollection
                    ? newCollectionData
                    : page.data,
              })),
              pageParams: navCacheData.pageParams,
            }));
          }
        }

        const prevCollectionData =
          queryClient.getQueryData<CollectionItemsAPIResponse>(
            collectionItemsQueryKey
          );
        if (prevCollectionData?.data) {
          const newCollectionData = [...prevCollectionData.data];
          arrayMoveMutable(
            newCollectionData,
            newCollectionData.findIndex((item) => item.id === payload.itemId),
            payload.newLocationIndex
          );

          queryClient.setQueryData(collectionItemsQueryKey, {
            data: newCollectionData,
          });
        }
      },
      onError: (payload: Payload) => {
        showErrorToast(formatMessage(messages.failedToReorderItem));
        logger.error('Failed to reorder collection item.', payload);
      },
    }
  );
}
