import type {
  AxomoShippingCountryAPIResponse,
  AxomoShippingStateAPIResponse,
  AxomoStoreInfoAPIResponse,
  AxomoSwagAPIResponse,
} from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import {
  getAxomoShippingCountriesQuery,
  getAxomoShippingStatesQuery,
  getAxomoStoreInfo,
  getSwagQuery,
} from '../../queries/getSwagQuery';

export function useGetSwagDetailsQuery(swagId: string, storeId: string) {
  return useQuery<AxomoSwagAPIResponse>(getSwagQuery(swagId, storeId));
}

export function useAxomoShippingCountriesQuery() {
  return useQuery<AxomoShippingCountryAPIResponse[]>(
    getAxomoShippingCountriesQuery()
  );
}

export function useAxomoStateFromCountryCodeQuery(countryCode: string) {
  return useQuery<AxomoShippingStateAPIResponse>(
    getAxomoShippingStatesQuery(countryCode, Boolean(countryCode))
  );
}

export function useAxomoStoreInfoQuery(
  options?: UseQueryOptions<AxomoStoreInfoAPIResponse>
) {
  return useQuery<AxomoStoreInfoAPIResponse>(getAxomoStoreInfo(options));
}
