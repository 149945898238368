import './DiscoverRoot.css';

import {
  browserUtils,
  SplitNames,
  useFeatureSplit,
  useGoogleTranslate,
  userAuthStore,
  useUserDetails,
} from '@assembly-web/services';
import {
  AnnouncementCenterModal,
  FilePreviewerV3Provider,
} from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { Suspense, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { FilePreviewModalProvider } from '../../contexts/FilePreviewModalContext';
import { useOpenPostDrawer } from '../../hooks/useOpenPostDrawer';
import { usePostInteractionSettings } from '../../hooks/usePostInteractionSettings';
import { useMultiDrawerStore } from '../../stores/useMultiDrawerStore';
import { DiscoverPageHeader } from './components/DiscoverPageHeader';
import { DiscoverPageSkeletonLoader } from './components/DiscoverPageSkeletonLoader';
import { DrawerDock } from './components/Drawers/DrawerDock';
import { AnnouncementsInsights } from './components/modals/AnnouncementsInsights';
import { ChallengeModals } from './components/modals/ChallengeModals';
import { CollectionModals } from './components/modals/CollectionModals';
import { DeletePostOrReplyModal } from './components/modals/DeletePostOrReplyModal';
import { PostInteractionSettingsModal } from './components/modals/PostInteractionSettingsModal';
import { NavTrigger } from './components/Nav/NavTrigger';
import { useNavTrigger } from './components/Nav/useNavTrigger';
import { AskDoraProvider } from './contexts/AskDoraContext';
import { ChallengeModalsProvider } from './contexts/ChallengeModalsContext';
import { CollectionModalsProvider } from './contexts/CollectionModalsContext';
import { FlowStatusProvider } from './contexts/FlowStatusContext';
import { ModalsProvider } from './contexts/ModalsContext';
import { useGlobalDomainEvents } from './hooks/domainEvents/useGlobalDomainEvents';
import { useNotificationPreferenceUpdatedEvents } from './hooks/domainEvents/useNotificationPreferenceUpdatedEvents';
import { usePostInteractionSettingsUpdatesEvents } from './hooks/domainEvents/usePostInteractionSettingsUpdatesEvents';
import { AnnouncementsContext } from './hooks/useAnnouncements';
import { useGlobalFilter } from './hooks/useGlobalFilter';
import { useAskDoraStore } from './stores/useAskDoraStore';

export function DiscoverRoot({ children }: { children: ReactNode }) {
  useGoogleTranslate();

  const { pathname } = useLocation();

  const filter = useGlobalFilter();
  useGlobalDomainEvents();

  const { data: userDetails } = useUserDetails();

  const { isTreatmentActive: multiPopoutDrawerEnabled } = useFeatureSplit(
    SplitNames.MultiPopoutDrawer
  );

  const setMemberId = useMultiDrawerStore((store) => store.setMemberId);
  const setMemberState = useAskDoraStore((store) => store.setMemberState);

  const isDiscoverPage = pathname.includes('/discover');

  useOpenPostDrawer();

  const { isBottomNav } = useNavTrigger();

  const { isTreatmentActive: isMSTeamsAppSubmissionEnabled } = useFeatureSplit(
    SplitNames.MSTeamsAppSubmission
  );

  const showNavForMSTeams = userAuthStore.getState().msTeamsContext
    ? !isMSTeamsAppSubmissionEnabled
    : true;

  const shouldDisplayNavTrigger =
    isDiscoverPage &&
    !browserUtils.isMobile &&
    !isBottomNav &&
    showNavForMSTeams;

  useEffect(() => {
    const recaptchaBadge = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaBadge.length) {
      recaptchaBadge[0].remove();
    }
  }, []);

  const {
    responseDetails,
    postInteractionSettings,
    isPostInteractionSettingsModalOpen,
    handlePostInteractionSettingsChange,
    handleClosePostInteractionSettingsModal,
  } = usePostInteractionSettings();

  const {
    isSaving,
    closeAnnouncementModal,
    announcementFormValues,
    disableResponseSettings,
    isAnnouncementsModalOpen,
    handleOnGetHelpButtonClick,
    handleOnSaveAnnouncementClick,
    selectedAnnouncementId,
    closeAnnouncementsInsightsModal,
  } = useContext(AnnouncementsContext);

  usePostInteractionSettingsUpdatesEvents();
  useNotificationPreferenceUpdatedEvents();

  useEffect(() => {
    if (userDetails?.member.memberId) {
      setMemberId(userDetails.member.memberId);
      setMemberState(userDetails.member.memberId);
    }
  }, [setMemberState, setMemberId, userDetails?.member.memberId]);

  useEffect(() => {
    return () => sessionStorage.removeItem('expandedCollections');
  }, []);

  useEffect(() => {
    window.parent.postMessage({ type: 'webAppFABInitialized' }, '*');
  }, []);

  return (
    <Suspense fallback={<DiscoverPageSkeletonLoader />}>
      <AskDoraProvider>
        <ModalsProvider>
          <CollectionModalsProvider>
            <ChallengeModalsProvider>
              <FlowStatusProvider>
                <FilePreviewerV3Provider>
                  <FilePreviewModalProvider>
                    <DiscoverPageHeader filter={filter} />

                    {Boolean(shouldDisplayNavTrigger) && <NavTrigger />}

                    {Boolean(multiPopoutDrawerEnabled) && <DrawerDock />}

                    <PostInteractionSettingsModal
                      responseDetails={responseDetails}
                      isOpen={isPostInteractionSettingsModalOpen}
                      postInteractionSettings={postInteractionSettings}
                      onClose={handleClosePostInteractionSettingsModal}
                      handlePostInteractionSettingsChange={
                        handlePostInteractionSettingsChange
                      }
                    />
                    <AnnouncementsInsights
                      announcementId={selectedAnnouncementId}
                      onClose={closeAnnouncementsInsightsModal}
                    />
                    {isAnnouncementsModalOpen && userDetails ? (
                      <AnnouncementCenterModal
                        isSavingAnnouncement={isSaving}
                        onClose={closeAnnouncementModal}
                        isOpen={isAnnouncementsModalOpen}
                        onSave={handleOnSaveAnnouncementClick}
                        memberID={userDetails.member.memberId}
                        announcementFormValues={announcementFormValues}
                        onGetHelpButtonClick={handleOnGetHelpButtonClick}
                        disableResponseSettings={disableResponseSettings}
                        memberFullName={userDetails.member.profile.fullName}
                        imageUrl={userDetails.member.profile.image}
                      />
                    ) : null}
                    {Boolean(userDetails) && (
                      <>
                        <CollectionModals />
                        <ChallengeModals />
                        <DeletePostOrReplyModal />
                      </>
                    )}
                    {children}
                  </FilePreviewModalProvider>
                </FilePreviewerV3Provider>
              </FlowStatusProvider>
            </ChallengeModalsProvider>
          </CollectionModalsProvider>
        </ModalsProvider>
      </AskDoraProvider>
    </Suspense>
  );
}
