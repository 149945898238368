import type { GlobalFilterOption } from '@assembly-web/ui';
import { Button, ConfirmationModal, LoadingSpinner } from '@assembly-web/ui';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useCollectionsDeleteMutation } from '../../hooks/useCollectionsDeleteMutation';
import type { SearchPayload } from '../../hooks/useSearchIndex';
import { trackDiscoverAction } from '../../services/analytics';

const messages = defineMessages({
  deleteHeader: {
    defaultMessage: "Are you sure you want to delete ''{collectionName}'?",
    id: '9ATF85',
  },
  deleteContent: {
    defaultMessage:
      "This action is permanent and you won't be able to recover it. All items can still be found in your workspace.",
    id: 'aDADrk',
  },
  deleteLabel: {
    defaultMessage: 'Delete collection dialog',
    id: 'QFlWe3',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'K3r6DQ',
  },
  deleting: {
    defaultMessage: 'Deleting',
    id: 'pYX859',
  },
});

type DeleteCollectionModalProps = {
  collectionId: string;
  filter: GlobalFilterOption;
  onClose: () => void;
  name: string;
  numCollections: number;
  open: boolean;
  query?: SearchPayload;
};

export function DeleteCollectionModal(props: DeleteCollectionModalProps) {
  const { collectionId, filter, name, numCollections, onClose, open, query } =
    props;
  const { formatMessage } = useIntl();
  const { mutateAsync: deleteCollection } = useCollectionsDeleteMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      trackDiscoverAction('deleteCollection', {
        filterType: filter,
        numCollections: numCollections - 1,
      });
      await deleteCollection({ name, collectionId, query });
      onClose();
      setTimeout(() => {
        setIsSubmitting(false);
      }, 300);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  return (
    <ConfirmationModal
      label={formatMessage(messages.deleteLabel)}
      title={formatMessage(messages.deleteHeader, {
        collectionName: name,
      })}
      description={formatMessage(messages.deleteContent)}
      open={open}
      onClose={onClose}
      leftButton={
        <Button variation="secondaryLite" isFullWidth onClick={onClose}>
          {formatMessage(messages.cancel)}
        </Button>
      }
      rightButton={
        <Button variation="danger" isFullWidth onClick={onSubmit}>
          {Boolean(isSubmitting) && <LoadingSpinner />}
          {formatMessage(isSubmitting ? messages.deleting : messages.delete)}
        </Button>
      }
    />
  );
}
