import { SVGReactComponents } from '@assembly-web/assets';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  Content,
  Item,
  Menu,
  Portal,
  Root as MenuRoot,
  Trigger,
} from '@radix-ui/react-menubar';
import type {
  ForwardRefExoticComponent,
  FunctionComponent,
  PropsWithoutRef,
  RefAttributes,
  SVGProps,
} from 'react';
import { useContext, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { CloseDrawerContext } from '../../../contexts/CloseDrawerContext';
import { trackPopoutAction } from '../../../services/analytics';
import { MoreToolbarButton } from './MoreToolbarButton';

type MenuItem = {
  id: string;
  text: string;
  icon:
    | ForwardRefExoticComponent<
        PropsWithoutRef<SVGProps<SVGSVGElement>> & {
          title?: string;
          titleId?: string;
        } & RefAttributes<SVGSVGElement>
      >
    | FunctionComponent<
        SVGProps<SVGSVGElement> & { title?: string | undefined }
      >;
  onClick: () => void;
};

type HeaderToolbarItems = MenuItem[];

const messages = defineMessages({
  open: {
    defaultMessage: 'Open',
    id: 'JfG49w',
  },
  minimize: {
    defaultMessage: 'Minimize',
    id: 'TuSL+Z',
  },
  closeItems: {
    defaultMessage: 'Close all {number} items',
    id: 'YZovS/',
  },
});

export function MoreToolbarMenu(props: { showCollapseOption?: boolean }) {
  const { showCollapseOption } = props;
  const { formatMessage } = useIntl();
  const drawers = useMultiDrawerStore((store) => store.getDrawers());
  const overflowDrawers = useMultiDrawerStore((store) =>
    store.getOverflowDrawers()
  );
  const isOverflowOpen = useMultiDrawerStore((store) => store.isOverflowOpen);
  const toggleIsOverflowOpen = useMultiDrawerStore(
    (store) => store.toggleIsOverflowOpen
  );

  const { openModal: openConfirmationCloseModal } =
    useContext(CloseDrawerContext);

  const numAsText =
    overflowDrawers.length > 9 ? '9+' : overflowDrawers.length.toString();

  const headerToolbarItems: HeaderToolbarItems = useMemo(() => {
    const onCloseAll = () => {
      openConfirmationCloseModal();

      trackPopoutAction('exitAllOverflowItemsClicked', {
        numPopouts: overflowDrawers.length,
      });
    };

    const toggleOverflowMenu = () => {
      if (isOverflowOpen) {
        trackPopoutAction('collapseOverflowClicked');
      } else {
        trackPopoutAction('expandOverflowClicked', {
          numPopouts: drawers.length + overflowDrawers.length,
        });
      }
      toggleIsOverflowOpen();
    };

    const items: HeaderToolbarItems = [
      {
        id: 'closeDrawer',
        text: formatMessage(messages.closeItems, { number: numAsText }),
        icon: XMarkIcon,
        onClick: onCloseAll,
      },
    ];

    if (showCollapseOption) {
      const option = isOverflowOpen
        ? {
            id: 'minimizeDrawer',
            text: formatMessage(messages.minimize),
            icon: SVGReactComponents.CollapseDrawerIcon,
            onClick: toggleOverflowMenu,
          }
        : {
            id: 'openDrawer',
            text: formatMessage(messages.open),
            icon: SVGReactComponents.OpenDrawerIcon,
            onClick: toggleOverflowMenu,
          };
      items.unshift(option);
    }
    return items;
  }, [
    drawers.length,
    formatMessage,
    isOverflowOpen,
    numAsText,
    openConfirmationCloseModal,
    overflowDrawers.length,
    showCollapseOption,
    toggleIsOverflowOpen,
  ]);

  return (
    <MenuRoot>
      <Menu>
        <Trigger asChild>
          <div className="[&>*]:data-[state=open]:bg-gray-8">
            <MoreToolbarButton />
          </div>
        </Trigger>
        <Portal>
          <Content
            align="end"
            alignOffset={-2}
            sideOffset={2}
            className="z-[11] max-h-[32rem] w-max origin-top overflow-auto rounded-md border border-gray-5 bg-gray-1 py-1 shadow-lg-down ring-opacity-5 focus:outline-none"
          >
            {headerToolbarItems.map((item) => (
              <Item
                key={item.id}
                onClick={item.onClick}
                className="cursor-pointer focus:bg-primary-2 focus:outline-none"
              >
                <div className="bg-gray-0 flex w-full items-center px-3 py-[9px] text-sm text-gray-8 hover:bg-gray-3 focus:outline-none">
                  <item.icon className="h-4 w-4 stroke-2" />
                  <div className="ml-2 line-clamp-1 text-left">{item.text}</div>
                </div>
              </Item>
            ))}
          </Content>
        </Portal>
      </Menu>
    </MenuRoot>
  );
}
