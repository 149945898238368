import type {
  Collection,
  CollectionsAPIResponse,
} from '@assembly-web/services';
import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import { getCollectionsQuery } from '../queries/getCollectionsQuery';

export function useGetCollectionsQuery(
  options?: UseQueryOptions<CollectionsAPIResponse>
): UseQueryResult<CollectionsAPIResponse> & {
  pinnedCollections: Collection[];
} {
  const collections = useQuery(getCollectionsQuery(options));
  const pinnedCollections =
    collections.data?.data.filter((collection) => {
      return collection.isPinned;
    }) ?? [];

  return {
    ...collections,
    pinnedCollections,
  };
}
