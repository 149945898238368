import type { DoraReportingCategory } from '@assembly-web/services';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import {
  getDoraChatStore,
  getPersistKeyForDoraChatDrawer,
} from '../../../stores/doraChatStore';
import type { DoraChatDrawer as DoraChatDrawerProps } from '../types';
import { ReportingInsightsChatView } from './ReportingInsightsChatView';
import { ReportingInsightsHomeScreen } from './ReportingInsightsHomeScreen';
import { useGetDoraChatReportResponse } from './useDoraChatReportEventSource';

export function DoraChatDrawerBody(props: DoraChatDrawerProps) {
  const { hasNewActivity, id, type } = props;
  const persistKey = getPersistKeyForDoraChatDrawer(props);

  const reportingType = type as DoraReportingCategory;

  const { abortController, doraChatStore } = useMemo(
    () => getDoraChatStore(persistKey),
    [persistKey]
  );

  const getDoraResponse = useGetDoraChatReportResponse(
    props.id,
    doraChatStore,
    abortController,
    reportingType
  );

  const useDoraChatStore = doraChatStore;

  const activeCategory = useDoraChatStore((state) => {
    if (reportingType === 'flow_responses') {
      doraChatStore.getState().setReportingCategory('flow_responses');
      return 'flow_responses';
    }
    return state.reportingInsights?.activeCategory;
  });
  const initialCategory = useRef(activeCategory);

  const shouldAnimateIntroMessage = useDoraChatStore((state) => {
    return initialCategory.current !== state.reportingInsights?.activeCategory;
  });

  const findAndUpdateDrawerField = useMultiDrawerStore(
    (store) => store.findAndUpdateDrawerField
  );

  const markRead = useCallback(() => {
    findAndUpdateDrawerField(id, (draft) => {
      delete draft.hasNewActivity;
    });
  }, [id, findAndUpdateDrawerField]);

  useEffect(() => {
    if (hasNewActivity) {
      markRead();
    }
  }, [hasNewActivity, markRead]);

  if (!activeCategory) {
    return (
      <ReportingInsightsHomeScreen {...props} doraChatStore={doraChatStore} />
    );
  }

  return (
    <ReportingInsightsChatView
      {...props}
      doraChatStore={doraChatStore}
      getDoraResponse={getDoraResponse}
      reportingCategory={activeCategory}
      shouldAnimateIntroMessage={shouldAnimateIntroMessage}
    />
  );
}
