import { fileIcons } from '@assembly-web/assets';
import { type DateRange, FileType } from '@assembly-web/services';
import type {
  FileFilterOption,
  PrimaryOptions,
  SelectableOptionProps,
  SelectedOption,
} from '@assembly-web/ui';
import {
  Button,
  DoubleDatePicker,
  IconButton,
  NestedFilterList,
  TextStyle,
} from '@assembly-web/ui';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackDiscoverAction } from '../../../services/analytics';
import { generateDateRange } from '../../../services/secondaryFilters';
import { BottomActionSheet } from '../shared/dora/BottomActionSheet';
import { useDoubleDatePicker } from './hooks/useDoubleDatePicker';
import { useRefetchResponse } from './hooks/useRefetchResponse';

type FilterBottomSheetProps = {
  onClose: () => void;
  promptId?: string;
  existingFileTypes?: FileType[];
  existingDateRange?: DateRange;
};

const messages = defineMessages({
  filterHeading: {
    defaultMessage: 'Narrow your answer with filters',
    id: 'wuBjG0',
  },
  save: {
    defaultMessage: 'Save & Re-fetch answer',
    id: '4ZUT4H',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  fileType: {
    defaultMessage: 'File type',
    id: 'PiZbK/',
  },
  fileTypeDescription: {
    defaultMessage: 'Guide DoraAI to the right source type:',
    id: 'PMYtyu',
  },
  documents: {
    defaultMessage: 'Documents',
    id: 'vBlT6y',
  },
  spreadsheets: {
    defaultMessage: 'Spreadsheets',
    id: 'fd/DO3',
  },
  presentations: {
    defaultMessage: 'Presentations',
    id: 'kXo2i+',
  },
  pdfs: {
    defaultMessage: 'PDFs',
    id: '0Vaj1z',
  },
  dateRange: {
    defaultMessage: 'Date range',
    id: 'tygEJX',
  },
  dateRangeDescription: {
    defaultMessage:
      'DoraAI will find files created during the selected date range.',
    id: 'BhQ9vQ',
  },
});

const isFileFilterOption = (
  option: SelectableOptionProps
): option is FileFilterOption => {
  return (
    'id' in option &&
    'value' in option &&
    'imgUrl' in option &&
    'type' in option
  );
};

const hasDateRangeValue = (dateOption?: SelectedOption | null) => {
  return (
    (dateOption?.id && dateOption.id !== 'custom') ||
    dateOption?.value?.end ||
    dateOption?.value?.start
  );
};

const formatDateRange = (dateRange?: DateRange) => {
  if (dateRange === undefined) return null;
  let formattedDateRange = { end: '', start: '' };
  if (dateRange.gte) {
    formattedDateRange.start = dayjs(dateRange.gte).format('MM-DD-YYYY');
  }
  if (dateRange.lte) {
    formattedDateRange.end = dayjs(dateRange.lte).format('MM-DD-YYYY');
  }

  return {
    id: 'custom',
    value: formattedDateRange,
  };
};

export const FilterBottomSheet = (props: FilterBottomSheetProps) => {
  const {
    onClose,
    promptId,
    existingFileTypes = [],
    existingDateRange,
  } = props;
  const { formatMessage } = useIntl();

  const fileOptions = useMemo(() => {
    return [
      {
        id: '1',
        value: formatMessage(messages.pdfs),
        type: FileType.Pdf,
        imgUrl: fileIcons[FileType.Pdf],
      },
      {
        id: '2',
        value: formatMessage(messages.documents),
        type: FileType.Document,
        imgUrl: fileIcons[FileType.Document],
      },
      {
        id: '3',
        value: formatMessage(messages.spreadsheets),
        type: FileType.Spreadsheet,
        imgUrl: fileIcons[FileType.Spreadsheet],
      },
      {
        id: '4',
        value: formatMessage(messages.presentations),
        type: FileType.Presentation,
        imgUrl: fileIcons[FileType.Presentation],
      },
    ];
  }, [formatMessage]);

  const [selectedFileTypeOptions, setSelectedFileTypeOptions] = useState<
    FileFilterOption[]
  >(fileOptions.filter((option) => existingFileTypes.includes(option.type)));
  const [selectedFileOptions, setSelectedFileOptions] = useState<
    FileFilterOption[]
  >(selectedFileTypeOptions); // used in primaryOptions so that selected options aren't reordered in list
  const [selectedDateRange, setSelectedDateRange] =
    useState<SelectedOption | null>(() => formatDateRange(existingDateRange));

  const {
    dateHelpText,
    duration,
    isRangeInvalid,
    selectedEndIsoDate,
    selectedStartIsoDate,
    firstDate,
    secondDate,
  } = useDoubleDatePicker(selectedDateRange);
  const selectedFileTypes = selectedFileTypeOptions.map((o) => o.type);
  const startDateIsSame =
    existingDateRange?.gte && selectedDateRange?.value?.start
      ? dayjs(selectedDateRange.value.start)
          .startOf('day')
          .isSame(dayjs(existingDateRange.gte).startOf('day'))
      : false;
  const endDateIsSame =
    existingDateRange?.lte && selectedDateRange?.value?.end
      ? dayjs(selectedDateRange.value.end)
          .startOf('day')
          .isSame(dayjs(existingDateRange.lte).startOf('day'))
      : false;
  const dateRangeIsSame = startDateIsSame && endDateIsSame;

  const isSubmitDisabled =
    isRangeInvalid ||
    (selectedFileTypes.length === 0 && !hasDateRangeValue(selectedDateRange)) ||
    (selectedFileTypes.length === existingFileTypes.length &&
      selectedFileTypes.every((type) => existingFileTypes.includes(type)) &&
      dateRangeIsSame);

  const onSelect = useCallback(
    (option: SelectableOptionProps) => {
      if (isFileFilterOption(option)) {
        const alreadySelected = selectedFileTypeOptions.some(
          (o) => o.type === option.type
        );
        if (alreadySelected) {
          setSelectedFileTypeOptions(
            selectedFileTypeOptions.filter((o) => o.id !== option.id)
          );
        } else {
          setSelectedFileTypeOptions([...selectedFileTypeOptions, option]);
        }
      }
    },
    [selectedFileTypeOptions]
  );

  const saveOptionsState = () => {
    setSelectedFileOptions(selectedFileTypeOptions);
  };

  const primaryOptions: PrimaryOptions = useMemo(() => {
    return {
      fileType: {
        description: formatMessage(messages.fileTypeDescription),
        value: formatMessage(messages.fileType),
        options: fileOptions,
        selectedOptions: selectedFileOptions,
      },
      dateRange: {
        description: formatMessage(messages.dateRangeDescription),
        value: formatMessage(messages.dateRange),
        options: fileOptions,
        selectedOption: selectedDateRange,
      },
    };
  }, [fileOptions, formatMessage, selectedFileOptions, selectedDateRange]);

  const refetchResponse = useRefetchResponse({ onRefetch: onClose, promptId });

  const handleDateOptionChange = (optionId: string) => {
    setSelectedDateRange({ id: optionId });
  };

  const onSave = () => {
    refetchResponse({
      fileTypes: selectedFileTypes,
      dateRange: selectedDateRange?.id
        ? generateDateRange(
            selectedDateRange.id,
            selectedDateRange.value?.start ?? '',
            selectedDateRange.value?.end ?? ''
          )
        : {},
    });
    trackDiscoverAction('saveDoraFiltersClicked');
  };

  const handleMenuSelect = (menuItem: string) => {
    if (menuItem === 'fileType') {
      trackDiscoverAction('fileTypeFilterClicked');
    } else if (menuItem === 'dateRange') {
      trackDiscoverAction('dateRangeFilterClicked');
    }
  };

  useEffect(() => {
    if (selectedStartIsoDate || selectedEndIsoDate) {
      const start = selectedStartIsoDate
        ? dayjs(selectedStartIsoDate).format('MM-DD-YYYY')
        : '';
      const end = selectedEndIsoDate
        ? dayjs(selectedEndIsoDate).format('MM-DD-YYYY')
        : '';

      setSelectedDateRange({
        id: 'custom',
        value: { start, end },
      });
    }
  }, [selectedEndIsoDate, selectedStartIsoDate]);

  return (
    <BottomActionSheet
      overlap
      shouldAnimateOnMount
      className="relative h-[306px]"
    >
      <NestedFilterList
        showCTAs={false}
        showSearch={false}
        displaySelectedOption
        primaryOptions={primaryOptions}
        onSelect={onSelect}
        onClose={onClose}
        onBackButtonClick={saveOptionsState}
        onDateOptionSelect={handleDateOptionChange}
        onMenuSelected={handleMenuSelect}
        menuHeader={
          <div className="px-3">
            <TextStyle variant="xs-regular" className="text-gray-8">
              {formatMessage(messages.filterHeading)}
            </TextStyle>
            <IconButton
              size="xSmall"
              variation="tertiaryLite"
              className="absolute right-2 top-2"
              onClick={onClose}
            >
              <XMarkIcon className="h-4 w-4 stroke-gray-8" />
            </IconButton>
          </div>
        }
        className={{
          container: 'mb-10',
          header: { title: 'pt-0.5 text-xs !font-medium' },
          selectableList: 'max-h-[300px] pt-1',
        }}
        datePicker={
          <div className="px-4">
            <DoubleDatePicker
              helpText={dateHelpText}
              showFooter={false}
              firstDate={firstDate}
              secondDate={secondDate}
            />
          </div>
        }
        duration={duration}
        footer={
          <footer className="absolute bottom-0 flex w-full gap-2 border-t border-gray-5 p-2">
            <Button
              isFullWidth
              size="small"
              variation="success"
              onClick={onSave}
              disabled={isSubmitDisabled}
            >
              <CheckIcon className="h-4 w-4" />
              <TextStyle variant="sm-medium">
                {formatMessage(messages.save)}
              </TextStyle>
            </Button>
            <Button size="small" variation="secondaryLite" onClick={onClose}>
              <XMarkIcon className="h-4 w-4 stroke-error-7" />
              <TextStyle variant="sm-medium">
                {formatMessage(messages.cancel)}
              </TextStyle>
            </Button>
          </footer>
        }
      />
    </BottomActionSheet>
  );
};
