import { fabIcons } from '@assembly-web/assets';
import { Bars3Icon } from '@heroicons/react/24/solid';
import {
  AnimatePresence,
  motion,
  useAnimation,
  useMotionValue,
} from 'framer-motion';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import useResizeObserver from 'use-resize-observer';

import { useNavData } from '../../hooks/nav/useNavData';
import { useNavStore } from '../../hooks/nav/useNavStore';
import { trackNavShow } from '../../services/analytics';

const messages = defineMessages({
  triggerNavLabel: {
    defaultMessage: 'Trigger nav menu',
    id: 'AZxO8K',
  },
});

export function NavTrigger() {
  const { formatMessage } = useIntl();
  const animationControl = useAnimation();
  const startPosition = useMotionValue(0);

  const isNavOpen = useNavStore((state) => state.isOpen);
  const pinNav = useNavStore((state) => state.pinNav);
  const showNav = useNavStore((state) => state.showNav);
  const hideNav = useNavStore((state) => state.hideNav);

  const { totalNoOfPinnedCollections, totalSoftPinnedCollections } =
    useNavData();
  const [maxWidth, setMaxWidth] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [ellipsesPosition, setEllipsesPosition] = useState<'left' | 'right'>(
    'left'
  );

  const updateNavTriggerPosition = useNavStore(
    (state) => state.updateNavTriggerPosition
  );
  const navTriggerPosition = useNavStore((state) => state.navTriggerPosition);

  const setPosition = (triggerWidth: number) => {
    const newMaxWidth = window.innerWidth - triggerWidth;
    setMaxWidth(newMaxWidth);
  };

  const [triggerWidth, setTriggerWidth] = useState(0);
  const { ref } = useResizeObserver({
    onResize: ({ width = 0 }) => {
      setPosition(width);
      setTriggerWidth(width);
    },
  });

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (triggerWidth !== 0) {
        setPosition(triggerWidth);
      }
    },
  });

  useEffect(() => {
    if (navTriggerPosition === 'right') {
      startPosition.set(maxWidth);
      setEllipsesPosition('right');
    } else {
      setEllipsesPosition('left');
    }
  }, [maxWidth, navTriggerPosition, startPosition]);

  useEffect(() => {
    const unsubscribeX = startPosition.on('change', (currentX) => {
      const windowHalfWidth = window.innerWidth / 2;
      if (currentX > windowHalfWidth) {
        animationControl.start({ x: maxWidth });
        setEllipsesPosition('right');
        updateNavTriggerPosition('right');
      } else {
        animationControl.start({ x: 0 });
        setEllipsesPosition('left');
        updateNavTriggerPosition('left');
      }
    });

    return () => unsubscribeX();
  }, [animationControl, startPosition, maxWidth, updateNavTriggerPosition]);

  return (
    <AnimatePresence>
      <motion.div
        ref={ref}
        className={twMerge(
          'fixed z-[5] mt-[22px] flex items-center',
          ellipsesPosition === 'right' && 'flex-row-reverse',
          isDragging && 'cursor-grabbing'
        )}
        drag="x"
        dragConstraints={{ left: 0, right: maxWidth }}
        dragMomentum
        style={{ x: startPosition }}
        animate={animationControl}
        onDragStart={() => {
          hideNav();
          setIsDragging(true);
        }}
        onDragEnd={(_, info) => {
          const windowHalfWidth = window.innerWidth / 2;
          if (info.point.x > windowHalfWidth) {
            animationControl.start({ x: maxWidth });
            setEllipsesPosition('right');
          } else {
            animationControl.start({ x: 0 });
            setEllipsesPosition('left');
          }
          updateNavTriggerPosition(ellipsesPosition);
          setIsDragging(false);
          trackNavShow({
            numPinnedCollections: totalNoOfPinnedCollections,
            numSoftPinnedCollections: totalSoftPinnedCollections,
          });
          showNav();
        }}
      >
        {isDragging ? (
          <button className="rounded-3xl bg-primary-5 p-2">
            <img
              src={fabIcons.moveIcon}
              className="h-6 w-6"
              draggable="false"
              alt=""
            />
          </button>
        ) : (
          <>
            <button className="cursor-pointer px-1 py-2.5 opacity-25 focus-within:opacity-100 hover:opacity-100 active:opacity-100">
              <img
                src={fabIcons.verticalEllipsisIcon}
                alt=""
                draggable="false"
              />
            </button>
            {!isNavOpen && (
              <motion.button
                animate={{ opacity: 100 }}
                aria-label={formatMessage(messages.triggerNavLabel)}
                initial={{ opacity: 100 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 1 }}
                className="rounded-3xl bg-primary-5 p-2"
                onClick={() => {
                  pinNav();
                  trackNavShow({
                    numPinnedCollections: totalNoOfPinnedCollections,
                    numSoftPinnedCollections: totalSoftPinnedCollections,
                  });
                  showNav();
                  // TODO: focus on the nav container
                }}
                onMouseEnter={() => {
                  trackNavShow({
                    numPinnedCollections: totalNoOfPinnedCollections,
                    numSoftPinnedCollections: totalSoftPinnedCollections,
                  });
                  showNav();
                }}
              >
                <Bars3Icon className="h-5 w-5 text-gray-1" />
              </motion.button>
            )}
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
}
