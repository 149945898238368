import type { CustomTimePeriodQuestionProps } from '../../../../types';
import { Message } from './Message';

export const CustomTimePeriodQuestion = (
  props: CustomTimePeriodQuestionProps
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { usePredefinedTimePeriodSetting },
  } = props;

  const isEditing = useEditSettingsStore(
    (store) => store.settingEditing === 'customTimePeriod'
  );

  const isOrigPredefinedTimePeriodSet = usePredefinedTimePeriodSetting(
    (store) => store.isSet
  );

  const isEditedPredefinedTimePeriodSet = useEditSettingsStore(
    (store) => store.predefinedTimePeriod.isSet
  );

  const isPredefinedTimePeriodSet =
    isOrigPredefinedTimePeriodSet || isEditedPredefinedTimePeriodSet;

  const isOrigCustomOptionSelected = usePredefinedTimePeriodSetting(
    (store) => !store.value?.endDate && !store.value?.startDate
  );

  const isEditedCustomOptionSelected = useEditSettingsStore(
    (store) =>
      !store.predefinedTimePeriod.value?.endDate &&
      !store.predefinedTimePeriod.value?.startDate
  );

  const isCustomOptionSelected =
    (!isEditing && isOrigCustomOptionSelected) ||
    (isEditing && isEditedCustomOptionSelected);

  const isEditingPrevSetting = useEditSettingsStore(
    (store) =>
      store.isEditingRespondents() ||
      store.isEditingBlocks() ||
      store.isEditingFocus() ||
      store.settingEditing === 'predefinedTimePeriod'
  );

  return (
    isPredefinedTimePeriodSet &&
    isCustomOptionSelected &&
    !isEditingPrevSetting && <Message {...props} />
  );
};
