import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { timeouts } from '../../../../../shared/dora/constants';
import { DoraSingleMessage } from '../../../../../shared/dora/DoraSingleMessage';
import type { BlocksGroupQuestionProps } from '../../../../types';

const messages = defineMessages({
  prevQuestionAnswered: {
    defaultMessage:
      'Thanks! Do you want to summarize the entire response, or only specific answers?',
    id: 'Ov57x9',
  },
  prevQuestionSkipped: {
    defaultMessage:
      'Do you want to summarize the entire response, or only specific answers?',
    id: 'PLayiO',
  },
});

export const Message = (props: BlocksGroupQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useBlocksGroupSetting },
    isPrevQuestionSkipped,
  } = props;

  const { formatMessage } = useIntl();
  const isSeen = useBlocksGroupSetting.getState().isQuestionSeen;
  const isSet = useBlocksGroupSetting((store) => store.isSet);
  const markSeen = useBlocksGroupSetting((store) => store.markQuestionSeen);

  const setShouldRequestInput = useBlocksGroupSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useBlocksGroupSetting(
    (store) => store.shouldRequestInput
  );

  const value = useBlocksGroupSetting((store) => store.value);

  const isEditingOtherSetting = useEditSettingsStore(
    (store) =>
      store.settingEditing === 'individualBlocks' ||
      store.isEditingFocus() ||
      store.isEditingTimePeriod()
  );

  useEffect(() => {
    if (!isSeen) {
      markSeen();
    }
  }, [isSeen, markSeen]);

  useEffect(() => {
    if (!value && !isSet && !shouldRequestInput) {
      setTimeout(() => {
        setShouldRequestInput(true);
      }, timeouts.showInput);
    }
  }, [isSet, setShouldRequestInput, shouldRequestInput, value]);

  return (
    <DoraSingleMessage isFaded={isEditingOtherSetting} shouldAnimate={!isSeen}>
      <TextStyle className="text-gray-9">
        {formatMessage(
          isPrevQuestionSkipped
            ? messages.prevQuestionSkipped
            : messages.prevQuestionAnswered
        )}
      </TextStyle>
    </DoraSingleMessage>
  );
};
