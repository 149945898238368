import { mapHexCodeToEmoticon } from '@assembly-web/services';
import { Avatar, AvatarSize, PostIcon, TextStyle } from '@assembly-web/ui';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { useGetPostAndRepliesDetails } from '../../../../../hooks/useGetPostAndRepliesDetails';
import { defaultRecognitionFlowIcon } from '../../cards/utils';
import type { PostDrawerProps } from '../types';

function PostDrawerHeaderLoader() {
  return (
    <div className="flex w-full animate-pulse items-center gap-1">
      <div className="h-4 w-4 rounded-full border-[1px] border-gray-1 bg-gray-5"></div>
      <div className="h-[12px] w-[128px] flex-shrink-0 rounded bg-gray-5"></div>
    </div>
  );
}

export function PostDrawerHeader(props: PostDrawerProps) {
  const {
    flowResponse,
    isPostCardDataLoading,
    isPostCardDataError,
    isPostNotFoundError,
  } = useGetPostAndRepliesDetails({
    flowId: props.data.type === 'flow' ? props.data.flowId : 'recognition',
    responseId:
      props.data.type === 'flow' ? props.data.responseId : props.data.postId,
    enabled: true,
  });

  const plainFlow = `${mapHexCodeToEmoticon(
    flowResponse?.flow.icon.value ?? defaultRecognitionFlowIcon
  )} ${flowResponse?.flow.name}`;

  return (
    <div className="grid grid-cols-[1fr_auto_auto] gap-2 p-0.5">
      <PostIcon className="mt-[2px] h-4 w-4 fill-gray-1" />
      <div className="flex-grow border-l border-gray-7"></div>

      {isPostCardDataLoading ? (
        <PostDrawerHeaderLoader />
      ) : (
        <div className="grid grid-cols-[auto_auto] items-center gap-1">
          {!isPostNotFoundError && Boolean(isPostCardDataError) && (
            <ExclamationTriangleIcon className="h-4 w-4 text-gray-1" />
          )}
          {!isPostCardDataError && (
            <Avatar
              className="flex-shrink-0"
              image={flowResponse?.respondent.image}
              size={AvatarSize.ExtraSmall}
              name={flowResponse?.respondent.name ?? ''}
              memberID={flowResponse?.respondent.memberID ?? ''}
            />
          )}
          <TextStyle
            as="span"
            className="truncate text-left text-gray-1"
            variant="xs-medium"
          >
            {isPostCardDataError ? (
              <>
                {isPostNotFoundError ? (
                  <FormattedMessage
                    defaultMessage="Post not found"
                    id="fCHjUH"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Error loading post"
                    id="/SFl6i"
                  />
                )}
              </>
            ) : (
              <FormattedMessage
                defaultMessage="in {plainFlow}"
                id="P1plM3"
                values={{ plainFlow }}
              />
            )}
          </TextStyle>
        </div>
      )}
    </div>
  );
}
