import {
  APIEndpoints,
  config,
  logger,
  unauthenticatedAssemblyAPI,
  userAuthStore,
} from '@assembly-web/services';
import { PulseLoadingIndicator, TextStyle } from '@assembly-web/ui';
import type { QueryClient } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { Suspense } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { Await, defer, Navigate, useLoaderData } from 'react-router-dom';

const signOutText = defineMessage({
  defaultMessage: 'Signing out',
  id: 'I6MMaq',
});

export function SignOutRoute() {
  const { formatMessage } = useIntl();

  const data = useLoaderData() as ReturnType<ReturnType<typeof signOutLoader>>;

  return (
    <Suspense
      fallback={
        <PulseLoadingIndicator size="sm">
          <TextStyle variant="base-medium">
            {formatMessage(signOutText)}
          </TextStyle>
        </PulseLoadingIndicator>
      }
    >
      <Await
        resolve={
          /* @ts-expect-error react router can't give us proper types for this */
          data.signOutState
        }
      >
        {() => <Navigate to="/workspaces" />}
      </Await>
    </Suspense>
  );
}

export function signOutLoader(queryClient: QueryClient) {
  return async function () {
    return defer({
      signOutState: (async function signOutLoader() {
        queryClient.removeQueries();
        if (config.env === 'prod') {
          mixpanel.reset();
        }

        try {
          await unauthenticatedAssemblyAPI.post(APIEndpoints.signOut);
          userAuthStore.getState().clearSession();
        } catch (error) {
          logger.error('Error while signing out', {}, error as Error);
        }
        return null;
      })(),
    });
  };
}
