import type { FocusOptionsQuestionProps } from '../../../../types';
import { Message } from './Message';

export const FocusOptionsQuestion = (props: FocusOptionsQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useIndividualBlocksSetting },
  } = props;

  const isBlocksSet = useIndividualBlocksSetting((store) => store.isSet);

  const isEditingPrevSetting = useEditSettingsStore(
    (store) => store.isEditingRespondents() || store.isEditingBlocks()
  );

  return isBlocksSet && !isEditingPrevSetting && <Message {...props} />;
};
