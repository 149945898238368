import { buildSafeUrl, config } from '@assembly-web/services';
import { AssemblyLoadingIndicator } from '@assembly-web/ui';

export function MSTeamsAuth() {
  return <AssemblyLoadingIndicator />;
}

export async function msTeamsAuthLoader() {
  try {
    window.location.href = buildSafeUrl(
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
      {
        redirect_uri: `${config.domains.app}/sso-sign-in/office365`,
        client_id: config.sso.office365,
        scope: 'User.Read',
        response_type: 'code',
        response_mode: 'query',
      }
    );

    return null;
  } catch (err) {
    return null;
  }
}
