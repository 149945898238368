import type { ConfirmActionsProps } from '../../types';
import { ConfirmActionsContent } from './ConfirmActionsContent';

export const ConfirmActions = (props: ConfirmActionsProps) => {
  const {
    formSettingsStore: { useSettingsConfirmationSetting },
  } = props;

  const shouldRequestInput = useSettingsConfirmationSetting(
    (store) => store.shouldRequestInput
  );

  const isConfirmed = useSettingsConfirmationSetting((store) => store.value);

  return (
    shouldRequestInput && !isConfirmed && <ConfirmActionsContent {...props} />
  );
};
