import { XMarkIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

import { ToolbarButton } from '../shared/ToolbarButton';

const messages = defineMessages({
  label: {
    defaultMessage: 'Close drawer',
    id: 'IdzYd0',
  },
  tooltipText: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export const CloseToolbarButton = ({ onClick }: { onClick?: () => void }) => {
  const { formatMessage } = useIntl();

  return (
    <ToolbarButton
      label={formatMessage(messages.label)}
      onClick={onClick}
      tooltipText={formatMessage(messages.tooltipText)}
    >
      <XMarkIcon className="h-4 w-4 text-[--foreground] group-hover:text-[--foreground-alt] group-focus:text-[--foreground-alt]" />
    </ToolbarButton>
  );
};
