import {
  UserActivitySortType,
  zustandLocalStorage,
} from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type FeedSortStore = {
  [GlobalFilterOption.All]: UserActivitySortType;
  [GlobalFilterOption.Collections]: UserActivitySortType;
  [GlobalFilterOption.Files]: UserActivitySortType;
  [GlobalFilterOption.Tasks]: UserActivitySortType;
  [GlobalFilterOption.Flows]: UserActivitySortType;
  [GlobalFilterOption.People]: UserActivitySortType;
  [GlobalFilterOption.Rewards]: UserActivitySortType;
  [GlobalFilterOption.Notifications]: UserActivitySortType;
  [GlobalFilterOption.Challenges]: UserActivitySortType;
  setSortType: (
    filter: GlobalFilterOption,
    sortType: UserActivitySortType
  ) => void;
  getSortType: (filter: GlobalFilterOption) => UserActivitySortType;
};

export const useFeedSortStore = create<FeedSortStore>()(
  persist(
    (set, get) => ({
      [GlobalFilterOption.Flows]: UserActivitySortType.AtoZ,
      [GlobalFilterOption.People]: UserActivitySortType.AtoZ,
      [GlobalFilterOption.All]: UserActivitySortType.Relevance,
      [GlobalFilterOption.Collections]: UserActivitySortType.RecentlyViewed,
      [GlobalFilterOption.Files]: UserActivitySortType.Relevance,
      [GlobalFilterOption.Tasks]: UserActivitySortType.Relevance,
      [GlobalFilterOption.Rewards]: UserActivitySortType.Relevance,
      [GlobalFilterOption.Notifications]: UserActivitySortType.RecentlyViewed,
      [GlobalFilterOption.Challenges]: UserActivitySortType.Relevance,
      getSortType: (filter) => {
        return (get() as FeedSortStore)[filter];
      },
      setSortType: (filter, sortType) => {
        return set(() => ({ [filter]: sortType }));
      },
    }),
    {
      name: 'user-feed-storage',
      storage: createJSONStorage(() => zustandLocalStorage),
    }
  )
);
