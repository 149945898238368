import type { DateRange, FileType, Member } from '@assembly-web/services';
import { useEffect, useRef, useState } from 'react';
import type { MessageDescriptor } from 'react-intl';

import { timeouts } from '../shared/dora/constants';
import { MemberChatMessage } from '../shared/dora/MemberChatMessage';
import { DoraChatMessage } from './DoraChatMessage';
import {
  type DoraResponseMetadata,
  useDoraEventSource,
} from './hooks/useDoraEventSource';

export function QuestionAnswerBlock({
  initialQuestion,
  isAdmin,
  isPlanFeaturesLoading,
  onUpgradeButtonClick,
  member,
  metadata,
  staticResponse,
  threadId,
  dateRange,
  documentId,
  fileTypes,
  refetchPromptId,
}: {
  initialQuestion: boolean;
  isAdmin: boolean;
  isPlanFeaturesLoading: boolean;
  onUpgradeButtonClick: () => void;
  member: Member;
  metadata?: DoraResponseMetadata;
  staticResponse?: MessageDescriptor;
  threadId?: string;
  dateRange?: DateRange;
  documentId?: string;
  fileTypes?: FileType[];
  refetchPromptId?: string;
}) {
  const {
    question,
    htmlResponse,
    markdownResponse,
    errorMessage,
    sources,
    isAnswerSeen,
    isError,
    isErrorSeen,
    isStreaming,
    promptId,
    isDoraIncludedInPlan,
    isLoading,
    isLoadingSeen,
    loadingMessage,
    getDoraResponse,
    reset,
    updateBlock,
  } = useDoraEventSource();

  const [showResponseBubble, setShowResponseBubble] = useState(
    isLoadingSeen || isAnswerSeen
  );

  const streamCalled = useRef(false);
  const currentQuestion = useRef(question);

  useEffect(() => {
    if (question !== currentQuestion.current) {
      streamCalled.current = false;
      currentQuestion.current = question;
      setShowResponseBubble(false);
    }
  }, [question]);

  useEffect(() => {
    if (!streamCalled.current && !isError && !staticResponse) {
      if (!htmlResponse || (htmlResponse && isStreaming)) {
        streamCalled.current = true;
        // reset if streaming gets cut off
        if (isStreaming) {
          reset();
        }
        getDoraResponse(question, {
          threadId,
          dateRange,
          documentId,
          fileTypes,
          refetchPromptId,
          isAdmin: metadata?.isAdmin,
          shouldUseStaticResponse: metadata?.shouldUseStaticResponse,
          suggestedQuestionActionType: initialQuestion
            ? undefined
            : 'followUpPromptClicked',
        });
        setTimeout(() => setShowResponseBubble(true), timeouts.showInput);
      }
    }
  }, [
    dateRange,
    documentId,
    fileTypes,
    initialQuestion,
    isError,
    isStreaming,
    getDoraResponse,
    metadata,
    question,
    htmlResponse,
    refetchPromptId,
    reset,
    sources,
    staticResponse,
    threadId,
    updateBlock,
  ]);

  const handleTryAgainClick = () => {
    getDoraResponse(question, { documentId, refetchPromptId });
  };

  return (
    <>
      <MemberChatMessage
        fullName={member.profile.fullName}
        image={member.profile.image}
        memberId={member.memberId}
        shouldAnimate={!(isLoadingSeen || isAnswerSeen)}
        notUpgraded={!isDoraIncludedInPlan && !isPlanFeaturesLoading}
      >
        {question}
      </MemberChatMessage>
      {Boolean(showResponseBubble) && (
        <DoraChatMessage
          errorMessage={errorMessage}
          isAdmin={isAdmin}
          isAnswerSeen={isAnswerSeen}
          isDoraIncludedInPlan={isDoraIncludedInPlan}
          isError={isError}
          isErrorSeen={isErrorSeen}
          isLoading={isLoading}
          isLoadingSeen={isLoadingSeen}
          isPlanFeaturesLoading={isPlanFeaturesLoading}
          isStreaming={isStreaming}
          loadingMessage={loadingMessage}
          onTryAgainClick={handleTryAgainClick}
          onUpgradeButtonClick={onUpgradeButtonClick}
          promptId={promptId}
          htmlResponse={htmlResponse}
          markdownResponse={markdownResponse ?? ''}
          notUpgraded={!isDoraIncludedInPlan && !isPlanFeaturesLoading}
          sources={sources}
          staticResponse={staticResponse}
          updateBlock={updateBlock}
        />
      )}
    </>
  );
}
