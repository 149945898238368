import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { timeouts } from '../../../../../shared/dora/constants';
import { DoraSingleMessage } from '../../../../../shared/dora/DoraSingleMessage';
import type { IndividualRespondentsQuestionProps } from '../../../../types';

const messages = defineMessages({
  specifyPeople: {
    defaultMessage: 'Great! Specify those people below:',
    id: 'xPMY/p',
  },
});

export const Message = (props: IndividualRespondentsQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useIndividualRespondentsSetting },
  } = props;

  const { formatMessage } = useIntl();

  const isEditingCurSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'individualRespondents'
  );

  const isEditingOtherSetting = useEditSettingsStore(
    (store) =>
      store.isEditingBlocks() ||
      store.isEditingFocus() ||
      store.isEditingTimePeriod()
  );

  const isOrigQuestionSeen =
    useIndividualRespondentsSetting.getState().isQuestionSeen;

  const isEditQuestionSeen =
    useEditSettingsStore.getState().individualRespondents.isQuestionSeen;

  const isSeen = isEditingCurSetting ? isEditQuestionSeen : isOrigQuestionSeen;

  const isOrigSet = useIndividualRespondentsSetting((store) => store.isSet);

  const isEditedSet = useEditSettingsStore(
    (store) => store.individualRespondents.isSet
  );

  const isSet =
    (isEditingCurSetting && isEditedSet) || (!isEditingCurSetting && isOrigSet);

  const markOrigQuestionSeen = useIndividualRespondentsSetting(
    (store) => store.markQuestionSeen
  );

  const markEditQuestionSeen = useEditSettingsStore(
    (store) => store.markQuestionSeen
  );

  const requestOrigInput = useIndividualRespondentsSetting(
    (store) => store.setShouldRequestInput
  );

  const requestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  useEffect(() => {
    if (!isOrigQuestionSeen && !isEditingCurSetting) {
      markOrigQuestionSeen();
    } else if (!isEditQuestionSeen && isEditingCurSetting) {
      markEditQuestionSeen('individualRespondents');
    }
  }, [
    isEditQuestionSeen,
    isEditingCurSetting,
    isOrigQuestionSeen,
    markEditQuestionSeen,
    markOrigQuestionSeen,
  ]);

  useEffect(() => {
    const requestInput = isEditingCurSetting
      ? (shouldRequestInput: boolean) =>
          requestEdit('individualRespondents', shouldRequestInput)
      : requestOrigInput;

    if (!isSet) {
      setTimeout(() => {
        requestInput(true);
      }, timeouts.showInput);
    }
  }, [isSet, isEditingCurSetting, requestOrigInput, requestEdit]);

  return (
    <DoraSingleMessage isFaded={isEditingOtherSetting} shouldAnimate={!isSeen}>
      <TextStyle className="text-gray-9">
        {formatMessage(messages.specifyPeople)}
      </TextStyle>
    </DoraSingleMessage>
  );
};
