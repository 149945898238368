import {
  fileIcons,
  googleDocsLogo,
  googleFormsLogo,
  googleJamboardLogo,
  googleSheetsLogo,
  googleSlidesLogo,
} from '@assembly-web/assets';
import { FileType } from '@assembly-web/services';

export function getFileIcon(mimeType: string) {
  switch (mimeType) {
    case '3d2':
    case '3gp':
    case 'avi':
    case 'mov':
    case 'mp4':
    case 'mpg':
    case 'ogv':
    case 'video/3gpp':
    case 'video/avi':
    case 'video/mp4':
    case 'video/mpeg':
    case 'video/ogg':
    case 'video/quicktime':
    case 'video/x-msvideo':
      return fileIcons[FileType.Video];
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'doc':
    case 'docx':
      return fileIcons[FileType.Document];
    case 'application/pdf':
    case 'pdf':
      return fileIcons[FileType.Pdf];
    case 'application/vnd.google-apps.document':
      return googleDocsLogo;
    case 'application/vnd.google-apps.form':
      return googleFormsLogo;
    case 'application/vnd.google-apps.jam':
      return googleJamboardLogo;
    case 'application/vnd.google-apps.presentation':
      return googleSlidesLogo;
    case 'application/vnd.google-apps.spreadsheet':
      return googleSheetsLogo;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'pps':
    case 'ppsx':
    case 'ppt':
    case 'pptx':
      return fileIcons[FileType.Presentation];
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'csv':
    case 'text/csv':
    case 'xls':
    case 'xlsx':
      return fileIcons[FileType.Spreadsheet];
    case 'application/zip':
    case 'zip':
      return fileIcons[FileType.Zip];
    case 'audio/mp4':
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/x-m4a':
    case 'audio/x-wav':
    case 'mp3':
    case 'ogg':
    case 'wav':
      return fileIcons[FileType.Audio];
    case 'bmp':
    case 'gif':
    case 'image/bmp':
    case 'image/gif':
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'svg':
      return fileIcons[FileType.Image];
    default:
      return null;
  }
}
