import type {
  ChallengeCardDetails,
  FlowPostResponse,
  ImportantActivitiesApiResponse,
  LegacyPostTypesUnion,
  RepliesResponse,
} from '@assembly-web/services';
import {
  ActivityCardTypeEnum,
  APIEndpoints,
  assemblyAPI,
} from '@assembly-web/services';
import type { ReactionEmoji } from '@assembly-web/ui';
import { convertPostDataToFlowPostFormat } from '@assembly-web/ui';
import {
  type InfiniteData,
  type QueryFunctionContext,
  useMutation,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { produce } from 'immer';

function getAssemblyFlowPost({
  flowId,
  asEmbed,
  responseId,
}: {
  flowId: string;
  asEmbed: boolean;
  responseId: string;
}) {
  return async ({ signal }: QueryFunctionContext) => {
    const response = await assemblyAPI.get<FlowPostResponse>(
      APIEndpoints.assemblyFlowPost({ flowId, responseId }),
      { params: { ...(asEmbed && { asEmbed }) }, signal }
    );
    return response.data;
  };
}

function getAssemblyRecognitionPost({
  asEmbed,
  responseId,
  currencyName,
}: {
  asEmbed: boolean;
  responseId: string;
  currencyName: string;
}) {
  return async () => {
    const response = await assemblyAPI.get<LegacyPostTypesUnion>(
      APIEndpoints.assemblyRecognitionPost(responseId),
      { params: { ...(asEmbed && { asEmbed }) } }
    );
    const legacyPost: LegacyPostTypesUnion['post'] = {
      ...response.data.post,
      currencyName,
    };

    return convertPostDataToFlowPostFormat(legacyPost);
  };
}

export function getPostQuery(
  payload: {
    flowId: string;
    responseId: string;
    currencyName: string;
    asEmbed: boolean;
  },
  options?: UseQueryOptions<FlowPostResponse>
): UseQueryOptions<FlowPostResponse> {
  const { flowId, responseId, currencyName, asEmbed } = payload;
  return {
    queryKey: ['assemblyFlowPost', flowId, responseId],
    queryFn:
      flowId === 'recognition'
        ? getAssemblyRecognitionPost({ responseId, currencyName, asEmbed })
        : getAssemblyFlowPost({ flowId, responseId, asEmbed }),
    staleTime: Infinity,
    ...options,
  };
}

export function getCarouselCardsQuery({
  cardId,
  enabled = false,
}: {
  cardId: string;
  enabled: boolean;
}): UseQueryOptions<FlowPostResponse[]> {
  return {
    queryKey: ['carouselCards', cardId],
    queryFn: async () => {
      const response = await assemblyAPI.get(
        APIEndpoints.getCarouselListByCardId(cardId)
      );
      return response.data;
    },
    enabled,
  };
}

export function getChallengeCarouselCardsQuery({
  cardId,
  enabled = false,
}: {
  cardId: string;
  enabled: boolean;
}): UseQueryOptions<ChallengeCardDetails[]> {
  return {
    queryKey: ['carouselCards', 'challenge'],
    queryFn: async () => {
      const response = await assemblyAPI.get(
        APIEndpoints.getChallengeCarouselListByCardId(cardId)
      );
      return response.data;
    },
    enabled,
  };
}

export function getPostRepliesQuery({
  flowId,
  responseId,
  enabled = true,
}: {
  flowId: string;
  responseId: string;
  enabled: boolean;
}): UseQueryOptions<RepliesResponse> {
  return {
    queryKey: ['assemblyFlowPostReplies', flowId, responseId],
    queryFn: async () => {
      const response = await assemblyAPI.get<RepliesResponse>(
        APIEndpoints.assemblyPostReplies({ flowId, responseId })
      );
      return response.data;
    },
    staleTime: Infinity,
    enabled,
  };
}

export function putAssemblyFlowPostReactionsQuery({
  flowId,
  responseId,
  action,
}: {
  flowId: string;
  responseId: string;
  action: string;
}): UseQueryOptions {
  return {
    queryKey: ['assemblyFlowPostReaction'],
    queryFn: async () => {
      const response = await assemblyAPI.put(
        APIEndpoints.assemblyPostReaction({ flowId, responseId, action })
      );
      return response.data;
    },
  };
}

type CarouselCardsMarkAsSeenPayload = {
  cardId: string;
  viewedEntity: {
    id: string;
    type: string;
  };
};

export function useCarouselCardMarkAsSeenQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: CarouselCardsMarkAsSeenPayload) => {
      const { cardId } = payload;
      const payloadForAPI = {
        viewedEntity: {
          ...payload.viewedEntity,
        },
      };
      return assemblyAPI.put(APIEndpoints.updateCarouselPostByCardId(cardId), {
        ...payloadForAPI,
      });
    },
    onMutate: async (payload: CarouselCardsMarkAsSeenPayload) => {
      if (payload.viewedEntity.type === 'CHALLENGE') {
        const queryKeyForChallengeCarousal = ['carouselCards', 'challenge'];
        const importantQueryKey = ['importantCards'];
        const latestImportantSectionData =
          queryClient.getQueryData<
            InfiniteData<ImportantActivitiesApiResponse>
          >(importantQueryKey);
        const carouselChallengeCards: ChallengeCardDetails[] | undefined =
          queryClient.getQueryData(queryKeyForChallengeCarousal);

        if (carouselChallengeCards) {
          // important section count update on title
          const updatedActivities = produce(
            latestImportantSectionData,
            (draft) => {
              draft?.pages.forEach((page) => {
                page.data.data = page.data.data.map((card) => {
                  if (
                    card.type === ActivityCardTypeEnum.ChallengeLaunchActivity
                  ) {
                    card.entity.countUnreadChallenge--;
                  }
                  return card;
                });
              });
            }
          );
          queryClient.setQueryData(importantQueryKey, updatedActivities);

          // removing card from carousal
          const previousChallengeCarousalData = carouselChallengeCards;
          const updatedResult = carouselChallengeCards.filter(
            (result) => result.challengeId !== payload.viewedEntity.id
          );
          queryClient.setQueryData(queryKeyForChallengeCarousal, updatedResult);

          return { previousChallengeCarousalData, latestImportantSectionData };
        }
      } else {
        const queryKey = ['carouselCards', payload.cardId];
        const carouselCards: FlowPostResponse[] | undefined =
          queryClient.getQueryData(queryKey);
        if (carouselCards) {
          const previousData = carouselCards;
          const updatedResult = carouselCards.filter(
            (result) => result.responseId !== payload.viewedEntity.id
          );
          queryClient.setQueryData(queryKey, updatedResult);

          return { previousData };
        }
      }
    },
    onError: (err: unknown, payload, context) => {
      const queryKey = ['carouselCards', payload.cardId];
      queryClient.setQueryData(queryKey, context?.previousData);
      const queryKeyForChallenge = ['carouselCards', 'challenge'];
      queryClient.setQueryData(
        queryKeyForChallenge,
        context?.previousChallengeCarousalData
      );
      queryClient.setQueryData(
        ['importantCards'],
        context?.latestImportantSectionData
      );
    },
  });
}

export type ReactionMember = {
  name: string;
  memberID: string;
};

export type FlowPostReactionMutationPayload = {
  payload: ReactionEmoji;
  contentID: string;
  action: UpdateReactionAction;
  userData: ReactionMember;
  flowId: string;
  responseId: string;
};

export enum UpdateReactionAction {
  Set = 'set',
  Unset = 'unset',
}
