import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useMutation } from '@tanstack/react-query';

export const useInviteMembers = () => {
  return useMutation({
    mutationKey: ['inviteMembers'],
    mutationFn: (members: string[]) =>
      assemblyAPI.post(APIEndpoints.inviteMembers, {
        data: members.map((member) => ({
          email: member,
        })),
      }),
  });
};
