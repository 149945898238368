import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQuery } from '@tanstack/react-query';

import type { FlowBlockProps } from '../types';

export const useGetFlowBlocksQuery = (flowId: string, enabled: boolean) => {
  return useQuery({
    enabled,
    queryKey: ['flowBlocks', flowId],
    queryFn: async () => {
      const response = await assemblyAPI.get<{
        data: FlowBlockProps[];
      }>(APIEndpoints.flowBlocks, {
        params: {
          flowId,
        },
      });

      return response.data.data;
    },
    retry: false,
  });
};
