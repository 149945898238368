import {
  logger,
  routeConstants,
  useMobileLoaded,
} from '@assembly-web/services';
import { ErrorPage } from '@assembly-web/ui';
import { isAxiosError } from 'axios';
import {
  isRouteErrorResponse,
  Navigate,
  useMatches,
  useRouteError,
} from 'react-router-dom';

import { trackDiscoverError } from './modules/discover/services/analytics';

export function RootErrorBoundary() {
  const error = useRouteError();
  const unknownPathDetails = useMatches().find((x) => x.params['*']);
  const isErrorObject = error instanceof Error;
  const isRouteError = isRouteErrorResponse(error);

  useMobileLoaded();

  if (isAxiosError(error) && error.response?.status === 401) {
    const currentPath = window.location.pathname;

    const isNewExperiencePath = routeConstants.newExperiencePaths.some((path) =>
      currentPath.includes(path)
    );

    if (isNewExperiencePath) {
      return <Navigate to="/workspaces" />;
    }
  }

  if (import.meta.env.DEV) {
    console.log('route error', error);
    console.log('loading fallback path', { unknownPathDetails });
  }

  let serializedError = 'unknown';

  if (isRouteError) {
    serializedError = `${error.status} ${error.statusText}`;
  } else if (isErrorObject) {
    serializedError = error.message;
  } else if (typeof error === 'string') {
    serializedError = error;
  }

  if (
    !isRouteError &&
    serializedError.includes(`not a valid JavaScript MIME type.`)
  ) {
    window.location.reload();
  }

  logger.error(
    'Loading error page from RootErrorBoundary triggered',
    {
      isRouteErrorResponse: isRouteError,
      routeError: serializedError,
      unknownPathDetails,
    },
    isErrorObject ? error : undefined
  );

  if (window.location.pathname.includes('/discover')) {
    trackDiscoverError({ action: 'pageLoaded' });
  }

  return <ErrorPage />;
}
