import { IconButton, Tooltip } from '@assembly-web/ui';
import type { ReactElement } from 'react';

export const ToolbarButton = ({
  children,
  label,
  onClick,
  tooltipText,
}: {
  children: ReactElement;
  label: string;
  onClick?: () => void;
  tooltipText: string;
}) => (
  <Tooltip contentClassName="z-[12]" tooltipText={tooltipText}>
    <IconButton
      aria-label={label}
      className="group bg-transparent focus:bg-[--foreground-hover] focus:ring-primary-4 focus:ring-offset-gray-9 enabled:hover:bg-[--foreground-hover]"
      onClick={onClick}
      size="xSmall"
      variation="tertiaryLite"
    >
      {children}
    </IconButton>
  </Tooltip>
);
