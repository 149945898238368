import { createContext, useContext } from 'react';

const BlockIdContext = createContext<string | null>(null);

export const BlockIdProvider = BlockIdContext.Provider;

export const useBlockIdContext = () => {
  const context = useContext(BlockIdContext);

  if (!context) {
    throw new Error('useBlockIdContext must be used within a BlockIdProvider');
  }

  return context;
};
