import { zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type SecondaryFiltersStore = {
  excludedPostedByMembers: string[];
  updateExcludedPostedByMembers: (excludedMembers: string[]) => void;
};

export const secondaryFiltersStore = create<SecondaryFiltersStore>()(
  persist(
    (set) => ({
      excludedPostedByMembers: [],
      updateExcludedPostedByMembers: (excludedMembers) => {
        set(() => ({ excludedPostedByMembers: excludedMembers }));
      },
    }),
    {
      name: 'secondary-filters-storage',
      storage: createJSONStorage(() => zustandLocalStorage),
    }
  )
);
