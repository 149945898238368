import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQuery } from '@tanstack/react-query';

import type { FlowRespondentProps } from '../types';

export const useGetFlowRespondentsQuery = (
  flowId: string,
  enabled?: boolean
) => {
  return useQuery({
    enabled,
    queryKey: ['postedBy', flowId],
    queryFn: async () => {
      const response = await assemblyAPI.get<{
        data: FlowRespondentProps[];
      }>(APIEndpoints.postedBy, {
        params: {
          flowId,
        },
      });

      return response.data.data;
    },
    retry: false,
  });
};
