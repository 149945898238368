import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

type AssemblyWorkspaceDepartmentsAPIResponse = {
  data: string[];
};

function getAllAssemblyWorkspaceDepartments(
  enabled: boolean
): UseQueryOptions<AssemblyWorkspaceDepartmentsAPIResponse> {
  return {
    enabled,
    queryKey: ['assemblyWorkspaceDepartments'],
    queryFn: async () => {
      const response =
        await assemblyAPI.get<AssemblyWorkspaceDepartmentsAPIResponse>(
          APIEndpoints.getDepartments
        );
      return response.data;
    },
  };
}

export function useGetAllAssemblyWorkspaceDepartments(
  enabled: boolean
): UseQueryResult<AssemblyWorkspaceDepartmentsAPIResponse> {
  return useQuery(getAllAssemblyWorkspaceDepartments(enabled));
}
