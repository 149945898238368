import { defineMessages, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../../../../stores/useMultiDrawerStore';
import type { DoraResponseMetadata } from '../../../../components/Drawers/AskDoraDrawer/hooks/useDoraEventSource';
import { useAskDoraStore } from '../../../../stores/useAskDoraStore';

const messages = defineMessages({
  drawerDefaultTitle: {
    defaultMessage: 'Ask DoraAI',
    id: '71+qWd',
  },
  drawerTitle: {
    defaultMessage: `Ask DoraAI: ''{question}'`,
    id: 'l56wrd',
  },
});

export function useCreateAskDoraDrawer() {
  const { formatMessage } = useIntl();
  const resetStore = useAskDoraStore((store) => store.resetStore);
  const addQuestionAnswerBlock = useAskDoraStore(
    (store) => store.addQuestionAnswerBlock
  );
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);

  const createAskDoraDrawer = ({
    question,
    metadata,
  }: {
    question?: string;
    metadata?: DoraResponseMetadata;
  }) => {
    resetStore();
    if (question) {
      addQuestionAnswerBlock({ question });
    }
    createDrawer({
      type: 'doraQ',
      title: question
        ? formatMessage(messages.drawerTitle, {
            question,
          })
        : formatMessage(messages.drawerDefaultTitle),
      data: { metadata, query: question ?? '' },
    });
  };

  return createAskDoraDrawer;
}
