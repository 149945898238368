import { HorizontalRule } from '@assembly-web/ui';
import ContentLoader from 'react-content-loader';

export function PostCardLoader() {
  return (
    <div className="mt-1 max-w-3xl rounded-lg border border-gray-5 bg-gray-1 p-4">
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 300 56"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx="12" cy="12" r="8" />
        <rect x="24" y="4" rx="4" ry="4" width="160" height="8" />
        <rect x="24" y="16" rx="2" ry="2" width="80" height="4" />
        <rect x="4" y="32" rx="2" ry="2" width="160" height="4" />
        <rect x="4" y="40" rx="2" ry="2" width="160" height="4" />
      </ContentLoader>
      <HorizontalRule />
      <ContentLoader
        speed={2}
        width="100%"
        height="100%"
        viewBox="0 0 300 24"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx="12" cy="12" r="8" />
        <rect x="24" y="6" rx="2" ry="2" width="160" height="12" />
      </ContentLoader>
    </div>
  );
}
