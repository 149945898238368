import { PopoutDrawerTemplate } from '../PopoutDrawerTemplate';
import type { PostDrawerProps } from '../types';
import { PostDrawerBody } from './PostDrawerBody';

export const PostDrawer = (props: PostDrawerProps) => {
  return (
    <PopoutDrawerTemplate {...props}>
      <PostDrawerBody {...props} />
    </PopoutDrawerTemplate>
  );
};
