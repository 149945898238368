// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactElement, RefObject } from 'react';
import type { MessageDescriptor } from 'react-intl';

import { askDoraConfig } from './AskDoraDrawer/configuration';
import { challengesDrawerConfiguration } from './ChallengesDrawer/configuration';
import { doraChatConfig } from './DoraChatDrawer/configuration';
import { flowCreationConfig } from './FlowCreationDrawer/configuration';
import { flowSummaryConfig } from './FlowSummaryDrawer/configuration';
import { postsAndRepliesConfig } from './PostDrawer/configuration';
import type { BaseDrawer, DrawerType } from './types';

// DOCS: https://joinassembly.atlassian.net/wiki/spaces/FE/pages/2058190856/How+to+Integrate+with+Drawer+Dock
export type DrawerConfig<
  TDrawer extends BaseDrawer & { type: DrawerType },
  TData,
> = {
  drawer: (props: TDrawer) => ReactElement | undefined;
  body: (
    props: TDrawer & { drawerRef?: RefObject<HTMLDivElement> }
  ) => ReactElement | undefined;
  header: (props: TDrawer) => ReactElement | undefined;
  overflowCard: (
    props: TDrawer & {
      onClick: () => void;
      onClose: () => void;
    }
  ) => ReactElement | undefined;
  allowMultipleInstance: boolean;
  resetOnCreate?: boolean;
  shouldConfirmOnClose?: (props: TDrawer) => boolean;
  confirmOnCloseCopy?: {
    title?: MessageDescriptor;
    description?: MessageDescriptor;
    cta?: MessageDescriptor;
    label?: MessageDescriptor;
  };
  getId?: (props: TData) => string | undefined;
  onCollapse?: (props: TDrawer) => void;
  onClose?: (props: TDrawer) => void;
  onOpenChange?: (drawer: TDrawer, isNewOpen: boolean) => void;
};

export const config = {
  doraChat: { ...doraChatConfig },
  doraQ: { ...askDoraConfig },
  flowCreation: { ...flowCreationConfig },
  flowSummary: { ...flowSummaryConfig },
  flow_responses: { ...doraChatConfig },
  postsAndReplies: { ...postsAndRepliesConfig },
  challenges: { ...challengesDrawerConfiguration },
} satisfies {
  [key in DrawerType]: DrawerConfig<any, any>;
};
