import type { Collection, ItemToAdd, LinkToEdit } from '@assembly-web/services';
import { createContext, type ReactNode, useState } from 'react';

import { useGetSearchResultPayload } from '../../../hooks/useGetSearchResultsPayload';
import { AddExternalLinkModal } from '../components/modals/AddExternalLinkModal';
import { AddToCollectionModal } from '../components/modals/AddToCollectionModal';
import { AddToPinnedCollectionModal } from '../components/modals/AddToPinnedCollectionModal';
import { CreateCollectionModal } from '../components/modals/CreateCollectionModal';
import { DeleteCollectionModal } from '../components/modals/DeleteCollectionModal';
import { LinksWarningModal } from '../components/modals/LinksWarningModal';
import { PinExistingCollectionModal } from '../components/modals/PinExistingCollectionModal';
import { UnpinCollectionModal } from '../components/modals/UnpinCollectionModal';
import { useNavData } from '../hooks/nav/useNavData';
import { useGetCollectionsQuery } from '../hooks/useGetCollectionsQuery';

const Noop = () => null;

export const CollectionModalsContext = createContext<{
  collectionModals: JSX.Element;
  openPinExistingCollectionModal: () => void;
  openAllLinks: (collection: Collection) => void;
  openUnpinModal: (collection: Collection) => void;
  openDeleteModal: (collection: Collection) => void;
  openAddToCollectionModal: (item: ItemToAdd) => void;
  openAddToPinnedCollectionModal: (item: ItemToAdd) => void;
  openCreateCollectionModal: (collection?: Collection) => void;
  openCreatePinnedCollectionModal: (collection?: Collection) => void;
  openAddExternalLinkModal: (collection: Collection, link?: LinkToEdit) => void;
}>({
  collectionModals: <Noop />,
  openAllLinks: () => {},
  openUnpinModal: () => {},
  openDeleteModal: () => {},
  openAddToCollectionModal: () => {},
  openAddExternalLinkModal: () => {},
  openCreateCollectionModal: () => {},
  openAddToPinnedCollectionModal: () => {},
  openPinExistingCollectionModal: () => {},
  openCreatePinnedCollectionModal: () => {},
});

export const CollectionModalsProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const payload = useGetSearchResultPayload();
  const { searchTerm, selectedFilters } = payload;
  const filter = selectedFilters[0];

  const [collection, setCollection] = useState<Collection | null>(null);
  const [itemToAdd, setItemToAdd] = useState<ItemToAdd | null>(null);
  const [linkToEdit, setLinkToEdit] = useState<LinkToEdit | null>(null);
  const [isPinnedDefault, setIsPinnedDefault] = useState<boolean>(false);
  const [isAddToCollectionModalOpen, setIsAddToCollectionModalOpen] =
    useState<boolean>(false);
  const [isDeleteCollectionModalOpen, setIsDeleteCollectionModalOpen] =
    useState<boolean>(false);
  const [isCreateCollectionModalOpen, setIsCreateCollectionModalOpen] =
    useState<boolean>(false);
  const [isLinksWarningModalOpen, setIsLinksWarningModalOpen] =
    useState<boolean>(false);
  const [isAddExternalLinkModalOpen, setIsAddExternalLinkModalOpen] =
    useState<boolean>(false);
  const [isUnpinModalOpen, setIsUnpinModalOpen] = useState<boolean>(false);
  const [isPinToCollectionModalOpen, setIsPinToCollectionModalOpen] =
    useState<boolean>(false);
  const [
    isPinExistingCollectionModalOpen,
    setIsPinExistingCollectionModalOpen,
  ] = useState<boolean>(false);

  const { data: collectionResponse, refetch: refetchCollections } =
    useGetCollectionsQuery({
      enabled:
        isUnpinModalOpen ||
        isAddExternalLinkModalOpen ||
        isAddToCollectionModalOpen ||
        isPinToCollectionModalOpen ||
        isCreateCollectionModalOpen ||
        isDeleteCollectionModalOpen ||
        isPinExistingCollectionModalOpen,
    });

  const { totalNoOfPinnedCollections } = useNavData();

  const openDeleteModal = (collection: Collection) => {
    setIsDeleteCollectionModalOpen(true);
    setCollection(collection);
  };

  let numLinks = 20; // TODO: update with real value
  const openAllLinks = (collection: Collection) => {
    if (numLinks >= 15) {
      setIsLinksWarningModalOpen(true);
      setCollection(collection);
    } else {
      // TODO: open link items when available
    }
  };

  const openAddToCollectionModal = async (item: ItemToAdd) => {
    setItemToAdd(item);
    const collectionsResponse = await refetchCollections();
    const collections = collectionsResponse.data?.data ?? [];
    if (collections.length > 0) {
      setIsAddToCollectionModalOpen(true);
    } else {
      setIsCreateCollectionModalOpen(true);
    }
  };

  const openAddExternalLinkModal = (
    collection: Collection,
    link?: LinkToEdit
  ) => {
    setIsAddExternalLinkModalOpen(true);
    setCollection(collection);
    if (link) {
      setLinkToEdit(link);
    }
  };

  const openUnpinModal = (collection: Collection) => {
    setIsUnpinModalOpen(true);
    setCollection(collection);
  };

  const openAddToPinnedCollectionModal = (item: ItemToAdd) => {
    setItemToAdd(item);
    if (totalNoOfPinnedCollections > 0) {
      setIsPinToCollectionModalOpen(true);
    } else {
      setIsAddToCollectionModalOpen(true);
    }
    setIsPinnedDefault(true);
  };

  const openPinExistingCollectionModal = () => {
    if ((collectionResponse?.data ?? []).length === 0) {
      openCreatePinnedCollectionModal();
    } else {
      setIsPinExistingCollectionModalOpen(true);
    }
  };

  const resetModal = () => {
    setIsAddExternalLinkModalOpen(false);
    setIsAddToCollectionModalOpen(false);
    setIsDeleteCollectionModalOpen(false);
    setIsCreateCollectionModalOpen(false);
    setIsLinksWarningModalOpen(false);
    setIsUnpinModalOpen(false);
    setIsPinToCollectionModalOpen(false);
    setIsPinnedDefault(false);
    setIsPinExistingCollectionModalOpen(false);
    setTimeout(() => {
      setCollection(null); // set after animation
      setItemToAdd(null);
      setLinkToEdit(null);
    }, 300);
  };

  const handleOpenAllLinks = () => {
    // TODO: handle when collection items available
    // https://joinassembly.atlassian.net/browse/APP-10420
    resetModal();
  };

  const openCreateCollectionModal = (collection?: Collection) => {
    setIsCreateCollectionModalOpen(true);
    setIsAddToCollectionModalOpen(false);
    // for editing
    if (collection) {
      setCollection(collection);
    }
  };

  const openCreatePinnedCollectionModal = (collection?: Collection) => {
    setIsCreateCollectionModalOpen(true);
    setIsPinToCollectionModalOpen(false);
    setIsPinExistingCollectionModalOpen(false);
    if (collection) {
      setCollection(collection);
    } else {
      setIsPinnedDefault(true);
    }
  };

  const deleteModal = (
    <DeleteCollectionModal
      collectionId={collection?.collectionId ?? ''}
      filter={filter}
      name={collection?.name ?? ''}
      numCollections={collectionResponse?.data.length ?? 0}
      onClose={resetModal}
      open={isDeleteCollectionModalOpen}
      query={payload}
    />
  );

  const addToCollectionModal = (
    <AddToCollectionModal
      filter={filter}
      itemToAdd={itemToAdd}
      onClose={resetModal}
      onCreateCollection={openCreateCollectionModal}
      open={isAddToCollectionModalOpen}
      searchTerm={searchTerm}
      pinCollection={isPinnedDefault}
    />
  );

  const createCollectionModal = (
    <CreateCollectionModal
      collection={collection}
      filter={filter}
      isPinnedDefault={isPinnedDefault}
      itemToAdd={itemToAdd}
      numCollections={collectionResponse?.data.length}
      onClose={resetModal}
      open={isCreateCollectionModalOpen}
      query={payload}
    />
  );

  const linksWarningModal = (
    <LinksWarningModal
      numLinks={numLinks}
      title={collection?.name ?? ''}
      open={isLinksWarningModalOpen}
      onClose={resetModal}
      onSubmit={handleOpenAllLinks}
    />
  );

  const addExternalLinkModal = (
    <AddExternalLinkModal
      open={isAddExternalLinkModalOpen}
      onClose={resetModal}
      collection={collection}
      linkToEdit={linkToEdit}
      filter={filter}
    />
  );

  const unpinModal = (
    <UnpinCollectionModal
      open={isUnpinModalOpen}
      onClose={resetModal}
      collectionId={collection?.collectionId ?? ''}
      name={collection?.name ?? ''}
    />
  );

  const addToPinnedCollectionModal = (
    <AddToPinnedCollectionModal
      filter={filter}
      itemToAdd={itemToAdd}
      onClose={resetModal}
      onCreateCollection={openCreatePinnedCollectionModal}
      open={isPinToCollectionModalOpen}
      searchTerm={searchTerm}
    />
  );

  const pinExistingCollection = (
    <PinExistingCollectionModal
      filter={filter}
      onClose={resetModal}
      onCreateCollection={openCreatePinnedCollectionModal}
      open={isPinExistingCollectionModalOpen}
      searchTerm={searchTerm}
    />
  );

  const collectionModals = (
    <>
      {createCollectionModal}
      {addToCollectionModal}
      {deleteModal}
      {linksWarningModal}
      {addExternalLinkModal}
      {unpinModal}
      {addToPinnedCollectionModal}
      {pinExistingCollection}
    </>
  );

  return (
    <CollectionModalsContext.Provider
      value={{
        collectionModals,
        openAddToCollectionModal,
        openAddExternalLinkModal,
        openAllLinks,
        openCreateCollectionModal,
        openCreatePinnedCollectionModal,
        openDeleteModal,
        openUnpinModal,
        openAddToPinnedCollectionModal,
        openPinExistingCollectionModal,
      }}
    >
      {children}
    </CollectionModalsContext.Provider>
  );
};
