import { browserUtils, zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { trackNavAction } from '../../services/analytics';

type NavTriggerPosition = 'left' | 'right';

type NavContainerState = {
  navTriggerPosition: NavTriggerPosition;
  isOpen: boolean;
  isPinned: boolean;
  isActionSectionExpanded: boolean;

  showNav: () => void;
  hideNav: () => void;

  pinNav: () => void;
  unpinNav: () => void;

  updateActionSection: (isExpanded: boolean) => void;
  updateNavTriggerPosition: (position: NavTriggerPosition) => void;
};

const navStorageKey = 'nav-store';

export function resetNavStore() {
  localStorage.removeItem(navStorageKey);
  useNavStore.persist.rehydrate();
}

// NOTE: multi-assembly isn't handled here
export const useNavStore = create<NavContainerState>()(
  persist(
    immer<NavContainerState>((set) => ({
      navTriggerPosition: 'left',
      isOpen: !browserUtils.isMobile,
      isPinned: true,
      isActionSectionExpanded: true,

      showNav() {
        set((store) => {
          store.isOpen = true;
        });
      },

      hideNav() {
        set((store) => {
          store.isOpen = false;
        });
      },

      pinNav() {
        trackNavAction('navPinned');
        set((store) => {
          store.isPinned = true;
        });
      },

      unpinNav() {
        trackNavAction('navUnpinned');
        set((store) => {
          store.isPinned = false;
        });
      },

      updateActionSection(newState) {
        trackNavAction(
          newState ? 'actionSectionExpanded' : 'actionSectionCollapsed'
        );

        set((store) => {
          store.isActionSectionExpanded = newState;
        });
      },

      updateNavTriggerPosition(position: NavTriggerPosition) {
        set((store) => {
          store.navTriggerPosition = position;
        });
      },
    })),
    {
      name: navStorageKey,
      storage: createJSONStorage(() => zustandLocalStorage),
      version: 2,
    }
  )
);
