import { fileIcons } from '@assembly-web/assets';
import type { FileType } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { CalendarIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { TextButton } from '../../../../../components/TextButton';
import { SearchFilter } from './SearchFilter';

const messages = defineMessages({
  header: {
    defaultMessage: 'How DoraAI finds information',
    id: 'gJ4UTp',
  },
  subText: {
    defaultMessage:
      'DoraAI interprets your question and applies filters to find sources with the right answer. Dora AI applied:',
    id: '4tKeEU',
  },
  fileLabel: {
    defaultMessage: 'File type:',
    id: 'WSz8Ai',
  },
  dateRangeLabel: {
    defaultMessage: 'Date range:',
    id: 'rNvlCF',
  },
  numFileTypes: {
    defaultMessage: '{num} file types',
    id: 'hhrajr',
  },
  afterDate: {
    defaultMessage: 'After {date}',
    id: 'Bl8xZP',
  },
  beforeDate: {
    defaultMessage: 'Before {date}',
    id: 'U5sjCv',
  },
});

export const InfoTooltip = (props: {
  fileTypes?: FileType[];
  dateRange?: {
    gte?: string;
    lte?: string;
  };
  onEditFilters?: () => void;
}) => {
  const { fileTypes = [], dateRange, onEditFilters } = props;
  const { formatMessage } = useIntl();

  function getDateRangeString(dateRange: { gte?: string; lte?: string }) {
    const firstDate = dateRange.gte
      ? dayjs(dateRange.gte).format('MM-DD-YYYY')
      : null;
    const lastDate = dateRange.lte
      ? dayjs(dateRange.lte).format('MM-DD-YYYY')
      : null;

    if (firstDate && !lastDate) {
      return formatMessage(messages.afterDate, { date: firstDate });
    }
    if (!firstDate && lastDate) {
      return formatMessage(messages.beforeDate, { date: lastDate });
    }
    if (firstDate === lastDate) {
      return firstDate ?? '';
    }

    return `${firstDate} - ${lastDate}`;
  }

  const hasMultipleFileTypes = fileTypes.length > 1;

  return (
    <>
      <div className="px-1 pb-2">
        <TextStyle variant="sm-medium">
          {formatMessage(messages.header)}
        </TextStyle>
        <TextStyle variant="xs-regular">
          {formatMessage(messages.subText)}
        </TextStyle>
      </div>
      <div className="rounded-lg border border-gray-5 bg-gray-1 px-3 py-2">
        {Boolean(fileTypes.length) && (
          <SearchFilter
            label={formatMessage(messages.fileLabel)}
            icon={
              hasMultipleFileTypes ? undefined : (
                <img className="h-4 w-4" src={fileIcons[fileTypes[0]]} alt="" />
              )
            }
            filterResult={
              hasMultipleFileTypes
                ? formatMessage(messages.numFileTypes, {
                    num: fileTypes.length,
                  })
                : fileTypes[0].charAt(0).toLocaleUpperCase() +
                  fileTypes[0].slice(1)
            }
          />
        )}
        {dateRange ? (
          <SearchFilter
            label={formatMessage(messages.dateRangeLabel)}
            icon={<CalendarIcon className="h-4 w-4 text-gray-9" />}
            filterResult={getDateRangeString(dateRange)}
          />
        ) : null}
        {onEditFilters ? (
          <TextStyle variant="xs-regular" className="text-gray-8">
            <FormattedMessage
              defaultMessage="Did DoraAI get it wrong? <button>Edit filters</button>"
              id="i7fJt4"
              values={{
                button: (text) => (
                  <TextButton
                    className="!text-gray-8"
                    onClick={onEditFilters}
                    underline
                  >
                    {text}
                  </TextButton>
                ),
              }}
            />
          </TextStyle>
        ) : null}
      </div>
    </>
  );
};
