import { classNames, Select } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';

const dateLabels = defineMessages({
  year: { defaultMessage: 'Year', id: 'IFo1oo' },
  month: { defaultMessage: 'Month', id: 'TBovrx' },
  day: { defaultMessage: 'Day', id: '7pXrnq' },
});

const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'long' });

export const months = [...Array(12).keys()].map((no) =>
  monthFormatter.format(new Date(2022, no))
);

type DateSelectOption = {
  value: string | number;
  text: string;
};

type UserDetailsPageDateSelectorProps = {
  label: string;
  namePrefix: string;
  helpText?: ReactNode;
  defaultValue?: { day?: number; month?: number; year?: number };
  showYear?: boolean;
  isInvalid?: boolean;
};

export function UserDetailsPageDateSelector(
  props: UserDetailsPageDateSelectorProps
) {
  const { formatMessage } = useIntl();

  const monthOptions = [
    { value: '', text: formatMessage(dateLabels.month) },
    ...months.map((month) => ({ value: month, text: month })),
  ];

  const generateDays = useCallback(
    (no: number) => [
      { value: '', text: formatMessage(dateLabels.day) },
      ...[...Array(no).keys()]
        .map((x) => x + 1)
        .map((no) => ({ value: no, text: no })),
    ],
    [formatMessage]
  );

  const defaultDays = generateDays(31);

  const [dates, setDates] = useState(defaultDays);

  const updateDates = useCallback(
    function updateDatesForMonth(month: string) {
      if (!month) {
        setDates(defaultDays);
      } else {
        setDates(
          generateDays(new Date(2009, months.indexOf(month) + 1, 0).getDate())
        );
      }
    },
    [defaultDays, generateDays]
  );

  const currentYear = new Date().getFullYear();
  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const years = [
    { value: '', text: formatMessage(dateLabels.year) },
    ...range(currentYear + 1, currentYear - 70, -1).map((x) => ({
      value: x,
      text: x,
    })),
  ];

  const [date, setDate] = useImmer({
    day: '',
    month: '',
    year: '',
  });

  const dateValue = props.showYear
    ? `${date.month}-${date.day}-${date.year}`
    : `${date.month}-${date.day}`;

  const defaultMonth = monthOptions[props.defaultValue?.month ?? 0];
  const defaultDay = dates[props.defaultValue?.day ?? 0];
  const defaultYear = years.find((x) => x.value === props.defaultValue?.year);

  useEffect(
    function setDefaults() {
      setDate((state) => {
        state.day = defaultDay.value.toString();
        state.month = defaultMonth.value;
        state.year = defaultYear?.value.toString() ?? '';
      });
    },
    [defaultDay.value, defaultMonth.value, defaultYear?.value, setDate]
  );

  return (
    <div>
      <input name={props.namePrefix} hidden value={dateValue} readOnly />
      <label className={classNames({ 'text-error-6': props.isInvalid })}>
        {props.label}

        <div className="grid grid-cols-1 sm:grid-cols-6">
          <Select
            name={`${props.namePrefix}Month`}
            label={formatMessage(dateLabels.month)}
            classNames={classNames({
              'sm:col-span-3': !props.showYear,
              'sm:col-span-2': props.showYear,
            })}
            triggerClassNames="rounded-r-none"
            options={monthOptions}
            selected={defaultMonth}
            getText={(option) => (option as DateSelectOption).text}
            getValue={(option) => (option as DateSelectOption).value}
            onChange={(option) => {
              {
                const month = (option as DateSelectOption).value.toString();
                updateDates(month);
                setDate((state) => {
                  state.month = month;
                });
              }
            }}
            isInvalid={props.isInvalid}
            hiddenLabel
          />
          <Select
            name={`${props.namePrefix}Day`}
            label={formatMessage(dateLabels.day)}
            classNames={classNames({
              'sm:col-span-3': !props.showYear,
              'sm:col-span-2': props.showYear,
            })}
            triggerClassNames={classNames({
              'rounded-l-none': !props.showYear,
              'rounded-none': props.showYear,
            })}
            options={dates}
            selected={defaultDay}
            getText={(option) => (option as DateSelectOption).text}
            getValue={(option) => (option as DateSelectOption).value}
            onChange={(option) => {
              const day = (option as DateSelectOption).value.toString();
              updateDates(day);
              setDate((state) => {
                state.day = day;
              });
            }}
            isInvalid={props.isInvalid}
            hiddenLabel
          />
          {Boolean(props.showYear) && (
            <Select
              name={`${props.namePrefix}Year`}
              label={formatMessage(dateLabels.year)}
              classNames={classNames({
                'sm:col-span-3': !props.showYear,
                'sm:col-span-2': props.showYear,
              })}
              triggerClassNames="rounded-l-none"
              options={years}
              selected={defaultYear}
              getText={(option) => (option as DateSelectOption).text}
              getValue={(option) => (option as DateSelectOption).value}
              onChange={(option) => {
                const year = (option as DateSelectOption).value.toString();
                updateDates(year);
                setDate((state) => {
                  state.year = year;
                });
              }}
              isInvalid={props.isInvalid}
              hiddenLabel
            />
          )}
        </div>
      </label>

      {Boolean(props.helpText) && (
        <span
          className={classNames('text-xs text-gray-9', {
            'text-error-6': props.isInvalid,
          })}
        >
          {props.helpText}
        </span>
      )}
    </div>
  );
}
