import {
  CriteriaRuleType,
  MemberRole,
  MemberState,
  Operator,
  PermissionType,
  type UserDetails,
} from '@assembly-web/services';
import type {
  CriteriaItemProps,
  ShareModalContentProps,
} from '@assembly-web/ui';
import {
  Button,
  ShareConfirmationModal,
  ShareModalContent,
  TextStyle,
  useToastStore,
} from '@assembly-web/ui';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';

import {
  generateSaveCriteriaPayloadFromRules,
  usePreviewShareChallengeRules,
} from '../../hooks/shareChallenge/usePreviewShareChallengeRules';
import { getMemberPermissionOptions } from '../../hooks/shareChallenge/useShareChallengesData';
import {
  getCriteriaId,
  useShareSheet,
} from '../../hooks/shareModal/useShareSheet';
import { useShareSheetCriteriaRules } from '../../hooks/shareModal/useShareSheetCriteriaRules';
import { useShareSheetMemberSearch } from '../../hooks/shareModal/useShareSheetMemberSearch';
import { trackSharesheetAction } from '../../services/analytics';
import type { SharingRulesType } from './ChallengeDefinition';

export const ShareChallengesMessageKey = 'OPEN_SHARE_CHALLENGES_MODAL';

const messages = defineMessages({
  challengeTitle: {
    defaultMessage: 'Choose who can claim and approve this challenge',
    id: '1k/9WZ',
  },
  confirmationModalTitle: {
    defaultMessage: 'Done Editing?',
    id: '1PH1ei',
  },
  confirmationModalBody: {
    defaultMessage:
      'You have unsaved changes. Before you go, choose whether you’d like to save or discard them.',
    id: 'jhcPUj',
  },
  discardChanges: {
    defaultMessage: 'Discard Changes',
    id: 'sLnDOb',
  },
  saveChanges: {
    defaultMessage: 'Save Changes',
    id: '3VI9mt',
  },
  successMessage: {
    defaultMessage: 'Updated permissions successfully',
    id: 'wZJxLk',
  },
  previewModalSubtitle: {
    defaultMessage:
      'See who is a participant and an approver for this challenge.',
    id: 'dvAlBX',
  },
  challengeNote: {
    defaultMessage: 'All administrators can approve any challenge',
    id: 'pv+44Q',
  },
  participantApproverLabel: {
    defaultMessage: 'Participant and Approver',
    id: 'nEy8I7',
  },
  addEmail: {
    defaultMessage: 'Add “{email}” to this challenge',
    id: 'JZlyBK',
  },
});

type Base = {
  currentUserDetails: UserDetails;
  updateSharingRulesPayload: (rules: SharingRulesType) => void;
  sharingRules?: SharingRulesType['sharingRules'];
  setChallengeSharingRules: (data: SharingRulesType) => void;
  challengeOwner: {
    memberID: string;
    memberState: MemberState;
  };
  setChallengeOwner: ({
    memberID,
    memberState,
  }: {
    memberID: string;
    memberState: MemberState;
  }) => void;
};

type ShareChallengeModalProps = {
  disabled?: boolean;
  tooltipText?: string;
} & (
  | (Base & { editChallenge: false })
  | (Base & {
      challengeId: string;
      editChallenge: true;
    })
);

export function ShareChallengeModal({
  currentUserDetails,
  updateSharingRulesPayload,
  sharingRules,
  challengeOwner,
  setChallengeSharingRules,
  setChallengeOwner,
  disabled,
  tooltipText,
}: ShareChallengeModalProps) {
  const [rules, setRules] = useImmer<CriteriaItemProps[]>([]);
  const [view, setView] = useState<'peopleWithAccess' | 'previewMembers'>(
    'peopleWithAccess'
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const { formatMessage } = useIntl();
  const { showSuccessToast } = useToastStore();

  const onTransferOwnership = (memberId?: string) => {
    setChallengeOwner({
      memberState: MemberState.Active,
      memberID: memberId ?? '',
    });
  };

  const closeShareChallengeModal = () => {
    setRules([]);
    onCustomRuleSelect(null);
    setView('peopleWithAccess');
  };

  const initialRules = sharingRules ?? {
    exclude: [],
    include: [
      {
        field: CriteriaRuleType.Member,
        values: [
          {
            operator: Operator.Is,
            perm: PermissionType.Approver,
            value: currentUserDetails.member.memberId,
            meta: {
              name: currentUserDetails.member.profile.fullName,
              email: currentUserDetails.member.email,
              state: currentUserDetails.member.state,
              memberId: currentUserDetails.member.memberId,
              image: '',
              role: [MemberRole.Admin],
            },
          },
        ],
      },
    ],
  };

  useShareSheetCriteriaRules({
    setRules,
    rules,
    owner: {
      ownerId: challengeOwner.memberID,
      ownerState: challengeOwner.memberState,
    },
    initialRules: initialRules,
    type: 'challenge',
  });

  const {
    customRules,
    customRuleOptions,
    onCustomRuleSelect,
    onCustomRulesAction,
    selectedCustomRule,
    onRulesPermissionChange,
    onRulesSelectionChange,
    handleOnEmailInvite,
  } = useShareSheet({
    enabled: view === 'peopleWithAccess',
    rules,
    setRules,
    type: 'challenge',
    customHandlePermissionChange: ({
      currentRule,
      draftRules,
      updatedPermission,
      ruleToUpdate,
      currentOwner,
    }) => {
      if (updatedPermission?.id === PermissionType.TransferOwnership) {
        // new owner
        ruleToUpdate.permissionOptions = getMemberPermissionOptions({
          formatMessage,
          isOwner: true,
        });
        if (ruleToUpdate.permission?.id === PermissionType.Participant) {
          ruleToUpdate.permission = {
            displayLabel: formatMessage(messages.participantApproverLabel),
            id: PermissionType.ParticipantApprover,
            label: formatMessage(messages.participantApproverLabel),
          };
        }

        if (ruleToUpdate.metaData) {
          ruleToUpdate.id = getCriteriaId({
            field: ruleToUpdate.metaData.field,
            value: ruleToUpdate.metaData.value,
            operator: ruleToUpdate.metaData.operator,
          });

          // old owner
          let oldDeactivatedOwnerIndex = -1;
          draftRules.map((rule, index) => {
            if (rule.metaData?.value === currentOwner?.memberID) {
              if (currentOwner?.memberState === MemberState.Deactivated) {
                oldDeactivatedOwnerIndex = index;
              } else {
                rule.permissionOptions = getMemberPermissionOptions({
                  formatMessage,
                  isOwner: false,
                  isAdmin: true,
                });
              }
            }
          });
          if (currentOwner?.memberState === MemberState.Deactivated) {
            draftRules.splice(oldDeactivatedOwnerIndex, 1);
          }
        }
        // callback to update createdBy
        onTransferOwnership(currentRule.metaData?.value);
        return true;
      }
      return false;
    },
  });

  const {
    isLoadingMembers,
    hasNextPage,
    isFetchingNextPage,
    searchTerm,
    members,
    onMemberSearch,
    onLoadMore,
    canInviteCurrentUser,
    hasUserInvitePermission,
  } = useShareSheetMemberSearch({
    enabled: view === 'peopleWithAccess',
  });

  useEffect(() => {
    const payload = generateSaveCriteriaPayloadFromRules(rules);
    const sharingRulesWithMeta = generateSaveCriteriaPayloadFromRules(
      rules,
      true
    );
    updateSharingRulesPayload(payload);
    setChallengeSharingRules(sharingRulesWithMeta);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules]);

  const {
    displayRules,
    previewFilterOptions,
    onSearchOrFilterList,
    isLoading: isLoadingPreview,
    isFetchingNextPage: isFetchingNextPageForPreview,
    hasNextPage: hasNextPageForPreview,
    fetchNextPage: fetchNextPageForPreview,
  } = usePreviewShareChallengeRules({
    rules,
    enabled: view === 'previewMembers',
    ownerId: challengeOwner.memberID,
    currentUserDetails,
  });

  const onDoneAction = () => {
    const payload = generateSaveCriteriaPayloadFromRules(rules);
    updateSharingRulesPayload(payload);
    closeShareChallengeModal();
    showSuccessToast(formatMessage(messages.successMessage));
  };

  const props: ShareModalContentProps =
    view === 'peopleWithAccess'
      ? {
          view: 'peopleWithAccess',
          disabled,
          tooltipText,
          customRuleSelectorProps: {
            includeManagerOption: true,
            customRuleOptions,
            selectedCustomRule,
            ruleOptions: customRuleOptions,
            onCustomRulesAction,
            onCustomRuleSelect,
          },
          rulesListProps: {
            hasNextPageForRules: false,
            onRulesPermissionChange,
            rules,
            ownerDetails: challengeOwner,
          },
          searchableRulesDropdownProps: {
            canInviteEmail: canInviteCurrentUser ?? false,
            customRules,
            handleOnEmailInvite,
            hasNextPageForMembers: hasNextPage ?? false,
            hasUserInvitePermission,
            members: canInviteCurrentUser ? [] : members,
            onLoadMore,
            onMemberSearch,
            onRulesSelectionChange,
            searchTerm,
            isLoadingMembers,
            isFetchingMoreMembers: isFetchingNextPage,
            addEmailLabel: messages.addEmail,
          },
          isLoading: false, //fix
          peopleWithAccessHeaderProps: {
            title: formatMessage(messages.challengeTitle),
            className: 'pt-2',
            subTitle: (
              <>
                <InformationCircleIcon className="h-4 w-4" />
                <TextStyle variant="xs-regular">
                  {formatMessage(messages.challengeNote)}
                </TextStyle>
              </>
            ),
            onPreviewButtonClick: () => {
              setView('previewMembers');
              trackSharesheetAction({
                actionName: 'previewListClicked',
                props: {
                  ruleType: 'simple',
                  isV3sharesheet: true,
                  sharesheetType: 'challenge',
                },
              });
            },
          },
        }
      : {
          view: 'previewMembers',
          options: previewFilterOptions,
          onPreviewModalBackButtonClick: () => setView('peopleWithAccess'),
          rules: displayRules,
          onLoadMore: fetchNextPageForPreview,
          hasNextPage: hasNextPageForPreview ?? false,
          onSearchOrFilterList,
          isLoadingPreview,
          isFetchingMoreMembers: isFetchingNextPageForPreview,
          className: 'max-h-[400px] min-h-[200px] pb-4',
          subTitle: messages.previewModalSubtitle,
        };

  return (
    <>
      <ShareModalContent {...props} />
      <ShareConfirmationModal
        description={formatMessage(messages.confirmationModalBody)}
        rightButton={
          <Button
            isFullWidth
            onClick={() => {
              setIsConfirmationModalOpen(false);
              onDoneAction();
            }}
            variation="primary"
          >
            {formatMessage(messages.saveChanges)}
          </Button>
        }
        onClose={() => {
          setIsConfirmationModalOpen(false);
          closeShareChallengeModal();
        }}
        open={isConfirmationModalOpen}
        leftButton={
          <Button
            isFullWidth
            onClick={() => {
              setIsConfirmationModalOpen(false);
              closeShareChallengeModal();
            }}
            variation="secondaryEmphasized"
          >
            {formatMessage(messages.discardChanges)}
          </Button>
        }
        title={formatMessage(messages.confirmationModalTitle)}
      />
    </>
  );
}
