import type { FormSettingsStoreType } from '../types';

export const useIsBottomSheetInputActive = (
  formSettingsStore: FormSettingsStoreType
) => {
  const {
    useBlocksGroupSetting,
    useCustomFocusSetting,
    useCustomTimePeriodSetting,
    useFocusTypeSetting,
    useIndividualBlocksSetting,
    useIndividualRespondentsSetting,
    usePredefinedTimePeriodSetting,
    useRespondentsGroupSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const isBlocksGroupInputActive = useBlocksGroupSetting(
    (store) => store.shouldRequestInput
  );

  const isCustomFocusInputActive = useCustomFocusSetting(
    (store) => store.shouldRequestInput
  );

  const isCustomTimePeriodInputActive = useCustomTimePeriodSetting(
    (store) => store.shouldRequestInput
  );

  const isFocusTypeInputActive = useFocusTypeSetting(
    (store) => store.shouldRequestInput
  );

  const isIndividualBlocksInputActive = useIndividualBlocksSetting(
    (store) => store.shouldRequestInput
  );

  const isIndividualRespondentsInputActive = useIndividualRespondentsSetting(
    (store) => store.shouldRequestInput
  );

  const isPredefinedTimePeriodInputActive = usePredefinedTimePeriodSetting(
    (store) => store.shouldRequestInput
  );

  const isRespondentsGroupInputActive = useRespondentsGroupSetting(
    (store) => store.shouldRequestInput
  );

  const isSettingsConfirmationInputActive = useSettingsConfirmationSetting(
    (store) => store.shouldRequestInput
  );

  const isInputActive =
    isCustomFocusInputActive ||
    isBlocksGroupInputActive ||
    isCustomTimePeriodInputActive ||
    isFocusTypeInputActive ||
    isIndividualBlocksInputActive ||
    isIndividualRespondentsInputActive ||
    isPredefinedTimePeriodInputActive ||
    isRespondentsGroupInputActive ||
    isSettingsConfirmationInputActive;

  return isInputActive;
};
