import { logger } from '@assembly-web/services';
import {
  Button,
  Checkbox,
  ConfirmationModal,
  useToastStore,
} from '@assembly-web/ui';
import { type ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useBlockType,
  useGetBlockIsLinked,
} from '../../../../../../stores/useFlowBuilderStore';
import type { DownloadDataJob } from '../../../../hooks/challenges/useDownloadClaimsData';
import { useDownloadFlowData } from '../../../../hooks/flows/useDownloadFlowData';
import { useBlockIdContext } from '../context/BlockIdContext';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useFlowData } from '../hooks/useFlowData';
import {
  useSetSwitchTypeConfirmationModal,
  useShowSwitchTypeConfirmationModal,
} from '../store/useEditorMiscState';

const messages = defineMessages({
  changeQuestionTitle: {
    defaultMessage: 'Change question type?',
    id: '/57j0t',
  },
  confirmPostExistTitle: {
    defaultMessage: 'Changes to flow data',
    id: 'bl1GaQ',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  change: {
    defaultMessage: 'Change',
    id: 'BY343C',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'K3r6DQ',
  },
  switchTypeDescription: {
    defaultMessage:
      'Changing the question type will erase existing question settings and details you’ve added. Only the primary question text will carry over to your new question type. Would you like to change it?',
    id: 'WnmAV6',
  },
  switchLinkedBlockDescription: {
    defaultMessage:
      'This question is linked to a Give Points block. If you change this question type, it will remove the linked question.',
    id: 'pl2yAh',
  },
  confirmDeleteModalTitle: {
    defaultMessage: 'Deleting Give Points Stack',
    id: 'dcUt63',
  },
  deleteLinkedPersonSelectBlock: {
    defaultMessage:
      'Deleting this Person Selection block will also delete the linked Give Points block. Please confirm this action.',
    id: 'frzzR/',
  },
  deleteLinkedGivePointsBlock: {
    defaultMessage:
      'Deleting this Give Points block will also delete the linked Person Selection block. Please confirm this action.',
    id: 'yun68n',
  },
  doNotShow: {
    defaultMessage: 'Don’t show me this again',
    id: 'NaJdQL',
  },
  postExistBody: {
    defaultMessage:
      '<p>This change to your flow content will create a new data set for this flow. To track changes to your flow’s data, we will create a new tab in your data download table as soon as the edited flow version has new responses (NOTE: If your flow does not have any responses yet, this will not affect your data). We store data for up to 20 of the latest versions of your data.</p><p>Changes that create a new version of your flow</p><ol><li>Changing a Question’s text</li><li>Changing a Question type</li><li>Adding or deleting a Question</li><li>Adding, changing, or removing options in the dropdown, multiple choice, or scale Question</li></ol><p>Want to download all current data now? <button>Download this flow’s data</button></p>',
    id: 'QIsPlD',
  },
  downloadFailed: {
    defaultMessage: 'Download failed. Retry later.',
    id: '7LPql5',
  },
  downloadSuccess: {
    defaultMessage: 'Download success',
    id: 'E9nYXm',
  },
});

const groupedMessages = {
  change_question: {
    edit: {
      title: messages.confirmPostExistTitle,
      primary: messages.change,
      cancel: messages.cancel,
      description: messages.postExistBody,
      linkedBlockDescription: messages.postExistBody,
    },
    new: {
      title: messages.changeQuestionTitle,
      primary: messages.change,
      cancel: messages.cancel,
      description: messages.switchTypeDescription,
      linkedBlockDescription: messages.switchLinkedBlockDescription,
    },
  },
  delete_question: {
    edit: {
      title: messages.delete,
      primary: messages.delete,
      cancel: messages.cancel,
      description: messages.postExistBody,
      linkedBlockDescription: messages.postExistBody,
    },
    new: {
      title: messages.confirmDeleteModalTitle,
      primary: messages.delete,
      cancel: messages.cancel,
      description: messages.deleteLinkedPersonSelectBlock,
      linkedBlockDescription: messages.deleteLinkedGivePointsBlock,
    },
  },
} as const;

function PostExistBody() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const { showSuccessToast, showErrorToast } = useToastStore();

  const { initiateDownload, isDownloading } = useDownloadFlowData(id, {
    onSuccess: (data: DownloadDataJob) => {
      window.open(data.url, '_blank', 'noreferrer, noopener');
      setTimeout(
        () => showSuccessToast(formatMessage(messages.downloadSuccess)),
        500
      );
    },
    onError: (error: unknown) => {
      const errorInfo = error instanceof Error ? error : undefined;
      showErrorToast(formatMessage(messages.downloadFailed));
      logger.error(
        'Failed to download flow data from editor',
        {
          flowId: id,
        },
        errorInfo
      );
    },
  });

  return formatMessage(messages.postExistBody, {
    p: (chunks: ReactNode) => <p>{chunks}</p>,
    ol: (chunks: ReactNode) => (
      <ol className="list-inside list-decimal">{chunks}</ol>
    ),
    li: (chunks: ReactNode) => <li>{chunks}</li>,
    button: (chunks: ReactNode) => (
      <Button
        onClick={initiateDownload}
        isLoading={isDownloading}
        variation="tertiaryEmphasized"
      >
        {chunks}
      </Button>
    ),
  });
}

function ConfirmShowAgain() {
  const { formatMessage } = useIntl();

  const isChecked = useShowSwitchTypeConfirmationModal();
  const handle = useSetSwitchTypeConfirmationModal();

  return (
    <div className="my-6 flex items-center gap-2">
      <Checkbox
        id="reminder"
        checked={!isChecked}
        onChange={(e) => {
          handle(e.target.checked);
        }}
      />
      <label
        htmlFor="reminder"
        className="ml-2 cursor-pointer text-sm text-gray-9"
      >
        {formatMessage(messages.doNotShow)}
      </label>
    </div>
  );
}

export const ConfirmAddQuestionWhenPostExist = ({
  handleClose,
  handleConfirmation,
  open,
}: {
  handleClose: () => void;
  handleConfirmation: () => void;
  open: boolean;
}) => {
  const { formatMessage } = useIntl();
  return (
    <ConfirmationModal
      descriptionType="span"
      descriptionClassName="flex flex-col gap-4"
      description={<PostExistBody />}
      leftButton={
        <Button variation="secondaryLite" isFullWidth onClick={handleClose}>
          {formatMessage(messages.cancel)}
        </Button>
      }
      onClose={handleClose}
      rightButton={
        <Button variation="primary" isFullWidth onClick={handleConfirmation}>
          {formatMessage(messages.change)}
        </Button>
      }
      open={open}
      title={formatMessage(messages.confirmPostExistTitle)}
    />
  );
};

export const ConfirmChangeQuestion = ({
  handleClose,
  handleConfirmation,
  open,
  confirmationType,
}: {
  handleClose: () => void;
  handleConfirmation: () => void;
  open: boolean;
  confirmationType: 'change_question' | 'delete_question';
}) => {
  const { formatMessage } = useIntl();
  const [showSwitchTypeConfirmationModal] = useState(
    useShowSwitchTypeConfirmationModal()
  );

  const { id, type } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useBlockType(id, blockId);
  const isLinkedBlock = useGetBlockIsLinked(id, blockId);

  const { data: newestPostsData } = useFlowData(id, type);
  const postExists = type === 'edit' && (newestPostsData?.data.length ?? 0) > 0;
  const isEdit = type === 'edit';
  const typeKey = isEdit ? 'edit' : 'new';

  return (
    <ConfirmationModal
      {...(type === 'edit' && {
        descriptionType: 'span',
        descriptionClassName: 'flex flex-col gap-4',
      })}
      description={
        <>
          {postExists ? (
            <PostExistBody />
          ) : confirmationType === 'change_question' &&
            (isLinkedBlock || blockType === 'GIVE_POINTS_STACK') ? (
            formatMessage(
              groupedMessages[confirmationType][typeKey].linkedBlockDescription
            )
          ) : confirmationType === 'delete_question' ? (
            isLinkedBlock ? (
              formatMessage(messages.deleteLinkedPersonSelectBlock)
            ) : (
              formatMessage(messages.deleteLinkedGivePointsBlock)
            )
          ) : (
            formatMessage(
              groupedMessages[confirmationType][typeKey].description
            )
          )}
          {!postExists &&
          confirmationType === 'change_question' &&
          (isLinkedBlock || showSwitchTypeConfirmationModal) ? (
            <ConfirmShowAgain />
          ) : null}
        </>
      }
      leftButton={
        <Button variation="secondaryLite" isFullWidth onClick={handleClose}>
          {formatMessage(groupedMessages[confirmationType][typeKey].cancel)}
        </Button>
      }
      onClose={handleClose}
      rightButton={
        <Button variation="primary" isFullWidth onClick={handleConfirmation}>
          {formatMessage(groupedMessages[confirmationType][typeKey].primary)}
        </Button>
      }
      open={open}
      title={formatMessage(groupedMessages[confirmationType][typeKey].title)}
    />
  );
};
