import { type MemberDetails, useUserDetails } from '@assembly-web/services';
import { useMemo } from 'react';
import invariant from 'tiny-invariant';

export const useCurrentUserDetail = () => {
  const { data: currentUserDetails } = useUserDetails();
  invariant(currentUserDetails, 'currentUserDetails should be defined');

  return useMemo(() => {
    return {
      memberID: currentUserDetails.member.memberId,
      name: currentUserDetails.member.profile.firstName,
      image: currentUserDetails.member.profile.image,
      firstName: currentUserDetails.member.profile.firstName,
      lastName: currentUserDetails.member.profile.lastName,
      username: currentUserDetails.member.profile.username,
      pointsGiven: currentUserDetails.member.pointsGiven,
      totalPointsGiven: currentUserDetails.member.totalPointsGiven,
      memberState: currentUserDetails.member.state,
      role: currentUserDetails.member.role,
      email: currentUserDetails.member.email,
    } as MemberDetails;
  }, [currentUserDetails]);
};
