import { TextStyle } from '@assembly-web/ui';
import {
  ExclamationTriangleIcon,
  PencilIcon,
} from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextButton } from '../../../../../../components/TextButton';
import { AnimateChat } from './AnimateChat';
import type { AnimateChatProps } from './types';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  editing: {
    defaultMessage: 'You are editing this response',
    id: 'ZC7O9x',
  },
});

type BottomActionSheetProps = AnimateChatProps & {
  className?: string;
  isEditing?: boolean;
  onCancel?: () => void;
  overlap?: boolean;
};

export const ErrorBottomActionSheet = (props: BottomActionSheetProps) => {
  const { children, ...bottomActionSheetProps } = props;

  return (
    <BottomActionSheet {...bottomActionSheetProps}>
      <div className="mx-6 my-2 flex flex-row justify-center gap-2">
        <ExclamationTriangleIcon className="mt-1 h-4 w-4 text-gray-9" />
        <TextStyle className="flex-1 text-gray-9">{children}</TextStyle>
      </div>
    </BottomActionSheet>
  );
};

export const LoadingBottomActionSheet = (
  props: Omit<BottomActionSheetProps, 'children'>
) => {
  return (
    <BottomActionSheet {...props}>
      <div className="mx-3 my-2 h-4 w-[221px] animate-pulse rounded bg-gray-5" />
    </BottomActionSheet>
  );
};

export const BottomActionSheet = (props: BottomActionSheetProps) => {
  const { formatMessage } = useIntl();
  const {
    children,
    className,
    isEditing,
    onCancel,
    overlap,
    shouldAnimateOnMount,
  } = props;

  return (
    <div
      className={twMerge(
        'sticky bottom-0 rounded-lg shadow-md-up',
        overlap && 'absolute w-full'
      )}
    >
      <AnimateChat
        shouldAnimateOnDismount
        shouldAnimateOnMount={shouldAnimateOnMount}
      >
        <div
          className={twMerge(
            'flex flex-col rounded-lg bg-gray-1 py-2',
            className
          )}
        >
          {Boolean(isEditing) && (
            <div className="mx-2 mb-1 flex flex-row justify-between rounded-lg bg-gray-3 px-2 py-1.5">
              <div className="flex flex-row items-center">
                <PencilIcon className="h-4 w-4 text-gray-9" />
                <TextStyle
                  as="span"
                  className="ml-1 text-gray-9"
                  variant="xs-regular"
                >
                  {formatMessage(messages.editing)}
                </TextStyle>
              </div>
              <TextButton
                className="text-sm font-normal !text-gray-9"
                onClick={onCancel}
                underline
              >
                {formatMessage(messages.cancel)}
              </TextButton>
            </div>
          )}
          {children}
        </div>
      </AnimateChat>
    </div>
  );
};
