import { TextStyle } from '@assembly-web/ui';
import { defineMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

const message = defineMessage({
  defaultMessage: 'BETA',
  id: 'vroSRZ',
});

export function BetaTag(props: { className?: string }) {
  const { formatMessage } = useIntl();

  return (
    <TextStyle
      className={twMerge(
        'flex h-4 w-9 items-center justify-center rounded text-[10px]',
        props.className
      )}
      variant="xs-bold"
    >
      {formatMessage(message)}
    </TextStyle>
  );
}
