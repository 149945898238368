import { useEffect, useRef } from 'react';
import { z } from 'zod';

const schema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('triggerSaveDraft'),
  }),
  z.object({
    type: z.literal('focusSearchInput'),
  }),
  z.object({
    type: z.literal('back'),
  }),
]);

export const useMobileMessageBridge = (
  callback: (data: z.infer<typeof schema>) => void
) => {
  const cbRef = useRef(callback);

  useEffect(() => {
    cbRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (event: MessageEvent<string>) => {
      try {
        const data = JSON.parse(event.data);
        const parsedData = schema.safeParse(data);

        if (parsedData.success) {
          cbRef.current(parsedData.data);
        }
      } catch (error) {
        // TODO: [Girish] find better way to log errors
        // if (error instanceof Error) {
        //   logger.error(
        //     'Error in useMobileMessageBridge',
        //     error,
        //     error
        //   );
        // }
      }
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);
};
