import { samlLogo } from '@assembly-web/assets';
import type { AssemblyInfoAPIResponse } from '@assembly-web/services';
import {
  config,
  getProviderName,
  lookupSSOProvider,
  SSOProvider,
  userAuthStore,
} from '@assembly-web/services';
import { AppLink, AssemblyLoadingIndicator, Button } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { LoaderFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useParams,
  useRouteLoaderData,
  useSearchParams,
} from 'react-router-dom';

import type { ReactRouterLoaderResponse } from '../../../../../../types/libs';
import { OnboardingContainer } from '../../../../components/OnboardingContainer';
import { SSOButton } from '../../../../components/SSOButton';
import { useGooglePlatformScriptLoader } from '../../../../hooks/useGooglePlatformScriptLoader';
import { handleSSOForLogin } from '../../../../services/sso';
import { onBoardingState } from '../../../../stores/onboardingStore';
import type { joinWorkspaceSlugLoader } from '..';

const validateWithProviderPageText = defineMessages({
  title: {
    defaultMessage: 'Welcome to {name}!',
    id: 'DBvi+R',
  },
  description: {
    defaultMessage:
      'Get ready to work smarter, not harder. Your organization has registered {provider} for sign in. Please click “Log in with {provider}” to sign into your new workspace.',
    id: 'oBnv+B',
  },
  cta: {
    defaultMessage: 'Log in with {provider}',
    id: 'BXwisy',
  },
  footer: {
    defaultMessage: 'Is this incorrect? <cta>Return to login</cta>',
    id: 'pCjZvR',
  },
});

export function JoinWithProviderRoute() {
  const loaderData = useRouteLoaderData(
    'join-workspace-root'
  ) as ReactRouterLoaderResponse<typeof joinWorkspaceSlugLoader>;

  const { provider: providerName } = useParams();

  const provider = lookupSSOProvider(providerName);

  if (!provider) {
    const { samlDetails } = userAuthStore.getState();
    if (samlDetails) {
      return (
        <ValidateSAMLForMagicLink
          assemblyDetails={loaderData}
          samlID={samlDetails.id}
          samlText={samlDetails.text}
        />
      );
    }
    return null;
  }

  return (
    <ValidateSSOForMagicLink provider={provider} assemblyDetails={loaderData} />
  );
}

function ValidateSSOForMagicLink({
  provider,
  assemblyDetails,
}: {
  provider: SSOProvider;
  assemblyDetails: AssemblyInfoAPIResponse;
}) {
  const { formatMessage } = useIntl();

  const googleScriptLoaded = useGooglePlatformScriptLoader();

  const [searchParams] = useSearchParams();

  const autoLogin = searchParams.get('auto') === 'true';

  useEffect(() => {
    if (autoLogin) {
      if (provider === SSOProvider.Google && !googleScriptLoaded) {
        return;
      }

      proceedWithLogin(provider, assemblyDetails, autoLogin);
    }
  }, [assemblyDetails, autoLogin, googleScriptLoaded, provider]);

  if (provider === SSOProvider.Google && !googleScriptLoaded) {
    return <AssemblyLoadingIndicator />;
  }

  const { resetBrandingToDefaults } = onBoardingState.getState();

  return (
    <OnboardingContainer
      title={formatMessage(validateWithProviderPageText.title, {
        name: assemblyDetails.name,
      })}
      description={formatMessage(validateWithProviderPageText.description, {
        provider: getProviderName(provider),
      })}
      footer={formatMessage(validateWithProviderPageText.footer, {
        cta: (text: ReactNode[]) => (
          <AppLink to="/login" onClick={() => resetBrandingToDefaults}>
            {text}
          </AppLink>
        ),
      })}
    >
      <SSOButton
        provider={provider}
        flow="login"
        isFullWidth
        onClick={() => {
          proceedWithLogin(provider, assemblyDetails, autoLogin);
        }}
      />
    </OnboardingContainer>
  );
}

function proceedWithLogin(
  provider: SSOProvider,
  assemblyDetails: AssemblyInfoAPIResponse,
  autoLogin?: boolean
) {
  const ssoProvider = lookupSSOProvider(provider);
  if (ssoProvider) {
    userAuthStore
      .getState()
      .updateEnforcedLoginDetails(
        assemblyDetails.assemblyId,
        `${assemblyDetails.workspaceSlug.name}-${assemblyDetails.workspaceSlug.shortCode}`,
        'join'
      );
    handleSSOForLogin(ssoProvider, autoLogin);
  }
}

function ValidateSAMLForMagicLink({
  samlText: provider,
  samlID,
  assemblyDetails,
}: {
  samlText: string;
  samlID: string;
  assemblyDetails: AssemblyInfoAPIResponse;
}) {
  const { formatMessage } = useIntl();
  const { resetBrandingToDefaults } = onBoardingState.getState();
  const isMobileApp = userAuthStore.getState().isLoginViaMobileApp;

  return (
    <OnboardingContainer
      title={formatMessage(validateWithProviderPageText.title, {
        name: assemblyDetails.name,
      })}
      description={formatMessage(validateWithProviderPageText.description, {
        provider,
      })}
      footer={formatMessage(validateWithProviderPageText.footer, {
        cta: (text: ReactNode[]) => (
          <AppLink to="/login" onClick={() => resetBrandingToDefaults()}>
            {text}
          </AppLink>
        ),
      })}
    >
      <Button
        variation="secondaryLite"
        isFullWidth
        onClick={() => {
          window.location.href = `${
            config.domains.api
          }/external/v1/saml/login/${samlID}?isMobileApp=${Boolean(
            isMobileApp
          )}`;
        }}
      >
        <img
          className="h-fit w-fit"
          src={samlLogo}
          role="presentation"
          alt=""
        />
        {formatMessage(validateWithProviderPageText.cta, { provider })}
      </Button>
    </OnboardingContainer>
  );
}

export async function joinWithProviderLoader({ params }: LoaderFunctionArgs) {
  if (!(params.provider !== 'saml' || !lookupSSOProvider(params.provider))) {
    return redirect('/login');
  }
}
