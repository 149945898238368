import { useMemo } from 'react';

import { createDrawerStore } from '../../../../stores/flowSummaryStore/drawerStore';
import { createEventSourceStore } from '../../../../stores/flowSummaryStore/eventSourceStore';
import { createFormSettingsStore } from '../../../../stores/flowSummaryStore/formSettingsStore';

export function useDrawerStores({ persistKey }: { persistKey: string }) {
  const eventSourceStore = useMemo(
    () => createEventSourceStore(persistKey),
    [persistKey]
  );

  const formSettingsStore = useMemo(
    () => createFormSettingsStore(persistKey),
    [persistKey]
  );

  const drawerStore = useMemo(
    () =>
      createDrawerStore(persistKey, { eventSourceStore, formSettingsStore }),
    [eventSourceStore, formSettingsStore, persistKey]
  );

  const { useDrawerStore } = drawerStore;

  const removeFromStorage = () => {
    drawerStore.clear();
    eventSourceStore.useEventSourceStore.persist.clearStorage();
    useDrawerStore.persist.clearStorage();
    formSettingsStore.clearStorage();
  };

  return {
    drawerStore,
    formSettingsStore,
    eventSourceStore,
    removeFromStorage,
  };
}
