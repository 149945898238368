import { TextStyle } from '@assembly-web/ui';

import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { useIsBottomSheetInputActive } from '../../../../hooks/useIsBottomSheetInputActive';
import { AnswerMessage } from '../../../../shared/AnswerMessage';
import {
  BlocksGroup,
  type EditSettingsStoreType,
  type EventSourceStoreType,
  type FormSettingsStoreType,
  type MemberAvatarProps,
} from '../../../../types';

export const IndividualBlocksAnswer = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    eventSourceStore: EventSourceStoreType;
    formSettingsStore: FormSettingsStoreType;
    isError: boolean;
  }
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { useEventSourceStore },
    formSettingsStore,
    isError,
    ...messageProps
  } = props;

  const {
    hideActiveInput,
    useBlocksGroupSetting,
    useIndividualBlocksSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const individualBlocksValue = useIndividualBlocksSetting(
    (store) => store.value
  );

  const blocksGroupValue = useBlocksGroupSetting((store) => store.value);

  const isSeen = useIndividualBlocksSetting.getState().isAnswerSeen;
  const isSet = useIndividualBlocksSetting((store) => store.isSet);
  const markSeen = useIndividualBlocksSetting((store) => store.markAnswerSeen);
  const markSet = useIndividualBlocksSetting((store) => store.markSet);

  const setShouldRequestInput = useIndividualBlocksSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useIndividualBlocksSetting(
    (store) => store.shouldRequestInput
  );

  const isEditingCurSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'individualBlocks'
  );

  const isEditingPrevSetting = useEditSettingsStore(
    (store) =>
      store.isEditingRespondents() || store.settingEditing === 'blocksGroup'
  );

  const isEditingOtherSetting = useEditSettingsStore(
    (store) => store.isEditingFocus() || store.isEditingTimePeriod()
  );

  const setShouldRequestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const isSettingsConfirmed = useSettingsConfirmationSetting((store) =>
    Boolean(store.value)
  );

  const handleEdit = () => {
    trackDoraAction('summaryInputEdited', { doraSummaryInput: 'Blocks' });
    setSettingEditing('individualBlocks');
    hideActiveInput();

    setTimeout(() => {
      setShouldRequestEdit('individualBlocks', true);
    }, timeouts.showInput);
  };

  const isInputActive = useIsBottomSheetInputActive(formSettingsStore);
  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    blocksGroupValue === BlocksGroup.Individual &&
    !isEditingPrevSetting &&
    individualBlocksValue && (
      <AnswerMessage
        isEditDisabled={
          !isSummaryError &&
          (isEditingCurSetting ||
            isEditingOtherSetting ||
            isSettingsConfirmed ||
            !isInputActive)
        }
        isEditing={isEditingCurSetting}
        isFaded={isEditingOtherSetting}
        isSeen={isSeen}
        isSet={isSet}
        markSeen={markSeen}
        markSet={markSet}
        onEdit={handleEdit}
        setShouldRequestInput={setShouldRequestInput}
        shouldRequestInput={shouldRequestInput}
        {...messageProps}
      >
        {individualBlocksValue.map((block, index) => (
          <TextStyle key={block.blockId}>
            {block.title}
            {index + 1 !== individualBlocksValue.length && ';'}
          </TextStyle>
        ))}
      </AnswerMessage>
    )
  );
};
