import { arrayMoveImmutable } from 'array-move';
import invariant from 'tiny-invariant';

/**
 * Get the reordering info for the given item in the
 * given list in the spec that our API expects.
 **/
export function getReorderingInfo({
  items,
  direction,
  itemId,
}: {
  items: { id: string; [key: string]: unknown }[];
  direction: 'down' | 'up';
  itemId: string;
}):
  | { beforeId?: string; afterId?: string; newLocationIndex: number }
  | undefined {
  const currentElementIndex = items.findIndex((item) => item.id === itemId);
  invariant(currentElementIndex >= 0, 'item must be present in collection');

  if (
    (direction === 'up' && currentElementIndex === 0) ||
    (direction === 'down' && currentElementIndex === items.length - 1)
  ) {
    return;
  }

  const newLocationIndex =
    direction === 'up' ? currentElementIndex - 1 : currentElementIndex + 1;

  const modifiedItems = arrayMoveImmutable(
    items,
    currentElementIndex,
    newLocationIndex
  );

  const beforeId = modifiedItems[newLocationIndex - 1]?.id;
  const afterId = modifiedItems[newLocationIndex + 1]?.id;

  return { newLocationIndex, afterId, beforeId };
}
