import {
  ActivityCardTypeEnum,
  type ChallengeAPIResponse,
  type CollectionItemsAPIResponse,
  type CollectionsAPIResponse,
  deserializeReactions,
  type FlowPostResponse,
  type GetDraftsResponse,
  getMemberDetailsFromUserDetails,
  type GetRepliesResponse,
  type ImportantActivitiesApiResponse,
  type MemberAPIResponse,
  type MemberDetails,
  type Reaction,
  type ReplyData,
  type UserFeedApiResponse,
} from '@assembly-web/services';
import type {
  InfiniteData,
  QueryClient,
  QueryKey,
} from '@tanstack/react-query';
import { produce } from 'immer';

import { convertNotificationReactionsToReactionsDetails } from '../../components/cards/utils';
import type { SearchIndexApiResponse } from '../useSearchIndex';
import type { AddReplyPayload, SaveDraftsPayload } from './types';

export function generateOptimisticReply(
  payload: AddReplyPayload,
  userDetails: MemberAPIResponse,
  responseId: string,
  challengeId: string | undefined = undefined
): ReplyData {
  return {
    gifURL: '',
    unread: true,
    imageURL: '',
    pointsEach: 0,
    kind: 'RESPONSE',
    taggedUsers: [],
    isDeleted: false,
    responseId: responseId,
    challengeId: challengeId,
    messageHtml: payload.messageHtml,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    messageTokens: payload.messageTokens,
    commentID: `comment-${new Date().getTime()}`,
    ...(payload.isAnonymous
      ? {
          type: 'INTERNAL_ANONYMOUS',
          fromMember: {
            anonymousIdentifier: userDetails.member.anonymousIdentifier ?? '',
          },
        }
      : {
          fromMember: {
            ...getMemberDetailsFromUserDetails(userDetails.member),
          },
        }),
    version: 2,
  };
}

export function addReplyToPostReplies(
  optimisticReply: ReplyData,
  previousReplies: InfiniteData<GetRepliesResponse>
): InfiniteData<GetRepliesResponse> {
  return produce(previousReplies, (draft) => {
    const pageLength = draft.pages.length;
    const lastPage = draft.pages[pageLength - 1];
    lastPage.data.push(optimisticReply);
    lastPage.total += 1;
  });
}

export function deleteReplyFromPostReplies(
  replyId: string,
  previousReplies: InfiniteData<GetRepliesResponse>
): InfiniteData<GetRepliesResponse> {
  return produce(previousReplies, (draft) => {
    const pageLength = draft.pages.length;
    const lastPage = draft.pages[pageLength - 1];
    lastPage.data = lastPage.data.filter(
      (reply) => reply.commentID !== replyId
    );
    lastPage.total -= 1;
  });
}

export function addReplyToEmptyPostReplies(
  optimisticReply: ReplyData
): InfiniteData<GetRepliesResponse> {
  return {
    pages: [
      {
        data: [optimisticReply],
        metadata: {
          pagination: {
            cursor: {
              previous: 'previous',
              next: 'next',
            },
          },
        },
        total: 1,
      },
    ],
    pageParams: [],
  };
}

export function updatePostWithDraftStatus(
  postQueryKey: string[],
  queryClient: QueryClient,
  postId: string,
  value: boolean
) {
  const previousAssemblyPost =
    queryClient.getQueryData<FlowPostResponse>(postQueryKey);

  const updatedPost = produce(previousAssemblyPost, (draft) => {
    if (draft?.responseId === postId) {
      draft.hasReplyDraft = value;
    }
  });

  queryClient.setQueryData(postQueryKey, updatedPost);
}

export function updateChallengeWithDraftStatus(
  challengeQueryKey: string[],
  queryClient: QueryClient,
  challengeId: string,
  value: boolean
) {
  const previousAssemblyPost =
    queryClient.getQueryData<ChallengeAPIResponse>(challengeQueryKey);

  const updatedPost = produce(previousAssemblyPost, (draft) => {
    if (draft?.challengeId === challengeId) {
      draft.hasReplyDraft = value;
    }
  });

  queryClient.setQueryData(challengeQueryKey, updatedPost);
}

export function updateUserFeedWithDraftStatus(
  userFeedQueryKeys: QueryKey[],
  queryClient: QueryClient,
  postId: string,
  value: boolean
) {
  userFeedQueryKeys.forEach((queryKey) => {
    const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
      queryClient.getQueryData(queryKey);
    if (userFeedData) {
      const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
        ...userFeedData,
        pages: userFeedData.pages.map((page) => {
          return {
            ...page,
            data: page.data.map((post) => {
              const cardDetails = post.cardDetails;
              if (
                cardDetails?.responseId === postId ||
                cardDetails?.challengeId === postId ||
                cardDetails?.post?.postID === postId
              ) {
                return produce(post, (draft) => {
                  if (draft.cardDetails) {
                    draft.cardDetails.hasReplyDraft = value;
                  }
                });
              }

              return post;
            }),
          };
        }),
      };

      queryClient.setQueryData(queryKey, mutatedUserFeedData);
    }
  });
}

export function updateSearchFeedWithDraftStatus(
  searchFeedQueryKeys: QueryKey[],
  queryClient: QueryClient,
  postId: string,
  value: boolean
) {
  searchFeedQueryKeys.forEach((queryKey) => {
    const searchData =
      queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(queryKey);
    if (searchData) {
      const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
        ...searchData,
        pages: searchData.pages.map((page) => {
          return {
            ...page,
            data: {
              ...page.data,
              data: page.data.data.map((result) => {
                const cardDetails = result.cardDetails;
                if (
                  cardDetails?.responseId === postId ||
                  cardDetails?.challengeId === postId ||
                  cardDetails?.post?.postID === postId
                ) {
                  return produce(result, (draft) => {
                    if (draft.cardDetails) {
                      draft.cardDetails.hasReplyDraft = value;
                    }
                  });
                }

                return result;
              }),
            },
          };
        }),
      };

      queryClient.setQueryData(queryKey, mutatedSearchData);
    }
  });
}

export function updatePostDrafts(
  draftKey: string[],
  queryClient: QueryClient,
  previousDraft: GetDraftsResponse | undefined,
  payload: SaveDraftsPayload
) {
  if (previousDraft) {
    const replyData =
      'replyData' in previousDraft.draft ? previousDraft.draft.replyData : {};

    queryClient.setQueryData(draftKey, {
      ...previousDraft,
      draft: {
        ...previousDraft.draft,
        replyData: {
          ...replyData,
          messageTokens: payload.replyData.messageTokens,
        },
      },
    });
  }
}

export function updateUserFeedReplyCountStatus(
  userFeedQueryKeys: QueryKey[],
  queryClient: QueryClient,
  postId: string,
  type: 'addReply' | 'deleteReply'
) {
  userFeedQueryKeys.forEach((queryKey) => {
    const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
      queryClient.getQueryData(queryKey);
    if (userFeedData) {
      const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
        ...userFeedData,
        pages: userFeedData.pages.map((page) => {
          return {
            ...page,
            data: page.data.map((post) => {
              return produce(post, (draft) => {
                if (
                  post.cardDetails?.responseId === postId ||
                  post.cardDetails?.post?.postID === postId
                ) {
                  if (draft.cardDetails?.replySummary) {
                    if (type === 'addReply') {
                      draft.cardDetails.replySummary.count += 1;
                    } else {
                      draft.cardDetails.replySummary.count =
                        draft.cardDetails.replySummary.count === 0
                          ? 0
                          : draft.cardDetails.replySummary.count - 1;
                    }
                  }
                  if (draft.cardDetails?.replySummary && type === 'addReply') {
                    draft.cardDetails.replySummary.lastRepliedAt =
                      new Date().toISOString();
                  }
                }
              });
            }),
          };
        }),
      };
      queryClient.setQueryData(queryKey, mutatedUserFeedData);
    }
  });
}

export function updateSearchFeedReplyCountStatus(
  searchFeedQueryKeys: QueryKey[],
  queryClient: QueryClient,
  postId: string,
  type: 'addReply' | 'deleteReply'
) {
  searchFeedQueryKeys.forEach((queryKey) => {
    const searchData =
      queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(queryKey);
    if (searchData) {
      const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
        ...searchData,
        pages: searchData.pages.map((page) => {
          return {
            ...page,
            data: {
              ...page.data,
              data: page.data.data.map((result) => {
                return produce(result, (draft) => {
                  if (
                    result.cardDetails?.responseId === postId ||
                    result.cardDetails?.post?.postID === postId
                  ) {
                    if (draft.cardDetails?.replySummary) {
                      if (type === 'addReply') {
                        draft.cardDetails.replySummary.count += 1;
                      } else {
                        draft.cardDetails.replySummary.count =
                          draft.cardDetails.replySummary.count === 0
                            ? 0
                            : draft.cardDetails.replySummary.count - 1;
                      }
                    }
                    if (
                      draft.cardDetails?.replySummary &&
                      type === 'addReply'
                    ) {
                      draft.cardDetails.replySummary.lastRepliedAt =
                        new Date().toISOString();
                    }
                  }
                });
              }),
            },
          };
        }),
      };
      queryClient.setQueryData(queryKey, mutatedSearchData);
    }
  });
}

export function updateUserFeedReactionsForChallengeCard({
  userFeedKeys,
  queryClient,
  payload,
  currentUser,
  action,
  challengeId,
}: {
  userFeedKeys: QueryKey[];
  queryClient: QueryClient;
  payload: Reaction;
  currentUser: MemberDetails | undefined;
  action: string;
  challengeId: string;
}) {
  userFeedKeys.forEach((queryKey) => {
    const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
      queryClient.getQueryData(queryKey);
    if (userFeedData) {
      const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
        ...userFeedData,
        pages: userFeedData.pages.map((page) => {
          return {
            ...page,
            data: page.data.map((card) => {
              if (
                card.cardDetails?.challengeId === challengeId &&
                card.type === 'challenge'
              ) {
                return produce(card, (draft) => {
                  const reactions = draft.cardDetails.reactions;
                  deserializeReactions({
                    payload,
                    currentUser,
                    reactions,
                    action,
                  });
                });
              }
              return card;
            }),
          };
        }),
      };
      queryClient.setQueryData(queryKey, mutatedUserFeedData);
    }
  });
}

export function updateSearchFeedReactionsForChallengeCard({
  searchFeedKeys,
  queryClient,
  payload,
  currentUser,
  action,
  challengeId,
}: {
  searchFeedKeys: QueryKey[];
  queryClient: QueryClient;
  payload: Reaction;
  currentUser: MemberDetails | undefined;
  action: string;
  challengeId: string;
}) {
  searchFeedKeys.forEach((queryKey) => {
    const searchData =
      queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(queryKey);
    if (searchData) {
      const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
        ...searchData,
        pages: searchData.pages.map((page) => {
          return {
            ...page,
            data: {
              ...page.data,
              data: page.data.data.map((result) => {
                if (
                  result.cardDetails?.challengeId === challengeId &&
                  result.type === 'challenge'
                ) {
                  return produce(result, (draft) => {
                    const reactions = draft.cardDetails.reactions;
                    deserializeReactions({
                      payload,
                      currentUser,
                      reactions,
                      action,
                    });
                  });
                }
                return result;
              }),
            },
          };
        }),
      };
      queryClient.setQueryData(queryKey, mutatedSearchData);
    }
  });
}

export function updateUserFeedReactions({
  userFeedKeys,
  queryClient,
  payload,
  currentUser,
  action,
  commentId,
}: {
  userFeedKeys: QueryKey[];
  queryClient: QueryClient;
  payload: Reaction;
  currentUser: MemberDetails | undefined;
  action: string;
  commentId: string;
}) {
  userFeedKeys.forEach((queryKey) => {
    const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
      queryClient.getQueryData(queryKey);
    if (userFeedData) {
      const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
        ...userFeedData,
        pages: userFeedData.pages.map((page) => {
          return {
            ...page,
            data: page.data.map((card) => {
              if (card.cardDetails?.commentID === commentId) {
                return produce(card, (draft) => {
                  if (draft.cardDetails) {
                    const reactions = draft.cardDetails.reactions;
                    deserializeReactions({
                      payload,
                      currentUser,
                      reactions,
                      action,
                    });
                  }
                });
              }
              return card;
            }),
          };
        }),
      };
      queryClient.setQueryData(queryKey, mutatedUserFeedData);
    }
  });
}

export function updateSearchFeedReactions({
  searchFeedKeys,
  queryClient,
  payload,
  currentUser,
  action,
  commentId,
}: {
  searchFeedKeys: QueryKey[];
  queryClient: QueryClient;
  payload: Reaction;
  currentUser: MemberDetails | undefined;
  action: string;
  commentId: string;
}) {
  searchFeedKeys.forEach((queryKey) => {
    const searchData =
      queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(queryKey);
    if (searchData) {
      const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
        ...searchData,
        pages: searchData.pages.map((page) => {
          return {
            ...page,
            data: {
              ...page.data,
              data: page.data.data.map((result) => {
                if (result.cardDetails?.commentID === commentId) {
                  return produce(result, (draft) => {
                    if (draft.cardDetails) {
                      const reactions = draft.cardDetails.reactions;
                      deserializeReactions({
                        payload,
                        currentUser,
                        reactions,
                        action,
                      });
                    }
                  });
                }
                return result;
              }),
            },
          };
        }),
      };
      queryClient.setQueryData(queryKey, mutatedSearchData);
    }
  });
}

export function updateCollectionItemFeedReactions({
  queryClient,
  payload,
  currentUser,
  action,
  commentId,
}: {
  queryClient: QueryClient;
  payload: Reaction;
  currentUser: MemberDetails | undefined;
  action: string;
  commentId?: string;
}) {
  const queryKey = ['collections'];
  const previousCollections =
    queryClient.getQueryData<CollectionsAPIResponse>(queryKey);
  let collectionItemCachedData: {
    key: string[];
    data: CollectionItemsAPIResponse | undefined;
  }[] = [];

  previousCollections?.data.map((collection) => {
    const previousCollectionItems =
      queryClient.getQueryData<CollectionItemsAPIResponse>([
        'collectionItems',
        collection.collectionId,
      ]);

    const updatedCollectionItems = produce(previousCollectionItems, (draft) => {
      if (draft?.data) {
        draft.data = draft.data.map((item) => {
          if (item.type === 'comment' && item.id === commentId) {
            deserializeReactions({
              payload,
              currentUser,
              reactions: item.cardDetails.reactions,
              action,
            });
            collectionItemCachedData.push({
              key: ['collectionItems', collection.collectionId],
              data: previousCollectionItems,
            });
          }
          return item;
        });
      }
    });
    queryClient.setQueryData(
      ['collectionItems', collection.collectionId],
      updatedCollectionItems
    );
  });
  return collectionItemCachedData;
}

export function updateImportantSectionReactions({
  importantSectionQueryKey,
  queryClient,
  payload,
  currentUser,
  action,
  commentId,
}: {
  importantSectionQueryKey: string[];
  queryClient: QueryClient;
  payload: Reaction;
  currentUser: MemberDetails;
  action: string;
  commentId?: string;
}) {
  const previousCards = queryClient.getQueryData<
    InfiniteData<ImportantActivitiesApiResponse>
  >(importantSectionQueryKey);
  return produce(previousCards, (draft) => {
    draft?.pages.forEach((page) => {
      page.data.data = page.data.data.map((card) => {
        switch (card.type) {
          case ActivityCardTypeEnum.ResponseThreadReplies:
          case ActivityCardTypeEnum.OwnerPostReply:
          case ActivityCardTypeEnum.OwnerResponseReply:
          case ActivityCardTypeEnum.PostThreadReplies:
          case ActivityCardTypeEnum.ChallengeThreadReplies:
          case ActivityCardTypeEnum.OwnerChallengeReply:
            if (card.entity.lastReply.commentId === commentId) {
              return produce(card, (draft) => {
                const reactions =
                  convertNotificationReactionsToReactionsDetails(
                    draft.entity.lastReply.reactions
                  );
                deserializeReactions({
                  payload,
                  currentUser,
                  reactions,
                  action,
                });
                draft.entity.lastReply.reactions = reactions;
              });
            }
        }
        return card;
      });
    });
  });
}

export function updateReactionInReplyDrawer({
  repliesData,
  commentId,
  payload,
  currentUser,
  action,
}: {
  repliesData: InfiniteData<GetRepliesResponse> | undefined;
  commentId: string;
  payload: Reaction;
  currentUser: MemberDetails | undefined;
  action: string;
}) {
  if (repliesData) {
    return {
      ...repliesData,
      pages: repliesData.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((reply) => {
            if (reply.commentID === commentId && reply.reactions) {
              return produce(reply, (draft) => {
                if (draft.reactions) {
                  deserializeReactions({
                    payload,
                    currentUser,
                    reactions: draft.reactions,
                    action,
                  });
                }
              });
            }
            return reply;
          }),
        };
      }),
    };
  }
}
