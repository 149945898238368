import {
  BlockLabel,
  EditorLoader,
  FlowEmojiPicker,
  TextField as BaseTextField,
} from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetFlowEmoji,
  useGetFlowName,
  useGetFlowNameError,
  useSetFlowEmoji,
  useSetFlowName,
} from '../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';

const messages = defineMessages({
  flowTitle: {
    defaultMessage: 'Add Flow Title:',
    id: 'CNy5Ay',
  },
  placeholder: {
    defaultMessage: 'Ex. Team Pets',
    id: 'VPIdqG',
  },
  flowEmoji: {
    defaultMessage: 'Add Emoji:',
    id: '9cqkjd',
  },
});

function EmojiPicker() {
  const { id, isLoading } = useEditorDataContext();
  const { formatMessage } = useIntl();

  const emoji = useGetFlowEmoji(id);
  const set = useSetFlowEmoji(id);

  const occurrenceInProgress = useAnyOccurrenceInProgress();

  if (!emoji) {
    return null;
  }

  if (isLoading) {
    return <EditorLoader className="h-[40px] w-[68px]" />;
  }

  return (
    <div className="flex w-max flex-shrink-0 flex-col gap-1 [&_button[aria-haspopup='dialog']]:h-10">
      <BlockLabel>{formatMessage(messages.flowEmoji)}</BlockLabel>
      <FlowEmojiPicker
        disabled={occurrenceInProgress}
        emoji={emoji}
        onChange={set}
        showDisabledTooltip={false}
      />
    </div>
  );
}

function TextField() {
  const { formatMessage } = useIntl();
  const { id, isLoading } = useEditorDataContext();

  const name = useGetFlowName(id);
  const setName = useSetFlowName(id);
  const error = useGetFlowNameError(id)?.message ?? null;

  const occurrenceInProgress = useAnyOccurrenceInProgress();

  if (isLoading) {
    return <EditorLoader className="h-[40px] flex-1" />;
  }

  return (
    <BaseTextField
      label={formatMessage(messages.flowTitle)}
      labelClassName="text-sm font-medium"
      inputClassName="px-4 py-2.5 text-gray-9 text-sm font-normal rounded-lg"
      className="flex flex-col gap-1"
      value={name}
      onChange={(e) => setName(e.target.value)}
      placeholder={formatMessage(messages.placeholder)}
      onClick={() => {
        trackFlowEditorAction('flowTitleClicked');
      }}
      disabled={occurrenceInProgress}
      {...(Boolean(error) && {
        isInvalid: true,
        invalidText: error ?? '',
      })}
    />
  );
}

export function FlowTitle() {
  return (
    <div className="flex justify-start gap-5 @[700px]/body:gap-2">
      <EmojiPicker />
      <TextField />
    </div>
  );
}
