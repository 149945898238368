import { zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type LayoutState = {
  isFormattingToolbarVisible: boolean;
  setIsFormattingToolbarVisible: (value: boolean) => void;
};

export const useLayoutStore = create<LayoutState>()(
  persist(
    immer<LayoutState>((set) => ({
      isFormattingToolbarVisible: true,

      setIsFormattingToolbarVisible: (isFormattingToolbarVisible: boolean) => {
        set((state) => {
          state.isFormattingToolbarVisible = isFormattingToolbarVisible;
        });
      },
    })),
    {
      name: 'layout-store',
      storage: createJSONStorage(() => zustandLocalStorage),
    }
  )
);
