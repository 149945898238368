import type { Nullable } from '@assembly-web/services';
import { useCallback } from 'react';

import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';

export function useChallengeDrawer() {
  const { createDrawer } = useMultiDrawerStore((store) => ({
    createDrawer: store.createDrawer,
  }));

  const openChallengeDrawer = useCallback(
    (
      challengeId: string,
      commentId?: Nullable<string>,
      commentIdToEdit?: Nullable<string>
    ) => {
      createDrawer({
        title: '',
        data: {
          commentId,
          challengeId,
          commentIdToEdit,
        },
        type: 'challenges',
      });
    },
    [createDrawer]
  );

  return {
    openChallengeDrawer,
  };
}
