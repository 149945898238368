import { type DatePickerProps, DoubleDatePicker } from '@assembly-web/ui';
import {
  type CalendarDate,
  getLocalTimeZone,
  today,
} from '@internationalized/date';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { TextButton } from '../../../../../../../../../components/TextButton';
import { trackDoraAction } from '../../../../../../../services/analytics';
import {
  BottomActionSheet,
  ErrorBottomActionSheet,
  LoadingBottomActionSheet,
} from '../../../../../shared/dora/BottomActionSheet';
import { timeouts } from '../../../../../shared/dora/constants';
import {
  getCalendarDate,
  getEndOfDay,
  isSameCalendarDates,
} from '../../../../../shared/dora/utils';
import { useGetFlowFeed } from '../../../../hooks/useGetFlowFeed';
import { getFlowSummaryMaxTimeIntervalQuery } from '../../../../hooks/useGetFlowSummaryMaxTimeInterval';
import type { CustomTimePeriodInputProps } from '../../../../types';

const messages = defineMessages({
  after: {
    defaultMessage: 'After {date}',
    id: 'Bl8xZP',
  },
  before: {
    defaultMessage: 'Before {date}',
    id: 'U5sjCv',
  },
  custom: {
    defaultMessage: 'I want to set a custom time period',
    id: 'MOIAj3',
  },
  end: {
    defaultMessage: 'End',
    id: '3JVa6k',
  },
  invalidDate: {
    defaultMessage: 'This date range is not available to be summarized.',
    id: 'bLRjxT',
  },
  noPostsInRange: {
    defaultMessage:
      'There are no posts in the specified range. Please select a different range.',
    id: 'fD3Fu0',
  },
  range: {
    defaultMessage: 'Select between {start} and {end}',
    id: 'EneEtw',
  },
  singleDate: {
    defaultMessage: 'On {date}',
    id: 'KGXk/j',
  },
  start: {
    defaultMessage: 'Start',
    id: 'mOFG3K',
  },
});

const TryAgainHelpText = (props: { onClick: () => void }) => (
  <FormattedMessage
    defaultMessage="Whoops! We ran into an issue. Please <button>try again</button>."
    id="fgBZFv"
    values={{
      button: (text) => (
        <TextButton className="!text-gray-8" onClick={props.onClick} underline>
          {text}
        </TextButton>
      ),
    }}
  />
);

const isNoResponsesError = (err: unknown) => {
  return (
    isAxiosError(err) &&
    err.response?.data.code === 'FORBIDDEN' &&
    err.response.data.message === 'No responses to calculate.'
  );
};

export const Selector = (props: CustomTimePeriodInputProps) => {
  const {
    drawerRef,
    editSettingsStore: useEditSettingsStore,
    eventSourceStore,
    flowId,
    flowPostInterval,
    formSettingsStore: {
      useCustomTimePeriodSetting,
      useIndividualBlocksSetting,
      usePredefinedTimePeriodSetting,
    },
    isAnonymous,
    isError,
    isLoading: isLoadingInput,
    onTryAgain,
    respondentIds,
  } = props;

  const { formatMessage, locale } = useIntl();
  const queryClient = useQueryClient();
  const portalContainer = drawerRef?.current ?? undefined;

  const isNewInputSeen = useCustomTimePeriodSetting.getState().isInputSeen;

  const markNewInputSeen = useCustomTimePeriodSetting(
    (store) => store.markInputSeen
  );

  const setTimePeriod = useCustomTimePeriodSetting((store) => store.setValue);

  const isCustomOptionSelected = usePredefinedTimePeriodSetting(
    (store) => store.isSet && !store.value?.startDate && !store.value?.endDate
  );

  const setPredefinedTimePeriod = usePredefinedTimePeriodSetting(
    (store) => store.setValue
  );

  const individualBlocksValue = useIndividualBlocksSetting(
    (store) => store.value
  );

  const isEditInputSeen =
    useEditSettingsStore.getState().customTimePeriod.isInputSeen;

  const markEditInputSeen = useEditSettingsStore(
    (store) => store.markInputSeen
  );

  const isEditing = useEditSettingsStore(
    (store) => store.customTimePeriod.shouldRequestInput
  );

  const exitEditMode = useEditSettingsStore((store) => store.exitEditMode);

  const handleCancel = () => {
    trackDoraAction('summaryInputEditCanceled');
    exitEditMode();
  };

  useEffect(() => {
    if (!isNewInputSeen && !isEditing) {
      markNewInputSeen();
    } else if (!isEditInputSeen && isEditing) {
      markEditInputSeen('customTimePeriod');
    }
  }, [
    isNewInputSeen,
    isEditing,
    isEditInputSeen,
    markEditInputSeen,
    markNewInputSeen,
  ]);

  const timezone = getLocalTimeZone();
  const dateOfOldestPost = flowPostInterval?.[0];
  const dateOfNewestPost = flowPostInterval?.[1];

  const origEndValueSelected = useCustomTimePeriodSetting(
    (store) => store.value?.endDate
  );

  const origEndCalendarDateValue = origEndValueSelected
    ? getCalendarDate(new Date(origEndValueSelected))
    : null;

  const origStartValueSelected = useCustomTimePeriodSetting(
    (store) => store.value?.startDate
  );

  const origStartCalendarDateValue = origStartValueSelected
    ? getCalendarDate(new Date(origStartValueSelected))
    : null;

  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    [locale]
  );

  const maxDate = dateOfNewestPost
    ? getCalendarDate(dateOfNewestPost)
    : today(timezone);

  const minDate = dateOfOldestPost ? getCalendarDate(dateOfOldestPost) : null;
  const [isRangeInvalid, setIsRangeInvalid] = useState(true);
  const [isFetchingRange, setIsFetchingRange] = useState(false);
  const [isEndInvalid, setIsEndInvalid] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);

  const [focusedEndValue, setFocusedEndValue] = useState<CalendarDate>(
    origEndCalendarDateValue ?? maxDate
  );

  const [maxEndDate, setMaxEndDate] = useState<CalendarDate>(maxDate);

  const [selectedEndDateValue, setSelectedEndDateValue] =
    useState<CalendarDate | null>(origEndCalendarDateValue);

  const [isStartInvalid, setIsStartInvalid] = useState(false);
  const [isStartOpen, setIsStartOpen] = useState(false);

  const [focusedStartValue, setFocusedStartValue] = useState<CalendarDate>(
    origStartCalendarDateValue ?? maxDate
  );

  const [minStartDate, setMinStartDate] = useState<CalendarDate | null>(
    minDate
  );

  const [selectedStartDateValue, setSelectedStartDateValue] =
    useState<CalendarDate | null>(origStartCalendarDateValue);

  const [dateHelpText, setDateHelpText] = useState<ReactNode>('');

  const getStartDateForRequest = () => {
    if (selectedStartDateValue) {
      let selectedStartDate = selectedStartDateValue.toDate(timezone);

      if (
        dateOfOldestPost &&
        isSameCalendarDates(selectedStartDate, dateOfOldestPost)
      ) {
        selectedStartDate = dateOfOldestPost;
      }

      return selectedStartDate.toISOString();
    }
  };

  const getEndDateForRequest = () => {
    if (selectedEndDateValue) {
      let selectedEndDate = selectedEndDateValue.toDate(timezone);

      if (
        dateOfNewestPost &&
        isSameCalendarDates(selectedEndDate, dateOfNewestPost)
      ) {
        selectedEndDate = dateOfNewestPost;
      } else {
        selectedEndDate = getEndOfDay(selectedEndDate);
      }

      return selectedEndDate.toISOString();
    }
  };

  const isValidDatesSelected =
    Boolean(selectedEndDateValue) &&
    Boolean(selectedStartDateValue) &&
    !isStartInvalid &&
    !isEndInvalid;

  const blockIds = individualBlocksValue?.map((block) => block.blockId);
  const commaSeparatedBlockIds = blockIds?.join(',');

  const commaSeparatedRespondentIds = respondentIds?.join(',');

  const {
    data: postsInRangeResponse,
    isError: isPostsInRangeError,
    isLoading: isPostsInRangeLoading,
    refetch: refetchPostsInRange,
  } = useGetFlowFeed(
    flowId,
    {
      filter: {
        blockIds,
        endDate: getEndDateForRequest(),
        isAnonymous,
        respondedBy: respondentIds,
        startDate: getStartDateForRequest(),
      },
      limit: 1,
    },
    isValidDatesSelected
  );

  const isLoading =
    (isValidDatesSelected && isPostsInRangeLoading) || isFetchingRange;

  const isClearButtonDisabled =
    (!selectedEndDateValue && !selectedStartDateValue) || isFetchingRange;

  const hasPostsInRange = Boolean(postsInRangeResponse?.data.length);

  const isSubmitButtonDisabled =
    isRangeInvalid ||
    (isValidDatesSelected &&
      (isPostsInRangeLoading || !hasPostsInRange || isPostsInRangeError));

  const isBothDatesSelected =
    Boolean(selectedEndDateValue) && Boolean(selectedStartDateValue);

  const maxEndDateValue = !isBothDatesSelected ? maxEndDate : maxDate;

  const minEndDateValue =
    !isBothDatesSelected && !isStartInvalid && selectedStartDateValue
      ? selectedStartDateValue
      : (minDate ?? undefined);

  const reset = () => {
    setSelectedEndDateValue(null);
    setFocusedEndValue(maxDate);
    setSelectedStartDateValue(null);
    setFocusedStartValue(maxDate);
    setDateHelpText('');
    setIsEndInvalid(false);
    setIsStartInvalid(false);
    setIsFetchingRange(false);
    setMaxEndDate(maxDate);
    setMinStartDate(minDate);
    setIsRangeInvalid(true);
  };

  const handleSelectedEndValueChange = (
    newSelectedEndCalendarDateValue: CalendarDate | null
  ) => {
    if (!newSelectedEndCalendarDateValue) {
      // User attempts to clear a date segment by pressing backspace on the keyboard.
      return;
    }

    setFocusedEndValue(newSelectedEndCalendarDateValue);
    setSelectedEndDateValue(newSelectedEndCalendarDateValue);

    const isNewValueOutOfRange =
      (minEndDateValue && newSelectedEndCalendarDateValue < minEndDateValue) ||
      newSelectedEndCalendarDateValue > maxEndDateValue;

    if (isNewValueOutOfRange) {
      setDateHelpText(formatMessage(messages.invalidDate));
      setIsEndInvalid(true);
      setIsRangeInvalid(true);
      return;
    }

    setIsEndInvalid(false);

    if (!isStartInvalid) {
      setDateHelpText('');
    }

    const isEndLastDateUnselected =
      !selectedEndDateValue && selectedStartDateValue;

    if (isEndLastDateUnselected) {
      // End date inputted for the first time, so skip fetching max start date if start date is already selected.
      setIsRangeInvalid(isStartInvalid);
      return;
    }

    const endDateRequestParam = getEndOfDay(
      newSelectedEndCalendarDateValue.toDate(timezone)
    );

    setIsRangeInvalid(true);

    const fetchIntervalBasedOnEndDate = async () => {
      setIsFetchingRange(true);

      try {
        const { startDate: updatedMinStartIsoDate } =
          await queryClient.fetchQuery(
            getFlowSummaryMaxTimeIntervalQuery({
              blockIds: commaSeparatedBlockIds,
              endDate: endDateRequestParam.toISOString(),
              flowId,
              isAnonymous,
              respondedBy: commaSeparatedRespondentIds,
            })
          );

        if (updatedMinStartIsoDate) {
          const updatedMinStartDate = new Date(updatedMinStartIsoDate);

          const newMinStartCalendarDate = getCalendarDate(updatedMinStartDate);

          setMinStartDate(newMinStartCalendarDate);

          const isCurSelectedStartDateOutofRange = selectedStartDateValue
            ? selectedStartDateValue < newMinStartCalendarDate ||
              selectedStartDateValue > newSelectedEndCalendarDateValue
            : false;

          if (isCurSelectedStartDateOutofRange) {
            setSelectedStartDateValue(null); // Reset because the start date is no longer within the updated range.
            setIsStartInvalid(false);
            setFocusedStartValue(newMinStartCalendarDate);
          }

          const isStartDateNotSelected = !selectedStartDateValue;

          const shouldShowRangeHelpText =
            isCurSelectedStartDateOutofRange || isStartDateNotSelected;

          if (shouldShowRangeHelpText) {
            setDateHelpText(
              formatMessage(messages.range, {
                end: dateFormatter.format(endDateRequestParam),
                start: dateFormatter.format(updatedMinStartDate),
              })
            );
          }

          const isBeforeRangeValid =
            shouldShowRangeHelpText &&
            updatedMinStartIsoDate === dateOfOldestPost?.toISOString();

          const isBetweenRangeValid =
            !isCurSelectedStartDateOutofRange && selectedStartDateValue;

          if (isBeforeRangeValid || isBetweenRangeValid) {
            setIsRangeInvalid(false);
          }
        }
      } catch (err) {
        if (isNoResponsesError(err)) {
          setIsEndInvalid(true);
          setDateHelpText(formatMessage(messages.invalidDate));
        } else {
          setIsEndInvalid(true);
          setDateHelpText(
            <TryAgainHelpText onClick={fetchIntervalBasedOnEndDate} />
          );
        }
      } finally {
        setIsFetchingRange(false);
      }
    };

    fetchIntervalBasedOnEndDate();
  };

  const endDatePickerProps: DatePickerProps = {
    focusedValue: focusedEndValue,
    isInvalid: isEndInvalid,
    isLoading,
    isOpen: isEndOpen,
    label: formatMessage(messages.end),
    maxValue: maxEndDateValue,
    minValue: minEndDateValue,
    onFocusedValueChange: setFocusedEndValue,
    onOpenChange: setIsEndOpen,
    onSelectedValueChange: handleSelectedEndValueChange,
    portalContainer,
    selectedValue: selectedEndDateValue,
  };

  const maxStartDateValue =
    !isBothDatesSelected &&
    !isEndInvalid &&
    selectedEndDateValue &&
    selectedEndDateValue < maxDate
      ? selectedEndDateValue
      : maxDate;

  const minStartDateValue =
    !isBothDatesSelected && minStartDate
      ? minStartDate
      : (minDate ?? undefined);

  const handleSelectedStartValueChange = (
    newSelectedStartCalendarDateValue: CalendarDate | null
  ) => {
    if (!newSelectedStartCalendarDateValue) {
      // User attempts to clear a date segment by pressing backspace on the keyboard.
      return;
    }

    setFocusedStartValue(newSelectedStartCalendarDateValue);
    setSelectedStartDateValue(newSelectedStartCalendarDateValue);

    const isNewValueOutOfRange =
      (minStartDateValue &&
        newSelectedStartCalendarDateValue < minStartDateValue) ||
      newSelectedStartCalendarDateValue > maxStartDateValue;

    if (isNewValueOutOfRange) {
      setDateHelpText(formatMessage(messages.invalidDate));
      setIsStartInvalid(true);
      setIsRangeInvalid(true);
      return;
    }

    setIsStartInvalid(false);

    if (!isEndInvalid) {
      setDateHelpText('');
    }

    const isStartLastDateUnselected =
      !selectedStartDateValue && selectedEndDateValue;

    if (isStartLastDateUnselected) {
      // Start date inputted for the first time, so skip fetching max end date if end date is already selected.
      setIsRangeInvalid(isEndInvalid);
      return;
    }

    setIsRangeInvalid(true);

    const fetchIntervalBasedOnStartDate = async () => {
      setIsFetchingRange(true);

      try {
        const startDateValue =
          newSelectedStartCalendarDateValue.toDate(timezone);

        const { endDate: updatedMaxEndIsoDate } = await queryClient.fetchQuery(
          getFlowSummaryMaxTimeIntervalQuery({
            blockIds: commaSeparatedBlockIds,
            flowId,
            isAnonymous,
            respondedBy: commaSeparatedRespondentIds,
            startDate: startDateValue.toISOString(),
          })
        );

        if (updatedMaxEndIsoDate) {
          const updatedMaxEndDate = new Date(updatedMaxEndIsoDate);

          const isMaxEndSameAsNewestPostDate =
            updatedMaxEndIsoDate === dateOfNewestPost?.toISOString();

          const isMaxEndDateEndofDay =
            updatedMaxEndDate.getHours() === 23 &&
            updatedMaxEndDate.getMinutes() === 59 &&
            updatedMaxEndDate.getSeconds() === 59 &&
            updatedMaxEndDate.getMilliseconds() === 999;

          if (
            (dateOfNewestPost && !isMaxEndSameAsNewestPostDate) ||
            (!dateOfNewestPost && !isMaxEndDateEndofDay)
          ) {
            // Set to previous day to avoid expanding the time range.
            updatedMaxEndDate.setDate(updatedMaxEndDate.getDate() - 1);
          }

          const newMaxEndCalendarDate = getCalendarDate(updatedMaxEndDate);

          setMaxEndDate(newMaxEndCalendarDate);

          const isCurSelectedEndDateOutofRange = selectedEndDateValue
            ? selectedEndDateValue > newMaxEndCalendarDate ||
              selectedEndDateValue < newSelectedStartCalendarDateValue
            : false;

          if (isCurSelectedEndDateOutofRange) {
            setSelectedEndDateValue(null); // Reset because the end date is no longer within the updated range.
            setIsEndInvalid(false);
            setFocusedEndValue(newMaxEndCalendarDate);
          }

          const isEndDateNotSelected = !selectedEndDateValue;

          const shouldShowRangeHelpText =
            isCurSelectedEndDateOutofRange || isEndDateNotSelected;

          if (shouldShowRangeHelpText) {
            setDateHelpText(
              formatMessage(messages.range, {
                end: dateFormatter.format(updatedMaxEndDate),
                start: dateFormatter.format(startDateValue),
              })
            );
          }

          const isAfterRangeValid =
            shouldShowRangeHelpText && isMaxEndSameAsNewestPostDate;

          const isBetweenRangeValid =
            !isCurSelectedEndDateOutofRange && selectedEndDateValue;

          if (isAfterRangeValid || isBetweenRangeValid) {
            setIsRangeInvalid(false);
          }
        }
      } catch (err) {
        if (isNoResponsesError(err)) {
          setIsStartInvalid(true);
          setDateHelpText(formatMessage(messages.invalidDate));
        } else {
          setIsStartInvalid(true);
          setDateHelpText(
            <TryAgainHelpText onClick={fetchIntervalBasedOnStartDate} />
          );
        }
      } finally {
        setIsFetchingRange(false);
      }
    };

    fetchIntervalBasedOnStartDate();
  };

  const startDatePickerProps: DatePickerProps = {
    focusedValue: focusedStartValue,
    isInvalid: isStartInvalid,
    isLoading,
    isOpen: isStartOpen,
    label: formatMessage(messages.start),
    maxValue: maxStartDateValue,
    minValue: minStartDateValue,
    onFocusedValueChange: setFocusedStartValue,
    onOpenChange: setIsStartOpen,
    onSelectedValueChange: handleSelectedStartValueChange,
    portalContainer,
    selectedValue: selectedStartDateValue,
  };

  const applyUpdatedValue = () => {
    if (isEditing) {
      if (!isCustomOptionSelected) {
        setPredefinedTimePeriod({
          duration: formatMessage(messages.custom),
          endDate: undefined,
          startDate: undefined,
        });

        setTimeout(exitEditMode, timeouts.hideInput);
      } else {
        exitEditMode();
      }
    }
  };

  const handleSubmitClick = () => {
    const selectedEndIsoDate = getEndDateForRequest();
    const selectedStartIsoDate = getStartDateForRequest();

    if (selectedEndIsoDate && selectedStartIsoDate) {
      // Both dates selected
      const selectedStartDate = new Date(selectedStartIsoDate);

      const formattedStartDate = dateFormatter.format(
        new Date(selectedStartIsoDate)
      );

      const selectedEndDate = new Date(selectedEndIsoDate);
      const formattedEndDate = dateFormatter.format(selectedEndDate);

      let formattedLabel = `${formattedStartDate} - ${formattedEndDate}`;
      let rawLabel = formattedLabel;

      if (isSameCalendarDates(selectedEndDate, selectedStartDate)) {
        formattedLabel = formatMessage(messages.singleDate, {
          date: formattedStartDate,
        });

        rawLabel = `On ${formattedStartDate}`;
      }

      if (
        selectedEndIsoDate !== origEndValueSelected ||
        selectedStartIsoDate !== origStartValueSelected
      ) {
        eventSourceStore.reset();
      }

      setTimePeriod({
        duration: formattedLabel,
        endDate: selectedEndIsoDate,
        startDate: selectedStartIsoDate,
      });

      trackDoraAction(
        isEditing ? 'summaryInputEditConfirmed' : 'customTimePeriodConfirmed',
        {
          doraSummaryInput: rawLabel,
        }
      );

      applyUpdatedValue();
    } else if (selectedEndIsoDate) {
      // Only end date selected
      const selectedEndDate = new Date(selectedEndIsoDate);
      const formattedEndDate = dateFormatter.format(selectedEndDate);

      const duration = formatMessage(messages.before, {
        date: formattedEndDate,
      });

      setTimePeriod({
        duration,
        endDate: selectedEndIsoDate,
      });

      if (selectedEndIsoDate !== origEndValueSelected) {
        eventSourceStore.reset();
      }

      trackDoraAction(
        isEditing ? 'summaryInputEditConfirmed' : 'customTimePeriodConfirmed',
        {
          doraSummaryInput: `Before ${formattedEndDate}`,
        }
      );

      applyUpdatedValue();
    } else if (selectedStartIsoDate) {
      // Only start date selected
      const selectedStartDate = new Date(selectedStartIsoDate);
      const formattedStartDate = dateFormatter.format(selectedStartDate);

      const duration = formatMessage(messages.after, {
        date: formattedStartDate,
      });

      setTimePeriod({
        duration,
        startDate: selectedStartIsoDate,
      });

      if (selectedStartIsoDate !== origStartValueSelected) {
        eventSourceStore.reset();
      }

      trackDoraAction(
        isEditing ? 'summaryInputEditConfirmed' : 'customTimePeriodConfirmed',
        {
          doraSummaryInput: `After ${formattedStartDate}`,
        }
      );

      applyUpdatedValue();
    }
  };

  let helpText = dateHelpText;

  if (isPostsInRangeError) {
    helpText = <TryAgainHelpText onClick={refetchPostsInRange} />;
  } else if (
    !hasPostsInRange &&
    !isPostsInRangeLoading &&
    !isFetchingRange &&
    isValidDatesSelected
  ) {
    helpText = formatMessage(messages.noPostsInRange);
  }

  const shouldAnimateOnMount =
    (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen);

  const intermediaryProps = {
    className: isEditing ? 'h-[172px]' : 'h-[134px]',
    isEditing,
    onCancel: handleCancel,
    shouldAnimateOnMount,
  };

  if (isLoadingInput) {
    return <LoadingBottomActionSheet {...intermediaryProps} />;
  }

  if (isError) {
    return (
      <ErrorBottomActionSheet {...intermediaryProps}>
        <FormattedMessage
          defaultMessage="Sorry, I had an issue fetching the time interval for this flow. Please <button>try again</button>."
          id="rOA7yx"
          values={{
            button: (text) => (
              <TextButton
                className="!text-gray-8"
                onClick={onTryAgain}
                underline
              >
                {text}
              </TextButton>
            ),
          }}
        />
      </ErrorBottomActionSheet>
    );
  }

  return (
    <BottomActionSheet
      isEditing={isEditing}
      onCancel={handleCancel}
      shouldAnimateOnMount={shouldAnimateOnMount}
    >
      <div className="mx-4 mt-2">
        <DoubleDatePicker
          firstDate={startDatePickerProps}
          helpText={helpText}
          isClearButtonDisabled={isClearButtonDisabled}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
          onClearClick={reset}
          onSubmitClick={handleSubmitClick}
          secondDate={endDatePickerProps}
        />
      </div>
    </BottomActionSheet>
  );
};
