import type { ExternalFileSource, Source } from '@assembly-web/services';
import { config, useUserDetails } from '@assembly-web/services';
import { Button, TextStyle } from '@assembly-web/ui';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { FilePreviewModalContext } from '../../../../contexts/FilePreviewModalContext';
import { trackDiscoverAction } from '../../services/analytics';
import { useRefetchResponse } from '../Drawers/AskDoraDrawer/hooks/useRefetchResponse';
import { SourceSwap } from '../Drawers/AskDoraDrawer/SourceSwap';
import { DoraFeedbackSection } from './DoraFeedbackSection';
import { DoraSourceLabel } from './DoraSourceLabel';
import { messages } from './messages';

type DoraResponseFooterProps = {
  canSwapSource?: boolean;
  className?: string;
  filterCount?: number;
  hideFeedbackLabel?: boolean;
  markdownResponse: string | null;
  onAdjustAnswerSettings?: () => void;
  onRefetch?: () => void;
  promptId: string | null;
  searchedSources?: Source[];
  sources: Source[];
};

export function DoraResponseFooter({
  canSwapSource,
  className,
  filterCount,
  hideFeedbackLabel,
  markdownResponse,
  onAdjustAnswerSettings,
  onRefetch,
  promptId,
  searchedSources = [],
  sources,
}: DoraResponseFooterProps) {
  const { data: userDetails } = useUserDetails();
  const { formatMessage } = useIntl();

  const { openModal } = useContext(FilePreviewModalContext);

  const refetchResponse = useRefetchResponse({ onRefetch, promptId });

  const handleOnSourceClick = (source: Source) => {
    let url = ``;
    const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath ?? '';

    if (source.type === 'file') {
      if (source.source === 'assembly' || !source.source) {
        const { flowId, name, blockId, responseId } = source;

        openModal({
          flowId,
          fileName: name,
          blockId,
          onUrlCallback: (url: string) =>
            trackDiscoverAction('sourceClicked', { sourceUrl: url }),
          responseId,
          workspaceSlugPath,
        });
      } else {
        const url = (source as ExternalFileSource).url;
        window.open(url, '_blank', 'noopener,noreferrer');
        trackDiscoverAction('sourceClicked', {
          sourceUrl: url,
        });
      }

      return;
    }

    if (source.type === 'response') {
      url = `${config.domains.app}/a/${workspaceSlugPath}/post/flow/flows?flowId=${source.flowId}&responseId=${source.id}`;
    }

    if (source.type === 'member') {
      url = `${config.domains.app}/a/${workspaceSlugPath}/user/${source.id}`;
    }

    window.open(url, '_blank', 'noopener,noreferrer');

    trackDiscoverAction('sourceClicked', {
      sourceUrl: url,
    });
  };

  return (
    <footer className="mb-1 mt-3">
      {sources.length > 0 && (
        <>
          <DoraFeedbackSection
            feedbackLabel={
              hideFeedbackLabel ? '' : formatMessage(messages.feedbackLabel)
            }
            filterCount={filterCount}
            onAdjustAnswerSettings={onAdjustAnswerSettings}
            promptId={promptId}
            markdownResponse={markdownResponse}
            className={twMerge(
              'mt-2 flex-col items-start md:flex-row md:items-center',
              className
            )}
          />
          <section className="flex items-center justify-between">
            <TextStyle
              className="mt-2 hidden text-gray-8 sm:hidden md:block"
              html={formatMessage(messages.sourcesLabel)}
            />
          </section>

          <TextStyle
            className="mt-2 text-gray-8 sm:block md:hidden"
            html={formatMessage(messages.sourcesLabel)}
          />
          <section className="mt-1 flex flex-wrap gap-1">
            {sources.map((source, index) => (
              <Button
                size="small"
                key={source.id}
                className="truncate text-sm font-normal"
                variation="secondaryLite"
                onClick={() => {
                  handleOnSourceClick(source);
                }}
              >
                <DoraSourceLabel index={index + 1} source={source} />
              </Button>
            ))}
            {Boolean(canSwapSource) && (
              <SourceSwap
                sources={searchedSources}
                refetchResponse={refetchResponse}
              />
            )}
          </section>
        </>
      )}
    </footer>
  );
}
