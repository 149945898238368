import { useDebounce, type UserDetails } from '@assembly-web/services';
import { AvatarSize, type CriteriaItemProps } from '@assembly-web/ui';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { generateSaveCriteriaPayloadFromRules } from '../../services/criteriaUtil';
import { usePollPreviewAPIForMembers } from '../shareModal/usePollPreviewAPIForMembers';
import type { GetMembersForPreviewRequest } from './queries/usePreviewCriteriaQuery';

const messages = defineMessages({
  viewers: {
    defaultMessage: 'Viewers',
    id: '37mth/',
  },
  collaborators: {
    defaultMessage: 'Collaborators',
    id: '9Jstnt',
  },
  all: {
    defaultMessage: 'All',
    id: 'zQvVDJ',
  },
  filterCount: {
    defaultMessage: ' • {count} {count,plural,=0{} one{person} other{people}}',
    id: 't9BQEm',
  },
  ownerSubtitle: {
    defaultMessage: '{subTitle} • Owner',
    id: 'FGuNqA',
  },
  currentUserLabel: {
    defaultMessage: '{currentUser} (You)',
    id: 'UfG6o8',
  },
});

export function usePreviewShareCollectionRules({
  rules,
  enabled,
  collectionOwnerId,
  currentUserDetails,
}: {
  rules: CriteriaItemProps[];
  enabled: boolean;
  collectionOwnerId: string | null;
  currentUserDetails: UserDetails;
}) {
  const {
    sharingRules: { include, exclude },
  } = generateSaveCriteriaPayloadFromRules(rules);

  const [previewMemberSearch, setPreviewMemberSearch] = useState('');
  const [previewMemberFilter, setPreviewMemberFilter] = useState('all');

  const debouncedPreviewMemberSearch = useDebounce(previewMemberSearch, 500);

  const { formatMessage } = useIntl();

  const previewFilterDefaultOptions = [
    {
      value: 'all',
      label: formatMessage(messages.all),
    },
    {
      value: 'permission:viewer',
      label: formatMessage(messages.viewers),
    },
    {
      value: 'permission:collaborator',
      label: formatMessage(messages.collaborators),
    },
  ];

  const previewCriteria: GetMembersForPreviewRequest = {
    criteriaType: 'simple',
    criteria: {
      include,
      exclude,
    },
  };

  const {
    data: members,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    totalMembersCount,
  } = usePollPreviewAPIForMembers({
    ownerId: collectionOwnerId ?? '',
    previewCriteria,
    previewMemberFilter: {
      search: debouncedPreviewMemberSearch,
      filter: previewMemberFilter,
    },
    enabled,
    flow: 'collection',
  });

  useEffect(() => {
    if (!enabled) {
      setPreviewMemberFilter('all');
      setPreviewMemberSearch('');
    }
  }, [enabled]);

  const previewFilterOptions = enabled
    ? previewFilterDefaultOptions.map((option) => {
        const isSelected = previewMemberFilter === option.value;
        const count = isSelected
          ? formatMessage(messages.filterCount, {
              count: totalMembersCount,
            })
          : '';
        const label = `${option.label} ${
          isSelected && !isLoading && totalMembersCount > 0 ? count : ''
        }`;
        return { ...option, label };
      })
    : previewFilterDefaultOptions;

  const ownerInformation: CriteriaItemProps = {
    id: currentUserDetails.member.memberId,
    title: currentUserDetails.member.profile.fullName,
    subtitle: formatMessage(messages.ownerSubtitle, {
      subTitle: currentUserDetails.member.email,
    }),
    avatar: {
      image: currentUserDetails.member.profile.image,
      memberID: currentUserDetails.member.memberId,
      name: `${currentUserDetails.member.profile.fullName}`,
      size: AvatarSize.ExtraLarge,
    },
  };

  const onSearchOrFilterList = ({
    searchTerm,
    filter,
  }: {
    searchTerm: string;
    filter: string;
  }) => {
    setPreviewMemberSearch(searchTerm);
    setPreviewMemberFilter(filter);
  };

  const displayRules = members.map((member) => {
    if (member.id === collectionOwnerId) {
      return {
        ...member,
        ...(currentUserDetails.member.memberId === collectionOwnerId
          ? {
              title: formatMessage(messages.currentUserLabel, {
                currentUser: ownerInformation.title,
              }),
            }
          : {}),
      };
    } else if (member.id === currentUserDetails.member.memberId) {
      return {
        ...member,
        title: formatMessage(messages.currentUserLabel, {
          currentUser: member.subtitle,
        }),
      };
    } else {
      return member;
    }
  });

  return {
    displayRules,
    previewFilterOptions,
    onSearchOrFilterList,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}
