import { gmailLogo, outlookLogo, yahooLogo } from '@assembly-web/assets';
import { Button, TextStyle } from '@assembly-web/ui';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackRegistrationAction } from '../../../services/analytics';

const messages = defineMessages({
  header: {
    defaultMessage: 'Choose your email provider to find your code:',
    id: 'oWrx0g',
  },
  cta: {
    defaultMessage: 'Open {provider}',
    id: 'fAk1L9',
  },
});

export function OpenMailProvider({ email }: { email: string }) {
  const { formatMessage } = useIntl();

  const isGmail = email.endsWith('@gmail.com');
  const isOutlookLive = email.includes('@live.');
  const isOutlookOffice = email.includes('.onmicrosoft.');
  const isYahooMail = email.includes('@yahoo.');

  const isKnownProvider = [
    isGmail,
    isOutlookLive,
    isOutlookOffice,
    isYahooMail,
  ].includes(true);

  const openMail = useCallback(function (
    provider: 'gmail' | 'outlook-live' | 'outlook-office' | 'yahoo'
  ) {
    let url;
    if (provider === 'gmail') {
      url =
        'https://mail.google.com/mail/u/0/#advanced-search/from=no-reply%40joinassembly.com&subset=all&has=Verify+your+email+with+Assembly&within=1d&sizeoperator=s_sl&sizeunit=s_smb&query=from%3A(no-reply%40joinassembly.com)+Verify+your+email+with+Assembly';
    } else if (provider === 'outlook-live') {
      url = 'https://outlook.live.com/mail/';
    } else if (provider === 'outlook-office') {
      url = 'https://outlook.office.com/mail/';
    } else {
      url = 'https://mail.yahoo.com/';
    }
    window.open(url, '_blank', 'noopener,noreferrer')?.focus();
  }, []);

  const GmailButton = () => (
    <Button
      variation="secondaryLite"
      isFullWidth
      onClick={() => {
        trackRegistrationAction('emailProviderClicked', {
          emailProviderClicked: 'gmail',
        });
        openMail('gmail');
      }}
    >
      <img className="h-fit w-fit" src={gmailLogo} role="presentation" alt="" />
      {formatMessage(messages.cta, { provider: 'Gmail' })}
      <ArrowTopRightOnSquareIcon className="h-4 pl-2" />
    </Button>
  );

  const OutlookLiveButton = () => (
    <Button
      variation="secondaryLite"
      isFullWidth
      onClick={() => {
        trackRegistrationAction('emailProviderClicked', {
          emailProviderClicked: 'outlook',
        });
        openMail('outlook-live');
      }}
    >
      <img
        className="h-fit w-fit"
        src={outlookLogo}
        role="presentation"
        alt=""
      />
      {formatMessage(messages.cta, { provider: 'Outlook' })}
      <ArrowTopRightOnSquareIcon className="h-4 pl-2" />
    </Button>
  );

  const OutlookOfficeButton = () => (
    <Button
      variation="secondaryLite"
      isFullWidth
      onClick={() => {
        trackRegistrationAction('emailProviderClicked', {
          emailProviderClicked: 'outlook',
        });
        openMail('outlook-office');
      }}
    >
      <img
        className="h-fit w-fit"
        src={outlookLogo}
        role="presentation"
        alt=""
      />
      {formatMessage(messages.cta, { provider: 'Outlook' })}
      <ArrowTopRightOnSquareIcon className="h-4 pl-2" />
    </Button>
  );

  const YahooButton = () => (
    <Button
      variation="secondaryLite"
      isFullWidth
      onClick={() => {
        trackRegistrationAction('emailProviderClicked', {
          emailProviderClicked: 'yahoo',
        });
        openMail('yahoo');
      }}
    >
      <img className="h-fit w-fit" src={yahooLogo} role="presentation" alt="" />
      {formatMessage(messages.cta, { provider: 'Yahoo' })}
      <ArrowTopRightOnSquareIcon className="h-4 pl-2" />
    </Button>
  );

  return (
    <div className="mt-6 space-y-2">
      {isKnownProvider ? (
        <>
          {Boolean(isYahooMail) && <YahooButton />}
          {Boolean(isOutlookOffice) && <OutlookOfficeButton />}
          {Boolean(isOutlookLive) && <OutlookLiveButton />}
          {Boolean(isGmail) && <GmailButton />}
        </>
      ) : (
        <>
          <TextStyle variant="base-medium">
            {formatMessage(messages.header)}
          </TextStyle>
          <GmailButton />
          <OutlookLiveButton />
          <YahooButton />
        </>
      )}
    </div>
  );
}
