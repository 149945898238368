import { type CustomFocusQuestionProps, FocusType } from '../../../../types';
import { Message } from './Message';

export const CustomFocusQuestion = (props: CustomFocusQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useFocusTypeSetting },
  } = props;

  const isOrigFocusTypeSet = useFocusTypeSetting((store) => store.isSet);
  const origFocusTypeValue = useFocusTypeSetting((store) => store.value);

  const isEditingPrevSetting = useEditSettingsStore(
    (store) =>
      store.isEditingRespondents() ||
      store.isEditingBlocks() ||
      store.settingEditing === 'focusType'
  );

  const isEditedFocusTypeSet = useEditSettingsStore(
    (store) => store.focusType.isSet
  );

  const isSet = isEditedFocusTypeSet || isOrigFocusTypeSet;

  const editedFocusTypeValue = useEditSettingsStore(
    (store) => store.focusType.value
  );

  const value = editedFocusTypeValue ?? origFocusTypeValue;

  return (
    isSet &&
    value === FocusType.Custom &&
    !isEditingPrevSetting && <Message {...props} />
  );
};
