import { usePusherChannel, usePusherEvents } from '@assembly-web/pusher';
import { logger, useUserDetails } from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';

import { useActionBarQuery } from '../useActionBarQuery';
import { useGlobalFilter } from '../useGlobalFilter';

export function useActionBarUpdatesEvents() {
  const filter = useGlobalFilter();

  const { refetch } = useActionBarQuery({
    enabled: filter === GlobalFilterOption.All || !filter,
  });

  const { data: userDetails } = useUserDetails();

  const memberChannel = usePusherChannel(
    `private-member-${userDetails?.member.memberId}`
  );

  const assemblyChannel = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  const memberEvents = ['NEW_FLOW_RESPONSE'];

  const assemblyEvents = [
    'FLOW_PUBLISHED',
    'OCCURRENCE_ENDED',
    'OCCURRENCE_CREATED',
    'SHARE_REQUEST_APPROVED',
    'ASSEMBLY_MEMBER_DEACTIVATED',
    'FLOW_ARCHIVED',
    'FLOW_UNARCHIVED',
  ];

  usePusherEvents(memberChannel, memberEvents, async () => {
    try {
      await refetch();
    } catch (e) {
      logger.error('Refetch error on new flow events', {}, e as Error);
    }
  });

  usePusherEvents(assemblyChannel, assemblyEvents, async () => {
    try {
      await refetch();
    } catch (e) {
      logger.error('Refetch error on new flow events', {}, e as Error);
    }
  });
}
