import type { ButtonProps, ButtonVariations } from '@assembly-web/ui';
import {
  Button,
  classNames,
  LoadingSpinner,
  TextStyle,
  Tooltip,
} from '@assembly-web/ui';
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import type { Ref } from 'react';
import { forwardRef, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ConnectionState } from '../types';

const messages = defineMessages({
  broken: {
    defaultMessage: 'Broken',
    id: 'LQxPj3',
  },
  connected: {
    defaultMessage: 'Connected',
    id: 'IvjoDS',
  },
  disconnect: {
    defaultMessage: 'Disconnect',
    id: 'qj1uhz',
  },
  disconnecting: {
    defaultMessage: 'Disconnecting',
    id: 'WNpMXw',
  },
  interruptedConnection: {
    defaultMessage:
      'Your connection has been interrupted, reconnect to get your latest content',
    id: 'Aj7kQj',
  },
  reconnect: {
    defaultMessage: 'Reconnect',
    id: 'Eg11ZY',
  },
  removingFiles: {
    defaultMessage:
      'Your content from this connection is in the process of being removed.',
    id: 'posOUj',
  },
  waitOnSyncing: {
    defaultMessage: 'Wait until syncing is finished to disconnect',
    id: 'YMlRtz',
  },
});

const StatusBaseButton = forwardRef(
  (props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    return (
      <Button
        size="small"
        {...props}
        ref={ref}
        className={classNames('group w-[137px]', props.className)}
      >
        {props.children}
      </Button>
    );
  }
);

StatusBaseButton.displayName = 'StatusBaseButton';

function BrokenStatusButton() {
  const { formatMessage } = useIntl();
  const ref = useRef(null);

  const [variation, setVariation] =
    useState<(typeof ButtonVariations)[number]>('danger');

  const showHoverVariation = () => setVariation('secondaryEmphasized');

  return (
    <Tooltip
      align="end"
      tooltipText={formatMessage(messages.interruptedConnection)}
    >
      <StatusBaseButton
        onBlur={() => setVariation('danger')}
        onFocus={showHoverVariation}
        onMouseEnter={showHoverVariation}
        onMouseLeave={() => {
          if (ref.current !== document.activeElement) {
            setVariation('danger');
          }
        }}
        ref={ref}
        variation={variation}
        // TODO: Implement click handler to reconnect.
        // https://joinassembly.atlassian.net/browse/APP-10302
      >
        <div className="flex w-full items-center justify-center group-hover:hidden group-focus:hidden">
          <InformationCircleIcon className="h-4 w-4 border-none stroke-2 text-gray-1" />
          <TextStyle as="span" className="ml-2 text-gray-1" variant="sm-medium">
            {formatMessage(messages.broken)}
          </TextStyle>
        </div>
        <div className="hidden group-hover:flex group-hover:w-full group-hover:items-center group-hover:justify-center group-focus:flex group-focus:w-full group-focus:items-center group-focus:justify-center">
          <TextStyle as="span" variant="sm-medium">
            {formatMessage(messages.reconnect)}
          </TextStyle>
        </div>
      </StatusBaseButton>
    </Tooltip>
  );
}

function ConnectedStatusButton({
  onDisconnectClick,
}: {
  onDisconnectClick: () => void;
}) {
  const { formatMessage } = useIntl();
  const ref = useRef(null);

  const [variation, setVariation] =
    useState<(typeof ButtonVariations)[number]>('success');

  const showHoverVariation = () => setVariation('danger');

  return (
    <StatusBaseButton
      onFocus={showHoverVariation}
      onBlur={() => setVariation('success')}
      onClick={onDisconnectClick}
      onMouseEnter={showHoverVariation}
      onMouseLeave={() => {
        if (ref.current !== document.activeElement) {
          setVariation('success');
        }
      }}
      ref={ref}
      variation={variation}
    >
      <div className="flex w-full items-center justify-center group-hover:hidden group-focus:hidden">
        <CheckCircleIcon className="mr-2 h-4 w-4 rounded-lg border-none bg-success-7 stroke-2 text-gray-1" />
        <TextStyle as="span" variant="sm-medium">
          {formatMessage(messages.connected)}
        </TextStyle>
      </div>
      <div className="hidden group-hover:flex group-hover:w-full group-hover:items-center group-hover:justify-center group-focus:flex group-focus:w-full group-focus:items-center group-focus:justify-center">
        <XMarkIcon className="mr-2 h-4 w-4 border-none stroke-2 text-gray-1" />
        <TextStyle as="span" className="text-gray-1" variant="sm-medium">
          {formatMessage(messages.disconnect)}
        </TextStyle>
      </div>
    </StatusBaseButton>
  );
}

function DisconnectingStatusButton() {
  const { formatMessage } = useIntl();

  return (
    <Tooltip tooltipText={formatMessage(messages.removingFiles)} align="end">
      <StatusBaseButton disabled variation="tertiaryLite">
        <TextStyle
          className="flex items-center justify-center text-error-6"
          variant="sm-regular"
        >
          <LoadingSpinner className="mr-2" />
          {formatMessage(messages.disconnecting)}
        </TextStyle>
      </StatusBaseButton>
    </Tooltip>
  );
}

function SyncingStatusButton() {
  const { formatMessage } = useIntl();

  return (
    <Tooltip tooltipText={formatMessage(messages.waitOnSyncing)}>
      <StatusBaseButton disabled variation="secondaryLite">
        <div className="flex w-full items-center justify-center">
          <CheckCircleIcon className="mr-2 h-4 w-4 rounded-lg border-none bg-gray-7 stroke-2 text-gray-2" />
          <TextStyle as="span" className="text-gray-7" variant="sm-medium">
            {formatMessage(messages.connected)}
          </TextStyle>
        </div>
      </StatusBaseButton>
    </Tooltip>
  );
}

export function StatusButton({
  connectionState,
  onDisconnectClick,
}: {
  connectionState: ConnectionState;
  onDisconnectClick: () => void;
}) {
  switch (connectionState) {
    case ConnectionState.Connected:
      return <ConnectedStatusButton onDisconnectClick={onDisconnectClick} />;
    case ConnectionState.ConnectionBroken:
      return <BrokenStatusButton />;
    case ConnectionState.Disconnecting:
      return <DisconnectingStatusButton />;
    case ConnectionState.Syncing:
      return <SyncingStatusButton />;
    default:
      return null;
  }
}
