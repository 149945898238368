import { useEffect } from 'react';

import type {
  EditSettingsStoreType,
  FormSettingsStoreType,
  MemberAvatarProps,
} from '../../types';
import { ConfirmAnswer } from './ConfirmAnswer';
import { ConfirmMessage } from './ConfirmMessage';

export const ConfirmSettings = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    formSettingsStore: FormSettingsStoreType;
  }
) => {
  const { editSettingsStore: useEditSettingsStore, formSettingsStore } = props;

  const { useCustomTimePeriodSetting, useSettingsConfirmationSetting } =
    formSettingsStore;

  const isTimePeriodSet = useCustomTimePeriodSetting((store) => store.isSet);

  const setShouldRequestInput = useSettingsConfirmationSetting(
    (store) => store.setShouldRequestInput
  );

  const isConfirmed = useSettingsConfirmationSetting((store) => store.value);

  const settingEditing = useEditSettingsStore((store) => store.settingEditing);

  useEffect(() => {
    if (isTimePeriodSet && !settingEditing) {
      // Show confirm actions sequentially (after ready message from Dora is displayed).
      setTimeout(() => {
        setShouldRequestInput(true);
      }, 1700);
    }
  }, [isTimePeriodSet, setShouldRequestInput, settingEditing]);

  return (
    isTimePeriodSet &&
    !settingEditing && (
      <>
        <ConfirmMessage formSettingsStore={formSettingsStore} />
        {Boolean(isConfirmed) && <ConfirmAnswer {...props} />}
      </>
    )
  );
};
