import type { UserDetails } from '@assembly-web/services';
import { defineMessages, useIntl } from 'react-intl';

import { isAdminMember } from '../../services/member';

const messages = defineMessages({
  milestonesCategoryDescriptionForAdmins: {
    defaultMessage: 'Ask about upcoming birthdays and anniversaries!',
    id: '10h9M8',
  },
  milestonesCategoryDescriptionForNonAdminManagers: {
    defaultMessage: `See your team's upcoming birthdays and anniversaries!`,
    id: 'MvpBmu',
  },
  milestonesCategoryTitle: {
    defaultMessage: 'Milestones',
    id: 'xpcHBZ',
  },
  participationCategoryDescriptionForAdmins: {
    defaultMessage:
      'Ask about flows created and participation levels across departments and teams',
    id: 'GiNTn9',
  },
  participationCategoryDescriptionForNonAdminManagers: {
    defaultMessage: `Ask about your team's participation levels in flows you created and flows you can see`,
    id: '/ZyMtD',
  },
  participationCategoryTitle: {
    defaultMessage: 'Flow engagement',
    id: 'JJOnra',
  },
  recognitionCategoryDescriptionForAdmins: {
    defaultMessage:
      'Ask about recognition given and received across departments and teams',
    id: 'aNwAq7',
  },
  recognitionCategoryDescriptionForNonAdminManagers: {
    defaultMessage:
      'Ask about recognition given and received by your team members',
    id: 'xmr86a',
  },
  recognitionCategoryTitle: {
    defaultMessage: 'Recognition stats',
    id: 'WOkqqS',
  },
  rewardRedemptionsCategoryDescription: {
    defaultMessage: 'Ask about rewards redeemed using {points} earned',
    id: 'daSnRr',
  },
  rewardRedemptionsCategoryTitle: {
    defaultMessage: 'Reward redemptions',
    id: '/VuUN9',
  },
});

export function useChatDisplayText(userDetails: UserDetails | undefined) {
  const { formatMessage } = useIntl();

  const isAdmin = userDetails ? isAdminMember(userDetails.member) : false;

  return {
    recognitionTitle: formatMessage(messages.recognitionCategoryTitle),
    recognitionDescription: formatMessage(
      isAdmin
        ? messages.recognitionCategoryDescriptionForAdmins
        : messages.recognitionCategoryDescriptionForNonAdminManagers
    ),

    participationTitle: formatMessage(messages.participationCategoryTitle),
    participationDescription: formatMessage(
      isAdmin
        ? messages.participationCategoryDescriptionForAdmins
        : messages.participationCategoryDescriptionForNonAdminManagers
    ),

    milestonesTitle: formatMessage(messages.milestonesCategoryTitle),
    milestonesDescription: formatMessage(
      isAdmin
        ? messages.milestonesCategoryDescriptionForAdmins
        : messages.milestonesCategoryDescriptionForNonAdminManagers
    ),

    rewardRedemptionsTitle: formatMessage(
      messages.rewardRedemptionsCategoryTitle
    ),
    rewardRedemptionsDescription: formatMessage(
      messages.rewardRedemptionsCategoryDescription,
      { points: userDetails?.assembly.currency.pluralName ?? 'points' }
    ),
  };
}
