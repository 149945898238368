import { useEffect, useState } from 'react';

export function useLoadingDotsAnimation() {
  const [dots, setDots] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(incrementDots, 300);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const incrementDots = () => {
    setDots((prevDots) => (prevDots % 3) + 1);
  };

  return dots;
}
