import { useUserDetails } from '@assembly-web/services';
import {
  DrawerCategoryOption,
  HorizontalRule,
  TabGroupItem,
  TabsGroup,
  TextStyle,
} from '@assembly-web/ui';
import {
  ChatBubbleLeftRightIcon,
  GiftIcon,
  TableCellsIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useChatDisplayText } from '../../../hooks/dora/useChatDisplayText';
import { useDoraChatRestrictions } from '../../../hooks/dora/useDoraChatRestrictions';
import { trackDoraAction } from '../../../services/analytics';
import { isAdminMember } from '../../../services/member';
import type { createDoraDrawerStore } from '../../../stores/doraChatStore';
import type { DoraChatDrawer as DoraChatDrawerProps } from '../types';
import { BetaTag } from './BetaTag';
import { ReportingCollections } from './ReportingCollections';
import { ReportingUpgradeBanner } from './ReportingUpgradeBanner';

const messages = defineMessages({
  reportingInsightsDescription: {
    defaultMessage:
      'Generate charts, graphs, and tables based on your most pressing questions about your team.',
    id: 'Mast1k',
  },
  reportingInsightsTitle: {
    defaultMessage: 'Welcome to DoraAI Reporting and Insights',
    id: 'pXC/1M',
  },
  reportingInsightsTabLabel: {
    defaultMessage: 'Reporting & Insights',
    id: 'WAbSLD',
  },
  tabsLabel: {
    defaultMessage: 'You are asking about',
    id: 'EIxjl3',
  },
});

export function ReportingInsightsHomeScreen(
  props: DoraChatDrawerProps & {
    doraChatStore: ReturnType<typeof createDoraDrawerStore>;
  }
) {
  const { doraChatStore, isFullScreen, id: promptId } = props;
  const { formatMessage } = useIntl();

  const [showUpgradeBanner, setShowUpgradeBanner] = useState(false);

  const betaCategoryTag = <BetaTag className="bg-gray-8 text-gray-1" />;

  const { data: userDetails } = useUserDetails();

  const displayText = useChatDisplayText(userDetails);

  const { isLimitedReportingExperience } = useDoraChatRestrictions();

  if (!userDetails) {
    return;
  }

  const isAdmin = isAdminMember(userDetails.member);

  const recognitionOption = (
    <DrawerCategoryOption.Root
      className={twMerge(
        'border-error-2 hover:from-error-2/20 hover:to-magenta-3/20 focus:from-error-2/40 focus:to-magenta-3/40',
        isAdmin ? 'md:w-1/2' : 'w-full'
      )}
      description={displayText.recognitionDescription}
      icon={
        <DrawerCategoryOption.Icon className="from-error-2 to-magenta-3">
          <TrophyIcon className="h-5 w-5 stroke-magenta-9 stroke-2" />
        </DrawerCategoryOption.Icon>
      }
      onClick={() => {
        doraChatStore.getState().setReportingCategory('recognition');
        trackDoraAction('recognitionReportingCategoryClicked');
      }}
      tag={betaCategoryTag}
      title={
        <DrawerCategoryOption.Title variant="lg-bold">
          {displayText.recognitionTitle}
        </DrawerCategoryOption.Title>
      }
    />
  );

  const participationOption = (
    <DrawerCategoryOption.Root
      className="w-full border-cyan-3 hover:from-cyan-3/20 hover:to-blue-2/20 focus:from-cyan-3/40 focus:to-blue-2/40 md:w-1/2"
      description={displayText.participationDescription}
      icon={
        <DrawerCategoryOption.Icon className="from-cyan-3 to-blue-2">
          <ChatBubbleLeftRightIcon className="h-5 w-5 stroke-cyan-9 stroke-2" />
        </DrawerCategoryOption.Icon>
      }
      onClick={() => {
        doraChatStore.getState().setReportingCategory('flows');
        trackDoraAction('flowEngagementReportingCategoryClicked');
      }}
      tag={betaCategoryTag}
      title={
        <DrawerCategoryOption.Title variant="lg-bold">
          {displayText.participationTitle}
        </DrawerCategoryOption.Title>
      }
    />
  );

  const milestonesOption = (
    <DrawerCategoryOption.Root
      className="w-full border-success-3 hover:from-success-3/20 hover:to-cyan-2/20 focus:from-success-3/40 focus:to-cyan-2/40 md:w-1/2"
      description={displayText.milestonesDescription}
      icon={
        <DrawerCategoryOption.Icon className="from-success-3 to-cyan-2">
          <TableCellsIcon className="h-5 w-5 stroke-success-9 stroke-2" />
        </DrawerCategoryOption.Icon>
      }
      onClick={() => {
        doraChatStore.getState().setReportingCategory('users');
        trackDoraAction('milestonesReportingCategoryClicked');
      }}
      tag={betaCategoryTag}
      title={
        <DrawerCategoryOption.Title variant="lg-bold">
          {displayText.milestonesTitle}
        </DrawerCategoryOption.Title>
      }
    />
  );

  const rewardRedemptionsOption = (
    <DrawerCategoryOption.Root
      className="w-full border-purple-3 hover:from-purple-3/20 hover:to-cyan-2/20 focus:from-purple-3/40 focus:to-cyan-2/40 md:w-1/2"
      description={displayText.rewardRedemptionsDescription}
      icon={
        <DrawerCategoryOption.Icon className="from-purple-3 to-cyan-2">
          <GiftIcon className="h-5 w-5 stroke-purple-9 stroke-2" />
        </DrawerCategoryOption.Icon>
      }
      onClick={() => {
        doraChatStore.getState().setReportingCategory('reward_redemption');
        trackDoraAction('rewardRedemptionsReportingCategoryClicked');
      }}
      tag={betaCategoryTag}
      title={
        <DrawerCategoryOption.Title variant="lg-bold">
          {displayText.rewardRedemptionsTitle}
        </DrawerCategoryOption.Title>
      }
    />
  );

  return (
    <div className="flex h-full flex-col">
      <div className="flex w-full flex-col items-center justify-center gap-y-1 border-b border-b-gray-5 bg-gray-1 p-4">
        <TextStyle variant="xs-regular" subdued>
          {formatMessage(messages.tabsLabel)}
        </TextStyle>
        <TabsGroup type="single" value="reportingInsights">
          <TabGroupItem
            className="flex flex-row items-center gap-x-2"
            value="reportingInsights"
          >
            {formatMessage(messages.reportingInsightsTabLabel)}
            <BetaTag className="bg-gray-1 text-gray-9" />
          </TabGroupItem>
        </TabsGroup>
      </div>
      <div
        className={twMerge(
          'relative flex h-full w-full flex-col items-center overflow-auto bg-gray-2',
          isFullScreen ? 'p-10' : 'px-10 pb-5 pt-2'
        )}
      >
        {showUpgradeBanner ? (
          <ReportingUpgradeBanner
            isFullScreen={Boolean(isFullScreen)}
            closeBanner={() => setShowUpgradeBanner(false)}
          />
        ) : (
          <div className="relative h-full w-full max-w-[672px]">
            <div className="flex flex-col items-center justify-center px-6 pb-2 pt-3 text-center">
              <TextStyle variant="lg-bold">
                {formatMessage(messages.reportingInsightsTitle)}
              </TextStyle>
              <TextStyle variant="sm-regular" subdued>
                {formatMessage(messages.reportingInsightsDescription)}
              </TextStyle>
            </div>
            <div className="mt-3 flex flex-col gap-3">
              <div
                className={twMerge(
                  isAdmin && 'space-y-3 md:flex md:gap-3 md:space-y-0'
                )}
              >
                {recognitionOption}
                {Boolean(isAdmin) && rewardRedemptionsOption}
              </div>
              <div className="space-y-3 md:flex md:gap-3 md:space-y-0">
                {participationOption}
                {milestonesOption}
              </div>
            </div>

            <HorizontalRule className="my-3 w-full" />
            <ReportingCollections
              currentDrawerId={promptId}
              isLimitedReportingExperience={isLimitedReportingExperience}
              openUpgradeBanner={() => setShowUpgradeBanner(true)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
