import {
  type FlowCollaborator,
  type MemberCriteriaAPIResponse,
  type MemberDetails,
  MemberState,
  Operator,
  PermissionType,
} from '@assembly-web/services';
import type { CriteriaItemProps } from '@assembly-web/ui';

function isMemberDetails(user: unknown): user is MemberDetails {
  return user != null && typeof user === 'object' && 'memberState' in user;
}

export function formatUserToFlowCollaborator(
  arg: MemberDetails | CriteriaItemProps
): FlowCollaborator {
  if (isMemberDetails(arg)) {
    return {
      email: arg.email ?? '',
      name: arg.name,
      image: arg.image ?? '',
      memberID: arg.memberID,
      state: arg.memberState,
    };
  }
  return {
    email: arg.subtitle ?? '',
    image: arg.avatar?.image ?? '',
    memberID: arg.metaData?.value ?? '',
    name: arg.metaData?.name ?? '',
    state: arg.state ?? MemberState.Active,
  };
}

export function formatFlowCollaboratorToCriteriaAPIResponse(
  user: FlowCollaborator,
  ownerId: string
): MemberCriteriaAPIResponse {
  const isOwner = user.memberID === ownerId;

  return {
    meta: {
      email: user.email,
      image: user.image,
      memberId: user.memberID,
      name: user.name,
      role: [],
      state: user.state,
    },
    operator: Operator.Is,
    perm: isOwner ? PermissionType.Owner : PermissionType.Collaborator,
    value: user.memberID,
  };
}
