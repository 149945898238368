import {
  APIEndpoints,
  type FlowPostResponse,
  getMemberDetailsFromUserDetails,
  type GetRepliesResponse,
  type RepliesResponse,
  type UserFeedApiResponse,
} from '@assembly-web/services';
import { assemblyAPI, useUserDetails } from '@assembly-web/services';
import type {
  ConversationCardMemberDetails,
  MemberDetailsForViewProfile,
  Reply,
} from '@assembly-web/ui';
import { getFormattedMessage } from '@assembly-web/ui';
import {
  type InfiniteData,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { produce } from 'immer';
import invariant from 'tiny-invariant';

import type { SearchIndexApiResponse } from '../useSearchIndex';
import type {
  AddReplyPayload,
  DeleteReplyPayload,
  DraftReplyInEditStatusPayload,
  GetPostRepliesPayload,
  UpdateReplyPayload,
} from './types';
import {
  addReplyToEmptyPostReplies,
  addReplyToPostReplies,
  deleteReplyFromPostReplies,
  generateOptimisticReply,
  updatePostWithDraftStatus,
  updateSearchFeedReplyCountStatus,
  updateSearchFeedWithDraftStatus,
  updateUserFeedReplyCountStatus,
  updateUserFeedWithDraftStatus,
} from './utils';

export function useGetPostRepliesInfiniteQuery(
  payload: GetPostRepliesPayload,
  options?: UseInfiniteQueryOptions<
    GetRepliesResponse,
    unknown,
    GetRepliesResponse,
    GetRepliesResponse,
    string[]
  >
) {
  const { flowId, responseId, commentId } = payload;

  const result = useInfiniteQuery({
    ...options,
    queryKey: ['replies', flowId, responseId],
    queryFn: async ({ pageParam = '' }) => {
      const { data } = await assemblyAPI.get<GetRepliesResponse>(
        flowId === 'recognition'
          ? APIEndpoints.getRepliesForRecognitionPost(responseId)
          : APIEndpoints.getRepliesForFlowPost(flowId, responseId),
        {
          params: {
            ...(pageParam ? { cursor: pageParam, limit: 10 } : { limit: 10 }),
            ...(Boolean(!pageParam) && commentId ? { commentId } : {}),
          },
        }
      );

      return data satisfies GetRepliesResponse;
    },
    getNextPageParam: (page) => page.metadata.pagination.cursor.next,
    getPreviousPageParam: (page) => page.metadata.pagination.cursor.previous,
    refetchOnWindowFocus: false,
    enabled: options?.enabled ?? true,
  });

  const getReplies = () => {
    if (!result.data) {
      return [];
    }

    return result.data.pages
      .reduce<Reply[]>((acc, page) => {
        return [
          ...acc,
          ...page.data.map((reply) => {
            const isEdited = Boolean(reply.editedAt);
            let memberDetails: ConversationCardMemberDetails<
              MemberDetailsForViewProfile & {
                isAnonymous: false;
                name: string;
              }
            >;
            if ('anonymousIdentifier' in reply.fromMember) {
              memberDetails = {
                isAnonymous: true,
                createdAt: reply.createdAt,
                memberID: reply.fromMember.anonymousIdentifier,
              };
            } else {
              const {
                name,
                image,
                memberID,
                lastName,
                pronouns,
                firstName,
                memberState,
                email,
                department,
                jobTitle,
              } = reply.fromMember;
              memberDetails = {
                isAnonymous: false,
                createdAt: reply.createdAt,
                name,
                image,
                memberID,
                lastName,
                pronouns,
                firstName,
                memberState,
                email,
                department,
                jobTitle,
              };
            }
            return {
              cardId: reply.commentID,
              reactions: reply.reactions,
              pointsEach: reply.pointsEach,
              messageContent:
                reply.version === 2 ? (reply.messageHtml ?? '') : '',
              memberDetails: {
                ...memberDetails,
              },
              isEdited,
              version: reply.version,
              gifURL: reply.gifURL,
              canEdit: reply.canEdit,
              taggedUsers: reply.taggedUsers,
              boost: reply.boost,
              messageNode:
                reply.version !== 2
                  ? getFormattedMessage(
                      {
                        mentions: reply.taggedUsers.map((user) => user),
                        value: reply.message ?? '',
                      },
                      () => {
                        //Todo: Fix this handler once Arun adds the handler
                      },
                      {}
                    )
                  : undefined,
            };
          }),
        ];
      }, [])
      .sort(
        (a, b) =>
          new Date(a.memberDetails.createdAt).getTime() -
          new Date(b.memberDetails.createdAt).getTime()
      );
  };

  return {
    replies: getReplies(),
    ...result,
  };
}

export function useAddReplyMutation({
  flowId,
  responseId,
  onError,
}: {
  flowId: string;
  responseId: string;
  onError: (err: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const { data: userDetails } = useUserDetails({ suspense: false });
  const userFeedKeys = queryCache
    .findAll(['userFeed'])
    .map((query) => query.queryKey);
  const searchFeedKeys = queryCache
    .findAll(['searchResults'])
    .map((query) => query.queryKey);

  return useMutation({
    mutationFn: async (payload: AddReplyPayload) => {
      return await assemblyAPI.post(APIEndpoints.addReply, payload);
    },
    onSuccess: async () => {
      const replySummaryQueryKey = [
        'assemblyFlowPostReplies',
        flowId,
        responseId,
      ];
      const previousReplySummary =
        queryClient.getQueryData<RepliesResponse>(replySummaryQueryKey);
      if (previousReplySummary) {
        const updatedReplySummary = produce(previousReplySummary, (draft) => {
          draft.count += 1;
        });

        queryClient.setQueryData(replySummaryQueryKey, updatedReplySummary);
      }

      updateUserFeedReplyCountStatus(
        userFeedKeys,
        queryClient,
        responseId,
        'addReply'
      );

      updateSearchFeedReplyCountStatus(
        searchFeedKeys,
        queryClient,
        responseId,
        'addReply'
      );

      await Promise.all([
        queryClient.invalidateQueries(['replies', flowId, responseId]),
        queryClient.invalidateQueries(['userDetails']),
      ]);

      return { previousReplySummary };
    },
    onMutate: async (payload) => {
      const queryKey = ['replies', flowId, responseId];
      await queryClient.cancelQueries(queryKey);
      const previousReplies = queryClient.getQueryData<
        InfiniteData<GetRepliesResponse>
      >(['replies', flowId, responseId]);

      if (userDetails) {
        const optimisticReply = generateOptimisticReply(
          payload,
          userDetails,
          responseId
        );

        let optimisticReplies: InfiniteData<GetRepliesResponse>;
        if (previousReplies) {
          optimisticReplies = addReplyToPostReplies(
            optimisticReply,
            previousReplies
          );
        } else {
          optimisticReplies = addReplyToEmptyPostReplies(optimisticReply);
        }

        queryClient.setQueryData(queryKey, optimisticReplies);
      }

      const postQueryKey = ['assemblyFlowPost', flowId, responseId];

      const previousPost =
        queryClient.getQueryData<FlowPostResponse>(postQueryKey);
      const previousUserFeed: InfiniteData<UserFeedApiResponse> | undefined =
        queryClient.getQueryData(userFeedKeys);
      const previousSearchFeed =
        queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
          searchFeedKeys
        );

      updatePostWithDraftStatus(postQueryKey, queryClient, responseId, false);
      updateUserFeedWithDraftStatus(
        userFeedKeys,
        queryClient,
        responseId,
        false
      );
      updateSearchFeedWithDraftStatus(
        searchFeedKeys,
        queryClient,
        responseId,
        false
      );

      return {
        previousPost,
        previousReplies,
        previousUserFeed,
        previousSearchFeed,
      };
    },
    onError: (err, payload, context) => {
      onError(err);
      if (!context) {
        return;
      }

      const userFeedKeys = queryCache
        .findAll(['userFeed'])
        .map((query) => query.queryKey);
      const searchFeedKeys = queryCache
        .findAll(['searchResults'])
        .map((query) => query.queryKey);
      const postQueryKey = ['assemblyFlowPost', flowId, responseId];

      queryClient.setQueryData(
        ['replies', flowId, responseId],
        context.previousReplies
      );
      queryClient.setQueryData(postQueryKey, context.previousPost);
      queryClient.setQueryData(userFeedKeys, context.previousUserFeed);
      queryClient.setQueryData(searchFeedKeys, context.previousSearchFeed);
    },
  });
}

export function useUpdateReplyMutation({
  flowId,
  onError,
  responseId,
}: {
  flowId: string;
  responseId: string;
  onError: (e: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const { data: userDetails } = useUserDetails();
  invariant(userDetails);

  return useMutation({
    mutationFn: async (payload: UpdateReplyPayload) => {
      return await assemblyAPI.put(
        APIEndpoints.updateReply(payload.replyId),
        payload
      );
    },
    onMutate: async (payload) => {
      await queryClient.cancelQueries(['replies', flowId, responseId]);
      const previousReplies = queryClient.getQueryData<
        InfiniteData<GetRepliesResponse>
      >(['replies', flowId, responseId]);
      if (!previousReplies) {
        return;
      }

      const updatedReplies: InfiniteData<GetRepliesResponse> = produce(
        previousReplies,
        (draft) => {
          return {
            ...draft,
            pages: draft.pages.map((page) => {
              return {
                ...page,
                data: page.data.map((reply) => {
                  if (reply.commentID === payload.replyId) {
                    return {
                      ...reply,
                      isEditing: true,
                      isSaving: true,
                      editedAt: new Date().toISOString(),
                      updatedAt: new Date().toISOString(),
                      messageHtml: payload.messageHtml,
                      messageTokens: payload.messageTokens,
                      fromMember: payload.isAnonymous
                        ? {
                            anonymousIdentifier:
                              userDetails.member.anonymousIdentifier ?? '',
                          }
                        : {
                            ...getMemberDetailsFromUserDetails(
                              userDetails.member
                            ),
                          },
                    };
                  }
                  return { ...reply };
                }),
              };
            }),
          };
        }
      );

      queryClient.setQueryData(['replies', flowId, responseId], updatedReplies);

      const userFeedKeys = queryCache
        .findAll(['userFeed'])
        .map((query) => query.queryKey);
      const searchFeedKeys = queryCache
        .findAll(['searchResults'])
        .map((query) => query.queryKey);
      const postQueryKey = ['assemblyFlowPost', flowId, responseId];

      const previousPost =
        queryClient.getQueryData<FlowPostResponse>(postQueryKey);
      const previousUserFeed: InfiniteData<UserFeedApiResponse> | undefined =
        queryClient.getQueryData(userFeedKeys);
      const previousSearchFeed =
        queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
          searchFeedKeys
        );

      updatePostWithDraftStatus(postQueryKey, queryClient, responseId, false);
      updateUserFeedWithDraftStatus(
        userFeedKeys,
        queryClient,
        responseId,
        false
      );
      updateSearchFeedWithDraftStatus(
        searchFeedKeys,
        queryClient,
        responseId,
        false
      );

      return {
        previousPost,
        previousReplies,
        previousUserFeed,
        previousSearchFeed,
      };
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['replies', flowId, responseId]),
        queryClient.invalidateQueries(['userDetails']),
      ]);
    },
    onError: (err, payload, context) => {
      onError(err);
      if (!context) {
        return;
      }

      const userFeedKeys = queryCache
        .findAll(['userFeed'])
        .map((query) => query.queryKey);
      const searchFeedKeys = queryCache
        .findAll(['searchResults'])
        .map((query) => query.queryKey);
      const postQueryKey = ['assemblyFlowPost', flowId, responseId];

      queryClient.setQueryData(postQueryKey, context.previousPost);
      queryClient.setQueryData(userFeedKeys, context.previousUserFeed);
      queryClient.setQueryData(searchFeedKeys, context.previousSearchFeed);
      queryClient.setQueryData(['replies', flowId, responseId], context);
    },
  });
}

export function useMutateReplyToEditStatus() {
  const queryClient = useQueryClient();

  const mutateReplyToEditStatus = ({
    flowId,
    replyId,
    responseId,
  }: DraftReplyInEditStatusPayload) => {
    queryClient.setQueryData<InfiniteData<GetRepliesResponse>>(
      ['replies', flowId, responseId],
      (currentPaginatedReplies) => {
        if (
          !currentPaginatedReplies?.pages ||
          currentPaginatedReplies.pages.length === 0
        ) {
          return currentPaginatedReplies;
        }

        const currentData = currentPaginatedReplies;
        currentData.pages[0].data = currentPaginatedReplies.pages[0].data.map(
          (reply) => {
            if (reply.commentID === replyId) {
              return {
                ...reply,
                isEditing: true,
              };
            }

            return reply;
          }
        );

        return currentData;
      }
    );
  };

  return {
    mutateReplyToEditStatus,
  };
}

export function useDeleteReplyMutation(
  onSuccessCallback: () => void,
  onErrorCallback: () => void
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: DeleteReplyPayload) => {
      if ('challengeId' in payload) {
        return await assemblyAPI.put(APIEndpoints.deleteReply, {
          commentId: payload.replyId,
          returnPoints: payload.returnPoints,
        });
      } else {
        return await assemblyAPI.put(
          payload.isLegacyReply
            ? APIEndpoints.archiveComment
            : APIEndpoints.deleteReply,
          {
            commentId: payload.replyId,
            returnPoints: payload.returnPoints,
          }
        );
      }
    },
    onSuccess: async (_, payload) => {
      onSuccessCallback();
      let previousReplies = null;
      if ('challengeId' in payload) {
        previousReplies = queryClient.getQueryData<
          InfiniteData<GetRepliesResponse>
        >(['replies', payload.challengeId]);

        if (previousReplies) {
          const updatedReplies = deleteReplyFromPostReplies(
            payload.replyId,
            previousReplies
          );

          queryClient.setQueryData(
            ['replies', payload.challengeId],
            updatedReplies
          );

          const replySummaryQueryKey = ['repliesSummary', payload.challengeId];
          await queryClient.invalidateQueries(replySummaryQueryKey);
        }
      } else {
        const { flowId, responseId } = payload;
        previousReplies = queryClient.getQueryData<
          InfiniteData<GetRepliesResponse>
        >(['replies', flowId, responseId]);

        if (previousReplies) {
          const updatedReplies = deleteReplyFromPostReplies(
            payload.replyId,
            previousReplies
          );

          queryClient.setQueryData(
            ['replies', flowId, responseId],
            updatedReplies
          );

          const replySummaryQueryKey = [
            'assemblyFlowPostReplies',
            flowId,
            responseId,
          ];
          await queryClient.invalidateQueries(replySummaryQueryKey);
        }
      }

      await queryClient.invalidateQueries(['userFeed']);
      await queryClient.invalidateQueries(['userDetails']);
      await queryClient.invalidateQueries(['searchResults']);
    },
    onError: () => {
      onErrorCallback();
    },
  });
}
