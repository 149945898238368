import { msTeamsConfigurePopupLogo } from '@assembly-web/assets';
import { config, logger, userAuthStore } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { app, pages } from '@microsoft/teams-js';
import { defineMessages, useIntl } from 'react-intl';

const msTeamsConfigText = defineMessages({
  setupConfigurePopupHeading: {
    defaultMessage: 'Add Assembly to your channel tab',
    id: 'Vwl0bU',
  },
  setupConfigurePopupDescription: {
    defaultMessage:
      'Get ready to celebrate your co-workers! Use Assembly in Microsoft Teams to give recognition and redeem rewards.',
    id: 'XCgxPW',
  },
});

export const MSTeamsConfig = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="mx-auto flex max-w-lg flex-col items-center justify-center space-y-6 px-4 pb-6">
      <img alt="MS teams popup icon" src={msTeamsConfigurePopupLogo} />
      <TextStyle as="span" className="pl-3 text-center text-2xl font-bold">
        {formatMessage(msTeamsConfigText.setupConfigurePopupHeading)}
      </TextStyle>
      <TextStyle as="span" className="pl-3 text-center">
        {formatMessage(msTeamsConfigText.setupConfigurePopupDescription)}
      </TextStyle>
    </div>
  );
};

export async function msTeamsConfigLoader() {
  try {
    await app.initialize();
    app.notifyAppLoaded();
    pages.config.setValidityState(true);
    pages.config.registerOnSaveHandler(async (saveEvent) => {
      await pages.config.setConfig({
        contentUrl: `${config.domains.app}/ms-teams/login`,
        entityId: 'joinAssembly-tab',
        suggestedDisplayName: 'Assembly',
      });
      saveEvent.notifySuccess();
    });
  } catch (error) {
    logger.error('Unable to set up config save handler for MS teams client', {
      email: userAuthStore.getState().msTeamsContext,
      error,
    });
  }

  return null;
}
