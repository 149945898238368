import type {
  PinnedCollection,
  SearchIndexResult,
  UserDetails,
} from '@assembly-web/services';
import {
  collectionColor,
  formatCollectionItemToSearchResult,
} from '@assembly-web/services';
import type { ToolbarItem } from '@assembly-web/ui';
import {
  mapColorToCssClass,
  OverflowText,
  TextStyle,
  Toolbar,
  Tooltip,
  UnpinIcon,
  useToolbarState,
} from '@assembly-web/ui';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronUpIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  UserGroupIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { Content, Header, Item, Trigger } from '@radix-ui/react-accordion';
import { forwardRef, type Ref, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useViewSoftPinCollectionMutation } from '../../hooks/nav/useViewSoftPinCollectionMutation';
import { CollectionEntry } from './CollectionEntry';
import type { collectionHeaderActions, NavBarProps } from './NavBar';

const messages = defineMessages({
  editCollection: {
    defaultMessage: 'Edit collection',
    id: 'WQT8ZA',
  },
  shareCollection: {
    defaultMessage: 'Share collection',
    id: 'apxoRl',
  },
  moveCollectionUp: {
    defaultMessage: 'Move collection up',
    id: 'RtY+85',
  },
  moveCollectionDown: {
    defaultMessage: 'Move collection down',
    id: 'JGHgkk',
  },
  unpinCollection: {
    defaultMessage: 'Unpin collection',
    id: 'NJpl8L',
  },
  deleteCollection: {
    defaultMessage: 'Delete',
    id: 'K3r6DQ',
  },
  addAnItemToCollection: {
    defaultMessage: 'Add an item to this collection',
    id: 'iiBB5D',
  },
  emptyCollectionContent: {
    defaultMessage:
      'To add something to this collection, click the 3-dotted menu on any item in your workspace and add from there.',
    id: 'dq9M5c',
  },
  softPinTooltipByOwner: {
    defaultMessage: `{collectionOwner} has shared and pinned this collection for you. You can always move this collection down or unpin this collection.`,
    id: 'gRyw8R',
  },
  softPinTooltipBySelf: {
    defaultMessage: `You have shared and pinned this collection. You can always move this collection down or unpin this collection.`,
    id: '/zWgnp',
  },
});

function useCollectionHeaderOptions({
  isFirstItem,
  isLastItem,
  collection,
}: {
  isFirstItem: boolean;
  isLastItem: boolean;
  collection: PinnedCollection;
}) {
  const { formatMessage } = useIntl();

  const { allowedEditing, allowedSharing } = collection;

  return useMemo(() => {
    const itemsToShow: ToolbarItem[] = [
      allowedEditing && {
        id: 'editCollection',
        icon: PencilIcon,
        text: formatMessage(messages.editCollection),
      },
      allowedEditing &&
        allowedSharing && {
          id: 'shareCollection',
          icon: UserPlusIcon,
          text: formatMessage(messages.shareCollection),
        },
      {
        id: 'moveCollectionUp',
        icon: ArrowUpIcon,
        text: formatMessage(messages.moveCollectionUp),
        disabled: isFirstItem,
      },
      {
        id: 'moveCollectionDown',
        icon: ArrowDownIcon,
        text: formatMessage(messages.moveCollectionDown),
        disabled: isLastItem,
      },
      {
        id: 'unpinCollection',
        icon: UnpinIcon,
        text: formatMessage(messages.unpinCollection),
      },
      allowedEditing && {
        id: 'deleteCollection',
        icon: TrashIcon,
        text: formatMessage(messages.deleteCollection),
        isAlertStyle: true,
      },
    ].filter(Boolean);

    return itemsToShow;
  }, [allowedEditing, allowedSharing, formatMessage, isFirstItem, isLastItem]);
}

const CollectionHeader = forwardRef(
  (
    {
      isOpen,
      isFirstItem,
      isLastItem,
      collection,
      onCollectionHeaderActionClick,
      currentUserId,
      ...otherProps
    }: {
      isFirstItem: boolean;
      isLastItem: boolean;
      isOpen: boolean;
      collection: PinnedCollection;
      currentUserId: string;
      onCollectionHeaderActionClick: NavBarProps['onCollectionHeaderActionClick'];
    },
    ref: Ref<HTMLHeadingElement>
  ) => {
    const { getContainerProps, getToolbarProps, isToolbarActive } =
      useToolbarState();
    const { formatMessage } = useIntl();
    const headerToolbarItems = useCollectionHeaderOptions({
      isFirstItem,
      isLastItem,
      collection,
    });

    const { mutate: viewSoftPinCollection } =
      useViewSoftPinCollectionMutation();

    const handleSoftPinHover = () => {
      viewSoftPinCollection(collection.id);
    };

    return (
      <Header
        {...otherProps}
        ref={ref}
        className="sticky top-0 z-50 items-center justify-start self-stretch px-1"
      >
        <Trigger
          className={twMerge(
            'w-full rounded p-1 pl-4',
            'inline-flex items-center gap-2',
            mapColorToCssClass(collectionColor.hexToColorMap[collection.color])
          )}
          {...getContainerProps()}
        >
          <div className="w-5">
            {collection.softPin === 'set' ? (
              <Tooltip
                tooltipText={
                  currentUserId === collection.createdBy?.memberID
                    ? formatMessage(messages.softPinTooltipBySelf)
                    : formatMessage(messages.softPinTooltipByOwner, {
                        collectionOwner: collection.createdBy?.name,
                      })
                }
              >
                <span
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                  tabIndex={0}
                  onMouseLeave={handleSoftPinHover}
                  onKeyDown={handleSoftPinHover}
                  className="relative flex h-2.5 w-2.5"
                  role="presentation"
                >
                  <span className="absolute h-full w-full animate-ping rounded-full bg-primary-6"></span>
                  <span className="relative h-2.5 w-2.5 rounded-full border border-gray-1 bg-primary-6"></span>
                </span>
              </Tooltip>
            ) : (
              <ChevronUpIcon
                className={twMerge(
                  'relative h-4 w-4',
                  !isOpen && '-rotate-180'
                )}
              />
            )}
          </div>
          <div className="inline-grid w-full grid-cols-12 gap-2 text-left">
            <div
              className={`${isToolbarActive ? 'col-span-9' : 'col-span-12'} inline-flex w-full items-center gap-1`}
            >
              <OverflowText
                variant="base-medium"
                className="!block truncate"
                tooltipText={collection.name}
              >
                {collection.name}
              </OverflowText>
              {collection.isShared ? (
                collection.softPin === 'viewed' ? (
                  <Tooltip
                    tooltipText={
                      currentUserId === collection.createdBy?.memberID
                        ? formatMessage(messages.softPinTooltipBySelf)
                        : formatMessage(messages.softPinTooltipByOwner, {
                            collectionOwner: collection.createdBy?.name,
                          })
                    }
                  >
                    <UserGroupIcon className="col-span-1 h-4 w-4 flex-shrink-0" />
                  </Tooltip>
                ) : (
                  <UserGroupIcon className="col-span-1 h-4 w-4 flex-shrink-0" />
                )
              ) : null}
            </div>
            <div
              className={twMerge(
                'items-center gap-1 justify-self-end',
                'flex',
                isToolbarActive ? 'col-span-3' : 'hidden'
              )}
            >
              <Toolbar
                {...getToolbarProps({
                  onMenuItemClick({ id: action }) {
                    onCollectionHeaderActionClick(
                      action as collectionHeaderActions,
                      collection.id
                    );
                  },
                })}
                secondaryToolbarItems={headerToolbarItems}
                compact
              />
              {Boolean(collection.allowedEditing) && (
                <button
                  aria-label={formatMessage(messages.addAnItemToCollection)}
                  className="visible flex h-6 w-6 items-center rounded-lg border border-gray-5 bg-gray-1 p-[4px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    onCollectionHeaderActionClick(
                      'addItemsToCollection',
                      collection.id
                    );
                  }}
                >
                  <Tooltip
                    tooltipText={formatMessage(messages.addAnItemToCollection)}
                  >
                    <PlusIcon className="h-4 w-4" aria-hidden="true" />
                  </Tooltip>
                </button>
              )}
            </div>
          </div>
        </Trigger>
      </Header>
    );
  }
);
CollectionHeader.displayName = 'CollectionHeader';

export function CollectionSection({
  isFirstItem,
  isLastItem,
  isOpen,
  collection,
  userDetails,
  openFilePreviewModal,
  onHeaderActionClick,
  onItemActionClick,
}: {
  isFirstItem: boolean;
  isLastItem: boolean;
  isOpen: boolean;
  collection: PinnedCollection;
  userDetails: UserDetails;
  openFilePreviewModal: (props: {
    blockId: string;
    flowId: string;
    fileName: string;
    responseId: string;
  }) => void;
  onHeaderActionClick: NavBarProps['onCollectionHeaderActionClick'];
  onItemActionClick: NavBarProps['onCollectionItemActionClick'];
}) {
  const { formatMessage } = useIntl();

  return (
    <Item
      value={collection.id}
      className="relative flex flex-col items-start justify-start gap-2 self-stretch py-1"
    >
      <CollectionHeader
        isFirstItem={isFirstItem}
        isLastItem={isLastItem}
        isOpen={isOpen}
        collection={collection}
        onCollectionHeaderActionClick={onHeaderActionClick}
        currentUserId={userDetails.member.memberId}
      />
      <Content className="w-full flex-col gap-2 px-1 data-[state=open]:inline-flex">
        {collection.listItems.length === 0 ? (
          <TextStyle
            variant="xs-regular"
            className="inline-flex items-start justify-start gap-2 rounded-lg py-1 pl-2 pr-2"
          >
            {formatMessage(messages.emptyCollectionContent)}
          </TextStyle>
        ) : (
          collection.listItems.map((item, index) => (
            <CollectionEntry
              key={item.id}
              item={
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                formatCollectionItemToSearchResult(item)! as SearchIndexResult
              }
              isFirstItem={index === 0}
              isLastItem={index === collection.listItems.length - 1}
              collection={collection}
              userDetails={userDetails}
              openFilePreviewModal={openFilePreviewModal}
              onItemActionClick={onItemActionClick}
            />
          ))
        )}
      </Content>
    </Item>
  );
}
