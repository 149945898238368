import {
  usePusherChannel,
  usePusherEvent,
  usePusherEvents,
} from '@assembly-web/pusher';
import { useUserDetails } from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import { useQueryClient } from '@tanstack/react-query';

import { useGetAnnouncementQuery } from '../useGetAnnouncementQuery';
import { useGlobalFilter } from '../useGlobalFilter';

export function useAnnouncementUpdatesEvents() {
  const queryClient = useQueryClient();

  const filter = useGlobalFilter();
  const isMyFeedPage = filter === GlobalFilterOption.All || !filter;

  const { refetch } = useGetAnnouncementQuery({
    enabled: isMyFeedPage,
  });

  const { data: userDetails } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  const channel = usePusherChannel(
    `private-member-${userDetails?.member.memberId}`
  );

  const announcementEvents = [
    'ANNOUNCEMENT_ENDED',
    'ANNOUNCEMENT_CREATED',
    'ANNOUNCEMENT_UPDATED',
  ];

  type PusherData = {
    flowId: string;
    entityId: string;
    announcementId: string;
  };

  usePusherEvents<PusherData>(assembly, announcementEvents, async (args) => {
    const { flowId, entityId } = args.data ?? {};
    const queryCache = queryClient.getQueryCache();

    if (args.eventName !== 'ANNOUNCEMENT_CREATED') {
      await queryClient.invalidateQueries([
        'assemblyFlowPost',
        flowId,
        entityId,
      ]);
    }

    queryCache.getAll().forEach((query) => {
      const queryKey = query.queryKey;
      const keysToUpdate = ['userFeed', 'searchResults'];
      const keysToInvalidate = ['entityPermission'];

      if (
        Array.isArray(queryKey) &&
        keysToInvalidate.includes(queryKey[0] as string)
      ) {
        queryClient.invalidateQueries(keysToInvalidate);
      }

      if (
        Array.isArray(queryKey) &&
        keysToUpdate.includes(queryKey[0] as string)
      ) {
        queryClient.refetchQueries(queryKey);
      }
    });

    await refetch();
  });

  usePusherEvent(channel, 'ANNOUNCEMENT_SEEN', () => {
    refetch();
  });
}
