import { KnownErrorKeys } from '@assembly-web/services';
import {
  Button,
  EditorLoader,
  FlowEditorContainers,
  useToastStore,
} from '@assembly-web/ui';
import { CheckIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import {
  useFlowBuilderStore,
  useInEditFlow,
} from '../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';
import {
  useCreateOrUpdateFlow,
  useIsCreatingFlowLoading,
  useIsUpdateFlowLoading,
} from '../hooks/useCreateOrUpdateFlow';
import { useValidateBlocks } from '../hooks/useIsBlocksValid';

const messages = defineMessages({
  createFlow: {
    defaultMessage: 'Create this flow',
    id: 'cjHULr',
  },
  saveEdits: {
    defaultMessage: 'Save edits',
    id: 'O9LywN',
  },
  errorToast: {
    defaultMessage:
      '{isKnown, select, known {{message}} other {{message} (Question {number})}}',
    id: 'IzMLu+',
  },
});

function CreateFlowButton() {
  const { formatMessage } = useIntl();
  const validate = useValidateBlocks();

  const { id, isLoading } = useEditorDataContext();
  const createOrUpdate = useCreateOrUpdateFlow();
  const isCreateMutating = Boolean(useIsCreatingFlowLoading());
  const isUpdateMutating = Boolean(useIsUpdateFlowLoading());

  const inEditFlow = useInEditFlow(id);
  const isMutating = isCreateMutating || isUpdateMutating;
  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleClick = () => {
    const errors = validate();
    if (errors && Object.keys(errors).length > 0) {
      Object.entries(errors)
        .slice(0, 1)
        .forEach(([key, value]) => {
          useToastStore.getState().showErrorToast(
            formatMessage(messages.errorToast, {
              isKnown: key in KnownErrorKeys ? 'known' : 'other',
              message: value.message,
              number: useFlowBuilderStore.getState().getBlockIndex(id, key) + 1,
            })
          );
        });
      return;
    }

    createOrUpdate();
    if (!inEditFlow) {
      trackFlowEditorAction('createFlowClicked');
    }
  };

  if (isLoading) {
    return <EditorLoader className="h-[40px] w-28" />;
  }

  return (
    <Button
      onClick={handleClick}
      disabled={isMutating || isOccurrenceInProgress}
      variation="primary"
      size="large"
      isLoading={isMutating}
      className={twJoin(
        inEditFlow &&
          '!border-success-7 !bg-success-7 hover:!bg-success-7 focus:!bg-success-7 focus:!ring-success-7 disabled:!bg-success-8 disabled:!text-gray-5'
      )}
    >
      {Boolean(inEditFlow) && <CheckIcon className="mr-2 h-4 w-4" />}
      {inEditFlow
        ? formatMessage(messages.saveEdits)
        : formatMessage(messages.createFlow)}
    </Button>
  );
}

export function Footer() {
  return (
    <FlowEditorContainers.Footer>
      <CreateFlowButton />
    </FlowEditorContainers.Footer>
  );
}
