import { useDebounce } from '@assembly-web/services';
import type { CriteriaItemProps } from '@assembly-web/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { generateSaveCriteriaPayloadFromRules } from '../../services/criteriaUtil';
import type { GetMembersForPreviewRequest } from '../shareCollections/queries/usePreviewCriteriaQuery';
import type { PreviewIdForCriteriaUnion } from './queries/usePreviewCriteriaQuery';
import { usePollPreviewAPIForMembers } from './usePollPreviewAPIForMembers';

const messages = defineMessages({
  viewers: {
    defaultMessage: 'Viewers',
    id: '37mth/',
  },
  collaborators: {
    defaultMessage: 'Collaborators',
    id: '9Jstnt',
  },
  all: {
    defaultMessage: 'All',
    id: 'zQvVDJ',
  },
  filterCount: {
    defaultMessage: ' • {count} {count,plural,=0{} one{person} other{people}}',
    id: 't9BQEm',
  },
  ownerSubtitle: {
    defaultMessage: '{subTitle} • Owner',
    id: 'FGuNqA',
  },
  currentUserLabel: {
    defaultMessage: '{currentUser} (You)',
    id: 'UfG6o8',
  },
});

export const usePreviewRules = ({
  rules,
  enabled,
  ...rest
}: {
  rules: CriteriaItemProps[];
  enabled: boolean;
} & PreviewIdForCriteriaUnion) => {
  const {
    sharingRules: { include, exclude },
  } = generateSaveCriteriaPayloadFromRules(rules);

  const [previewMemberSearch, setPreviewMemberSearch] = useState('');
  const [previewMemberFilter, setPreviewMemberFilter] = useState('all');

  const debouncedPreviewMemberSearch = useDebounce(previewMemberSearch, 500);

  const { formatMessage } = useIntl();

  const previewCriteria: GetMembersForPreviewRequest = useMemo(
    () => ({
      criteriaType: 'simple',
      criteria: {
        include,
        exclude,
      },
    }),
    [exclude, include]
  );

  const {
    data: displayRules,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    totalMembersCount,
  } = usePollPreviewAPIForMembers({
    previewCriteria,
    previewMemberFilter: useMemo(
      () => ({
        search: debouncedPreviewMemberSearch,
        filter: previewMemberFilter,
      }),
      [debouncedPreviewMemberSearch, previewMemberFilter]
    ),
    enabled,
    ...rest,
  });

  useEffect(() => {
    if (!enabled) {
      setPreviewMemberFilter('all');
      setPreviewMemberSearch('');
    }
  }, [enabled]);

  const onSearchOrFilterList = useCallback(
    ({ searchTerm, filter }: { searchTerm: string; filter: string }) => {
      setPreviewMemberSearch(searchTerm);
      setPreviewMemberFilter(filter);
    },
    []
  );

  const previewFilterDefaultOptions = useMemo(
    () => [
      {
        value: 'all',
        label: formatMessage(messages.all),
      },
      {
        value: 'permission:viewer',
        label: formatMessage(messages.viewers),
      },
      {
        value: 'permission:collaborator',
        label: formatMessage(messages.collaborators),
      },
    ],
    [formatMessage]
  );

  const previewFilterOptions = useMemo(
    () =>
      enabled
        ? previewFilterDefaultOptions.map((option) => {
            const isSelected = previewMemberFilter === option.value;
            const count = isSelected
              ? formatMessage(messages.filterCount, {
                  count: totalMembersCount,
                })
              : '';
            const label = `${option.label} ${
              isSelected && !isLoading && totalMembersCount > 0 ? count : ''
            }`;
            return { ...option, label };
          })
        : previewFilterDefaultOptions,
    [
      enabled,
      formatMessage,
      isLoading,
      previewFilterDefaultOptions,
      previewMemberFilter,
      totalMembersCount,
    ]
  );

  return {
    displayRules,
    previewFilterOptions,
    onSearchOrFilterList,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    totalMembersCount,
  } as const;
};
