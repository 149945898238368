import type { ListWorkspacesAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  config,
  getAPIErrorCode,
  getProviderName,
  getUserDetailsQuery,
  logger,
  lookupSSOProvider,
  MobileJWTTokenQueryParamKey,
  MobileRefreshTokenQueryParam,
  SplitNames,
  SSOProvider,
  unauthenticatedAssemblyAPI,
  useFeatureSplit,
  userAuthStore,
} from '@assembly-web/services';
import {
  AppLink,
  Banner,
  Button,
  classNames,
  HorizontalRule,
  RouterForm,
  TextField,
  validateForm,
} from '@assembly-web/ui';
import type { QueryClient } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useActionData,
  useNavigation,
  useSearchParams,
  useSubmit,
} from 'react-router-dom';
import { z } from 'zod';

import {
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../../services/analytics';
import { waitForRedirection } from '../../../../services/waitForRedirection';
import type { ReactRouterActionResponse } from '../../../../types/libs';
import { isAdminMember } from '../../../discover/services/member';
import { OnboardingContainer } from '../../components/OnboardingContainer';
import { SSOButton } from '../../components/SSOButton';
import { useGooglePlatformScriptLoader } from '../../hooks/useGooglePlatformScriptLoader';
import { redirectToAmazonAdminForAuthorization } from '../../services/amazonAuth';
import { getSSODetailsFromError, handleSSOForLogin } from '../../services/sso';

const loginFormText = defineMessages({
  title: {
    defaultMessage: 'Welcome back! Get ready to work smarter, not harder.',
    id: '3W+NAx',
  },
  description: {
    defaultMessage: 'Log in below to enter a workspace.',
    id: 'dtCJTJ',
  },
  footer: {
    defaultMessage: "Don't have an Assembly yet? <cta>Sign up</cta>",
    id: 'mvJrR5',
  },
  emailSubmissionButton: {
    defaultMessage: 'Next',
    id: '9+Ddtu',
  },
  emailErrorMessage: {
    defaultMessage: 'Enter a valid email',
    id: 'PFfzPv',
  },
  inputPlaceholder: {
    defaultMessage: 'Enter your email address',
    id: 'NgCT/u',
  },
  horizontalSeparator: {
    defaultMessage: 'OR',
    id: 'INlWvJ',
  },
  loginWithSSOProvider: {
    defaultMessage: 'Log in with {provider}',
    id: 'BXwisy',
  },
  loginPageTitle: {
    defaultMessage: 'Login',
    id: 'AyGauy',
  },
  loginToContinue: {
    defaultMessage: 'Please log in to join {workspaceName} workspace',
    id: 'JrA7Mu',
  },
});

const loginFormErrors = defineMessages({
  popup_closed_by_user: {
    defaultMessage:
      "Whoops, you declined Assembly's permission to connect with Google",
    id: '+ONxsm',
  },
  slack_access_denied: {
    defaultMessage:
      "Whoops, you declined Assembly's permission to connect with Slack",
    id: 'tDrT7b',
  },
  defaultMessage: {
    defaultMessage:
      'Whoops, some wires got crossed. Sorry for the error, please try again now or wait a bit and try later.',
    id: 'E5BeoJ',
  },
  user_not_found: {
    defaultMessage: 'We couldn’t find a workspace associated to your email. ',
    id: 'QdvBwh',
  },
  too_many_requests_per_user: {
    defaultMessage:
      'Maximum number of attempts reached. Please try again in 30 minutes.',
    id: 'hM+Sro',
  },
  code_generation_blocked: {
    defaultMessage: 'You can request a new code in 30 seconds',
    id: '8eFffF',
  },
  user_already_exists: {
    defaultMessage:
      'Account has already been created. Log in below to access your account.',
    id: 'vD/zi7',
  },
  invalid_parameter: {
    defaultMessage:
      'Your session has expired for security reasons. Please log in again to continue.',
    id: 'PjIbAh',
  },
});

export function LoginRoute({
  isMSTeamsPage = false,
}: {
  isMSTeamsPage?: boolean;
}) {
  const { formatMessage } = useIntl();
  const isMobileApp = userAuthStore.getState().isLoginViaMobileApp;

  const actionData = useActionData() as ReactRouterActionResponse<
    typeof loginAction
  >;
  const navigation = useNavigation();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const errorCode = searchParams.get('error');
  const provider = searchParams.get('provider');
  const referrer = searchParams.get('referrer');

  const isPageLoading = navigation.state === 'loading';
  const isSubmissionInProgress = navigation.state === 'submitting';

  const submitFn = useSubmit();
  const googleScriptLoaded = useGooglePlatformScriptLoader();
  const { isTreatmentActive: allowAssemblyCreation } = useFeatureSplit(
    SplitNames.AllowAssemblyCreation
  );

  if (errorCode) {
    logger.error('Login error', {
      email,
      provider,
      errorCode,
      samlEnforced: userAuthStore.getState().isEnforcedAuth,
    });
  }

  const loginWithSSO = useCallback(
    function loginWithSSO(ssoProvider: SSOProvider) {
      userAuthStore.getState().updateUserAuthFlow('login');
      const formData = new FormData();
      formData.append('provider', ssoProvider.toLowerCase());

      trackRegistrationAction('ssoClicked', {
        ssoType: getProviderName(ssoProvider).toLowerCase(),
        samlEnforced: userAuthStore.getState().isEnforcedAuth,
      });

      return submitFn(formData);
    },
    [submitFn]
  );

  useEffect(() => {
    if (email && isMobileApp && !errorCode) {
      const formData = new FormData();
      formData.append('email', email);
      submitFn(formData, { method: 'post' });
    }
  }, [
    email,
    isMobileApp,
    errorCode,
    submitFn,
    provider,
    googleScriptLoaded,
    loginWithSSO,
  ]);

  useEffect(() => {
    if (provider) {
      const ssoProvider = lookupSSOProvider(provider);
      if (
        ssoProvider &&
        isMobileApp &&
        (ssoProvider === SSOProvider.Google ? googleScriptLoaded : true)
      ) {
        handleSSOForLogin(ssoProvider);
      }
    }
  }, [googleScriptLoaded, isMobileApp, provider]);

  if (errorCode) {
    trackRegistrationError('ssoClicked', {});
  }

  return (
    <OnboardingContainer
      title={formatMessage(loginFormText.title)}
      description={formatMessage(loginFormText.description)}
      footer={
        !isMobileApp &&
        Boolean(allowAssemblyCreation) &&
        formatMessage(loginFormText.footer, {
          cta: (text: ReactNode[]) => (
            <AppLink
              to="/create-account"
              onClick={() => trackRegistrationAction('logIntoExistingClicked')}
            >
              {text}
            </AppLink>
          ),
        })
      }
    >
      <Helmet>
        <title>{formatMessage(loginFormText.loginPageTitle)}</title>
      </Helmet>
      {errorCode ? (
        errorCode === 'user_already_exists' ? (
          <Banner status="info">
            {formatMessage(loginFormErrors.user_already_exists)}
          </Banner>
        ) : (
          <Banner status="error">
            {Object.keys(loginFormErrors).includes(errorCode)
              ? formatMessage(
                  loginFormErrors[errorCode as keyof typeof loginFormErrors]
                )
              : formatMessage(loginFormErrors.defaultMessage)}
          </Banner>
        )
      ) : null}

      {Boolean(referrer) && (
        <Banner status="info">
          {formatMessage(loginFormText.loginToContinue, {
            workspaceName: referrer,
          })}
        </Banner>
      )}

      <section className="grid grid-flow-col grid-cols-1 grid-rows-3 gap-3">
        {!isMSTeamsPage && (
          <>
            <div
              id="google-signin-button"
              className="col-span-1 rounded-md border border-gray-9"
            ></div>
            <SSOButton
              flow="login"
              provider={SSOProvider.Slack}
              onClick={() => loginWithSSO(SSOProvider.Slack)}
              className="col-span-1 whitespace-nowrap"
            />
          </>
        )}
        <SSOButton
          flow="login"
          provider={SSOProvider.Office365}
          onClick={() => loginWithSSO(SSOProvider.Office365)}
          className={classNames('whitespace-nowrap', {
            'col-span-3': isMSTeamsPage,
            'col-span-1': !isMSTeamsPage,
          })}
        />
      </section>

      <HorizontalRule>
        {formatMessage(loginFormText.horizontalSeparator)}
      </HorizontalRule>

      <RouterForm>
        <TextField
          name="email"
          type="email"
          label={formatMessage(loginFormText.inputPlaceholder)}
          hideLabel
          placeholder={formatMessage(loginFormText.inputPlaceholder)}
          autoComplete="email"
          spellCheck={false}
          invalidTextTestId="email-error"
          isInvalid={Boolean(actionData?.error)}
          invalidText={formatMessage(loginFormText.emailErrorMessage)}
          required
        />
        <Button
          type="submit"
          isFullWidth
          isLoading={isSubmissionInProgress}
          disabled={isSubmissionInProgress || isPageLoading}
        >
          {formatMessage(loginFormText.emailSubmissionButton)}
        </Button>
      </RouterForm>
    </OnboardingContainer>
  );
}

export function loginLoader(queryClient: QueryClient, isMSTeamsPage = false) {
  return async function loginLoader({
    request: { url, signal },
  }: LoaderFunctionArgs) {
    if (isMSTeamsPage) {
      try {
        if (userAuthStore.getState().msTeamsContext) {
          return redirect('/ms-teams/login');
        }
      } catch (err) {
        logger.error(
          'Unable to get user context for teams client',
          err as Error
        );
      }
    }

    const provider = new URL(url).searchParams.get('provider');
    const mobilePlatform = new URL(url).searchParams.get('mobilePlatform');

    if (!provider) {
      try {
        const {
          data: { allowList, invited, partOf, isLegacySession },
        } = await assemblyAPI.post<ListWorkspacesAPIResponse>(
          APIEndpoints.listWorkspaces,
          null,
          { signal }
        );

        try {
          if (
            allowList.length === 0 &&
            invited.length === 0 &&
            partOf.length === 1
          ) {
            const userDetailsQuery = getUserDetailsQuery();
            await queryClient.fetchQuery(userDetailsQuery);
            const userDetails = await queryClient.fetchQuery(userDetailsQuery);
            const isAdmin = isAdminMember(userDetails.member);

            const isMobileApp = Boolean(mobilePlatform);

            const { assemblyId } = partOf[0];

            if (isMobileApp) {
              const {
                data: { jwtToken, refreshToken },
              } = await assemblyAPI.post<{
                jwtToken: string;
                refreshToken?: string;
              }>(APIEndpoints.loginToWorkspace, { assemblyId }, { signal });

              window.location.href = `${config.domains.mobileApp}home?${new URLSearchParams(
                [
                  [MobileJWTTokenQueryParamKey, jwtToken],
                  ...(refreshToken
                    ? [[MobileRefreshTokenQueryParam, refreshToken]]
                    : []),
                ]
              )}`;
            } else {
              await redirectToAmazonAdminForAuthorization(isAdmin, assemblyId);
              return redirect('/a/discover');
            }

            await waitForRedirection();
            return null;
          } else if (isLegacySession) {
            return null;
          } else {
            const userDetailsQuery = getUserDetailsQuery();
            await queryClient.fetchQuery(userDetailsQuery);
            const userDetails = await queryClient.fetchQuery(userDetailsQuery);
            const isAdmin = isAdminMember(userDetails.member);
            const { assemblyId } = partOf[0];

            await redirectToAmazonAdminForAuthorization(isAdmin, assemblyId);

            return assemblyId
              ? redirect('/a/discover')
              : redirect('/workspaces');
          }
        } catch (error) {
          await redirectToAmazonAdminForAuthorization(true, null);
          return redirect('/workspaces');
        }
      } catch {
        await redirectToAmazonAdminForAuthorization(true, null);
        // Carry on, we don't have a valid user here
        return null;
      }
    }

    const ssoProvider = lookupSSOProvider(provider);

    if (ssoProvider && !mobilePlatform) {
      handleSSOForLogin(ssoProvider);
      return null;
    }
  };
}

export async function loginAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const provider = formData.get('provider');

  if (provider) {
    return null;
  }

  const invalidEmailCode = 'invalid_email';
  const formSchema = z.object({
    email: z.string().email(invalidEmailCode),
  });

  try {
    const { email } = validateForm(formSchema, formData);

    await unauthenticatedAssemblyAPI.post(
      APIEndpoints.generateEmailVerificationCode,
      { email, type: 'login' },
      { signal: request.signal }
    );

    trackRegistrationAction('emailEntered', {
      email,
    });

    return redirect(`/verify-email/login?email=${encodeURIComponent(email)}`);
  } catch (error) {
    trackRegistrationError('emailEntered', {
      email: formData.get('email')?.toString(),
    });
    const enforcedLoginDetails = getSSODetailsFromError(error);

    if (enforcedLoginDetails) {
      return redirect(
        `/login/${enforcedLoginDetails.workspaceSlug}/${enforcedLoginDetails.provider}`
      );
    }

    const errorCode = getAPIErrorCode(error);

    if (['assembly_not_active', 'forbidden'].includes(errorCode)) {
      return redirect(`/create-account?error=${errorCode}`);
    }

    if (
      ['too_many_requests_per_user', 'code_generation_blocked'].includes(
        errorCode
      )
    ) {
      return redirect(`${new URL(request.url).pathname}?error=${errorCode}`);
    } else if (errorCode === 'user_not_found') {
      return { error: 'user_not_found' };
    }

    return { error: invalidEmailCode };
  }
}
