import { postIcon } from '@assembly-web/assets';
import {
  type ChallengeState,
  copyToClipboard,
  SplitNames,
  useFeatureSplit,
} from '@assembly-web/services';
import { config } from '@assembly-web/services';
import {
  PinIcon,
  type ToolbarItem,
  useDeviceInfo,
  useToastStore,
} from '@assembly-web/ui';
import {
  AdjustmentsHorizontalIcon,
  ArchiveBoxXMarkIcon,
  BellAlertIcon,
  BellSlashIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  LinkIcon,
  PencilIcon,
  PlayIcon,
  PlusIcon,
  Square2StackIcon,
  StopIcon,
} from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ChallengeModalsContext } from '../../../../contexts/ChallengeModalsContext';
import { CollectionModalsContext } from '../../../../contexts/CollectionModalsContext';
import { useUpdateAssemblyChallengeNotificationPreferenceMutationQuery } from '../../../../hooks/useUpdateAssemblyChallengeNotificationPreferenceMutationQuery';
import { ToolbarItemId } from '../../../cards/utils';

const messages = defineMessages({
  copyLink: {
    defaultMessage: 'Copy link',
    id: 'lbr3Lq',
  },
  copiedToClipboard: {
    defaultMessage: 'Link successfully copied to clipboard',
    id: 'QHwY68',
  },
  editChallenge: {
    defaultMessage: 'Edit challenge',
    id: 'QZoli7',
  },
  pinThisItem: {
    defaultMessage: 'Pin this item',
    id: 'yn0QgI',
  },
  addToCollection: {
    defaultMessage: 'Add to collection',
    id: 'ub1kHa',
  },
  challenge: {
    defaultMessage: 'Challenge: {title}',
    id: 'ABCYUG',
  },
  endChallenge: {
    defaultMessage: 'End Challenge',
    id: 'vZJToS',
  },
  launchChallenge: {
    defaultMessage: 'Launch challenge',
    id: 'iZXfAS',
  },
  notificationPreferenceSuccess: {
    defaultMessage: 'You successfully {action} the challenge post.',
    id: '8BqBWF',
  },
  notificationPreferenceError: {
    defaultMessage:
      '`Unable to {action} this challenge post, please try again later',
    id: 'mpl1Vt',
  },
  followChallengePost: {
    defaultMessage: 'Follow challenge post',
    id: 'sAtjMo',
  },
  unfollowChallengePost: {
    defaultMessage: 'Unfollow challenge post',
    id: 'hBcHBf',
  },
  viewSettings: {
    defaultMessage: 'View settings',
    id: 'SMlC/z',
  },
  archiveChallengePost: {
    defaultMessage: 'Archive challenge',
    id: 'WXuhP6',
  },
  viewChallengeInsights: {
    defaultMessage: 'View challenge insights',
    id: '+3aFPw',
  },
  duplicateChallenge: {
    defaultMessage: 'Duplicate challenge',
    id: 'bABcHf',
  },
  manageInteractionSettings: {
    defaultMessage: 'Manage replies and reactions',
    id: '4CKaGM',
  },
});

export function useChallengeToolbarItems({
  title,
  canEdit,
  challengeId,
  state,
  isAdmin,
  hideLaunchChallengeItem = false,
  hideEndChallengeItem = false,
  isMuted = false,
  showChallengeNotificationPreference = false,
  points,
  hideReplies,
  hideReactions,
}: {
  title: string;
  canEdit: boolean;
  challengeId: string;
  state: ChallengeState;
  isAdmin?: boolean;
  hideLaunchChallengeItem?: boolean;
  hideEndChallengeItem?: boolean;
  isMuted?: boolean;
  showChallengeNotificationPreference?: boolean;
  points: number;
  hideReplies?: boolean;
  hideReactions?: boolean;
}) {
  const { formatMessage } = useIntl();
  const { showSuccessToast, showErrorToast } = useToastStore();

  const { isTreatmentActive: isChallengesV2TreatmentActive } = useFeatureSplit(
    SplitNames.ChallengesV2
  );

  const isMobileView = useDeviceInfo().deviceType === 'mobile';

  const { openAddToPinnedCollectionModal, openAddToCollectionModal } =
    useContext(CollectionModalsContext);

  const {
    openEditChallengeModal,
    openEndChallengeModal,
    openLaunchChallengeModal,
    openArchiveChallengeModal,
    openChallengeInsightsModal,
    openDuplicateChallengeModal,
    openEditChallengeInteractionSettingsModal,
  } = useContext(ChallengeModalsContext);

  const { mutate: updateAssemblyChallengeNotification } =
    useUpdateAssemblyChallengeNotificationPreferenceMutationQuery();

  const handleNotificationPreferenceChangeSuccess = () => {
    showSuccessToast(
      formatMessage(messages.notificationPreferenceSuccess, {
        action: isMuted ? 'followed' : 'unfollowed',
      })
    );
  };

  const handleNotificationPreferenceChangeError = () => {
    showErrorToast(
      formatMessage(messages.notificationPreferenceError, {
        action: isMuted ? 'follow' : 'unfollow',
      })
    );
  };

  const toolbarItems: ToolbarItem[] = [
    {
      icon: LinkIcon,
      id: ToolbarItemId.CopyLink,
      text: formatMessage(messages.copyLink),
    },
    ...(!isMobileView
      ? [
          {
            icon: PinIcon,
            id: ToolbarItemId.PinItemToCollection,
            text: formatMessage(messages.pinThisItem),
          },
        ]
      : []),
    {
      icon: PlusIcon,
      id: ToolbarItemId.AddToCollection,
      text: formatMessage(messages.addToCollection),
    },
  ];

  if (!isMobileView && showChallengeNotificationPreference) {
    toolbarItems.push({
      id: ToolbarItemId.PostNotificationPreference,
      icon: isMuted ? BellAlertIcon : BellSlashIcon,
      text: formatMessage(
        isMuted ? messages.followChallengePost : messages.unfollowChallengePost
      ),
    });
  }

  if (
    !isMobileView &&
    !hideLaunchChallengeItem &&
    (canEdit || isAdmin) &&
    state === 'QUEUED'
  ) {
    toolbarItems.unshift({
      icon: PlayIcon,
      id: ToolbarItemId.LaunchChallenge,
      text: formatMessage(messages.launchChallenge),
    });
  }

  if (
    !isMobileView &&
    !hideEndChallengeItem &&
    (canEdit || isAdmin) &&
    state === 'ACTIVE'
  ) {
    toolbarItems.unshift({
      icon: StopIcon,
      id: ToolbarItemId.EndChallenge,
      text: formatMessage(messages.endChallenge),
    });
  }

  if (
    hideReplies !== undefined &&
    hideReactions !== undefined &&
    isAdmin &&
    canEdit &&
    isChallengesV2TreatmentActive
  ) {
    toolbarItems.unshift({
      id: ToolbarItemId.ManageInteractionSettings,
      icon: AdjustmentsHorizontalIcon,
      text: formatMessage(messages.manageInteractionSettings),
    });
  }

  if (
    isChallengesV2TreatmentActive &&
    (isAdmin || canEdit) &&
    state !== 'QUEUED'
  ) {
    toolbarItems.unshift({
      icon: ChartBarIcon,
      id: ToolbarItemId.ViewChallengeInsights,
      text: formatMessage(messages.viewChallengeInsights),
    });
  }

  if (!isMobileView && isChallengesV2TreatmentActive && isAdmin) {
    toolbarItems.unshift({
      icon: Square2StackIcon,
      id: ToolbarItemId.DuplicateChallenge,
      text: formatMessage(messages.duplicateChallenge),
    });
  }

  if (
    !isMobileView &&
    (canEdit || isAdmin) &&
    (state === 'ACTIVE' || state === 'QUEUED')
  ) {
    toolbarItems.unshift({
      icon: PencilIcon,
      id: ToolbarItemId.EditPost,
      text: formatMessage(messages.editChallenge),
    });
  }

  if (isChallengesV2TreatmentActive && isAdmin && state === 'CLOSED') {
    toolbarItems.unshift({
      icon: Cog6ToothIcon,
      id: ToolbarItemId.ViewSettings,
      text: formatMessage(messages.viewSettings),
    });
  }

  if (!isMobileView && isAdmin && state !== 'ARCHIVED' && state !== 'ACTIVE') {
    toolbarItems.push({
      icon: ArchiveBoxXMarkIcon,
      id: ToolbarItemId.ArchiveChallenge,
      text: formatMessage(messages.archiveChallengePost),
      isAlertStyle: true,
    });
  }

  const onToolbarMenuItemClick = async (toolbarItem: ToolbarItem) => {
    switch (toolbarItem.id) {
      case ToolbarItemId.CopyLink:
        {
          const baseUrl = config.domains.app;
          const linkToCopy = `${baseUrl}/a/discover?${new URLSearchParams([
            ['filter', 'challenges'],
            ['challengeId', challengeId],
          ])}`;

          await copyToClipboard(linkToCopy);
          showSuccessToast(formatMessage(messages.copiedToClipboard));
        }
        break;
      case ToolbarItemId.ViewSettings:
      case ToolbarItemId.EditPost:
        openEditChallengeModal(challengeId);
        break;
      case ToolbarItemId.ViewChallengeInsights:
        openChallengeInsightsModal({
          id: challengeId,
          title,
          state,
        });
        break;
      case ToolbarItemId.EndChallenge:
        openEndChallengeModal({
          id: challengeId,
          title: title,
        });
        break;
      case ToolbarItemId.LaunchChallenge:
        openLaunchChallengeModal({
          id: challengeId,
          title: title,
        });
        break;
      case ToolbarItemId.AddToCollection:
      case ToolbarItemId.PinItemToCollection:
        {
          const icon = (
            <img src={postIcon} alt="" className="h-4 w-4 max-md:max-w-none" />
          );

          const modalProps = {
            icon,
            type: 'challenge',
            entityId: challengeId,
            name: formatMessage(messages.challenge, { title }),
          };

          if (toolbarItem.id === ToolbarItemId.PinItemToCollection) {
            openAddToPinnedCollectionModal(modalProps);
          } else {
            openAddToCollectionModal(modalProps);
          }
        }
        break;
      case ToolbarItemId.PostNotificationPreference:
        {
          updateAssemblyChallengeNotification(
            {
              type: isMuted ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
              postType: 'CHALLENGE',
              challengeId,
            },
            {
              onSuccess: handleNotificationPreferenceChangeSuccess,
              onError: handleNotificationPreferenceChangeError,
            }
          );
        }
        break;
      case ToolbarItemId.ArchiveChallenge:
        {
          openArchiveChallengeModal({
            id: challengeId,
            title,
            points,
          });
        }
        break;
      case ToolbarItemId.DuplicateChallenge:
        {
          openDuplicateChallengeModal({
            id: challengeId,
            title,
            points,
          });
        }
        break;
      case ToolbarItemId.ManageInteractionSettings:
        {
          openEditChallengeInteractionSettingsModal({
            id: challengeId,
            title,
            hideReactions: hideReactions ?? false,
            hideReplies: hideReplies ?? false,
          });
        }
        break;
    }
  };

  return {
    toolbarItems,
    onToolbarMenuItemClick,
  };
}
