export type Connection = SyncingConnection | NonSyncingConnection;

export type ListConnectionsResponse = {
  data: Connection[];
};

export enum ConnectionState {
  Connected = 'CONNECTED',
  ConnectionBroken = 'CONNECTION_BROKEN',
  Disconnected = 'DISCONNECTED',
  Disconnecting = 'DISCONNECTING',
  Syncing = 'SYNCING',
}

type BaseConnection = {
  connectionId: string;
  email: string;
  integrationImage: string;
  integrationName: string;
};

type NonSyncingConnection = {
  connectionState:
    | ConnectionState.Connected
    | ConnectionState.ConnectionBroken
    | ConnectionState.Disconnected
    | ConnectionState.Disconnecting;
  lastSyncedAt: string;
} & BaseConnection;

type SyncingConnection = {
  connectionState: ConnectionState.Syncing;
  lastSyncedAt?: string;
} & BaseConnection;
