import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { timeouts } from '../../../../../shared/dora/constants';
import { DoraSingleMessage } from '../../../../../shared/dora/DoraSingleMessage';
import type { TimePeriodOptionsQuestionProps } from '../../../../types';

const messages = defineMessages({
  editing: {
    defaultMessage:
      'If you want to summarize a longer time period, you can edit your choices above to narrow down the information included in my summary.',
    id: 'c6ObqP',
  },
  response: {
    defaultMessage:
      "I'll try my best to focus on this. Now it's time to pick how much of this flow you want to summarize!",
    id: 'OOX/XU',
  },
});

export const Message = (props: TimePeriodOptionsQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { usePredefinedTimePeriodSetting },
  } = props;

  const { formatMessage } = useIntl();
  const isSeen = usePredefinedTimePeriodSetting.getState().isQuestionSeen;
  const isSet = usePredefinedTimePeriodSetting((store) => store.isSet);

  const markSeen = usePredefinedTimePeriodSetting(
    (store) => store.markQuestionSeen
  );

  const setShouldRequestInput = usePredefinedTimePeriodSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = usePredefinedTimePeriodSetting(
    (store) => store.shouldRequestInput
  );

  const value = usePredefinedTimePeriodSetting((store) => store.value);

  const isEditingOtherSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'customTimePeriod'
  );

  useEffect(() => {
    if (!isSeen) {
      markSeen();
    }
  }, [isSeen, markSeen]);

  useEffect(() => {
    if (!value && !isSet && !shouldRequestInput) {
      setTimeout(() => {
        setShouldRequestInput(true);
      }, timeouts.showInput);
    }
  }, [isSet, setShouldRequestInput, shouldRequestInput, value]);

  return (
    <DoraSingleMessage isFaded={isEditingOtherSetting} shouldAnimate={!isSeen}>
      <TextStyle className="mb-6 text-gray-9">
        {formatMessage(messages.response)}
      </TextStyle>
      <TextStyle className="text-gray-9">
        {formatMessage(messages.editing)}
      </TextStyle>
    </DoraSingleMessage>
  );
};
