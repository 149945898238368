import type { Member } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';

import { MemberChatMessage } from '../shared/dora/MemberChatMessage';

export function DoraChatReportPrompt(props: {
  prompt?: string;
  memberDetails: Member;
  shouldAnimate?: boolean;
}) {
  const { memberDetails, prompt, shouldAnimate = false } = props;

  if (!prompt) {
    return null;
  }

  return (
    <MemberChatMessage
      fullName={memberDetails.profile.fullName}
      image={memberDetails.profile.image}
      memberId={memberDetails.memberId}
      shouldAnimate={shouldAnimate}
    >
      {prompt.split('\n').map((line, index) => (
        <TextStyle key={index} className={index > 0 ? 'mt-6' : undefined}>
          {line}
        </TextStyle>
      ))}
    </MemberChatMessage>
  );
}
