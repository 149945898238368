import { config } from '@assembly-web/services';
import type { LoaderFunctionArgs } from 'react-router-dom';

import { waitForRedirection } from '../../../services/waitForRedirection';

export async function externalParticipationLoader({
  params,
}: LoaderFunctionArgs) {
  const flowId = params['flowId'];
  const url = `${config.domains.legacyApp}/e/flows/${flowId}`;
  window.location.href = url.toString();
  await waitForRedirection();
}
