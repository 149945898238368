import { Challenge, TextStyle } from '@assembly-web/ui';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { useGetChallengeDetailsByIdQuery } from '../../../hooks/challenges/useGetChallengeDetailsByIdQuery';
import type { ChallengesDrawer } from '../types';

function ChallengeIcon() {
  return (
    <div className="h-6 w-6 rounded border-[1px] border-gray-1 bg-upgrade-3 p-1">
      <img src={Challenge} className="object-contain" alt="empty state" />
    </div>
  );
}

function ChallengeDrawerHeaderLoader() {
  return (
    <div className="flex w-full items-center gap-2">
      <ChallengeIcon />
      <div className="h-[12px] w-[256px] flex-shrink-0 rounded bg-gray-5"></div>
    </div>
  );
}

function ChallengeDrawerContent({ title }: { title: string }) {
  return (
    <div className="flex w-full items-center gap-2">
      <ChallengeIcon />
      <TextStyle
        as="span"
        variant="xs-medium"
        className="flex w-[392px] truncate text-left text-gray-1"
      >
        <FormattedMessage defaultMessage="Challenge:" id="/HaL5l" />
        <div className="ml-1">{title}</div>
      </TextStyle>
    </div>
  );
}

function ChallengeDrawerError() {
  return (
    <div className="flex w-full items-center gap-2">
      <ExclamationTriangleIcon className="h-4 w-4 text-gray-1" />
      <TextStyle
        as="span"
        variant="xs-medium"
        className="text-left text-gray-1"
      >
        <FormattedMessage defaultMessage="Challenge not found" id="l7IOuJ" />
      </TextStyle>
    </div>
  );
}

export function ChallengeDrawerHeader({
  data: { challengeId },
}: ChallengesDrawer) {
  const { data, isLoading, error } =
    useGetChallengeDetailsByIdQuery(challengeId);

  if (error) {
    return <ChallengeDrawerError />;
  }

  if (isLoading) {
    return <ChallengeDrawerHeaderLoader />;
  }

  if (data) {
    return <ChallengeDrawerContent title={data.title} />;
  }

  return <ChallengeDrawerError />;
}
