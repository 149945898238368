import { assemblyMobileAppQRCode, supportIcon } from '@assembly-web/assets';
import {
  config,
  MemberStatus,
  SplitNames,
  useFeatureSplit,
  userAuthStore,
  useUserDetails,
} from '@assembly-web/services';
import {
  Avatar,
  AvatarSize,
  GlobalFilterOption,
  Modal,
  RewardsDetailsContent,
  TextStyle,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { Menu } from '@headlessui/react';
import {
  ArrowRightOnRectangleIcon,
  BookOpenIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
  Cog6ToothIcon,
  DevicePhoneMobileIcon,
  GiftIcon,
  PaintBrushIcon,
  PuzzlePieceIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AnimatePresence } from 'framer-motion';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { twMerge } from 'tailwind-merge';
import type { MergeExclusive } from 'type-fest';

import { trackAppConnectionAction } from '../../connections/services/analytics';
import {
  getRewardsRedirectionPath,
  useRewardsHeaderDetails,
} from '../hooks/useRewardsHeaderDetails';
import { trackDiscoverAction } from '../services/analytics';
import { isAdminMember } from '../services/member';
import { mapV2ToV3RewardsRoute } from '../services/rewardsUtil';
import { useLegacyPathStore } from '../stores/legacyPathStore';
import { ThemeCustomizationModal } from './modals/ThemeCustomizationModal';

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

const messages = defineMessages({
  exploreTemplates: {
    defaultMessage: 'Explore templates',
    id: '1ODiI7',
  },
  getOurMobileApp: {
    defaultMessage: 'Get our mobile app',
    id: 'h63pxG',
  },
  viewProfile: {
    defaultMessage: 'View Profile',
    id: 'Jkxa7F',
  },
  goToAdmin: {
    defaultMessage: 'Go to Admin',
    id: 'st3OOX',
  },
  themeYourWorkspace: {
    defaultMessage: 'Change workspace theme',
    id: 'dkcsfC',
  },
  myRewards: {
    defaultMessage: 'My Rewards',
    id: 'Wocb8I',
  },
  mySettings: {
    defaultMessage: 'My Settings',
    id: 'ECJpOU',
  },
  helpCenter: {
    defaultMessage: 'Go to help center',
    id: 'HaZw6Q',
  },
  giveFeedback: {
    defaultMessage: 'Give us feedback',
    id: 'aQPexO',
  },
  getLiveSupport: {
    defaultMessage: 'Get live support',
    id: 'zEMDQ5',
  },
  switchAccounts: {
    defaultMessage: 'Switch accounts',
    id: '6xNr8c',
  },
  qrCodeMessage: {
    defaultMessage: `Scan this QR code using your phone’s camera to be redirected to the App Store. You can also search for “Assembly - Digital HQ” in the App Store to find it yourself.`,
    id: 'ybuaeI',
  },
  qrCodeModalTitle: {
    defaultMessage: 'Get our mobile app!',
    id: '934g+W',
  },
  qrCodeAltText: {
    defaultMessage: 'QR code for the Assembly mobile app',
    id: 'fS+KBJ',
  },
  connectAppsCTA: {
    defaultMessage: 'Connect apps',
    id: 'wKK2GT',
  },
});

function MenuItem({
  dataTestId,
  icon,
  text,
  url,
  target = '_blank',
  path,
  onClick,
  onNavLinkClick,
  onAnchorLinkClick,
}: {
  dataTestId?: string;
  icon: ReactNode;
  text: string;
} & MergeExclusive<
  MergeExclusive<
    {
      /** for external URLs */
      url: string;
      target?: '_blank' | '_self';
      onAnchorLinkClick?: () => void;
    },
    {
      /** for relative paths */
      path: string;
      onNavLinkClick?: () => void;
    }
  >,
  {
    onClick: () => void;
  }
>) {
  const { setIFrameSrc } = useLegacyPathStore();
  return (
    <Menu.Item>
      {({ active, close }) => (
        <div data-testid="user-menu-item">
          {onClick ? (
            <button
              data-testid={dataTestId}
              onClick={() => onClick()}
              className={twMerge(
                'group flex w-full items-center gap-2 border-none py-2 pl-4 pr-3 hover:bg-gray-3',
                active && 'bg-gray-3'
              )}
            >
              {icon}
              <TextStyle
                className="whitespace-nowrap"
                subdued
                variant="sm-regular"
                as="span"
              >
                {text}
              </TextStyle>
            </button>
          ) : null}
          {Boolean(url) && (
            <a
              data-testid={dataTestId}
              href={url}
              onClick={() => {
                onAnchorLinkClick?.();
              }}
              className={twMerge(
                'group flex w-full items-center gap-2 border-none py-2 pl-4 pr-3 hover:bg-gray-3',
                active && 'bg-gray-3'
              )}
              rel="noreferrer"
              target={target}
            >
              {icon}
              <TextStyle
                className="whitespace-nowrap"
                subdued
                variant="sm-regular"
                as="span"
              >
                {text}
              </TextStyle>
            </a>
          )}
          {path ? (
            <NavLink
              data-testid={dataTestId}
              to={path}
              onClick={() => {
                onNavLinkClick?.();
                setIFrameSrc(`${config.domains.legacyApp}/${path}`);
                close();
              }}
              className={twMerge(
                'group flex w-full items-center gap-2 border-none py-2 pl-4 pr-3 hover:bg-gray-3',
                active && 'bg-gray-3'
              )}
            >
              {icon}
              <TextStyle
                className="whitespace-nowrap"
                subdued
                variant="sm-regular"
                as="span"
              >
                {text}
              </TextStyle>
            </NavLink>
          ) : null}
        </div>
      )}
    </Menu.Item>
  );
}

function MenuSection({
  children,
  className = '',
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'flex border-collapse flex-col border-gray-4',
        className
      )}
    >
      {children}
    </div>
  );
}

export function UserMenu(props: { filter: GlobalFilterOption }) {
  const { filter } = props;
  const { data: userDetails } = useUserDetails();

  const { formatMessage } = useIntl();

  const { show: showIntercom } = useIntercom();
  const navigate = useAssemblyNavigate();
  const { setIFrameSrc } = useLegacyPathStore();

  let closeCallback: (() => void) | null;
  closeCallback = null;

  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [isThemeCustomizationModalOpen, setThemeCustomizationModalOpen] =
    useState(false);

  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;

  const {
    hideAllowanceDetails,
    hasCharitiesSetup,
    hasGiftCardsSetup,
    hasSwagsSetup,
    hasCultureSetup,
    hideEarnedPoints,
  } = useRewardsHeaderDetails();

  const isObserver = userDetails?.member.status === MemberStatus.Observer;

  const isRewardsSetup =
    !isObserver &&
    (hasCharitiesSetup ||
      hasCultureSetup ||
      hasGiftCardsSetup ||
      hasSwagsSetup);

  useEffect(() => {
    window.addEventListener('blur', function () {
      function handler() {
        if (
          document.activeElement instanceof HTMLIFrameElement &&
          closeCallback
        ) {
          closeCallback();
        }
      }

      window.setTimeout(handler, 0);

      return () => {
        window.removeEventListener('blur', handler);
      };
    });
  }, [closeCallback]);

  const { isTreatmentActive: addMergeAppsEnabled } = useFeatureSplit(
    SplitNames.AddMergeApps
  );
  const canAddConnections =
    addMergeAppsEnabled && userDetails && isAdminMember(userDetails.member);

  const { isTreatmentActive: isV3RewardsEnabled } = useFeatureSplit(
    SplitNames.RewardsV3
  );

  const { isTreatmentActive: allowThemeCustomization } = useFeatureSplit(
    SplitNames.CustomizeWorkspaceTheme
  );

  const { isTreatmentActive: isMSTeamsAppSubmissionEnabled } = useFeatureSplit(
    SplitNames.MSTeamsAppSubmission
  );

  if (!userDetails || !workspaceSlugPath) {
    return null;
  }

  const redirectionPath = getRewardsRedirectionPath({
    workspaceSlugPath,
    hasGiftCardsSetup,
    hasCharitiesSetup,
    isV3RewardsEnabled,
  });

  const { member } = userDetails;

  const myRewardsPath = mapV2ToV3RewardsRoute(
    '/a/discover',
    {
      filter: 0,
      tab: 1,
    },
    `${userDetails.assembly.workspaceSlugPath}/rewards/my-rewards`,
    userDetails.assembly.workspaceSlugPath
  );

  const showAdminMenu = Boolean(
    userAuthStore.getState().msTeamsContext
      ? !isMSTeamsAppSubmissionEnabled
      : true
  );

  return (
    <Menu>
      {({ open, close }) => {
        closeCallback = close;
        return (
          <div className="relative" data-testid="user-menu">
            <Menu.Button
              className={twMerge(
                'group flex w-[72px] items-center justify-center gap-2 rounded-lg px-2 py-1 hover:bg-gray-3',
                open && 'bg-gray-3'
              )}
              onClick={() => {
                if (!open) {
                  trackDiscoverAction('profileMenuOpened', { v3enabled: true });
                }
              }}
              data-testid="user-menu-button"
            >
              <Avatar
                memberID={member.memberId}
                size={AvatarSize.Medium}
                name={member.profile.fullName}
                image={member.profile.image}
              />
              <ChevronDownIcon
                data-arrow-icon
                className="h-4 w-4 group-aria-expanded:rotate-180"
              />
            </Menu.Button>
            {Boolean(open) && (
              <Menu.Items
                className={twMerge(
                  'ring-black ring-1 ring-gray-8 ring-opacity-5',
                  'absolute -right-[50%] top-12 z-10 md:right-0 md:top-12',
                  'h-screen max-md:w-screen md:h-fit md:w-max md:min-w-[190px]',
                  'divide-y bg-gray-1 py-1 shadow-lg-down focus:outline-none md:rounded-md'
                )}
              >
                <MenuSection>
                  <MenuItem
                    icon={
                      <Avatar
                        memberID={member.memberId}
                        size={AvatarSize.Small}
                        name={member.profile.fullName}
                        image={member.profile.image}
                        className="min-h-[24px] min-w-[24px]"
                      />
                    }
                    text={member.profile.fullName}
                    path={`${userDetails.assembly.workspaceSlugPath}/user/${member.memberId}`}
                    onNavLinkClick={() => {
                      trackDiscoverAction('profileMenuItemClicked', {
                        profileMenuItem: 'viewProfile',
                        v3enabled: true,
                      });
                    }}
                  />
                </MenuSection>

                <MenuSection>
                  <MenuItem
                    icon={
                      <DevicePhoneMobileIcon className="h-4 w-4 text-gray-8" />
                    }
                    text={formatMessage(messages.getOurMobileApp)}
                    onClick={() => {
                      trackDiscoverAction('profileMenuItemClicked', {
                        profileMenuItem: 'getMobileApp',
                        v3enabled: true,
                      });
                      setIsQRCodeModalOpen(true);
                    }}
                  />
                </MenuSection>

                <MenuSection className="md:hidden">
                  {Boolean(canAddConnections) && (
                    <MenuItem
                      icon={<PuzzlePieceIcon className="h-4 w-4 text-gray-8" />}
                      onNavLinkClick={() =>
                        trackAppConnectionAction('manageAppsOpened', {
                          filterType: filter,
                        })
                      }
                      text={formatMessage(messages.connectAppsCTA)}
                      path="connections"
                    />
                  )}
                  <MenuItem
                    icon={<BookOpenIcon className="h-4 w-4 text-gray-8" />}
                    text={formatMessage(messages.exploreTemplates)}
                    path="templates"
                  />
                </MenuSection>

                <MenuSection>
                  <RewardsDetailsContent
                    earnedBalance={userDetails.member.pointsEarned}
                    spentAllowance={
                      userDetails.member.allowance.points -
                      userDetails.member.pointsLeftThisCycle
                    }
                    totalAllowance={userDetails.member.allowance.points}
                    remainingAllowance={userDetails.member.pointsLeftThisCycle}
                    currency={userDetails.assembly.currency}
                    onBrowseRewardsClick={() => {
                      if (
                        filter === GlobalFilterOption.Rewards &&
                        isV3RewardsEnabled
                      ) {
                        close();
                        return;
                      } else {
                        setIFrameSrc(
                          `${config.domains.legacyApp}${redirectionPath}`
                        );
                        navigate(redirectionPath);
                        close();
                      }
                    }}
                    hideRedeemButton={!isRewardsSetup}
                    hideAllowanceDetails={hideAllowanceDetails}
                    hideEarnedPoints={hideEarnedPoints}
                    className="shadow-none lg:hidden"
                  />
                </MenuSection>

                <MenuSection className="border-collapse border-gray-4">
                  {(member.role.includes('Owner') ||
                    member.role.includes('Admin')) && (
                    <>
                      {Boolean(allowThemeCustomization) && (
                        <MenuItem
                          dataTestId="theme-customization"
                          icon={
                            <PaintBrushIcon className="h-4 w-4 text-gray-8" />
                          }
                          text={formatMessage(messages.themeYourWorkspace)}
                          onClick={() => {
                            setThemeCustomizationModalOpen(true);
                            trackDiscoverAction('profileMenuItemClicked', {
                              profileMenuItem: 'themeYourWorkspace',
                              v3enabled: true,
                            });
                          }}
                        />
                      )}
                      {Boolean(showAdminMenu) && (
                        <MenuItem
                          icon={
                            <ShieldCheckIcon className="h-4 w-4 text-gray-8" />
                          }
                          text={formatMessage(messages.goToAdmin)}
                          url={config.domains.adminApp}
                          onAnchorLinkClick={() => {
                            trackDiscoverAction('profileMenuItemClicked', {
                              profileMenuItem: 'goToAdmin',
                              v3enabled: true,
                            });
                          }}
                        />
                      )}
                    </>
                  )}

                  {Boolean(isRewardsSetup) && (
                    <MenuItem
                      icon={<GiftIcon className="h-4 w-4 text-gray-8" />}
                      text={formatMessage(messages.myRewards)}
                      path={
                        isV3RewardsEnabled
                          ? myRewardsPath
                          : `${userDetails.assembly.workspaceSlugPath}/rewards/my-rewards`
                      }
                      onNavLinkClick={() => {
                        trackDiscoverAction('profileMenuItemClicked', {
                          profileMenuItem: 'myRewards',
                          v3enabled: true,
                        });
                      }}
                    />
                  )}
                  <MenuItem
                    icon={<Cog6ToothIcon className="h-4 w-4 text-gray-8" />}
                    text={formatMessage(messages.mySettings)}
                    path={`${userDetails.assembly.workspaceSlugPath}/settings/general`}
                    onNavLinkClick={() => {
                      trackDiscoverAction('profileMenuItemClicked', {
                        profileMenuItem: 'mySettings',
                        v3enabled: true,
                      });
                    }}
                  />
                </MenuSection>

                <MenuSection>
                  <MenuItem
                    icon={
                      <QuestionMarkCircleIcon className="h-4 w-4 text-gray-8" />
                    }
                    text={formatMessage(messages.helpCenter)}
                    url="https://help.joinassembly.com/en/"
                    onAnchorLinkClick={() => {
                      trackDiscoverAction('profileMenuItemClicked', {
                        profileMenuItem: 'goToHelp',
                        v3enabled: true,
                      });
                    }}
                  />

                  <MenuItem
                    icon={
                      <img
                        src={supportIcon}
                        alt="Get support menu icon"
                        className="h-4 w-4 text-gray-8"
                      />
                    }
                    text={formatMessage(messages.getLiveSupport)}
                    onClick={() => {
                      closeCallback?.();
                      showIntercom();
                      trackDiscoverAction('profileMenuItemClicked', {
                        v3enabled: true,
                        profileMenuItem: 'getLiveSupport',
                      });
                    }}
                  />

                  <MenuItem
                    icon={
                      <ClipboardDocumentListIcon className="h-4 w-4 text-gray-8" />
                    }
                    text={formatMessage(messages.giveFeedback)}
                    url="https://my.joinassembly.com/e/flows/6478a909ec762bd84a6b18c1"
                    onAnchorLinkClick={() => {
                      trackDiscoverAction('profileMenuItemClicked', {
                        profileMenuItem: 'giveUsFeedback',
                        v3enabled: true,
                      });
                    }}
                  />
                </MenuSection>

                <MenuSection>
                  <MenuItem
                    icon={
                      <ArrowRightOnRectangleIcon className="h-4 w-4 text-gray-8" />
                    }
                    text={formatMessage(messages.switchAccounts)}
                    url={`${config.domains.legacyApp}/signout`}
                    target="_self"
                    onAnchorLinkClick={() => {
                      trackDiscoverAction('profileMenuItemClicked', {
                        profileMenuItem: 'switchAccounts',
                        v3enabled: true,
                      });
                    }}
                  />
                </MenuSection>
              </Menu.Items>
            )}
            <AnimatePresence>
              {Boolean(isQRCodeModalOpen) && (
                <Modal
                  title={formatMessage(messages.qrCodeModalTitle)}
                  isOpen
                  onClose={() => setIsQRCodeModalOpen(false)}
                >
                  <TextStyle subdued variant="sm-regular" className="text-left">
                    {formatMessage(messages.qrCodeMessage)}
                  </TextStyle>
                  <img
                    src={assemblyMobileAppQRCode}
                    alt={formatMessage(messages.qrCodeAltText)}
                    className="mx-auto h-[320px] w-[320px]"
                  />
                </Modal>
              )}
              <ThemeCustomizationModal
                isOpen={isThemeCustomizationModalOpen}
                userDetails={userDetails}
                onClose={() => {
                  setThemeCustomizationModalOpen(false);
                  close();
                }}
              />
            </AnimatePresence>
          </div>
        );
      }}
    </Menu>
  );
}
