import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

import { ToolbarButton } from './ToolbarButton';

const messages = defineMessages({
  label: {
    defaultMessage: 'Enter full screen drawer',
    id: 'Y7F6Va',
  },
  tooltipText: {
    defaultMessage: 'Full Screen',
    id: 'bRjypX',
  },
});

export const EnterFullScreenToolbarButton = ({
  onClick,
}: {
  onClick: () => void;
}) => {
  const { formatMessage } = useIntl();

  return (
    <ToolbarButton
      label={formatMessage(messages.label)}
      onClick={onClick}
      tooltipText={formatMessage(messages.tooltipText)}
    >
      <ArrowsPointingOutIcon className="h-4 w-4 text-[--foreground] group-hover:text-[--foreground-alt] group-focus:text-[--foreground-alt]" />
    </ToolbarButton>
  );
};
