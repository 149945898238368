import {
  APIEndpoints,
  type FlowPostResponse,
  type GetDraftsResponse,
  type UserFeedApiResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import {
  type InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import type { SearchIndexApiResponse } from '../useSearchIndex';
import type { SaveDraftsPayload } from './types';
import {
  updateChallengeWithDraftStatus,
  updatePostDrafts,
  updatePostWithDraftStatus,
  updateSearchFeedWithDraftStatus,
  updateUserFeedWithDraftStatus,
} from './utils';

export function useSaveDraftsMutation({
  onError,
}: {
  onError: (e: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  return useMutation({
    mutationFn: async (payload: SaveDraftsPayload) => {
      await assemblyAPI.post(APIEndpoints.saveDrafts, payload);
    },
    onMutate: async (payload) => {
      const postId = payload.replyData.postId;
      const draftKey = ['getDrafts', 'REPLY', postId];
      const hasDraft = Boolean(payload.replyData.messageTokens);

      const userFeedKeys = queryCache
        .findAll(['userFeed'])
        .map((query) => query.queryKey);
      const searchFeedKeys = queryCache
        .findAll(['searchResults'])
        .map((query) => query.queryKey);

      const previousDraft =
        queryClient.getQueryData<GetDraftsResponse>(draftKey);
      const previousUserFeed: InfiniteData<UserFeedApiResponse> | undefined =
        queryClient.getQueryData(userFeedKeys);
      const previousSearchFeed =
        queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
          searchFeedKeys
        );

      updatePostDrafts(draftKey, queryClient, previousDraft, payload);
      updateUserFeedWithDraftStatus(
        userFeedKeys,
        queryClient,
        postId,
        hasDraft
      );

      updateSearchFeedWithDraftStatus(
        searchFeedKeys,
        queryClient,
        postId,
        hasDraft
      );

      if (payload.replyData.kind === 'CHALLENGE') {
        const challengeQueryKey = ['challenge', postId];

        const previousChallenge =
          queryClient.getQueryData<FlowPostResponse>(challengeQueryKey);

        updateChallengeWithDraftStatus(
          challengeQueryKey,
          queryClient,
          postId,
          hasDraft
        );

        return { previousChallenge, previousUserFeed, previousSearchFeed };
      }

      if ('flowId' in payload) {
        const flowId = payload.flowId;

        const postQueryKey = ['assemblyFlowPost', flowId, postId];

        const previousPost =
          queryClient.getQueryData<FlowPostResponse>(postQueryKey);

        updatePostWithDraftStatus(postQueryKey, queryClient, postId, hasDraft);

        return { previousPost, previousUserFeed, previousSearchFeed };
      }

      return { previousUserFeed, previousSearchFeed };
    },
    onError: (err, payload, context) => {
      onError(err);
      if (!context) {
        return;
      }

      const postId = payload.replyData.postId;

      const userFeedKeys = queryCache
        .findAll(['userFeed'])
        .map((query) => query.queryKey);
      const searchFeedKeys = queryCache
        .findAll(['searchResults'])
        .map((query) => query.queryKey);

      queryClient.setQueryData(userFeedKeys, context.previousUserFeed);
      queryClient.setQueryData(searchFeedKeys, context.previousSearchFeed);

      if (payload.replyData.kind === 'CHALLENGE') {
        const challengeQueryKey = ['challenge', payload.replyData.postId];

        queryClient.setQueryData(challengeQueryKey, context.previousChallenge);
      }

      if (payload.replyData.kind === 'RESPONSE' && 'flowId' in payload) {
        const flowId = payload.flowId;
        const postQueryKey = ['assemblyFlowPost', flowId, postId];

        queryClient.setQueryData(postQueryKey, context.previousPost);
      }
    },
  });
}
