import type { Timeout } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { PopoutDrawerTemplate } from '../PopoutDrawerTemplate';
import type { DoraChatDrawer as DoraChatDrawerProps } from '../types';
import { DoraChatDrawerBody } from './DoraChatDrawerBody';

const messages = defineMessages({
  successNotificationLabel: {
    defaultMessage: 'Your report is ready!',
    id: 'gEC9ZY',
  },
  warningNotificationLabel: {
    defaultMessage: 'Something went wrong',
    id: 'JqiqNj',
  },
});

export function DoraChatDrawer(props: DoraChatDrawerProps) {
  const { hasNewActivity, newActivityType, isOpen } = props;
  const { formatMessage } = useIntl();
  const shouldNotify = !isOpen && hasNewActivity;

  const [shouldShowNotificationLabel, setShouldShowNotificationLabel] =
    useState(false);

  const [shouldShowDotNotification, setShouldShowDotNotification] =
    useState(false);

  const dotNotificationTimeout = useRef<Timeout>();

  useEffect(() => {
    if (shouldNotify) {
      setShouldShowNotificationLabel(true);

      dotNotificationTimeout.current = setTimeout(() => {
        setShouldShowNotificationLabel(false);
        setShouldShowDotNotification(true);
      }, 2000);
    }

    if (!shouldNotify) {
      setShouldShowNotificationLabel(false);
      setShouldShowDotNotification(false);
      clearTimeout(dotNotificationTimeout.current);
    }

    return () => {
      clearTimeout(dotNotificationTimeout.current);
    };
  }, [shouldNotify]);

  let notification;

  if (shouldShowNotificationLabel) {
    const isWarningActivity = newActivityType === 'warning';
    notification = (
      <div className="self-center">
        <motion.div
          animate={{ height: 'auto' }}
          initial={{ height: '0' }}
          transition={{
            duration: 0.3,
          }}
        >
          <div
            className={twMerge(
              'rounded-[72px] bg-primary-6 px-2 py-0.5 text-gray-1',
              isWarningActivity && 'bg-warning-6'
            )}
          >
            <TextStyle variant="xs-medium">
              {formatMessage(
                isWarningActivity
                  ? messages.warningNotificationLabel
                  : messages.successNotificationLabel
              )}
            </TextStyle>
          </div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-1">
      {notification}
      <PopoutDrawerTemplate
        {...props}
        shouldShowDotNotification={shouldShowDotNotification}
      >
        {(isFullScreen) => (
          <DoraChatDrawerBody {...props} isFullScreen={isFullScreen} />
        )}
      </PopoutDrawerTemplate>
    </div>
  );
}
