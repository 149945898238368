import { buildSafeUrl, config } from '@assembly-web/services';

import { waitForRedirection } from '../../../services/waitForRedirection';

export async function authenticateSAMLLoader() {
  const searchParams = new URLSearchParams(location.search);
  window.location.href = buildSafeUrl(
    `${config.domains.mobileApp}home`,
    searchParams
  );
  await waitForRedirection();
}
