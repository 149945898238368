import {
  BlocksGroup,
  type IndividualBlocksQuestionProps,
} from '../../../../types';
import { Message } from './Message';

export const IndividualBlocksQuestion = (
  props: IndividualBlocksQuestionProps
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useBlocksGroupSetting },
  } = props;

  const isBlocksGroupSet = useBlocksGroupSetting((store) => store.isSet);
  const origValue = useBlocksGroupSetting((store) => store.value);
  const editedValue = useEditSettingsStore((store) => store.blocksGroup.value);

  const value = editedValue ?? origValue;

  const isEditedBlocksGroupSet = useEditSettingsStore(
    (store) => store.blocksGroup.isSet
  );

  const isSet = isEditedBlocksGroupSet || isBlocksGroupSet;

  const isEditingPrevSetting = useEditSettingsStore(
    (store) =>
      store.isEditingRespondents() || store.settingEditing === 'blocksGroup'
  );

  return (
    isSet &&
    value === BlocksGroup.Individual &&
    !isEditingPrevSetting && <Message {...props} />
  );
};
