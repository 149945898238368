import { config, useUserDetails } from '@assembly-web/services';
import { useAssemblyNavigate } from '@assembly-web/ui';
import { useCallback } from 'react';

import { useLegacyPathStore } from '../modules/discover/stores/legacyPathStore';

export function useNavigateToUserFeed() {
  const { setIFrameSrc } = useLegacyPathStore();
  const assemblyNavigate = useAssemblyNavigate();
  const { data: userDetails } = useUserDetails();

  const navigate = useCallback(
    (memberId: string) => {
      if (userDetails) {
        const slug = `${userDetails.assembly.workspaceSlug.name}-${userDetails.assembly.workspaceSlug.shortCode}`;
        assemblyNavigate(`/a/${slug}/user/${memberId}`);

        if (location.pathname.includes(`/user`)) {
          setIFrameSrc(`${config.domains.legacyApp}/${slug}/user/${memberId}`);
        }
      }
    },
    [assemblyNavigate, setIFrameSrc, userDetails]
  );

  return { navigate };
}
