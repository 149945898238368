import { assemblyLogo } from '@assembly-web/assets';
import type { AssemblyBasicInfo } from '@assembly-web/services';
import { immer } from 'zustand/middleware/immer';
import { createStore } from 'zustand/vanilla';

type Platform = 'web' | 'android' | 'ios' | 'ms_teams' | 'mobile_app';
type OnBoardingState = {
  updateDetails: (name: string, logo: string) => void;
  resetBrandingToDefaults: () => void;
  setDeviceDetails: (platform: Platform) => void;
  setRedirectUrl: (url: string) => void;
  platform: Platform;
  isMobile?: boolean;
  redirectUrl: string;
} & Partial<AssemblyBasicInfo>;

export const onBoardingState = createStore<OnBoardingState>()(
  immer((set) => ({
    name: 'Assembly',
    logo: assemblyLogo,
    platform: 'web',
    redirectUrl: '/login',

    updateDetails: (name: string, logo: string) =>
      set((state) => {
        state.name = name;
        if (logo) {
          state.logo = logo;
        }
      }),

    resetBrandingToDefaults: () =>
      set((state) => {
        state.name = 'Assembly';
        state.logo = assemblyLogo;
        state.platform = 'web';
      }),

    setDeviceDetails: (platform: Platform) =>
      set((state) => {
        state.platform = platform;
        state.isMobile = platform === 'ios' || platform === 'android';
      }),

    setRedirectUrl: (url: string) =>
      set((state) => {
        state.redirectUrl = url;
      }),
  }))
);
