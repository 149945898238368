import type {
  ImportantActivitiesApiResponse,
  UserFeedApiResponse,
} from '@assembly-web/services';
import {
  ActivityCardTypeEnum,
  APIEndpoints,
  assemblyAPI,
} from '@assembly-web/services';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from './useSearchIndex';

type PostPayload = {
  flowId: string;
  responseId: string;
  returnPoints?: boolean;
};

export function useArchiveFlowPostMutation(
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void
) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  return useMutation(
    async (payload: PostPayload) => {
      const { flowId, responseId, returnPoints } = payload;
      await assemblyAPI.delete(
        APIEndpoints.archiveFlowPost(flowId, responseId),
        {
          params: {
            returnPoints,
          },
        }
      );
    },
    {
      onSuccess: (_, payload) => {
        onSuccessCallback?.();
        const { flowId, responseId, returnPoints } = payload;
        const userFeedKeys = queryCache
          .findAll(['userFeed'])
          .map((query) => query.queryKey);
        userFeedKeys.forEach((queryKey) => {
          const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
            queryClient.getQueryData(queryKey);

          const updatedTasks = produce(userFeedData, (draft) => {
            draft?.pages.forEach((page) => {
              page.data = page.data.filter((card) => {
                if (card.type === 'response') {
                  return !(
                    card._meta.entityId === responseId &&
                    card._meta.flowId === flowId
                  );
                }
                return true;
              });
            });
          });
          queryClient.setQueryData(queryKey, updatedTasks);
        });

        const searchFeedKeys = queryCache
          .findAll(['searchResults'])
          .map((query) => query.queryKey);
        searchFeedKeys.forEach((queryKey) => {
          const previousTasks =
            queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
              queryKey
            );

          const updatedTasks = produce(previousTasks, (draft) => {
            draft?.pages.forEach((page) => {
              page.data.data = page.data.data.filter((card) => {
                if (card.type === 'response') {
                  return !(
                    card._meta.entityId === responseId &&
                    card._meta.flowId === flowId
                  );
                }
                return true;
              });
            });
          });

          queryClient.setQueryData(queryKey, updatedTasks);
        });

        const importantQueryKey = ['importantCards'];
        const previousCards =
          queryClient.getQueryData<
            InfiniteData<ImportantActivitiesApiResponse>
          >(importantQueryKey);

        if (previousCards?.pages.length) {
          const updatedActivities = produce(previousCards, (draft) => {
            draft.pages.forEach((page) => {
              page.data.data = page.data.data.filter((card) => {
                switch (card.type) {
                  case ActivityCardTypeEnum.ResponseMentions:
                    return !(
                      card.entity.flowId === flowId &&
                      card.entity.responseId === responseId
                    );
                }

                return true;
              });
            });
          });

          queryClient.setQueryData(importantQueryKey, updatedActivities);
        }

        if (returnPoints) {
          queryClient.invalidateQueries(['userDetails']);
        }
      },
      onError: () => {
        onErrorCallback?.();
      },
    }
  );
}
