import { Button, ConfirmationModal, LoadingSpinner } from '@assembly-web/ui';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useUnpinItemFromNav } from '../../hooks/nav/useUnpinItemFromNav';

const messages = defineMessages({
  header: {
    defaultMessage: 'Would you like to unpin this collection?',
    id: 'CwVOzh',
  },
  body: {
    defaultMessage:
      "''{collectionName}' will still be available from the Collections tab and you can always re-pin it from there.",
    id: 'iSol3q',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  label: {
    defaultMessage: 'Unpin collection dialog',
    id: 'wdA9s1',
  },
  unpin: {
    defaultMessage: 'Unpin this collection',
    id: 'dU9MgR',
  },
  unpinning: {
    defaultMessage: 'Unpinning',
    id: 'tHg4oP',
  },
});

type UnpinCollectionProps = {
  collectionId: string;
  onClose: () => void;
  name: string;
  open: boolean;
};

export function UnpinCollectionModal(props: UnpinCollectionProps) {
  const { collectionId, name, onClose, open } = props;
  const { formatMessage } = useIntl();
  const { mutateAsync: unpinItem } = useUnpinItemFromNav();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await unpinItem({ itemId: collectionId, name });
      onClose();
      setTimeout(() => {
        setIsSubmitting(false);
      }, 300);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  return (
    <ConfirmationModal
      label={formatMessage(messages.label)}
      title={formatMessage(messages.header)}
      description={formatMessage(messages.body, {
        collectionName: name,
      })}
      open={open}
      onClose={onClose}
      leftButton={
        <Button variation="secondaryLite" isFullWidth onClick={onClose}>
          {formatMessage(messages.cancel)}
        </Button>
      }
      rightButton={
        <Button variation="danger" isFullWidth onClick={onSubmit}>
          {Boolean(isSubmitting) && <LoadingSpinner />}
          {formatMessage(isSubmitting ? messages.unpinning : messages.unpin)}
        </Button>
      }
    />
  );
}
