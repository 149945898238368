import { OpenTextEditor } from '@assembly-web/ui';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../../../services/analytics';
import { BottomActionSheet } from '../../../../../shared/dora/BottomActionSheet';
import { timeouts } from '../../../../../shared/dora/constants';
import { type CustomFocusInputProps, FocusType } from '../../../../types';

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Write something...',
    id: 'WRqY9c',
  },
});

export const Editor = (props: CustomFocusInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore,
    formSettingsStore: { useFocusTypeSetting, useCustomFocusSetting },
  } = props;

  const isNewInputSeen = useCustomFocusSetting.getState().isInputSeen;

  const markNewInputSeen = useCustomFocusSetting(
    (store) => store.markInputSeen
  );

  const setFocusType = useFocusTypeSetting((store) => store.setValue);
  const setCustomFocus = useCustomFocusSetting((store) => store.setValue);

  const { formatMessage } = useIntl();
  const focusTypeValue = useFocusTypeSetting((store) => store.value);
  const origValue = useCustomFocusSetting((store) => store.value);
  const [value, setValue] = useState(origValue ?? '');

  const isEditing = useEditSettingsStore(
    (store) => store.customFocus.shouldRequestInput
  );

  const exitEditMode = useEditSettingsStore((store) => store.exitEditMode);

  const handleSubmitClick = () => {
    if (!value) {
      return;
    }

    setCustomFocus(value);

    if (isEditing) {
      if (focusTypeValue !== FocusType.Custom) {
        setFocusType(FocusType.Custom);
        setTimeout(exitEditMode, timeouts.hideInput);
      } else {
        exitEditMode();
      }

      if (value !== origValue) {
        eventSourceStore.reset();
      }
    }

    trackDoraAction(
      isEditing ? 'summaryInputEditConfirmed' : 'inputtedFocusConfirmed',
      {
        doraSummaryInput: value,
      }
    );
  };

  const handleCancel = () => {
    trackDoraAction('summaryInputEditCanceled');
    exitEditMode();
  };

  const isEditInputSeen =
    useEditSettingsStore.getState().customFocus.isInputSeen;

  const markEditInputSeen = useEditSettingsStore(
    (store) => store.markInputSeen
  );

  useEffect(() => {
    if (!isNewInputSeen && !isEditing) {
      markNewInputSeen();
    } else if (!isEditInputSeen && isEditing) {
      markEditInputSeen('customFocus');
    }
  }, [
    isNewInputSeen,
    isEditing,
    isEditInputSeen,
    markEditInputSeen,
    markNewInputSeen,
  ]);

  return (
    <BottomActionSheet
      isEditing={isEditing}
      onCancel={handleCancel}
      shouldAnimateOnMount={
        (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen)
      }
    >
      <div className="mx-6 my-1">
        <OpenTextEditor
          className="max-h-[174px]"
          isSubmitDisabled={value.trim() === ''}
          maxLength={1500}
          onChange={(e) => setValue(e.target.value)}
          onSubmit={handleSubmitClick}
          placeholder={formatMessage(messages.placeholder)}
          value={value}
          autoFocusInput
          shouldSubmitOnEnter
        />
      </div>
    </BottomActionSheet>
  );
};
