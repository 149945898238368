import type { Member } from '@assembly-web/services';
import {
  AllowanceType,
  CreditState,
  isUserAdmin,
  MemberStatus,
  RewardType,
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import { useSearchParams } from 'react-router-dom';

import { mapV2ToV3RewardsRoute } from '../services/rewardsUtil';
import { useGetAmazonConnections } from './rewards/useGetAmazonConnections';
import { useAxomoStoreInfoQuery } from './rewards/useGetAxomoDetailsQuery';
import { useGetPlanFeaturesQuery } from './useGetPlanFeaturesQuery';
import { useGlobalFilter } from './useGlobalFilter';
import { useRedeemRewardsDetails } from './useRewardsDetails';

function canCurrentUserGiveAllowance(currentUser: Member) {
  const { allowance } = currentUser;

  const hasCustomAllowanceOfZero =
    allowance.type === AllowanceType.Custom && allowance.points === 0;

  const hasDefaultAllowanceOfZero =
    allowance.type === AllowanceType.Regular && allowance.points === 0;

  const hasNoAllowance =
    currentUser.allowance.type === AllowanceType.NoAllowance;

  const isObserver = currentUser.status === MemberStatus.Observer;

  const isReceiver = currentUser.status === MemberStatus.Receiver;

  return !(
    hasCustomAllowanceOfZero ||
    hasNoAllowance ||
    isObserver ||
    isReceiver ||
    hasDefaultAllowanceOfZero
  );
}

export function getRewardsRedirectionPath({
  hasGiftCardsSetup,
  hasCharitiesSetup,
  workspaceSlugPath = '',
  isV3RewardsEnabled = false,
}: {
  hasGiftCardsSetup: boolean;
  hasCharitiesSetup: boolean;
  workspaceSlugPath: string;
  isV3RewardsEnabled: boolean;
}) {
  const basePath = `/a/discover`;
  const paramMappings = {
    filter: 0,
    tab: 1,
  };

  const rewardsV2PathMap = {
    [RewardType.GiftCards]: 'gift-cards',
  };

  if (hasGiftCardsSetup || hasCharitiesSetup) {
    const path = `/${workspaceSlugPath}/rewards/${
      hasGiftCardsSetup
        ? rewardsV2PathMap[RewardType.GiftCards]
        : RewardType.Charities
    }`;

    if (isV3RewardsEnabled) {
      return mapV2ToV3RewardsRoute(
        basePath,
        paramMappings,
        path,
        workspaceSlugPath
      );
    } else {
      return path;
    }
  }

  return isV3RewardsEnabled
    ? mapV2ToV3RewardsRoute(
        basePath,
        paramMappings,
        `/${workspaceSlugPath}/rewards/culture`,
        workspaceSlugPath
      )
    : `/${workspaceSlugPath}/rewards/culture`;
}

export function useRewardsHeaderDetails() {
  let [searchParams] = useSearchParams();
  const isRewardsTabSelected = useGlobalFilter() === GlobalFilterOption.Rewards;

  const { data: userDetails } = useUserDetails();
  const { data: planDetails } = useGetPlanFeaturesQuery();

  const { data: swagTabInfo, isLoading: isSwagTabStatusLoading } =
    useAxomoStoreInfoQuery({
      enabled: !(
        planDetails?.uniqueId === 'free' || planDetails?.uniqueId === 'lite'
      ),
    });

  const { isTreatmentActive: isAmazonRewardsEnabled } = useFeatureSplit(
    SplitNames.AmazonRewards
  );

  const { data: amazonConnections } = useGetAmazonConnections({
    enabled: isAmazonRewardsEnabled && isRewardsTabSelected,
  });

  const isSwagTabOn = swagTabInfo?.visibility === 'ON';
  const isSwagConnected = swagTabInfo?.state === 'CONNECTED';

  const getAmazonRewardsTurnedOn = () => {
    if (amazonConnections === 'OK' || !amazonConnections) return false;
    return amazonConnections.visibility === 'ON';
  };

  const isAmazonRewardsTurnedOn = getAmazonRewardsTurnedOn();
  const isAmazonConnected = amazonConnections !== 'OK';

  const isAdmin = userDetails && Boolean(isUserAdmin(userDetails.member));

  const [{ data: redeemInfoData, isLoading: isRedeemInfoDataLoading }] =
    useRedeemRewardsDetails();

  const isObserver = userDetails?.member.status === MemberStatus.Observer;

  const isRedeemable = redeemInfoData?.isRedeemable;

  const canExploreRewards = (() => {
    const isCreditStateDeniedByAdmin =
      userDetails?.assembly.accounts.creditState === CreditState.DeniedByAdmin;

    if (isAdmin) {
      return true;
    }

    return !isCreditStateDeniedByAdmin;
  })();

  const hasCultureSetup = isAdmin
    ? true
    : (redeemInfoData?.hasCulture ?? false);
  const hasGiftCardsSetup = isAdmin
    ? true
    : (redeemInfoData?.hasGiftCards ?? false);
  const hasCharitiesSetup = isAdmin
    ? true
    : (redeemInfoData?.hasCharities ?? false);
  const hasSwagsSetup = isAdmin
    ? true
    : ((redeemInfoData?.hasSwags && isSwagTabOn) ?? false);
  const hasDiscountsSetup = redeemInfoData?.hasDiscounts ?? false;
  const hasAmazonSetup = isAdmin
    ? true
    : ((redeemInfoData?.hasAmazon && isAmazonRewardsTurnedOn) ?? false);

  const getMultipleCountriesConnected = () => {
    if (amazonConnections === 'OK') return false;
    return amazonConnections?.connections
      ? amazonConnections.connections.length > 1
      : false;
  };
  const hasMultipleCountriesConnected = getMultipleCountriesConnected();

  const rewardsType = searchParams.get('tab');

  const hasRewardsSetup =
    hasCharitiesSetup ||
    hasCultureSetup ||
    hasGiftCardsSetup ||
    hasSwagsSetup ||
    hasAmazonSetup;

  const hideEarnedPoints =
    Boolean(
      !userDetails?.member ||
        userDetails.member.status === MemberStatus.Giver ||
        userDetails.member.pointsEarned === 0
    ) || !hasRewardsSetup;

  const hideAllowanceDetails =
    Boolean(
      !userDetails?.member || !canCurrentUserGiveAllowance(userDetails.member)
    ) || userDetails?.member.allowance.type === AllowanceType.NoAllowance;

  const isRewardsTabsDisabled =
    !isRedeemInfoDataLoading &&
    ((rewardsType === RewardType.GiftCards && !hasGiftCardsSetup) ||
      (rewardsType === RewardType.Swag && !hasSwagsSetup) ||
      (rewardsType === RewardType.Charities && !hasCharitiesSetup) ||
      (rewardsType === RewardType.Culture && !hasCultureSetup) ||
      (rewardsType === RewardType.Amazon && !hasAmazonSetup) ||
      !hasRewardsSetup);

  const isRewardsDisabled =
    !isRedeemInfoDataLoading &&
    ((rewardsType === RewardType.GiftCards && !redeemInfoData?.hasGiftCards) ||
      (rewardsType === RewardType.Swag && !redeemInfoData?.hasSwags) ||
      (rewardsType === RewardType.Charities && !redeemInfoData?.hasCharities) ||
      (rewardsType === RewardType.Culture && !redeemInfoData?.hasCulture) ||
      (rewardsType === RewardType.Amazon && !redeemInfoData?.hasAmazon) ||
      !hasRewardsSetup);

  return {
    isSwagTabOn,
    isSwagConnected,
    isSwagTabStatusLoading,
    swagTabInfo,
    canExploreRewards,
    isObserver,
    hideAllowanceDetails,
    isRedeemable,
    hasCultureSetup,
    hasGiftCardsSetup,
    hasCharitiesSetup,
    hasSwagsSetup: isAdmin
      ? true
      : ((redeemInfoData?.hasSwags && isSwagTabOn) ?? false),
    hideEarnedPoints,
    isRedeemInfoDataLoading,
    isRewardsTabsDisabled,
    isRewardsDisabled,
    hasRewardsSetup,
    hasDiscountsSetup,
    isAmazonRewardsTurnedOn,
    isAmazonConnected,
    hasAmazonSetup,
    hasMultipleCountriesConnected,
    canRedeemAmazonRewards: redeemInfoData?.hasAmazon ?? false,
  };
}
