import { Button } from '@assembly-web/ui';
import type { ReactNode } from 'react';

export const Option = (props: {
  children: ReactNode;
  isDisabled?: boolean;
  onClick: () => void;
}) => {
  const { children, isDisabled, onClick } = props;

  return (
    <Button
      className="!justify-start rounded-none px-6"
      disabled={isDisabled}
      isFullWidth
      onClick={onClick}
      variation="tertiaryLite"
    >
      {children}
    </Button>
  );
};
