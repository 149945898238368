import { createContext, useContext } from 'react';

import type { FlowCreationData } from '../../types';

type EditorDataContextType = {
  id: string;
  isLoading: boolean;
  type: FlowCreationData['type'];
};

const EditorDataContext = createContext<EditorDataContextType | null>(null);

export const EditorDataProvider = EditorDataContext.Provider;

export const useEditorDataContext = () => {
  const context = useContext(EditorDataContext);

  if (!context) {
    throw new Error(
      'useEditorDataContext must be used within a EditorDataProvider'
    );
  }

  return context;
};
