import { useMemo } from 'react';

import { createEditSettingsStore } from '../../../stores/flowSummaryStore/formSettingsStore';
import { PopoutDrawerTemplate } from '../PopoutDrawerTemplate';
import type { FlowSummaryDrawer as FlowSummaryDrawerProps } from '../types';
import { FlowSummaryBody } from './FlowSummaryBody';

export const FlowSummaryDrawer = (props: FlowSummaryDrawerProps) => {
  const editSettingsStore = useMemo(createEditSettingsStore, []);

  return (
    <PopoutDrawerTemplate {...props}>
      <FlowSummaryBody {...props} editSettingsStore={editSettingsStore} />
    </PopoutDrawerTemplate>
  );
};
