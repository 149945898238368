import type { MetadataProps, PinnedCollection } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import { trackNavError } from '../../services/analytics';

export const navItemsQueryKey = ['navItems'];

type NavItemsPageData = {
  total: number;
  metadata?: MetadataProps;
  data: PinnedCollection[];
};

export type NavItemsResponse = {
  pages: NavItemsPageData[];
  pageParams: unknown[];
};

function getNavDataInfinite(
  options?: UseInfiniteQueryOptions<NavItemsPageData>
): UseInfiniteQueryOptions<NavItemsPageData> {
  return {
    ...options,
    queryKey: navItemsQueryKey,
    queryFn: async ({ pageParam = '' }) => {
      const { data } = await assemblyAPI.post(APIEndpoints.getNavItems, {
        ...(pageParam ? { cursor: pageParam, limit: 10 } : { limit: 10 }),
      });
      return data satisfies NavItemsPageData;
    },
    getNextPageParam: (page) => page.metadata?.pagination.cursor.next,
    getPreviousPageParam: (page) => page.metadata?.pagination.cursor.previous,
    onError: () => trackNavError({ action: 'getNavData' }),
  };
}

export function useNavData(
  options?: UseInfiniteQueryOptions<NavItemsPageData>
): UseInfiniteQueryResult<NavItemsPageData> & {
  pinnedCollections: PinnedCollection[];
  totalNoOfPinnedCollections: number;
  totalSoftPinnedCollections: number;
} {
  const result = useInfiniteQuery(getNavDataInfinite(options));
  const pinnedCollections = result.data?.pages.flatMap((x) => x.data) ?? [];

  const totalNoOfPinnedCollections = result.data?.pages[0].total ?? 0;
  const totalSoftPinnedCollections =
    result.data?.pages[0].data.filter((item) => item.softPin).length ?? 0;

  return {
    ...result,
    pinnedCollections,
    totalNoOfPinnedCollections,
    totalSoftPinnedCollections,
  };
}
