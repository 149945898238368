import { deriveEnumForValue, RewardType } from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import { useSearchParams } from 'react-router-dom';

import { RewardsSecondaryFilterParams } from '../../components/rewardsFilter/types';
import { useGlobalFilter } from '../useGlobalFilter';

export function useGetSelectedRewardsSecondaryFilters() {
  const [searchParams] = useSearchParams();
  const filter = useGlobalFilter();
  const rewardsFilter =
    deriveEnumForValue(searchParams.get('rewardsFilter'), RewardType) ??
    RewardType.GiftCards;
  const category =
    searchParams.get(RewardsSecondaryFilterParams.Category) ?? '';
  const country = searchParams.get(RewardsSecondaryFilterParams.Country) ?? '';
  const sortOrder =
    searchParams.get(RewardsSecondaryFilterParams.Sort) ?? 'asc';

  const isGiftCardsFilterSecondaryFilterSelected =
    filter === GlobalFilterOption.Rewards &&
    rewardsFilter === RewardType.GiftCards &&
    Boolean(country.length || sortOrder.length);

  const isSwagFilterSecondaryFilterSelected =
    filter === GlobalFilterOption.Rewards &&
    rewardsFilter === RewardType.Swag &&
    Boolean(category.length || sortOrder.length);

  const isRewardsSecondaryFilterApplied =
    isGiftCardsFilterSecondaryFilterSelected ||
    isSwagFilterSecondaryFilterSelected;

  return {
    rewardsSecondaryFilters: {
      category,
      country,
      sortOrder,
    },
    isRewardsSecondaryFilterApplied,
  };
}
