import { usePusherChannel, usePusherEvent } from '@assembly-web/pusher';
import { logger, useUserDetails } from '@assembly-web/services';
import { useQueryClient } from '@tanstack/react-query';

type ResponseUpdatedEvent = {
  flowId: string;
  responseId: string;
};

export function useFlowResponseUpdatesEvents() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  usePusherEvent<ResponseUpdatedEvent>(
    assembly,
    'FLOW_RESPONSE_EDITED',
    async (data) => {
      try {
        if (data) {
          await queryClient.refetchQueries([
            'assemblyFlowPost',
            data.flowId,
            data.responseId,
          ]);
        }
      } catch (e) {
        logger.error('Refetch error on flow response updated', {}, e as Error);
      }
    }
  );
}
