import type {
  Collection,
  ItemToAdd,
  PinnedCollection,
} from '@assembly-web/services';
import {
  getMixpanelCollectionItemType,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import {
  Button,
  HorizontalRule,
  LoadingSpinner,
  Modal,
  SearchableSelect,
  TextStyle,
} from '@assembly-web/ui';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { usePinItemToNav } from '../../hooks/nav/usePinItemToNav';
import { useCollectionItemsMutation } from '../../hooks/useCollectionItemsMutation';
import { useCollectionsMutation } from '../../hooks/useCollectionsMutation';
import type { SearchPayload } from '../../hooks/useSearchIndex';
import { trackDiscoverAction } from '../../services/analytics';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  addToNewCollection: {
    defaultMessage: 'Add to a new collection',
    id: 'aqCt/O',
  },
  createNewCollection: {
    defaultMessage: 'Create a new collection',
    id: 'PMBeAe',
  },
  or: {
    defaultMessage: 'or',
    id: 'Ntjkqd',
  },
  search: {
    defaultMessage: 'Search for a collection...',
    id: 'sZzyrN',
  },
  allCollectionsPinned: {
    defaultMessage: 'All of your existing collections are currently pinned',
    id: 'KD7+O/',
  },
});

type BaseCollectionModalProps = {
  filter: GlobalFilterOption;
  itemToAdd: ItemToAdd | null;
  onClose: () => void;
  onCreateCollection: () => void;
  open: boolean;
  searchTerm: string;
  collections: Collection[] | PinnedCollection[] | undefined;
  title: string;
  bodyText: string;
  collectionLabel: string;
  ctaText: string;
  ctaLoadingText: string;
  pinCollection?: boolean;
  query?: SearchPayload;
};

type CollectionOption = {
  id: string;
  name: string;
  icon: string;
};

const displayValue = (collection: CollectionOption) => collection.name;
const getIcon = (collection: CollectionOption) =>
  mapHexCodeToEmoticon(collection.icon);

export function BaseCollectionModal(props: BaseCollectionModalProps) {
  const {
    onCreateCollection,
    title,
    bodyText,
    collectionLabel,
    collections,
    ctaText,
    ctaLoadingText,
    onClose,
    open,
    itemToAdd,
    filter,
    pinCollection,
    searchTerm,
    query,
  } = props;
  const { formatMessage } = useIntl();

  const { mutateAsync: addItemToCollection } = useCollectionItemsMutation();
  const { mutateAsync: updateCollection } = useCollectionsMutation();
  const { mutateAsync: pinItem } = usePinItemToNav();

  const [collectionOptions, setCollectionOptions] = useState<
    CollectionOption[]
  >([]);
  const [selectedCollection, setSelectedCollection] =
    useState<CollectionOption>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (collections) {
      let options = collections.map((collection) => {
        if ('collectionId' in collection) {
          return {
            id: collection.collectionId,
            name: collection.name,
            icon: collection.icon.value,
          };
        }
        return {
          id: collection.id,
          name: collection.name,
          icon: collection.icon.value,
        };
      });

      setCollectionOptions(options);
      setSelectedCollection(options[0]);
    }
  }, [collections, itemToAdd]);

  const pinExistingCollection = async () => {
    if (!selectedCollection) {
      return;
    }
    try {
      setIsSubmitting(true);
      const response = await pinItem({
        id: selectedCollection.id,
        type: 'collection',
      });
      if (response.status === 200) {
        onClose();
      }
      setTimeout(() => {
        setIsSubmitting(false);
      }, 300);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  const onSubmit = async () => {
    if (!itemToAdd) {
      pinExistingCollection();
      return;
    }
    if (!selectedCollection) {
      return;
    }
    const { icon, ...itemToAddReq } = itemToAdd;
    setIsSubmitting(true);
    trackDiscoverAction('addToCollection', {
      filterType: filter,
      searchQuery: searchTerm,
      collectionItemType: getMixpanelCollectionItemType([itemToAdd]),
    });
    try {
      if (pinCollection) {
        await updateCollection(
          {
            payload: {
              name: selectedCollection.name,
              collectionId: selectedCollection.id,
              isPinned: true,
            },
            query,
          },
          {}
        );
      }
      const response = await addItemToCollection({
        action: 'add',
        collectionId: selectedCollection.id,
        collectionName: selectedCollection.name,
        ...itemToAddReq,
      });
      if (response.status === 200) {
        onClose();
      }
      setTimeout(() => {
        setIsSubmitting(false);
      }, 300);
    } catch (err) {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal title={title} isOpen={open} onClose={onClose}>
      <div className="grid gap-6">
        <TextStyle variant="sm-regular" className="pr-8">
          {bodyText}
        </TextStyle>
        {itemToAdd ? (
          <div className="flex items-center truncate">
            {itemToAdd.icon}
            {itemToAdd.name ? (
              <TextStyle
                variant="base-regular"
                className="ml-2 truncate"
                html={itemToAdd.name as string | TrustedHTML}
              />
            ) : (
              <TextStyle variant="base-regular" className="ml-2 truncate">
                {itemToAdd.link?.title ?? itemToAdd.link?.url}
              </TextStyle>
            )}
          </div>
        ) : null}
        <div className="grid gap-4">
          <SearchableSelect
            label={collectionLabel}
            onChange={setSelectedCollection}
            displayValue={displayValue}
            getIcon={getIcon}
            noOptionsAvailCopy={formatMessage(messages.allCollectionsPinned)}
            placeholder={formatMessage(messages.search)}
            selectedOption={selectedCollection}
            selectOptions={collectionOptions}
          />
          <HorizontalRule>
            <TextStyle variant="sm-regular" className="uppercase text-gray-7">
              {formatMessage(messages.or)}
            </TextStyle>
          </HorizontalRule>
          <Button
            variation="secondaryLite"
            isFullWidth
            onClick={onCreateCollection}
          >
            <PlusIcon className="h-4 w-4" />
            {formatMessage(
              itemToAdd
                ? messages.addToNewCollection
                : messages.createNewCollection
            )}
          </Button>
        </div>
        <section className="flex items-center">
          <Button
            variation="secondaryEmphasized"
            isFullWidth
            className="mr-3"
            onClick={onClose}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button
            variation="primary"
            isFullWidth
            disabled={collections?.length === 0}
            onClick={onSubmit}
          >
            {Boolean(isSubmitting) && <LoadingSpinner />}
            {isSubmitting ? ctaLoadingText : ctaText}
          </Button>
        </section>
      </div>
    </Modal>
  );
}
