import { SparklesIcon } from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

import { AnimateChat } from './AnimateChat';

export const DoraAvatar = (props: {
  isFaded?: boolean;
  notUpgraded?: boolean;
  shouldAnimate: boolean;
}) => {
  const { isFaded, notUpgraded, shouldAnimate } = props;

  const content = (
    <div className="col-start-1">
      <div
        className={twMerge(
          'mt-2 flex h-8 w-8 flex-row items-center justify-center self-center rounded-full bg-primary-6',
          isFaded ? 'opacity-50' : 'opacity-100',
          notUpgraded && 'bg-upgrade-4'
        )}
      >
        <SparklesIcon
          className={twMerge(
            'h-4 w-4 stroke-2 text-gray-1 max-md:max-w-none',
            notUpgraded && 'text-warning-9'
          )}
        />
      </div>
    </div>
  );

  return (
    <AnimateChat shouldAnimateOnMount={shouldAnimate}>{content}</AnimateChat>
  );
};
