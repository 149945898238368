import { logger } from '@assembly-web/services';

const idOfDrawerInFullScreenModeKey = 'idOfPopoutDrawerInFullScreen';

export function setFullScreenDrawerId(id?: string) {
  try {
    if (id) {
      sessionStorage.setItem(idOfDrawerInFullScreenModeKey, id);
      document.dispatchEvent(
        new CustomEvent('drawer-full-screen', { detail: id })
      );
    } else {
      sessionStorage.removeItem(idOfDrawerInFullScreenModeKey);
    }
  } catch (error) {
    logger.error(
      'Failed to update ID of drawer in full-screen mode',
      { key: idOfDrawerInFullScreenModeKey, value: id },
      error instanceof Error ? error : undefined
    );
  }
}

export function getFullScreenDrawerId() {
  try {
    return sessionStorage.getItem(idOfDrawerInFullScreenModeKey);
  } catch (error) {
    logger.error(
      'Failed to access ID of drawer in full-screen mode',
      { key: idOfDrawerInFullScreenModeKey },
      error instanceof Error ? error : undefined
    );

    return null;
  }
}
