import { minusIcon, plusIcon } from '@assembly-web/assets';
import { Checkbox, classNames } from '@assembly-web/ui';
import { Disclosure } from '@headlessui/react';
import type { ChangeEvent } from 'react';

type categoryLists = {
  key: string;
  name: string;
  color?: string;
  description?: string;
};

type OnFilterClickProps = {
  id: string;
  value: boolean;
  filterName: string;
};

type FilterAccordionProps = {
  categoryName: string;
  categoryLists: categoryLists[];
  filterName: string;
  onFilterClick: (props: OnFilterClickProps) => void;
  selectedFilters?: string[];
  isFilterApplied?: boolean;
};

export function FilterAccordion(props: FilterAccordionProps) {
  const {
    categoryName,
    categoryLists,
    onFilterClick,
    filterName,
    selectedFilters = [],
    isFilterApplied = false,
  } = props;

  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classNames(
              'flex w-full justify-between rounded-lg pt-3 text-left text-sm font-medium text-gray-9 focus:outline-none focus-visible:ring focus-visible:ring-opacity-75 focus-visible:focus:ring-primary-6',
              {
                'pb-1': open,
                'pb-2': !open,
              }
            )}
          >
            <span
              className={classNames('relative', {
                "after:absolute after:right-[-16px] after:top-[6px] after:ml-0.5 after:h-2 after:w-2 after:rounded-full after:border-r-[100%] after:bg-primary-6 after:content-['']":
                  isFilterApplied,
              })}
            >
              {categoryName}
            </span>
            <span
              className={classNames({
                'hidden transform': open,
                'inline transform': !open,
              })}
            >
              <img src={plusIcon} alt="" />
            </span>
            <span
              className={classNames({
                'hidden transform': !open,
                'inline transform': open,
              })}
            >
              <img src={minusIcon} alt="" />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className="text-sm text-gray-9">
            {categoryLists.map((categoryItem) => (
              <div
                className="relative flex items-start pb-2"
                key={categoryItem.key}
              >
                <div className="flex h-5 items-center">
                  <Checkbox
                    id={categoryItem.key}
                    aria-describedby="comments-description"
                    name={filterName}
                    checked={selectedFilters.includes(categoryItem.key)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      onFilterClick({
                        id: categoryItem.key,
                        value: e.target.checked,
                        filterName: filterName,
                      });
                    }}
                    dataTestId={`${filterName}-${categoryItem.key}`}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor={categoryItem.key} className="cursor-pointer">
                    {categoryItem.name}
                  </label>
                </div>
              </div>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
