import type { Timeout } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useAskDoraStore } from '../../../stores/useAskDoraStore';
import type { AskDoraDrawer } from '../types';

const messages = defineMessages({
  askDoraDefault: {
    defaultMessage: 'Ask DoraAI',
    id: '71+qWd',
  },
});

export const AskDoraDrawerHeader = (props: AskDoraDrawer) => {
  const { formatMessage } = useIntl();
  const threadId = useAskDoraStore((store) => store.getThreadId());
  const currentBlock = useAskDoraStore((store) => store.getCurrentBlockState());

  const shouldAnimateQuestion =
    props.data.query &&
    !threadId &&
    !(currentBlock.isLoadingSeen || currentBlock.isAnswerSeen);

  const [question, setQuestion] = useState(() => {
    if (shouldAnimateQuestion) {
      return '';
    }
    return props.data.query || '';
  });

  useEffect(() => {
    if (props.data.query && shouldAnimateQuestion) {
      const timeoutIds: Timeout[] = [];
      const animateQuestion = () => {
        let typedQuestion = '';
        const query = props.data.query;
        for (let i = 0; i < query.length; i++) {
          const id = setTimeout(() => {
            typedQuestion += query[i];
            setQuestion(typedQuestion);
          }, i * 70);
          timeoutIds.push(id);
        }
      };

      animateQuestion();

      return () => {
        timeoutIds.forEach((id) => clearTimeout(id));
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.query]);

  return (
    <>
      <div className="mr-2 rounded bg-gradient-to-r from-[#1d3fc8] via-purple-7 to-[#a309ca] p-1">
        <SparklesIcon className="h-4 w-4 text-gray-1" />
      </div>
      <TextStyle
        as="span"
        className="flex-1 truncate text-left text-gray-1"
        variant="xs-medium"
      >
        {props.data.query
          ? `${formatMessage(messages.askDoraDefault)}: ${question}`
          : formatMessage(messages.askDoraDefault)}
      </TextStyle>
    </>
  );
};
