import { useDebounce, type UserDetails } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import {
  ClockIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { getIdForDoraQuestion } from '../services/dora';
import { recentSearchesStore } from '../stores/recentSearchesStore';
import { useGetSuggestedQuestionsQuery } from './useGetSuggestedQuestionsQuery';

const messages = defineMessages({
  askDoraOption: {
    defaultMessage:
      "DoraAI - Get an answer from your files for - ''{searchValue}'",
    id: 'NmYMA/',
  },
  defaultSuggestedQuestion: {
    defaultMessage: 'What can DoraAI do for me?',
    id: 'mUo+gC',
  },
  recentSearchesHeader: {
    defaultMessage: 'Recent searches',
    id: 'h9IMab',
  },
  searchDefaultOption: {
    defaultMessage: "Search - ''{searchValue}'",
    id: 'dHe18h',
  },
  suggestedQuestionsHeader: {
    defaultMessage:
      'Suggested questions for DoraAI - Ask about contents of your files.',
    id: 'J+Qnsv',
  },
});

export function useDiscoverSearchOptions({
  searchValue,
  userDetails,
  showDoraSuggestions,
}: {
  searchValue: string;
  userDetails?: UserDetails;
  showDoraSuggestions: boolean;
}) {
  const { formatMessage, locale } = useIntl();

  const [isAutocompleteDropdownOpen, setIsAutocompleteDropdownOpen] =
    useState(false);

  const debouncedSearchValue = useDebounce(searchValue, 200);
  const { data: suggestedQuestions } = useGetSuggestedQuestionsQuery(
    debouncedSearchValue,
    2,
    { keepPreviousData: true, enabled: isAutocompleteDropdownOpen }
  );

  const suggestions = useMemo(() => {
    const optionSections = [];

    const recentQueries = recentSearchesStore
      .getState()
      .getRecentQueries(userDetails);

    if (searchValue) {
      const options = [
        {
          displayValue: (
            <>
              <MagnifyingGlassIcon className="mr-2 h-4 w-4 text-gray-8" />
              <TextStyle
                as="span"
                className="flex-1 truncate"
                subdued
                variant="sm-regular"
              >
                {formatMessage(messages.searchDefaultOption, { searchValue })}
              </TextStyle>
            </>
          ),
          documentId: undefined,
          id: 'defaultSearch',
          isDefaultDora: false,
          isRecent: false,
          textValue: searchValue,
          triggerDora: false,
        },
      ];
      if (showDoraSuggestions) {
        options.push({
          displayValue: (
            <>
              <div className="mr-2 rounded bg-gradient-to-r from-[#1d3fc8] via-purple-7 to-[#a309ca] p-0.5">
                <SparklesIcon className="h-3 w-3 text-gray-1" />
              </div>
              <TextStyle
                as="span"
                className="flex-1 truncate"
                subdued
                variant="sm-regular"
              >
                {formatMessage(messages.askDoraOption, { searchValue })}
              </TextStyle>
            </>
          ),
          documentId: undefined,
          id: 'doraSearch',
          isDefaultDora: false,
          isRecent: false,
          textValue: searchValue,
          triggerDora: true,
        });
      }

      const defaultOptionsSection = {
        id: 'defaultOptionsSection',
        options,
      };

      optionSections.push(defaultOptionsSection);
    } else if (recentQueries.length > 0) {
      const recentSearchesSection = {
        header: (
          <TextStyle className="truncate text-gray-7" variant="xs-regular">
            {formatMessage(messages.recentSearchesHeader)}
          </TextStyle>
        ),
        id: 'recentSearchesSection',
        options: recentQueries.map((recentQuery, index) => {
          return {
            displayValue: (
              <>
                <ClockIcon className="mr-2 h-4 w-4 text-gray-8" />
                <TextStyle
                  as="span"
                  className="flex-1 truncate"
                  subdued
                  variant="sm-regular"
                >
                  {recentQuery}
                </TextStyle>
              </>
            ),
            documentId: undefined,
            id: `recent${index}`,
            isDefaultDora: false,
            isRecent: true,
            textValue: recentQuery,
            triggerDora: false,
          };
        }),
      };

      optionSections.push(recentSearchesSection);
    }

    const options = [];

    if (suggestedQuestions?.data) {
      const questionIds = new Set<string>(); //Prevent duplicate questions from different documents
      for (const { question, id: documentId } of suggestedQuestions.data) {
        const questionId = getIdForDoraQuestion(question, { locale });
        if (questionIds.has(questionId)) continue;

        const option = {
          displayValue: (
            <>
              <QuestionMarkCircleIcon className="mr-2 h-4 w-4 text-gray-8" />
              <TextStyle
                as="span"
                className="flex-1 truncate"
                subdued
                variant="sm-regular"
              >
                {question}
              </TextStyle>
            </>
          ),
          documentId,
          id: questionId,
          isDefaultDora: false,
          isRecent: false,
          textValue: question,
          triggerDora: true,
        };
        questionIds.add(questionId);
        options.push(option);
      }
    }

    options.push({
      displayValue: (
        <>
          <SparklesIcon className="mr-2 h-4 w-4 text-gray-8" />
          <TextStyle
            as="span"
            className="flex-1 truncate"
            subdued
            variant="sm-regular"
          >
            {formatMessage(messages.defaultSuggestedQuestion)}
          </TextStyle>
        </>
      ),
      documentId: undefined,
      id: 'suggested',
      isDefaultDora: true,
      isRecent: false,
      textValue: formatMessage(messages.defaultSuggestedQuestion),
      triggerDora: true,
    });

    if (showDoraSuggestions) {
      optionSections.push({
        id: 'suggestedDoraQuestions',
        header: (
          <TextStyle className="truncate text-gray-7" variant="xs-regular">
            {formatMessage(messages.suggestedQuestionsHeader)}
          </TextStyle>
        ),
        options: options,
      });
    }

    return optionSections;
  }, [
    formatMessage,
    locale,
    userDetails,
    searchValue,
    suggestedQuestions,
    showDoraSuggestions,
  ]);

  return {
    isAutocompleteDropdownOpen,
    setIsAutocompleteDropdownOpen,
    suggestions,
  };
}
