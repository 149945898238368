import {
  config,
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import {
  GlobalFilterOption,
  RewardsDetails,
  useAssemblyNavigate,
} from '@assembly-web/ui';

import { useGlobalFilter } from '../hooks/useGlobalFilter';
import {
  getRewardsRedirectionPath,
  useRewardsHeaderDetails,
} from '../hooks/useRewardsHeaderDetails';
import { trackDiscoverAction } from '../services/analytics';
import { useLegacyPathStore } from '../stores/legacyPathStore';

export type GiftCardReward = {
  brandKey: string;
  brandName: string;
  disclaimer: string;
  description: string;
  shortDescription: string;
  terms: string;
  createdDate: string;
  lastUpdateDate: string;
  status: string;
  rewardType: string;
  isActive: boolean;
  countries: string[];
  maxLimit?: number;
};

export type GetGiftCardResponse = {
  data: {
    rewards: GiftCardReward[];
    exchangeRate: number;
  };
};

export function Rewards({ className }: { className?: string }) {
  const { data: userDetails } = useUserDetails();

  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;

  const {
    isRedeemable = false,
    isObserver,
    hideAllowanceDetails,
    hasCharitiesSetup,
    hasGiftCardsSetup,
    hideEarnedPoints,
  } = useRewardsHeaderDetails();

  const navigate = useAssemblyNavigate();
  const { setIFrameSrc } = useLegacyPathStore();
  const { treatment } = useFeatureSplit(SplitNames.RewardsV3);

  const filter = useGlobalFilter();

  const isV3RewardsEnabled = treatment === 'on';

  if (!userDetails?.assembly.currency || !workspaceSlugPath) return null;

  const redirectionPath = getRewardsRedirectionPath({
    workspaceSlugPath,
    hasGiftCardsSetup,
    hasCharitiesSetup,
    isV3RewardsEnabled,
  });

  const onBrowseRewardsClick = () => {
    trackDiscoverAction('browseRewardsClicked', {
      v3enabled: true,
    });
    if (filter === GlobalFilterOption.Rewards && isV3RewardsEnabled) {
      return;
    }
    if (!isV3RewardsEnabled) {
      setIFrameSrc(`${config.domains.legacyApp}${redirectionPath}`);
    }
    navigate(redirectionPath);
  };

  return (
    <RewardsDetails
      className={className}
      earnedBalance={userDetails.member.pointsEarned}
      spentAllowance={
        userDetails.member.allowance.points -
        userDetails.member.pointsLeftThisCycle
      }
      totalAllowance={userDetails.member.allowance.points}
      remainingAllowance={userDetails.member.pointsLeftThisCycle}
      currency={userDetails.assembly.currency}
      isRedeemable={isRedeemable}
      isObserver={isObserver}
      hideAllowanceDetails={hideAllowanceDetails}
      hideEarnedPoints={hideEarnedPoints}
      onBrowseRewardsClick={onBrowseRewardsClick}
      onShowDetailsClick={() => {
        trackDiscoverAction('pointsMenuOpened', {
          v3enabled: true,
        });
      }}
    />
  );
}
