import { createContext, type ReactNode, useState } from 'react';

export const ModalsContext = createContext<{
  selectedPostOrReplyToDeleteDetails: {
    replyId: string | null;
    flowId: string;
    responseId: string;
    hasTrophies?: boolean;
    isLegacyReply?: boolean;
    challengeId?: string | null;
    onReplyDeleteSuccess?: () => void;
    onEditReplyCancel?: () => void;
  };
  onPostOrDeleteModalClose: () => void;
  onDeleteChallengeReplyClick: ({
    replyId,
    challengeId,
    hasPoints,
  }: {
    replyId: string;
    challengeId: string;
    hasPoints: boolean;
  }) => void;
  onDeleteReplyOrPostClick: ({
    replyId,
    flowId,
    responseId,
    hasTrophies,
    isLegacyReply,
    onReplyDeleteSuccess,
    onEditReplyCancel,
  }: {
    flowId: string;
    responseId: string;
    hasTrophies?: boolean;
    replyId: string | null;
    isLegacyReply?: boolean;
    onEditReplyCancel?: () => void;
    onReplyDeleteSuccess?: () => void;
  }) => void;
}>({
  selectedPostOrReplyToDeleteDetails: {
    replyId: '',
    flowId: '',
    responseId: '',
    hasTrophies: false,
    isLegacyReply: false,
    onEditReplyCancel: undefined,
    onReplyDeleteSuccess: undefined,
  },
  onPostOrDeleteModalClose: () => {},
  onDeleteReplyOrPostClick: () => {},
  onDeleteChallengeReplyClick: () => {},
});

export const ModalsProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [
    selectedPostOrReplyToDeleteDetails,
    setSelectedPostOrReplyToDeleteDetails,
  ] = useState<{
    flowId: string;
    responseId: string;
    hasTrophies?: boolean;
    replyId: string | null;
    isLegacyReply?: boolean;
    challengeId?: string | null;
    onEditReplyCancel?: () => void;
    onReplyDeleteSuccess?: () => void;
  }>({
    flowId: '',
    replyId: null,
    responseId: '',
    challengeId: null,
    hasTrophies: false,
    isLegacyReply: false,
    onEditReplyCancel: undefined,
    onReplyDeleteSuccess: undefined,
  });

  const handleDeletePostOrReplyModalClose = () => {
    setSelectedPostOrReplyToDeleteDetails({
      replyId: null,
      flowId: '',
      responseId: '',
      challengeId: null,
      hasTrophies: false,
      isLegacyReply: false,
      onEditReplyCancel: undefined,
      onReplyDeleteSuccess: undefined,
    });
  };

  const handleDeletePostOrReplyClick = ({
    replyId,
    flowId,
    responseId,
    hasTrophies,
    isLegacyReply,
    onReplyDeleteSuccess,
    onEditReplyCancel,
  }: {
    replyId: string | null;
    flowId: string;
    responseId: string;
    hasTrophies?: boolean;
    isLegacyReply?: boolean;
    onReplyDeleteSuccess?: () => void;
    onEditReplyCancel?: () => void;
  }) => {
    setSelectedPostOrReplyToDeleteDetails({
      replyId,
      flowId,
      responseId,
      hasTrophies,
      isLegacyReply,
      onReplyDeleteSuccess,
      onEditReplyCancel,
      challengeId: null,
    });
  };

  const handleDeleteChallengeReplyClick = ({
    replyId,
    challengeId,
    hasPoints,
  }: {
    replyId: string;
    challengeId: string;
    hasPoints: boolean;
  }) => {
    setSelectedPostOrReplyToDeleteDetails({
      replyId,
      flowId: '',
      challengeId,
      responseId: '',
      hasTrophies: hasPoints,
    });
  };

  return (
    <ModalsContext.Provider
      value={{
        selectedPostOrReplyToDeleteDetails,
        onDeleteReplyOrPostClick: handleDeletePostOrReplyClick,
        onPostOrDeleteModalClose: handleDeletePostOrReplyModalClose,
        onDeleteChallengeReplyClick: handleDeleteChallengeReplyClick,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};
