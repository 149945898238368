import { userAuthStore } from '@assembly-web/services';
import { app } from '@microsoft/teams-js';

export async function initializeMSTeamsContext() {
  try {
    await app.initialize();
    const msTeamsContext = await app.getContext();
    userAuthStore.getState().setMSTeamsContext(msTeamsContext.user?.loginHint);
  } catch (error) {
    if (
      error instanceof Error &&
      !error.message.includes('Initialization Failed. No Parent window found.')
    ) {
      console.info(error.message);
    }
  }
}
