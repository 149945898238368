import {
  APIEndpoints,
  getAPIErrorCode,
  unauthenticatedAssemblyAPI,
} from '@assembly-web/services';
import { AssemblyLoadingIndicator } from '@assembly-web/ui';
import type { LoaderFunctionArgs } from 'react-router-dom';
import { redirect } from 'react-router-dom';

export function AccountInviteRoute() {
  return <AssemblyLoadingIndicator />;
}

export async function accountInviteLoader({
  params: { inviteToken },
  request: { signal },
}: LoaderFunctionArgs) {
  try {
    const {
      data: { workspaceName },
    } = await unauthenticatedAssemblyAPI.post(
      APIEndpoints.acceptWorkspaceInvite,
      {
        inviteToken,
      },
      { signal }
    );
    return redirect(`/login?referrer=${workspaceName}`);
  } catch (error) {
    const errorCode = getAPIErrorCode(error);
    const isUserNotFoundError = errorCode === 'user_not_found';

    let url = '/create-account';
    if (errorCode === 'user_already_exists') {
      url = '/login';
    } else if (isUserNotFoundError) {
      url = '/404';
    }
    return redirect(`${url}?error=${errorCode}`);
  }
}
