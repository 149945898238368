import { FlowCreationOverflowCard } from '@assembly-web/ui';

import type { FlowCreationDrawer } from '../types';
import { useEditorDrawerHeaderTitle } from './hooks/useEditorDrawerHeaderTitle';

export const FlowCreationDrawerOverflowCard = (
  props: FlowCreationDrawer & {
    onClick: () => void;
    onClose: () => void;
  }
) => {
  const { data, onClick, onClose, lastInteractedTS } = props;
  const title = useEditorDrawerHeaderTitle(data);

  return (
    <FlowCreationOverflowCard
      isLoading={false}
      title={title}
      onClose={onClose}
      onClick={onClick}
      isChatView={data.view === 'chat'}
      lastEditedTS={lastInteractedTS}
    />
  );
};
