import { classNames, TextStyle } from '@assembly-web/ui';
import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useLoadingDotsAnimation } from '../../../hooks/useLoadingDotsAnimation';
import { DisconnectModalContext } from '../contexts/DisconnectModalContext';
import { type Connection, ConnectionState } from '../types';
import { StatusButton } from './StatusButton';

const messages = defineMessages({
  appColumn: {
    defaultMessage: 'App',
    id: '2rUVsU',
  },
  connectAnotherApp: {
    defaultMessage: 'Connect another app',
    id: 'hMI/90',
  },
  connectNewApp: {
    defaultMessage: 'Connect new app',
    id: 'spSZ03',
  },
  connectedToColumn: {
    defaultMessage: 'Connected to',
    id: 'WruA7o',
  },
  formattedSyncTimestamp: {
    defaultMessage: '{date} at {time}',
    id: 'QGnOZU',
  },
  lastSyncedColumn: {
    defaultMessage: 'Last synced',
    id: 'ucEwQX',
  },
  statusColumn: {
    defaultMessage: 'Status',
    id: 'tzMNF3',
  },
  syncing: {
    defaultMessage: 'Syncing now',
    id: 'UwsMyB',
  },
  tableName: {
    defaultMessage: 'My apps',
    id: 'TnPYbz',
  },
});

const validConnectionStates = new Set(Object.values(ConnectionState));

export function ConnectionsTable({
  connections,
}: {
  connections: Connection[];
}) {
  const { formatDate, formatMessage, formatTime } = useIntl();

  const { handleAppDisconnectClick } = useContext(DisconnectModalContext);

  const dots = useLoadingDotsAnimation();

  return (
    <table className="w-full border-separate border-spacing-0 rounded-lg border border-gray-5">
      <colgroup className="max-md:hidden">
        <col className="w-1/2" />
        <col className="w-1/2" />
        <col className="min-w-[114px]" />
        <col />
      </colgroup>
      <thead>
        <tr>
          <td className="p-3">
            <TextStyle as="span" className="text-gray-9" variant="sm-medium">
              {formatMessage(messages.appColumn)}
            </TextStyle>
          </td>
          <td className="border-l border-gray-5 p-3 max-md:hidden">
            <TextStyle as="span" className="text-gray-9" variant="sm-medium">
              {formatMessage(messages.connectedToColumn)}
            </TextStyle>
          </td>
          <td className="whitespace-nowrap border-l border-gray-5 p-3 max-md:hidden">
            <TextStyle as="span" className="text-gray-9" variant="sm-medium">
              {formatMessage(messages.lastSyncedColumn)}
            </TextStyle>
          </td>
          <td className="border-l border-gray-5 p-3 max-md:hidden">
            <TextStyle as="span" className="text-gray-9" variant="sm-medium">
              {formatMessage(messages.statusColumn)}
            </TextStyle>
          </td>
        </tr>
      </thead>

      <tbody>
        {connections
          .filter((app) => validConnectionStates.has(app.connectionState))
          .map((connectedApp) => {
            const appBrand = (
              <div className="flex items-center">
                <img
                  alt=""
                  className="h-8 w-8"
                  src={connectedApp.integrationImage}
                />
                <TextStyle
                  as="span"
                  className="ml-2 truncate text-gray-9"
                  variant="sm-medium"
                >
                  {connectedApp.integrationName}
                </TextStyle>
              </div>
            );

            const handleDisconnectClick = () =>
              handleAppDisconnectClick(connectedApp);

            const isNewAppSyncing =
              connectedApp.connectionState === ConnectionState.Syncing &&
              !connectedApp.lastSyncedAt;

            const animatedSyncing = `${formatMessage(
              messages.syncing
            )}${'.'.repeat(dots)}`;

            let syncedMessage;

            if (connectedApp.connectionState === ConnectionState.Syncing) {
              syncedMessage = animatedSyncing;
            } else {
              const lastSyncedAtDate = new Date(connectedApp.lastSyncedAt);

              syncedMessage = formatMessage(messages.formattedSyncTimestamp, {
                date: formatDate(lastSyncedAtDate, {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                }),
                time: formatTime(lastSyncedAtDate, {
                  hour: '2-digit',
                  minute: '2-digit',
                }),
              });
            }

            return (
              <tr key={connectedApp.connectionId}>
                <td className="hidden border-t border-gray-5 p-3 max-md:block">
                  <div className="flex flex-col">
                    {appBrand}
                    <div
                      className={classNames('mt-1 flex', {
                        'flex-col': !isNewAppSyncing,
                      })}
                    >
                      {isNewAppSyncing ? (
                        <TextStyle
                          as="span"
                          className="text-gray-9"
                          variant="sm-regular"
                        >
                          {animatedSyncing}
                        </TextStyle>
                      ) : (
                        <>
                          <TextStyle
                            as="span"
                            className="text-gray-9 [overflow-wrap:anywhere]"
                            variant="sm-regular"
                          >
                            {`${connectedApp.email} •`}
                          </TextStyle>
                          <TextStyle
                            as="span"
                            className="text-gray-9"
                            variant="sm-regular"
                          >
                            {syncedMessage}
                          </TextStyle>
                        </>
                      )}{' '}
                    </div>
                    <div className="mt-2">
                      <StatusButton
                        connectionState={connectedApp.connectionState}
                        onDisconnectClick={handleDisconnectClick}
                      />
                    </div>
                  </div>
                </td>

                <td className="border-t border-gray-5 p-3 max-md:hidden">
                  {appBrand}
                </td>
                <td className="border-l border-t border-gray-5 p-3 max-md:hidden">
                  <TextStyle
                    as="span"
                    className="text-gray-9"
                    variant="sm-regular"
                  >
                    {!isNewAppSyncing ? connectedApp.email : animatedSyncing}
                  </TextStyle>
                </td>
                <td className="whitespace-nowrap border-l border-t border-gray-5 p-3 max-md:hidden">
                  <TextStyle
                    as="span"
                    className="text-gray-9"
                    variant="sm-regular"
                  >
                    {syncedMessage}
                  </TextStyle>
                </td>
                <td className="border-l border-t border-gray-5 p-3 max-md:hidden">
                  <StatusButton
                    connectionState={connectedApp.connectionState}
                    onDisconnectClick={handleDisconnectClick}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
