import type { Collection, LinkToEdit } from '@assembly-web/services';
import { checkValidUrl } from '@assembly-web/services';
import type { GlobalFilterOption } from '@assembly-web/ui';
import {
  Button,
  LoadingSpinner,
  Modal,
  TextField,
  TextStyle,
} from '@assembly-web/ui';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useCollectionItemsMutation } from '../../hooks/useCollectionItemsMutation';
import {
  trackDiscoverAction,
  trackDiscoverError,
} from '../../services/analytics';

const messages = defineMessages({
  addHeader: {
    defaultMessage: 'Add an external link',
    id: 'X85ad2',
  },
  editHeader: {
    defaultMessage: 'Edit an external link',
    id: '7h1IC7',
  },
  content: {
    defaultMessage:
      'Make your collection more powerful with links to PDFs, Google Drive, Figma, JIRA, Notion, YouTube, landing pages, etc.',
    id: 'Zw9adb',
  },
  urlLabel: {
    defaultMessage: 'Plese enter a valid URL',
    id: 'Oh87SM',
  },
  titleLabel: {
    defaultMessage: 'Give this URL a title (optional)',
    id: '32HChp',
  },
  urlPlaceholder: {
    defaultMessage: 'Paste or type in https://...',
    id: 'Pkf65i',
  },
  titlePlaceholder: {
    defaultMessage: 'eg. My Special Link',
    id: 'g9M70x',
  },
  addLink: {
    defaultMessage: 'Add link',
    id: 'JnxkKM',
  },
  addingLink: {
    defaultMessage: 'Adding link',
    id: '6hEXFm',
  },
  editLink: {
    defaultMessage: 'Edit link',
    id: 'sq+2Tt',
  },
  editingLink: {
    defaultMessage: 'Editing link',
    id: 'S1VhXc',
  },
});

type CreateCollectionModalProps = {
  collection: Collection | null;
  filter: GlobalFilterOption;
  linkToEdit: LinkToEdit | null;
  onClose: () => void;
  open: boolean;
};

const inputClassName = 'mt-1 placeholder:text-gray-8 text-sm';
const labelClassName = 'text-sm font-medium';

export function AddExternalLinkModal(props: CreateCollectionModalProps) {
  const { collection, filter, linkToEdit, open, onClose } = props;
  const { formatMessage } = useIntl();
  const { mutateAsync: updateItemToCollection } = useCollectionItemsMutation();

  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (linkToEdit) {
      setUrl(linkToEdit.link.url);
      setTitle(linkToEdit.link.title ?? '');
    }
  }, [linkToEdit]);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setUrl('');
      setTitle('');
      setIsUrlValid(true);
      setIsSubmitting(false);
    }, 300);
  };

  const onSubmit = async () => {
    if (!collection) {
      return;
    }
    const isValid = checkValidUrl(url);
    if (isValid) {
      setIsUrlValid(true);
      setIsSubmitting(true);
      try {
        const response = await updateItemToCollection({
          action: linkToEdit ? 'edit' : 'add',
          collectionId: collection.collectionId,
          collectionName: collection.name,
          entityId: linkToEdit?.entityId ?? '',
          link: {
            url: url.trim(),
            title: title.trim(),
          },
          type: 'link',
        });
        trackDiscoverAction('linkAddedToCollection', {
          filterType: filter,
        });
        if (response.status === 200) {
          handleClose();
        } else {
          setIsSubmitting(false);
        }
      } catch (err) {
        setIsSubmitting(false);
        trackDiscoverError({ action: 'linkAddedToCollection' });
      }
    } else {
      setIsUrlValid(false);
    }
  };

  return (
    <Modal
      title={formatMessage(
        linkToEdit ? messages.editHeader : messages.addHeader
      )}
      isOpen={open}
      onClose={handleClose}
    >
      <div className="grid grid-flow-row gap-6">
        <TextStyle variant="sm-regular" className="pr-8">
          {formatMessage(messages.content)}
        </TextStyle>
        <TextField
          name="url"
          label={formatMessage(messages.urlLabel)}
          value={url}
          onChange={(ev: ChangeEvent<HTMLInputElement>) => {
            setUrl(ev.target.value);
            setIsUrlValid(true);
          }}
          placeholder={formatMessage(messages.urlPlaceholder)}
          isInvalid={!isUrlValid}
          invalidText=""
          inputClassName={inputClassName}
          labelClassName={labelClassName}
          required
        />
        <TextField
          name="title"
          label={formatMessage(messages.titleLabel)}
          value={title}
          onChange={(ev: ChangeEvent<HTMLInputElement>) =>
            setTitle(ev.target.value)
          }
          placeholder={formatMessage(messages.titlePlaceholder)}
          isInvalid={false}
          invalidText=""
          inputClassName={inputClassName}
          labelClassName={labelClassName}
        />
        <Button
          variation="primary"
          isFullWidth
          onClick={onSubmit}
          isLoading={isSubmitting}
        >
          {Boolean(isSubmitting) && <LoadingSpinner />}
          {formatMessage(
            linkToEdit
              ? isSubmitting
                ? messages.editingLink
                : messages.editLink
              : isSubmitting
                ? messages.addingLink
                : messages.addLink
          )}
        </Button>
      </div>
    </Modal>
  );
}
