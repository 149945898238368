import { errorImage } from '@assembly-web/assets';
import { Button } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

const templatesErrorText = defineMessages({
  startFromScratch: {
    defaultMessage: '+ Start from scratch',
    id: 'KduLMo',
  },
  templatesListErrorText: {
    defaultMessage: 'Whoops! Something went wrong.',
    id: 'XvKOkW',
  },
  refreshInfo: {
    defaultMessage: 'Click the refresh button to try fetching templates again.',
    id: 'IjUGja',
  },
});

type TemplatesErrorViewProps = {
  onButtonClick: () => void;
};

export const TemplatesErrorView = (props: TemplatesErrorViewProps) => {
  const { onButtonClick } = props;
  const { formatMessage } = useIntl();
  return (
    <div className="m-auto mt-[8vh] w-[100%] !max-w-[360px] text-center">
      <img
        src={errorImage}
        alt="Error in loading data, please try again"
        className="m-auto"
      />
      <div className="pt-6 text-base font-medium">
        {formatMessage(templatesErrorText.templatesListErrorText)}
      </div>
      <div className="mb-4 pt-1 text-sm">
        {formatMessage(templatesErrorText.refreshInfo)}
      </div>
      <Button variation="secondaryEmphasized" onClick={onButtonClick}>
        {formatMessage(templatesErrorText.startFromScratch)}
      </Button>
    </div>
  );
};
