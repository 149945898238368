import { memo } from 'react';

import { DefaultBlockRow } from '../../DefaultBlockRow';
import { Description } from '../../Description';
import { SelectionRange } from '../../SelectionRangeSettings';
import { Options } from './Options';

export const MultiChoiceBlock = memo(function MultiChoiceBlock() {
  return (
    <DefaultBlockRow>
      <Description />
      <Options />
      <SelectionRange />
    </DefaultBlockRow>
  );
});
