import {
  type ContentBlockState,
  type FlowBuilderState,
  flowEditorValidators,
  isGivePointsBlock,
  isMultiChoiceOrDropdownBlock,
  isOpenEndedBlock,
  isPersonSelectorBlock,
  isScaleBlock,
  KnownErrorKeys,
} from '@assembly-web/services';
import { defineMessages, type IntlShape } from 'react-intl';

const messages = defineMessages({
  requiredTitle: {
    defaultMessage: 'Flow title is required',
    id: 'TFuAnK',
  },
  titleTooLong: {
    defaultMessage: 'Maximum limit is 30 characters',
    id: 'dcOeyS',
  },
  requiredQuestionText: {
    defaultMessage: 'Missing question text',
    id: 'CsvT5Q',
  },
  missingOptions: {
    defaultMessage:
      'Missing options on {type, select, multiChoice {multiple choice} other {dropdown}} question',
    id: 'HHyuCr',
  },
  missingOptionText: {
    defaultMessage:
      'Missing text in {type, select, multiChoice {multiple choice} other {dropdown}} options',
    id: 'X+SG5m',
  },
  missingScaleLabels: {
    defaultMessage: 'Missing labels on scale',
    id: '8HPrEH',
  },
  invalidBlockLimits: {
    defaultMessage:
      'Invalid minimum/maximum characters option on {type, select, openEnded {open ended question} multiChoice {multiple choice} other {select person}}',
    id: 'q9h9ou',
  },
  invalidPointsPercent: {
    defaultMessage: 'Maximum allowed percentage is 100%',
    id: 'di6JcM',
  },
});

export function validateTitle(
  errors: NonNullable<FlowBuilderState['errors']>,
  formatMessage: IntlShape['formatMessage'],
  title: string | undefined
) {
  const error = flowEditorValidators.title(title);

  if (!error) {
    delete errors['FLOW_NAME'];
  }

  if (error === flowEditorValidators.constants.REQUIRED) {
    errors[KnownErrorKeys.FLOW_NAME] = {
      message: formatMessage(messages.requiredTitle),
    };
  }
  if (error === flowEditorValidators.constants.TOO_LONG) {
    errors[KnownErrorKeys.FLOW_NAME] = {
      message: formatMessage(messages.titleTooLong),
    };
  }
}

export function validateQuestionText(
  errors: NonNullable<FlowBuilderState['errors']>,
  formatMessage: IntlShape['formatMessage'],
  state: ContentBlockState[]
) {
  state.forEach((block) => {
    const error = flowEditorValidators.questionText(block.title);

    if (!error && block.id in errors) {
      const { [block.id]: _, ...rest } = errors;
      errors = rest;
    }

    if (error === flowEditorValidators.constants.REQUIRED) {
      errors[block.id] = {
        message: formatMessage(messages.requiredQuestionText),
      };
    }
  });
}

export function validateOpenEndedBlockLimits(
  errors: NonNullable<FlowBuilderState['errors']>,
  formatMessage: IntlShape['formatMessage'],
  state: ContentBlockState[]
) {
  state.forEach((block) => {
    if (!isOpenEndedBlock(block)) {
      return;
    }

    const error = flowEditorValidators.openEndedBlockLimits({
      min: block.minimumCharacters,
      max: block.maximumCharacters,
    });

    if (!error && block.id in errors) {
      const { [block.id]: _, ...rest } = errors;
      errors = rest;
    } else if (block.id in errors) {
      return;
    }

    if (error === flowEditorValidators.constants.INVALID) {
      errors[block.id] = {
        message: formatMessage(messages.invalidBlockLimits, {
          type: 'openEnded',
        }),
        showBanner: true,
      };
    }
  });
}

export function validateSelectBlockOptions(
  errors: NonNullable<FlowBuilderState['errors']>,
  formatMessage: IntlShape['formatMessage'],
  state: ContentBlockState[]
) {
  state.forEach((block) => {
    if (!isMultiChoiceOrDropdownBlock(block)) {
      return;
    }

    const error = flowEditorValidators.selectBlockOptions(block.options);

    if (!error && block.id in errors) {
      const { [block.id]: _, ...rest } = errors;
      errors = rest;
    } else if (block.id in errors) {
      return;
    }

    if (error === flowEditorValidators.constants.REQUIRED) {
      errors[block.id] = {
        message: formatMessage(messages.missingOptions, {
          type: block.type === 'DROPDOWN' ? 'dropdown' : 'multiChoice',
        }),
        showBanner: true,
      };
    }
  });
}

export function validateSelectBlockOptionText(
  errors: NonNullable<FlowBuilderState['errors']>,
  formatMessage: IntlShape['formatMessage'],
  state: ContentBlockState[]
) {
  state.forEach((block) => {
    if (!isMultiChoiceOrDropdownBlock(block)) {
      return;
    }

    block.options.forEach((option, index) => {
      const error = flowEditorValidators.selectBlockOptionText(option);

      if (!error && block.id in errors) {
        const { [block.id]: _, ...rest } = errors;
        errors = rest;
      } else if (block.id in errors) {
        return;
      }

      if (error === flowEditorValidators.constants.REQUIRED) {
        errors[block.id] = {
          message: formatMessage(messages.missingOptionText, {
            type: block.type === 'DROPDOWN' ? 'dropdown' : 'multiChoice',
          }),
          showBanner: true,
          index,
        };
      }
    });
  });
}

export function validateScaleLabels(
  errors: NonNullable<FlowBuilderState['errors']>,
  formatMessage: IntlShape['formatMessage'],
  state: ContentBlockState[]
) {
  state.forEach((block) => {
    if (!isScaleBlock(block)) {
      return;
    }

    const error = flowEditorValidators.scaleLabels({
      high: block.highLabel,
      low: block.lowLabel,
      middle: block.middleLabel,
    });

    if (!error && block.id in errors) {
      const { [block.id]: _, ...rest } = errors;
      errors = rest;
    } else if (block.id in errors) {
      return;
    }

    if (error === flowEditorValidators.constants.REQUIRED) {
      errors[block.id] = {
        message: formatMessage(messages.missingScaleLabels),
        showBanner: true,
      };
    }
  });
}

export function validatePointsPercent(
  errors: NonNullable<FlowBuilderState['errors']>,
  formatMessage: IntlShape['formatMessage'],
  state: ContentBlockState[]
) {
  state.forEach((block) => {
    if (
      !isGivePointsBlock(block) ||
      block.limitAmountDetails?.type !== 'PERCENT'
    ) {
      return;
    }

    const error = flowEditorValidators.pointsPercent(
      block.limitAmountDetails.value
    );

    if (!error && block.id in errors) {
      const { [block.id]: _, ...rest } = errors;
      errors = rest;
    } else if (block.id in errors) {
      return;
    }

    if (error === flowEditorValidators.constants.INVALID) {
      errors[block.id] = {
        message: formatMessage(messages.invalidPointsPercent),
        showBanner: true,
      };
    }
  });
}

export function validateSelectionRange(
  errors: NonNullable<FlowBuilderState['errors']>,
  formatMessage: IntlShape['formatMessage'],
  state: ContentBlockState[]
) {
  state.forEach((block) => {
    if (!isPersonSelectorBlock(block) && !isMultiChoiceOrDropdownBlock(block)) {
      return;
    }

    if (block.optionType === 'SINGLE') {
      return;
    }

    const error = flowEditorValidators.selectionRange(block.optionSelectObject);

    if (!error && block.id in errors) {
      const { [block.id]: _, ...rest } = errors;
      errors = rest;
    } else if (block.id in errors) {
      return;
    }

    if (error === flowEditorValidators.constants.INVALID) {
      errors[block.id] = {
        message: formatMessage(messages.invalidBlockLimits, {
          type: isPersonSelectorBlock(block) ? 'personSelector' : 'multiChoice',
        }),
        showBanner: true,
      };
    }
  });
}
