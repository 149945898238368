import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { produce } from 'immer';

import { navItemsQueryKey, type NavItemsResponse } from '../nav/useNavData';

export function useViewSoftPinCollectionMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (itemId: string) => {
      return assemblyAPI.put(APIEndpoints.markSoftPinViewed(itemId));
    },
    onMutate: (itemId: string) => {
      const navCacheData =
        queryClient.getQueryData<NavItemsResponse>(navItemsQueryKey);
      const updatedNavData = produce(navCacheData, (draft) => {
        draft?.pages.forEach((page) => {
          page.data[page.data.findIndex((item) => item.id === itemId)].softPin =
            'viewed';
        });
      });
      queryClient.setQueryData(navItemsQueryKey, updatedNavData);
      return navCacheData;
    },
    onError: (err: AxiosError, itemId, previousNavData) => {
      queryClient.setQueryData(navItemsQueryKey, previousNavData);
      logger.error(
        'Error marking soft pin viewed',
        { itemId, error: err },
        err
      );
    },
  });
}
