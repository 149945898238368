import { TextStyle } from '@assembly-web/ui';
import { SparklesIcon } from '@heroicons/react/24/outline';

export const FlowSummaryHeader = (props: { title: string }) => {
  return (
    <>
      <div className="mr-2 rounded bg-gradient-to-r from-[#1d3fc8] via-purple-7 to-[#a309ca] p-1">
        <SparklesIcon className="h-4 w-4 text-gray-1" />
      </div>
      <TextStyle
        as="span"
        className="flex-1 truncate text-left text-gray-1"
        variant="xs-medium"
      >
        {props.title}
      </TextStyle>
    </>
  );
};
