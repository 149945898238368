import ContentLoader from 'react-content-loader';

export function ConnectionsSkeletonLoader() {
  return (
    <section className="px-12 py-9">
      <ContentLoader
        speed={2}
        width={212}
        height={24}
        viewBox="0 0 212 24"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="4" rx="4" ry="4" width="212" height="16" />
      </ContentLoader>
    </section>
  );
}
