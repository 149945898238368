import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { useIsBottomSheetInputActive } from '../../../../hooks/useIsBottomSheetInputActive';
import { AnswerMessage } from '../../../../shared/AnswerMessage';
import type {
  EditSettingsStoreType,
  EventSourceStoreType,
  FormSettingsStoreType,
  MemberAvatarProps,
} from '../../../../types';

export const TimePeriodOptionsAnswer = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    eventSourceStore: EventSourceStoreType;
    formSettingsStore: FormSettingsStoreType;
  }
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { useEventSourceStore },
    formSettingsStore,
    ...messageProps
  } = props;

  const {
    hideActiveInput,
    usePredefinedTimePeriodSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const origPredefinedTimePeriodValue = usePredefinedTimePeriodSetting(
    (store) => store.value
  );

  const isSeen = usePredefinedTimePeriodSetting.getState().isAnswerSeen;
  const isSet = usePredefinedTimePeriodSetting((store) => store.isSet);

  const markSeen = usePredefinedTimePeriodSetting(
    (store) => store.markAnswerSeen
  );
  const markSet = usePredefinedTimePeriodSetting((store) => store.markSet);

  const setShouldRequestInput = usePredefinedTimePeriodSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = usePredefinedTimePeriodSetting(
    (store) => store.shouldRequestInput
  );

  const isEditingPrevSetting = useEditSettingsStore(
    (store) =>
      store.isEditingRespondents() ||
      store.isEditingBlocks() ||
      store.isEditingFocus()
  );

  const isEditingCurSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'predefinedTimePeriod'
  );

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const setShouldRequestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const isSettingsConfirmed = useSettingsConfirmationSetting((store) =>
    Boolean(store.value)
  );

  const isEditingCustomTimePeriod = useEditSettingsStore(
    (store) => store.settingEditing === 'customTimePeriod'
  );

  const isCustomOptionSelected = usePredefinedTimePeriodSetting(
    (store) => store.isSet && !store.value?.endDate && !store.value?.startDate
  );

  const isEditingOtherSetting =
    isEditingCustomTimePeriod && isCustomOptionSelected;

  const editedPredefinedTimePeriodValue = useEditSettingsStore(
    (store) => store.predefinedTimePeriod.value
  );

  const value =
    editedPredefinedTimePeriodValue ?? origPredefinedTimePeriodValue;

  const resetEdited = useEditSettingsStore((store) => store.reset);

  const handleEdit = () => {
    trackDoraAction('summaryInputEdited', { doraSummaryInput: 'Time Period' });
    setSettingEditing('predefinedTimePeriod');
    hideActiveInput();
    resetEdited();

    setTimeout(() => {
      setShouldRequestEdit('predefinedTimePeriod', true);
    }, timeouts.showInput);
  };

  const isInputActive = useIsBottomSheetInputActive(formSettingsStore);
  const isEditing = useEditSettingsStore((store) =>
    Boolean(store.settingEditing)
  );
  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    value &&
    !isEditingPrevSetting && (
      <AnswerMessage
        isEditDisabled={
          !isSummaryError &&
          (isEditingCurSetting ||
            isEditingOtherSetting ||
            isSettingsConfirmed ||
            (!isInputActive && !isEditing))
        }
        isEditing={isEditingCurSetting}
        isFaded={isEditingOtherSetting}
        isSeen={isSeen}
        isSet={isSet}
        markSeen={markSeen}
        markSet={markSet}
        onEdit={handleEdit}
        setShouldRequestInput={setShouldRequestInput}
        shouldRequestInput={shouldRequestInput}
        {...messageProps}
      >
        {value.duration}
      </AnswerMessage>
    )
  );
};
