import {
  type AdditionalInfoData,
  type AnswerSourcesData,
  DoraAPIEndpoints,
  type DoraErrorServerSideEvent,
  logger,
  type Source,
  type UpdateData,
} from '@assembly-web/services';
import { config } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQueryClient } from '@tanstack/react-query';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { getSuggestedQuestionsQuery } from '../queries/getSuggestedQuestionsQuery';
import { trackDiscoverAction } from '../services/analytics';
import {
  convertMarkDownResponseToHTML,
  getIdForDoraQuestion,
  isDoraIncludedInWorkspacePlan,
  messages as sharedDoraMessages,
  removeBlinkingCursor,
  streamingTimeout,
} from '../services/dora';
import { useGenerateSuggestedQuestionsMutation } from './useGenerateSuggestedQuestionsMutation';
import { useGetPlanFeaturesQuery } from './useGetPlanFeaturesQuery';

export type DoraEventSourceData = {
  clearSuggestedQuestions: () => void;
  errorMessage?: string;
  getDoraResponse: (
    question: string,
    metadata?: {
      documentId?: string;
      isAdmin?: boolean;
      shouldUseStaticResponse?: boolean;
      suggestedQuestionActionType?:
        | 'followUpPromptClicked'
        | 'suggestedPromptClicked';
      suggestedQuestionsHistory?: string[];
    }
  ) => void;
  htmlResponse: string | null;
  isDoraIncludedInPlan: boolean;
  isError: boolean;
  isLoading: boolean;
  isStreaming: boolean;
  markdownResponse: string | null;
  promptId: string | null;
  reset: () => void;
  sources: Source[];
  staticResponse?: ReactElement;
  suggestedQuestions?: { documentId: string; question: string }[];
};

const messages = defineMessages({
  doraNotIncludedInPlan: {
    defaultMessage:
      'Want to get DORA powered answers? Upgrade your account to our Standard or Premium plans and unlock this feature to elevate your experience along with many others',
    id: '3F9v3o',
  },
  notUpgradedAdminStaticAnswer: {
    defaultMessage:
      "<p>Thanks for asking! I''m here to help you find answers faster. If your workspace is upgraded, you''ll be able to ask me specific questions about the <b>contents of files</b> uploaded to Assembly, or files you have permission to view in your connected tools like Google Drive or Dropbox. Here are some examples:</p><ul><li>Instead of reading the entire employee handbook to find out how to input time off, you can ask me, <quot>how do I log PTO?</quot></li><li>Or if you have a marketing project plan in a long document, you can ask, <quot>what is the expected delivery date for phase 3 of the ABC marketing project?</quot></li><li>Note: the more specific you can be, the better my answer will be! Over time, I''ll be able to answer more complex questions and give you even more helpful insights.</li></ul><p>I can also help you find files more easily – I''ll always give you the source documents for my answers. I''m always expanding my skills and improving. If you want access to ask me anything, upgrade below:</p>",
    id: 'ZfB3aD',
  },
  notUpgradedNonAdminStaticAnswer: {
    defaultMessage:
      "<p>Thanks for asking! I''m here to help you find answers faster. If your workspace is upgraded, you''ll be able to ask me specific questions about the <b>contents of files</b> uploaded to Assembly, or files you have permission to view in your connected tools like Google Drive or Dropbox. Here are some examples:</p><ul><li>Instead of reading the entire employee handbook to find out how to input time off, you can ask me, <quot>how do I log PTO?</quot></li><li>Or if you have a marketing project plan in a long document, you can ask, <quot>what is the expected delivery date for phase 3 of the ABC marketing project?</quot></li><li>Note: the more specific you can be, the better my answer will be! Over time, I''ll be able to answer more complex questions and give you even more helpful insights.</li></ul><p>I can also help you find files more easily – I''ll always give you the source documents for my answers. I''m always expanding my skills and improving. If you want access to ask me anything, ask your workspace administrator to upgrade your workspace!</p>",
    id: 'DZ2H2P',
  },
  upgradedStaticAnswer: {
    defaultMessage:
      "<p>Thanks for asking! I''m here to help you find answers faster. For now, you can ask me specific questions about the <b>contents of files</b> uploaded to Assembly, or files you have permission to view in your connected tools like Google Drive or Dropbox. If I don''t find an answer for you, it might be because I can''t access files with the answer! You can easily connect tools by clicking <b>connect another tool</b> below. Here are some examples:</p><ul><li>Instead of reading the entire employee handbook to find out how to input time off, you can ask me, <quot>how do I log PTO?</quot></li><li>Or if you have a marketing project plan in a long document, you can ask, <quot>what is the expected delivery date for phase 3 of the ABC marketing project?</quot></li><li>Note: the more specific you can be, the better my answer will be! Over time, I''ll be able to answer more complex questions and give you even more helpful insights.</li></ul><p>I can also help you find files more easily – I''ll always give you the source documents for my answers.</p><p>Please leave me a <quot>thumbs down</quot> whenever my answer isn''t helpful. I''m always expanding my skills and improving. Happy asking!</p>",
    id: 'VcJmQa',
  },
});

const displayValuesMapper = {
  b: (content: React.ReactNode[]) => (
    <TextStyle as="span" variant="base-bold">
      {content}
    </TextStyle>
  ),
  li: (content: React.ReactNode[]) => <li>{content}</li>,
  p: (content: React.ReactNode[]) => <TextStyle>{content}</TextStyle>,
  quot: (content: React.ReactNode[]) => `"${content}"`,
  ul: (content: React.ReactNode[]) => (
    <ul className="ml-8 list-disc">{content}</ul>
  ),
};

const markdownValuesMapper = {
  b: (content: string[]) => `**${content}**`,
  li: (content: string[]) => `- ${content}\n`,
  p: (content: string[]) => `${content}\n`,
  quot: (content: string[]) => `"${content}"`,
  ul: (content: string[]) => `\n${content}\n`,
};

export function useDoraEventSource(): DoraEventSourceData {
  const { formatMessage, locale } = useIntl();
  const queryClient = useQueryClient();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [sources, setSources] = useState<Source[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [promptId, setPromptId] = useState<string | null>(null);
  const [staticResponse, setStaticResponse] = useState<ReactElement>();
  const [data, setData] = useState<string | null>(null);

  const [suggestedQuestions, setSuggestedQuestions] =
    useState<{ documentId: string; question: string }[]>();

  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  useEffect(() => {
    return () => {
      abortController?.abort();
    };
  }, [abortController]);

  const reset = useCallback(() => {
    setData(null);
    setStaticResponse(undefined);
    abortController?.abort();
    setIsError(false);
    setPromptId(null);
    setIsLoading(false);
    setIsStreaming(false);
    setSources([]);
    setSuggestedQuestions(undefined);
  }, [abortController]);

  const { mutateAsync: generateSuggestedQuestions } =
    useGenerateSuggestedQuestionsMutation();

  const showSuggestionsForResponseWithNoSources = useCallback(
    async (question: string) => {
      const { data: rawQuestions } = await queryClient.fetchQuery(
        getSuggestedQuestionsQuery(question, 4)
      );

      const questionId = getIdForDoraQuestion(question, { locale });

      const questions = rawQuestions.reduce<
        { documentId: string; question: string }[]
      >((uniqueQuestions, rawData) => {
        const curQuestionId = getIdForDoraQuestion(rawData.question, {
          locale,
        });

        if (curQuestionId !== questionId && uniqueQuestions.length < 3) {
          uniqueQuestions.push({
            documentId: rawData.id,
            question: rawData.question,
          });
        }

        return uniqueQuestions;
      }, []);

      setSuggestedQuestions(questions);
    },
    [locale, queryClient]
  );

  const getEventResponse = useCallback(
    (
      question: string,
      metadata?: {
        documentId?: string;
        suggestedQuestionActionType?:
          | 'followUpPromptClicked'
          | 'suggestedPromptClicked';
        suggestedQuestionsHistory?: string[];
      }
    ) => {
      let streamedData = '';
      let isAnswerSourceEventReceived = false;

      setData('');
      setIsError(false);
      setIsLoading(true);
      abortController?.abort();

      let url = `${config.domains.doraApi}/v1/qna/ask?text=${encodeURIComponent(
        question
      )}`;
      if (metadata?.documentId) {
        url += `&document_id=${metadata.documentId}`;
      }

      const controller = new AbortController();
      const signal = controller.signal;

      setAbortController(controller);

      const abortTimer = setTimeout(() => {
        logger.warn('Timing out Dora request for GET /v1/qna/ask', {
          question,
          timeout: streamingTimeout,
        });

        controller.abort();
        setIsError(true);
        setIsLoading(false);
        setIsStreaming(false);
      }, streamingTimeout);

      fetchEventSource(url, {
        credentials: 'include',

        async onopen(resp) {
          clearTimeout(abortTimer);

          if (!resp.ok) {
            let responseBody;

            if (resp.headers.get('content-type') === 'application/json') {
              responseBody = await resp.json();
            } else {
              responseBody = await resp.text();
            }

            logger.error(
              `Dora request for GET ${DoraAPIEndpoints.askDora} failed`,
              { question, responseBody, statusCode: resp.status, url: resp.url }
            );

            throw new Error('Dora Q/A');
          }
        },

        onerror(err) {
          setIsError(true);
          setIsLoading(false);
          setIsStreaming(false);
          throw err;
        },

        async onmessage(msg) {
          if (msg.event === 'error') {
            setIsError(true);
            setIsLoading(false);
            setIsStreaming(false);

            const response = JSON.parse(msg.data) as DoraErrorServerSideEvent;

            logger.error(
              `Error event received from GET ${DoraAPIEndpoints.askDora}`,
              { data: response, message: msg, question }
            );

            if (response.error === 'RATE_LIMIT_ERROR') {
              setErrorMessage(formatMessage(sharedDoraMessages.rateLimitError));
            }
          } else if (msg.event === 'answer_sources') {
            const response = JSON.parse(msg.data) as AnswerSourcesData;
            const answerSources = response.data.answer_sources;
            if (answerSources.length) {
              setSources(answerSources);
              isAnswerSourceEventReceived = true;

              const questions = await generateSuggestedQuestions({
                docId: answerSources[0].id,
                blacklistQuestions: metadata?.suggestedQuestionsHistory,
              });
              setSuggestedQuestions(
                questions.map((question) => ({
                  documentId: answerSources[0].id,
                  question,
                }))
              );
            } else {
              showSuggestionsForResponseWithNoSources(question);
            }
          } else if (msg.event === 'additional_info') {
            const response = JSON.parse(msg.data) as AdditionalInfoData;
            setPromptId(response.data.prompt_id);
          } else if (msg.event === 'update') {
            const response = JSON.parse(msg.data) as UpdateData;
            streamedData = streamedData + response.data;

            setData((value) => (value ? streamedData : response.data));
            setIsLoading(false);
            setIsStreaming(true);
          } else if (msg.event === 'end') {
            abortController?.abort();
            setIsStreaming(false);

            trackDiscoverAction('answerGenerated', {
              answerText: streamedData,
            });

            if (metadata?.suggestedQuestionActionType) {
              trackDiscoverAction(metadata.suggestedQuestionActionType, {
                answerText: streamedData,
                searchQuery: question,
              });
            }

            if (!isAnswerSourceEventReceived) {
              showSuggestionsForResponseWithNoSources(question);
            }
          }
        },

        openWhenHidden: true,
        signal,
      });
    },
    [
      abortController,
      formatMessage,
      generateSuggestedQuestions,
      showSuggestionsForResponseWithNoSources,
    ]
  );

  const { data: planFeatureDetails } = useGetPlanFeaturesQuery();

  const isDoraIncludedInPlan =
    isDoraIncludedInWorkspacePlan(planFeatureDetails);

  const getDoraResponse = useCallback(
    (
      query: string,
      metadata?: {
        documentId?: string;
        isAdmin?: boolean;
        shouldUseStaticResponse?: boolean;
        suggestedQuestionActionType?:
          | 'followUpPromptClicked'
          | 'suggestedPromptClicked';
        suggestedQuestionsHistory?: string[];
      }
    ) => {
      const { isAdmin = false, shouldUseStaticResponse = false } =
        metadata ?? {};

      if (shouldUseStaticResponse) {
        setIsLoading(true);
        setTimeout(() => {
          const staticAnswer = (
            <>
              {formatMessage(
                isDoraIncludedInPlan
                  ? messages.upgradedStaticAnswer
                  : isAdmin
                    ? messages.notUpgradedAdminStaticAnswer
                    : messages.notUpgradedNonAdminStaticAnswer,
                displayValuesMapper
              )}
            </>
          );

          const answerText = formatMessage(
            isDoraIncludedInPlan
              ? messages.upgradedStaticAnswer
              : isAdmin
                ? messages.notUpgradedAdminStaticAnswer
                : messages.notUpgradedNonAdminStaticAnswer,
            markdownValuesMapper
          );

          setStaticResponse(staticAnswer);
          setIsLoading(false);

          trackDiscoverAction('whatCanDoraDoClicked', {
            answerText,
            searchQuery: query,
          });
        }, 2000);
      } else if (isDoraIncludedInPlan) {
        getEventResponse(query, metadata);
      } else {
        setData(formatMessage(messages.doraNotIncludedInPlan));
      }
    },
    [formatMessage, getEventResponse, isDoraIncludedInPlan]
  );

  const htmlResponse = useMemo(() => {
    if (!data) {
      return null;
    }

    const html = convertMarkDownResponseToHTML(data) as string;

    if (!isStreaming) {
      return removeBlinkingCursor(html);
    }
    return html;
  }, [data, isStreaming]);

  return {
    clearSuggestedQuestions: () => setSuggestedQuestions(undefined),
    errorMessage,
    sources,
    isError,
    promptId,
    htmlResponse,
    isDoraIncludedInPlan,
    isLoading,
    isStreaming,
    getDoraResponse,
    markdownResponse: data,
    suggestedQuestions,
    reset,
    staticResponse,
  };
}
