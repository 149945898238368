import {
  APIEndpoints,
  type ChallengeDetailsAPIResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function getChallengeDetailsQuery(
  id: string,
  enabled: boolean
): UseQueryOptions<ChallengeDetailsAPIResponse> {
  return {
    queryKey: ['challengeDetails', id],
    queryFn: async () => {
      const response = await assemblyAPI.get<ChallengeDetailsAPIResponse>(
        APIEndpoints.getChallengeDetails(id)
      );
      return response.data;
    },
    enabled,
  };
}
