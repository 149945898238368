import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import {
  generatePostDrawerPayload,
  getIsFlowOrRecognitionPost,
  getShouldOpenPostDrawer,
  getUpdatedUrlForFlowOrRecognitionPost,
  removeFlowOrPostParamsFromUrl,
} from '../modules/discover/services/common';
import { useMultiDrawerStore } from '../stores/useMultiDrawerStore';

export const POST_DRAWER_MESSAGE = {
  OPEN_POST_DRAWER: 'OPEN_POST_DRAWER',
} as const;

const OpenPostDrawerPayload = z.object({
  flowId: z.string().nullable().optional(),
  postId: z.string(),
  responseId: z.string().nullable().optional(),
});

export function useOpenPostDrawer() {
  const navigate = useNavigate();
  const isFlowOrRecognitionPost = getIsFlowOrRecognitionPost(location.pathname);

  const searchParams = new URLSearchParams(location.search);

  const flowId = searchParams.get('flowId');
  const postId = searchParams.get('postId');
  const responseId = searchParams.get('responseId');
  const commentId = searchParams.get('commentId');
  const shouldOpenPostDrawer = getShouldOpenPostDrawer();

  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);

  useEffect(() => {
    if (shouldOpenPostDrawer || isFlowOrRecognitionPost) {
      navigate(getUpdatedUrlForFlowOrRecognitionPost(), { replace: true });

      const createDrawerPayload = generatePostDrawerPayload({
        flowId,
        postId,
        responseId,
        commentId,
        onClose: async () => {
          if (location.pathname.includes('/discover')) {
            const updatedUrl = removeFlowOrPostParamsFromUrl();
            navigate(updatedUrl, { replace: true });
          }
        },
      });

      createDrawer(createDrawerPayload);
    }
  }, [
    commentId,
    createDrawer,
    flowId,
    isFlowOrRecognitionPost,
    navigate,
    postId,
    responseId,
    shouldOpenPostDrawer,
  ]);

  useEffect(() => {
    function handleMessage({
      data,
    }: MessageEvent<{
      type: keyof typeof POST_DRAWER_MESSAGE;
      payload: unknown;
    }>) {
      switch (data.type) {
        case POST_DRAWER_MESSAGE.OPEN_POST_DRAWER:
          {
            const payload = OpenPostDrawerPayload.safeParse(data.payload);
            if (!payload.success) return;
            const createDrawerPayload = generatePostDrawerPayload({
              flowId: payload.data.flowId ?? null,
              postId: payload.data.postId,
              responseId: payload.data.responseId ?? null,
              commentId: 'viewLastReply',
            });
            createDrawer(createDrawerPayload);
          }
          break;
        default:
          return;
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [createDrawer]);
}
