import { Banner, classNames, Heading, TextStyle } from '@assembly-web/ui';
import type { ReactNode } from 'react';

import { onBoardingState } from '../stores/onboardingStore';

type OnboardingContainerProps = {
  showLogo?: boolean;
  title: ReactNode;
  description?: ReactNode;
  errorMessage?: ReactNode;
  children?: ReactNode;
  footer?: ReactNode;
  className?: string;
};

export function OnboardingContainer(props: OnboardingContainerProps) {
  const { getState } = onBoardingState;
  const { logo } = getState();

  return (
    <section
      className={classNames(
        'container mx-auto w-[300px] max-w-lg space-y-6 px-4 pb-6 sm:w-[434px]',
        props.className
      )}
    >
      <section>
        {Boolean(props.showLogo) && (
          <img className="absolute -ml-20 block h-16 w-16" src={logo} alt="" />
        )}
        <Heading as="h4">{props.title}</Heading>

        {Boolean(props.description) && (
          <TextStyle subdued className="pt-4">
            {props.description}
          </TextStyle>
        )}

        {Boolean(props.errorMessage) && (
          <Banner status="error">{props.errorMessage}</Banner>
        )}
      </section>

      {props.children}

      {Boolean(props.footer) && (
        <TextStyle subdued className="text-center">
          {props.footer}
        </TextStyle>
      )}
    </section>
  );
}
