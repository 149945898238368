import { FlowSummaryDrawerCard } from '@assembly-web/ui';

import { getStoresForFlowSummaryDrawer } from '../../../stores/flowSummaryStore';
import type { Drawer } from '../types';

export const FlowSummaryOverflowCard = (
  props: Drawer & {
    onClick: () => void;
    onClose: () => void;
  }
) => {
  const { onClick, onClose, title, lastInteractedTS } = props;

  const {
    eventSourceStore: { useEventSourceStore },
  } = getStoresForFlowSummaryDrawer(props);

  const isSummaryFetched = useEventSourceStore(
    (store) => store.isSummaryFetched
  );

  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    <FlowSummaryDrawerCard
      status={!isSummaryError && isSummaryFetched ? 'complete' : 'inprogress'}
      title={title}
      onClose={onClose}
      onClick={onClick}
      lastMessageTS={lastInteractedTS}
    />
  );
};
