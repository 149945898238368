import {
  APIEndpoints,
  getAPIErrorCode,
  unauthenticatedAssemblyAPI,
  userAuthStore,
} from '@assembly-web/services';
import {
  ErrorIcon,
  InlineError,
  LoadingSpinner,
  RouterForm,
  TextField,
  TextStyle,
  validateForm,
  validateFormSafely,
} from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useActionData,
  useNavigation,
  useParams,
  useSearchParams,
  useSubmit,
} from 'react-router-dom';
import { z } from 'zod';

import { TextButton } from '../../../../components/TextButton';
import {
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../../services/analytics';
import type { ReactRouterActionResponse } from '../../../../types/libs';
import { OnboardingContainer } from '../../components/OnboardingContainer';
import { OpenMailProvider } from '../../components/OpenMailProvider';

const messages = defineMessages({
  title: { defaultMessage: 'Check your email', id: 'zx7CKB' },
  description: {
    defaultMessage:
      'You will receive a temporary 10 digit confirmation code at <b>{email}</b>, enter it here.',
    id: '+L4umK',
  },
  shortTimeoutError: {
    defaultMessage: 'You can request a new code in 30 seconds',
    id: '8eFffF',
  },
  verificationCodeError: {
    defaultMessage:
      'Whoops, this code doesn’t match what we sent, check your email and try again.',
    id: 'QIvLEw',
  },
  otpFieldPlaceholder: {
    defaultMessage: 'Enter your code here',
    id: 'ZVMCn+',
  },
  codeNotFound: {
    defaultMessage:
      "Code does not match or has expired. Click 'Resend link' to receive a new code",
    id: 'FSac6Q',
  },
  maxAttemptsError: {
    defaultMessage:
      'Too many attempts with an incorrect code. Please try again in 30 minutes.',
    id: '3vlcqH',
  },
  submitCTA: {
    defaultMessage: 'Submit',
    id: 'wSZR47',
  },
  helpText: {
    defaultMessage:
      "Keep this window open while checking your email. If you can't find your code, check your spam or junk folder or <cta>resend the code in 30 seconds</cta>.",
    id: 'dKVUxN',
  },
});

function ResendVerificationLinkSection({
  onResendCode,
}: {
  onResendCode: () => void;
}) {
  const { formatMessage } = useIntl();

  const resendInterval = 30;
  const [
    secondsLeftBeforeResendingVerificationCode,
    setSecondsLeftBeforeResendingVerificationCode,
  ] = useState(resendInterval);

  useEffect(
    function countdownResendCodeTimer() {
      const interval = setInterval(() => {
        if (secondsLeftBeforeResendingVerificationCode <= 0) {
          return;
        }
        setSecondsLeftBeforeResendingVerificationCode((time) => time - 1);
      }, 1000);
      return () => clearInterval(interval);
    },
    [secondsLeftBeforeResendingVerificationCode]
  );

  const disableResendButton = secondsLeftBeforeResendingVerificationCode !== 0;

  return (
    <TextStyle
      className="text-center text-gray-8"
      data-test-id="reset-verification-code"
    >
      {formatMessage(messages.helpText, {
        cta: (text: ReactNode) => (
          <TextButton
            disabled={disableResendButton}
            onClick={() => {
              setSecondsLeftBeforeResendingVerificationCode(resendInterval);
              onResendCode();
            }}
            underline
          >
            {text}
          </TextButton>
        ),
      })}
    </TextStyle>
  );
}

export function VerifyEmailFlowRoute() {
  const { formatMessage } = useIntl();
  const { flow } = useParams();

  const submit = useSubmit();
  const navigation = useNavigation();

  const isPageLoading = navigation.state === 'loading';
  const isSubmissionInProgress = navigation.state === 'submitting';

  const [searchParams] = useSearchParams();

  const actionData = useActionData() as ReactRouterActionResponse<
    typeof verifyEmailFlowAction
  >;

  const email = searchParams.get('email') ?? actionData?.email ?? '';

  const isVerificationCodeIssue =
    actionData?.error &&
    [
      'code_not_found',
      'invalid_code',
      'invalid_parameter',
      'missing_required_parameters',
      'code_generation_blocked',
    ].includes(actionData.error);

  const maxAttemptsReached = actionData?.error === 'too_many_requests_per_user';

  const shouldDisableButton =
    isSubmissionInProgress || isPageLoading || maxAttemptsReached;

  const otpErrorMessage =
    actionData?.error &&
    formatMessage(
      actionData.error === 'code_not_found'
        ? messages.codeNotFound
        : actionData.error === 'code_generation_blocked'
          ? messages.shortTimeoutError
          : messages.verificationCodeError
    );

  const [isVerificationCodeError, setIsVerificationCodeError] = useState(false);

  useEffect(() => {
    setIsVerificationCodeError(Boolean(isVerificationCodeIssue));
  }, [
    isVerificationCodeIssue,
    /**
     * NOTE: we need the action data as a dependency here so that we can
     * update the state when there are errors multiple times in a row
     **/
    actionData,
  ]);

  return (
    <OnboardingContainer
      title={formatMessage(messages.title)}
      description={formatMessage(messages.description, {
        email,
        b: (text: ReactNode) => <span className="font-bold">{text}</span>,
      })}
    >
      <RouterForm
        method="post"
        onChange={({ target, currentTarget }) => {
          if (target instanceof HTMLInputElement) {
            const code = target.value.toString();
            setIsVerificationCodeError(false);

            if (!shouldDisableButton && code.length >= 10) {
              submit(currentTarget);
            }
          }
        }}
      >
        <TextField
          min={0}
          name="code"
          type="number"
          inputMode="numeric"
          autoComplete="off"
          label={formatMessage(messages.otpFieldPlaceholder)}
          hideLabel
          placeholder={formatMessage(messages.otpFieldPlaceholder)}
          isInvalid={isVerificationCodeError}
          invalidText={otpErrorMessage ?? ''}
          required
          connectedRight={
            isSubmissionInProgress ? (
              <div className="pointer-events-none pr-3">
                <LoadingSpinner />
              </div>
            ) : isVerificationCodeError ? (
              <div className="pointer-events-none pr-3">
                <ErrorIcon />
              </div>
            ) : null
          }
        />

        <input readOnly name="email" type="email" value={email} hidden />
        <input readOnly name="type" value={flow} hidden />
        {Boolean(maxAttemptsReached) && (
          <InlineError id="codeError">
            {formatMessage(messages.maxAttemptsError)}
          </InlineError>
        )}
      </RouterForm>
      {!maxAttemptsReached && (
        <ResendVerificationLinkSection
          onResendCode={() => {
            const formData = new FormData();
            formData.set('email', email);
            formData.set('type', flow ?? '');
            submit(formData, { method: 'put' });
          }}
        />
      )}
      <OpenMailProvider email={email} />
    </OnboardingContainer>
  );
}

export async function verifyEmailFlowAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  if (request.method.toLowerCase() === 'post') {
    return await handleEmailCodeVerification(formData, request.signal);
  } else {
    return await regenerateCode(formData, request.signal);
  }
}

async function handleEmailCodeVerification(
  formData: FormData,
  signal: AbortSignal
) {
  const invalidVerificationCode = 'invalid_code';

  const formSchema = z.object({
    email: z.string().email(),
    code: z
      .string()
      .trim()
      .length(10)
      .regex(/^[0-9]{1,10}$/),
    type: z.enum(['login', 'signup']),
  });

  let response = {
    // Send email back since react router will load the url w/o query params
    // & we'll need this value to render the component with an email
    email: formData.get('email')?.toString(),
  };

  try {
    const validation = validateFormSafely(formSchema, formData);

    if (validation.success) {
      const {
        data: { email, code, type },
      } = validation;

      trackRegistrationAction('codeSubmitted', {
        email,
      });

      await unauthenticatedAssemblyAPI.post(
        APIEndpoints.verifyEmailVerificationCode,
        { email, code, type },
        { signal }
      );

      userAuthStore
        .getState()
        .updateUserAuthFlow(type === 'signup' ? 'create-account' : 'login');

      trackRegistrationAction('codeSubmitted', {
        email,
        codeSubmittedSuccess: true,
      });

      return redirect('/workspaces');
    } else {
      return { ...response, error: invalidVerificationCode };
    }
  } catch (error) {
    const errorCode = getAPIErrorCode(error);

    trackRegistrationError('codeSubmitted', {
      email: response.email,
      codeSubmittedSuccess: false,
    });

    return { ...response, error: errorCode };
  }
}

async function regenerateCode(formData: FormData, signal: AbortSignal) {
  const formSchema = z.object({
    email: z.string().email(),
    type: z.enum(['login', 'signup']),
  });

  try {
    const { email, type } = validateForm(formSchema, formData);

    await unauthenticatedAssemblyAPI.post(
      APIEndpoints.generateEmailVerificationCode,
      { email, type },
      { signal }
    );
    return redirect(`/verify-email/${type}?email=${encodeURIComponent(email)}`);
  } catch (error) {
    const errorCode = getAPIErrorCode(error);

    return { error: errorCode, email: formData.get('email')?.toString() };
  }
}
