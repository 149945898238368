import { useGetFlowFeed } from '../../FlowSummaryDrawer/hooks/useGetFlowFeed';
import type { FlowCreationDrawer } from '../../types';

export const useFlowData = (
  id: string,
  type: FlowCreationDrawer['data']['type']
) => {
  return useGetFlowFeed(
    id,
    {
      filter: {},
      limit: 1,
      sortBy: 'desc',
    },
    type === 'edit'
  );
};
