import { SVGReactComponents } from '@assembly-web/assets';
import { defineMessages, useIntl } from 'react-intl';

import { DoraLoadingLabel } from '../../../dora/DoraLoadingLabel';

const messages = defineMessages({
  loading: {
    defaultMessage: 'Loading your conversation',
    id: 'QKo3kr',
  },
});

export function ChatHistoryLoading() {
  const { formatMessage } = useIntl();

  return (
    <div className="flex h-full flex-col items-center justify-center gap-5 bg-gray-1">
      <SVGReactComponents.SparklesAnimatedIcon className="h-12 w-12" />
      <DoraLoadingLabel label={formatMessage(messages.loading)} />
    </div>
  );
}
