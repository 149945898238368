import type { EntityPermissionApiResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export type EntityPermissionPayload = {
  entityIds: string[];
};

export function getEntityPermissionQuery(
  payload: EntityPermissionPayload,
  options?: UseQueryOptions<EntityPermissionApiResponse>
): UseQueryOptions<EntityPermissionApiResponse> {
  return {
    ...options,
    queryKey: ['entityPermission', payload.entityIds.sort().join(',')],
    queryFn: async () => {
      const response = await assemblyAPI.post<EntityPermissionApiResponse>(
        APIEndpoints.getEntityPermissions,
        payload
      );

      return response.data;
    },
  };
}
