import { useQueryClient } from '@tanstack/react-query';

export function useInvalidateFeed() {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const userFeedQueryKeys = queryCache
    .findAll(['userFeed'])
    .map((query) => query.queryKey);
  const searchFeedQueryKeys = queryCache
    .findAll(['searchResults'])
    .map((query) => query.queryKey);

  const keysToInvalidate = [...userFeedQueryKeys, ...searchFeedQueryKeys];

  const invalidateFeed = () => {
    keysToInvalidate.forEach(async (queryKey) => {
      queryClient.invalidateQueries({
        queryKey,
        exact: true,
      });
    });
  };

  return { invalidateFeed };
}
