import { useEffect } from 'react';

import {
  useGetCanValidate,
  useGetStateForValidation,
} from '../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useValidateBlocks } from '../hooks/useIsBlocksValid';

export function Validate() {
  const { id } = useEditorDataContext();

  const validate = useValidateBlocks();

  const canValidate = useGetCanValidate(id);
  const state = useGetStateForValidation(id);

  useEffect(() => {
    if (canValidate) {
      validate();
    }
  }, [canValidate, validate, state]);

  return null;
}
