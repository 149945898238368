import { useSearchParams } from 'react-router-dom';

import {
  type ChallengeStatusType,
  UserFeedKeys,
} from '../stores/queryKeyStore';

export const useChallengeFeedQueryKey = () => {
  const [query] = useSearchParams();
  const statusFilterValue = query.get('challengeStatusType');
  const statusFilterArray = statusFilterValue
    ? statusFilterValue.split(',')
    : [];

  return {
    queryKey: UserFeedKeys.challenges({
      challengeStatus: statusFilterArray as ChallengeStatusType[],
    }),
    allChallengesQueryKey: UserFeedKeys.allChallenges(),
    statusFilter: statusFilterArray as ChallengeStatusType[],
  };
};
