import { CriteriaRuleType, PermissionType } from '@assembly-web/services';
import type { CriteriaItemPermission } from '@assembly-web/ui';
import type { MessageDescriptor } from 'react-intl';

import { messages } from './messages';

export function getPermissionOptionsWithoutExclusion(
  formatMessage: (descriptor: MessageDescriptor) => string
): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: [
          {
            id: PermissionType.Participant,
            label: formatMessage(messages.participantLabel),
            displayLabel: formatMessage(messages.participantLabel),
          },
        ],
      },
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removeRuleLabel),
            displayLabel: formatMessage(messages.removeRuleLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}

export function getManagerOfParticipantPermission(
  formatMessage: (descriptor: MessageDescriptor) => string
): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: [
          {
            id: PermissionType.Approver,
            label: formatMessage(messages.approverLabel),
            displayLabel: formatMessage(messages.approverLabel),
            subText: formatMessage(messages.managerSubtitle),
          },
        ],
      },
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removeRuleLabel),
            displayLabel: formatMessage(messages.removeRuleLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}

function getPositiveOptionsWithExclusion(
  formatMessage: (descriptor: MessageDescriptor) => string,
  isOwner?: boolean
) {
  if (isOwner) {
    return [
      {
        id: PermissionType.Approver,
        label: formatMessage(messages.approverLabel),
        displayLabel: formatMessage(messages.approverLabel),
      },
      {
        id: PermissionType.ParticipantApprover,
        label: formatMessage(messages.participantApproverLabel),
        displayLabel: formatMessage(messages.participantApproverLabel),
      },
    ];
  } else {
    return [
      {
        id: PermissionType.Participant,
        label: formatMessage(messages.participantLabel),
        displayLabel: formatMessage(messages.participantLabel),
      },
      {
        id: PermissionType.Approver,
        label: formatMessage(messages.approverLabel),
        displayLabel: formatMessage(messages.approverLabel),
      },
      {
        id: PermissionType.ParticipantApprover,
        label: formatMessage(messages.participantApproverLabel),
        displayLabel: formatMessage(messages.participantApproverLabel),
      },
      {
        id: PermissionType.Excluded,
        label: formatMessage(messages.exclusionTitle),
        displayLabel: formatMessage(messages.exclusionLabel),
        subText: formatMessage(messages.exclusionSubtitle),
      },
    ];
  }
}

export function getNonMemberPermissionOptions(
  formatMessage: (descriptor: MessageDescriptor) => string
): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: getPositiveOptionsWithExclusion(formatMessage),
      },
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removeRuleLabel),
            displayLabel: formatMessage(messages.removeRuleLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}
export function getPendingMemberPermissionOptions(
  formatMessage: (descriptor: MessageDescriptor) => string,
  isAdmin?: boolean,
  isOwner?: boolean
): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: getPositiveOptionsWithExclusion(formatMessage, isOwner),
      },
      ...(isAdmin
        ? [
            {
              id: 'operation',
              options: [
                {
                  id: PermissionType.TransferOwnership,
                  label: formatMessage(messages.transferOwnership),
                  displayLabel: formatMessage(messages.transferOwnership),
                },
              ],
            },
          ]
        : []),
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removeRuleLabel),
            displayLabel: formatMessage(messages.removePersonLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}
export function getMemberPermissionOptions({
  formatMessage,
  isOwner,
  isAdmin,
}: {
  formatMessage: (descriptor: MessageDescriptor) => string;
  isOwner?: boolean;
  isAdmin?: boolean;
}): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: getPositiveOptionsWithExclusion(formatMessage, isOwner),
      },
      ...(!isOwner && isAdmin
        ? [
            {
              id: 'operation',
              options: [
                {
                  id: PermissionType.TransferOwnership,
                  label: formatMessage(messages.transferOwnership),
                  displayLabel: formatMessage(messages.transferOwnership),
                },
              ],
            },
          ]
        : []),
      ...(!isOwner
        ? [
            {
              id: 'negative',
              options: [
                {
                  id: PermissionType.RemoveRule,
                  label: formatMessage(messages.removeRuleLabel),
                  displayLabel: formatMessage(messages.removePersonLabel),
                  isDelete: true,
                },
              ],
            },
          ]
        : []),
    ],
  };
}

export function getPermissionOptions({
  type,
  isOwner,
  formatMessage,
  isDeactivatedOwner,
  isManagerOfParticipant,
  isAdmin,
}: {
  type: CriteriaRuleType;
  isOwner?: boolean;
  formatMessage: (descriptor: MessageDescriptor) => string;
  isDeactivatedOwner?: boolean;
  isManagerOfParticipant?: boolean;
  isAdmin?: boolean;
}): CriteriaItemPermission {
  if (isOwner && isDeactivatedOwner) {
    return {
      isLocked: true,
      tooltipText: formatMessage(messages.deactivatedOwnerToolTip),
    };
  }
  if (type === CriteriaRuleType.ManagerStatus && isManagerOfParticipant) {
    return getManagerOfParticipantPermission(formatMessage);
  }
  if (type === CriteriaRuleType.Member) {
    return getMemberPermissionOptions({
      formatMessage,
      isOwner,
      isAdmin,
    });
  }
  if (type === CriteriaRuleType.Email) {
    return getPendingMemberPermissionOptions(formatMessage, isAdmin, isOwner);
  }
  if (type === CriteriaRuleType.Everyone) {
    return getPermissionOptionsWithoutExclusion(formatMessage);
  }

  return getNonMemberPermissionOptions(formatMessage);
}
