import { browserUtils } from '@assembly-web/services';
import { useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';

export function useNavTrigger() {
  const { width } = useResizeObserver({ ref: document.body });

  const { hotkey } = useMemo(() => {
    if (browserUtils.isMac) {
      return { hotkey: 'meta+shift+p' };
    }
    return { hotkey: 'ctrl+shift+p' };
  }, []);

  return {
    isBottomNav: width ? width <= 800 : false,
    hotkey,
  };
}
