import { deleteDoraChatDrawer } from '../../../stores/doraChatStore';
import type { DrawerConfig } from '../configuration';
import type { DoraChatDrawer as DoraChatDrawerProps } from '../types';
import { DoraChatDrawer } from '.';
import { DoraChatDrawerBody } from './DoraChatDrawerBody';
import { DoraChatDrawerHeader } from './DoraChatDrawerHeader';
import { DoraChatDrawerOverflowCard } from './DoraChatDrawerOverflowCard';

export const doraChatConfig = {
  drawer: DoraChatDrawer,
  body: DoraChatDrawerBody,
  header: DoraChatDrawerHeader,
  overflowCard: DoraChatDrawerOverflowCard,
  allowMultipleInstance: true,
  shouldConfirmOnClose: () => false,
  onClose: deleteDoraChatDrawer,
} satisfies DrawerConfig<DoraChatDrawerProps, object>;
