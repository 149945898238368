import { PopoutDrawer } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { trackPopoutAction } from '../../../services/analytics';
import { OverflowMenuContent } from './Content';
import { OverflowMenuHeader } from './Header';
import { MoreToolbarMenu } from './MoreToolbarMenu';

const messages = defineMessages({
  label: {
    defaultMessage: 'Overflow Menu',
    id: '712Dad',
  },
});

export function OverflowMenu() {
  const { formatMessage } = useIntl();

  const isOverflowOpen = useMultiDrawerStore((store) => store.isOverflowOpen);
  const openOverflowDrawer = useMultiDrawerStore(
    (store) => store.openOverflowDrawer
  );
  const toggleIsOverflowOpen = useMultiDrawerStore(
    (store) => store.toggleIsOverflowOpen
  );
  const drawers = useMultiDrawerStore((store) => store.getDrawers());
  const overflowDrawers = useMultiDrawerStore((store) =>
    store.getOverflowDrawers()
  );

  const onOpenChange = () => {
    if (isOverflowOpen) {
      trackPopoutAction('collapseOverflowClicked');
    } else {
      trackPopoutAction('expandOverflowClicked', {
        numPopouts: drawers.length + overflowDrawers.length,
      });
    }
    toggleIsOverflowOpen();
  };

  return (
    <PopoutDrawer
      header={<OverflowMenuHeader />}
      label={formatMessage(messages.label)}
      isOpen={isOverflowOpen}
      onOpenChange={onOpenChange}
      toolbar={<MoreToolbarMenu showCollapseOption />}
    >
      <OverflowMenuContent onOverflowCardClick={openOverflowDrawer} />
    </PopoutDrawer>
  );
}
