import {
  getCanUserCreateFlows,
  MemberRole,
  useUserDetails,
} from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';

import { useActionBarQuery } from './useActionBarQuery';
import { useGlobalFilter } from './useGlobalFilter';

export const useActionBarOptions = () => {
  const filter = useGlobalFilter();

  const { data: currentUserDetails, isLoading: isLoadingUserDetails } =
    useUserDetails();

  const { data, isLoading: isLoadingActionBarQuery } = useActionBarQuery({
    enabled: filter === GlobalFilterOption.All || !filter,
  });

  const isAdminOrManager =
    (currentUserDetails?.member.role.includes(MemberRole.Admin) ?? false) ||
    (currentUserDetails?.member.profile.isManager ?? false);

  const hasFlowsToParticipate =
    (data?.flowsToAnswerAnytime.total ?? 0) > 0 ||
    (data?.flowsToAnswerNow.total ?? 0) > 0 ||
    (data?.flowsToDoCount.count ?? 0) > 0;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasFlowsToView = (data?.flowsToView?.length ?? 0) > 0;

  return {
    isLoading: isLoadingUserDetails || isLoadingActionBarQuery,
    isAdminOrManager,
    hasFlowsToParticipate,
    canPostAnnouncement:
      currentUserDetails?.member.permissions.canPostAnnouncements,
    canGenerateReport:
      isAdminOrManager &&
      ((!hasFlowsToView && !hasFlowsToParticipate) || hasFlowsToView),
    canCreateWorkflow: currentUserDetails
      ? getCanUserCreateFlows(currentUserDetails)
      : false,
    canAskDora: true,
    hasFlowsToSummarize: hasFlowsToView,
    giveRecognition: (() => {
      if (!hasFlowsToParticipate && !hasFlowsToView) {
        return 'primary';
      }
      if (hasFlowsToParticipate && !hasFlowsToView) {
        return false;
      }
      if (!hasFlowsToParticipate && hasFlowsToView) {
        return 'primary';
      }
      return 'secondary';
    })(),
    toDoFlows: data?.flowsToDoCount.count ?? 0,
  } as const;
};
