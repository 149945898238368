import {
  type DoraReportingCategory,
  type Timeout,
  useUserDetails,
} from '@assembly-web/services';
import {
  IconButton,
  OpenTextEditor,
  TextStyle,
  Tooltip,
  useTouchDevice,
} from '@assembly-web/ui';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import type { Draft } from 'immer';
import { type ReactNode, useCallback, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { useSuggestedChatQuestions } from '../../../hooks/dora/useSuggestedChatQuestions';
import { trackDoraAction } from '../../../services/analytics';
import { isAdminMember } from '../../../services/member';
import type { createDoraDrawerStore } from '../../../stores/doraChatStore';
import { SuggestedQuestionsModule } from '../AskDoraDrawer/SuggestedQuestionsModule';
import { BottomActionSheet } from '../shared/dora/BottomActionSheet';
import { timeouts } from '../shared/dora/constants';
import type { Drawer } from '../types';
import { ChatReportHealthDisclaimer } from './ChatReportHealthDisclaimer';

const messages = defineMessages({
  editorLabel: {
    defaultMessage: 'Ask something',
    id: 'Yh9fcS',
  },
  editorHelperText: {
    defaultMessage: 'What would you like to know?',
    id: 'KXSupW',
  },
  tryPromptsLabel: {
    defaultMessage: 'Try one of these prompts:',
    id: 'vGEQ8v',
  },
  editorHelpDescription: {
    defaultMessage:
      'Like humans, AI can make mistakes. For critical business decisions, please download and verify analyses.',
    id: '/A/IkX',
  },
  restartConversationPlaceholder: {
    defaultMessage:
      'This conversation’s data needs to be refreshed. <button>Start new conversation</button>',
    id: 'NJ+F8H',
  },
  conversationLimitReachedPlaceholder: {
    defaultMessage:
      'You’ve already reached your account’s AI Reporting limit for this month. <button>Upgrade to full version</button>',
    id: 'Tu3Iz7',
  },
  promptLimitReachedPlaceholder: {
    defaultMessage: `You’ve reached this conversation’s limit. You have {remainingConversationsForMonth, plural, one {{remainingConversationsForMonth} conversation} other {{remainingConversationsForMonth} conversations}} left this month. <NewConversationButton>Start a new conversation</NewConversationButton> or <UpgradeButton>Upgrade to full version</UpgradeButton>`,
    id: '3un9bl',
  },
});

type DoraChatBottomSheetProps = {
  id: string;
  threadId: string;
  showEditorHelpText: boolean;
  isAdmin: boolean;
  isLastMessagePastCutOffTime: boolean;
  hasReachedConversationLimit: boolean;
  isLoading: boolean;
  canUpdateResponseChat: boolean;
  reportingCategory: DoraReportingCategory;
  doraChatStore: ReturnType<typeof createDoraDrawerStore>;
  getDoraResponse: (prompt: string, threadId?: string) => void;
  setIsResponseBubbleVisible: (isResponseBubbleVisible: boolean) => void;
  changeCategory: (category: DoraReportingCategory) => void;
  onUpgradeClick: () => void;
  isLimitedReportingExperience: boolean;
  hasReachedPromptLimit: boolean;
  remainingConversationsForMonth: number;
};

function HelpText() {
  const { formatMessage } = useIntl();

  return (
    <TextStyle
      variant="sm-regular"
      subdued
      className="inline-flex items-center gap-1 text-center"
    >
      {formatMessage(messages.editorHelperText)}
      <Tooltip tooltipText={formatMessage(messages.editorHelpDescription)}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.9898 6.4999L7.9949 6.5H7.625C7.28011 6.5 7 6.78011 7 7.125V11.375C7 11.7199 7.28011 12 7.625 12H8.375C8.71989 12 9 11.7199 9 11.375V7.125C9 6.78011 8.71989 6.5 8.375 6.5H8.0051L8.0102 6.4999C8.33728 6.49322 8.64871 6.3586 8.87766 6.12493C9.10661 5.89125 9.23485 5.57714 9.23485 5.25C9.23485 4.92286 9.10661 4.60875 8.87766 4.37507C8.64871 4.1414 8.33728 4.00678 8.0102 4.0001L8 3.9999L7.9898 4.0001C7.66272 4.00678 7.35129 4.1414 7.12234 4.37507C6.89339 4.60875 6.76515 4.92286 6.76515 5.25C6.76515 5.57714 6.89339 5.89125 7.12234 6.12493C7.35129 6.3586 7.66272 6.49322 7.9898 6.4999ZM1.5 8C1.5 4.41052 4.41052 1.5 8 1.5C11.5895 1.5 14.5 4.41052 14.5 8C14.5 11.5895 11.5895 14.5 8 14.5C4.41052 14.5 1.5 11.5895 1.5 8Z"
            fill="#597EF7"
            stroke="#597EF7"
          />
        </svg>
      </Tooltip>
    </TextStyle>
  );
}

function DisabledConversation(props: { children: ReactNode }) {
  return (
    <div className="inline-flex min-h-[90px] w-full flex-col rounded-lg border border-gray-7 bg-gray-2 p-2">
      <TextStyle subdued>{props.children}</TextStyle>
      <section className="flex flex-row-reverse p-2">
        <IconButton disabled>
          <PaperAirplaneIcon className="h-6 w-6 text-gray-7" />
        </IconButton>
      </section>
    </div>
  );
}

function TimedOutConversation(props: { startNewConversation: () => void }) {
  const { formatMessage } = useIntl();

  return (
    <DisabledConversation>
      {formatMessage(messages.restartConversationPlaceholder, {
        button: (elem: ReactNode) => (
          <button className="underline" onClick={props.startNewConversation}>
            {elem}
          </button>
        ),
      })}
    </DisabledConversation>
  );
}

export function DoraChatBottomSheet({
  id,
  isLoading,
  showEditorHelpText,
  isAdmin,
  isLastMessagePastCutOffTime,
  hasReachedConversationLimit,
  doraChatStore,
  reportingCategory,
  getDoraResponse,
  threadId,
  setIsResponseBubbleVisible,
  changeCategory,
  canUpdateResponseChat,
  onUpgradeClick,
  isLimitedReportingExperience,
  hasReachedPromptLimit,
  remainingConversationsForMonth,
}: DoraChatBottomSheetProps) {
  const { data: userDetails } = useUserDetails();

  const { formatMessage } = useIntl();
  const isTouchDevice = useTouchDevice();

  const useDoraChatStore = doraChatStore;

  const hasSeenMissingDataBanner = useDoraChatStore(
    (state) => state.hasSeenMissingDataBanner
  );
  const markMissingDataBannerSeen = useDoraChatStore(
    (state) => state.markMissingDataBannerSeen
  );

  const draftInputValue =
    useMultiDrawerStore((store) => store.findDrawer(id))?.inputDraftValue ?? '';

  const updateDraftInputValue = useMultiDrawerStore(
    (store) => store.updateDraftInputValue
  );

  const suggestedQuestions = useSuggestedChatQuestions(reportingCategory);
  const currentBlock = useDoraChatStore(
    (state) => state.reportingInsights?.recentBlock
  );

  const addPrompt = useDoraChatStore((state) => state.addPrompt);

  const showResponseTimeout = useRef<Timeout>();

  const findAndUpdateDrawerField = useMultiDrawerStore(
    (store) => store.findAndUpdateDrawerField
  );

  const startNewConversation = useCallback(() => {
    trackDoraAction('clickedTimeoutStartNew');
    changeCategory(reportingCategory);
  }, [changeCategory, reportingCategory]);

  useEffect(() => {
    return () => clearTimeout(showResponseTimeout.current);
  }, []);

  if (!userDetails) {
    return null;
  }

  const getSuggestedQuestions = () => {
    const filteredByCategory = suggestedQuestions.filter(
      (suggestion) =>
        !suggestion.category || suggestion.category === reportingCategory
    );

    const filteredByRole = filteredByCategory.filter((suggestion) => {
      const { member } = userDetails;
      if (isAdminMember(member)) {
        return suggestion.role === 'admin';
      }

      if (member.profile.isManager) {
        return suggestion.role === 'manager';
      }

      return false;
    });

    return filteredByRole.map((suggestion) => suggestion.question);
  };

  const handlePromptSubmit = () => {
    getDoraResponse(draftInputValue, threadId);
    updateResponseChatBubble(draftInputValue);

    updateDraftInputValue(id, '');
  };

  const updateResponseChatBubble = (prompt: string) => {
    if (canUpdateResponseChat) {
      findAndUpdateDrawerField(id, (draft: Draft<Drawer>) => {
        if (draft.type === 'doraChat') {
          draft.data = {
            prompt,
            // To be able to distinguish custom titles when we add support for it
            isDefaultTitle: true,
          };
        }
      });
    }

    clearTimeout(showResponseTimeout.current);
    setIsResponseBubbleVisible(false);

    showResponseTimeout.current = setTimeout(
      () => setIsResponseBubbleVisible(true),
      timeouts.showInput
    );
  };

  const editor = (
    <>
      <OpenTextEditor
        autoFocusInput
        shouldSubmitOnEnter
        className="max-h-[174px]"
        aria-label={formatMessage(messages.editorLabel)}
        maxLength={500}
        placeholder={formatMessage(messages.editorLabel)}
        rows={1}
        isSubmitDisabled={draftInputValue.trim() === '' || isLoading}
        value={draftInputValue}
        onChange={(e) => updateDraftInputValue(id, e.target.value)}
        onSubmit={handlePromptSubmit}
      />
      <div className="mt-2">
        <SuggestedQuestionsModule
          prompt={formatMessage(messages.tryPromptsLabel)}
          onSuggestedQuestionClick={(selectedQuestion) => {
            const selectedSuggestion = suggestedQuestions.find(
              (suggestion) => suggestion.question === selectedQuestion
            );

            if (selectedSuggestion) {
              const { text } = selectedSuggestion;

              if (currentBlock?.isLoading) {
                updateDraftInputValue(id, text);
                return;
              }

              addPrompt(text);

              getDoraResponse(text, threadId);

              updateResponseChatBubble(text);
            }
          }}
          suggestedQuestions={getSuggestedQuestions()}
        />
      </div>
    </>
  );

  let content;

  if (isLimitedReportingExperience) {
    if (hasReachedConversationLimit) {
      content = (
        <DisabledConversation>
          {formatMessage(messages.conversationLimitReachedPlaceholder, {
            button: (elem: ReactNode) => (
              <button
                className="cursor-pointer underline"
                onClick={() => {
                  trackDoraAction('upgradeOnLimitReachedClicked');
                  onUpgradeClick();
                }}
              >
                {elem}
              </button>
            ),
          })}
        </DisabledConversation>
      );
    } else if (hasReachedPromptLimit) {
      content = (
        <DisabledConversation>
          {formatMessage(messages.promptLimitReachedPlaceholder, {
            remainingConversationsForMonth,
            NewConversationButton: (elem: ReactNode) => (
              <button
                className="cursor-pointer underline"
                onClick={startNewConversation}
              >
                {elem}
              </button>
            ),
            UpgradeButton: (elem: ReactNode) => (
              <button
                className="cursor-pointer underline"
                onClick={onUpgradeClick}
              >
                {elem}
              </button>
            ),
          })}
        </DisabledConversation>
      );
    } else if (isLastMessagePastCutOffTime) {
      content = (
        <TimedOutConversation startNewConversation={startNewConversation} />
      );
    } else {
      content = editor;
    }
  } else {
    if (isLastMessagePastCutOffTime) {
      content = (
        <TimedOutConversation startNewConversation={startNewConversation} />
      );
    } else {
      content = editor;
    }
  }

  return (
    <BottomActionSheet shouldAnimateOnMount={false}>
      <div className="relative mx-6 my-2">
        <AnimatePresence>
          {!isTouchDevice &&
            !hasSeenMissingDataBanner &&
            reportingCategory !== 'flow_responses' && (
              <ChatReportHealthDisclaimer
                key="health-disclaimer"
                isAdmin={isAdmin}
                onDismiss={() => markMissingDataBannerSeen()}
              />
            )}
          {Boolean(showEditorHelpText) && (
            <motion.div
              key="help-text"
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: '100%', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <HelpText />
            </motion.div>
          )}
        </AnimatePresence>

        {content}
      </div>
    </BottomActionSheet>
  );
}
