import { usePusherChannel, usePusherEvents } from '@assembly-web/pusher';
import { logger, useUserDetails } from '@assembly-web/services';

export function useAdminSettingsUpdateEvents() {
  const { data: userDetails, refetch } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  usePusherEvents(assembly, ['ADMIN_SETTINGS_UPDATED'], async () => {
    try {
      await refetch();
    } catch (e) {
      logger.error(
        'Failed to refetch member details after admin settings update',
        {},
        e as Error
      );
    }
  });
}
