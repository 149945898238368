import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const DoraMessageBubble = (
  props: {
    children: ReactNode;
    isFaded?: boolean;
    footer?: ReactNode;
  } & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) => {
  const { children, isFaded, footer, ...otherAttributes } = props;

  if (footer) {
    return (
      <div className="rounded-lg border border-gray-5 bg-gray-2 py-3">
        <div className="px-4 pb-2">{props.children}</div>
        <footer className="border-t border-gray-5 px-4 pt-2">{footer}</footer>
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        'relative rounded-lg border border-gray-5 bg-gray-2 px-4 py-3',
        isFaded ? 'opacity-50' : 'opacity-100'
      )}
      {...otherAttributes}
    >
      {children}
    </div>
  );
};
