import {
  APIEndpoints,
  assemblyAPI,
  type FlowBuilderState,
  type SaveFlowPayload,
  serializeBuilderBlockData,
  useUserDetails,
} from '@assembly-web/services';
import { useAssemblyNavigate, useToastStore } from '@assembly-web/ui';
import {
  useIsMutating,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { getErrorMessage } from '../../../../../../services/errorUtils';
import { useFlowBuilderStore } from '../../../../../../stores/useFlowBuilderStore';
import { useMultiDrawerStore } from '../../../../../../stores/useMultiDrawerStore';
import { useInviteMembers } from '../../../../hooks/useInviteMembers';
import { useEditorDataContext } from '../context/EditorDataContext';

const messages = defineMessages({
  genericError: {
    defaultMessage:
      'Unable to create your flow at the moment. Please try again later.',
    id: '2AhIWG',
  },
});

const CREATION_MUTATION_KEY = ['createFlow'] as const;
const EDIT_MUTATION_KEY = ['editFlow'] as const;

export const useCreateFlowMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: SaveFlowPayload) =>
      assemblyAPI.post<{ flowId: string }>(APIEndpoints.createFlow, payload),
    onSuccess: async () => {
      queryClient.invalidateQueries(['userFeed', 'flows']);
    },
    mutationKey: CREATION_MUTATION_KEY,
  });
};

export const useUpdateFlowMutation = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: SaveFlowPayload) =>
      assemblyAPI.put(APIEndpoints.editFlow(id), payload),
    mutationKey: EDIT_MUTATION_KEY,
    onSuccess: () => {
      queryClient.removeQueries(['flowDetails', id, 'builder']);
    },
  });
};

export const useIsCreatingFlowLoading = () => {
  return useIsMutating({ mutationKey: CREATION_MUTATION_KEY, exact: true });
};

export const useIsUpdateFlowLoading = () => {
  return useIsMutating({ mutationKey: EDIT_MUTATION_KEY, exact: true });
};

export const useCreateOrUpdateFlow = () => {
  const { id, type } = useEditorDataContext();
  const { formatMessage } = useIntl();

  const navigate = useAssemblyNavigate();

  const { data: userDetails } = useUserDetails();

  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;

  const { mutate: createFlowMutation } = useCreateFlowMutation();
  const { mutate: updateFlowMutation } = useUpdateFlowMutation(id);
  const { mutate: inviteMembers } = useInviteMembers();

  const createOrUpdateFlow = useCallback(
    (flow: FlowBuilderState) => {
      const serializedData = serializeBuilderBlockData(flow);

      if (flow.inEditMode) {
        updateFlowMutation(serializedData, {
          onSuccess() {
            document.dispatchEvent(new CustomEvent('invalidate-flow-details'));

            useMultiDrawerStore.getState().deleteDrawer(id);
            useFlowBuilderStore.getState().deleteFlow(id);
          },
          onError(error) {
            if (error instanceof AxiosError) {
              const errorMessage = getErrorMessage(
                error,
                formatMessage(messages.genericError)
              );
              if (errorMessage) {
                useToastStore.getState().showErrorToast(errorMessage);
              }
            }
          },
        });
      } else {
        createFlowMutation(serializedData, {
          onSuccess(data) {
            useMultiDrawerStore.getState().deleteDrawer(id);
            useFlowBuilderStore.getState().deleteFlow(id);

            if (data.data.flowId) {
              navigate(
                `/a/${workspaceSlugPath}/flows/${data.data.flowId}?${new URLSearchParams(
                  [
                    ['show-share-sheet', 'true'],
                    ['flow-created-from-template', 'true'],
                  ]
                )}`
              );

              if (type === 'duplicate') {
                setTimeout(() => {
                  document.dispatchEvent(
                    new CustomEvent('reload-legacy-embed')
                  );
                }, 100);
              }
            }
          },
          onError(error) {
            if (error instanceof AxiosError) {
              const errorMessage = getErrorMessage(
                error,
                formatMessage(messages.genericError)
              );
              if (errorMessage) {
                useToastStore.getState().showErrorToast(errorMessage);
              }
            }
          },
        });
      }
    },
    [
      createFlowMutation,
      formatMessage,
      id,
      navigate,
      type,
      updateFlowMutation,
      workspaceSlugPath,
    ]
  );

  return useCallback(() => {
    const flow = useFlowBuilderStore.getState().flows[id];

    if (!flow) {
      return;
    }

    if (flow.newUsersToInvite.length) {
      inviteMembers(flow.newUsersToInvite, {
        onSuccess() {
          createOrUpdateFlow(flow);
        },
        onError(error) {
          if (error instanceof AxiosError) {
            const errorMessage = getErrorMessage(
              error,
              formatMessage(messages.genericError)
            );
            if (errorMessage) {
              useToastStore.getState().showErrorToast(errorMessage);
            }
          }
        },
      });
    } else {
      createOrUpdateFlow(flow);
    }
  }, [createOrUpdateFlow, formatMessage, id, inviteMembers]);
};
