import type { ContentScaleBlockState } from '@assembly-web/services';
import {
  BlockLabel,
  BlockOptionsDropdown,
  TextStyle,
  useRefProvider,
} from '@assembly-web/ui';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetBlockType,
  useGetScaleRange,
  useSetScaleBlockData,
} from '../../../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../../../services/analytics';
import { useBlockIdContext } from '../../../context/BlockIdContext';
import { useEditorDataContext } from '../../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../../hooks/useAnyOccurrenceInProgress';
import { useFormattedBlockName } from '../../../hooks/useFormattedBlockName';

const messages = defineMessages({
  label: {
    defaultMessage: 'Content limits',
    id: 'SNRwRH',
  },
  scaleLabel: {
    defaultMessage: 'Scale range',
    id: 'GX7kJt',
  },
  npsLabel: {
    defaultMessage: 'NPS range',
    id: 'sdB+DH',
  },
  range: {
    defaultMessage:
      '<minLimit>_</minLimit> <wrap>to</wrap> <maxLimit>_</maxLimit>',
    id: 'kHwGLp',
  },
  minLabel: {
    defaultMessage: 'Min',
    id: 'A4AHkc',
  },
  maxLabel: {
    defaultMessage: 'Max',
    id: 'kkjl2v',
  },
});

type Option = {
  label: string;
  value: string;
};

type RangeSelectProps = { type: 'min' | 'max' };

const rangeAttributeMap = {
  max: 'maximumRange',
  min: 'minimumRange',
} satisfies Record<
  RangeSelectProps['type'],
  keyof Pick<ContentScaleBlockState, 'minimumRange' | 'maximumRange'>
>;

const minOptions = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
] satisfies Option[];

const maxOptions = [
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
] satisfies Option[];

function RangeSelect({ type }: RangeSelectProps) {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useGetBlockType(id, blockId);
  const isNPS = blockType === 'NPS';

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  let value = useGetScaleRange(id, blockId, type);
  const set = useSetScaleBlockData(id, blockId);

  value = isNPS ? (type === 'min' ? 0 : 10) : value;

  const portalContainer = useRefProvider();

  const formattedBlockName = useFormattedBlockName();

  return (
    <BlockOptionsDropdown.DropdownTypeContextProvider value="SELECT">
      <BlockOptionsDropdown.SelectConstruct
        onValueChange={(value) => {
          trackFlowEditorAction('blockDetailClicked', {
            blockType: formattedBlockName,
            detailType: `${formattedBlockName}${(() => {
              return (
                { max: 'MaxScale', min: 'MinScale' } satisfies Record<
                  RangeSelectProps['type'],
                  string
                >
              )[type];
            })()}`,
          });
          set((state) => {
            state[rangeAttributeMap[type]] = Number(value);

            return state;
          });
        }}
        value={String(value)}
        triggerVariant="border"
        label={formatMessage(
          messages[type === 'min' ? 'minLabel' : 'maxLabel']
        )}
        disabled={isNPS || isOccurrenceInProgress}
        portal={portalContainer}
      >
        {(type === 'min' ? minOptions : maxOptions).map(({ label, value }) => (
          <BlockOptionsDropdown.Item key={value} label={label} value={value} />
        ))}
      </BlockOptionsDropdown.SelectConstruct>
    </BlockOptionsDropdown.DropdownTypeContextProvider>
  );
}

function Lock() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useGetBlockType(id, blockId);

  if (blockType !== 'NPS') {
    return null;
  }

  return <LockClosedIcon className="text-gray-900 h-4 w-4" />;
}

function Label() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useGetBlockType(id, blockId);

  if (blockType === 'NPS') {
    return <BlockLabel>{formatMessage(messages.npsLabel)}</BlockLabel>;
  }

  if (blockType === 'SCALE') {
    return <BlockLabel>{formatMessage(messages.scaleLabel)}</BlockLabel>;
  }

  return <BlockLabel>{formatMessage(messages.label)}</BlockLabel>;
}

export function Range() {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-col gap-1">
      <Label />
      <div className="flex items-center gap-2">
        {formatMessage(messages.range, {
          minLimit: () => <RangeSelect type="min" />,
          maxLimit: () => <RangeSelect type="max" />,
          wrap: (chunk) => (
            <TextStyle as="span" variant="sm-regular">
              {chunk}
            </TextStyle>
          ),
        })}
        <Lock />
      </div>
    </div>
  );
}
