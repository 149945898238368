import { errorBrowserCatImage } from '@assembly-web/assets';
import { TextStyle } from '@assembly-web/ui';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { TextButton } from '../../../../../../components/TextButton';

const messages = defineMessages({
  whoopsTroubleLoading: {
    defaultMessage: 'Whoops! We had trouble loading this conversation',
    id: 'cGSiFY',
  },
});

export function ChatHistoryError(props: { onTryAgain: () => void }) {
  const { formatMessage } = useIntl();

  return (
    <div className="flex h-full flex-col items-center justify-center bg-gray-1">
      <img alt="" className="mb-1 w-[185px]" src={errorBrowserCatImage} />
      <TextStyle className="text-gray-9" variant="base-medium">
        {formatMessage(messages.whoopsTroubleLoading)}
      </TextStyle>
      <TextStyle variant="sm-regular" className="text-gray-9">
        <FormattedMessage
          defaultMessage="Please <button>try again</button>."
          id="4MeFIR"
          values={{
            button: (text) => (
              <TextButton
                className="!text-gray-9"
                onClick={props.onTryAgain}
                underline
              >
                {text}
              </TextButton>
            ),
          }}
        />
      </TextStyle>
    </div>
  );
}
