import type {
  ChallengeAPIResponse,
  RepliesResponse,
  ReplyData,
  UserDetails,
} from '@assembly-web/services';
import { useUserDetails } from '@assembly-web/services';
import {
  EmptyOrErrorStateTemplate,
  HorizontalRule,
  NoRewardsFound as PostNotFound,
  TextStyle,
  useDeviceInfo,
} from '@assembly-web/ui';
import { type ElementRef, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RemoveScroll } from 'react-remove-scroll';
import { twJoin } from 'tailwind-merge';

import {
  useGetChallengeRepliesInfiniteQuery,
  useGetRepliesSummaryForChallenge,
} from '../../../hooks/challenges/useChallengeRepliesQueries';
import { useGetChallengeDetailsByIdQuery } from '../../../hooks/challenges/useGetChallengeDetailsByIdQuery';
import { useGetRepliesDrafts } from '../../../hooks/useGetRepliesDrafts';
import type { ChallengesDrawer } from '../types';
import { ChallengePostContainer } from './ChallengePostContainer';
import { ChallengeRepliesContainer } from './ChallengeRepliesContainer';
import { ChallengeRepliesEditorContainer } from './ChallengeRepliesEditorContainer';
import { useShowShadowForEditor } from './hooks/useShowShadowForEditor';

const messages = defineMessages({
  repliesTurnedOff: {
    defaultMessage: 'Replies have been turned off',
    id: 'h9h1y6',
  },
  replyCount: {
    defaultMessage:
      '{count, plural, =0 {No Replies} one {1 reply} other {{count} replies}}',
    id: 'Sn3666',
  },
  challengeNotFound: {
    defaultMessage: 'Challenge not found',
    id: 'l7IOuJ',
  },
  challengeNotFoundDescription: {
    defaultMessage:
      'It may have been deleted or you may have lost access to it. Please confirm that you are logged into the correct account.',
    id: 'lxLyeY',
  },
});

export function ChallengeDrawerLoader() {
  return (
    <div className="m-4 w-full animate-pulse items-start">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div className="h-[12px] w-[79px] rounded-full bg-gray-5" />
          <div className="h-4 w-4 rounded bg-gray-5" />
        </div>
        <div className="flex items-center gap-1">
          <div className="h-[12px] w-[79px] rounded-full bg-gray-5" />
          <div className="h-4 w-4 rounded bg-gray-5" />
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-2">
        <div className="h-[32px] w-full max-w-[482px] flex-shrink-0 rounded bg-gray-5" />
        <div className="h-[192px] w-full max-w-[182px] flex-shrink-0 rounded bg-gray-5" />
        <div className="h-[24px] w-[208px] flex-shrink-0 rounded bg-gray-5" />
        <div className="h-[24px] w-[482px] flex-shrink-0 rounded bg-gray-5" />
      </div>
    </div>
  );
}

export function ChallengeDrawerError() {
  const { formatMessage } = useIntl();

  return (
    <section className="flex h-full items-center justify-center self-auto px-6 pb-4">
      <EmptyOrErrorStateTemplate
        image={PostNotFound}
        altText="flow-response-loading-error"
        heading={formatMessage(messages.challengeNotFound)}
        subHeading={formatMessage(messages.challengeNotFoundDescription)}
      />
    </section>
  );
}

type ChallengeDrawerContentProps = {
  commentIdToEdit?: string;
  userDetails: UserDetails;
  focusedCommentId?: string;
  repliesData: RepliesResponse;
  challengeDetails: ChallengeAPIResponse;
};

function ChallengeDrawerContent({
  repliesData,
  userDetails,
  commentIdToEdit,
  challengeDetails,
  focusedCommentId,
}: ChallengeDrawerContentProps) {
  const isMobile = useDeviceInfo().deviceType === 'mobile';

  const containerRef = useRef<ElementRef<'section'>>(null);
  const challengeDrawerRef = useRef<ElementRef<'section'>>(null);

  const [selectedReply, setSelectedReply] = useState<ReplyData | null>(null);
  const [isRemoveScrollEnabled, setIsRemoveScrollEnabled] = useState(false);

  const { formatMessage } = useIntl();

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (challengeDrawerRef.current?.contains(e.target as Node)) {
        setIsRemoveScrollEnabled(true);
      } else {
        setIsRemoveScrollEnabled(false);
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: true });
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const { handleOnDrawerBodyScroll, showShadowForEditor } =
    useShowShadowForEditor({ containerRef, drawerRef: challengeDrawerRef });

  const hideReplies =
    challengeDetails.interactionSettings?.hideReplies ?? false;

  return (
    <section className="h-full" ref={challengeDrawerRef}>
      <RemoveScroll
        enabled={!isMobile && isRemoveScrollEnabled}
        className="flex h-full flex-col overflow-hidden bg-gray-1"
      >
        <section
          ref={containerRef}
          onScroll={handleOnDrawerBodyScroll}
          className="flex flex-grow flex-col overflow-x-hidden px-6 pb-4 pt-3"
        >
          <ChallengePostContainer
            repliesData={repliesData}
            userDetails={userDetails}
            challengeDetails={challengeDetails}
          />

          <div className="grid w-full items-center gap-2">
            <HorizontalRule>
              <TextStyle variant="sm-regular" className="text-gray-8">
                {hideReplies
                  ? formatMessage(messages.repliesTurnedOff)
                  : formatMessage(messages.replyCount, {
                      count: repliesData.count,
                    })}
              </TextStyle>
            </HorizontalRule>
          </div>

          {!hideReplies && (
            <ChallengeRepliesContainer
              userDetails={userDetails}
              repliesData={repliesData}
              containerRef={containerRef}
              commentIdToEdit={commentIdToEdit}
              focusedCommentId={focusedCommentId}
              onEditReplyClick={setSelectedReply}
              challengeDetails={challengeDetails}
              editingCommentId={selectedReply?.commentID ?? null}
            />
          )}
        </section>
        {!hideReplies && (
          <section
            className={twJoin(
              'z-10 flex max-h-[80%] flex-shrink-0 flex-col gap-2 bg-gray-1 px-6 pb-4 pt-3 transition-all duration-300',
              showShadowForEditor && 'shadow-base-up'
            )}
          >
            <ChallengeRepliesEditorContainer
              userDetails={userDetails}
              containerRef={containerRef}
              selectedReply={selectedReply}
              onSelectedReplyChange={() => {
                setSelectedReply(null);
              }}
              challengeId={challengeDetails.challengeId}
            />
          </section>
        )}
      </RemoveScroll>
    </section>
  );
}

export function ChallengeDrawerBody({
  data: { challengeId, commentId, commentIdToEdit },
}: ChallengesDrawer) {
  const { data: userDetails, isLoading: isUserDetailsLoading } =
    useUserDetails();

  const { isLoading: isDraftsLoading, error: isGetRepliesDraftsError } =
    useGetRepliesDrafts(challengeId);

  const {
    data: challengeDetails,
    isError: isChallengeDetailsError,
    isLoading: isChallengeDetailsLoading,
  } = useGetChallengeDetailsByIdQuery(challengeId);

  const {
    data: replySummaryData,
    isError: isRepliesSummaryError,
    isLoading: isRepliesSummaryLoading,
  } = useGetRepliesSummaryForChallenge(challengeId);

  const { isError: isRepliesListError, isLoading: isRepliesListLoading } =
    useGetChallengeRepliesInfiniteQuery({
      challengeId,
      commentId: commentId ?? null,
    });

  if (
    isDraftsLoading ||
    isRepliesListLoading ||
    isUserDetailsLoading ||
    isRepliesSummaryLoading ||
    isChallengeDetailsLoading
  ) {
    return <ChallengeDrawerLoader />;
  }

  if (
    isRepliesListError ||
    isRepliesSummaryError ||
    isChallengeDetailsError ||
    isGetRepliesDraftsError
  ) {
    return <ChallengeDrawerError />;
  }

  if (userDetails) {
    return (
      <ChallengeDrawerContent
        userDetails={userDetails}
        focusedCommentId={commentId}
        repliesData={replySummaryData}
        challengeDetails={challengeDetails}
        commentIdToEdit={commentIdToEdit}
      />
    );
  }

  return <ChallengeDrawerError />;
}
