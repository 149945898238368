import { BlockMultiOption } from '@assembly-web/ui';
import { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useBlockType,
  useCreateOption,
  useDuplicateOption,
  useGetBlockOptionDetail,
  useGetBlockOptionIndex,
  useGetBlockOptions,
  useGetOtherBlockOptionExist,
  useIsOtherOption,
  useRemoveOption,
  useSetOptionLabel,
} from '../../../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../../../services/analytics';
import { useBlockIdContext } from '../../../context/BlockIdContext';
import { useEditorDataContext } from '../../../context/EditorDataContext';
import {
  OptionIdProvider,
  useOptionIdContext,
} from '../../../context/OptionIdContext';
import { useAnyOccurrenceInProgress } from '../../../hooks/useAnyOccurrenceInProgress';
import { useFormattedBlockName } from '../../../hooks/useFormattedBlockName';

const messages = defineMessages({
  multiChoiceBlockLabel: {
    defaultMessage: 'Multiple choice answer options',
    id: '+DegS5',
  },
  dropdownBlockLabel: {
    defaultMessage: 'Dropdown answer options',
    id: 'L1xgar',
  },
});

function AddOption() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const optionsLength = useGetBlockOptions(id, blockId).length;

  const createOption = useCreateOption(id, blockId);

  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleClick = () => {
    createOption();
    trackFlowEditorAction('blockDetailClicked', {
      blockType: formattedBlockName,
      detailType: `${formattedBlockName}AddOption`,
    });
  };

  return (
    <BlockMultiOption.Item>
      <BlockMultiOption.Container>
        <BlockMultiOption.OptionNumber>
          {optionsLength + 1}
        </BlockMultiOption.OptionNumber>
        <BlockMultiOption.AddNewOption
          disabled={isOccurrenceInProgress}
          onClick={handleClick}
        />
      </BlockMultiOption.Container>
    </BlockMultiOption.Item>
  );
}

function OtherOption() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useBlockType(id, blockId);
  const otherOptionExists = useGetOtherBlockOptionExist(id, blockId);
  const createOption = useCreateOption(id, blockId);

  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleClick = () => {
    createOption(true);
    trackFlowEditorAction('blockDetailClicked', {
      blockType: formattedBlockName,
      detailType: `${formattedBlockName}OtherOption`,
    });
  };

  if (blockType !== 'MULTI_CHOICE' || otherOptionExists) {
    return null;
  }

  return (
    <BlockMultiOption.Item>
      <BlockMultiOption.Container highlighted={false}>
        <span />
        <BlockMultiOption.AddOtherOption
          disabled={isOccurrenceInProgress}
          onClick={handleClick}
        />
      </BlockMultiOption.Container>
    </BlockMultiOption.Item>
  );
}

function OptionIndex() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();
  const optionId = useOptionIdContext();

  const index = useGetBlockOptionIndex({ blockId, id, optionId });

  return (
    <BlockMultiOption.OptionNumber>{index + 1}</BlockMultiOption.OptionNumber>
  );
}

function OptionDescription() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();
  const optionId = useOptionIdContext();

  const isOtherOption = useIsOtherOption({ blockId, id, optionId });
  const index = useGetBlockOptionIndex({ blockId, id, optionId });
  const handleChange = useSetOptionLabel({ blockId, id, optionId });
  const value = useGetBlockOptionDetail({
    blockId,
    id,
    optionId,
    selector(state) {
      return state.label;
    },
  });
  const createOption = useCreateOption(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  if (isOtherOption) {
    return <BlockMultiOption.OtherOption />;
  }

  return (
    <BlockMultiOption.OptionDescription
      value={value ?? ''}
      onChange={(e) => handleChange(e.target.value)}
      index={index + 1}
      onPressEnter={createOption}
      disabled={isOccurrenceInProgress}
    />
  );
}

function DuplicateOption() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();
  const optionId = useOptionIdContext();

  const createOption = useDuplicateOption({ blockId, id, optionId });
  const isOtherOption = useIsOtherOption({ blockId, id, optionId });

  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleClick = () => {
    createOption();
    trackFlowEditorAction('blockDetailClicked', {
      blockType: formattedBlockName,
      detailType: `${formattedBlockName}Duplicate`,
    });
  };

  if (isOtherOption) {
    return null;
  }

  return (
    <BlockMultiOption.OptionDuplicate
      onClick={handleClick}
      disabled={isOccurrenceInProgress}
    />
  );
}

function RemoveOption() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();
  const optionId = useOptionIdContext();

  const removeOption = useRemoveOption({ blockId, id, optionId });

  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleClick = () => {
    removeOption();
    trackFlowEditorAction('blockDetailClicked', {
      blockType: formattedBlockName,
      detailType: `${formattedBlockName}Delete`,
    });
  };

  return (
    <BlockMultiOption.OptionRemove
      onClick={handleClick}
      disabled={isOccurrenceInProgress}
    />
  );
}

const Option = memo(function Option() {
  return (
    <BlockMultiOption.Item>
      <BlockMultiOption.Container>
        <OptionIndex />
        <OptionDescription />
        <DuplicateOption />
        <RemoveOption />
      </BlockMultiOption.Container>
    </BlockMultiOption.Item>
  );
});

function OptionsList() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const options = useGetBlockOptions(id, blockId);

  return options.map((optionId) => (
    <OptionIdProvider key={optionId} value={optionId}>
      <Option />
    </OptionIdProvider>
  ));
}

export function Options() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useBlockType(id, blockId);

  const blockLabel = formatMessage(
    blockType === 'DROPDOWN'
      ? messages.dropdownBlockLabel
      : messages.multiChoiceBlockLabel
  );

  return (
    <BlockMultiOption.Root blockLabel={blockLabel}>
      <OptionsList />
      <AddOption />
      <OtherOption />
    </BlockMultiOption.Root>
  );
}
