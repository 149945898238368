import camelCase from 'lodash/camelCase';

import {
  useBlockType,
  useGetBlockIsLinked,
} from '../../../../../../stores/useFlowBuilderStore';
import { useBlockIdContext } from '../context/BlockIdContext';
import { useEditorDataContext } from '../context/EditorDataContext';

export const useFormattedBlockName = () => {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useBlockType(id, blockId);
  const isLinkedBlock = useGetBlockIsLinked(id, blockId);

  if (isLinkedBlock) {
    return 'givePoints';
  }

  return camelCase(blockType ?? '');
};
