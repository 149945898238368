import { doraAPI, DoraAPIEndpoints } from '@assembly-web/services';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

export type DoraThreadDetails = {
  id: string;
  reportType: string;
  threadName: string;
  userId: string;
  assemblyId: string;
  isShared: boolean;
  promptCount: number;
  messageCount: number;
  createdAt: Date;
  updatedAt: Date;
  state: string;
};

export const getDoraThreadDetailsQueryKey = (threadId: string) => [
  'doraThreadDetails',
  threadId,
];

export function useDoraThreadDetails({
  threadId,
  enabled,
}: {
  threadId: string;
  enabled: boolean;
}): UseQueryResult<DoraThreadDetails> {
  return useQuery({
    enabled,
    queryKey: getDoraThreadDetailsQueryKey(threadId),
    queryFn: async () => {
      const response = await doraAPI.get<DoraThreadDetails>(
        DoraAPIEndpoints.getThreadDetails,
        { params: { threadId } }
      );

      return response.data;
    },
  });
}
