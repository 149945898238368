import type { Drawer } from '../../components/Drawers/types';
import { createDrawerStore } from './drawerStore';
import { createEventSourceStore } from './eventSourceStore';
import { createFormSettingsStore } from './formSettingsStore';

export const getPersistKeyForFlowSummaryDrawer = (drawer: Drawer) =>
  `${drawer.type}-${drawer.id}`;

export const getStoresForFlowSummaryDrawer = (drawer: Drawer) => {
  const persistId = getPersistKeyForFlowSummaryDrawer(drawer);
  const eventSourceStore = createEventSourceStore(persistId);
  const formSettingsStore = createFormSettingsStore(persistId);

  const drawerStore = createDrawerStore(persistId, {
    eventSourceStore,
    formSettingsStore,
  });

  return {
    eventSourceStore,
    formSettingsStore,
    drawerStore,
  };
};

export const deleteFlowSummaryDrawer = (drawer: Drawer) => {
  const { drawerStore, eventSourceStore, formSettingsStore } =
    getStoresForFlowSummaryDrawer(drawer);

  eventSourceStore.useEventSourceStore.persist.clearStorage();
  drawerStore.useDrawerStore.persist.clearStorage();
  formSettingsStore.clearStorage();
};
