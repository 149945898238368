import {
  doraAPI,
  DoraAPIEndpoints,
  type DoraReportingCategory,
} from '@assembly-web/services';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

export type DoraSavedPrompt = {
  id: string;
  reportType: DoraReportingCategory;
  userId: string;
  assemblyId: string;
  prompt: string;
  generationType: string;
  createdAt: Date;
  updatedAt: Date;
  reportName: string;
};

export const useSavedReportsQueryKey = ['doraSavedThreads'];

export function useSavedReports(): UseQueryResult<DoraSavedPrompt[]> {
  return useQuery({
    queryKey: useSavedReportsQueryKey,
    queryFn: async () => {
      const response = await doraAPI.get<DoraSavedPrompt[]>(
        DoraAPIEndpoints.getSavedReports
      );

      return response.data.filter(
        (report) => report.reportType !== 'flow_responses'
      );
    },
  });
}
