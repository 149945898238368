export const CHALLENGE_STATUS = {
  ACTIVE: 'ACTIVE',
  QUEUED: 'QUEUED',
  CLOSED: 'CLOSED',
  ARCHIVED: 'ARCHIVED',
} as const;

export type ChallengeStatusType = keyof typeof CHALLENGE_STATUS;

export const UserFeedKeys = {
  challenges: ({
    challengeStatus,
  }: {
    challengeStatus?: ChallengeStatusType[];
  }) => [
    'userFeed',
    'challenges',
    'relevance',
    'asc',
    {
      flowStatus: ['ACTIVE', 'INACTIVE'],
      ...(challengeStatus &&
        challengeStatus.length > 0 && {
          challengeStatus: challengeStatus.sort(),
        }),
    },
    'challenge',
  ],
  allChallenges: () => ['userFeed', 'challenges'],
};
