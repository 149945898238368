import type {
  ImportantActivitiesApiResponse,
  UserFeedApiResponse,
} from '@assembly-web/services';
import {
  ActivityCardTypeEnum,
  APIEndpoints,
  assemblyAPI,
} from '@assembly-web/services';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import type { GetUserFeedPayload } from '../queries/getUserFeedQuery';
import type { SearchIndexApiResponse, SearchPayload } from './useSearchIndex';

type PostPayload = {
  postId: string;
  returnPoints: boolean;
  query?: GetUserFeedPayload | SearchPayload;
};

export function useArchiveRecognitionPostMutation(
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void
) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  return useMutation(
    async (payload: PostPayload) => {
      const { postId, returnPoints } = payload;
      await assemblyAPI.put(APIEndpoints.archivePost, {
        postId,
        returnPoints,
      });
    },
    {
      onSuccess: (_, payload) => {
        onSuccessCallback?.();
        const { postId } = payload;
        const userFeedKeys = queryCache
          .findAll(['userFeed'])
          .map((query) => query.queryKey);
        userFeedKeys.forEach((queryKey) => {
          const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
            queryClient.getQueryData(queryKey);

          const updatedTasks = produce(userFeedData, (draft) => {
            draft?.pages.forEach((page) => {
              page.data = page.data.filter((card) => {
                if (card.type === 'post') {
                  return card._meta.entityId !== postId;
                }

                return true;
              });
            });
          });
          queryClient.setQueryData(queryKey, updatedTasks);
        });

        const searchFeedKeys = queryCache
          .findAll(['searchResults'])
          .map((query) => query.queryKey);
        searchFeedKeys.forEach((queryKey) => {
          const previousTasks =
            queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
              queryKey
            );

          const updatedTasks = produce(previousTasks, (draft) => {
            draft?.pages.forEach((page) => {
              page.data.data = page.data.data.filter((card) => {
                if (card.type === 'post') {
                  return card._meta.entityId !== postId;
                }

                return true;
              });
            });
          });

          queryClient.setQueryData(queryKey, updatedTasks);
        });

        const importantQueryKey = ['importantCards'];
        const previousCards =
          queryClient.getQueryData<
            InfiniteData<ImportantActivitiesApiResponse>
          >(importantQueryKey);

        if (previousCards?.pages.length) {
          const updatedActivities = produce(previousCards, (draft) => {
            draft.pages.forEach((page) => {
              page.data.data = page.data.data.filter((card) => {
                switch (card.type) {
                  case ActivityCardTypeEnum.PostMentions:
                    return card.entity.postId !== postId;
                }

                return true;
              });
            });
          });

          queryClient.setQueryData(importantQueryKey, updatedActivities);
        }
      },
      onError: () => {
        onErrorCallback?.();
      },
    }
  );
}
