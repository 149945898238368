import { useMemo } from 'react';

import { useGetAllAssemblyWorkspaceDepartments } from './queries/useGetDeparmentsQuery';
import {
  type ProfileDataAPIResponse,
  ProfileDataType,
  usePostProfileData,
} from './queries/usePostProfileDataQuery';

export const useShareSheetNonMemberCriteriaOptions = ({
  enabled,
}: {
  enabled: boolean;
}) => {
  const { data: departmentsData } =
    useGetAllAssemblyWorkspaceDepartments(enabled);

  const { data: profileData } = usePostProfileData(enabled);

  const extractData = (key: string, profileData: ProfileDataAPIResponse) =>
    (profileData.data.find((profile) => profile.key === key)?.values ?? []).map(
      (item) => ({
        id: item,
        label: item,
        isSelected: false,
      })
    );

  const { jobTitles, homeLocations, workLocations } = useMemo(() => {
    if (!profileData) {
      return {
        jobTitles: [],
        homeLocations: [],
        workLocations: [],
      };
    }

    return {
      jobTitles: extractData(ProfileDataType.JobTitle, profileData),
      homeLocations: extractData(ProfileDataType.HomeLocation, profileData),
      workLocations: extractData(ProfileDataType.WorkLocation, profileData),
    };
  }, [profileData]);

  const departments = useMemo(() => {
    if (!departmentsData) return [];
    return departmentsData.data.map((department) => ({
      id: department,
      label: department,
      isSelected: false,
    }));
  }, [departmentsData]);

  return {
    departments,
    jobTitles,
    homeLocations,
    workLocations,
  };
};
