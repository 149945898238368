import { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextButton } from '../../../../../../../../../components/TextButton';
import { trackDoraAction } from '../../../../../../../services/analytics';
import {
  ErrorBottomActionSheet,
  LoadingBottomActionSheet,
} from '../../../../../shared/dora/BottomActionSheet';
import {
  anonymousMemberId,
  timeouts,
} from '../../../../../shared/dora/constants';
import { Option } from '../../../../shared/Option';
import { OptionsSelectorInput } from '../../../../shared/OptionsSelectorInput';
import {
  RespondentsGroup,
  type RespondentsGroupInputProps,
} from '../../../../types';

const messages = defineMessages({
  all: {
    defaultMessage: 'All respondents',
    id: 'HcFS1f',
  },
  anonymous: {
    defaultMessage: 'All anonymous respondents',
    id: 'q4nF8V',
  },
  individual: {
    defaultMessage: 'Individual respondents',
    id: 'cd1bey',
  },
  nonAnonymous: {
    defaultMessage: 'Choose individual non-anonymous respondents',
    id: 'MDkCcM',
  },
});

export const Selector = (props: RespondentsGroupInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore,
    formSettingsStore: {
      repromptTimePeriod,
      useIndividualRespondentsSetting,
      useRespondentsGroupSetting,
    },
    isError,
    isLoading,
    onTryAgain,
    shouldShowAnonymousOption,
  } = props;

  const { formatMessage } = useIntl();

  const isRespondentsSet = useIndividualRespondentsSetting(
    (store) => store.isSet
  );

  const markRespondentsSet = useIndividualRespondentsSetting(
    (store) => store.markSet
  );

  const setIndividualRespondents = useIndividualRespondentsSetting(
    (store) => store.setValue
  );

  const isNewInputSeen = useRespondentsGroupSetting.getState().isInputSeen;

  const markNewInputSeen = useRespondentsGroupSetting(
    (store) => store.markInputSeen
  );

  const setGroupRespondents = useRespondentsGroupSetting(
    (store) => store.setValue
  );

  const value = useRespondentsGroupSetting((store) => store.value);

  const isEditing = useEditSettingsStore(
    (store) => store.respondentsGroup.shouldRequestInput
  );

  const isEditInputSeen =
    useEditSettingsStore.getState().respondentsGroup.isInputSeen;

  const markEditInputSeen = useEditSettingsStore(
    (store) => store.markInputSeen
  );

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const exitEditMode = useEditSettingsStore((store) => store.exitEditMode);
  const markEditedSet = useEditSettingsStore((store) => store.markSet);
  const editValue = useEditSettingsStore((store) => store.setValue);

  const requestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  useEffect(() => {
    if (!isNewInputSeen && !isEditing) {
      markNewInputSeen();
    } else if (!isEditInputSeen && isEditing) {
      markEditInputSeen('respondentsGroup');
    }
  }, [
    isEditInputSeen,
    isEditing,
    isNewInputSeen,
    markEditInputSeen,
    markNewInputSeen,
  ]);

  useEffect(() => {
    return () => {
      if (!isRespondentsSet) {
        const isAnonymousPicked = value === RespondentsGroup.Anonymous;

        if (value === RespondentsGroup.All || isAnonymousPicked) {
          setTimeout(() => {
            if (isAnonymousPicked) {
              setIndividualRespondents([
                { fullName: 'Anonymous', memberId: anonymousMemberId },
              ]);
            }

            markRespondentsSet();
          }, timeouts.showNextMessage);
        }
      }
    };
  }, [isRespondentsSet, markRespondentsSet, setIndividualRespondents, value]);

  const shouldAnimateOnMount =
    (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen);

  const handleCancel = () => {
    trackDoraAction('summaryInputEditCanceled');
    exitEditMode();
  };

  const intermediaryProps = {
    className: twMerge(isEditing ? 'h-[134px]' : 'h-24'),
    isEditing,
    onCancel: handleCancel,
    shouldAnimateOnMount,
  };

  if (isLoading) {
    return <LoadingBottomActionSheet {...intermediaryProps} />;
  }

  if (isError) {
    return (
      <ErrorBottomActionSheet {...intermediaryProps}>
        <FormattedMessage
          defaultMessage="Sorry, I had an issue fetching the list of respondents to this flow. Please <button>try again</button>."
          id="CtnZYl"
          values={{
            button: (text) => (
              <TextButton
                className="!text-gray-8"
                onClick={onTryAgain}
                underline
              >
                {text}
              </TextButton>
            ),
          }}
        />
      </ErrorBottomActionSheet>
    );
  }

  const handleAllRespondentsClick = () => {
    const isSameAsOrigValue = value === RespondentsGroup.All;

    if (isSameAsOrigValue) {
      exitEditMode();
    } else {
      setGroupRespondents(RespondentsGroup.All);

      if (isEditing) {
        useIndividualRespondentsSetting.getState().reset();
        markRespondentsSet();
        repromptTimePeriod();
        eventSourceStore.reset();
        exitEditMode();
      }
    }

    trackDoraAction(
      isSameAsOrigValue || isEditing
        ? 'summaryInputEditConfirmed'
        : 'peopleToIncludeSelected',
      {
        doraSummaryInput: 'All respondents',
      }
    );
  };

  const handleIndividualRespondentsClick = () => {
    if (value === RespondentsGroup.Individual) {
      exitEditMode();

      trackDoraAction('summaryInputEditConfirmed', {
        doraSummaryInput: shouldShowAnonymousOption
          ? 'Choose individual non-anonymous respondents'
          : 'Individual respondents',
      });
    } else if (isEditing) {
      editValue('respondentsGroup', RespondentsGroup.Individual);
      requestEdit('respondentsGroup', false);

      setTimeout(() => {
        setSettingEditing('individualRespondents');
        markEditedSet('respondentsGroup');
      }, timeouts.showNextMessage);
    } else {
      setGroupRespondents(RespondentsGroup.Individual);

      trackDoraAction('peopleToIncludeSelected', {
        doraSummaryInput: shouldShowAnonymousOption
          ? 'Choose individual non-anonymous respondents'
          : 'Individual respondents',
      });
    }
  };

  const options = [
    <Option key={RespondentsGroup.All} onClick={handleAllRespondentsClick}>
      {formatMessage(messages.all)}
    </Option>,
    <Option
      key={RespondentsGroup.Individual}
      onClick={handleIndividualRespondentsClick}
    >
      {formatMessage(
        shouldShowAnonymousOption ? messages.nonAnonymous : messages.individual
      )}
    </Option>,
  ];

  if (shouldShowAnonymousOption) {
    const handleAnonymousRespondentsClick = () => {
      const isSameAsOrigValue = value === RespondentsGroup.Anonymous;

      if (isSameAsOrigValue) {
        exitEditMode();
      } else if (isEditing) {
        useIndividualRespondentsSetting.getState().reset();
        markRespondentsSet();
        repromptTimePeriod();
        eventSourceStore.reset();

        setGroupRespondents(RespondentsGroup.Anonymous);

        setIndividualRespondents([
          { fullName: 'Anonymous', memberId: anonymousMemberId },
        ]);

        exitEditMode();
      } else {
        setGroupRespondents(RespondentsGroup.Anonymous);
      }

      trackDoraAction(
        isSameAsOrigValue || isEditing
          ? 'summaryInputEditConfirmed'
          : 'peopleToIncludeSelected',
        {
          doraSummaryInput: 'All anonymous respondents',
        }
      );
    };

    options.splice(
      1,
      0,
      <Option onClick={handleAnonymousRespondentsClick}>
        {formatMessage(messages.anonymous)}
      </Option>
    );
  }

  return (
    <OptionsSelectorInput
      isEditing={isEditing}
      onCancel={handleCancel}
      shouldAnimateOnMount={
        (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen)
      }
    >
      {options}
    </OptionsSelectorInput>
  );
};
