import {
  Button,
  Checkbox,
  Modal,
  TextStyle,
  useToastStore,
} from '@assembly-web/ui';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDoraFeedbackMutation } from '../../hooks/useDoraFeedbackMutation';
import { trackDiscoverAction } from '../../services/analytics';
import {
  doraFeedbackMessages,
  managerInsightsFeedbackMessages,
  messages,
} from '../dora/messages';
import type { DoraFeedbackPromptSource } from '../Drawers/types';

type DoraFeedbackModalProps = {
  modalSource: DoraFeedbackPromptSource;
  onClose: () => void;
  promptId: string | null;
};

type CheckboxValues = {
  notTrue?: boolean;
  notHelpful?: boolean;
  'harmful-unsafe'?: boolean;
  tookTooLong?: boolean;
  irrelevant?: boolean;
  incorrectDataOrAnalysis?: boolean;
  technicalIssues?: boolean;
  other?: boolean;
};

type CheckboxKey = keyof CheckboxValues;
type AskDoraFeedbackRating = 'UNTRUE' | 'UNHELPFUL' | 'HARMFUL';
type ManagerReportingFeedbackRating =
  | 'TOO_LONG'
  | 'IRRELEVANT'
  | 'INCORRECT_DATA_OR_ANALYSIS'
  | 'TECHNICAL_ISSUES'
  | 'OTHER';
type Rating = AskDoraFeedbackRating | ManagerReportingFeedbackRating;

function FeedbackRatingOption({
  onChange,
  id,
  label,
}: {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  label: string;
}) {
  return (
    <label htmlFor={id} className="h-6 cursor-pointer text-sm text-gray-9">
      <Checkbox className="mr-2" id={id} onChange={onChange} />
      <span>{label}</span>
    </label>
  );
}

export function DoraFeedbackModal(props: DoraFeedbackModalProps) {
  const { promptId, modalSource, onClose } = props;
  const { showSuccessToast } = useToastStore();
  const { formatMessage } = useIntl();

  const [textAreaValue, setTextAreaValue] = useState('');
  const [checkboxValues, setCheckboxValues] = useState<CheckboxValues>({
    notTrue: false,
    notHelpful: false,
    'harmful-unsafe': false,
    tookTooLong: false,
    irrelevant: false,
    incorrectDataOrAnalysis: false,
    technicalIssues: false,
    other: false,
  });

  const { mutate } = useDoraFeedbackMutation();

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxValues({
      ...checkboxValues,
      [e.target.id]: e.target.checked,
    });
  };

  const handleOnSendFeedbackButtonClick = useCallback(() => {
    trackDiscoverAction('ratingSendFeedbackClicked', {
      ...checkboxValues,
      openEndedText: textAreaValue,
    });
    onClose();
    if (promptId) {
      const ratingMap: Record<keyof CheckboxValues, Rating> = {
        notTrue: 'UNTRUE',
        notHelpful: 'UNHELPFUL',
        'harmful-unsafe': 'HARMFUL',
        tookTooLong: 'TOO_LONG',
        irrelevant: 'IRRELEVANT',
        incorrectDataOrAnalysis: 'INCORRECT_DATA_OR_ANALYSIS',
        technicalIssues: 'TECHNICAL_ISSUES',
        other: 'OTHER',
      };

      const ratings = Object.keys(ratingMap)
        .filter((key) => checkboxValues[key as CheckboxKey])
        .map((key) => ratingMap[key as CheckboxKey]);

      mutate({
        ratings: ratings,
        type_is: 'negative',
        prompt_id: promptId,
        feedback: textAreaValue,
      });
    }
    showSuccessToast(formatMessage(messages.feedbackSubmittedMessage));
  }, [
    mutate,
    onClose,
    promptId,
    formatMessage,
    textAreaValue,
    checkboxValues,
    showSuccessToast,
  ]);

  const isDoraChatFeedbackModal = modalSource === 'doraChat';

  return (
    <Modal
      title={formatMessage(
        isDoraChatFeedbackModal
          ? managerInsightsFeedbackMessages.title
          : doraFeedbackMessages.title
      )}
      isOpen
      onClose={onClose}
    >
      <TextStyle variant="sm-regular" className="pb-4 pr-8 text-left">
        {formatMessage(
          isDoraChatFeedbackModal
            ? managerInsightsFeedbackMessages.subTitle
            : doraFeedbackMessages.subTitle
        )}
      </TextStyle>

      <textarea
        rows={3}
        onChange={(e) => setTextAreaValue(e.target.value)}
        placeholder={formatMessage(
          isDoraChatFeedbackModal
            ? managerInsightsFeedbackMessages.inputPlaceholder
            : doraFeedbackMessages.inputPlaceholder
        )}
        className="mb-1 w-full resize-none rounded-lg px-3 py-4 text-sm text-gray-8 placeholder:text-sm focus:!border-primary-6 focus:!ring-primary-6"
      />

      {isDoraChatFeedbackModal ? (
        <div className="flex flex-col">
          <FeedbackRatingOption
            id="tookTooLong"
            onChange={handleCheckboxChange}
            label={formatMessage(managerInsightsFeedbackMessages.tookTooLong)}
          />
          <FeedbackRatingOption
            id="irrelevant"
            onChange={handleCheckboxChange}
            label={formatMessage(managerInsightsFeedbackMessages.irrelevant)}
          />
          <FeedbackRatingOption
            id="incorrectDataOrAnalysis"
            onChange={handleCheckboxChange}
            label={formatMessage(
              managerInsightsFeedbackMessages.incorrectDataOrAnalysis
            )}
          />
          <FeedbackRatingOption
            id="technicalIssues"
            onChange={handleCheckboxChange}
            label={formatMessage(
              managerInsightsFeedbackMessages.technicalIssue
            )}
          />
          <FeedbackRatingOption
            id="other"
            onChange={handleCheckboxChange}
            label={formatMessage(managerInsightsFeedbackMessages.other)}
          />
        </div>
      ) : (
        <div className="flex flex-col">
          <FeedbackRatingOption
            id="harmful-unsafe"
            onChange={handleCheckboxChange}
            label={formatMessage(doraFeedbackMessages.harmful)}
          />
          <FeedbackRatingOption
            id="notTrue"
            onChange={handleCheckboxChange}
            label={formatMessage(doraFeedbackMessages.notTrue)}
          />
          <FeedbackRatingOption
            id="notHelpful"
            onChange={handleCheckboxChange}
            label={formatMessage(doraFeedbackMessages.notHelpful)}
          />
        </div>
      )}
      <section className="mt-3 flex items-center justify-end">
        <Button variation="primary" onClick={handleOnSendFeedbackButtonClick}>
          {formatMessage(messages.sendFeedbackLabel)}
        </Button>
      </section>
    </Modal>
  );
}
