import { motion } from 'framer-motion';

import type { AnimateChatProps } from './types';

export const AnimateChat = (props: AnimateChatProps) => {
  const {
    children,
    className,
    shouldAnimateOnDismount = false,
    shouldAnimateOnMount,
  } = props;

  return (
    <motion.div
      animate={{ height: 'auto' }}
      className={className}
      exit={shouldAnimateOnDismount ? { height: 0 } : undefined}
      initial={shouldAnimateOnMount ? { height: '0' } : undefined}
      transition={{
        duration: 0.3,
      }}
    >
      {children}
    </motion.div>
  );
};
