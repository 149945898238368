import { AnimatePresence } from 'framer-motion';

import type { CustomTimePeriodInputProps } from '../../../../types';
import { Selector } from './Selector';

export const CustomTimePeriodInput = (props: CustomTimePeriodInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useCustomTimePeriodSetting },
  } = props;

  const shouldRequestInput = useCustomTimePeriodSetting(
    (store) => store.shouldRequestInput
  );

  const shouldRequestEdit = useEditSettingsStore(
    (store) => store.customTimePeriod.shouldRequestInput
  );

  return (
    <AnimatePresence>
      {Boolean(shouldRequestInput || shouldRequestEdit) && (
        <Selector {...props} />
      )}
    </AnimatePresence>
  );
};
