import { Button, LoadingSpinner, TextStyle } from '@assembly-web/ui';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextButton } from '../../../components/TextButton';
import { useOpenMergeModal } from '../hooks/useOpenMergeModal';
import { trackAppConnectionAction } from '../services/analytics';
import type { Connection } from '../types';
import { ConnectionsTable } from './ConnectionsTable';

const messages = defineMessages({
  connectAnotherApp: {
    defaultMessage: 'Connect another app',
    id: 'hMI/90',
  },
  connectNewApp: {
    defaultMessage: 'Connect new app',
    id: 'spSZ03',
  },
  learnMore: {
    defaultMessage: 'Learn more',
    id: 'TdTXXf',
  },
  requestApp: {
    defaultMessage: 'Request app',
    id: 'jfL3kz',
  },
  tableDescription: {
    defaultMessage:
      'Connect your important work tools to centralize your work-related information and find things faster.',
    id: 'Lq8AiG',
  },
  tableName: {
    defaultMessage: 'My apps',
    id: 'TnPYbz',
  },
});

export function AppConnections({ connections }: { connections: Connection[] }) {
  const { formatMessage } = useIntl();
  const { fetchLinkToken, isFetchingToken } = useOpenMergeModal();

  // Keep track of each connect button click so that we only show the loading spinner on the one that got clicked on.
  const [isConnectingNewApp, setIsConnectingNewApp] = useState(false);
  const [isConnectingAnotherApp, setIsConnectingAnotherApp] = useState(false);

  const connectedAppNames = connections.map(
    (connection) => connection.integrationName
  );

  const handleConnectApp = () => {
    trackAppConnectionAction('connectAppClicked', {
      connectedAppName: connectedAppNames,
    });
    fetchLinkToken();
  };

  const handleConnectNewAppClicked = () => {
    setIsConnectingNewApp(true);
    handleConnectApp();
  };

  const handleConnectAnotherAppClicked = () => {
    setIsConnectingAnotherApp(true);
    handleConnectApp();
  };

  useEffect(() => {
    if (!isFetchingToken) {
      setIsConnectingAnotherApp(false);
      setIsConnectingNewApp(false);
    }
  }, [isFetchingToken]);

  return (
    <>
      <TextStyle as="span" className="text-gray-9" variant="base-medium">
        {formatMessage(messages.tableName)}
      </TextStyle>
      <div className="mb-2 flex h-full flex-row items-center justify-between max-sm:flex-col max-sm:items-start">
        <div className="flex-1">
          <TextStyle as="span" className="text-gray-9" variant="sm-regular">
            {formatMessage(messages.tableDescription)}{' '}
            <TextButton
              className="text-sm font-normal !text-gray-9"
              onClick={() => {
                window.open(
                  'https://www.joinassembly.com/articles/connecting-apps-to-assembly',
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
              underline
            >
              {formatMessage(messages.learnMore)}
            </TextButton>
          </TextStyle>
        </div>

        <div className="flex gap-x-3 max-sm:mt-2">
          <Button
            className="order-1 max-sm:order-2"
            onClick={() =>
              window.open(
                'https://my.joinassembly.com/e/flows/64ad7d5a68178f0f6120acd5/',
                '_blank',
                'noopener,noreferrer'
              )
            }
            size="small"
            variation="tertiaryLite"
          >
            {formatMessage(messages.requestApp)}
          </Button>
          <Button
            className="order-2 max-sm:order-1"
            isLoading={isFetchingToken}
            onClick={handleConnectNewAppClicked}
            size="small"
            variation="secondaryEmphasized"
          >
            {Boolean(isConnectingNewApp) && <LoadingSpinner />}
            {formatMessage(messages.connectNewApp)}
          </Button>
        </div>
      </div>

      <ConnectionsTable connections={connections} />

      <Button
        className="mx-4 mb-6 mt-4"
        isLoading={isFetchingToken}
        onClick={handleConnectAnotherAppClicked}
        size="small"
        variation="secondaryEmphasized"
      >
        {Boolean(isConnectingAnotherApp) && <LoadingSpinner />}
        {formatMessage(messages.connectAnotherApp)}
      </Button>
    </>
  );
}
