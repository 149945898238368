import { AppLink, TextStyle } from '@assembly-web/ui';
import { type ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import type { createFormSettingsStore } from '../../../../../stores/flowSummaryStore/formSettingsStore';
import { AnimateChat } from '../../../shared/dora/AnimateChat';
import { DoraAvatar } from '../../../shared/dora/DoraAvatar';
import { DoraMessageBubble } from '../../../shared/dora/DoraMessageBubble';
import type { EditSettingsStoreType } from '../../types';

export const IntroMessage = (props: {
  children: ReactNode;
  editSettingsStore: EditSettingsStoreType;
  flowHref: string;
  flowTitle: string;
  formSettingsStore: ReturnType<typeof createFormSettingsStore>;
}) => {
  const {
    children: firstSetting,
    editSettingsStore: useEditSettingsStore,
    flowHref,
    flowTitle,
    formSettingsStore: { useIntroMessage },
  } = props;

  const isIntroMessageShown = useIntroMessage(
    (store) => store.isIntroMessageShown
  );

  const setIsIntroMessageShown = useIntroMessage(
    (store) => store.setIsIntroMessageShown
  );

  useEffect(() => {
    // Delay prompting for input of the first setting after the user has time to read the intro message.
    if (!isIntroMessageShown) {
      setTimeout(() => {
        setIsIntroMessageShown(true);
      }, 1700);
    }
  }, [isIntroMessageShown, setIsIntroMessageShown]);

  const isEditing = useEditSettingsStore((store) =>
    Boolean(store.settingEditing)
  );

  return (
    <>
      <DoraAvatar isFaded={isEditing} shouldAnimate={!isIntroMessageShown} />
      <div className="col-start-2 flex flex-col gap-y-1 self-start">
        <AnimateChat shouldAnimateOnMount={!isIntroMessageShown}>
          <DoraMessageBubble isFaded={isEditing}>
            <TextStyle className="text-gray-9">
              <FormattedMessage
                defaultMessage="Let's get started on your summary of <flow>{flowTitle}</flow>! I'll ask you a few questions to make sure my summary is helpful, and you can always change your answers before I start."
                id="pys+iB"
                values={{
                  flow: (text) => <AppLink to={flowHref}>{text}</AppLink>,
                  flowTitle,
                }}
              />
            </TextStyle>
          </DoraMessageBubble>
        </AnimateChat>
        {firstSetting}
      </div>
    </>
  );
};
