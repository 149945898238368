import type { BlockType } from '@assembly-web/services';
import {
  BlockOptionsDropdown,
  BlocksTable,
  EditorLoader,
} from '@assembly-web/ui';
import camelCase from 'lodash/camelCase';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useBlockIndex,
  useBlockType,
  useCanShowInsertBlockMenu,
  useCreateBlock,
  useGetBlockIsLinked,
  useGivePointsBlockExists,
  useInsertBlock,
  useReplaceBlock,
} from '../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useBlockIdContext } from '../context/BlockIdContext';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';
import { useFlowData } from '../hooks/useFlowData';
import { useShowSwitchTypeConfirmationModal } from '../store/useEditorMiscState';
import {
  ConfirmAddQuestionWhenPostExist,
  ConfirmChangeQuestion,
} from './ConfirmChangeQuestion';

const messages = defineMessages({
  addBlock: {
    defaultMessage: 'Add question',
    id: '0eJKDI',
  },
});

type BlockTypes = ReturnType<typeof useBlockType>;

function Options({ onSelect }: { onSelect?: (block: BlockType) => void }) {
  const { id } = useEditorDataContext();

  const personSelectorExists = useGivePointsBlockExists(id);

  return (
    <>
      <BlockOptionsDropdown.Group>
        <BlockOptionsDropdown.QuestionTypesLabel />
        <BlockOptionsDropdown.OpenEndedItem
          onSelect={() => onSelect?.('OPEN_ENDED')}
        />
        <BlockOptionsDropdown.DropdownItem
          onSelect={() => onSelect?.('DROPDOWN')}
        />
        <BlockOptionsDropdown.ScaleItem onSelect={() => onSelect?.('SCALE')} />
        <BlockOptionsDropdown.MultiChoiceItem
          onSelect={() => onSelect?.('MULTI_CHOICE')}
        />
        <BlockOptionsDropdown.FileUploadItem
          onSelect={() => onSelect?.('FILE_UPLOAD')}
        />
        <BlockOptionsDropdown.PersonSelectItem
          onSelect={() => onSelect?.('PERSON_SELECTOR')}
        />
        <BlockOptionsDropdown.GIFItem onSelect={() => onSelect?.('GIF')} />
      </BlockOptionsDropdown.Group>
      <BlockOptionsDropdown.Group>
        <BlockOptionsDropdown.AdvancedQuestionLabel />
        <BlockOptionsDropdown.GivePointsItem
          onSelect={() => onSelect?.('GIVE_POINTS_STACK')}
          disabled={personSelectorExists}
        />
        <BlockOptionsDropdown.NPSItem onSelect={() => onSelect?.('NPS')} />
      </BlockOptionsDropdown.Group>
    </>
  );
}

export function InsertBlockMenu() {
  const [addBlockType, setAddBlockType] = useState<BlockType | null>(null);
  const { formatMessage } = useIntl();

  const { id, type } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockIndex = useBlockIndex(id, blockId);
  const canShow = useCanShowInsertBlockMenu(id, blockId);
  const insertBlock = useInsertBlock(id, blockIndex);
  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const { data: newestPostsData } = useFlowData(id, type);

  const handleSelection = (block: BlockType) => {
    if (type === 'edit' && newestPostsData?.data.length) {
      setAddBlockType(block);
      return;
    }
    insertBlock(block);
  };

  const handleClose = () => {
    setAddBlockType(null);
  };
  const handleConfirmation = () => {
    if (!addBlockType) {
      return;
    }

    insertBlock(addBlockType);
    setAddBlockType(null);
  };

  if (!canShow || isOccurrenceInProgress) {
    return null;
  }

  return (
    <>
      <BlockOptionsDropdown.Menu
        triggerTooltip={formatMessage(messages.addBlock)}
        trigger={<BlockOptionsDropdown.MenuTrigger variant="icon" />}
      >
        <Options onSelect={handleSelection} />
      </BlockOptionsDropdown.Menu>
      <ConfirmAddQuestionWhenPostExist
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
        open={Boolean(addBlockType)}
      />
    </>
  );
}

export function AddBlockMenu() {
  const [addBlockType, setAddBlockType] = useState<BlockType | null>(null);

  const { id, isLoading, type } = useEditorDataContext();

  const createBlock = useCreateBlock(id);
  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const { data: newestPostsData } = useFlowData(id, type);

  const handleSelection = (block: BlockType) => {
    if (type === 'edit' && newestPostsData?.data.length) {
      setAddBlockType(block);
      return;
    }
    createBlock(block);
  };

  const handleClose = () => {
    setAddBlockType(null);
  };
  const handleConfirmation = () => {
    if (!addBlockType) {
      return;
    }

    createBlock(addBlockType);
    setAddBlockType(null);
  };

  if (isLoading) {
    return <EditorLoader className="h-8 w-28" />;
  }

  return (
    <>
      <BlockOptionsDropdown.Menu
        disabled={isOccurrenceInProgress}
        trigger={<BlockOptionsDropdown.MenuTrigger variant="primary" />}
      >
        <Options onSelect={handleSelection} />
      </BlockOptionsDropdown.Menu>
      <ConfirmAddQuestionWhenPostExist
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
        open={Boolean(addBlockType)}
      />
    </>
  );
}

export function BlockTypeDropdown({
  triggerVariant = 'transparent',
}: {
  triggerVariant?: Parameters<
    typeof BlockOptionsDropdown.Select
  >[0]['triggerVariant'];
}) {
  const [resetKey, setResetKey] = useState(() => window.crypto.randomUUID());
  const [switchToType, setSwitchToType] = useState<BlockTypes>(null);
  const [showSwitchTypeConfirmationModal] = useState(
    useShowSwitchTypeConfirmationModal()
  );

  const { id, type } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useBlockType(id, blockId);
  const isLinkedBlock = useGetBlockIsLinked(id, blockId);
  const isEdit = type === 'edit';

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const replaceBlock = useReplaceBlock(id, blockId);

  const handleChange = (value: string) => {
    if (
      isEdit ||
      showSwitchTypeConfirmationModal ||
      isLinkedBlock ||
      blockType === 'GIVE_POINTS_STACK'
    ) {
      setSwitchToType(value as BlockTypes);
      trackFlowEditorAction('blockTypeSwitched', {
        blockType: camelCase(value),
      });
    } else {
      replaceBlock(value as Exclude<BlockTypes, null>);
      trackFlowEditorAction('blockTypeSwitchConfirmed', {
        blockType: camelCase(value),
      });
    }
  };

  const handleClose = () => {
    setSwitchToType(null);
    setResetKey(window.crypto.randomUUID());
    trackFlowEditorAction('blockTypeSwitchCancelled');
  };
  const handleConfirmation = () => {
    if (!switchToType) {
      return;
    }

    replaceBlock(switchToType);
    trackFlowEditorAction('blockTypeSwitchConfirmed', {
      blockType: camelCase(switchToType),
    });
  };
  const handleClick = () => {
    trackFlowEditorAction('blockTypeDropdownClicked');
  };

  if (!blockType) {
    return null;
  }

  return (
    <>
      <BlocksTable.BlockTypeContainer>
        <BlockOptionsDropdown.Select
          isLinkedBlock={isLinkedBlock || blockType === 'GIVE_POINTS_STACK'}
          defaultValue={blockType}
          onValueChange={handleChange}
          key={resetKey}
          triggerVariant={triggerVariant}
          onClick={handleClick}
          disabled={isOccurrenceInProgress}
        >
          <Options />
        </BlockOptionsDropdown.Select>
      </BlocksTable.BlockTypeContainer>
      <ConfirmChangeQuestion
        handleClose={handleClose}
        handleConfirmation={handleConfirmation}
        open={Boolean(switchToType)}
        confirmationType="change_question"
      />
    </>
  );
}
