import type {
  ActivityEntity,
  NotificationReactionResponse,
  Person,
  Replacement,
  Tasks,
} from '@assembly-web/services';
import { MemberRole, MemberState, TaskState } from '@assembly-web/services';
import type { ToolbarItem } from '@assembly-web/ui';
import { PinIcon, UnpinIcon } from '@assembly-web/ui';
import {
  AdjustmentsHorizontalIcon,
  ArchiveBoxArrowDownIcon,
  ArchiveBoxXMarkIcon,
  ArrowDownIcon,
  ArrowRightOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUpIcon,
  ArrowUpTrayIcon,
  BellAlertIcon,
  BellSlashIcon,
  ChartBarIcon,
  CheckCircleIcon,
  DocumentCheckIcon,
  LinkIcon,
  MegaphoneIcon,
  PencilIcon,
  PlusIcon,
  StopIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';

import type { CardAction } from '../../services/analytics';
import { messages } from './messages';
import type { ToolbarItemsParam } from './type';

export enum ToolbarItemId {
  Follow = 'follow',
  Unfollow = 'unfollow',
  EditFlow = 'edit-flow',
  EditPost = 'edit-post',
  CopyLink = 'copy-link',
  GoToPost = 'go-to-post',
  GoToLink = 'go-to-link',
  EditReply = 'edit-reply',
  GoToFlow = 'go-to-flow',
  ShareLink = 'share-link',
  DeletePost = 'delete-post',
  DeleteTask = 'delete-task',
  MarkAsSeen = 'mark-as-seen',
  GoToMember = 'go-to-member',
  DeleteReply = 'delete-reply',
  ArchiveTask = 'archive-task',
  GoToNotebook = 'go-to-notebook',
  UnarchiveTask = 'unarchive-task',
  DeleteCollection = 'delete-collection',
  ViewFlowInsights = 'view-flow-insights',
  MarkTaskAsComplete = 'mark-task-as-complete',
  CopyLinkForFileCard = 'copy-link-for-file-card',
  GoToPostForFileCard = 'go-to-post-for-file-card',
  MarkTaskAsIncomplete = 'mark-task-as-incomplete',
  AddExternalLink = 'add-external-link',
  AddToCollection = 'add-to-collection',
  EditCollection = 'edit-collection',
  OpenAllLinks = 'open-all-links',
  RemoveFromCollection = 'remove-from-collection',
  ManageInteractionSettings = 'manage-interaction-settings',
  ShareAsAnnouncement = 'share-as-an-announcement',
  EditAnnouncement = 'edit-announcement',
  ViewAnnouncementInsights = 'view-announcement-insights',
  EndAnnouncement = 'end-announcement',
  CopyWebLink = 'copy-web-link',
  EditWebLink = 'edit-web-link',
  ShareCollection = 'share-collection',
  PinCollection = 'pin-collection',
  UnpinCollection = 'unpin-collection',
  PinItemToCollection = 'pin-item-to-collection',
  ArchiveFlow = 'archive-flow',
  MoveCollectionDown = 'move-collection-down',
  MoveCollectionUp = 'move-collection-up',
  MoveCollectionItemDown = 'move-collection-item-down',
  MoveCollectionItemUp = 'move-collection-item-up',
  CopyReplyLink = 'copy-reply-link',
  CopyChallengeReplyLink = 'copy-challenge-reply-link',
  EditChallengeReply = 'edit-challenge-reply-link',
  DeleteChallengeReply = 'delete-challenge-reply-link',
  AddReaction = 'add-reaction',
  PostNotificationPreference = 'post-notification-preference',
  DeleteAssemblyPost = 'delete-assembly-post',
  EndChallenge = 'end-challenge',
  LaunchChallenge = 'launch-challenge',
  GoToChallenge = 'go-to-challenge',
  ViewSettings = 'view-challenge-settings',
  ArchiveChallenge = 'archive-challenge',
  ViewChallengeInsights = 'view-challenge-insights',
  DuplicateChallenge = 'duplicate-challenge',
}

export const defaultRecognitionFlowIcon = '1F389';

export function getPrimaryToolbarItems(filter: ToolbarItemsParam) {
  const { formatMessage } = filter;
  return [
    {
      id: ToolbarItemId.MarkAsSeen,
      icon: DocumentCheckIcon,
      text: formatMessage(messages.markAsSeen),
    },
  ];
}

function getCollectionToolbarItems(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
  } = filter;

  const moveCollectionOptions =
    isCollectionItem && canEditCollection && !(isFirstItem && isLastItem)
      ? [
          {
            id: ToolbarItemId.MoveCollectionItemUp,
            icon: ArrowUpIcon,
            text: formatMessage(messages.moveItemUp),
            disabled: isFirstItem,
          },
          {
            id: ToolbarItemId.MoveCollectionItemDown,
            icon: ArrowDownIcon,
            text: formatMessage(messages.moveItemDown),
            disabled: isLastItem,
          },
        ]
      : [];

  return [
    ...moveCollectionOptions,
    {
      id: ToolbarItemId.PinItemToCollection,
      icon: PinIcon,
      text: formatMessage(messages.pinThisItem),
    },
    {
      id: ToolbarItemId.AddToCollection,
      icon: PlusIcon,
      text: formatMessage(messages.addToCollection),
    },
    isCollectionItem &&
      canEditCollection && {
        id: ToolbarItemId.RemoveFromCollection,
        icon: ArrowRightOnRectangleIcon,
        text: formatMessage(messages.removeFromCollection),
      },
  ].filter(Boolean);
}

function getAnnouncementToolbarItems(filter: ToolbarItemsParam) {
  const {
    formatMessage,
    canEndAnnouncement,
    canShowViewInsights,
    canEditAnnouncement,
  } = filter;

  const toolbarItems = [];

  if (canEditAnnouncement) {
    toolbarItems.push({
      id: ToolbarItemId.EditAnnouncement,
      icon: PencilIcon,
      text: formatMessage(messages.editAnnouncement),
    });
  }

  if (canShowViewInsights) {
    toolbarItems.push({
      id: ToolbarItemId.ViewAnnouncementInsights,
      icon: ChartBarIcon,
      text: formatMessage(messages.viewAnnouncementInsights),
    });
  }

  if (canEndAnnouncement) {
    toolbarItems.push({
      id: ToolbarItemId.EndAnnouncement,
      icon: StopIcon,
      text: formatMessage(messages.endAnnouncement),
    });
  }

  return toolbarItems;
}

export function getToolbarMenuItems(filter: ToolbarItemsParam) {
  const {
    formatMessage,
    isAdmin,
    isReply,
    isFollowingThread,
    showPostInteractionSettings,
  } = filter;
  const primaryToolbarItems = getPrimaryToolbarItems(filter);

  const secondaryToolbarItems: ToolbarItem[] = [
    {
      id: ToolbarItemId.CopyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyLink),
    },
    isFollowingThread
      ? {
          id: ToolbarItemId.Unfollow,
          icon: BellSlashIcon,
          text: formatMessage(messages.unfollowThread),
        }
      : {
          id: ToolbarItemId.Follow,
          icon: BellAlertIcon,
          text: formatMessage(messages.followThread),
        },
  ];

  if (showPostInteractionSettings) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.ManageInteractionSettings,
      icon: AdjustmentsHorizontalIcon,
      text: formatMessage(messages.manageInteractionSettings),
    });
  }

  if (isAdmin) {
    if (isReply) {
      secondaryToolbarItems.push({
        icon: TrashIcon,
        isAlertStyle: true,
        id: ToolbarItemId.DeleteReply,
        text: formatMessage(messages.deleteReply),
      });
    } else {
      secondaryToolbarItems.push({
        icon: TrashIcon,
        isAlertStyle: true,
        id: ToolbarItemId.DeletePost,
        text: formatMessage(messages.deletePost),
      });
    }
  }

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForFlowDueCard(filter: ToolbarItemsParam) {
  const { formatMessage, isFlowOwner } = filter;
  const primaryToolbarItems = getPrimaryToolbarItems(filter);

  const nonOwnerActions = [
    ...primaryToolbarItems,
    {
      id: ToolbarItemId.CopyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyLink),
    },
  ];

  const ownerActions = [
    ...nonOwnerActions,
    {
      id: ToolbarItemId.EditFlow,
      icon: PencilIcon,
      text: formatMessage(messages.editFlow),
    },
    {
      id: ToolbarItemId.ViewFlowInsights,
      icon: ChartBarIcon,
      text: formatMessage(messages.viewFlowInsights),
    },
    {
      id: ToolbarItemId.ArchiveFlow,
      icon: ArchiveBoxArrowDownIcon,
      text: formatMessage(messages.archiveFlow),
      isAlertStyle: true,
    },
  ];

  return {
    primaryToolbarItems,
    secondaryToolbarItems: isFlowOwner ? ownerActions : nonOwnerActions,
  };
}

export function getToolbarMenuItemsForFlowAccessGrantedCard(
  filter: ToolbarItemsParam
) {
  const { formatMessage } = filter;
  const primaryToolbarItems = [
    {
      id: ToolbarItemId.GoToLink,
      icon: ArrowTopRightOnSquareIcon,
      text: formatMessage(messages.goToLink),
    },
    {
      id: ToolbarItemId.MarkAsSeen,
      icon: DocumentCheckIcon,
      text: formatMessage(messages.markAsSeen),
    },
  ];

  return {
    primaryToolbarItems,
    secondaryToolbarItems: primaryToolbarItems,
  };
}

export function getToolbarMenuItemsForFlowCard(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isFlowOwner,
    isLastItem,
    flowStatus,
  } = filter;

  const primaryToolbarItems = [
    {
      id: ToolbarItemId.GoToFlow,
      icon: ArrowTopRightOnSquareIcon,
      text: formatMessage(messages.goToFlow),
    },
  ];

  const nonOwnerActions = [
    ...primaryToolbarItems,
    {
      id: ToolbarItemId.CopyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyLink),
    },
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  const ownerActions = [
    ...nonOwnerActions,
    {
      id: ToolbarItemId.EditFlow,
      icon: PencilIcon,
      text: formatMessage(messages.editFlow),
    },
    {
      id: ToolbarItemId.ViewFlowInsights,
      icon: ChartBarIcon,
      text: formatMessage(messages.viewFlowInsights),
    },
    {
      id: ToolbarItemId.ArchiveFlow,
      icon: ArchiveBoxArrowDownIcon,
      text: formatMessage(messages.archiveFlow),
      isAlertStyle: true,
    },
  ];

  return {
    primaryToolbarItems,
    secondaryToolbarItems:
      flowStatus === 'INACTIVE'
        ? []
        : isFlowOwner
          ? ownerActions
          : nonOwnerActions,
  };
}

export function getToolbarMenuItemsForResponseCard(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    formatMessage,
    isAuthor,
    isCollectionItem,
    isFirstItem,
    isLastItem,
    isReply,
    canEndAnnouncement,
    canEditAnnouncement,
    canShareAsAnnouncement,
    showPostInteractionSettings,
    canShowViewInsights,
    canDeletePost,
    postNotificationPreference,
    canEditReply,
  } = filter;

  const primaryToolbarItems = [
    {
      id: ToolbarItemId.GoToPost,
      icon: ArrowTopRightOnSquareIcon,
      text: formatMessage(messages.goToPost),
    },
  ];

  const secondaryToolbarItems: ToolbarItem[] = [
    {
      id: ToolbarItemId.CopyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyLink),
    },
    ...getAnnouncementToolbarItems({
      formatMessage,
      canEndAnnouncement,
      canEditAnnouncement,
      canShowViewInsights,
    }),
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  if (canShareAsAnnouncement) {
    secondaryToolbarItems.unshift({
      icon: MegaphoneIcon,
      id: ToolbarItemId.ShareAsAnnouncement,
      text: formatMessage(messages.shareAsAnnouncement),
    });
  }

  if (isAuthor) {
    if (!isReply) {
      secondaryToolbarItems.push({
        id: ToolbarItemId.EditPost,
        icon: PencilIcon,
        text: formatMessage(messages.editPost),
      });
    }
  }
  if (isReply && canEditReply) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.EditReply,
      icon: PencilIcon,
      text: formatMessage(messages.editReply),
    });
  }

  if (postNotificationPreference) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.PostNotificationPreference,
      icon: postNotificationPreference.isMuted ? BellAlertIcon : BellSlashIcon,
      text: formatMessage(
        postNotificationPreference.isMuted
          ? messages.followPost
          : messages.unfollowPost
      ),
    });
  }

  if (showPostInteractionSettings) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.ManageInteractionSettings,
      icon: AdjustmentsHorizontalIcon,
      text: formatMessage(messages.manageInteractionSettings),
    });
  }

  if (canDeletePost) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.DeleteAssemblyPost,
      icon: TrashIcon,
      text: formatMessage(messages.deletePost),
      isAlertStyle: true,
    });
  }

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForReplyCard(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
    showPostInteractionSettings,
    isAdmin,
    canEditReply,
  } = filter;

  const secondaryToolbarItems: ToolbarItem[] = [
    {
      id: ToolbarItemId.CopyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyReplyLink),
    },
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  if (canEditReply) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.EditReply,
      icon: PencilIcon,
      text: formatMessage(messages.editReply),
    });
  }

  if (showPostInteractionSettings) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.ManageInteractionSettings,
      icon: AdjustmentsHorizontalIcon,
      text: formatMessage(messages.manageInteractionSettings),
    });
  }

  if (canEditReply || isAdmin) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.DeleteReply,
      icon: TrashIcon,
      isAlertStyle: true,
      text: formatMessage(messages.deleteReply),
    });
  }

  return secondaryToolbarItems;
}

export function getToolbarMenuItemsForChallengeReplyCard(
  filter: ToolbarItemsParam
) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
    showPostInteractionSettings,
    isAdmin,
    canEditReply,
  } = filter;

  const secondaryToolbarItems: ToolbarItem[] = [
    {
      id: ToolbarItemId.CopyChallengeReplyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyReplyLink),
    },
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  if (canEditReply) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.EditChallengeReply,
      icon: PencilIcon,
      text: formatMessage(messages.editReply),
    });
  }

  if (showPostInteractionSettings) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.ManageInteractionSettings,
      icon: AdjustmentsHorizontalIcon,
      text: formatMessage(messages.manageInteractionSettings),
    });
  }

  if (canEditReply || isAdmin) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.DeleteChallengeReply,
      icon: TrashIcon,
      isAlertStyle: true,
      text: formatMessage(messages.deleteReply),
    });
  }

  return secondaryToolbarItems;
}

export function getToolbarMenuItemsForFileCard(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
    isClaimFile,
  } = filter;
  const primaryToolbarItems = [
    {
      icon: ArrowTopRightOnSquareIcon,
      id: ToolbarItemId.GoToPostForFileCard,
      text: formatMessage(isClaimFile ? messages.viewProof : messages.goToPost),
    },
  ];

  const secondaryToolbarItems: ToolbarItem[] = [
    ...primaryToolbarItems,
    {
      icon: LinkIcon,
      id: ToolbarItemId.CopyLinkForFileCard,
      text: formatMessage(messages.copyLink),
    },
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForPeopleCard(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
  } = filter;
  const primaryToolbarItems: ToolbarItem[] = [];

  const secondaryToolbarItems: ToolbarItem[] = [
    ...primaryToolbarItems,
    {
      id: ToolbarItemId.CopyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyLink),
    },
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForTasks(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    cardSource,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
    state,
  } = filter;
  const primaryToolbarItems =
    cardSource === 'activity'
      ? [
          {
            id: ToolbarItemId.MarkAsSeen,
            icon: DocumentCheckIcon,
            text: formatMessage(messages.markAsSeen),
          },
        ]
      : state === TaskState.Active
        ? [
            {
              id: ToolbarItemId.MarkTaskAsComplete,
              icon: CheckCircleIcon,
              text: formatMessage(messages.markTaskAsComplete),
            },
          ]
        : [];

  let secondaryToolbarItems = [];
  if (state === TaskState.Archived) {
    secondaryToolbarItems = [
      ...primaryToolbarItems,
      {
        id: ToolbarItemId.CopyLink,
        icon: LinkIcon,
        text: formatMessage(messages.copyLink),
      },
      ...getCollectionToolbarItems({
        canEditCollection,
        formatMessage,
        isCollectionItem,
        isFirstItem,
        isLastItem,
      }),
      {
        id: ToolbarItemId.UnarchiveTask,
        icon: ArrowUpTrayIcon,
        text: formatMessage(messages.unarchiveTask),
      },
      {
        id: ToolbarItemId.DeleteTask,
        icon: TrashIcon,
        text: formatMessage(messages.deleteTask),
        isAlertStyle: true,
      },
    ];
  } else {
    secondaryToolbarItems = [
      {
        id: ToolbarItemId.GoToNotebook,
        icon: ArrowTopRightOnSquareIcon,
        text: formatMessage(messages.goToNotebook),
      },
      {
        id: ToolbarItemId.CopyLink,
        icon: LinkIcon,
        text: formatMessage(messages.copyLink),
      },
      ...getCollectionToolbarItems({
        canEditCollection,
        formatMessage,
        isCollectionItem,
        isFirstItem,
        isLastItem,
      }),
      {
        id: ToolbarItemId.ArchiveTask,
        icon: ArchiveBoxXMarkIcon,
        text: formatMessage(messages.archiveTask),
        isAlertStyle: true,
      },
    ];
    secondaryToolbarItems.unshift(
      state === TaskState.Completed
        ? {
            id: ToolbarItemId.MarkTaskAsIncomplete,
            icon: CheckCircleIcon,
            text: formatMessage(messages.markTaskAsInComplete),
          }
        : {
            id: ToolbarItemId.MarkTaskAsComplete,
            icon: CheckCircleIcon,
            text: formatMessage(messages.markTaskAsComplete),
          }
    );
    if (cardSource === 'activity') {
      secondaryToolbarItems.unshift(...primaryToolbarItems);
    }
  }

  if (primaryToolbarItems.length === 0) {
    return { secondaryToolbarItems };
  }

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForUrlCards(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
    isChallengeCard,
  } = filter;
  const primaryToolbarItems = [
    {
      id: !isChallengeCard
        ? ToolbarItemId.GoToLink
        : ToolbarItemId.GoToChallenge,
      icon: ArrowTopRightOnSquareIcon,
      text: formatMessage(messages.goToLink),
    },
  ];

  const secondaryToolbarItems: ToolbarItem[] = [
    ...primaryToolbarItems,
    {
      id: ToolbarItemId.CopyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyLink),
    },
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForLinkCards(filter: ToolbarItemsParam) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
  } = filter;
  const primaryToolbarItems = [
    {
      id: ToolbarItemId.GoToLink,
      icon: ArrowTopRightOnSquareIcon,
      text: formatMessage(messages.goToLink),
    },
  ];

  const secondaryToolbarItems: ToolbarItem[] = [
    {
      id: ToolbarItemId.CopyWebLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyLink),
    },
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  if (isCollectionItem) {
    secondaryToolbarItems.splice(1, 0, {
      id: ToolbarItemId.EditWebLink,
      icon: PencilIcon,
      text: formatMessage(messages.editLink),
    });
  }

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForRecognitionReminderCards(
  filter: ToolbarItemsParam
) {
  const { formatMessage } = filter;
  const primaryToolbarItems = [
    {
      id: ToolbarItemId.MarkAsSeen,
      icon: DocumentCheckIcon,
      text: formatMessage(messages.markAsSeen),
    },
  ];

  const secondaryToolbarItems: ToolbarItem[] = [
    ...primaryToolbarItems,
    {
      id: ToolbarItemId.CopyLink,
      icon: LinkIcon,
      text: formatMessage(messages.copyLink),
    },
  ];

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForExternalFileCards(
  filter: ToolbarItemsParam
) {
  const {
    canEditCollection,
    formatMessage,
    isCollectionItem,
    isFirstItem,
    isLastItem,
  } = filter;
  const secondaryToolbarItems: ToolbarItem[] = [
    ...getCollectionToolbarItems({
      canEditCollection,
      formatMessage,
      isCollectionItem,
      isFirstItem,
      isLastItem,
    }),
  ];

  return {
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForCollectionCards(
  filter: ToolbarItemsParam
) {
  const {
    formatMessage,
    canShareCollection,
    canEditCollection,
    showCollectionMoveMenus,
    isPinned,
    isFirstItem,
    isLastItem,
  } = filter;

  const primaryToolbarItems =
    canShareCollection && canEditCollection
      ? [
          {
            id: ToolbarItemId.ShareCollection,
            icon: UserPlusIcon,
            text: formatMessage(messages.shareCollection),
          },
        ]
      : [];

  const secondaryToolbarItems: ToolbarItem[] = canEditCollection
    ? [
        {
          id: ToolbarItemId.EditCollection,
          icon: PencilIcon,
          text: formatMessage(messages.editCollection),
        },
      ]
    : [];

  if (canEditCollection) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.AddExternalLink,
      icon: PlusIcon,
      text: formatMessage(messages.addExternalLink),
    });
  }

  if (showCollectionMoveMenus) {
    secondaryToolbarItems.push(
      {
        id: ToolbarItemId.MoveCollectionUp,
        icon: ArrowUpIcon,
        text: formatMessage(messages.moveCollectionUp),
        disabled: isFirstItem,
      },
      {
        id: ToolbarItemId.MoveCollectionDown,
        icon: ArrowDownIcon,
        text: formatMessage(messages.moveCollectionDown),
        disabled: isLastItem,
      }
    );
  }

  if (isPinned) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.UnpinCollection,
      icon: UnpinIcon,
      text: formatMessage(messages.unpinCollection),
    });
  } else {
    secondaryToolbarItems.push({
      id: ToolbarItemId.PinCollection,
      icon: PinIcon,
      text: formatMessage(messages.pinCollection),
    });
  }

  if (canEditCollection) {
    secondaryToolbarItems.push({
      id: ToolbarItemId.DeleteCollection,
      icon: TrashIcon,
      text: formatMessage(messages.deleteCollection),
      isAlertStyle: true,
    });
  }

  return {
    primaryToolbarItems,
    secondaryToolbarItems,
  };
}

export function getToolbarMenuItemsForImportantSectionChallengeCards(
  args: ToolbarItemsParam
) {
  const { formatMessage } = args;

  const primaryToolbarItems: ToolbarItem[] = [
    {
      id: ToolbarItemId.GoToLink,
      icon: ArrowTopRightOnSquareIcon,
      text: formatMessage(messages.goToLink),
    },
    {
      id: ToolbarItemId.MarkAsSeen,
      icon: DocumentCheckIcon,
      text: formatMessage(messages.markAsSeen),
    },
  ];

  return primaryToolbarItems;
}

export function getUrlPathForCards(
  card: ActivityEntity,
  workspaceSlugPath: string
) {
  const { type } = card;
  const basePath = `/a/${workspaceSlugPath}`;

  switch (type) {
    case 'RECOGNITION_REMINDERS':
      return `${basePath}/post/flow/recognition`;
    case 'FLOW_ACTIVITY':
      return `${basePath}/flows/${card.entity.flowId}`;
    case 'POST_MENTIONS':
    case 'RECOGNITION_DIRECT_REPORT_ACTIVITY':
    case 'BIRTHDAY_CELEBRATION_DIRECT_REPORT_ACTIVITY':
    case 'ANNIVERSARY_CELEBRATION_DIRECT_REPORT_ACTIVITY':
      return `${basePath}/post/flow/recognition?postId=${card.entity.postId}&showReplies=true&type=recognition`;
    case 'OWNER_POST_REPLY':
    case 'POST_THREAD_REPLIES':
      return `${basePath}/post/flow/recognition?postId=${card.entity.post.postId}&commentId=${card.entity.lastReply.commentId}`;
    case 'RESPONSE_MENTIONS':
      return `${basePath}/post/flow/flows?flowId=${card.entity.flowId}&responseId=${card.entity.responseId}&showReplies=true&type=flow`;
    case 'OWNER_RESPONSE_REPLY':
      return `${basePath}/post/flow/flows?flowId=${card.entity.flowId}&responseId=${card.entity.response.responseId}&commentId=${card.entity.lastReply.commentId}`;
    case 'DIRECT_REPORT_ACTIVITY':
      return `${basePath}/post/flow/flows?flowId=${card.entity.flowId}`;
    case 'BIRTHDAY_CELEBRATION_ACTIVITY':
    case 'ANNIVERSARY_CELEBRATION_ACTIVITY':
      return card.entity.others === 0
        ? `${basePath}/post/flow/recognition?postId=${card.entity.postId}&showReplies=true&type=recognition`
        : `${basePath}/flows/recognition`;
    case 'TASK_ACTIVITIES':
      return `${basePath}/notebook/board?taskId=${card.entity.taskId}`;
    case 'RESPONSE_THREAD_REPLIES':
      return `${basePath}/post/flow/flows?flowId=${card.entity.flowId}&responseId=${card.entity.response.responseId}&commentId=${card.entity.lastReply.commentId}`;
    case 'OWNER_CHALLENGE_REPLY':
    case 'CHALLENGE_THREAD_REPLIES':
      return `${basePath}/a/discover?${new URLSearchParams([
        ['filter', 'challenges'],
        ['commentId', card.entity.lastReply.commentId],
        ['challengeId', card.entity.challenge.challengeId],
      ])}`;
    case 'FLOW_ACCESS_REQUEST':
      return '';
    default:
      return '';
  }
}

export function getCardReplacementPatterns(persons: Person[], tasks?: Tasks[]) {
  let replacements: Replacement[] = [];
  if (persons.length > 0) {
    replacements = persons.map((person) => ({
      value: person.name,
      key: person.memberId,
    }));
  }
  if (tasks && tasks.length > 0) {
    replacements = [
      ...replacements,
      ...tasks.map((task) => ({
        value: task.title[0].value,
        key: task.taskId,
      })),
    ];
  }
  return replacements;
}

export const convertPersonsToMentions = (user: {
  memberId: string;
  name: string;
}) => {
  return {
    firstName: user.name.split(' ')[0],
    lastName: user.name.split(' ')[1],
    memberState: MemberState.Active,
    memberId: user.memberId,
  };
};

export const ReplyToolBarAction: Record<string, CardAction> = {
  [ToolbarItemId.AddToCollection]: 'addToCollection',
  [ToolbarItemId.RemoveFromCollection]: 'removeFromCollection',
  [ToolbarItemId.PinItemToCollection]: 'pinItemClicked',
  [ToolbarItemId.EditReply]: 'editReplyClicked',
  [ToolbarItemId.DeleteReply]: 'deleteReplyClicked',
  [ToolbarItemId.CopyLink]: 'replyLinkCopied',
};

export const convertNotificationReactionsToReactionsDetails = (
  reactions: NotificationReactionResponse[]
) => {
  return reactions.map((reaction) => {
    return {
      ...reaction,
      name: reaction.name,
      displayName: reaction.displayName,
      members: reaction.members.map((item) => {
        return {
          name: item.name,
          firstName: item.name,
          lastName: item.name,
          username: item.name,
          memberID: 'memberId' in item ? item.memberId : item.memberID,
          memberState: MemberState.Active,
          role: [MemberRole.Admin],
          pointsGiven: 0,
          totalPointsGiven: 0,
        };
      }),
    };
  });
};
