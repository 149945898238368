import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

export enum ProfileDataType {
  HomeLocation = 'HOME_LOCATION',
  WorkLocation = 'WORK_LOCATION',
  JobTitle = 'JOB_TITLE',
}

export type ProfileDataAPIResponse = {
  data: [
    {
      key: ProfileDataType.HomeLocation;
      values: string[];
    },
    {
      key: ProfileDataType.WorkLocation;
      values: string[];
    },
    {
      key: ProfileDataType.JobTitle;
      values: string[];
    },
  ];
};

function fetchProfileData(
  enabled: boolean
): UseQueryOptions<ProfileDataAPIResponse> {
  return {
    enabled,
    queryKey: ['profileData'],
    queryFn: async () => {
      const response = await assemblyAPI.post<ProfileDataAPIResponse>(
        APIEndpoints.postProfileData,
        {
          keywords: [
            ProfileDataType.HomeLocation,
            ProfileDataType.WorkLocation,
            ProfileDataType.JobTitle,
          ],
        }
      );
      return response.data;
    },
  };
}

export function usePostProfileData(
  enabled: boolean
): UseQueryResult<ProfileDataAPIResponse> {
  return useQuery(fetchProfileData(enabled));
}
