import { create } from 'zustand';

type scrollPersistenceState = {
  scrollPosition: Map<string, ScrollToOptions>;
  updateScrollPosition: (id: string, scrollPosition: ScrollToOptions) => void;
};

export const useScrollPersistence = create<scrollPersistenceState>((set) => ({
  scrollPosition: new Map(),
  updateScrollPosition: (id, scrollPosition) => {
    set((state) => {
      state.scrollPosition.set(id, scrollPosition);
      return state;
    });
  },
}));
