import { zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type {
  BlocksGroup,
  FocusType,
  IndividualFlowBlock,
  IndividualFlowRespondent,
  RespondentsGroup,
  TimePeriodParams,
} from '../../components/Drawers/FlowSummaryDrawer/types';

type SettingStore<T> = {
  isAnswerSeen: boolean;
  isInputSeen: boolean;
  isQuestionSeen: boolean;
  isSet: boolean;
  markAnswerSeen: () => void;
  markInputSeen: () => void;
  markQuestionSeen: () => void;
  markSet: () => void;
  reset: () => void;
  setShouldRequestInput: (shouldRequestInput: boolean) => void;
  setValue: (value: T | undefined) => void;
  shouldRequestInput: boolean;
  value?: T;
};

type EditableSetting =
  | 'blocksGroup'
  | 'individualBlocks'
  | 'individualRespondents'
  | 'respondentsGroup'
  | 'focusType'
  | 'customFocus'
  | 'predefinedTimePeriod'
  | 'customTimePeriod';

type SettingState<T> = {
  isAnswerSeen: boolean;
  isInputSeen: boolean;
  isQuestionSeen: boolean;
  isSet: boolean;
  shouldRequestInput: boolean;
  value?: T;
};

type EditSettingsStore = {
  blocksGroup: SettingState<BlocksGroup>;
  customFocus: SettingState<string>;
  customTimePeriod: SettingState<TimePeriodParams>;
  exitEditMode: () => void;
  focusType: SettingState<FocusType>;
  individualBlocks: SettingState<IndividualFlowBlock[]>;
  individualRespondents: SettingState<IndividualFlowRespondent[]>;
  isEditingBlocks: () => boolean;
  isEditingFocus: () => boolean;
  isEditingRespondents: () => boolean;
  isEditingTimePeriod: () => boolean;
  markInputSeen: (targetSetting: EditableSetting) => void;
  markQuestionSeen: (targetSetting: EditableSetting) => void;
  markSet: (targetSetting: EditableSetting) => void;
  predefinedTimePeriod: SettingState<TimePeriodParams>;
  reset: () => void;
  respondentsGroup: SettingState<RespondentsGroup>;
  setSettingEditing: (targetSetting?: EditableSetting) => void;
  setShouldRequestInput: (
    targetSetting: EditableSetting,
    shouldRequestInput: boolean
  ) => void;
  setValue: <T>(targetSetting: EditableSetting, value: T) => void;
  settingEditing?: EditableSetting;
};

const createSettingStore = <T>(persistKey: string) =>
  create<SettingStore<T>>()(
    persist(
      (set) => ({
        isAnswerSeen: false,
        isInputSeen: false,
        isQuestionSeen: false,
        isSet: false,
        shouldRequestInput: false,
        value: undefined,

        markAnswerSeen: () => {
          set({ isAnswerSeen: true });
        },
        markInputSeen: () => {
          set({ isInputSeen: true });
        },
        markQuestionSeen: () => {
          set({ isQuestionSeen: true });
        },
        markSet: () => {
          set({ isSet: true });
        },
        reset: () => {
          set({
            isAnswerSeen: false,
            isInputSeen: false,
            isQuestionSeen: false,
            isSet: false,
            shouldRequestInput: false,
            value: undefined,
          });
        },
        setShouldRequestInput: (shouldRequestInput) => {
          set({ shouldRequestInput });
        },
        setValue: (value) => {
          set({ value });
        },
      }),
      {
        name: persistKey,
        storage: createJSONStorage(() => zustandLocalStorage),
      }
    )
  );

const createSettingState = () => ({
  isAnswerSeen: false,
  isInputSeen: false,
  isQuestionSeen: false,
  isSet: false,
  shouldRequestInput: false,
  value: undefined,
});

const createEmptySettings = () => ({
  blocksGroup: createSettingState(),
  customFocus: createSettingState(),
  customTimePeriod: createSettingState(),
  focusType: createSettingState(),
  respondentsGroup: createSettingState(),
  individualBlocks: createSettingState(),
  individualRespondents: createSettingState(),
  predefinedTimePeriod: createSettingState(),
});

export const createEditSettingsStore = () => {
  return create<EditSettingsStore>()(
    immer<EditSettingsStore>((set, get) => ({
      ...createEmptySettings(),

      exitEditMode: () => {
        const { reset, setSettingEditing } = get();

        setSettingEditing();
        reset();
      },

      isEditingBlocks: () => {
        const { settingEditing } = get();

        return (
          settingEditing === 'blocksGroup' ||
          settingEditing === 'individualBlocks'
        );
      },

      isEditingFocus: () => {
        const { settingEditing } = get();

        return (
          settingEditing === 'focusType' || settingEditing === 'customFocus'
        );
      },

      isEditingRespondents: () => {
        const { settingEditing } = get();

        return (
          settingEditing === 'respondentsGroup' ||
          settingEditing === 'individualRespondents'
        );
      },

      isEditingTimePeriod: () => {
        const { settingEditing } = get();

        return (
          settingEditing === 'predefinedTimePeriod' ||
          settingEditing === 'customTimePeriod'
        );
      },

      markInputSeen: (targetSetting: EditableSetting) => {
        set((state) => {
          state[targetSetting].isInputSeen = true;
        });
      },

      markSet: (targetSetting: EditableSetting) => {
        set((state) => {
          state[targetSetting].isSet = true;
        });
      },

      markQuestionSeen: (targetSetting: EditableSetting) => {
        set((state) => {
          state[targetSetting].isQuestionSeen = true;
        });
      },

      reset: () => {
        set(createEmptySettings());
      },

      setSettingEditing: (targetSetting?: EditableSetting) => {
        set({ settingEditing: targetSetting });
      },

      setShouldRequestInput: (
        targetSetting: EditableSetting,
        shouldRequestInput: boolean
      ) => {
        set((state) => {
          state[targetSetting].shouldRequestInput = shouldRequestInput;
        });
      },

      setValue: <T>(targetSetting: EditableSetting, value: T) => {
        set((state) => {
          (state[targetSetting] as SettingState<T>).value = value;
        });
      },
    }))
  );
};

export const createFormSettingsStore = (persistKey: string) => {
  const useBlocksGroupSetting = createSettingStore<BlocksGroup>(
    `${persistKey}-blocksGroup`
  );

  const useCustomFocusSetting = createSettingStore<string>(
    `${persistKey}-customFocus`
  );

  const useCustomTimePeriodSetting = createSettingStore<TimePeriodParams>(
    `${persistKey}-customTimePeriod`
  );

  const useIndividualBlocksSetting = createSettingStore<IndividualFlowBlock[]>(
    `${persistKey}-individualBlocks`
  );

  const useFocusTypeSetting = createSettingStore<FocusType>(
    `${persistKey}-focusType`
  );

  const usePredefinedTimePeriodSetting = createSettingStore<TimePeriodParams>(
    `${persistKey}-predefinedTimePeriod`
  );

  const useRespondentsGroupSetting = createSettingStore<RespondentsGroup>(
    `${persistKey}-respondentsGroup`
  );

  const useSettingsConfirmationSetting = createSettingStore<boolean>(
    `${persistKey}-settingsConfirmation`
  );

  const useIndividualRespondentsSetting = createSettingStore<
    IndividualFlowRespondent[]
  >(`${persistKey}-individualRespondents`);

  const useIntroMessage = create<{
    isIntroMessageShown: boolean;
    setIsIntroMessageShown: (isShown: boolean) => void;
  }>()(
    persist(
      (set) => ({
        isIntroMessageShown: false,

        setIsIntroMessageShown: (isShown) => {
          set({ isIntroMessageShown: isShown });
        },
      }),
      { name: persistKey }
    )
  );

  const useFeedbackMessage = create<{
    isFeedbackMessageShown: boolean;
    setIsFeedbackMessageShown: (isShown: boolean) => void;
  }>()(
    persist(
      (set) => ({
        isFeedbackMessageShown: false,

        setIsFeedbackMessageShown: (isShown) => {
          set({ isFeedbackMessageShown: isShown });
        },
      }),
      { name: persistKey }
    )
  );

  const clearStorage = () => {
    useIntroMessage.persist.clearStorage();
    useCustomFocusSetting.persist.clearStorage();
    useBlocksGroupSetting.persist.clearStorage();
    useCustomTimePeriodSetting.persist.clearStorage();
    useIndividualBlocksSetting.persist.clearStorage();
    useFocusTypeSetting.persist.clearStorage();
    useIndividualRespondentsSetting.persist.clearStorage();
    useRespondentsGroupSetting.persist.clearStorage();
    usePredefinedTimePeriodSetting.persist.clearStorage();
    useSettingsConfirmationSetting.persist.clearStorage();
    useFeedbackMessage.persist.clearStorage();
  };

  const hideActiveInput = () => {
    useCustomFocusSetting.getState().setShouldRequestInput(false);
    useCustomFocusSetting.setState({ isInputSeen: false });
    useBlocksGroupSetting.getState().setShouldRequestInput(false);
    useBlocksGroupSetting.setState({ isInputSeen: false });
    useCustomTimePeriodSetting.getState().setShouldRequestInput(false);
    useCustomTimePeriodSetting.setState({ isInputSeen: false });
    useIndividualBlocksSetting.getState().setShouldRequestInput(false);
    useIndividualBlocksSetting.setState({ isInputSeen: false });
    useFocusTypeSetting.getState().setShouldRequestInput(false);
    useFocusTypeSetting.setState({ isInputSeen: false });
    useIndividualRespondentsSetting.getState().setShouldRequestInput(false);
    useIndividualRespondentsSetting.setState({ isInputSeen: false });
    useRespondentsGroupSetting.getState().setShouldRequestInput(false);
    useRespondentsGroupSetting.setState({ isInputSeen: false });
    usePredefinedTimePeriodSetting.getState().setShouldRequestInput(false);
    usePredefinedTimePeriodSetting.setState({ isInputSeen: false });
    useSettingsConfirmationSetting.getState().setShouldRequestInput(false);
    useSettingsConfirmationSetting.setState({ isInputSeen: false });
  };

  const repromptTimePeriod = () => {
    usePredefinedTimePeriodSetting.getState().reset();
    useCustomTimePeriodSetting.getState().reset();
  };

  const resetSettings = () => {
    useIntroMessage.getState().setIsIntroMessageShown(false);
    useCustomFocusSetting.getState().reset();
    useBlocksGroupSetting.getState().reset();
    useCustomTimePeriodSetting.getState().reset();
    useIndividualBlocksSetting.getState().reset();
    useFocusTypeSetting.getState().reset();
    useIndividualRespondentsSetting.getState().reset();
    useRespondentsGroupSetting.getState().reset();
    usePredefinedTimePeriodSetting.getState().reset();
    useSettingsConfirmationSetting.getState().reset();
    useFeedbackMessage.getState().setIsFeedbackMessageShown(false);
  };

  return {
    clearStorage,
    hideActiveInput,
    repromptTimePeriod,
    resetSettings,
    useBlocksGroupSetting,
    useCustomFocusSetting,
    useCustomTimePeriodSetting,
    useFeedbackMessage,
    useFocusTypeSetting,
    useIndividualBlocksSetting,
    useIndividualRespondentsSetting,
    useIntroMessage,
    usePredefinedTimePeriodSetting,
    useRespondentsGroupSetting,
    useSettingsConfirmationSetting,
  };
};
