import { classNames, TextStyle, useAssemblyNavigate } from '@assembly-web/ui';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackRegistrationAction } from '../../../../services/analytics';

const messages = defineMessages({
  cardTitle: {
    defaultMessage: 'Create new',
    id: '5WK7jL',
  },
  cardDescription: {
    defaultMessage: 'Start from scratch',
    id: 'fGhwMp',
  },
  getStartedCTA: {
    defaultMessage: 'Get started',
    id: '/aBLH2',
  },
});

export function CreateWorkspaceCard({
  email,
  hasNoWorkspaces,
}: {
  email?: string;
  hasNoWorkspaces: boolean;
}) {
  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();

  const onCreateWorkspace = useCallback(
    function () {
      trackRegistrationAction('createNewWorkspaceClicked', {
        email,
      });
      navigate('/onboarding/owner-details');
    },
    [navigate, email]
  );

  return (
    <button
      data-testid="createWorkspaceCard"
      className={classNames(
        'group flex h-20 w-full rounded-md border-[1px]',
        {
          'border-gray-4': !hasNoWorkspaces,
          'border-primary-6': hasNoWorkspaces,
        },
        'hover:border-primary-6 hover:bg-primary-1 hover:text-primary-6 focus:bg-primary-1'
      )}
      onClick={() => onCreateWorkspace()}
    >
      <div
        className={classNames(
          'flex h-full w-20 flex-col items-center justify-center rounded-bl-md rounded-tl-md align-middle',
          {
            'bg-gray-3': !hasNoWorkspaces,
          },
          'group-hover:bg-primary-2 group-focus:bg-primary-3'
        )}
      >
        <PlusIcon
          className={classNames(
            'h-14 w-14 group-hover:text-primary-6 group-focus:text-primary-6',
            { 'text-primary-6': hasNoWorkspaces }
          )}
        />
      </div>

      <div
        className={classNames(
          'flex h-full w-60 flex-1 flex-col justify-center px-4 text-left group-hover:text-primary-6 group-focus:text-primary-6',
          { 'text-primary-6': hasNoWorkspaces }
        )}
      >
        <TextStyle variant="base-medium">
          {formatMessage(messages.cardTitle)}
        </TextStyle>
        <TextStyle
          subdued
          variant="xs-medium"
          className={classNames(
            'group-hover:text-primary-6 group-focus:text-primary-6',
            {
              '!text-inherit': hasNoWorkspaces,
            }
          )}
        >
          {formatMessage(messages.cardDescription)}
        </TextStyle>
      </div>
      <div className="flex h-full w-28 flex-col items-center justify-center align-middle text-primary-6 hover:text-primary-5">
        {!hasNoWorkspaces && (
          <TextStyle variant="sm-regular">
            {formatMessage(messages.getStartedCTA)}
          </TextStyle>
        )}
        {Boolean(hasNoWorkspaces) && (
          <ChevronRightIcon
            className={classNames(
              'h-8 w-8 group-hover:text-primary-6 group-focus:text-primary-6',
              { 'text-primary-6': hasNoWorkspaces }
            )}
          />
        )}
      </div>
    </button>
  );
}
