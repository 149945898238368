import type { GlobalFilterOption } from '@assembly-web/ui';
import { useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useGetCollectionsQuery } from '../../hooks/useGetCollectionsQuery';
import { BaseCollectionModal } from './BaseCollectionModal';

const messages = defineMessages({
  header: {
    defaultMessage: 'Pin a Collection',
    id: '2f2JRH',
  },
  content: {
    defaultMessage:
      'Pin an existing collection or create a new one. Pinned collections will allow their contents to be easily accessible from your home page.',
    id: 'ZqdzIK',
  },
  chooseCollection: {
    defaultMessage: 'Choose an existing collection',
    id: 'XczYAd',
  },
  pinCollection: {
    defaultMessage: 'Pin this collection',
    id: '+gE5gk',
  },
  pinning: {
    defaultMessage: 'Pinning this collection',
    id: '2ApYhu',
  },
});

type AddToPinnedCollectionModalProps = {
  filter: GlobalFilterOption;
  onClose: () => void;
  onCreateCollection: () => void;
  open: boolean;
  searchTerm: string;
};

export function PinExistingCollectionModal(
  props: AddToPinnedCollectionModalProps
) {
  const { onCreateCollection, onClose, open, filter, searchTerm } = props;
  const { formatMessage } = useIntl();

  const { data: existingCollections, pinnedCollections } =
    useGetCollectionsQuery({ enabled: open });

  const unpinnedCollections = useRef(existingCollections?.data);

  // filter collections
  useEffect(() => {
    const pinnedCollectionIds = pinnedCollections.map(
      (collection) => collection.collectionId
    );
    const filteredUnpinned =
      existingCollections?.data.filter((collection) => {
        return !pinnedCollectionIds.includes(collection.collectionId);
      }) ?? [];
    const orderedUnpinned = Array.from(filteredUnpinned).sort((a, b) => {
      return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
    });
    unpinnedCollections.current = orderedUnpinned;
  }, [existingCollections, pinnedCollections]);

  return (
    <BaseCollectionModal
      onCreateCollection={onCreateCollection}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.header)}
      bodyText={formatMessage(messages.content)}
      collectionLabel={formatMessage(messages.chooseCollection)}
      ctaText={formatMessage(messages.pinCollection)}
      ctaLoadingText={formatMessage(messages.pinning)}
      filter={filter}
      itemToAdd={null}
      searchTerm={searchTerm}
      collections={unpinnedCollections.current}
    />
  );
}
