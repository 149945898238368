import type { RespondentsGroupQuestionProps } from '../../../../types';
import { Message } from './Message';

export const RespondentsGroupQuestion = (
  props: RespondentsGroupQuestionProps
) => {
  const {
    formSettingsStore: { useIntroMessage },
  } = props;

  const isIntroMessageShown = useIntroMessage(
    (store) => store.isIntroMessageShown
  );

  return isIntroMessageShown && <Message {...props} />;
};
