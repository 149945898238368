import { useGetFlowBuilderDetailsById } from '../../../../hooks/flows/useGetFlowBuilderDetailsById';
import { useEditorDataContext } from '../context/EditorDataContext';

export const useAnyOccurrenceInProgress = () => {
  const { id, type } = useEditorDataContext();
  const { data, fetchStatus, status } = useGetFlowBuilderDetailsById(id, {
    enabled: type === 'edit',
    select: (data) => data.hasActiveOccurrence,
  });

  const isLoading = fetchStatus === 'fetching' && status === 'loading';

  if (isLoading || !data) {
    return false;
  }

  return data;
};
