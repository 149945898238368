import type { TrackEventProps } from '../../../services/analytics';
import { trackAction } from '../../../services/analytics';

const eventName = 'app.connections.events';

export function trackAppConnectionAction(
  action:
    | 'connectAppClicked'
    | 'disconnectAppClicked'
    | 'getExtensionClicked'
    | 'learnMoreClicked'
    | 'manageAppsOpened',
  customProps?: TrackEventProps
) {
  return trackAction({
    eventName,
    eventProps: {
      action,
      event: 'action',
      ...customProps,
    },
  });
}

export function trackAppConnectionError(
  errorType: 'connectAppError' | 'disconnectAppError' | 'openMergeModalError',
  customProps?: TrackEventProps
) {
  return trackAction({
    eventName,
    eventProps: {
      errorType,
      event: 'error',
      ...customProps,
    },
  });
}

export function trackAppConnectionsShow(customProps?: TrackEventProps) {
  return trackAction({
    eventName,
    eventProps: {
      event: 'show',
      ...customProps,
    },
  });
}
