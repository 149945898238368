import { Button, EditorLoader, TextStyle, Tooltip } from '@assembly-web/ui';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessage, defineMessages, useIntl } from 'react-intl';

import {
  useGetFlowCollaborators,
  useGetFlowOwner,
} from '../../../../../../stores/useFlowBuilderStore';
import { useMultiDrawerStore } from '../../../../../../stores/useMultiDrawerStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';

const messages = defineMessages({
  owner: {
    defaultMessage: 'Owner',
    id: 'zINlao',
  },
  collaborators: {
    defaultMessage: 'Collaborators',
    id: '9Jstnt',
  },
  noCollaborators: {
    defaultMessage: 'No collaborators have been added yet',
    id: 'QVjBNM',
  },
});

function Owner() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();

  const owner = useGetFlowOwner(id);

  return (
    <div className="flex flex-1 flex-col gap-1">
      <TextStyle as="span" variant="sm-medium">
        {formatMessage(messages.owner)}
      </TextStyle>
      <TextStyle as="span" variant="sm-regular">
        {owner?.name ?? <EditorLoader className="h-5" />}
      </TextStyle>
    </div>
  );
}

function Collaborators() {
  const measureRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const { formatMessage } = useIntl();

  const { id, isLoading } = useEditorDataContext();

  const collaborators = useGetFlowCollaborators(id);

  useEffect(() => {
    function handler() {
      const fullWidth = textRef.current?.getBoundingClientRect().width ?? 0;
      const displayWidth =
        textRef.current?.parentElement?.getBoundingClientRect().width ?? 0;

      setShowTooltip(fullWidth > displayWidth);
    }

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
      textRef.current?.remove();
    };
  }, []);

  const tooltipText = collaborators
    ?.map((collaborator) => collaborator.name)
    .join(', ');

  const textMountCallback = useCallback((e: HTMLParagraphElement | null) => {
    if (!e) {
      if (textRef.current) {
        textRef.current.remove();
        textRef.current = null;
      }
      return;
    }
    textRef.current = e.cloneNode(true) as HTMLParagraphElement;

    textRef.current.className = '';
    textRef.current.style.position = 'fixed';
    textRef.current.style.overflow = 'visible';
    textRef.current.style.whiteSpace = 'nowrap';
    textRef.current.style.visibility = 'hidden';

    e.parentNode?.appendChild(textRef.current);

    const fullWidth = textRef.current.getBoundingClientRect().width;
    const displayWidth = e.getBoundingClientRect().width;

    setShowTooltip(fullWidth > displayWidth);
  }, []);

  return (
    <div ref={measureRef} className="flex min-w-0 flex-1 flex-col gap-1">
      <TextStyle as="span" variant="sm-medium">
        {formatMessage(messages.collaborators)}
      </TextStyle>
      {isLoading ? (
        <EditorLoader className="h-5" />
      ) : collaborators && collaborators.length > 0 ? (
        <Tooltip tooltipText={showTooltip ? tooltipText : undefined}>
          <TextStyle
            as="span"
            variant="sm-regular"
            className="truncate"
            ref={textMountCallback}
            key={collaborators
              .map((collaborator) => collaborator.email)
              .join('')}
          >
            {collaborators.map((collaborator) => collaborator.name).join(', ')}
          </TextStyle>
        </Tooltip>
      ) : (
        <TextStyle variant="sm-italic">
          {formatMessage(messages.noCollaborators)}
        </TextStyle>
      )}
    </div>
  );
}

export function FlowOwnerAndCollaborators() {
  const { formatMessage } = useIntl();

  const { id, isLoading } = useEditorDataContext();
  const updateDrawer = useMultiDrawerStore((store) => store.updateDrawerField);

  const occurrenceInProgress = useAnyOccurrenceInProgress();

  const handleClick = () => {
    trackFlowEditorAction('editOwnerCollaboratorClicked');
    updateDrawer(id, (state) => {
      if (state.type !== 'flowCreation') return state;
      state.data.view = 'permissions';
    });
  };

  return (
    <div className="flex flex-col gap-6 @[700px]/body:flex-row @[700px]/body:items-center @[700px]/body:gap-2">
      <div className="flex min-w-0 flex-1 flex-col gap-6 @[700px]/body:flex-row @[700px]/body:gap-2">
        <Owner />
        <Collaborators />
      </div>
      <Button
        className="w-max @[700px]/body:self-end"
        variation="secondaryEmphasized"
        size="large"
        onClick={handleClick}
        disabled={isLoading || occurrenceInProgress}
      >
        <PencilIcon className="h-4 w-4" />
        {formatMessage(
          defineMessage({
            defaultMessage: 'Edit Owners & Collaborators',
            id: 'cdM3t3',
          })
        )}
      </Button>
    </div>
  );
}
