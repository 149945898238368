import { defineMessages } from 'react-intl';

import { useFlowBuilderStore } from '../../../../../stores/useFlowBuilderStore';
import type { DrawerConfig } from '../configuration';
import type {
  FlowCreationData,
  FlowCreationDrawer as FlowCreationDrawerProps,
} from '../types';
import { FlowCreationDrawer } from '.';
import { FlowCreationDrawerBody } from './FlowCreationDrawerBody';
import { FlowCreationDrawerHeader } from './FlowCreationDrawerHeader';
import { FlowCreationDrawerOverflowCard } from './FlowCreationDrawerOverflowCard';

const messages = defineMessages({
  title: {
    defaultMessage: 'Exit flow editor?',
    id: 'rirqsR',
  },
  description: {
    defaultMessage:
      "You will lose all progress or unsaved changes by closing this window. To continue working on your flow, click 'Cancel'.",
    id: 'jFi0xp',
  },
  cta: {
    defaultMessage: 'Leave',
    id: 'fnihsY',
  },
});

export const flowCreationConfig = {
  drawer: FlowCreationDrawer,
  body: FlowCreationDrawerBody,
  header: FlowCreationDrawerHeader,
  overflowCard: FlowCreationDrawerOverflowCard,
  allowMultipleInstance: false,
  resetOnCreate: false,
  getId: (data) => data.id,
  shouldConfirmOnClose: (props) => {
    const { data } = props;

    return data.view !== 'empty';
  },
  onClose: ({ id }) => {
    useFlowBuilderStore.getState().deleteFlow(id);
  },
  confirmOnCloseCopy: {
    title: messages.title,
    description: messages.description,
    cta: messages.cta,
  },
} satisfies DrawerConfig<FlowCreationDrawerProps, FlowCreationData>;
