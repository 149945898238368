import type { DoraReportingCategory } from '@assembly-web/services';
import { zustandLocalStorage } from '@assembly-web/services';
import type { Draft } from 'immer';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import type { Drawer } from '../components/Drawers/types';

type QuestionAnswersBlock = {
  errorType?: string;
  isAnswerSeen?: boolean;
  isErrorSeen?: boolean;
  isLoading?: boolean;
  isLoadingSeen?: boolean;
  isSecondaryLoading?: boolean;
  isStreaming?: boolean;
  markdownResps?: string[];
  prompt: string;
};

export type DoraChatDrawerData = Draft<{
  prompt: string;
  isDefaultTitle: boolean;
  threadId: string;
}>;

type DoraChatState = {
  hasSeenMissingDataBanner?: boolean;
  markMissingDataBannerSeen: () => void;
  activeMode: 'reportingInsights';
  reportingInsights?: {
    activeCategory?: DoraReportingCategory;
    recentBlock?: QuestionAnswersBlock;
    threads?: { id?: string; category: DoraReportingCategory }[];
  };
  addPrompt: (prompt: string) => void;
  endMessageResponse: () => void;
  closeStream: () => void;
  markAnswerSeen: () => void;
  markErrorSeen: () => void;
  markLoadingSeen: () => void;
  resetBlock: () => void;
  saveThread: (threadId: string, category: DoraReportingCategory) => void;
  setErrorType: (errorType: string) => void;
  setReportingCategory: (category: DoraReportingCategory) => void;
  showSecondaryLoading: () => void;
  updateResponse: (response: string, isNewMessage?: boolean) => void;
};

const defaultState: Pick<
  DoraChatState,
  'hasSeenMissingDataBanner' | 'activeMode'
> = {
  hasSeenMissingDataBanner: false,
  activeMode: 'reportingInsights',
};

export function createDoraDrawerStore(persistKey: string) {
  return create<DoraChatState>()(
    persist(
      immer<DoraChatState>((set) => ({
        ...defaultState,

        markMissingDataBannerSeen: () => {
          set((store) => {
            store.hasSeenMissingDataBanner = true;
          });
        },

        addPrompt: (prompt: string) => {
          set((store) => {
            if (!store.reportingInsights) {
              store.reportingInsights = {};
            }

            store.reportingInsights.recentBlock = {
              isLoading: true,
              prompt,
            };
          });
        },

        endMessageResponse: () => {
          set((store) => {
            if (store.reportingInsights?.recentBlock) {
              delete store.reportingInsights.recentBlock.isStreaming;
            }
          });
        },

        closeStream: () => {
          set((store) => {
            if (store.reportingInsights?.recentBlock) {
              delete store.reportingInsights.recentBlock.isLoading;
            }
          });
        },

        markAnswerSeen: () => {
          set((store) => {
            if (store.reportingInsights?.recentBlock) {
              store.reportingInsights.recentBlock.isAnswerSeen = true;
            }
          });
        },

        markErrorSeen: () => {
          set((store) => {
            if (store.reportingInsights?.recentBlock) {
              store.reportingInsights.recentBlock.isErrorSeen = true;
            }
          });
        },

        markLoadingSeen: () => {
          set((store) => {
            if (store.reportingInsights?.recentBlock) {
              store.reportingInsights.recentBlock.isLoadingSeen = true;
            }
          });
        },

        resetBlock: () => {
          set((store) => {
            if (store.reportingInsights?.recentBlock) {
              delete store.reportingInsights.recentBlock;
            }
          });
        },

        setErrorType: (errorType: string) => {
          set((store) => {
            if (store.reportingInsights?.recentBlock) {
              store.reportingInsights.recentBlock.errorType = errorType;
              delete store.reportingInsights.recentBlock.isLoading;
            }
          });
        },

        saveThread: (threadId: string, category: DoraReportingCategory) => {
          set((store) => {
            if (store.reportingInsights) {
              if (!store.reportingInsights.threads) {
                store.reportingInsights.threads = [];
              }

              store.reportingInsights.threads.push({ id: threadId, category });
            }
          });
        },

        setReportingCategory: (category: DoraReportingCategory) => {
          set((store) => {
            if (!store.reportingInsights) {
              store.reportingInsights = {};
            }

            store.reportingInsights.activeCategory = category;
          });
        },

        showSecondaryLoading: () => {
          set((store) => {
            if (store.reportingInsights?.recentBlock) {
              store.reportingInsights.recentBlock.isSecondaryLoading = true;
            }
          });
        },

        updateResponse: (response: string, isNewMessage?: boolean) => {
          set((store) => {
            const recentBlock = store.reportingInsights?.recentBlock;
            if (!recentBlock) {
              return;
            }
            if (!recentBlock.markdownResps) {
              recentBlock.markdownResps = [response];
            } else if (isNewMessage) {
              recentBlock.markdownResps.push(response);
            } else {
              recentBlock.markdownResps[recentBlock.markdownResps.length - 1] =
                response;
            }

            recentBlock.isStreaming = true;
            delete recentBlock.isAnswerSeen;
            delete recentBlock.isLoadingSeen;
            delete recentBlock.isSecondaryLoading;
          });
        },
      })),
      {
        name: persistKey,
        partialize: (state) => {
          const partialState = state;

          if (partialState.reportingInsights) {
            // Reconnecting to the server is not going to be handled (for now) after the user manually refreshes the page and Dora is still returning the full answer to the previous prompt.
            // `recentBlock` is excluded from being persisted so that the user does not unexpectedly see an intermediate state.
            const { recentBlock, ...otherInsightsProps } =
              partialState.reportingInsights;

            partialState.reportingInsights = otherInsightsProps;
          }

          return partialState;
        },
        storage: createJSONStorage(() => zustandLocalStorage),
        version: 2,
      }
    )
  );
}

export function getPersistKeyForDoraChatDrawer(drawer: Drawer) {
  return `${drawer.type}-${drawer.id}`;
}

const map = new Map<
  string,
  {
    abortController: AbortController;
    doraChatStore: ReturnType<typeof createDoraDrawerStore>;
  }
>();

export function deleteDoraChatDrawer(drawer: Drawer) {
  const persistKey = getPersistKeyForDoraChatDrawer(drawer);
  const { abortController, doraChatStore } = getDoraChatStore(persistKey);

  doraChatStore.persist.clearStorage();
  abortController.abort();
  map.delete(persistKey);
}

export function getDoraChatStore(key: string) {
  const cached = map.get(key);

  if (cached) {
    return cached;
  }

  const doraChatStore = createDoraDrawerStore(key);
  const abortController = new AbortController();

  const fullStore = {
    abortController,
    doraChatStore,
  };

  map.set(key, fullStore);

  return fullStore;
}
