import { Button } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { AnimateChat } from '../../../shared/dora/AnimateChat';
import type { ConfirmActionsProps } from '../../types';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  neverMind: {
    defaultMessage: 'Never mind, cancel this summary',
    id: 'IxdTxP',
  },
  ready: {
    defaultMessage: "I'm ready!",
    id: 'XJjmWS',
  },
});

export const ConfirmActionsContent = (props: ConfirmActionsProps) => {
  const {
    formSettingsStore: { useSettingsConfirmationSetting },
    onCancel,
  } = props;

  const { formatMessage } = useIntl();
  const isSeen = useSettingsConfirmationSetting.getState().isInputSeen;

  const markSeen = useSettingsConfirmationSetting(
    (store) => store.markInputSeen
  );

  const setValue = useSettingsConfirmationSetting((store) => store.setValue);

  useEffect(() => {
    if (!isSeen) {
      markSeen();
    }
  }, [isSeen, markSeen]);

  const confirm = () => setValue(true);

  const actions = (
    <div className="flex justify-between gap-x-2 bg-gray-1 p-2">
      <Button isFullWidth onClick={confirm}>
        {formatMessage(messages.ready)}
      </Button>
      <Button isFullWidth onClick={onCancel} variation="secondaryLite">
        <span className="hidden sm:inline">
          {formatMessage(messages.neverMind)}
        </span>
        <span className="hidden max-sm:inline">
          {formatMessage(messages.cancel)}
        </span>
      </Button>
    </div>
  );

  return (
    <div className="sticky bottom-0">
      <AnimateChat shouldAnimateOnMount={!isSeen}>{actions}</AnimateChat>
    </div>
  );
};
