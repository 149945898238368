import type { UserDetails } from '@assembly-web/services';
import { zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type RecentSearchesStore = {
  getRecentQueries: (userDetails?: UserDetails) => string[];
  pushNewSearch: (value: string, userDetails?: UserDetails) => void;
  recentQueries?: Record<string, string[] | undefined>;
};

const recentQueriesLimit = 3;

const getKey = (userDetails: UserDetails) => {
  const { assembly, member } = userDetails;

  return `${assembly.assemblyId}-${member.memberId}`;
};

export const recentSearchesStore = create<RecentSearchesStore>()(
  persist(
    immer((set, get) => ({
      getRecentQueries: (userDetails?: UserDetails) => {
        if (!userDetails) {
          return [];
        }

        const userKey = getKey(userDetails);
        const { recentQueries = {} } = get();

        return recentQueries[userKey] ?? [];
      },
      pushNewSearch: (value: string, userDetails?: UserDetails) => {
        if (value && userDetails) {
          set((store) => {
            const userKey = getKey(userDetails);
            const { recentQueries = {} } = store;
            const updatedRecentQueriesForUser = recentQueries[userKey] ?? [];

            if (value === updatedRecentQueriesForUser[0]) {
              return;
            }

            updatedRecentQueriesForUser.unshift(value);

            if (updatedRecentQueriesForUser.length > recentQueriesLimit) {
              updatedRecentQueriesForUser.pop();
            }

            store.recentQueries = {
              ...store.recentQueries,
              [userKey]: updatedRecentQueriesForUser,
            };
          });
        }
      },
    })),
    {
      name: 'recent-searches-store',
      storage: createJSONStorage(() => zustandLocalStorage),
    }
  )
);
