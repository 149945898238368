import { deriveEnumForValue } from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/ui';
import { useSearchParams } from 'react-router-dom';

export function useGlobalFilter() {
  const [searchParams] = useSearchParams();

  return (
    deriveEnumForValue(searchParams.get('filter'), GlobalFilterOption) ??
    GlobalFilterOption.All
  );
}
