import ContentLoader from 'react-content-loader';

export const TemplateCardLoad = () => {
  return (
    <div className="w-full rounded-md border border-gray-5 px-2 pt-4">
      <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox="0 0 300 80"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="48" y="3" rx="3" ry="3" width="60%" height="16" />
        <rect x="48" y="24" rx="3" ry="3" width="60%" height="12" />
        <rect x="0" y="0" rx="8" ry="8" width="40" height="40" />
        <rect x="0" y="50" rx="3" ry="3" width="265" height="10" />
      </ContentLoader>
    </div>
  );
};
