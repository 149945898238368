import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { useIsBottomSheetInputActive } from '../../../../hooks/useIsBottomSheetInputActive';
import { AnswerMessage } from '../../../../shared/AnswerMessage';
import {
  type EditSettingsStoreType,
  type EventSourceStoreType,
  type FormSettingsStoreType,
  type IndividualFlowRespondent,
  type MemberAvatarProps,
  RespondentsGroup,
} from '../../../../types';

export const IndividualRespondentsAnswer = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    eventSourceStore: EventSourceStoreType;
    formSettingsStore: FormSettingsStoreType;
  }
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { useEventSourceStore },
    formSettingsStore,
    ...messageProps
  } = props;

  const {
    hideActiveInput,
    useIndividualRespondentsSetting,
    useRespondentsGroupSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const respondentsGroupValue = useRespondentsGroupSetting(
    (store) => store.value
  );

  const members = useIndividualRespondentsSetting((store) => store.value);
  const isSeen = useIndividualRespondentsSetting.getState().isAnswerSeen;
  const isSet = useIndividualRespondentsSetting((store) => store.isSet);

  const markSeen = useIndividualRespondentsSetting(
    (store) => store.markAnswerSeen
  );

  const markSet = useIndividualRespondentsSetting((store) => store.markSet);

  const setShouldRequestInput = useIndividualRespondentsSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useIndividualRespondentsSetting(
    (store) => store.shouldRequestInput
  );

  const { locale } = useIntl();

  const getCommaSeparatedMembers = useCallback(
    (respondents: IndividualFlowRespondent[]) => {
      const listFormatter = new Intl.ListFormat(locale, {
        style: 'long',
        type: 'conjunction',
      });

      const memberNames = respondents.map((respondent) => respondent.fullName);

      return listFormatter.format(memberNames);
    },
    [locale]
  );

  const settingEditing = useEditSettingsStore((store) => store.settingEditing);

  const isEditingOtherSetting = useEditSettingsStore(
    (store) =>
      store.isEditingBlocks() ||
      store.isEditingFocus() ||
      store.isEditingTimePeriod()
  );

  const isEditingPrevSetting = settingEditing === 'respondentsGroup';
  const isEditingCurSetting = settingEditing === 'individualRespondents';

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const setShouldRequestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const handleEdit = () => {
    trackDoraAction('summaryInputEdited', { doraSummaryInput: 'Respondents' });
    setSettingEditing('individualRespondents');
    hideActiveInput();

    setTimeout(() => {
      setShouldRequestEdit('individualRespondents', true);
    }, timeouts.showInput);
  };

  const isSettingsConfirmed = useSettingsConfirmationSetting((store) =>
    Boolean(store.value)
  );

  const isInputActive = useIsBottomSheetInputActive(formSettingsStore);
  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    respondentsGroupValue === RespondentsGroup.Individual &&
    !isEditingPrevSetting &&
    members && (
      <AnswerMessage
        isEditDisabled={
          !isSummaryError &&
          (isEditingCurSetting ||
            isEditingOtherSetting ||
            isSettingsConfirmed ||
            !isInputActive)
        }
        isEditing={isEditingCurSetting}
        isFaded={isEditingOtherSetting}
        isSeen={isSeen}
        isSet={isSet}
        markSeen={markSeen}
        markSet={markSet}
        onEdit={handleEdit}
        setShouldRequestInput={setShouldRequestInput}
        shouldRequestInput={shouldRequestInput}
        {...messageProps}
      >
        {getCommaSeparatedMembers(members)}
      </AnswerMessage>
    )
  );
};
