import type { EditPostInteractionSettingsPayload } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useMutation } from '@tanstack/react-query';

export function useEditPostInteractionSettings() {
  return useMutation({
    mutationFn: async (payload: EditPostInteractionSettingsPayload) => {
      const { flowId, responseId } = payload;
      const payloadForAPI = {
        hideReplies: payload.hideReplies,
        hideReactions: payload.hideReactions,
      };
      return assemblyAPI.put(
        APIEndpoints.editPostInteractionSettings(flowId, responseId),
        payloadForAPI
      );
    },
  });
}
