import { zustandLocalStorage } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { useMultiDrawerStore } from '../../../../../../stores/useMultiDrawerStore';
import {
  type DoraChatThreadDetail,
  useChatThreadsSummary,
} from '../../../../hooks/dora/useChatThreadsSummary';
import { isDoraConversationPastCutOffTime } from '../../../../hooks/dora/useDoraChatRestrictions';
import {
  type DoraSavedPrompt,
  useSavedReports,
} from '../../../../hooks/dora/useSavedReports';
import { trackDoraAction } from '../../../../services/analytics';
import { setFullScreenDrawerId } from '../../../../services/drawers';
import {
  type DoraChatDrawerData,
  getDoraChatStore,
  getPersistKeyForDoraChatDrawer,
} from '../../../../stores/doraChatStore';
import {
  ReportingCollectionsContainer,
  ReportingCollectionTabs,
  ReportingConversationCard,
  SavedPromptCard,
} from './ReportingCollectionsContainer';
import { ReportingCollectionsSkeletonLoader } from './ReportingCollectionsSkeletonLoader';

const messages = defineMessages({
  noSavedReports: {
    defaultMessage: 'No saved reports',
    id: 'I8pO2t',
  },
  noHistory: {
    defaultMessage: 'No history found',
    id: '/WqoHs',
  },
  newDoraChatDrawerTitle: {
    defaultMessage: 'DoraAI Reporting',
    id: 'eE3c8F',
  },
});

export const useDoraReportingSidebar = create<{
  currentTab: ReportingCollectionTabs;
  setCurrentTab: (tab: ReportingCollectionTabs) => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
}>()(
  persist(
    (set) => ({
      isSidebarOpen: false,
      currentTab: ReportingCollectionTabs.SavedReports,
      setCurrentTab: (currentTab: ReportingCollectionTabs) =>
        set({ currentTab }),
      setIsSidebarOpen: (isSidebarOpen: boolean) => set({ isSidebarOpen }),
    }),
    {
      name: 'doraReportingSidebar',
      storage: createJSONStorage(() => zustandLocalStorage),
    }
  )
);

export function ReportingCollections({
  isLoadedInSidebar,
  currentDrawerId,
  isLimitedReportingExperience,
  openUpgradeBanner,
}: {
  isLoadedInSidebar?: boolean;
  currentDrawerId: string;
  isLimitedReportingExperience: boolean;
  openUpgradeBanner: () => void;
}) {
  const { formatMessage } = useIntl();

  const [currentTab, setCurrentTab] = useState<ReportingCollectionTabs>(
    isLimitedReportingExperience
      ? ReportingCollectionTabs.History
      : ReportingCollectionTabs.SavedReports
  );

  const persistedCurrentTab = useDoraReportingSidebar(
    (state) => state.currentTab
  );
  const setPersistedCurrentTab = useDoraReportingSidebar(
    (state) => state.setCurrentTab
  );

  const activeTab = isLoadedInSidebar ? persistedCurrentTab : currentTab;
  const setActiveTab = isLoadedInSidebar
    ? setPersistedCurrentTab
    : setCurrentTab;

  const { data: chatThreadsSummaryData, isLoading: isThreadSummaryLoading } =
    useChatThreadsSummary();

  const { data: savedReports, isLoading: isSavedReportsLoading } =
    useSavedReports();

  const getDrawers = useMultiDrawerStore((store) => store.getDrawers);
  const getOverflowDrawers = useMultiDrawerStore(
    (store) => store.getOverflowDrawers
  );

  const findDrawer = useMultiDrawerStore((store) => store.findDrawer);
  const openDrawer = useMultiDrawerStore((store) => store.openDrawer);
  const deleteOverflowDrawer = useMultiDrawerStore(
    (store) => store.deleteOverflowDrawer
  );
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const deleteDrawer = useMultiDrawerStore((store) => store.deleteDrawer);
  const updateDraftInputValue = useMultiDrawerStore(
    (store) => store.updateDraftInputValue
  );
  const currentDrawer = findDrawer(currentDrawerId);

  const onSavedPromptClick = useCallback(
    (savedPrompt: DoraSavedPrompt) => {
      let drawerId = currentDrawerId;

      if (!currentDrawer) {
        return;
      }

      const persistKeyForCurrentDrawer =
        getPersistKeyForDoraChatDrawer(currentDrawer);

      const chatStore = getDoraChatStore(persistKeyForCurrentDrawer);

      const currentCategory =
        chatStore.doraChatStore.getState().reportingInsights?.activeCategory;

      const currentDrawerMetaData = chatThreadsSummaryData?.find(
        (x) => x.id === (currentDrawer.data as DoraChatDrawerData).threadId
      );

      let switchConversation = currentCategory !== savedPrompt.reportType;

      if (currentDrawerMetaData) {
        const isLastMessagePastCutOffTime = isDoraConversationPastCutOffTime(
          currentDrawerMetaData.createdAt
        );

        if (isLastMessagePastCutOffTime) {
          switchConversation = true;
        }
      }

      if (switchConversation) {
        deleteDrawer(currentDrawerId);

        drawerId = createDrawer({
          type: 'doraChat',
          title: savedPrompt.reportName,
          data: {
            isDefaultTitle: false,
            prompt: savedPrompt.prompt,
          } as DoraChatDrawerData,
        });

        const drawer = findDrawer(drawerId);

        if (drawer) {
          const persistKey = getPersistKeyForDoraChatDrawer(drawer);

          const { doraChatStore: newStore } = getDoraChatStore(persistKey);
          newStore.getState().setReportingCategory(savedPrompt.reportType);

          setFullScreenDrawerId(drawerId);
        }
      }
      updateDraftInputValue(drawerId, savedPrompt.prompt);
      trackDoraAction('runSavedPromptClicked');
    },
    [
      chatThreadsSummaryData,
      createDrawer,
      currentDrawer,
      currentDrawerId,
      deleteDrawer,
      findDrawer,
      updateDraftInputValue,
    ]
  );

  const onConversationClick = useCallback(
    (thread: DoraChatThreadDetail) => {
      const drawers = getDrawers();
      const overflowDrawers = getOverflowDrawers();

      const existingDrawer = drawers.find(
        (drawer) =>
          (drawer.data as DoraChatDrawerData | undefined)?.threadId ===
          thread.id
      );
      const existingOverflowDrawer = overflowDrawers.find(
        (drawer) =>
          (drawer.data as DoraChatDrawerData | undefined)?.threadId ===
          thread.id
      );

      if (existingDrawer && existingDrawer.id === currentDrawerId) {
        return;
      }

      trackDoraAction('historyConversationClicked');

      deleteDrawer(currentDrawerId);

      if (existingDrawer) {
        openDrawer(existingDrawer.id);
      } else {
        if (existingOverflowDrawer) {
          deleteOverflowDrawer(existingOverflowDrawer.id);
        }

        const drawerId = createDrawer({
          type: 'doraChat',
          title: thread.threadName,
          data: {
            isDefaultTitle: false,
            prompt: thread.threadName,
            threadId: thread.id,
          } as DoraChatDrawerData,
        });

        const drawer = findDrawer(drawerId);

        if (drawer) {
          const persistKey = getPersistKeyForDoraChatDrawer(drawer);

          const { doraChatStore: newStore } = getDoraChatStore(persistKey);
          newStore.getState().setReportingCategory(thread.reportType);
          newStore.getState().saveThread(thread.id, thread.reportType);
          setFullScreenDrawerId(drawerId);
        }
      }
    },
    [
      createDrawer,
      currentDrawerId,
      deleteDrawer,
      deleteOverflowDrawer,
      findDrawer,
      getDrawers,
      getOverflowDrawers,
      openDrawer,
    ]
  );

  return (
    <ReportingCollectionsContainer
      isLoadedInSidebar={Boolean(isLoadedInSidebar)}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      isLimitedReportingExperience={isLimitedReportingExperience}
      openUpgradeBanner={openUpgradeBanner}
    >
      {activeTab === ReportingCollectionTabs.SavedReports && (
        <>
          {isSavedReportsLoading ? (
            <ReportingCollectionsSkeletonLoader />
          ) : savedReports && savedReports.length > 0 ? (
            savedReports.map((savedPrompt) => (
              <SavedPromptCard
                isLimitedReportingExperience={isLimitedReportingExperience}
                key={savedPrompt.id}
                savedPrompt={savedPrompt}
                onSavedPromptClick={onSavedPromptClick}
              />
            ))
          ) : (
            <TextStyle variant="sm-medium" subdued>
              {formatMessage(messages.noSavedReports)}
            </TextStyle>
          )}
        </>
      )}
      {activeTab === ReportingCollectionTabs.History && (
        <>
          {isThreadSummaryLoading ? (
            <ReportingCollectionsSkeletonLoader />
          ) : chatThreadsSummaryData && chatThreadsSummaryData.length > 0 ? (
            chatThreadsSummaryData.map((thread) => (
              <ReportingConversationCard
                isActive={
                  (currentDrawer?.data as DoraChatDrawerData).threadId ===
                  thread.id
                }
                key={thread.id}
                isLimitedReportingExperience={isLimitedReportingExperience}
                thread={thread}
                onConversationClick={onConversationClick}
              />
            ))
          ) : (
            <TextStyle variant="sm-medium" subdued>
              {formatMessage(messages.noHistory)}
            </TextStyle>
          )}
        </>
      )}
    </ReportingCollectionsContainer>
  );
}
