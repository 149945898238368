import { assemblyMonogramImage } from '@assembly-web/assets';
import type { Workspace } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import startCase from 'lodash/startCase';
import { defineMessages, useIntl } from 'react-intl';

import type { WorkspaceActionPayload } from '../../types/workspaces';

export const workspacesPageActions = [
  'launch',
  'join',
  'accept',
  'create',
] as const;

export type WorkspaceCardAction = (typeof workspacesPageActions)[number];

const messages = defineMessages({
  acceptWorkspaceInviteAction: {
    defaultMessage: 'Accept',
    id: 'sjzLbX',
  },
  launchWorkspaceAction: {
    defaultMessage: 'Launch',
    id: 'ewx2b7',
  },
  joinWorkspaceAction: {
    defaultMessage: 'Join',
    id: 'P6PLpi',
  },
  createWorkspaceAction: {
    defaultMessage: 'Get started',
    id: '/aBLH2',
  },
  workspaceMemberDescription: {
    defaultMessage:
      '{count, plural, =0 {no members} one {1 member} other {{count} members}}',
    id: 'gipEMW',
  },
});

const actionText: Record<
  WorkspaceCardAction,
  { id: string; defaultMessage: string }
> = {
  launch: messages.launchWorkspaceAction,
  join: messages.joinWorkspaceAction,
  create: messages.createWorkspaceAction,
  accept: messages.acceptWorkspaceInviteAction,
};

export function WorkspaceCard({
  workspace,
  action,
  handleWorkspaceCardClick,
}: {
  workspace: Workspace;
  action: WorkspaceCardAction;
  handleWorkspaceCardClick: (payload: WorkspaceActionPayload) => void;
}) {
  const { formatMessage } = useIntl();

  let companyNameAbbreviation;

  if (!workspace.logo) {
    companyNameAbbreviation = startCase(workspace.name)
      .split(' ')
      .map((word) => {
        if (Number(word)) {
          return word;
        }
        const firstCharacter = word.codePointAt(0);

        return firstCharacter ? String.fromCodePoint(firstCharacter) : '';
      })
      .join('')
      .toUpperCase()
      .substring(0, 4);
  }

  return (
    <button
      key={workspace.assemblyId}
      className="flex h-20 w-full rounded-md border-[1px] border-gray-4 hover:bg-primary-1 focus:bg-primary-2"
      data-testid={`workspaceCard-${workspace.assemblyId}`}
      onClick={() =>
        handleWorkspaceCardClick({
          assemblyId: workspace.assemblyId,
          action,
          workspaceSlug: `${workspace.workspaceSlug.name}-${workspace.workspaceSlug.shortCode}`,
          inviteToken: workspace.inviteToken,
        })
      }
    >
      {workspace.logo ? (
        <img
          src={workspace.logo}
          alt=""
          role="presentation"
          className="flex h-full w-20 rounded-l-md object-contain"
        />
      ) : (
        <div className="flex h-full w-20 flex-col items-center justify-center rounded-l-md bg-brand-6 align-middle">
          <img
            src={assemblyMonogramImage}
            className="h-6 w-6"
            role="presentation"
            alt=""
          />
          <p className="font-medium text-gray-1">{companyNameAbbreviation}</p>
        </div>
      )}
      <div className="flex flex-1">
        <div className="w-[14rem] px-4 text-left">
          <TextStyle
            variant="base-medium"
            className="overflow-hidden text-ellipsis whitespace-nowrap pt-3"
            title={
              workspace.name.length > 25 ? startCase(workspace.name) : undefined
            }
          >
            {startCase(workspace.name)}
          </TextStyle>
          <TextStyle subdued variant="sm-regular" className="pt-1">
            {formatMessage(messages.workspaceMemberDescription, {
              count: workspace.totalMembers,
            })}
          </TextStyle>
        </div>
      </div>
      <div className="flex h-full w-20 flex-col items-center justify-center align-middle text-primary-6 hover:text-primary-5">
        <TextStyle variant="sm-regular">
          {formatMessage(actionText[action])}
        </TextStyle>
      </div>
    </button>
  );
}
