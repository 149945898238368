import { Button } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../services/analytics';
import type { EventSourceStoreType, FormSettingsStoreType } from '../../types';

const messages = defineMessages({
  restart: {
    defaultMessage: 'Restart with settings',
    id: 'IQeG00',
  },
  sameSettings: {
    defaultMessage: 'Start again with same settings',
    id: 'UWfK7r',
  },
  scratch: {
    defaultMessage: 'Start from scratch',
    id: 'fGhwMp',
  },
});

export const RestartSummaryActions = (props: {
  eventSourceStore: EventSourceStoreType;
  formSettingsStore: FormSettingsStoreType;
  onStartFromScratchClick: () => void;
}) => {
  const {
    eventSourceStore: { reset: clearSummary, useEventSourceStore },
    formSettingsStore: { useFeedbackMessage, useSettingsConfirmationSetting },
    onStartFromScratchClick,
  } = props;

  const { formatMessage } = useIntl();

  const isSummaryDisplayed = useEventSourceStore(
    (store) =>
      store.isSummaryFetched &&
      !store.isSummaryError &&
      !store.isFetchingSummary
  );

  const handleRestartWithSameSettings = () => {
    useFeedbackMessage.setState({ isFeedbackMessageShown: false });
    useSettingsConfirmationSetting.getState().setValue(false);
    clearSummary();

    trackDoraAction('startNewSummaryWithSettings');
  };

  return (
    isSummaryDisplayed && (
      <div className="sticky bottom-0">
        <div className="flex justify-between gap-x-2 bg-gray-1 p-2">
          <Button isFullWidth onClick={handleRestartWithSameSettings}>
            <span className="hidden sm:inline">
              {formatMessage(messages.sameSettings)}
            </span>
            <span className="hidden max-sm:inline">
              {formatMessage(messages.restart)}
            </span>
          </Button>
          <Button
            isFullWidth
            onClick={onStartFromScratchClick}
            variation="secondaryLite"
          >
            {formatMessage(messages.scratch)}
          </Button>
        </div>
      </div>
    )
  );
};
