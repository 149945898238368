import { usePusherChannel, usePusherEvents } from '@assembly-web/pusher';
import { logger, useUserDetails } from '@assembly-web/services';
import { useQueryClient } from '@tanstack/react-query';

type PusherData = {
  memberIds: string[];
  targetMemberIds: string[] | ['ALL'];
};

type UserFeedUpdatedEventOptions = {
  feedUpdated?: () => void;
};

export function useUserFeedUpdatedEvents(
  options?: UserFeedUpdatedEventOptions
) {
  const { feedUpdated } = options ?? {};
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails({ suspense: false });

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  usePusherEvents<PusherData>(
    assembly,
    ['USER_ACTIVITY_FEED_UPDATED'],
    (args) => {
      try {
        if (!userDetails) {
          return;
        }

        const { memberIds, targetMemberIds } = args.data ?? {};
        const { memberId: currentUserId } = userDetails.member;

        if (memberIds?.includes(currentUserId)) {
          const queryCache = queryClient.getQueryCache();
          const keysToInvalidate = ['userFeed', 'searchResults'];

          queryCache.getAll().forEach((query) => {
            const queryKey = query.queryKey;

            if (
              Array.isArray(queryKey) &&
              keysToInvalidate.includes(queryKey[0] as string)
            ) {
              queryClient.cancelQueries(queryKey);
              queryClient.invalidateQueries(queryKey);
            }
          });
        } else if (
          targetMemberIds?.includes('ALL') ||
          targetMemberIds?.includes(currentUserId)
        ) {
          feedUpdated?.();
        }
      } catch (e) {
        logger.error(
          'Refetch error on user activity feed update',
          {},
          e as Error
        );
      }
    }
  );
}
