import {
  doraAPI,
  DoraAPIEndpoints,
  type DoraReportingCategory,
} from '@assembly-web/services';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

export type DoraChatThreadDetail = {
  id: string;
  reportType: DoraReportingCategory;
  threadName: string;
  userId: string;
  assemblyId: string;
  isShared: boolean;
  promptCount: number;
  messageCount: number;
  createdAt: Date;
  updatedAt: Date;
  state: 'COMPLETED' | 'FAILED';
};

export const doraChatThreadsSummaryQueryKey = ['doraChatThreadsSummary'];

export function useChatThreadsSummary(
  enabled = true
): UseQueryResult<DoraChatThreadDetail[]> {
  return useQuery({
    enabled,
    queryKey: doraChatThreadsSummaryQueryKey,
    queryFn: async () => {
      const response = await doraAPI.get<DoraChatThreadDetail[]>(
        DoraAPIEndpoints.getThreadsSummary
      );

      return response.data.filter(
        (thread) => thread.reportType !== 'flow_responses'
      );
    },
  });
}
