import type { Member } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

export type GetMembersResponse = {
  data: {
    total: number /* total members count */;
    members: Member[];
    metadata: {
      /* it will not present when doing keyword search */
      pagination?: {
        count: number /* page count; 30 */;
        first: string /* base64 encoded value, ex: page=1,limit=10,sort={} */;
        prev: string /* base64 encoded value */;
        self: string /* base64 encoded value */;
        next: string /* base64 encoded value */;
        last: string /* base64 encoded value */;
      };
    };
    newMembers?: string[];
    totalPending: number;
    canEmailInvite?: boolean;
  };
};

export function getAllMembersForAssemblyWorkspace({
  enabled = false,
  searchTerm,
  options,
}: {
  enabled: boolean;
  searchTerm?: string;
  options?: UseInfiniteQueryOptions<GetMembersResponse>;
}): UseInfiniteQueryOptions<GetMembersResponse> {
  return {
    ...options,
    enabled,
    queryKey: ['getAllMembersForAssemblyWorkspace', searchTerm],
    queryFn: async ({ pageParam }) =>
      await assemblyAPI.get(APIEndpoints.getMembers, {
        params: {
          ...(pageParam ? { delta: pageParam } : {}),
          ...(searchTerm ? { keyword: searchTerm, searchLimit: 25 } : {}),
        },
      }),
    getNextPageParam: (page) =>
      page.data.metadata.pagination?.next ?? undefined,
    getPreviousPageParam: (page) =>
      page.data.metadata.pagination?.prev ?? undefined,
  };
}

export function useGetMembers({
  enabled,
  searchTerm,
  options,
}: {
  enabled: boolean;
  searchTerm?: string;
  options?: UseInfiniteQueryOptions<GetMembersResponse>;
}): UseInfiniteQueryResult<GetMembersResponse> & { members: Member[] } {
  const result = useInfiniteQuery(
    getAllMembersForAssemblyWorkspace({ enabled, searchTerm, options })
  );

  const members = result.data?.pages.flatMap((x) => x.data.members) ?? [];

  return {
    ...result,
    members,
  };
}
