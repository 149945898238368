import type { ResponseDetails } from '@assembly-web/services';
import { useEffect, useState } from 'react';

import { trackCardAction } from '../modules/discover/services/analytics';

export function usePostInteractionSettings() {
  const [
    isPostInteractionSettingsModalOpen,
    setPostInteractionSettingsModalOpen,
  ] = useState<boolean>(false);

  const [responseDetails, setResponseDetails] = useState<
    ResponseDetails | undefined
  >(undefined);

  const [postInteractionSettings, setPostInteractionSettings] = useState<{
    allowReplies: boolean;
    allowReactions: boolean;
  }>({
    allowReplies: false,
    allowReactions: false,
  });

  useEffect(() => {
    const handleMessage = ({
      data: { type, payload },
    }: MessageEvent<{
      type: string;
      payload: ResponseDetails;
    }>) => {
      if (type === 'OPEN_CONFIGURE_POST_INTERACTION_SETTINGS_MODAL') {
        setPostInteractionSettingsModalOpen(true);
        setResponseDetails(payload);
        setPostInteractionSettings({
          allowReplies: payload.allowReplies,
          allowReactions: payload.allowReactions,
        });
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const handleClosePostInteractionSettingsModal = () => {
    setPostInteractionSettingsModalOpen(false);
  };

  const handlePostInteractionSettingsChange = (
    setting: 'Replies' | 'Reactions'
  ) => {
    if (setting === 'Replies') {
      setPostInteractionSettings({
        ...postInteractionSettings,
        allowReplies: !postInteractionSettings.allowReplies,
      });
      trackCardAction(
        `${
          postInteractionSettings.allowReplies
            ? 'hideRepliesClicked'
            : 'showRepliesClicked'
        }`,
        {
          isRepliesDisabled: !postInteractionSettings.allowReplies,
          isReactionsDisabled: !postInteractionSettings.allowReactions,
        }
      );
    } else {
      setPostInteractionSettings({
        ...postInteractionSettings,
        allowReactions: !postInteractionSettings.allowReactions,
      });
      trackCardAction(
        `${
          postInteractionSettings.allowReplies
            ? 'hideReactionsClicked'
            : 'showReactionsClicked'
        }`,
        {
          isRepliesDisabled: !postInteractionSettings.allowReplies,
          isReactionsDisabled: !postInteractionSettings.allowReactions,
        }
      );
    }
  };

  return {
    responseDetails,
    postInteractionSettings,
    handlePostInteractionSettingsChange,
    isPostInteractionSettingsModalOpen,
    setPostInteractionSettingsModalOpen,
    handleClosePostInteractionSettingsModal,
  };
}
