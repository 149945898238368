import { usePusherChannel, usePusherEvent } from '@assembly-web/pusher';
import { useUserDetails } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useQueryClient } from '@tanstack/react-query';
import { defineMessages, useIntl } from 'react-intl';

import { getConnectionsQueryKey } from '../queries/getConnectionsQuery';
import { type Connection, ConnectionState } from '../types';

const messages = defineMessages({
  successfullyDisconnected: {
    defaultMessage: 'You have successfully disconnected {app}',
    id: 'ti3FWN',
  },
});

const queryKey = getConnectionsQueryKey();

export function usePusherForConnectionStatusUpdates() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails({ suspense: false });
  const { formatMessage } = useIntl();
  const { showSuccessToast } = useToastStore();

  const channel = usePusherChannel(
    `private-member-${userDetails?.member.memberId}`
  );

  usePusherEvent<Connection>(
    channel,
    'INTEGRATION_STATE_CHANGED',
    (socketEventData) => {
      if (!socketEventData) {
        return;
      }

      const cachedConnectionsList = queryClient.getQueryData<
        Connection[] | undefined
      >(queryKey);

      switch (socketEventData.connectionState) {
        case ConnectionState.Connected:
        case ConnectionState.ConnectionBroken:
        case ConnectionState.Disconnecting:
        case ConnectionState.Syncing: {
          const connectionIdFromEvent = socketEventData.connectionId;
          const updatedConnectionsList = [...(cachedConnectionsList ?? [])];

          const indexOfMatchingAppInCache = updatedConnectionsList.findIndex(
            (app) => app.connectionId === connectionIdFromEvent
          );

          if (indexOfMatchingAppInCache !== -1) {
            updatedConnectionsList[indexOfMatchingAppInCache] = socketEventData;
          } else {
            updatedConnectionsList.push(socketEventData);

            updatedConnectionsList.sort((a, b) => {
              // Make sure list is sorted in alphabetical order (A-Z) by the app name.
              if (a.integrationImage < b.integrationImage) {
                return -1;
              }

              if (a.integrationImage > b.integrationImage) {
                return 1;
              }

              return 0;
            });
          }

          queryClient.setQueryData(queryKey, updatedConnectionsList);
          break;
        }
        case ConnectionState.Disconnected: {
          if (cachedConnectionsList) {
            const disconnectedAppId = socketEventData.connectionId;

            const indexOfMatchingAppInCache = cachedConnectionsList.findIndex(
              (app) => app.connectionId === disconnectedAppId
            );

            const disconnectedAppName =
              indexOfMatchingAppInCache !== -1
                ? cachedConnectionsList[indexOfMatchingAppInCache]
                    .integrationName
                : null;

            const updatedConnectionsList = cachedConnectionsList.filter(
              (connection) => connection.connectionId !== disconnectedAppId
            );

            queryClient.setQueryData(queryKey, updatedConnectionsList);

            if (disconnectedAppName) {
              showSuccessToast(
                formatMessage(messages.successfullyDisconnected, {
                  app: disconnectedAppName,
                })
              );
            }
          }
          break;
        }
        default:
      }
    }
  );
}
