import {
  type FlowBuilderState,
  getBaseEmojiFromIcon,
  getContent,
  getTrigger,
  getVisibility,
} from '@assembly-web/services';

import { useGetTemplateDetailsById } from '../../../../hooks/templates/useGetTemplateDetailsById';
import { useCurrentUserDetail } from '../../../../hooks/useCurrentUserDetail';
import type { FlowCreationDrawer as FlowCreationEditorProps } from '../../types';
import { formatUserToFlowCollaborator } from '../utils/user';

export const useFetchTemplate = (
  templateId: string,
  type: FlowCreationEditorProps['data']['type'],
  enabled = true
) => {
  const owner = useCurrentUserDetail();

  return useGetTemplateDetailsById(templateId, {
    enabled: enabled && type === 'template',
    select(data): FlowBuilderState {
      return {
        currentSchedule: undefined,
        description: data.templateDetails.description.text,
        collaborators: [],
        emoji: getBaseEmojiFromIcon(
          data.templateDetails.description.icon?.value ?? ''
        ),
        flowId: window.crypto.randomUUID(),
        flowName: data.templateDetails.title,
        inEditMode: false,
        isFlowDataChangeConfirmed: false,
        showDataChangeConfirm: false,
        templateId: data.templateId,
        templateName: data.templateDetails.title,
        owner: [formatUserToFlowCollaborator(owner)],
        blockData: {
          PARTICIPANTS: {
            errors: null,
            participantsCriteria: undefined,
          },
          ACTION: {
            errors: null,
            actionType: 'SEND_FORM',
          },
          CONTENT: getContent(data.templateDetails),
          VISIBILITY: getVisibility(data.templateDetails),
          TRIGGER: getTrigger(data.templateDetails),
        },
        canValidate: true,
        errors: null,
        newUsersToInvite: [],
      };
    },
  });
};
