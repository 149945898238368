import { TextField } from '@assembly-web/ui';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useIntl } from 'react-intl';

import { discoverPageHeaderMessages } from './DiscoverPageHeader';

export function DiscoverPageSkeletonLoader() {
  const { formatMessage } = useIntl();

  return (
    <header className="sticky top-0 z-[2] flex justify-between bg-gray-1 px-6 py-3 max-md:w-screen max-md:px-4 md:space-y-0">
      <section className="flex space-x-4 md:w-full">
        <div className="inline-flex cursor-pointer space-x-2">
          <div className="h-12 w-12 animate-pulse rounded-lg bg-gray-5" />
        </div>
        <div className="grow md:w-[250px]">
          <TextField
            type="search"
            inputSize="lg"
            label={formatMessage(discoverPageHeaderMessages.searchLabel)}
            hideLabel
            placeholder={formatMessage(
              discoverPageHeaderMessages.searchPlaceholder
            )}
            invalidText=""
            isInvalid={false}
            disabled
            connectedLeft={
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-8" />
            }
          />
        </div>
        <div className="flex items-center md:space-x-5">
          <div className="hidden h-12 w-40 animate-pulse items-center justify-center rounded bg-gray-5 max-md:h-9 max-md:w-9 md:block" />
          <div className="hidden h-8 w-[1px] rounded bg-gray-5 md:block" />
          <div className="hidden h-12 w-12 animate-pulse items-center justify-center rounded bg-gray-5 max-md:h-9 max-md:w-9 md:block" />
          <div className="hidden h-8 w-[1px] rounded bg-gray-5 md:block" />
          <div className="mr-4 flex w-16 items-center max-md:w-10">
            <div className="flex h-9 w-9 animate-pulse items-center justify-center rounded-full bg-gray-5 max-md:h-9 max-md:w-9" />
          </div>
        </div>
      </section>
    </header>
  );
}
