import { defineMessages, useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../../../services/analytics';
import type { createFormSettingsStore } from '../../../../../../../stores/flowSummaryStore/formSettingsStore';
import { timeouts } from '../../../../../shared/dora/constants';
import { useIsBottomSheetInputActive } from '../../../../hooks/useIsBottomSheetInputActive';
import { AnswerMessage } from '../../../../shared/AnswerMessage';
import type {
  EditSettingsStoreType,
  EventSourceStoreType,
  MemberAvatarProps,
} from '../../../../types';
import { RespondentsGroup } from '../../../../types';

const messages = defineMessages({
  allRespondents: {
    defaultMessage: 'All respondents',
    id: 'HcFS1f',
  },
  anonymousRespondents: {
    defaultMessage: 'All anonymous respondents',
    id: 'q4nF8V',
  },
  individualRespondents: {
    defaultMessage: 'Individual respondents',
    id: 'cd1bey',
  },
});

const getMessage = (selectedRespondentGroup: RespondentsGroup) => {
  switch (selectedRespondentGroup) {
    case RespondentsGroup.All:
      return messages.allRespondents;
    case RespondentsGroup.Individual:
      return messages.individualRespondents;
    case RespondentsGroup.Anonymous:
      return messages.anonymousRespondents;
  }
};

export const RespondentsGroupAnswer = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    eventSourceStore: EventSourceStoreType;
    formSettingsStore: ReturnType<typeof createFormSettingsStore>;
  }
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { useEventSourceStore },
    formSettingsStore,
    ...messageProps
  } = props;

  const {
    hideActiveInput,
    useRespondentsGroupSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const { formatMessage } = useIntl();

  const origRespondentsGroupValue = useRespondentsGroupSetting(
    (store) => store.value
  );

  const isSeen = useRespondentsGroupSetting.getState().isAnswerSeen;
  const isSet = useRespondentsGroupSetting((store) => store.isSet);
  const markSeen = useRespondentsGroupSetting((store) => store.markAnswerSeen);
  const markSet = useRespondentsGroupSetting((store) => store.markSet);

  const setShouldRequestInput = useRespondentsGroupSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useRespondentsGroupSetting(
    (store) => store.shouldRequestInput
  );

  const settingEditing = useEditSettingsStore((store) => store.settingEditing);
  const isEditingCurSetting = settingEditing === 'respondentsGroup';

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const setShouldRequestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const isSettingsConfirmed = useSettingsConfirmationSetting((store) =>
    Boolean(store.value)
  );

  const editedRespondentsGroupValue = useEditSettingsStore(
    (store) => store.respondentsGroup.value
  );

  const resetEdited = useEditSettingsStore((store) => store.reset);

  const handleEdit = () => {
    trackDoraAction('summaryInputEdited', { doraSummaryInput: 'Respondents' });
    setSettingEditing('respondentsGroup');
    hideActiveInput();
    resetEdited();

    setTimeout(() => {
      setShouldRequestEdit('respondentsGroup', true);
    }, timeouts.showInput);
  };

  const isEditingOtherSetting = useEditSettingsStore((store) => {
    const isEditingSpecificRespondents =
      store.settingEditing === 'individualRespondents' &&
      !store.respondentsGroup.value;

    return (
      store.isEditingBlocks() ||
      isEditingSpecificRespondents ||
      store.isEditingFocus() ||
      store.isEditingTimePeriod()
    );
  });

  const value = editedRespondentsGroupValue ?? origRespondentsGroupValue;
  const isInputActive = useIsBottomSheetInputActive(formSettingsStore);
  const isEditing = useEditSettingsStore((store) =>
    Boolean(store.settingEditing)
  );
  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    value && (
      <AnswerMessage
        isEditDisabled={
          !isSummaryError &&
          (isEditingCurSetting ||
            isEditingOtherSetting ||
            isSettingsConfirmed ||
            (!isInputActive && !isEditing))
        }
        isEditing={isEditingCurSetting}
        isFaded={isEditingOtherSetting}
        isSeen={isSeen}
        isSet={isSet}
        markSeen={markSeen}
        markSet={markSet}
        onEdit={handleEdit}
        setShouldRequestInput={setShouldRequestInput}
        shouldRequestInput={shouldRequestInput}
        {...messageProps}
      >
        {formatMessage(getMessage(value))}
      </AnswerMessage>
    )
  );
};
