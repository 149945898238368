import { defineMessage, useIntl } from 'react-intl';

const loadingMessage = defineMessage({
  defaultMessage: 'Loading content',
  id: '3Pyhwf',
});

export function ReportingCollectionsSkeletonLoader() {
  const { formatMessage } = useIntl();
  return (
    <div
      className="inline-flex w-full items-start justify-start gap-2 rounded-lg"
      role="status"
    >
      <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
        <div className="inline-flex items-start justify-between self-stretch pb-2 pt-1">
          <div className="flex h-7 shrink grow basis-0 justify-start gap-2">
            <div className="relative h-7 w-7 rounded-full">
              <div className="absolute left-[4px] top-[4px] h-7 w-7 rounded-full bg-gradient-to-r from-gray-3 to-gray-5" />
            </div>
            <div className="inline-flex flex-col items-start justify-start">
              <div className="flex flex-col items-start justify-start gap-2 py-0.5">
                <div className="h-4 w-96 rounded bg-gradient-to-r from-gray-3 to-gray-5" />
              </div>
              <div className="flex h-5 w-24 flex-col items-start justify-start gap-2 py-1">
                <div className="h-3 self-stretch rounded bg-gradient-to-r from-gray-3 to-gray-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className="sr-only">{formatMessage(loadingMessage)}</span>
    </div>
  );
}
