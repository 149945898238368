import { Button, ConfirmationModal } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { createContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useDisconnectingApps } from '../hooks/useDisconnectingApps';
import { trackAppConnectionAction } from '../services/analytics';
import type { Connection } from '../types';

const messages = defineMessages({
  confirmDisconnect: {
    defaultMessage: 'Yes, disconnect',
    id: 'k4cUsV',
  },
  disconnectApp: {
    defaultMessage: 'Disconnect {app}',
    id: '1v31JM',
  },
  disconnectDescription: {
    defaultMessage:
      "By disconnecting you'll lose access to all of your important information from this tool in Assembly. Anyone that you've shared content with who is in Assembly will also lose access. Are you sure you want to disconnect?",
    id: 'ajALSi',
  },
  disconnectLabel: {
    defaultMessage: 'Disconnect app dialog',
    id: 'UxJLcN',
  },
  keepConnection: {
    defaultMessage: 'No, keep connection',
    id: '+L63eX',
  },
});

export const DisconnectModalContext = createContext<{
  handleAppDisconnectClick: (app: Connection) => void;
}>({
  handleAppDisconnectClick: () => {},
});

export const DisconnectModalProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const { formatMessage } = useIntl();

  const [appToDisconnect, setAppToDisconnect] = useState<Connection>();
  const isDisconnectModalOpen = Boolean(appToDisconnect);
  const closeDisconnectModal = () => setAppToDisconnect(undefined);
  const { disconnectApp } = useDisconnectingApps();

  const handleAppDisconnectClick = (app: Connection) => {
    setAppToDisconnect(app);
  };

  const handleDisconnectConfirmClick = () => {
    if (appToDisconnect) {
      const disconnectedAppName = appToDisconnect.integrationName;

      disconnectApp({
        appName: disconnectedAppName,
        connectionId: appToDisconnect.connectionId,
      });

      trackAppConnectionAction('disconnectAppClicked', { disconnectedAppName });
    }

    closeDisconnectModal();
  };

  return (
    <DisconnectModalContext.Provider value={{ handleAppDisconnectClick }}>
      <ConfirmationModal
        description={formatMessage(messages.disconnectDescription)}
        label={formatMessage(messages.disconnectLabel)}
        leftButton={
          <Button
            isFullWidth
            onClick={closeDisconnectModal}
            variation="secondaryEmphasized"
          >
            {formatMessage(messages.keepConnection)}
          </Button>
        }
        onClose={closeDisconnectModal}
        open={isDisconnectModalOpen}
        rightButton={
          <Button
            isFullWidth
            onClick={handleDisconnectConfirmClick}
            variation="danger"
          >
            {formatMessage(messages.confirmDisconnect)}
          </Button>
        }
        title={formatMessage(messages.disconnectApp, {
          app: appToDisconnect?.integrationName,
        })}
      />
      {children}
    </DisconnectModalContext.Provider>
  );
};
