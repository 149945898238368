import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { useIsBottomSheetInputActive } from '../../../../hooks/useIsBottomSheetInputActive';
import { AnswerMessage } from '../../../../shared/AnswerMessage';
import type {
  EditSettingsStoreType,
  EventSourceStoreType,
  FormSettingsStoreType,
  MemberAvatarProps,
} from '../../../../types';

export const CustomTimePeriodAnswer = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    eventSourceStore: EventSourceStoreType;
    formSettingsStore: FormSettingsStoreType;
  }
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { useEventSourceStore },
    formSettingsStore,
    ...messageProps
  } = props;

  const {
    hideActiveInput,
    useCustomTimePeriodSetting,
    usePredefinedTimePeriodSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const predefinedTimePeriodEndDate = usePredefinedTimePeriodSetting(
    (store) => store.value?.endDate
  );

  const predefinedTimePeriodStartDate = usePredefinedTimePeriodSetting(
    (store) => store.value?.startDate
  );

  const isCustomOptionSelected =
    !predefinedTimePeriodEndDate && !predefinedTimePeriodStartDate;

  const customTimePeriodValue = useCustomTimePeriodSetting(
    (store) => store.value
  );

  const isSeen = useCustomTimePeriodSetting.getState().isAnswerSeen;
  const isSet = useCustomTimePeriodSetting((store) => store.isSet);
  const markSeen = useCustomTimePeriodSetting((store) => store.markAnswerSeen);
  const markSet = useCustomTimePeriodSetting((store) => store.markSet);

  const setShouldRequestInput = useCustomTimePeriodSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useCustomTimePeriodSetting(
    (store) => store.shouldRequestInput
  );

  const isEditingPrevSetting = useEditSettingsStore(
    (store) =>
      store.isEditingRespondents() ||
      store.isEditingBlocks() ||
      store.isEditingFocus() ||
      store.settingEditing === 'predefinedTimePeriod'
  );

  const isEditingCurSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'customTimePeriod'
  );

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const setShouldRequestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const isSettingsConfirmed = useSettingsConfirmationSetting((store) =>
    Boolean(store.value)
  );

  const handleEdit = () => {
    trackDoraAction('summaryInputEdited', { doraSummaryInput: 'Time Period' });
    setSettingEditing('customTimePeriod');
    hideActiveInput();

    setTimeout(() => {
      setShouldRequestEdit('customTimePeriod', true);
    }, timeouts.showInput);
  };

  const isInputActive = useIsBottomSheetInputActive(formSettingsStore);
  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    isCustomOptionSelected &&
    customTimePeriodValue &&
    !isEditingPrevSetting && (
      <AnswerMessage
        isEditDisabled={
          !isSummaryError &&
          (isEditingCurSetting || isSettingsConfirmed || !isInputActive)
        }
        isEditing={isEditingCurSetting}
        isSeen={isSeen}
        isSet={isSet}
        markSeen={markSeen}
        markSet={markSet}
        onEdit={handleEdit}
        setShouldRequestInput={setShouldRequestInput}
        shouldRequestInput={shouldRequestInput}
        {...messageProps}
      >
        {customTimePeriodValue.duration}
      </AnswerMessage>
    )
  );
};
