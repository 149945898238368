import { searchingImage } from '@assembly-web/assets';
import { Button } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

type TemplatesEmptyViewProps = {
  onButtonClick: () => void;
};

const templatesText = defineMessages({
  startFromScratch: {
    defaultMessage: '+ Start from scratch',
    id: 'KduLMo',
  },
  matchNotFound: {
    defaultMessage: 'We couldn’t find any matching templates',
    id: 'mIDAFm',
  },
  tryDifferentSearch: {
    defaultMessage:
      'Try a different search or start building a workflow from scratch',
    id: '7B0uAq',
  },
});

export const TemplatesEmptyView = (props: TemplatesEmptyViewProps) => {
  const { onButtonClick } = props;
  const { formatMessage } = useIntl();
  return (
    <div
      className="m-auto mt-[8vh] w-[100%] !max-w-[420px] text-center"
      data-testid="emptyResult"
    >
      <img src={searchingImage} alt="No results found" className="m-auto" />
      <div className="pt-6 text-base font-medium">
        {formatMessage(templatesText.matchNotFound)}
      </div>
      <div className="mb-4 pt-1 text-sm">
        {formatMessage(templatesText.tryDifferentSearch)}
      </div>
      <Button onClick={onButtonClick} variation="secondaryEmphasized">
        {formatMessage(templatesText.startFromScratch)}
      </Button>
    </div>
  );
};
