import { SVGReactComponents } from '@assembly-web/assets';
import type { Source } from '@assembly-web/services';
import {
  Button,
  IconButton,
  Popover,
  TextStyle,
  Tooltip,
} from '@assembly-web/ui';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackDiscoverAction } from '../../../services/analytics';
import { DoraSourceLabel } from '../../dora/DoraSourceLabel';
import type { RefetchParams } from './hooks/useRefetchResponse';

const messages = defineMessages({
  answerFromDiffSource: {
    defaultMessage: 'Answer from a different source',
    id: 'jB5Ylz',
  },
  description: {
    defaultMessage:
      'DoraAI chooses what it thinks is the most relevant file to answer from. If another source seems better, click on it to get an answer.',
    id: 'qBiSLR',
  },
  label: {
    defaultMessage: 'Swap source',
    id: '/Y/030',
  },
});

export const SourceSwap = (props: {
  sources: Source[];
  refetchResponse: (params: RefetchParams) => void;
}) => {
  const { refetchResponse, sources } = props;
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => {
    if (!isOpen) {
      trackDiscoverAction('swapSourcesClicked');
    }
    setIsOpen(!isOpen);
  };

  const onClick = (source: Source) => {
    refetchResponse({ documentId: source.id });
    togglePopover();
    trackDiscoverAction('newSourceClicked');
  };

  return (
    <Popover
      aria-label={formatMessage(messages.label)}
      side="left"
      modal={false}
      open={isOpen}
      contentClassName="px-0 py-2"
      onOpenChange={setIsOpen}
      onTriggerButtonClick={togglePopover}
      trigger={
        <Tooltip tooltipText={formatMessage(messages.answerFromDiffSource)}>
          <IconButton className="h-8 w-8" variation="secondaryLite">
            <ArrowsRightLeftIcon className="h-4 w-4 text-gray-8" />
          </IconButton>
        </Tooltip>
      }
    >
      <div className="w-60 overflow-hidden">
        <div className="px-3 pt-1">
          <TextStyle variant="xs-medium">
            {formatMessage(messages.answerFromDiffSource)}
          </TextStyle>
          <TextStyle variant="xs-regular" className="text-gray-8">
            {formatMessage(messages.description)}
          </TextStyle>
        </div>
        {sources.map((source) => (
          <Button
            key={source.id}
            variation="tertiaryLite"
            isFullWidth
            className="flex items-center justify-between gap-2 rounded-none px-3 py-2"
            onClick={() => onClick(source)}
          >
            <DoraSourceLabel source={source} size="sm" />
            <SVGReactComponents.SparklesColoredIcon className="h-4 w-4 shrink-0" />
          </Button>
        ))}
      </div>
    </Popover>
  );
};
