import type { DateRange, FileType, Source } from '@assembly-web/services';
import { zustandLocalStorage } from '@assembly-web/services';
import type { MessageDescriptor } from 'react-intl';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { useMultiDrawerStore } from '../../../stores/useMultiDrawerStore';

export type MemberAskDoraBlockState = {
  question: string;
  data: string;
  dateRange?: DateRange;
  documentId?: string;
  errorMessage?: string;
  fileTypes?: FileType[];
  isAnswerSeen: boolean;
  isError: boolean;
  isErrorSeen: boolean;
  isLoading: boolean;
  isLoadingSeen: boolean;
  isStreaming: boolean;
  loadingMessage?: string;
  promptId: string | null;
  refetchPromptId?: string;
  sources: Source[];
  searchedSources?: Source[];
  staticResponse?: MessageDescriptor;
};

type MemberAskDoraState = {
  currentBlock: MemberAskDoraBlockState;
  suggestedQuestions: string[];
  threadId: string;
};

const emptyMemberAskDoraState: MemberAskDoraBlockState = {
  question: '',
  dateRange: undefined,
  documentId: undefined,
  fileTypes: undefined,
  isAnswerSeen: false,
  isError: false,
  isErrorSeen: false,
  isLoading: false,
  isLoadingSeen: false,
  isStreaming: false,
  loadingMessage: undefined,
  promptId: null,
  refetchPromptId: undefined,
  sources: [],
  searchedSources: [],
  staticResponse: undefined,
  data: '',
};

type AskDoraState = {
  members: Record<string, MemberAskDoraState>;
  resetStore: () => void;
  resetBlockState: () => void;
  setMemberState: (id: string) => void;
  getCurrentMemberId: () => string;
  getCurrentBlockState: () => MemberAskDoraBlockState;
  getSuggestedQuestions: () => string[];
  getThreadId: () => string;
  updateBlock: (updates: Partial<MemberAskDoraBlockState>) => void;
  addQuestionAnswerBlock: (params: {
    question: string;
    dateRange?: DateRange;
    documentId?: string;
    refetchPromptId?: string;
    fileTypes?: FileType[];
  }) => void;
  setSuggestedQuestions: (questions: string[]) => void;
  setThreadId: (threadId: string) => void;
};

export const useAskDoraStore = create<AskDoraState>()(
  persist(
    immer<AskDoraState>((set, get) => ({
      members: {},

      getCurrentMemberId() {
        return useMultiDrawerStore.getState().currentMemberId;
      },

      resetStore() {
        const currentMemberId = get().getCurrentMemberId();
        if (!currentMemberId) {
          return;
        }
        set((store) => {
          store.members[currentMemberId].currentBlock = emptyMemberAskDoraState;
          store.members[currentMemberId].threadId = '';
          store.members[currentMemberId].suggestedQuestions = [];
        });
      },

      resetBlockState() {
        const currentMemberId = get().getCurrentMemberId();
        if (!currentMemberId) {
          return;
        }
        set((store) => {
          store.members[currentMemberId].currentBlock = emptyMemberAskDoraState;
        });
      },

      setMemberState(id: string) {
        const { members } = get();
        if (!members.hasOwnProperty.call(members, id)) {
          set((store) => {
            store.members[id] = {
              currentBlock: emptyMemberAskDoraState,
              suggestedQuestions: [],
              threadId: '',
            };
          });
        }
      },

      getCurrentBlockState() {
        const currentMemberId = get().getCurrentMemberId();
        if (!currentMemberId) {
          return emptyMemberAskDoraState;
        }
        return get().members[currentMemberId].currentBlock;
      },

      getSuggestedQuestions() {
        const currentMemberId = get().getCurrentMemberId();
        if (!currentMemberId) {
          return [];
        }
        return get().members[currentMemberId].suggestedQuestions;
      },

      getThreadId() {
        const currentMemberId = get().getCurrentMemberId();
        if (!currentMemberId) {
          return '';
        }
        return get().members[currentMemberId].threadId;
      },

      addQuestionAnswerBlock(params: {
        question: string;
        dateRange?: DateRange;
        documentId?: string;
        refetchPromptId?: string;
      }) {
        const currentMemberId = get().getCurrentMemberId();
        if (!currentMemberId) {
          return;
        }
        set((store) => {
          store.members[currentMemberId].currentBlock = {
            ...emptyMemberAskDoraState,
            ...params,
            isLoading: true,
          };
        });
      },

      updateBlock(updates: Partial<MemberAskDoraState>) {
        const { members, getCurrentMemberId } = get();
        const currentMemberId = getCurrentMemberId();
        const currentBlock = members[currentMemberId].currentBlock;
        set((store) => {
          store.members[currentMemberId].currentBlock = {
            ...currentBlock,
            ...updates,
          };
        });
      },

      setSuggestedQuestions(questions: string[]) {
        const currentMemberId = get().getCurrentMemberId();
        set((store) => {
          store.members[currentMemberId].suggestedQuestions = questions;
        });
      },

      setThreadId(threadId: string) {
        const currentMemberId = get().getCurrentMemberId();
        set((store) => {
          store.members[currentMemberId].threadId = threadId;
        });
      },
    })),
    {
      name: 'ask-dora-store',
      storage: createJSONStorage(() => zustandLocalStorage),
      version: 2,
    }
  )
);
