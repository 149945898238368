import type { AmazonConnectionsResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

function getAmazonConnections(
  options?: UseQueryOptions<AmazonConnectionsResponse>
) {
  return {
    ...options,
    queryKey: ['amazonConnections'],
    queryFn: async () => {
      const response = await assemblyAPI.get<AmazonConnectionsResponse>(
        APIEndpoints.amazonConnections
      );
      return response.data;
    },
    retry: 1,
    refetchOnWindowFocus: false,
  };
}

export function useGetAmazonConnections(
  options?: UseQueryOptions<AmazonConnectionsResponse>
) {
  return useQuery<AmazonConnectionsResponse>(getAmazonConnections(options));
}
