import {
  APIEndpoints,
  type FlowBuilderDetailsApiResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

export function useGetFlowBuilderDetailsById<TSelect>(
  flowId: string,
  options?: UseQueryOptions<FlowBuilderDetailsApiResponse, unknown, TSelect>
) {
  return useQuery<FlowBuilderDetailsApiResponse, unknown, TSelect>({
    ...options,
    retry: false,
    queryKey: ['flowDetails', flowId, 'builder'],
    queryFn: async () => {
      const response = await assemblyAPI.get<FlowBuilderDetailsApiResponse>(
        `${APIEndpoints.getFlowDetailsById(flowId)}?${new URLSearchParams([['type', 'builder']])}`
      );

      return response.data;
    },
  });
}
