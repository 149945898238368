import {
  DoraAPIEndpoints,
  type SearchSuggestedQuestionsApiResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function getSuggestedQuestionsQuery(
  searchTerm: string,
  limit = 2,
  options?: UseQueryOptions<SearchSuggestedQuestionsApiResponse>
): UseQueryOptions<SearchSuggestedQuestionsApiResponse> {
  return {
    ...options,
    queryKey: ['suggestedQuestions', searchTerm, limit],
    queryFn: async () => {
      const response =
        await assemblyAPI.post<SearchSuggestedQuestionsApiResponse>(
          DoraAPIEndpoints.searchSuggestedQuestions,
          {
            searchTerm,
            cursor: {
              from: 0,
              limit,
            },
          }
        );
      return response.data;
    },
  };
}
