import '@assembly-web/ui/lib/ui-styles.css';
import '@total-typescript/ts-reset';
import './services/polyfill';

import {
  config,
  getMobilePlatformFromURL,
  initializeSentry,
  queryClientOptions,
  routeConstants,
  userAuthStore,
} from '@assembly-web/services';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { IntercomProvider } from 'react-use-intercom';

import { Router } from './Router';
import { initializeMSTeamsContext } from './services/msTeamsInitialization';

(async function RenderApp() {
  const container = document.getElementById('assembly_app');

  if (!container) {
    return;
  }

  const locale = config.locale;

  const intercomAppToken = config.intercomAppId;

  const [appMessages, uiMessages] = await Promise.all([
    import(`./translations/${locale}.json`).then((x) => x.default),
    import(`@assembly-web/ui`).then((x) => x.translations[locale]),
    initializeSentry(),
  ]);

  const messages = { ...appMessages, ...uiMessages };

  if (config.env === 'prod') {
    mixpanel.init(config.mixpanelToken);
  }

  await initializeMSTeamsContext();

  const searchParams = new URLSearchParams(window.location.search);

  if (config.isMobileDevMode) {
    const jwtToken = decodeURIComponent(
      searchParams.get('mobileAuthToken') ?? ''
    );
    const refreshToken = decodeURIComponent(
      searchParams.get('mobileRefreshToken') ?? ''
    );
    if (jwtToken) {
      userAuthStore.getState().setJwtToken(jwtToken);
    }
    if (refreshToken) {
      userAuthStore.getState().setRefreshToken(refreshToken);
    }
  }

  const mobilePlatform = getMobilePlatformFromURL();
  if (mobilePlatform) {
    userAuthStore.getState().setIsLoginViaMobileApp(true);
    userAuthStore.getState().setMobilePlatform(mobilePlatform);

    if (searchParams.get('isMobileAppV3') === 'true') {
      userAuthStore.getState().setIsMobileAppV3(true);
    }
    if (
      routeConstants.legacyExperiencePaths.some((path) =>
        window.location.href.includes(path)
      )
    ) {
      userAuthStore.getState().setCloseDrawerOnNavigateBack(true);
    }
  }

  const queryClient = new QueryClient(queryClientOptions);

  createRoot(container).render(
    <StrictMode>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | Assembly"
            defaultTitle="Assembly - Empower your team"
          />
          <QueryClientProvider client={queryClient}>
            <IntercomProvider autoBoot={false} appId={intercomAppToken}>
              <Router />
            </IntercomProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </IntlProvider>
    </StrictMode>
  );
})();
