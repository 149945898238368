import type { Source } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import {
  ExclamationTriangleIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { messages as sharedMessages } from '../../../services/dora';
import type { MemberAskDoraBlockState } from '../../../stores/useAskDoraStore';
import { DoraLoadingLabel } from '../../dora/DoraLoadingLabel';
import { DoraResponseFooter } from '../../dora/DoraResponseFooter';
import { messages } from '../../dora/messages';
import { UpgradeButton } from '../../dora/UpgradeButton';
import { AnimateChat } from '../shared/dora/AnimateChat';
import { DoraAvatar } from '../shared/dora/DoraAvatar';
import { DoraMessageBubble } from '../shared/dora/DoraMessageBubble';
import { DoraSingleMessage } from '../shared/dora/DoraSingleMessage';
import { TryAgainMessage } from '../shared/dora/TryAgainMessage';
import { displayValuesMapper } from './utils';

export const DoraChatMessage = (props: {
  canSwapSource?: boolean;
  errorMessage?: string;
  filterCount?: number;
  htmlResponse: string | null;
  isAdmin: boolean;
  isAnswerSeen?: boolean;
  isDoraIncludedInPlan: boolean;
  isError?: boolean;
  isErrorSeen?: boolean;
  isLoading?: boolean;
  isLoadingSeen?: boolean;
  isPlanFeaturesLoading: boolean;
  isStreaming?: boolean;
  loadingMessage?: string;
  markdownResponse: string;
  notUpgraded?: boolean;
  onAdjustAnswerSettings?: () => void;
  onRefetch?: () => void;
  onTryAgainClick?: () => void;
  onUpgradeButtonClick?: () => void;
  promptId: string | null;
  searchedSources?: Source[];
  sources: Source[];
  staticResponse?: MessageDescriptor;
  updateBlock?: (updates: Partial<MemberAskDoraBlockState>) => void;
}) => {
  const {
    canSwapSource,
    errorMessage,
    filterCount,
    htmlResponse,
    isAdmin,
    isAnswerSeen = true,
    isDoraIncludedInPlan,
    isError = false,
    isErrorSeen = true,
    isPlanFeaturesLoading,
    isLoading = false,
    isLoadingSeen = true,
    isStreaming = false,
    loadingMessage,
    markdownResponse,
    notUpgraded,
    onAdjustAnswerSettings,
    onRefetch,
    onTryAgainClick,
    onUpgradeButtonClick,
    promptId,
    searchedSources = [],
    sources,
    staticResponse,
    updateBlock,
  } = props;

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (isError && !isErrorSeen) {
      updateBlock?.({ isErrorSeen: true });
    }
  }, [isError, isErrorSeen, updateBlock]);

  useEffect(() => {
    if (isLoading && !isLoadingSeen) {
      updateBlock?.({ isLoadingSeen: true });
    }
  }, [isLoading, isLoadingSeen, updateBlock]);

  useEffect(() => {
    if (!isLoading && !isAnswerSeen) {
      updateBlock?.({ isAnswerSeen: true });
    }
  }, [isLoading, isAnswerSeen, updateBlock]);

  if (isLoading && loadingMessage) {
    return (
      <DoraSingleMessage
        shouldAnimate={!isLoadingSeen}
        notUpgraded={notUpgraded}
      >
        <DoraLoadingLabel label={loadingMessage} />
      </DoraSingleMessage>
    );
  }

  let content;

  if (isError) {
    content = (
      <div className="flex">
        <ExclamationTriangleIcon className="mr-2 mt-1 h-4 w-4 text-gray-9" />
        <TextStyle variant="base-regular" className="flex-1 text-gray-9">
          {errorMessage ?? formatMessage(sharedMessages.genericError)}
        </TextStyle>
      </div>
    );
  } else if (htmlResponse) {
    content = (
      <>
        <TextStyle
          as="span"
          className={twMerge(
            'space-y-2',
            !isPlanFeaturesLoading && !isDoraIncludedInPlan && 'blur-sm'
          )}
          html={htmlResponse}
        />
        {!isStreaming && (
          <TextStyle
            as="p"
            className={twMerge(
              'mt-2',
              !isPlanFeaturesLoading && !isDoraIncludedInPlan && 'blur-sm'
            )}
            variant="base-regular"
            html={formatMessage(messages.doraFooterLabel)}
          />
        )}
      </>
    );
  } else if (staticResponse) {
    content = (
      <TextStyle as="span" className="space-y-2">
        {formatMessage(staticResponse, displayValuesMapper)}
      </TextStyle>
    );
  }

  return (
    content && (
      <>
        <DoraAvatar shouldAnimate={!isAnswerSeen} notUpgraded={notUpgraded} />
        <AnimateChat shouldAnimateOnMount={!isAnswerSeen}>
          <div className="col-start-2 flex flex-col gap-y-2 self-start antialiased">
            <DoraMessageBubble>
              {content}
              {Boolean(promptId) && (
                <DoraResponseFooter
                  canSwapSource={canSwapSource}
                  filterCount={filterCount}
                  sources={sources}
                  onAdjustAnswerSettings={onAdjustAnswerSettings}
                  onRefetch={onRefetch}
                  promptId={promptId}
                  markdownResponse={markdownResponse}
                  searchedSources={searchedSources}
                  hideFeedbackLabel
                />
              )}
            </DoraMessageBubble>
            {!isPlanFeaturesLoading &&
              !isDoraIncludedInPlan &&
              !isStreaming && (
                <div className="col-start-2 flex self-start">
                  {Boolean(htmlResponse) && (
                    <>
                      {isAdmin && onUpgradeButtonClick ? (
                        <UpgradeButton
                          onUpgradeButtonClick={onUpgradeButtonClick}
                          text={formatMessage(
                            messages.upgradeToViewAnswerLabel
                          )}
                        />
                      ) : (
                        <div className="flex flex-row items-center px-3 py-1">
                          <RocketLaunchIcon className="mr-2 h-4 w-4 stroke-2 text-upgrade-9" />
                          <TextStyle
                            className="text-upgrade-9"
                            variant="sm-medium"
                          >
                            {formatMessage(messages.askAdmin)}
                          </TextStyle>
                        </div>
                      )}
                    </>
                  )}
                  {Boolean(staticResponse) &&
                  Boolean(isAdmin) &&
                  onUpgradeButtonClick ? (
                    <UpgradeButton
                      onUpgradeButtonClick={onUpgradeButtonClick}
                      text={formatMessage(messages.upgradeToGetMoreAnswers)}
                    />
                  ) : null}
                </div>
              )}
          </div>
        </AnimateChat>
        {isError && onTryAgainClick ? (
          <TryAgainMessage
            onClick={onTryAgainClick}
            shouldAnimateOnMount={!isErrorSeen}
          >
            {formatMessage(messages.tryAgain)}
          </TryAgainMessage>
        ) : null}
      </>
    )
  );
};
