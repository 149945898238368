import {
  Avatar,
  AvatarSize,
  IconButton,
  TextStyle,
  Tooltip,
} from '@assembly-web/ui';
import { PencilIcon } from '@heroicons/react/24/outline';
import { type FocusEvent, type ReactNode, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { AnimateChat } from './AnimateChat';

const messages = defineMessages({
  editLabel: {
    defaultMessage: 'Edit',
    id: 'wEQDC6',
  },
  editing: {
    defaultMessage: 'Editing...',
    id: '6CJjWk',
  },
  editTooltip: {
    defaultMessage: 'Edit response',
    id: 'zudqar',
  },
});

export const MemberChatMessage = (props: {
  children: ReactNode;
  fullName: string;
  image?: string;
  isEditDisabled?: boolean;
  isEditing?: boolean;
  isFaded?: boolean;
  tooltip?: ReactNode;
  memberId: string;
  notUpgraded?: boolean;
  onEdit?: () => void;
  shouldAnimate: boolean;
  setIsTooltipActive?: (active?: boolean) => void;
}) => {
  const {
    children,
    fullName,
    image,
    isEditDisabled = true,
    isEditing,
    isFaded,
    tooltip,
    memberId,
    notUpgraded,
    onEdit,
    shouldAnimate,
    setIsTooltipActive,
  } = props;

  const { formatMessage } = useIntl();
  const [isEditActive, setIsEditActive] = useState(false);
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    if (!isEditDisabled) {
      // Reset if the edit button becomes disabled so that it's not showing by default when it's re-enabled.
      setIsEditActive(false);
    }
  }, [isEditDisabled]);

  const handleMouseEnter = () => {
    if (!isTouch) {
      setIsEditActive(true);
      setIsTooltipActive?.(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isTouch) {
      setIsEditActive(false);
      setIsTooltipActive?.(false);
    }
  };

  const handleTouchStart = () => {
    setIsTouch(true);
    setIsEditActive(!isEditActive);
    setIsTooltipActive?.();
  };

  const handleFocus = () => {
    if (!isTouch) {
      setIsEditActive(true);
      setIsTooltipActive?.(true);
    }
  };

  const handleBlur = (e: FocusEvent) => {
    if (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) {
      return;
    }

    setIsEditActive(false);
    setIsTooltipActive?.(false);
  };

  return (
    <>
      <AnimateChat shouldAnimateOnMount={shouldAnimate}>
        <div className="col-start-1">
          <Avatar
            className={twMerge(
              isEditing ? 'mt-10' : 'mt-2',
              isFaded ? 'opacity-50' : 'opacity-100'
            )}
            image={image}
            memberID={memberId}
            name={fullName}
            size={AvatarSize.Medium}
          />
        </div>
      </AnimateChat>
      <AnimateChat shouldAnimateOnMount={shouldAnimate}>
        <div className="col-start-2 flex antialiased">
          <div>
            {Boolean(isEditing) && (
              <TextStyle
                className="rounded-t-lg bg-gray-3 px-4 py-1 text-gray-9"
                variant="xs-regular"
              >
                {formatMessage(messages.editing)}
              </TextStyle>
            )}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions*/}
            <div
              className={twMerge(
                'relative bg-primary-6 px-4 py-3 text-gray-1',
                notUpgraded && 'bg-upgrade-4 text-warning-9',
                isEditing ? 'rounded-b-lg' : 'rounded-lg',
                isFaded ? 'opacity-50' : 'opacity-100'
              )}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onTouchStart={handleTouchStart}
              tabIndex={isEditDisabled ? undefined : 0}
            >
              {children}
              {!isEditDisabled && Boolean(isEditActive) && (
                <div className="absolute right-2 top-2">
                  <Tooltip
                    align="center"
                    side="top"
                    tooltipText={formatMessage(messages.editTooltip)}
                  >
                    <IconButton
                      aria-label={formatMessage(messages.editLabel)}
                      onClick={onEdit}
                      onTouchStart={(ev) => ev.stopPropagation()}
                      size="small"
                      variation="tertiaryLite"
                    >
                      <PencilIcon className="h-4 w-4 text-gray-8" />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {tooltip}
            </div>
          </div>
        </div>
      </AnimateChat>
    </>
  );
};
