import {
  DoraAPIEndpoints,
  type DoraChatHistoryAPIResponse,
} from '@assembly-web/services';
import { doraAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export const getDoraChatHistoryQueryKey = (threadId: string) => [
  'doraChatHistory',
  threadId,
];

export function getDoraChatHistoryQuery(
  threadId: string,
  options?: UseQueryOptions<DoraChatHistoryAPIResponse>
): UseQueryOptions<DoraChatHistoryAPIResponse> {
  return {
    ...options,
    queryKey: getDoraChatHistoryQueryKey(threadId),
    queryFn: async () => {
      const response = await doraAPI.get<DoraChatHistoryAPIResponse>(
        DoraAPIEndpoints.getChatHistory,
        {
          params: {
            thread_id: threadId,
          },
        }
      );

      return response.data;
    },
  };
}
