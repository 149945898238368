import type { ContentOpenEndedBlockState } from '@assembly-web/services';
import { BlockLabel, Checkbox, TextStyle } from '@assembly-web/ui';
import { type ReactNode, useId } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetOpenEndedBlockDetails,
  useSetFormattingOptions,
} from '../../../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../../../services/analytics';
import { useBlockIdContext } from '../../../context/BlockIdContext';
import { useEditorDataContext } from '../../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../../hooks/useAnyOccurrenceInProgress';
import { useFormattedBlockName } from '../../../hooks/useFormattedBlockName';

const messages = defineMessages({
  label: { defaultMessage: 'Formatting options', id: 'JB/hpH' },
  all: {
    defaultMessage: 'Show all formatting and attachment options',
    id: 'VmW6KC',
  },
  mentions: { defaultMessage: 'Mentions (@FirstNameLastName)', id: '2vD77Q' },
  emojis: { defaultMessage: 'Emojis', id: 'YpDhtm' },
  gifs: { defaultMessage: 'GIFs', id: 'RYSEcv' },
  attachments: { defaultMessage: 'Attachments', id: 'Vlx13n' },
});

type TypesWithoutTasks =
  | Exclude<keyof ContentOpenEndedBlockState['openEndedOptions'], 'tasks'>
  | 'all';

const typeToLabelMap = {
  all: 'all',
  attachments: 'attachments',
  emojis: 'emojis',
  gifs: 'gifs',
  mentions: 'mentions',
} satisfies Record<TypesWithoutTasks, keyof typeof messages>;

function Item({ type }: { type: TypesWithoutTasks }) {
  const { formatMessage } = useIntl();
  const elId = useId();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const selected =
    useGetOpenEndedBlockDetails(id, blockId, (state) =>
      type === 'all'
        ? Object.entries(state.openEndedOptions).every(([, value]) =>
            Boolean(value)
          )
        : (state.openEndedOptions[type] ?? false)
    ) ?? false;

  const label = formatMessage(messages[typeToLabelMap[type]]);

  const set = useSetFormattingOptions(id, blockId, type);

  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleChange = () => {
    trackFlowEditorAction('blockDetailClicked', {
      blockType: formattedBlockName,
      detailType: `${formattedBlockName}${((): string => {
        return (
          {
            all: 'ShowAllOptions',
            attachments: 'Attachments',
            emojis: 'Emojis',
            gifs: 'Gifs',
            mentions: 'Mentions',
          } satisfies Record<TypesWithoutTasks, string>
        )[type];
      })()}`,
    });
    set();
  };

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        id={elId}
        checked={selected}
        onChange={handleChange}
        disabled={isOccurrenceInProgress}
      />
      <label htmlFor={elId}>
        <TextStyle as="span" variant="sm-regular">
          {label}
        </TextStyle>
      </label>
    </div>
  );
}

function Group({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col gap-2 [&>*:not(:first-child)]:ml-6">
      {children}
    </div>
  );
}

export function FormattingOptions() {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-col gap-2">
      <BlockLabel>{formatMessage(messages.label)}</BlockLabel>
      <Group>
        <Item type="all" />
        <Item type="attachments" />
        <Item type="emojis" />
        <Item type="gifs" />
        <Item type="mentions" />
      </Group>
    </div>
  );
}
