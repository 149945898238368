import type { AssistantHistoryItem } from '@assembly-web/services';
import { AskDoraOverflowCard } from '@assembly-web/ui';

import { useGetDoraChatHistoryQuery } from '../../../hooks/dora/useChatHistoryQuery';
import { useAskDoraStore } from '../../../stores/useAskDoraStore';
import type { Drawer } from '../types';

export const AskDoraDrawerOverflowCard = (
  props: Drawer & {
    onClick: () => void;
    onClose: () => void;
  }
) => {
  const { onClick, onClose, title } = props;
  const threadId = useAskDoraStore((store) => store.getThreadId());

  const { data: chatHistoryData, isLoading } = useGetDoraChatHistoryQuery(
    threadId,
    {
      enabled: Boolean(threadId),
    }
  );

  const lastMessageTS = chatHistoryData
    ?.filter(
      (message): message is AssistantHistoryItem => message.role === 'assistant'
    )
    .pop()?.createdAt;

  return (
    <AskDoraOverflowCard
      isLoading={Boolean(isLoading) && Boolean(threadId)}
      numMessages={chatHistoryData?.length ?? 0}
      title={title}
      onClose={onClose}
      onClick={onClick}
      lastMessageTS={lastMessageTS}
    />
  );
};
