import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  header: {
    defaultMessage: `Sit tight while we create your account! It'll be ready shortly.`,
    id: '570KBK',
  },
  loadingMsg1: {
    defaultMessage: 'Connecting you with your team',
    id: 'gFCRmK',
  },
  loadingMsg2: {
    defaultMessage: 'Gathering important information',
    id: 'u+ErsI',
  },
  loadingMsg3: {
    defaultMessage: 'Organizing your work',
    id: 'SXnqCU',
  },
  loadingMsg4: {
    defaultMessage: 'Getting you in the flow',
    id: 'a0MMec',
  },
});

function MessageScroller() {
  const { formatMessage } = useIntl();
  const msgs = useMemo(() => {
    return [
      formatMessage(messages.loadingMsg1),
      formatMessage(messages.loadingMsg2),
      formatMessage(messages.loadingMsg3),
      formatMessage(messages.loadingMsg4),
    ];
  }, [formatMessage]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % msgs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [msgs.length]);

  return (
    <div className="mt-8 flex h-6 items-center">
      <AnimatePresence mode="wait">
        <motion.div
          key={index}
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: '0%', opacity: 1 }}
          exit={{ x: '-100%', opacity: 0 }}
          transition={{ duration: 0.75 }}
          className="whitespace-nowrap"
        >
          {msgs[index]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export function AccountCreationLoadingIndicator() {
  const { formatMessage } = useIntl();

  return (
    <section
      className="mt-8 flex flex-col items-center justify-center"
      style={{ height: 'calc(100vh - 40px)' }}
    >
      <div className="text-neutral-800 mb-12 text-center text-3xl font-medium leading-9 md:w-1/2 lg:w-1/3">
        {formatMessage(messages.header)}
      </div>
      <div className="flex items-center justify-center space-x-2">
        <div className="h-6 w-6 animate-bounce rounded-full bg-primary-6"></div>
        <div className="h-6 w-6 animate-bounce rounded-full bg-primary-6 animation-delay-100"></div>
        <div className="h-6 w-6 animate-bounce rounded-full bg-primary-6 animation-delay-200"></div>
      </div>
      <MessageScroller />
    </section>
  );
}
