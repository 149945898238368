import { AnimatePresence } from 'framer-motion';

import type { BlocksGroupInputProps } from '../../../../types';
import { Selector } from './Selector';

export const BlocksGroupInput = (props: BlocksGroupInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useBlocksGroupSetting },
  } = props;

  const shouldRequestInput = useBlocksGroupSetting(
    (store) => store.shouldRequestInput
  );

  const shouldRequestEdit = useEditSettingsStore(
    (store) => store.blocksGroup.shouldRequestInput
  );

  return (
    <AnimatePresence>
      {Boolean(shouldRequestInput || shouldRequestEdit) && (
        <Selector {...props} />
      )}
    </AnimatePresence>
  );
};
