import { TextStyle } from '@assembly-web/ui';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { CloseDrawerContext } from '../../../contexts/CloseDrawerContext';
import { trackPopoutAction } from '../../../services/analytics';
import { accessDrawer, type Drawer } from '../types';

const messages = defineMessages({
  recentlyOpened: {
    defaultMessage: 'Recently opened',
    id: 'W3/xbS',
  },
  pastWeek: {
    defaultMessage: 'Past week',
    id: 'UH48JX',
  },
  pastMonth: {
    defaultMessage: 'Past month',
    id: 'Mnl25I',
  },
  itemsDisappear: {
    defaultMessage:
      '👆 Items disappear from this list 30 days after you last interact with it. If you want to find something after that, use search.',
    id: 'eGXSXc',
  },
});

export function OverflowMenuContent(props: {
  onOverflowCardClick: (id: string) => void;
}) {
  const { onOverflowCardClick } = props;
  const { formatMessage } = useIntl();

  const { openModal: openConfirmationCloseModal } =
    useContext(CloseDrawerContext);

  const overflowDrawers = useMultiDrawerStore((store) =>
    store.getOverflowDrawers()
  );
  const deleteOverflowDrawer = useMultiDrawerStore(
    (store) => store.deleteOverflowDrawer
  );

  const [recentGroup, setRecentGroup] = useState<Drawer[]>([]);
  const [pastWeekGroup, setPastWeekGroup] = useState<Drawer[]>([]);
  const [pastMonthGroup, setPastMonthGroup] = useState<Drawer[]>([]);

  useEffect(() => {
    // group drawers by lastInteractedTS
    const timeNow = dayjs();
    const recent: Drawer[] = [];
    const pastWeek: Drawer[] = [];
    const pastMonth: Drawer[] = [];

    overflowDrawers.forEach((drawer) => {
      const diffInDays = timeNow.diff(drawer.lastInteractedTS, 'd');
      if (diffInDays === 0) {
        recent.push(drawer);
      } else if (diffInDays < 7) {
        pastWeek.push(drawer);
      } else if (diffInDays < 30) {
        pastMonth.push(drawer);
      }
    });
    setRecentGroup(recent);
    setPastWeekGroup(pastWeek);
    setPastMonthGroup(pastMonth);
  }, [overflowDrawers]);

  const onCardClose = (drawer: Drawer) => {
    accessDrawer(drawer, drawer.type, (config, args) => {
      if (config.shouldConfirmOnClose?.(args)) {
        openConfirmationCloseModal(args);
      } else {
        deleteOverflowDrawer(args.id);
        config.onClose?.(args);
      }
    });
    trackPopoutAction('exitOverflowItemClicked', {
      popoutType: drawer.type,
    });
  };

  const onCardClick = ({ id, type }: { id: string; type: string }) => {
    onOverflowCardClick(id);
    trackPopoutAction('overflowPopoutListItemClicked', { popoutType: type });
  };
  const displayGroup = (title: MessageDescriptor, drawers: Drawer[]) => {
    return drawers.length > 0 ? (
      <section>
        <TextStyle variant="sm-medium">{formatMessage(title)}</TextStyle>
        <div className="mt-1 grid gap-2">
          {drawers.map((drawer) => {
            return accessDrawer(drawer, drawer.type, (config, props) => {
              const { id, type } = props;
              const overflowCardProps = {
                ...props,
                onClose: () => onCardClose(drawer),
                onClick: () => onCardClick({ id, type }),
              };
              const OverflowCardComponent = config.overflowCard;
              return (
                <OverflowCardComponent
                  key={`overflow-${drawer.id}`}
                  {...overflowCardProps}
                />
              );
            });
          })}
        </div>
      </section>
    ) : null;
  };

  return (
    <div className="flex h-full flex-col gap-3 overflow-y-scroll px-6 py-4">
      {displayGroup(messages.recentlyOpened, recentGroup)}
      {displayGroup(messages.pastWeek, pastWeekGroup)}
      {displayGroup(messages.pastMonth, pastMonthGroup)}
      {pastMonthGroup.length > 0 && (
        <TextStyle className="pb-2" variant="sm-regular">
          {formatMessage(messages.itemsDisappear)}
        </TextStyle>
      )}
    </div>
  );
}
