import type { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import { createContext, useRef, useState } from 'react';

import type { DoraEventSourceData } from '../hooks/useDoraEventSource';
import { useDoraEventSource } from '../hooks/useDoraEventSource';

export const AskDoraContext = createContext<
  Omit<DoraEventSourceData, 'reset'> & {
    followUpBlocks: ReactElement[];
    getPrevQuestionsAsked: () => string[] | undefined;
    prevAskedQuestions?: string[];
    resetContext: () => void;
    savePrevQuestionAsked: (question: string) => void;
    setFollowUpBlocks: Dispatch<SetStateAction<ReactElement[]>>;
  }
>({
  clearSuggestedQuestions: () => {},
  followUpBlocks: [],
  getDoraResponse: () => {},
  getPrevQuestionsAsked: () => undefined,
  htmlResponse: null,
  isDoraIncludedInPlan: false,
  isError: false,
  isLoading: false,
  isStreaming: false,
  markdownResponse: null,
  promptId: null,
  resetContext: () => {},
  savePrevQuestionAsked: () => {},
  setFollowUpBlocks: () => {},
  sources: [],
});

export const AskDoraProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [followUpBlocks, setFollowUpBlocks] = useState<ReactElement[]>([]);
  const suggestedQuestionsHistory = useRef<string[]>();
  const doraEventData = useDoraEventSource();

  const resetContext = () => {
    doraEventData.reset();
    setFollowUpBlocks([]);
    suggestedQuestionsHistory.current = undefined;
  };

  const savePrevQuestionAsked = (question: string) => {
    suggestedQuestionsHistory.current = [
      ...(suggestedQuestionsHistory.current ?? []),
      question,
    ];
  };

  const getPrevQuestionsAsked = () => suggestedQuestionsHistory.current;

  return (
    <AskDoraContext.Provider
      value={{
        ...doraEventData,
        followUpBlocks,
        getPrevQuestionsAsked,
        resetContext,
        savePrevQuestionAsked,
        setFollowUpBlocks,
      }}
    >
      {children}
    </AskDoraContext.Provider>
  );
};
