import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  participantLabel: {
    defaultMessage: 'Participant',
    id: 'fVMECF',
  },
  approverLabel: {
    defaultMessage: 'Approver',
    id: 'OkL2JB',
  },
  participantApproverLabel: {
    defaultMessage: 'Participant and Approver',
    id: 'nEy8I7',
  },
  removeRuleLabel: {
    defaultMessage: 'Remove rule',
    id: 'E8UYvP',
  },
  exclusionTitle: {
    defaultMessage: 'Exclude from challenge',
    id: 'g9kXLd',
  },
  exclusionLabel: {
    defaultMessage: 'Excluded',
    id: 'sCOZQ4',
  },
  transferOwnership: {
    defaultMessage: 'Transfer Ownership',
    id: 'd2dgcX',
  },
  exclusionSubtitle: {
    defaultMessage: 'Can’t participate or approve on this challenge',
    id: 'pidlNH',
  },
  managerSubtitle: {
    defaultMessage:
      'Each manager will be able to approve their own direct reports’ claims',
    id: 'N6eMqx',
  },
  removePersonLabel: {
    defaultMessage: 'Remove person',
    id: 'onUk2A',
  },
  deactivatedOwnerToolTip: {
    defaultMessage:
      'Owner deactivated. Please select a new owner in order to remove this person from the challenge',
    id: 'Zut6RW',
  },
});
