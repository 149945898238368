import type { CollectionsAPIResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { defineMessages, useIntl } from 'react-intl';

import { trackDiscoverError } from '../../services/analytics';
import { navItemsQueryKey } from './useNavData';

const messages = defineMessages({
  pinError: {
    defaultMessage: 'Failed to pin item. Please try again.',
    id: 'ZWw1bV',
  },
  pinSuccess: {
    defaultMessage: 'Item successfully pinned.',
    id: 'MuYdfN',
  },
});

type PinItem = {
  id: string;
  type: 'collection';
};

export function usePinItemToNav() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { showErrorToast, showSuccessToast } = useToastStore();

  return useMutation({
    mutationFn: async (payload: PinItem) => {
      return assemblyAPI.post(APIEndpoints.pinItemToNav, { item: payload });
    },
    onSuccess: (data, payload) => {
      const collectionQueryKey = ['collections'];
      const previousCollections =
        queryClient.getQueryData<CollectionsAPIResponse>(collectionQueryKey);
      let updatedCollections;

      updatedCollections = produce(previousCollections, (draft) => {
        if (draft) {
          draft.data = draft.data.map((collection) => {
            if (collection.collectionId === payload.id) {
              return {
                ...collection,
                isPinned: true,
              };
            }
            return collection;
          });
        }
      });
      queryClient.setQueryData(collectionQueryKey, updatedCollections);
      showSuccessToast(formatMessage(messages.pinSuccess));
      queryClient.invalidateQueries(navItemsQueryKey);
    },
    onError: (err: unknown, payload) => {
      const errorInfo = err instanceof Error ? err : undefined;

      logger.error(
        'Error pinning item',
        { ...payload, error: errorInfo },
        errorInfo
      );

      showErrorToast(formatMessage(messages.pinError));
      trackDiscoverError({ action: 'pinItemToNav' });
    },
  });
}
