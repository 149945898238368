import type { ContentOpenEndedBlockState } from '@assembly-web/services';
import { BlockLabel, NumberInput, TextStyle } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetOpenEndedBlockDetails,
  useSetContentLimit,
} from '../../../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../../../services/analytics';
import { useBlockIdContext } from '../../../context/BlockIdContext';
import { useEditorDataContext } from '../../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../../hooks/useAnyOccurrenceInProgress';
import { useFormattedBlockName } from '../../../hooks/useFormattedBlockName';

const messages = defineMessages({
  limitLabel: {
    defaultMessage: 'Content limits',
    id: 'SNRwRH',
  },
  contentLimit: {
    defaultMessage:
      '<minLimit>_</minLimit> <wrap>to</wrap> <maxLimit>_</maxLimit> <wrap>characters</wrap>',
    id: 'AK9+pm',
  },
  minimum: {
    defaultMessage: 'Minimum',
    id: 'Amt/8b',
  },
  maximum: {
    defaultMessage: 'Maximum',
    id: 'BCzmdv',
  },
  footnote: {
    defaultMessage:
      'NOTE: Entering a content minimum will require the participant to answer this question.',
    id: '1JrYku',
  },
});

type LimitProps = {
  type: 'min' | 'max';
};

const LimitLabelMap = { max: 'maximum', min: 'minimum' } satisfies Record<
  LimitProps['type'],
  keyof typeof messages
>;

const limitAttributeMap = {
  max: 'maximumCharacters',
  min: 'minimumCharacters',
} satisfies Record<
  LimitProps['type'],
  keyof Pick<
    ContentOpenEndedBlockState,
    'minimumCharacters' | 'maximumCharacters'
  >
>;

function Limit({ type }: LimitProps) {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const value =
    useGetOpenEndedBlockDetails(
      id,
      blockId,
      (state) => state[limitAttributeMap[type]]
    ) ?? undefined;

  const set = useSetContentLimit(id, blockId, limitAttributeMap[type]);
  const formattedBlockName = useFormattedBlockName();

  const label = formatMessage(messages[LimitLabelMap[type]]);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <NumberInput
      minValue={1}
      labelHidden
      label={label}
      placeholder={label}
      value={value}
      isDisabled={isOccurrenceInProgress}
      onFocus={() => {
        trackFlowEditorAction('blockDetailClicked', {
          blockType: formattedBlockName,
          detailType: `${formattedBlockName}ContentLimits`,
        });
      }}
      onChange={(value) => {
        set((state) => {
          if (Number.isNaN(value)) {
            return NaN;
          }

          if (type === 'min' && !state.maximumCharacters) {
            return value > 0 ? value : 1;
          }

          if (type === 'max' && !state.minimumCharacters) {
            return value > 0 ? value : 1;
          }

          if (type === 'max' && value < (state.minimumCharacters ?? 1)) {
            return state.minimumCharacters ?? 1;
          }

          if (type === 'min' && value > (state.maximumCharacters ?? 1)) {
            return state.maximumCharacters ?? 1;
          }

          return value;
        });
      }}
    />
  );
}

export function ContentLimit() {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-col gap-1">
      <BlockLabel>{formatMessage(messages.limitLabel)}</BlockLabel>
      <div className="flex items-center gap-2">
        {formatMessage(messages.contentLimit, {
          minLimit: () => <Limit type="min" />,
          maxLimit: () => <Limit type="max" />,
          wrap: (children) => (
            <TextStyle as="span" variant="sm-regular">
              {children}
            </TextStyle>
          ),
        })}
      </div>
      <TextStyle variant="xs-italic">
        {formatMessage(messages.footnote)}
      </TextStyle>
    </div>
  );
}
