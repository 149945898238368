import {
  type IndividualRespondentsQuestionProps,
  RespondentsGroup,
} from '../../../../types';
import { Message } from './Message';

export const IndividualRespondentsQuestion = (
  props: IndividualRespondentsQuestionProps
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useRespondentsGroupSetting },
  } = props;

  const isOrigRespondentsGroupSet = useRespondentsGroupSetting(
    (store) => store.isSet
  );

  const origRespondentsGroupValue = useRespondentsGroupSetting(
    (store) => store.value
  );

  const isEditingPrevSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'respondentsGroup'
  );

  const isEditedRespondentsGroupSet = useEditSettingsStore(
    (store) => store.respondentsGroup.isSet
  );

  const isSet = isEditedRespondentsGroupSet || isOrigRespondentsGroupSet;

  const editedRespondentsGroupValue = useEditSettingsStore(
    (store) => store.respondentsGroup.value
  );

  const value = editedRespondentsGroupValue ?? origRespondentsGroupValue;

  return (
    isSet &&
    !isEditingPrevSetting &&
    value === RespondentsGroup.Individual && <Message {...props} />
  );
};
