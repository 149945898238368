import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { isAxiosError } from 'axios';

export type SubmitClaimPayload = {
  challengeId: string;
  proof?: {
    text: {
      plainText: string;
      messageTokens: string;
      messageHtml: string;
    };
  };
};

export type SubmitClaimResponse = {
  challengeId: string;
  claimId: string;
};

export function useSubmitClaimMutation({
  onError,
  onSuccess,
}: {
  onError: (
    payload: SubmitClaimPayload,
    error: string | undefined,
    errorInfo: AxiosError | undefined
  ) => void;
  onSuccess: (data: AxiosResponse<SubmitClaimResponse>) => void;
}) {
  return useMutation({
    mutationFn: async (payload: SubmitClaimPayload) => {
      return await assemblyAPI.post<SubmitClaimResponse>(
        APIEndpoints.submitClaim,
        payload
      );
    },
    onSuccess: async (data) => {
      onSuccess(data);
    },
    onError: (error: unknown, payload) => {
      const errorInfo = isAxiosError(error) ? error : undefined;
      const errorMsg = errorInfo?.response?.data?.body;
      onError(payload, errorMsg, errorInfo);
    },
  });
}
