import { reportInsightsLoading } from '@assembly-web/assets';
import type {
  DoraReportingCategory,
  Member,
  Timeout,
} from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import {
  convertMarkDownResponseToHTML,
  messages as sharedMessages,
} from '../../../services/dora';
import type { createDoraDrawerStore } from '../../../stores/doraChatStore';
import { DoraLoadingLabel } from '../../dora/DoraLoadingLabel';
import { AnimateChat } from '../shared/dora/AnimateChat';
import { timeouts } from '../shared/dora/constants';
import { DoraMessageBubble } from '../shared/dora/DoraMessageBubble';
import { DoraSingleMessage } from '../shared/dora/DoraSingleMessage';
import { DoraStackedMessages } from '../shared/dora/DoraStackedMessages';
import { TryAgainMessage } from '../shared/dora/TryAgainMessage';
import { DoraChatReportAnswer } from './DoraChatReportAnswer';
import { DoraChatReportPrompt } from './DoraChatReportPrompt';

const messages = defineMessages({
  answeringNow: {
    defaultMessage: 'Answering now',
    id: '8h6QdP',
  },
  secondaryLoadingTitle: {
    defaultMessage: 'Analyzing...',
    id: 'mmW0Dh',
  },
  secondaryLoadingSubTitle: {
    defaultMessage:
      "This may take a minute. You can minimize this window and I'll notify you when I'm done!",
    id: 'ouryVk',
  },
  tryAgain: {
    defaultMessage: 'Try again to get an answer',
    id: 'PZQRU7',
  },
});

export function QuestionAnswerBlock({
  reportingCategory,
  doraChatStore,
  getDoraResponse,
  isFullScreen,
  isResponseBubbleVisible,
  member,
}: {
  reportingCategory: DoraReportingCategory;
  doraChatStore: ReturnType<typeof createDoraDrawerStore>;
  getDoraResponse: (prompt: string, threadId?: string) => void;
  isFullScreen?: boolean;
  isResponseBubbleVisible: boolean;
  member: Member;
}) {
  const useDoraChatStore = doraChatStore;
  const { formatMessage } = useIntl();

  const isLoadingSeen = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isLoadingSeen ?? false
  );

  const isAnswerSeen = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isAnswerSeen ?? false
  );

  const isErrorSeen = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isErrorSeen ?? false
  );

  const errorType = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.errorType
  );

  const isError = Boolean(errorType);

  const isLoading = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isLoading ?? false
  );

  const isStreaming = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isStreaming ?? false
  );

  const prompt = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.prompt ?? ''
  );

  const threadId = useDoraChatStore(
    (store) => store.reportingInsights?.threads?.[0].id ?? ''
  );

  const markdownResps = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.markdownResps
  );

  const htmlResponses = useMemo(
    () =>
      markdownResps?.map(
        (markdownResponse, index) =>
          convertMarkDownResponseToHTML(markdownResponse, {
            showCursor: isStreaming && index === markdownResps.length - 1,
            showImageBanner: true,
          }) as string
      ),
    [isStreaming, markdownResps]
  );

  const markErrorSeen = useDoraChatStore((state) => state.markErrorSeen);
  const markAnswerSeen = useDoraChatStore((state) => state.markAnswerSeen);
  const markLoadingSeen = useDoraChatStore((state) => state.markLoadingSeen);

  useEffect(() => {
    if (isError && !isErrorSeen) {
      markErrorSeen();
    }
  }, [isError, isErrorSeen, markErrorSeen]);

  useEffect(() => {
    if (isLoading && !isLoadingSeen) {
      markLoadingSeen();
    }
  }, [isLoading, isLoadingSeen, markLoadingSeen]);

  useEffect(() => {
    if (!isAnswerSeen) {
      markAnswerSeen();
    }
  }, [htmlResponses, isAnswerSeen, markAnswerSeen]);

  const typingTimeout = useRef<Timeout>();
  const [shouldShowTyping, setShouldShowTyping] = useState(false);

  useEffect(() => {
    if (isLoading && !isStreaming && htmlResponses) {
      setShouldShowTyping(false);
      clearTimeout(typingTimeout.current);

      typingTimeout.current = setTimeout(() => {
        setShouldShowTyping(true);
      }, timeouts.showNextMessage);
    } else {
      setShouldShowTyping(false);
      clearTimeout(typingTimeout.current);
    }

    return () => {
      clearTimeout(typingTimeout.current);
    };
  }, [htmlResponses, isLoading, isStreaming]);

  const secondaryLoadingStateTimeout = useRef<Timeout>();

  const isSecondaryLoading = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isSecondaryLoading ?? false
  );

  const showSecondaryLoading = useDoraChatStore(
    (store) => store.showSecondaryLoading
  );

  useEffect(() => {
    if (isLoading) {
      secondaryLoadingStateTimeout.current = setTimeout(() => {
        showSecondaryLoading();
      }, 7000);
    }

    return () => {
      clearTimeout(secondaryLoadingStateTimeout.current);
    };
  }, [isLoading, showSecondaryLoading]);

  let responseBubble = null;

  if (isError && !htmlResponses) {
    let errorMessage;

    switch (errorType) {
      case 'RATE_LIMIT_ERROR':
        errorMessage = formatMessage(sharedMessages.rateLimitError);
        break;
      default:
        errorMessage = formatMessage(sharedMessages.genericError);
    }

    responseBubble = (
      <DoraSingleMessage shouldAnimate={!isErrorSeen}>
        <div className="flex">
          <ExclamationTriangleIcon className="mr-2 mt-1 h-4 w-4 text-gray-9" />
          <TextStyle variant="base-regular" className="flex-1 text-gray-9">
            {errorMessage}
          </TextStyle>
        </div>
      </DoraSingleMessage>
    );
  } else if (isLoading && !htmlResponses) {
    responseBubble = (
      <DoraStackedMessages shouldAnimateAvatar={!isLoadingSeen}>
        {isSecondaryLoading ? (
          <AnimateChat className="w-full" shouldAnimateOnMount={!isLoadingSeen}>
            <div className="w-full rounded-lg border border-gray-5 bg-gray-1 py-6">
              <div
                className={twMerge(
                  'mx-auto flex max-w-[611px] flex-col items-center justify-center gap-10',
                  isFullScreen && 'flex-row'
                )}
              >
                <img
                  alt=""
                  className="h-[88px] w-[234px]"
                  src={reportInsightsLoading}
                />
                <div className="px-2 md:w-[337px] md:p-0">
                  <TextStyle className="text-gray-9" variant="base-medium">
                    {formatMessage(messages.secondaryLoadingTitle)}
                  </TextStyle>
                  <TextStyle className="text-gray-9" variant="base-regular">
                    {formatMessage(messages.secondaryLoadingSubTitle)}
                  </TextStyle>
                </div>
              </div>
            </div>
          </AnimateChat>
        ) : (
          <AnimateChat shouldAnimateOnMount={!isLoadingSeen}>
            <DoraMessageBubble>
              <DoraLoadingLabel label={formatMessage(messages.answeringNow)} />
            </DoraMessageBubble>
          </AnimateChat>
        )}
      </DoraStackedMessages>
    );
  } else if (htmlResponses) {
    responseBubble = (
      <DoraChatReportAnswer
        reportingCategory={reportingCategory}
        htmlResponses={htmlResponses}
        isAnswerSeen={isAnswerSeen}
        isLoading={shouldShowTyping}
        shouldAnimate
        showFeedbackControl={false}
      />
    );
  }

  const handleTryAgainClick = () => {
    getDoraResponse(prompt, threadId);
  };

  return (
    <>
      <DoraChatReportPrompt
        memberDetails={member}
        prompt={prompt}
        shouldAnimate={!(isLoadingSeen || isAnswerSeen)}
      />
      {Boolean(isResponseBubbleVisible) && responseBubble}
      {Boolean(isError) && (
        <TryAgainMessage
          onClick={handleTryAgainClick}
          shouldAnimateOnMount={!isErrorSeen}
        >
          {formatMessage(messages.tryAgain)}
        </TryAgainMessage>
      )}
    </>
  );
}
