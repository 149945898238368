import type { FlowFeedParams } from '@assembly-web/services';
import { useCallback } from 'react';

import { useGetFlowFeed } from './useGetFlowFeed';

export const useGetFlowPostInterval = (
  flowId: string,
  filterParams: FlowFeedParams['filter'],
  enabled: boolean
) => {
  const flowFeedSharedParams = {
    filter: filterParams,
    limit: 1,
  };

  const {
    data: newestPostsResponse,
    isError: isNewestPostsError,
    isInitialLoading: isNewestPostsLoading,
    refetch: refetchNewestPosts,
  } = useGetFlowFeed(
    flowId,
    {
      ...flowFeedSharedParams,
      sortBy: 'desc',
    },
    enabled
  );

  const dateOfNewestPost = newestPostsResponse?.data[0]?.createdAt;

  const {
    data: oldestPostsResponse,
    isError: isOldestPostsError,
    isInitialLoading: isOldestPostsLoading,
    refetch: refetchOldestPosts,
  } = useGetFlowFeed(
    flowId,
    {
      ...flowFeedSharedParams,
      sortBy: 'asc',
    },
    enabled
  );

  const dateOfOldestPost = oldestPostsResponse?.data[0]?.createdAt;

  const refetch = useCallback(() => {
    if (isNewestPostsError) {
      refetchNewestPosts();
    }

    if (isOldestPostsError) {
      refetchOldestPosts();
    }
  }, [
    isNewestPostsError,
    isOldestPostsError,
    refetchNewestPosts,
    refetchOldestPosts,
  ]);

  return {
    data:
      dateOfOldestPost && dateOfNewestPost
        ? [new Date(dateOfOldestPost), new Date(dateOfNewestPost)]
        : undefined,
    isError: isNewestPostsError || isOldestPostsError,
    isInitialLoading: isNewestPostsLoading || isOldestPostsLoading,
    refetch,
  };
};
