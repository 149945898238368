import { useCurrency, useDisplayCurrency } from '@assembly-web/services';
import {
  BlockLabel,
  Checkbox,
  NumberInput,
  RadioGroup,
  TextStyle,
} from '@assembly-web/ui';
import { type ReactNode, useId } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetGivePointsExactLimit,
  useGetGivePointsPercentLimit,
  useGivePointsCurrencyHidden,
  useGivePointsLimitCurrencyEnabled,
  useGivePointsLimitType,
  useSetGivePointsExactLimit,
  useSetGivePointsLimitType,
  useSetGivePointsPercentLimit,
  useToggleGivePointsCurrencyValue,
  useToggleGivePointsLimit,
} from '../../../../../../../../stores/useFlowBuilderStore';
import { useBlockIdContext } from '../../../context/BlockIdContext';
import { useEditorDataContext } from '../../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../../hooks/useAnyOccurrenceInProgress';

const messages = defineMessages({
  label: {
    defaultMessage: 'More settings',
    id: '8ZKBkx',
  },
  limitLabel: {
    defaultMessage:
      'Limit the amount of {emoji} {currencies} that someone can give',
    id: 'pztKjM',
  },
  hideLabel: {
    defaultMessage:
      'Hide the amount of {emoji} {currencies} given once the response is posted',
    id: '7U/YEl',
  },
  limitPercent: {
    defaultMessage: 'Limit up to a percentage of the giver’s allownace',
    id: 'Askjzy',
  },
  limitExact: {
    defaultMessage: 'Limit up to an exact value',
    id: 'EXmQvi',
  },
  percent: {
    defaultMessage: '<percent>_</percent> percent',
    id: 'M3R5gj',
  },
  percentLabel: {
    defaultMessage: 'Percent',
    id: 'DqKd4r',
  },
  exactValueLabel: {
    defaultMessage: 'Exact value',
    id: '5HtnRk',
  },
});

function CheckItem({
  checked,
  disabled,
  label,
  onChange,
}: {
  checked: boolean;
  disabled?: boolean;
  label: ReactNode;
  onChange: () => void;
}) {
  const id = useId();

  return (
    <div className="flex items-center gap-2">
      <Checkbox
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>
        <TextStyle as="span" variant="sm-regular">
          {label}
        </TextStyle>
      </label>
    </div>
  );
}

function RadioContainer({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col gap-1 [&>:not(:first-child)]:ml-[26px]">
      {children}
    </div>
  );
}

function PercentLimit() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const percentLimit = useGetGivePointsPercentLimit(id, blockId);
  const set = useSetGivePointsPercentLimit(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <TextStyle as="span" className="flex items-center gap-2">
      {formatMessage(messages.percent, {
        percent: () => (
          <NumberInput
            value={percentLimit}
            onChange={set}
            label={formatMessage(messages.percentLabel)}
            minValue={0}
            maxValue={100}
            labelHidden
            isDisabled={isOccurrenceInProgress}
          />
        ),
      })}
    </TextStyle>
  );
}

function ExactLimit() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const currencyIcon = useDisplayCurrency();
  const exactLimit = useGetGivePointsExactLimit(id, blockId);
  const set = useSetGivePointsExactLimit(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <NumberInput
      minValue={0}
      value={exactLimit}
      onChange={set}
      label={formatMessage(messages.exactValueLabel)}
      labelHidden
      leadingIcon={currencyIcon}
      isDisabled={isOccurrenceInProgress}
    />
  );
}

function LimitSettings() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const type = useGivePointsLimitType(id, blockId);
  const set = useSetGivePointsLimitType(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <RadioGroup.Root
      className="flex flex-col gap-4"
      value={type}
      onValueChange={set}
      disabled={isOccurrenceInProgress}
    >
      <RadioContainer>
        <RadioGroup.Item value="PERCENT">
          {formatMessage(messages.limitPercent)}
        </RadioGroup.Item>
        {type === 'PERCENT' && <PercentLimit />}
      </RadioContainer>
      <RadioContainer>
        <RadioGroup.Item value="EXACT">
          {formatMessage(messages.limitExact)}
        </RadioGroup.Item>
        {type === 'EXACT' && <ExactLimit />}
      </RadioContainer>
    </RadioGroup.Root>
  );
}

function LimitAmount() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const currencyIcon = useDisplayCurrency();
  const currency = useCurrency();

  const limitCurrency = useGivePointsLimitCurrencyEnabled(id, blockId);
  const set = useToggleGivePointsLimit(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <CheckItem
      checked={limitCurrency}
      label={formatMessage(messages.limitLabel, {
        emoji: currencyIcon,
        currencies: currency.pluralName,
      })}
      onChange={set}
      disabled={isOccurrenceInProgress}
    />
  );
}

function HideAmount() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const currencyIcon = useDisplayCurrency();
  const currency = useCurrency();

  const currencyHidden = useGivePointsCurrencyHidden(id, blockId);
  const set = useToggleGivePointsCurrencyValue(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <CheckItem
      checked={currencyHidden}
      label={formatMessage(messages.hideLabel, {
        emoji: currencyIcon,
        currencies: currency.pluralName,
      })}
      onChange={set}
      disabled={isOccurrenceInProgress}
    />
  );
}

function LimitAmountSettings() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const limitCurrency = useGivePointsLimitCurrencyEnabled(id, blockId);

  if (!limitCurrency) {
    return null;
  }

  return (
    <div className="ml-6 py-1">
      <LimitSettings />
    </div>
  );
}

export function MoreSettings() {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-col gap-2">
      <BlockLabel>{formatMessage(messages.label)}</BlockLabel>
      <LimitAmount />
      <LimitAmountSettings />
      <HideAmount />
    </div>
  );
}
