import { doraAPI, DoraAPIEndpoints } from '@assembly-web/services';
import { useQuery } from '@tanstack/react-query';

type FlowSummaryMaxIntervalParams = {
  blockIds?: string;
  endDate?: string;
  flowId: string;
  isAnonymous?: boolean;
  respondedBy?: string;
  startDate?: string;
};

export const getFlowSummaryMaxTimeIntervalQuery = (
  params: FlowSummaryMaxIntervalParams,
  enabled?: boolean
) => ({
  enabled,
  queryKey: ['flowSummaryMaxTimeInterval', params],
  queryFn: async () => {
    const { data } = await doraAPI.get<string>(
      DoraAPIEndpoints.maxFlowSummaryTimeInterval,
      {
        params: {
          block_ids: params.blockIds,
          end_date: params.endDate,
          filter_responded_by: params.respondedBy,
          flowId: params.flowId,
          is_anonymous: params.isAnonymous,
          start_date: params.startDate,
        },
      }
    );

    if (params.endDate && !params.startDate) {
      return { endDate: params.endDate, startDate: data };
    } else if (!params.endDate && params.startDate) {
      return { endDate: data, startDate: params.startDate };
    }
    return { endDate: params.endDate, startDate: params.startDate };
  },
  retry: false,
});

export const useGetFlowSummaryMaxTimeInterval = (
  params: FlowSummaryMaxIntervalParams,
  enabled: boolean
) => {
  return useQuery<unknown, unknown, { endDate: string; startDate: string }>(
    getFlowSummaryMaxTimeIntervalQuery(params, enabled)
  );
};
