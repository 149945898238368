import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  everyone: {
    defaultMessage: 'Everyone',
    id: '2XDuH4',
  },
  department: {
    defaultMessage: 'Department',
    id: 'eXcD4S',
  },
  workLocation: {
    defaultMessage: 'Work location',
    id: '4mb2dn',
  },
  manager: {
    defaultMessage: 'Manager status',
    id: 'jfRiOS',
  },
  homeLocation: {
    defaultMessage: 'Home location',
    id: 'BdIFnv',
  },
  jobTitle: {
    defaultMessage: 'Job title',
    id: 'HsQ3BK',
  },
  everyoneInThisWorkspace: {
    defaultMessage: 'Everyone in this workspace',
    id: 'zqo7Vo',
  },
  departmentRule: {
    defaultMessage: 'Department {condition} {name}',
    id: 'PR5IEt',
  },
  workLocationRule: {
    defaultMessage: 'Work location {condition} {name}',
    id: 'kUPmA/',
  },
  managerRule: {
    defaultMessage: 'Manager status {condition}',
    id: 'kKQ/Qt',
  },
  homeLocationRule: {
    defaultMessage: 'Home location {condition} {name}',
    id: 'sE0gFE',
  },
  jobTitleRule: {
    defaultMessage: 'Job title {condition} {name}',
    id: '6r41bF',
  },
  is: {
    defaultMessage: 'is',
    id: 'fXdkiI',
  },
  isNot: {
    defaultMessage: 'is not',
    id: 'alA913',
  },
  isTrue: {
    defaultMessage: 'is true',
    id: '0rzFZS',
  },
  isFalse: {
    defaultMessage: 'is false',
    id: 'thJnXm',
  },
  inviteUponSharing: {
    defaultMessage: 'Invite upon sharing',
    id: 'zTWTNM',
  },
  nonMemberCriteriaTooltipWithEveryone: {
    defaultMessage:
      'These role settings will override ‘Everyone in this workspace,’ but will not override individual role settings',
    id: 'sx3r1f',
  },
  memberCriteriaTooltip: {
    defaultMessage:
      'These manual role settings will override any roles configured using rules.',
    id: 'rTuy8M',
  },
  filterCount: {
    defaultMessage: ' • {count} {count,plural,=0{} one{person} other{people}}',
    id: 't9BQEm',
  },
  ownerSubtitle: {
    defaultMessage: '{subTitle} • Owner',
    id: 'FGuNqA',
  },
  currentUserLabel: {
    defaultMessage: '{currentUser} (You)',
    id: 'UfG6o8',
  },
  isTheManagerOfTheParticipant: {
    defaultMessage: 'is the Manager of the Participant',
    id: 'Go/EJf',
  },
  approver: {
    defaultMessage: 'Approver',
    id: 'OkL2JB',
  },
  participant: {
    defaultMessage: 'Participant',
    id: 'fVMECF',
  },
  participantApproverLabel: {
    defaultMessage: 'Participant and Approver',
    id: 'nEy8I7',
  },
  all: {
    defaultMessage: 'All',
    id: 'zQvVDJ',
  },
});
