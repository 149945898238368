import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { GoogleLimitationModalContext } from '../contexts/GoogleLimitationModalContext';
import { trackAppConnectionError } from '../services/analytics';
import type { Connection } from '../types';

const messages = defineMessages({
  alreadyConnected: {
    defaultMessage: 'App already connected',
    id: 'YSdt6i',
  },
  errorConnecting: {
    defaultMessage: 'Failed to connect app. Please try again.',
    id: 'GPUfhq',
  },
});

export function useSendPublicTokenMutation() {
  const { formatMessage } = useIntl();
  const { showInfoToast, showErrorToast } = useToastStore();
  const { openModal } = useContext(GoogleLimitationModalContext);

  return useMutation({
    mutationFn: async (variables: { publicToken: string }) => {
      const { publicToken } = variables;

      const response = await assemblyAPI.post<Connection>(
        APIEndpoints.connectionPool,
        {
          code: publicToken,
          source: 'MERGE',
        }
      );

      return response.data;
    },
    onError: (err: unknown) => {
      const errorInfo = err instanceof Error ? err : undefined;

      if (isAxiosError(err) && err.response?.data.message === 'FORBIDDEN') {
        logger.warn('Error sending token to backend to connect app', {
          error: errorInfo,
        });
        showInfoToast(formatMessage(messages.alreadyConnected));
      } else {
        logger.error('Error sending token to backend to connect app', {
          error: errorInfo,
        });
        showErrorToast(formatMessage(messages.errorConnecting));
      }
      trackAppConnectionError('connectAppError');
    },
    onSuccess: (data) => {
      if (data.integrationName === 'Google Drive') {
        openModal();
      }
    },
  });
}
