import { config, MobileJWTTokenQueryParamKey } from '@assembly-web/services';
import { useAssemblyNavigate } from '@assembly-web/ui';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { resetNavStore } from '../../../discover/hooks/nav/useNavStore';
import { AccountCreationLoadingIndicator } from '../../components/AccountCreationLoadingIndicator';
import { useWorkspaceSetup } from '../../hooks/useAccountSetup';

export function WaitForAccountRoute() {
  const accountStatus = useWorkspaceSetup(true);
  const navigate = useAssemblyNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!accountStatus) {
      return;
    }

    resetNavStore();
    const isMobileApp = searchParams.get('isMobileApp');
    const jwt = searchParams.get(MobileJWTTokenQueryParamKey);

    if (isMobileApp && jwt) {
      window.location.href = `${config.domains.mobileApp}home?${searchParams}`;
    } else {
      navigate('/discover');
    }
  }, [accountStatus, navigate, searchParams]);

  return <AccountCreationLoadingIndicator />;
}
