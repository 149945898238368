import { defineMessages, useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { useIsBottomSheetInputActive } from '../../../../hooks/useIsBottomSheetInputActive';
import { AnswerMessage } from '../../../../shared/AnswerMessage';
import {
  BlocksGroup,
  type EditSettingsStoreType,
  type EventSourceStoreType,
  type FormSettingsStoreType,
  type MemberAvatarProps,
} from '../../../../types';

const messages = defineMessages({
  entireResponse: {
    defaultMessage: 'Summarize entire response',
    id: 'ccN3lR',
  },
  specificResponses: {
    defaultMessage: 'Choose specific answers',
    id: 'O8gtpE',
  },
});

export const BlocksGroupAnswer = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    eventSourceStore: EventSourceStoreType;
    formSettingsStore: FormSettingsStoreType;
  }
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { useEventSourceStore },
    formSettingsStore,
    ...messageProps
  } = props;

  const {
    hideActiveInput,
    useBlocksGroupSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const { formatMessage } = useIntl();

  const origBlocksGroupValue = useBlocksGroupSetting((store) => store.value);
  const isSeen = useBlocksGroupSetting.getState().isAnswerSeen;
  const isSet = useBlocksGroupSetting((store) => store.isSet);
  const markSeen = useBlocksGroupSetting((store) => store.markAnswerSeen);
  const markSet = useBlocksGroupSetting((store) => store.markSet);

  const setShouldRequestInput = useBlocksGroupSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useBlocksGroupSetting(
    (store) => store.shouldRequestInput
  );

  const settingEditing = useEditSettingsStore((store) => store.settingEditing);

  const setShouldRequestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const editedBlocksGroupValue = useEditSettingsStore(
    (store) => store.blocksGroup.value
  );

  const value = editedBlocksGroupValue ?? origBlocksGroupValue;

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const isSettingsConfirmed = useSettingsConfirmationSetting((store) =>
    Boolean(store.value)
  );

  const resetEdited = useEditSettingsStore((store) => store.reset);

  const handleEdit = () => {
    trackDoraAction('summaryInputEdited', { doraSummaryInput: 'Blocks' });
    setSettingEditing('blocksGroup');
    hideActiveInput();
    resetEdited();

    setTimeout(() => {
      setShouldRequestEdit('blocksGroup', true);
    }, timeouts.showInput);
  };

  const isEditingOtherSetting = useEditSettingsStore((store) => {
    const isEditingSpecificBlocks =
      store.settingEditing === 'individualBlocks' && !store.blocksGroup.value;

    return (
      isEditingSpecificBlocks ||
      store.isEditingFocus() ||
      store.isEditingTimePeriod()
    );
  });

  const isEditingCurSetting = settingEditing === 'blocksGroup';

  const isEditingPrevSetting = useEditSettingsStore((store) =>
    store.isEditingRespondents()
  );

  const isInputActive = useIsBottomSheetInputActive(formSettingsStore);
  const isEditing = useEditSettingsStore((store) =>
    Boolean(store.settingEditing)
  );
  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    value &&
    !isEditingPrevSetting && (
      <AnswerMessage
        isEditDisabled={
          !isSummaryError &&
          (isEditingCurSetting ||
            isEditingOtherSetting ||
            isSettingsConfirmed ||
            (!isInputActive && !isEditing))
        }
        isEditing={isEditingCurSetting}
        isFaded={isEditingOtherSetting}
        isSeen={isSeen}
        isSet={isSet}
        markSeen={markSeen}
        markSet={markSet}
        onEdit={handleEdit}
        setShouldRequestInput={setShouldRequestInput}
        shouldRequestInput={shouldRequestInput}
        {...messageProps}
      >
        {formatMessage(
          value === BlocksGroup.Individual
            ? messages.specificResponses
            : messages.entireResponse
        )}
      </AnswerMessage>
    )
  );
};
