import {
  CriteriaRuleType,
  PermissionType,
  type ShareCriteria,
} from '@assembly-web/services';
import type { CriteriaItemProps } from '@assembly-web/ui';
import sortBy from 'lodash/sortBy';

import type { SaveShareCollectionCriteriaAPIRequest } from '../hooks/shareCollections/queries/useSaveShareCollectionCriteria';
import {
  CollaboratorPermission,
  ExcludedPermission,
  OwnerPermission,
  ViewerPermission,
} from './shareCollectionsData';
import {
  ApproverPermission,
  ParticipantApproverPermission,
  ParticipantPermission,
  PeopleSelectorPermission,
} from './shareSheetData';

export function getPermissions({
  perm,
  isOwner,
  isExcluded,
}: {
  perm?: string;
  isOwner: boolean;
  isExcluded: boolean;
}) {
  if (isOwner) {
    return OwnerPermission;
  }

  if (isExcluded) {
    return ExcludedPermission;
  }

  switch (perm) {
    case 'viewer':
      return ViewerPermission;

    case 'collaborator':
      return CollaboratorPermission;

    default:
      return undefined;
  }
}

export function getPermissionsForOwnerAndCollaborators({
  isOwner,
}: {
  isOwner: boolean;
}) {
  if (isOwner) {
    return OwnerPermission;
  }
  return CollaboratorPermission;
}

export function getPermissionsForPeopleSelector() {
  return PeopleSelectorPermission;
}

export function getPermissionsForChallenge({
  perm,
  isOwner,
  isExcluded,
  isDeactivatedOwner,
}: {
  perm?: string;
  isOwner: boolean;
  isExcluded: boolean;
  isDeactivatedOwner?: boolean;
}) {
  let permission;
  if (isExcluded) {
    permission = { ...ExcludedPermission };
  }

  switch (perm) {
    case 'approver':
      permission = { ...ApproverPermission };
      break;
    case 'participant':
      permission = { ...ParticipantPermission };
      break;
    case 'participant-approver':
      permission = { ...ParticipantApproverPermission };
      break;
  }
  if (isOwner && isDeactivatedOwner && permission) {
    permission.label = OwnerPermission.label;
  }
  return permission;
}

export function generateSaveCriteriaPayloadFromRules(
  rules: CriteriaItemProps[],
  isPinned?: boolean
): SaveShareCollectionCriteriaAPIRequest {
  const includedRules = rules.filter(
    (rule) => rule.permission?.id !== PermissionType.Excluded
  );
  const excludedRules = rules.filter(
    (rule) => rule.permission?.id === PermissionType.Excluded
  );

  return {
    sharingRules: {
      include: Object.keys(groupDataByField(includedRules)).map((key) => {
        return key === 'everyone'
          ? {
              field: key as CriteriaRuleType,
              value: true,
              perm: groupDataByField(includedRules)[key]?.[0]?.perm,
              operator: groupDataByField(includedRules)[key]?.[0]?.operator,
            }
          : {
              field: key as CriteriaRuleType,
              values: groupDataByField(includedRules)[key],
            };
      }),
      exclude: Object.keys(groupDataByField(excludedRules)).map((key) => {
        return {
          field: key as CriteriaRuleType,
          values: groupDataByField(excludedRules)[key],
        };
      }),
    },
    shouldPin: isPinned,
  } as SaveShareCollectionCriteriaAPIRequest;
}

export function groupDataByField(data: CriteriaItemProps[]) {
  const groupedData: Partial<
    Record<
      string,
      {
        value: string | boolean;
        operator: string;
        perm?: string | undefined;
      }[]
    >
  > = {};

  data.forEach((item) => {
    const currentMetaData = item.metaData as {
      field: CriteriaRuleType;
      value: string | boolean;
      operator: string;
    };

    const currentRulePayload = {
      value: getCriteriaPayloadValue(
        currentMetaData.field as CriteriaRuleType,
        currentMetaData.value
      ),
      operator: currentMetaData.operator,
      ...(item.permission?.id !== PermissionType.Excluded && {
        perm:
          item.permission?.id === PermissionType.Owner
            ? PermissionType.Viewer
            : item.permission?.id,
      }),
    };

    if (groupedData[currentMetaData.field as CriteriaRuleType]) {
      groupedData[currentMetaData.field as CriteriaRuleType]?.push(
        currentRulePayload
      );
    } else {
      groupedData[currentMetaData.field as CriteriaRuleType] = [
        currentRulePayload,
      ];
    }
  });
  return groupedData;
}

export const sortInitialCriteria = (initialData: ShareCriteria) => {
  const { include, exclude } = initialData;

  const order = [
    CriteriaRuleType.Everyone,
    CriteriaRuleType.Department,
    CriteriaRuleType.JobTitle,
    CriteriaRuleType.WorkLocation,
    CriteriaRuleType.HomeLocation,
    CriteriaRuleType.ManagerStatus,
    CriteriaRuleType.Member,
    CriteriaRuleType.Email,
  ];
  return {
    include: sortBy(include, (item) => order.indexOf(item.field)),
    exclude: sortBy(exclude, (item) => order.indexOf(item.field)),
  };
};

export const getCriteriaPayloadValue = (
  type: CriteriaRuleType,
  value: string | boolean
) => {
  switch (type) {
    case CriteriaRuleType.Everyone:
      return true;
    case CriteriaRuleType.ManagerStatus:
      return value === 'true' ? true : false;

    default:
      return value;
  }
};
