import { zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import type { createEventSourceStore } from './eventSourceStore';
import type { createFormSettingsStore } from './formSettingsStore';

type DrawerStore = {
  hasCollapsedOnce: boolean;
  shouldShow: boolean;
};

export const createDrawerStore = (
  persistKey: string,
  {
    eventSourceStore,
    formSettingsStore,
  }: {
    eventSourceStore: ReturnType<typeof createEventSourceStore>;
    formSettingsStore: ReturnType<typeof createFormSettingsStore>;
  }
) => {
  const useDrawerStore = create<DrawerStore>()(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set) => ({
        hasCollapsedOnce: false,
        shouldShow: false,
      }),
      {
        name: persistKey,
        storage: createJSONStorage(() => zustandLocalStorage),
      }
    )
  );

  const resetAll = () => {
    useDrawerStore.setState({ hasCollapsedOnce: false });
    formSettingsStore.resetSettings();
    eventSourceStore.reset();
  };

  const clear = () => {
    useDrawerStore.setState({ shouldShow: false });
    resetAll();
  };

  const onOpenChange = (newIsOpen: boolean) => {
    if (!newIsOpen) {
      useDrawerStore.setState({ hasCollapsedOnce: true });
    }
  };

  return {
    clear,
    onOpenChange,
    useDrawerStore,
  };
};
