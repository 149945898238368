import { doraAPI, DoraAPIEndpoints } from '@assembly-web/services';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import type { Except } from 'type-fest';

type ChatReportDataHealthResponse = {
  userCount: number;
  missingLocation: number;
  missingDepartment: number;
  missingHireDate: number;
  missingBirthday: number;
  missingJobTitle: number;
  managersWithNoReports: number;
  hasMissingData: boolean;
};

export function useChatReportHealth({
  enabled = true,
}: {
  enabled: boolean;
}): UseQueryResult<ChatReportDataHealthResponse> {
  return useQuery({
    enabled,
    queryKey: ['doraChatReportHealth'],
    queryFn: async () => {
      const { data } = await doraAPI.get<
        Except<ChatReportDataHealthResponse, 'hasMissingData'>
      >(DoraAPIEndpoints.chatReportHealth);

      const { userCount, ...otherAttributes } = data;

      return {
        userCount,
        ...otherAttributes,
        hasMissingData: Object.values(otherAttributes).some(Boolean),
      };
    },
  });
}
