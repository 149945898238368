import { Button, ConfirmationModal } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { createContext, useState } from 'react';
import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../stores/useMultiDrawerStore';
import { accessDrawer, type Drawer } from '../components/Drawers/types';
import { trackDoraAction } from '../services/analytics';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  closeMultipleDescription: {
    defaultMessage:
      "If you're closing any flow summaries, you'll have to start over to re-configure them. If you'd like to copy any of the content of the conversation, go back before closing the window.",
    id: 'c+pajJ',
  },
  closeMultipleLabel: {
    defaultMessage: 'Close conversations dialog',
    id: 'UnARqe',
  },
  closeMultipleTitle: {
    defaultMessage: 'Are you sure you want to close these conversations?',
    id: '5A5P0W',
  },
  closeMultipleButton: {
    defaultMessage: 'Close conversations',
    id: '/NixGf',
  },
  closeSingleButton: {
    defaultMessage: 'Close conversation',
    id: 'Lqcd0E',
  },
  closeSingleDescription: {
    defaultMessage:
      "Once you close this, this conversation will be lost. If you'd like to copy any of the content of the conversation, go back before closing the window.",
    id: '/hDMNz',
  },
  closeSingleLabel: {
    defaultMessage: 'Close conversation dialog',
    id: 'l86+tt',
  },
  closeSingleTitle: {
    defaultMessage: 'Are you sure you want to close this conversation?',
    id: '32wsIF',
  },
});

export const CloseDrawerContext = createContext<{
  openModal: (drawer?: Drawer) => void;
}>({
  openModal: () => {},
});

export const CloseDrawerProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const { formatMessage } = useIntl();
  const deleteDrawer = useMultiDrawerStore((store) => store.deleteDrawer);
  const deleteOverflowDrawer = useMultiDrawerStore(
    (store) => store.deleteOverflowDrawer
  );
  const removeOverflowDrawers = useMultiDrawerStore(
    (store) => store.removeOverflowDrawers
  );
  const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false);
  const [onCloseSummaryClick, setOnCloseSummaryClick] = useState<() => void>();

  const [description, setDescription] = useState<MessageDescriptor>(
    messages.closeSingleDescription
  );

  const [label, setLabel] = useState<MessageDescriptor>(
    messages.closeSingleLabel
  );

  const [primaryCtaCopy, setPrimaryCtaCopy] = useState<MessageDescriptor>(
    messages.closeSingleButton
  );

  const [title, setTitle] = useState<MessageDescriptor>(
    messages.closeSingleTitle
  );

  const handleCloseConfirmationModal = () => {
    setIsConfirmCloseModalOpen(false);
    resetConfirmationModal();
  };

  // if a drawer isn't passed, assume it's closing the overflow menu
  const handleOpenConfirmationModal = (drawer?: Drawer) => {
    setIsConfirmCloseModalOpen(true);

    setOnCloseSummaryClick(() => () => {
      if (drawer) {
        accessDrawer(drawer, drawer.type, (config, args) => {
          config.onClose?.(args);
          deleteDrawer(args.id);
          deleteOverflowDrawer(args.id);
        });
      } else {
        const drawersRemoved = removeOverflowDrawers();

        drawersRemoved.forEach((drawer) => {
          accessDrawer(drawer, drawer.type, (config, args) => {
            config.onClose?.(args);
          });
        });
      }
      handleCloseConfirmationModal();

      trackDoraAction('exitConfirmationClicked');
    });

    if (!drawer) {
      setDescription(messages.closeMultipleDescription);
      setLabel(messages.closeMultipleLabel);
      setPrimaryCtaCopy(messages.closeMultipleButton);
      setTitle(messages.closeMultipleTitle);
    } else {
      accessDrawer(drawer, drawer.type, (config) => {
        const confirmOnCloseCopy = config.confirmOnCloseCopy;
        if (confirmOnCloseCopy) {
          if (confirmOnCloseCopy.title) {
            setTitle(confirmOnCloseCopy.title);
          }
          if (confirmOnCloseCopy.description) {
            setDescription(confirmOnCloseCopy.description);
          }
          if (confirmOnCloseCopy.label) {
            setLabel(confirmOnCloseCopy.label);
          }
          if (confirmOnCloseCopy.cta) {
            setPrimaryCtaCopy(confirmOnCloseCopy.cta);
          }
        }
      });
    }
  };

  const resetConfirmationModal = () => {
    setDescription(messages.closeSingleDescription);
    setLabel(messages.closeSingleLabel);
    setPrimaryCtaCopy(messages.closeSingleButton);
    setTitle(messages.closeSingleTitle);
  };

  return (
    <CloseDrawerContext.Provider
      value={{ openModal: handleOpenConfirmationModal }}
    >
      <ConfirmationModal
        description={formatMessage(description)}
        label={formatMessage(label)}
        leftButton={
          <Button
            isFullWidth
            onClick={handleCloseConfirmationModal}
            variation="secondaryEmphasized"
          >
            {formatMessage(messages.cancel)}
          </Button>
        }
        onClose={handleCloseConfirmationModal}
        open={isConfirmCloseModalOpen}
        rightButton={
          <Button isFullWidth onClick={onCloseSummaryClick} variation="danger">
            {formatMessage(primaryCtaCopy)}
          </Button>
        }
        title={formatMessage(title)}
      />
      {children}
    </CloseDrawerContext.Provider>
  );
};
