import type { TimePeriodOptionsQuestionProps } from '../../../../types';
import { Message } from './Message';

export const TimePeriodOptionsQuestion = (
  props: TimePeriodOptionsQuestionProps
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useCustomFocusSetting },
  } = props;

  const isFocusSet = useCustomFocusSetting((store) => store.isSet);

  const isEditingPrevSetting = useEditSettingsStore(
    (store) =>
      store.isEditingRespondents() ||
      store.isEditingBlocks() ||
      store.isEditingFocus()
  );

  return isFocusSet && !isEditingPrevSetting && <Message {...props} />;
};
