import type { TextVariant } from '@assembly-web/ui';
import { TextStyle } from '@assembly-web/ui';

import { useLoadingDotsAnimation } from '../../../../hooks/useLoadingDotsAnimation';

type DoraLoadingLabelProps = {
  label: string;
  subdued?: boolean;
  variant?: TextVariant;
};

export function DoraLoadingLabel({
  label,
  subdued = false,
  variant = 'base-regular',
}: DoraLoadingLabelProps) {
  const dots = useLoadingDotsAnimation();

  return (
    <div className="relative">
      <TextStyle as="span" variant={variant} subdued={subdued} html={label} />
      <TextStyle
        as="span"
        variant={variant}
        subdued={subdued}
        html={'.'.repeat(dots)}
        className="inline-block w-3"
      />
    </div>
  );
}
