import {
  APIEndpoints,
  type FlowFeedParams,
  type FlowPostResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import { useQuery } from '@tanstack/react-query';

export const useGetFlowFeed = (
  flowId: string,
  params: FlowFeedParams,
  enabled?: boolean
) => {
  return useQuery({
    enabled,
    queryKey: ['flowFeed', params],
    queryFn: async () => {
      const response = await assemblyAPI.post<{ data: FlowPostResponse[] }>(
        APIEndpoints.flowFeed(flowId),
        params
      );

      return response.data;
    },
    retry: false,
  });
};
