import { create } from 'zustand';

type LegacyPathStore = {
  iFrameSrc: string;
  previousUrls: string[];
  setIFrameSrc: (src: string) => void;
  setPreviousUrls: (urls: string[]) => void;
};

export const useLegacyPathStore = create<LegacyPathStore>((set) => ({
  iFrameSrc: '',
  previousUrls: [],
  setIFrameSrc: (src) => set(() => ({ iFrameSrc: src })),
  setPreviousUrls(urls) {
    set(() => ({ previousUrls: urls }));
  },
}));
