import type {
  DoraChatHistoryAPIResponse,
  DoraReportingCategory,
} from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { defineMessage, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { trackDoraAction } from '../../../services/analytics';
import { ReportingInsightsChatSwitcher } from './ReportingInsightsChatSwitcher';

const reportingInsightsTabLabel = defineMessage({
  defaultMessage: 'Reporting & Insights AI',
  id: 'W/zPla',
});

type ReportingInsightsChatHeaderProps = {
  id: string;
  isLoading: boolean;
  isStreaming: boolean;
  chatHistoryData?: DoraChatHistoryAPIResponse;
  reportingCategory: DoraReportingCategory;
  changeCategory: (category: DoraReportingCategory) => void;
};

const categoryActions = {
  users: 'switchToMilestonesReportingCategory',
  recognition: 'switchToRecognitionReportingCategory',
  flows: 'switchToFlowEngagementReportingCategory',
  reward_redemption: 'switchToRewardRedemptionsReportingCategory',
} as Record<DoraReportingCategory, Parameters<typeof trackDoraAction>[0]>;

export function ReportingInsightsChatHeader({
  reportingCategory,
  changeCategory,
  isLoading,
  isStreaming,
  chatHistoryData,
  id,
}: ReportingInsightsChatHeaderProps) {
  const { formatMessage } = useIntl();
  const deleteDrawer = useMultiDrawerStore((store) => store.deleteDrawer);

  return (
    <div className="border-b border-b-gray-5 bg-gray-1 px-4 py-2">
      <div className="flex h-6 w-full flex-row items-center gap-1">
        <TextStyle variant="xs-regular" subdued className="mr-1">
          {formatMessage(reportingInsightsTabLabel)}
        </TextStyle>

        <ReportingInsightsChatSwitcher
          currentCategory={reportingCategory}
          onCategoryChange={(category: DoraReportingCategory) => {
            if (category === reportingCategory) {
              return;
            }

            trackDoraAction(categoryActions[category]);

            changeCategory(category);

            const isEmptyConversation =
              !(isLoading || isStreaming) && !chatHistoryData;

            if (isEmptyConversation) {
              deleteDrawer(id);
            }
          }}
        />
      </div>
    </div>
  );
}
