import { Avatar, AvatarSize, TextStyle } from '@assembly-web/ui';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextButton } from '../../../../../../../../../components/TextButton';
import { trackDoraAction } from '../../../../../../../services/analytics';
import {
  ErrorBottomActionSheet,
  LoadingBottomActionSheet,
} from '../../../../../shared/dora/BottomActionSheet';
import {
  anonymousMemberId,
  timeouts,
} from '../../../../../shared/dora/constants';
import { SelectableListInput } from '../../../../shared/SelectableListInput';
import {
  type IndividualFlowRespondent,
  type IndividualRespondentsInputProps,
  RespondentsGroup,
} from '../../../../types';

type FlowRespondentOption = {
  id: string;
  imageUrl: string;
  memberId: string;
  value: string;
};

type RespondentDetails = {
  imageUrl: string;
  fullName: string;
};

const mapSelectedFlowRespondent = (
  respondent: IndividualFlowRespondent,
  detailsMap: Map<string, RespondentDetails>
) => {
  const { fullName: value = respondent.fullName, imageUrl = '' } =
    detailsMap.get(respondent.memberId) ?? {};

  return {
    id: respondent.memberId,
    imageUrl,
    memberId: respondent.memberId,
    value,
  };
};

export const Selector = (props: IndividualRespondentsInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore,
    flowRespondents,
    formSettingsStore: {
      repromptTimePeriod,
      useIndividualRespondentsSetting,
      useRespondentsGroupSetting,
    },
    isError,
    isLoading,
    onTryAgain,
  } = props;

  const isNewInputSeen = useIndividualRespondentsSetting.getState().isInputSeen;

  const markNewInputSeen = useIndividualRespondentsSetting(
    (store) => store.markInputSeen
  );

  const setIndividualRespondents = useIndividualRespondentsSetting(
    (store) => store.setValue
  );

  const [searchValue, setSearchValue] = useState('');

  const origSelectedRespondents = useIndividualRespondentsSetting(
    (store) => store.value
  );

  const respondentsMap = useMemo(() => {
    const map = new Map<string, RespondentDetails>();

    if (flowRespondents) {
      flowRespondents.forEach((member) => {
        map.set(member.memberID, {
          fullName: member.name,
          imageUrl: member.image,
        });
      });
    }
    return map;
  }, [flowRespondents]);

  const [selectedMembers, setSelectedMembers] = useState<
    FlowRespondentOption[]
  >(
    (origSelectedRespondents ?? []).map((respondent) =>
      mapSelectedFlowRespondent(respondent, respondentsMap)
    )
  );

  useEffect(() => {
    setSelectedMembers(
      (origSelectedRespondents ?? []).map((respondent) =>
        mapSelectedFlowRespondent(respondent, respondentsMap)
      )
    );
  }, [origSelectedRespondents, respondentsMap]);

  const allOptions = useMemo(() => {
    if (flowRespondents) {
      return flowRespondents.reduce<FlowRespondentOption[]>((all, member) => {
        if (
          member.memberID !== anonymousMemberId &&
          member.name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        ) {
          all.push({
            id: member.memberID,
            imageUrl: member.image,
            memberId: member.memberID,
            value: member.name,
          });
        }
        return all;
      }, []);
    }
    return [];
  }, [flowRespondents, searchValue]);

  const isEditing = useEditSettingsStore(
    (store) => store.individualRespondents.shouldRequestInput
  );

  const exitEditMode = useEditSettingsStore((store) => store.exitEditMode);

  const respondentsGroupValue = useRespondentsGroupSetting(
    (store) => store.value
  );

  const setRespondentsGroup = useRespondentsGroupSetting(
    (store) => store.setValue
  );

  const handleDoneClick = (chosenMembers: FlowRespondentOption[]) => {
    if (chosenMembers.length === 0) {
      return;
    }

    setSelectedMembers(chosenMembers);

    setIndividualRespondents(
      chosenMembers.map((member) => ({
        fullName: member.value,
        memberId: member.memberId,
      }))
    );

    if (isEditing) {
      const origChosenRespondents = new Set(
        origSelectedRespondents?.map((respondent) => respondent.memberId)
      );

      const hasNewRespondentsChosen =
        chosenMembers.length !== origChosenRespondents.size ||
        chosenMembers.some((m) => !origChosenRespondents.has(m.memberId));

      if (hasNewRespondentsChosen) {
        eventSourceStore.reset();
        repromptTimePeriod();
      }

      if (respondentsGroupValue !== RespondentsGroup.Individual) {
        setRespondentsGroup(RespondentsGroup.Individual);
        setTimeout(exitEditMode, timeouts.hideInput);
      } else {
        exitEditMode();
      }
    }

    trackDoraAction(
      isEditing ? 'summaryInputEditConfirmed' : 'peopleSelectedConfirmed',
      {
        doraSummaryInput: chosenMembers.map((member) => member.value),
      }
    );
  };

  const isEditInputSeen =
    useEditSettingsStore.getState().individualRespondents.isInputSeen;

  const markEditInputSeen = useEditSettingsStore(
    (store) => store.markInputSeen
  );

  useEffect(() => {
    if (!isNewInputSeen && !isEditing) {
      markNewInputSeen();
    } else if (!isEditInputSeen && isEditing) {
      markEditInputSeen('individualRespondents');
    }
  }, [
    isNewInputSeen,
    isEditing,
    isEditInputSeen,
    markEditInputSeen,
    markNewInputSeen,
  ]);

  const shouldAnimateOnMount =
    (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen);

  const handleCancel = () => {
    trackDoraAction('summaryInputEditCanceled');
    exitEditMode();
  };

  const intermediaryProps = {
    className: 'h-[328px]',
    isEditing,
    onCancel: handleCancel,
    shouldAnimateOnMount,
  };

  if (isLoading) {
    return <LoadingBottomActionSheet {...intermediaryProps} />;
  }

  if (isError) {
    return (
      <ErrorBottomActionSheet {...intermediaryProps}>
        <FormattedMessage
          defaultMessage="Sorry, I had an issue fetching the list of respondents to this flow. Please <button>try again</button>."
          id="CtnZYl"
          values={{
            button: (text) => (
              <TextButton
                className="!text-gray-8"
                onClick={onTryAgain}
                underline
              >
                {text}
              </TextButton>
            ),
          }}
        />
      </ErrorBottomActionSheet>
    );
  }

  return (
    <SelectableListInput<FlowRespondentOption>
      isEditing={isEditing}
      onCancel={handleCancel}
      onClearAllClick={() => setSelectedMembers([])}
      onDoneClick={handleDoneClick}
      onSearchChange={setSearchValue}
      options={allOptions}
      renderOption={(option) => (
        <div className="flex gap-2">
          <Avatar
            name={option.value}
            size={AvatarSize.Small}
            image={option.imageUrl}
            memberID={option.memberId}
          />
          <TextStyle>{option.value}</TextStyle>
        </div>
      )}
      selectedOptions={selectedMembers}
      shouldAnimateOnMount={shouldAnimateOnMount}
    />
  );
};
