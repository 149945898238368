import { useAskDoraStore } from '../../../stores/useAskDoraStore';
import type { DrawerConfig } from '../configuration';
import type {
  AskDoraData,
  AskDoraDrawer as AskDoraDrawerProps,
} from '../types';
import { AskDoraDrawer } from '.';
import { AskDoraDrawerBody } from './AskDoraDrawerBody';
import { AskDoraDrawerHeader } from './AskDoraDrawerHeader';
import { AskDoraDrawerOverflowCard } from './AskDoraDrawerOverflowCard';

export const askDoraConfig = {
  drawer: AskDoraDrawer,
  body: AskDoraDrawerBody,
  header: AskDoraDrawerHeader,
  overflowCard: AskDoraDrawerOverflowCard,
  allowMultipleInstance: false,
  resetOnCreate: true,
  getId: () => 'doraQ',
  shouldConfirmOnClose: () => false,
  onClose: () => useAskDoraStore.getState().resetStore(),
} satisfies DrawerConfig<AskDoraDrawerProps, AskDoraData>;
