import { PopoutDrawerTemplate } from '../PopoutDrawerTemplate';
import type { AskDoraDrawer as AskDoraDrawerProps } from '../types';
import { AskDoraDrawerBody } from './AskDoraDrawerBody';

export const AskDoraDrawer = (props: AskDoraDrawerProps) => {
  return (
    <PopoutDrawerTemplate {...props}>
      <AskDoraDrawerBody {...props} />
    </PopoutDrawerTemplate>
  );
};
