import { type ReactElement, useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { TextButton } from '../../../../../../../../../components/TextButton';
import { trackDoraAction } from '../../../../../../../services/analytics';
import {
  ErrorBottomActionSheet,
  LoadingBottomActionSheet,
} from '../../../../../shared/dora/BottomActionSheet';
import { timeouts } from '../../../../../shared/dora/constants';
import { Option } from '../../../../shared/Option';
import { OptionsSelectorInput } from '../../../../shared/OptionsSelectorInput';
import type { TimePeriodOptionsInputProps } from '../../../../types';

const messages = defineMessages({
  custom: {
    defaultMessage: 'I want to set a custom time period',
    id: 'MOIAj3',
  },
});

export const Selector = (props: TimePeriodOptionsInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore,
    formSettingsStore: {
      useCustomTimePeriodSetting,
      usePredefinedTimePeriodSetting,
    },
    isError,
    isLoading,
    onTryAgain,
    predefinedTimePeriods,
  } = props;

  const { formatMessage } = useIntl();

  const isCustomTimePeriodSet = useCustomTimePeriodSetting(
    (store) => store.isSet
  );

  const markSet = useCustomTimePeriodSetting((store) => store.markSet);
  const isNewInputSeen = usePredefinedTimePeriodSetting.getState().isInputSeen;

  const isPreDefinedTimePeriodSet = usePredefinedTimePeriodSetting(
    (store) => store.isSet
  );

  const markNewInputSeen = usePredefinedTimePeriodSetting(
    (store) => store.markInputSeen
  );

  const setTimePeriod = usePredefinedTimePeriodSetting(
    (store) => store.setValue
  );

  const markEditedSet = useEditSettingsStore((store) => store.markSet);
  const editValue = useEditSettingsStore((store) => store.setValue);

  const requestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const value = usePredefinedTimePeriodSetting((store) => store.value);

  const isEditing = useEditSettingsStore(
    (store) => store.predefinedTimePeriod.shouldRequestInput
  );

  const isEditInputSeen =
    useEditSettingsStore.getState().predefinedTimePeriod.isInputSeen;

  const markEditInputSeen = useEditSettingsStore(
    (store) => store.markInputSeen
  );

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const exitEditMode = useEditSettingsStore((store) => store.exitEditMode);

  useEffect(() => {
    if (!isNewInputSeen && !isEditing) {
      markNewInputSeen();
    } else if (!isEditInputSeen && isEditing) {
      markEditInputSeen('predefinedTimePeriod');
    }
  }, [
    isEditInputSeen,
    isEditing,
    isNewInputSeen,
    markEditInputSeen,
    markNewInputSeen,
  ]);

  useEffect(() => {
    return () => {
      if (!isCustomTimePeriodSet && value?.endDate && value.startDate) {
        // Skip the prompting of the custom time period if one of the predefined options was selected.
        setTimeout(() => {
          markSet();
        }, timeouts.showNextMessage);
      }
    };
  }, [isCustomTimePeriodSet, isPreDefinedTimePeriodSet, markSet, value]);

  const handleCancel = () => {
    trackDoraAction('summaryInputEditCanceled');
    exitEditMode();
  };

  const allOptions = useMemo(() => {
    const options: ReactElement<typeof Option>[] = [
      ...predefinedTimePeriods.map((predefinedTimePeriod) => {
        const { hasPosts, formattedLabel, range, rawLabel } =
          predefinedTimePeriod;

        return (
          <Option
            key={`${range.startDate}-${range.endDate}-${formattedLabel}`}
            isDisabled={!hasPosts}
            onClick={() => {
              if (isEditing) {
                useCustomTimePeriodSetting.setState({ value: undefined });
                exitEditMode();

                if (
                  value?.endDate !== range.endDate ||
                  value.startDate !== range.startDate
                ) {
                  eventSourceStore.reset();
                }
              }

              setTimePeriod({ ...range, duration: formattedLabel });

              trackDoraAction(
                isEditing ? 'summaryInputEditConfirmed' : 'timePeriodSelected',
                {
                  doraSummaryInput: rawLabel,
                }
              );
            }}
          >
            {formattedLabel}
          </Option>
        );
      }),
      <Option
        key={formatMessage(messages.custom)}
        onClick={() => {
          const isSameAsOrigValue =
            isEditing && !value?.endDate && !value?.startDate;

          if (isSameAsOrigValue) {
            exitEditMode();
          } else if (isEditing) {
            editValue('predefinedTimePeriod', {
              duration: formatMessage(messages.custom),
            });

            requestEdit('predefinedTimePeriod', false);

            setTimeout(() => {
              setSettingEditing('customTimePeriod');
              markEditedSet('predefinedTimePeriod');
            }, timeouts.showNextMessage);
          } else {
            setTimePeriod({ duration: formatMessage(messages.custom) });
          }

          trackDoraAction(
            isEditing ? 'summaryInputEditConfirmed' : 'timePeriodSelected',
            {
              doraSummaryInput: 'I want to set a custom time period',
            }
          );
        }}
      >
        {formatMessage(messages.custom)}
      </Option>,
    ];

    return options;
  }, [
    editValue,
    exitEditMode,
    formatMessage,
    isEditing,
    markEditedSet,
    predefinedTimePeriods,
    requestEdit,
    setSettingEditing,
    setTimePeriod,
    useCustomTimePeriodSetting,
    value,
  ]);

  const shouldAnimateOnMount =
    (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen);

  const intermediaryProps = {
    className: isEditing ? 'h-[254px]' : 'h-[216px]',
    isEditing,
    onCancel: handleCancel,
    shouldAnimateOnMount,
  };

  if (isLoading) {
    return <LoadingBottomActionSheet {...intermediaryProps} />;
  }

  if (isError) {
    return (
      <ErrorBottomActionSheet {...intermediaryProps}>
        <FormattedMessage
          defaultMessage="Sorry, I had an issue fetching the time periods to summarize. Please <button>try again</button>."
          id="K3pDlB"
          values={{
            button: (text) => (
              <TextButton
                className="!text-gray-8"
                onClick={onTryAgain}
                underline
              >
                {text}
              </TextButton>
            ),
          }}
        />
      </ErrorBottomActionSheet>
    );
  }

  return (
    <OptionsSelectorInput
      isEditing={isEditing}
      onCancel={handleCancel}
      shouldAnimateOnMount={shouldAnimateOnMount}
    >
      {allOptions}
    </OptionsSelectorInput>
  );
};
