import { logger } from '@assembly-web/services';

type HTMLElement = {
  requestFullscreen?: () => Promise<void>;
  webkitRequestFullscreen?: () => void;
};

type Document = {
  exitFullscreen?: () => Promise<void>;
  webkitExitFullscreen?: () => void;
  fullscreenElement?: Element;
  webkitFullscreenElement?: Element;
};

export const enterFullScreen = () => {
  const docEle = document.documentElement as HTMLElement;
  if (!document.fullscreenElement) {
    if (docEle.requestFullscreen) {
      docEle.requestFullscreen().catch((error) => {
        logger.error('Unable to request fullscreen mode', {}, error as Error);
      });
    } else if (docEle.webkitRequestFullscreen) {
      docEle.webkitRequestFullscreen();
    }
  }
};

export const exitFullScreen = () => {
  const doc = document as Document;
  if (doc.fullscreenElement && doc.exitFullscreen) {
    doc.exitFullscreen().catch((error) => {
      logger.error('Unable to exit fullscreen mode', {}, error as Error);
    });
  } else if (doc.webkitFullscreenElement && doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  }
};
