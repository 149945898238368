import { memo } from 'react';

import { DefaultBlockRow } from '../DefaultBlockRow';
import { Description } from '../Description';

export const GIF = memo(function GIF() {
  return (
    <DefaultBlockRow>
      <Description />
    </DefaultBlockRow>
  );
});
