import { type MutableRefObject, useCallback, useEffect, useState } from 'react';

export function useShowShadowForEditor({
  drawerRef,
  containerRef,
}: {
  drawerRef: MutableRefObject<HTMLElement | null>;
  containerRef: MutableRefObject<HTMLElement | null>;
}) {
  const [showShadowForEditor, setShowShadowForEditor] = useState(false);

  const handleShadowForRepliesEditor = useCallback((container: HTMLElement) => {
    const { scrollTop, scrollHeight, clientHeight } = container;
    const isScrollAtEnd =
      Math.abs(scrollTop + clientHeight - scrollHeight) <= 10;
    setShowShadowForEditor(!isScrollAtEnd);
  }, []);

  const handleOnDrawerBodyScroll = useCallback(() => {
    const { current: container } = containerRef;

    if (container) {
      handleShadowForRepliesEditor(container);
    }
  }, [containerRef, handleShadowForRepliesEditor]);

  useEffect(() => {
    const postDrawer = drawerRef.current;
    const container = containerRef.current;

    if (!container || !postDrawer) {
      return;
    }

    const mutationObserver = new MutationObserver(() => {
      handleShadowForRepliesEditor(container);
    });

    mutationObserver.observe(postDrawer, {
      subtree: true,
      childList: true,
      attributes: true,
    });

    return () => {
      mutationObserver.disconnect();
    };
  }, [containerRef, drawerRef, handleShadowForRepliesEditor]);

  return {
    showShadowForEditor,
    handleOnDrawerBodyScroll,
  };
}
