export type QueryStringTypes = {
  actions?: string;
  jobTypes?: string;
  useCases?: string;
  categories?: string;
  isEmbedded?: string;
  selectedFilters?: string;
  isShopForTemplate?: string;
  isEmbeddedPublicly?: string;
};

export const createQueryParam = (
  receivedQueryString: QueryStringTypes,
  initialQueryString: string
) => {
  let params = new URLSearchParams(initialQueryString);
  const {
    actions,
    useCases,
    jobTypes,
    isEmbedded,
    categories,
    selectedFilters,
    isShopForTemplate,
    isEmbeddedPublicly,
  } = receivedQueryString;
  if (isEmbedded) {
    params.set('isEmbedded', 'true');
  }
  if (isEmbeddedPublicly) {
    params.set('publicView', 'true');
  }
  if (isShopForTemplate) {
    params.set('isShopForTemplate', 'true');
  }
  if (categories) {
    params.set('categories', categories);
  }
  if (jobTypes) {
    params.set('jobTypes', jobTypes);
  }
  if (useCases) {
    params.set('useCases', useCases);
  }
  if (actions) {
    params.set('actions', actions);
  }
  if (selectedFilters) {
    params.set('selectedFilters', selectedFilters);
  }
  let decoded = decodeURIComponent(`/${params.toString()}`);
  return decoded;
};
