import {
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import { useEffect } from 'react';

import { useMultiDrawerStore } from '../../../../../../stores/useMultiDrawerStore';
import { isAdminMember } from '../../../../services/member';

export function useDrawerCleanUpByFF() {
  const deleteDrawerByType = useMultiDrawerStore(
    (store) => store.deleteDrawerByType
  );
  const { isTreatmentActive: isFlowSummariesEnabled } = useFeatureSplit(
    SplitNames.FlowSummaries
  );
  const { isTreatmentActive: isAskDoraEnabled } = useFeatureSplit(
    SplitNames.AskDoraPopout
  );
  const { isTreatmentActive: isDoraReportingInsightsV0Enabled } =
    useFeatureSplit(SplitNames.DoraReportingInsightsV0);
  const { isTreatmentActive: isDoraReportingInsightsManagersEnabled } =
    useFeatureSplit(SplitNames.DoraReportingInsightsManagers);

  const { data: userDetails } = useUserDetails();
  const isManager = userDetails?.member.profile.isManager;
  const isAdmin = userDetails ? isAdminMember(userDetails.member) : false;

  const { isTreatmentActive: isFlowCreationDrawerEnabled } = useFeatureSplit(
    SplitNames.FlowCreation
  );

  const { isTreatmentActive: isFlowResponseEnabled } = useFeatureSplit(
    SplitNames.FlowResponse
  );

  // clean up any existing drawers if the feature flag is disabled
  useEffect(() => {
    if (!isFlowSummariesEnabled) {
      deleteDrawerByType('flowSummary');
    }
    if (!isFlowResponseEnabled) {
      deleteDrawerByType('flow_responses');
    }
    if (!isAskDoraEnabled) {
      deleteDrawerByType('doraQ');
    }
    if (!isDoraReportingInsightsV0Enabled) {
      deleteDrawerByType('doraChat');
    }
    if (!isDoraReportingInsightsManagersEnabled && !isAdmin && isManager) {
      deleteDrawerByType('doraChat');
    }
    if (!isFlowCreationDrawerEnabled) {
      deleteDrawerByType('flowCreation');
    }
  }, [
    deleteDrawerByType,
    isAdmin,
    isAskDoraEnabled,
    isDoraReportingInsightsManagersEnabled,
    isDoraReportingInsightsV0Enabled,
    isFlowCreationDrawerEnabled,
    isFlowResponseEnabled,
    isFlowSummariesEnabled,
    isManager,
  ]);
}
