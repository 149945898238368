import type { OptionsSelectObject } from '@assembly-web/services';
import {
  BlockLabel,
  BlockToggleSwitch,
  NumberInput,
  RadioGroup,
  TextStyle,
} from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useBlockType,
  useGetBlockOptions,
  useGetBlockParticipants,
  useGetBlockType,
  useGetCustomPersonSelectorCount,
  useGetSelectableOptionDetails,
  useGetSelectableOptionType,
  useSetSelectableOption,
  useSetSelectableOptionDetails,
} from '../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useBlockIdContext } from '../context/BlockIdContext';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';
import { useFormattedBlockName } from '../hooks/useFormattedBlockName';

const messages = defineMessages({
  label: { defaultMessage: 'More settings', id: '8ZKBkx' },
  switchLabel: {
    defaultMessage: 'Allow people to select multiple people',
    id: 'LQfy02',
  },
  exactNumber: {
    defaultMessage: 'An exact number',
    id: 'IKI5V6',
  },
  range: {
    defaultMessage: 'A range',
    id: 'wtkDBu',
  },
  unlimited: {
    defaultMessage: 'Unlimited options',
    id: 'vegfZe',
  },
  options: {
    defaultMessage: 'options',
    id: 'Q0+IaG',
  },
  to: {
    defaultMessage: 'to',
    id: 'NLeFGn',
  },
  minimum: {
    defaultMessage: 'Minimum in the range',
    id: 'PUqkab',
  },
  maximum: {
    defaultMessage: 'Maximum in the range',
    id: 'ASmSyO',
  },
  peopleSelectionBlockSwitchLabel: {
    defaultMessage: 'Allow people to select multiple people',
    id: 'LQfy02',
  },
  optionsBlockSwitchLabel: {
    defaultMessage: 'Allow people to select multiple options',
    id: 'M04FQk',
  },
});

function useGetMaximumCount() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const options = useGetBlockOptions(id, blockId);
  const participantsCount = useGetCustomPersonSelectorCount(id, blockId);
  const participants = useGetBlockParticipants(id, blockId);
  const blockType = useGetBlockType(id, blockId);

  return blockType === 'PERSON_SELECTOR'
    ? participants === 'EVERYONE'
      ? Infinity
      : participantsCount
    : options.length;
}

function Switch() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useBlockType(id, blockId);
  const setSelectableParticipation = useSetSelectableOption(id, blockId);
  const selectableOption = useGetSelectableOptionType(id, blockId);
  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <BlockToggleSwitch
      checked={selectableOption === 'MULTI'}
      disabled={isOccurrenceInProgress}
      label={
        blockType === 'PERSON_SELECTOR'
          ? formatMessage(messages.peopleSelectionBlockSwitchLabel)
          : formatMessage(messages.optionsBlockSwitchLabel)
      }
      onCheckedChange={(checked) => {
        checked
          ? setSelectableParticipation('MULTI')
          : setSelectableParticipation('SINGLE');
        trackFlowEditorAction('blockDetailClicked', {
          blockType: formattedBlockName,
          detailType: checked
            ? `${formattedBlockName}AllowSelectMultiple`
            : `${formattedBlockName}AllowSelectSingle`,
        });
      }}
    />
  );
}

function OptionsContainer({ children }: { children: ReactNode }) {
  const { formatMessage } = useIntl();

  return (
    <div className="flex items-center gap-2">
      {children}
      <TextStyle as="span" variant="sm-regular" subdued>
        {formatMessage(messages.options)}
      </TextStyle>
    </div>
  );
}

function ExactNumber() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const exactSelectable = useGetSelectableOptionDetails(
    id,
    blockId,
    (state) => {
      if (state.type === 'EXACT_NUMBER') {
        return state.exactOptions;
      }
      return 1;
    }
  );
  const set = useSetSelectableOptionDetails(id, blockId);

  const count = useGetMaximumCount();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <OptionsContainer>
      <NumberInput
        labelHidden
        minValue={1}
        maxValue={count}
        isDisabled={isOccurrenceInProgress}
        onChange={(value) =>
          set((state) => {
            return {
              ...state,
              exactOptions: value,
            };
          })
        }
        value={exactSelectable ?? 1}
        label={formatMessage(messages.exactNumber)}
      />
    </OptionsContainer>
  );
}

function RangeSelector() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const count = useGetMaximumCount();

  const minimumInRange = useGetSelectableOptionDetails(id, blockId, (state) => {
    if (state.type === 'RANGE') {
      return state.minOptions;
    }
    return 0;
  });
  const maximumInRange = useGetSelectableOptionDetails(id, blockId, (state) => {
    if (state.type === 'RANGE') {
      return state.maxOptions;
    }
    return 0;
  });
  const set = useSetSelectableOptionDetails(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <OptionsContainer>
      <NumberInput
        minValue={1}
        maxValue={maximumInRange ?? count}
        value={minimumInRange ?? 0}
        labelHidden
        label={formatMessage(messages.minimum)}
        isDisabled={isOccurrenceInProgress}
        onChange={(value) =>
          set((state) => {
            if (state.type !== 'RANGE') {
              return state;
            }
            return {
              ...state,
              minOptions: state.maxOptions <= value ? state.maxOptions : value,
            };
          })
        }
      />
      <TextStyle as="span" variant="sm-regular" subdued>
        {formatMessage(messages.to)}
      </TextStyle>
      <NumberInput
        minValue={1}
        maxValue={count}
        value={maximumInRange ?? 0}
        labelHidden
        label={formatMessage(messages.maximum)}
        isDisabled={isOccurrenceInProgress}
        onChange={(value) =>
          set((state) => {
            if (state.type !== 'RANGE') {
              return state;
            }
            return {
              ...state,
              maxOptions: state.minOptions >= value ? state.minOptions : value,
            };
          })
        }
      />
    </OptionsContainer>
  );
}

function ItemContainer({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-col gap-1 [&>div:nth-child(2)]:pl-6">
      {children}
    </div>
  );
}

function AdvancedOptions() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const selectedOption = useGetSelectableOptionDetails(
    id,
    blockId,
    (state) => state.type
  );
  const set = useSetSelectableOptionDetails(id, blockId);
  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <RadioGroup.Root
      value={selectedOption as string}
      disabled={isOccurrenceInProgress}
      onValueChange={(value: OptionsSelectObject['type']) => {
        trackFlowEditorAction('blockDetailClicked', {
          blockType: formattedBlockName,
          detailType: `${formattedBlockName}${((): string => {
            return (
              {
                EXACT_NUMBER: 'ExactNumber',
                RANGE: 'Range',
                UNLIMITED_OPTIONS: 'Unlimited',
              } satisfies Record<OptionsSelectObject['type'], string>
            )[value];
          })()}`,
        });
        set(() => {
          if (value === 'EXACT_NUMBER') {
            return {
              type: 'EXACT_NUMBER',
              exactOptions: 1,
            };
          }
          if (value === 'RANGE') {
            return {
              type: 'RANGE',
              maxOptions: 1,
              minOptions: 1,
            };
          }
          return {
            type: 'UNLIMITED_OPTIONS',
          };
        });
      }}
    >
      <ItemContainer>
        <RadioGroup.Item value="EXACT_NUMBER">
          {formatMessage(messages.exactNumber)}
        </RadioGroup.Item>
        {selectedOption === 'EXACT_NUMBER' && <ExactNumber />}
      </ItemContainer>
      <ItemContainer>
        <RadioGroup.Item value="RANGE">
          {formatMessage(messages.range)}
        </RadioGroup.Item>
        {selectedOption === 'RANGE' && <RangeSelector />}
      </ItemContainer>
      <ItemContainer>
        <RadioGroup.Item value="UNLIMITED_OPTIONS">
          {formatMessage(messages.unlimited)}
        </RadioGroup.Item>
      </ItemContainer>
    </RadioGroup.Root>
  );
}

function RevealContainer({ children }: { children: ReactNode }) {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const selectableParticipation = useGetSelectableOptionType(id, blockId);

  return selectableParticipation === 'MULTI' ? (
    <div className="flex flex-col gap-4">{children}</div>
  ) : null;
}

export function SelectionRange() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useBlockType(id, blockId);

  if (blockType === 'MULTI_CHOICE' || blockType === 'PERSON_SELECTOR') {
    return (
      <div className="flex flex-col gap-1">
        <BlockLabel>{formatMessage(messages.label)}</BlockLabel>
        <Switch />
        <RevealContainer>
          <AdvancedOptions />
        </RevealContainer>
      </div>
    );
  }
  return null;
}
