import { useUserDetails } from '@assembly-web/services';
import { type RefObject, useMemo } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { trackDoraAction } from '../../../services/analytics';
import { getPersistKeyForFlowSummaryDrawer } from '../../../stores/flowSummaryStore';
import { createEditSettingsStore } from '../../../stores/flowSummaryStore/formSettingsStore';
import { anonymousMemberId } from '../shared/dora/constants';
import { useDrawerStores } from '../shared/hooks/useDrawerStores';
import type { FlowSummaryDrawer as FlowSummaryDrawerProps } from '../types';
import { useGetFlowBlocksQuery } from './hooks/useGetFlowBlocksQuery';
import { useGetFlowPostInterval } from './hooks/useGetFlowPostInterval';
import { useGetFlowRespondentsQuery } from './hooks/useGetFlowRespondentsQuery';
import { useGetPredefinedTimePeriods } from './hooks/useGetPredefinedTimePeriods';
import { BlocksGroupAnswer } from './messages/blocks/group/answer/BlocksGroupAnswer';
import { BlocksGroupInput } from './messages/blocks/group/input/BlocksGroupInput';
import { BlocksGroupQuestion } from './messages/blocks/group/question/BlocksGroupQuestion';
import { IndividualBlocksAnswer } from './messages/blocks/individual/answer/IndividualBlocksAnswer';
import { IndividualBlocksInput } from './messages/blocks/individual/input/IndividualBlocksInput';
import { IndividualBlocksQuestion } from './messages/blocks/individual/question/IndividualBlocksQuestion';
import { ConfirmActions } from './messages/confirm/ConfirmActions';
import { ConfirmSettings } from './messages/confirm/ConfirmSettings';
import { RestartSummaryActions } from './messages/confirm/RestartSummaryActions';
import { CustomFocusAnswer } from './messages/focus/custom/answer/CustomFocusAnswer';
import { CustomFocusInput } from './messages/focus/custom/input/CustomFocusInput';
import { CustomFocusQuestion } from './messages/focus/custom/question/CustomFocusQuestion';
import { FocusOptionsAnswer } from './messages/focus/options/answer/FocusOptionsAnswer';
import { FocusOptionsInput } from './messages/focus/options/input/FocusOptionsInput';
import { FocusOptionsQuestion } from './messages/focus/options/question/FocusOptionsQuestion';
import { IntroMessage } from './messages/intro/IntroMessage';
import { RespondentsGroupAnswer } from './messages/respondents/group/answer/RespondentsGroupAnswer';
import { RespondentsGroupInput } from './messages/respondents/group/input/RespondentsGroupInput';
import { RespondentsGroupQuestion } from './messages/respondents/group/question/RespondentsGroupQuestion';
import { IndividualRespondentsAnswer } from './messages/respondents/individual/answer/IndividualRespondentsAnswer';
import { IndividualRespondentsInput } from './messages/respondents/individual/input/IndividualRespondentsInput';
import { IndividualRespondentsQuestion } from './messages/respondents/individual/question/IndividualRespondentsQuestion';
import { FeedbackMessage } from './messages/summary/FeedbackMessage';
import { SummaryResponse } from './messages/summary/SummaryResponse';
import { CustomTimePeriodAnswer } from './messages/timePeriod/custom/answer/CustomTimePeriodAnswer';
import { CustomTimePeriodInput } from './messages/timePeriod/custom/input/CustomTimePeriodInput';
import { CustomTimePeriodQuestion } from './messages/timePeriod/custom/question/CustomTimePeriodQuestion';
import { TimePeriodOptionsAnswer } from './messages/timePeriod/options/answer/TimePeriodOptionsAnswer';
import { TimePeriodOptionsInput } from './messages/timePeriod/options/input/TimePeriodOptionsInput';
import { TimePeriodOptionsQuestion } from './messages/timePeriod/options/question/TimePeriodOptionsQuestion';
import { BlocksGroup, type EditSettingsStoreType } from './types';

export const FlowSummaryBody = (
  props: FlowSummaryDrawerProps & {
    editSettingsStore?: EditSettingsStoreType;
    drawerRef?: RefObject<HTMLDivElement>;
  }
) => {
  const {
    data,
    drawerRef,
    editSettingsStore: origEditSettingsStore,
    id,
  } = props;

  const persistKey = getPersistKeyForFlowSummaryDrawer(props);

  const editSettingsStore = useMemo(
    // For the desktop view, the store is passed from the parent to maintain the edit state when switching to/from full-screen mode.
    // For the mobile view, the store can be created here since the drawer always covers the entire screen.
    () => origEditSettingsStore ?? createEditSettingsStore(),
    [origEditSettingsStore]
  );

  const useEditSettingsStore = editSettingsStore;

  const {
    drawerStore,
    eventSourceStore,
    formSettingsStore,
    removeFromStorage,
  } = useDrawerStores({ persistKey });

  const { flowId, flowTitle } = data;
  const { useDrawerStore } = drawerStore;
  const hasCollapsedOnce = useDrawerStore((state) => state.hasCollapsedOnce);

  const {
    useBlocksGroupSetting,
    useCustomTimePeriodSetting,
    useFocusTypeSetting,
    useIndividualBlocksSetting,
    useIndividualRespondentsSetting,
    usePredefinedTimePeriodSetting,
    useRespondentsGroupSetting,
  } = formSettingsStore;

  const settingEditing = useEditSettingsStore((store) => store.settingEditing);

  const isEditingRespondents = useEditSettingsStore((store) =>
    store.isEditingRespondents()
  );

  const isEditedRespondentsSet = useEditSettingsStore(
    (store) => store.respondentsGroup.isSet
  );

  const isOrigRespondentsSet = useIndividualRespondentsSetting(
    (store) => store.isSet
  );

  const isRespondentsSet = isEditingRespondents
    ? isEditedRespondentsSet
    : isOrigRespondentsSet;

  const {
    data: flowRespondents,
    isError: isFlowRespondentsError,
    isInitialLoading: isFlowRespondentsLoading,
    refetch: refetchFlowRespondents,
  } = useGetFlowRespondentsQuery(flowId, !isRespondentsSet);

  const hasOnlyAnonymousResponses =
    Array.isArray(flowRespondents) &&
    flowRespondents.length === 1 &&
    flowRespondents[0].memberID === anonymousMemberId;

  const origBlocksGroupValue = useBlocksGroupSetting((store) => store.value);

  const isOrigPredefinedTimePeriodSet = usePredefinedTimePeriodSetting(
    (store) => store.isSet
  );

  const isEditedPredefinedTimePeriodSet = useEditSettingsStore(
    (store) => store.predefinedTimePeriod.isSet
  );

  const isEditingPredefinedTimePeriod =
    settingEditing === 'predefinedTimePeriod';

  const isPredefinedTimePeriodSet = isEditingPredefinedTimePeriod
    ? isEditedPredefinedTimePeriodSet
    : isOrigPredefinedTimePeriodSet;

  const origPredefinedTimePeriodEndDate = usePredefinedTimePeriodSetting(
    (store) => store.value?.endDate
  );

  const origPredefinedTimePeriodStartDate = usePredefinedTimePeriodSetting(
    (store) => store.value?.startDate
  );

  const isEditingCustomTimePeriod = settingEditing === 'customTimePeriod';

  const editedPredefinedTimePeriodEndDate = useEditSettingsStore(
    (store) => store.predefinedTimePeriod.value?.endDate
  );

  const editedPredefinedTimePeriodStartDate = useEditSettingsStore(
    (store) => store.predefinedTimePeriod.value?.startDate
  );

  const predefinedTimePeriodEndDate = isEditingCustomTimePeriod
    ? editedPredefinedTimePeriodEndDate
    : origPredefinedTimePeriodEndDate;

  const predefinedTimePeriodStartDate = isEditingCustomTimePeriod
    ? editedPredefinedTimePeriodStartDate
    : origPredefinedTimePeriodStartDate;

  const isOrigCustomTimePeriodSet = useCustomTimePeriodSetting(
    (store) => store.isSet
  );

  const isEditedCustomTimePeriodSet = useEditSettingsStore(
    (store) => store.customTimePeriod.isSet
  );

  const isCustomTimePeriodSet = isEditingCustomTimePeriod
    ? isEditedCustomTimePeriodSet
    : isOrigCustomTimePeriodSet;

  const editedBlocksGroupValue = useEditSettingsStore(
    (store) => store.blocksGroup.value
  );

  const blocksGroupValue = editedBlocksGroupValue ?? origBlocksGroupValue;
  const isEditingIndividualBlocks = settingEditing === 'individualBlocks';
  const isOrigBlocksSet = useIndividualBlocksSetting((store) => store.isSet);

  const isEditedBlocksSet = useEditSettingsStore(
    (store) => store.individualBlocks.isSet
  );

  const isBlocksSet = isEditingIndividualBlocks
    ? isEditedBlocksSet
    : isOrigBlocksSet;

  const individualBlocksValue = useIndividualBlocksSetting(
    (store) => store.value
  );

  const blockIds = individualBlocksValue?.map((block) => block.blockId);

  const individualRespondentsValue = useIndividualRespondentsSetting(
    (store) => store.value
  );

  const respondentsGroupValue = useRespondentsGroupSetting(
    (store) => store.value
  );

  let respondedBy = individualRespondentsValue?.reduce<string[]>(
    (respondentIds, respondent) => {
      if (respondent.memberId !== anonymousMemberId) {
        respondentIds.push(respondent.memberId);
      }

      return respondentIds;
    },
    []
  );

  if (respondedBy && respondedBy.length === 0) {
    respondedBy = undefined; // API rejects the request for an empty array
  }

  const isAnonymous = individualRespondentsValue?.some(
    (respondent) => respondent.memberId === anonymousMemberId
  );

  const flowFilterParams = { blockIds, isAnonymous, respondedBy };

  const {
    data: flowBlocks,
    isError: isFlowBlocksError,
    isInitialLoading: isFlowBlocksLoading,
    refetch: refetchFlowBlocks,
  } = useGetFlowBlocksQuery(
    flowId,
    blocksGroupValue === BlocksGroup.Individual && !isBlocksSet
  );

  const isFocusSet = useFocusTypeSetting((store) => store.isSet);

  const {
    data: predefinedTimePeriods,
    isError: isPredefinedTimePeriodsError,
    isInitialLoading: isPredefinedTimePeriodsLoading,
    refetch: refetchPredefinedTimePeriods,
  } = useGetPredefinedTimePeriods(
    flowId,
    flowFilterParams,
    isFocusSet,
    isPredefinedTimePeriodSet
  );

  const {
    data: flowPostInterval,
    isError: isFlowPostIntervalError,
    isInitialLoading: isFlowPostIntervalLoading,
    refetch: refetchPostInterval,
  } = useGetFlowPostInterval(
    flowId,
    flowFilterParams,
    isPredefinedTimePeriodSet &&
      !predefinedTimePeriodEndDate &&
      !predefinedTimePeriodStartDate &&
      !isCustomTimePeriodSet
  );

  const shouldShowAnonymousOption =
    Array.isArray(flowRespondents) &&
    flowRespondents.length > 1 &&
    flowRespondents.some((member) => member.memberID === anonymousMemberId);

  const { data: userDetails } = useUserDetails();
  const slugCode = userDetails?.assembly.workspaceSlug.shortCode ?? '';
  const slugName = userDetails?.assembly.workspaceSlug.name ?? '';
  const slug = `${slugName}-${slugCode}`;

  const memberProps = {
    memberId: userDetails?.member.memberId ?? '',
    memberFullName: userDetails?.member.profile.fullName ?? '',
    memberImage: userDetails?.member.profile.image,
  };

  const flowLinkProps = {
    flowHref: `/${slug}/flows/${flowId}`,
    flowTitle,
  };

  const isRespondentsQuestionSkipped =
    (isOrigRespondentsSet &&
      !individualRespondentsValue &&
      !respondentsGroupValue) ||
    hasOnlyAnonymousResponses;

  const deleteDrawer = useMultiDrawerStore((store) => store.deleteDrawer);

  const deleteOverflowDrawer = useMultiDrawerStore(
    (store) => store.deleteOverflowDrawer
  );

  const handleCancelSummary = () => {
    deleteDrawer(id);
    deleteOverflowDrawer(id);
    removeFromStorage();

    trackDoraAction('flowSummaryCanceled');
  };

  return (
    <div className="flex h-full flex-col justify-end bg-gray-1">
      <ScrollToBottom
        className="overflow-auto"
        followButtonClassName="hidden"
        initialScrollBehavior={hasCollapsedOnce ? 'auto' : 'smooth'}
      >
        <div
          className="grid grid-cols-[max-content_minmax(0,1fr)] gap-x-2 gap-y-4 overflow-auto p-6"
          role="region"
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
        >
          <IntroMessage
            {...flowLinkProps}
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
          >
            <RespondentsGroupQuestion
              {...flowLinkProps}
              editSettingsStore={editSettingsStore}
              formSettingsStore={formSettingsStore}
              hasOnlyAnonymousResponses={hasOnlyAnonymousResponses}
              isQuestionSkipped={isRespondentsQuestionSkipped}
            />
          </IntroMessage>
          <RespondentsGroupAnswer
            {...memberProps}
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            formSettingsStore={formSettingsStore}
          />
          <IndividualRespondentsQuestion
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
          />
          <IndividualRespondentsAnswer
            {...memberProps}
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            formSettingsStore={formSettingsStore}
          />
          <BlocksGroupQuestion
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
            isPrevQuestionSkipped={isRespondentsQuestionSkipped}
          />
          <BlocksGroupAnswer
            {...memberProps}
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            formSettingsStore={formSettingsStore}
          />
          <IndividualBlocksQuestion
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
          />
          <IndividualBlocksAnswer
            {...memberProps}
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            formSettingsStore={formSettingsStore}
            isError={isFlowBlocksError}
          />
          <FocusOptionsQuestion
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
          />
          <FocusOptionsAnswer
            {...memberProps}
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            formSettingsStore={formSettingsStore}
          />
          <CustomFocusQuestion
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
          />
          <CustomFocusAnswer
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            formSettingsStore={formSettingsStore}
            {...memberProps}
          />
          <TimePeriodOptionsQuestion
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
          />
          <TimePeriodOptionsAnswer
            {...memberProps}
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            formSettingsStore={formSettingsStore}
          />
          <CustomTimePeriodQuestion
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
            isError={isFlowPostIntervalError}
            isLoading={isFlowPostIntervalLoading}
          />
          <CustomTimePeriodAnswer
            {...memberProps}
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            formSettingsStore={formSettingsStore}
          />
          <ConfirmSettings
            {...memberProps}
            editSettingsStore={editSettingsStore}
            formSettingsStore={formSettingsStore}
          />
          <SummaryResponse
            editSettingsStore={editSettingsStore}
            eventSourceStore={eventSourceStore}
            flowId={flowId}
            formSettingsStore={formSettingsStore}
          >
            <FeedbackMessage
              formSettingsStore={formSettingsStore}
              eventSourceStore={eventSourceStore}
            />
          </SummaryResponse>
        </div>
      </ScrollToBottom>
      <RespondentsGroupInput
        editSettingsStore={editSettingsStore}
        eventSourceStore={eventSourceStore}
        formSettingsStore={formSettingsStore}
        isError={isFlowRespondentsError}
        isLoading={isFlowRespondentsLoading}
        onTryAgain={refetchFlowRespondents}
        shouldShowAnonymousOption={shouldShowAnonymousOption}
      />
      <IndividualRespondentsInput
        editSettingsStore={editSettingsStore}
        eventSourceStore={eventSourceStore}
        flowRespondents={flowRespondents}
        formSettingsStore={formSettingsStore}
        isError={isFlowRespondentsError}
        isLoading={isFlowRespondentsLoading}
        onTryAgain={refetchFlowRespondents}
      />
      <BlocksGroupInput
        editSettingsStore={editSettingsStore}
        eventSourceStore={eventSourceStore}
        formSettingsStore={formSettingsStore}
      />
      <IndividualBlocksInput
        editSettingsStore={editSettingsStore}
        eventSourceStore={eventSourceStore}
        flowBlocks={flowBlocks}
        formSettingsStore={formSettingsStore}
        isError={isFlowBlocksError}
        isLoading={isFlowBlocksLoading}
        onTryAgain={refetchFlowBlocks}
      />
      <FocusOptionsInput
        editSettingsStore={editSettingsStore}
        eventSourceStore={eventSourceStore}
        formSettingsStore={formSettingsStore}
      />
      <CustomFocusInput
        editSettingsStore={editSettingsStore}
        eventSourceStore={eventSourceStore}
        formSettingsStore={formSettingsStore}
      />
      <TimePeriodOptionsInput
        editSettingsStore={editSettingsStore}
        eventSourceStore={eventSourceStore}
        formSettingsStore={formSettingsStore}
        isError={isPredefinedTimePeriodsError}
        isLoading={isPredefinedTimePeriodsLoading}
        onTryAgain={refetchPredefinedTimePeriods}
        predefinedTimePeriods={predefinedTimePeriods}
      />
      <CustomTimePeriodInput
        drawerRef={drawerRef}
        editSettingsStore={editSettingsStore}
        eventSourceStore={eventSourceStore}
        flowId={flowId}
        flowPostInterval={flowPostInterval}
        formSettingsStore={formSettingsStore}
        isAnonymous={isAnonymous}
        isError={isFlowPostIntervalError}
        isLoading={isFlowPostIntervalLoading}
        onTryAgain={refetchPostInterval}
        respondentIds={respondedBy}
      />
      <ConfirmActions
        formSettingsStore={formSettingsStore}
        onCancel={handleCancelSummary}
      />
      <RestartSummaryActions
        eventSourceStore={eventSourceStore}
        formSettingsStore={formSettingsStore}
        onStartFromScratchClick={removeFromStorage}
      />
    </div>
  );
};
