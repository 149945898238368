import { doraAPI, DoraAPIEndpoints } from '@assembly-web/services';
import { useMutation } from '@tanstack/react-query';

export function useGenerateSuggestedQuestionsMutation() {
  return useMutation({
    mutationFn: async (variables: {
      docId?: string;
      blacklistQuestions?: string[];
      threadId?: string;
    }) => {
      const { blacklistQuestions, docId, threadId } = variables;

      const params = threadId
        ? { thread_id: threadId }
        : {
            blacklist_questions: blacklistQuestions,
            document_id: docId,
          };

      const response = await doraAPI.post<{
        questions: string[];
      }>(DoraAPIEndpoints.suggestedQuestions, params);

      return response.data.questions;
    },
  });
}
