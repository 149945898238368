import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { AnimateChat } from '../../../shared/dora/AnimateChat';
import { timeouts } from '../../../shared/dora/constants';
import { DoraMessageBubble } from '../../../shared/dora/DoraMessageBubble';
import type { EventSourceStoreType, FormSettingsStoreType } from '../../types';

const messages = defineMessages({
  leaveFeedback: {
    defaultMessage: 'Please leave feedback so I can improve!',
    id: 'YzAFXO',
  },
});

export const FeedbackMessage = (props: {
  eventSourceStore: EventSourceStoreType;
  formSettingsStore: FormSettingsStoreType;
}) => {
  const {
    eventSourceStore: { useEventSourceStore },
    formSettingsStore: { useFeedbackMessage },
  } = props;

  const { formatMessage } = useIntl();
  const isFeedbackMessageShown = useFeedbackMessage(
    (store) => store.isFeedbackMessageShown
  );
  const setIsFeedbackMessageShown = useFeedbackMessage(
    (store) => store.setIsFeedbackMessageShown
  );

  const isSummaryFetched = useEventSourceStore(
    (store) => store.isSummaryFetched
  );

  useEffect(() => {
    if (isSummaryFetched && !isFeedbackMessageShown) {
      setTimeout(() => {
        setIsFeedbackMessageShown(true);
      }, timeouts.showNextMessage);
    }
  }, [isSummaryFetched, isFeedbackMessageShown, setIsFeedbackMessageShown]);

  return (
    isFeedbackMessageShown && (
      <AnimateChat shouldAnimateOnMount={!isFeedbackMessageShown}>
        <DoraMessageBubble>
          <TextStyle className="mb-6">
            <FormattedMessage
              defaultMessage="I hope this summary was helpful! <strong>Remember, make sure to copy and save this summary</strong> before closing this window. If you want to change just one or a few settings, you can start a new summary with the same settings applied, then make edits."
              id="MNCoby"
              values={{
                strong: (text) => (
                  <TextStyle as="span" variant="base-medium">
                    {text}
                  </TextStyle>
                ),
              }}
            />
          </TextStyle>
          <TextStyle className="text-gray-9">
            {formatMessage(messages.leaveFeedback)}
          </TextStyle>
        </DoraMessageBubble>
      </AnimateChat>
    )
  );
};
