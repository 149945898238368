import {
  APIEndpoints,
  assemblyAPI,
  type AssemblySearchResult,
  type UserFeedApiResponse,
  useUserDetails,
} from '@assembly-web/services';
import {
  type InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { produce } from 'immer';

import { CHALLENGE_STATUS } from '../stores/queryKeyStore';
import { useChallengeFeedQueryKey } from './useChallengeFeedQueryKey';
import type { CreateChallengePayload } from './useCreateChallengeMutation';

export function useUpdateChallengeMutation({
  onSuccess,
  onError,
}: {
  onSuccess: (payload: CreateChallengePayload) => void;
  onError: (
    error: unknown,
    payload: { challengeId: string; payload: CreateChallengePayload }
  ) => void;
}) {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();
  const { memberId } = userDetails?.member ?? {};

  const {
    queryKey,
    allChallengesQueryKey,
    statusFilter: statusFilterArray,
  } = useChallengeFeedQueryKey();

  return useMutation({
    mutationFn: async ({
      challengeId,
      payload,
    }: {
      challengeId: string;
      payload: CreateChallengePayload;
    }) => {
      return assemblyAPI.put(APIEndpoints.editChallenge(challengeId), payload);
    },
    onMutate: async ({ challengeId, payload }) => {
      const isValidState = statusFilterArray.length
        ? [
            payload.launchNow
              ? CHALLENGE_STATUS.ACTIVE
              : CHALLENGE_STATUS.QUEUED,
          ].some((item) => statusFilterArray.includes(item))
        : payload.launchNow;

      await queryClient.cancelQueries({ queryKey });

      if (isValidState) {
        queryClient.setQueryData(
          queryKey,
          produce((draft: InfiniteData<UserFeedApiResponse> | undefined) => {
            draft?.pages.forEach((page) => {
              page.data.forEach((challenge) => {
                if (challenge.id === challengeId) {
                  const updatedChallenge = getOptimisticData({
                    challenge,
                    payload,
                    memberId: memberId ?? '',
                  });
                  Object.assign(challenge, updatedChallenge);
                }
              });
            });
          })
        );
      } else {
        queryClient.setQueryData(
          queryKey,
          produce((draft: InfiniteData<UserFeedApiResponse> | undefined) => {
            draft?.pages.forEach((page) => {
              page.data = page.data.filter(
                (challenge) => challenge.id !== challengeId
              );
            });
          })
        );
      }
    },
    onSuccess: (_, context) => {
      onSuccess(context.payload);
    },
    onError: (err, payload) => {
      onError(err, payload);
    },
    onSettled: () => {
      // Invalidate the cache after 3 seconds to sync with ES
      setTimeout(() => {
        queryClient.invalidateQueries(allChallengesQueryKey);
      }, 3000);
    },
  });
}

export const isCurrentUserParticipant = ({
  values,
  memberId,
}: {
  values: { value: string; perm: string }[];
  memberId: string;
}) => {
  return values.some(
    ({ value, perm }) =>
      value === memberId &&
      (perm === 'participant-approver' || perm === 'participant')
  );
};

const getOptimisticData = ({
  challenge,
  payload,
  memberId,
}: {
  challenge: AssemblySearchResult;
  payload: CreateChallengePayload;
  memberId: string;
}) => {
  const { cardDetails } = challenge;
  const {
    title,
    description,
    points,
    claimButtonText,
    interactionSettings,
    launchNow,
    image,
    imageUrl,
    sharingRules,
  } = payload;

  const updatedAt = new Date().toISOString();

  const defaultSelfClaimDetails = {
    claims: [],
  };

  return {
    ...challenge,
    cardDetails: {
      ...cardDetails,
      image: {
        name: image?.name ?? '',
        location: imageUrl ?? '',
        size: image?.size ?? 0,
        type: 'image',
        createdAt: new Date().toISOString(),
        originalName: image?.name ?? '',
        thumbnails: {
          '32': '',
          '256': '',
          '512': '',
        },
      },
      updatedAt,
      description,
      title,
      points,
      claimButtonText,
      selfClaimDetails: sharingRules?.include.some((item) => {
        if (item.field === 'member') {
          return isCurrentUserParticipant({ values: item.values, memberId });
        }
      })
        ? defaultSelfClaimDetails
        : null,
      interactionSettings,
      state: launchNow ? 'ACTIVE' : 'QUEUED',
    },
  };
};
