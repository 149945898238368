import { samlLogo } from '@assembly-web/assets';
import type { AssemblyInfoAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  config,
  getProviderName,
  lookupSSOProvider,
  SSOProvider,
  unauthenticatedAssemblyAPI,
  userAuthStore,
} from '@assembly-web/services';
import { AppLink, Banner, Button } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { LoaderFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useLoaderData,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../../../services/analytics';
import type { ReactRouterLoaderResponse } from '../../../../../types/libs';
import { OnboardingContainer } from '../../../components/OnboardingContainer';
import { SSOButton } from '../../../components/SSOButton';
import { useGooglePlatformScriptLoader } from '../../../hooks/useGooglePlatformScriptLoader';
import { handleSSOForLogin } from '../../../services/sso';
import { onBoardingState } from '../../../stores/onboardingStore';

const loginProviderFormText = defineMessages({
  ssoTitle: {
    defaultMessage: 'You’re registered with {provider}',
    id: 'Agyq5A',
  },
  ssoDescription: {
    defaultMessage:
      'You’ve previously used {provider} to authorize your account for this workspace. Please click the “Log in with {provider}” button to get back into your workspace.',
    id: 'fBDjzf',
  },
  samlTitle: {
    defaultMessage: 'Please log in with {samlText}',
    id: 'BbeGkH',
  },
  samlDescription: {
    defaultMessage:
      'This workspace requires you to authorize your account with {samlText}. Please click the “Log in with {samlText}” button to get back into your workspace.',
    id: 'BoJFNZ',
  },
  samlCTA: {
    defaultMessage: 'Log in with {samlText}',
    id: 'uc2nY1',
  },
  footer: {
    defaultMessage: 'Is this incorrect? <cta>Return to login</cta>',
    id: 'pCjZvR',
  },
  workspaceMismatch: {
    defaultMessage:
      'Whoops! Looks like you may have selected the wrong {provider} workspace. Please double check and try again.',
    id: 'eAS4vy',
  },
});

function LoginWithSSOProvider({
  ssoProvider,
  assemblyId,
  workspaceSlug,
}: {
  ssoProvider: SSOProvider;
  assemblyId: string;
  workspaceSlug: string;
}) {
  trackRegistrationAction('ssoClicked', {
    ssoType: getProviderName(ssoProvider),
    ssoEnforced: true,
  });

  const { formatMessage } = useIntl();

  const googleScriptLoaded = useGooglePlatformScriptLoader({
    width: '400px',
  });

  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get('error');

  useEffect(() => {
    if (googleScriptLoaded) {
      // auto=true + join -> login
      // auto=false + join -> sign up API -> create-account
      userAuthStore
        .getState()
        .updateEnforcedLoginDetails(
          assemblyId,
          workspaceSlug,
          userAuthStore.getState().userAuthFlow === 'create-account'
            ? 'create-account'
            : 'login'
        );
    }
  }, [assemblyId, googleScriptLoaded, workspaceSlug]);

  const provider = getProviderName(ssoProvider);
  const { resetBrandingToDefaults } = onBoardingState.getState();

  if (errorCode) {
    trackRegistrationError('ssoClicked', {
      ssoType: provider,
      ssoEnforced: true,
    });
  }

  return (
    <OnboardingContainer
      title={formatMessage(loginProviderFormText.ssoTitle, { provider })}
      description={formatMessage(loginProviderFormText.ssoDescription, {
        provider,
      })}
      footer={formatMessage(loginProviderFormText.footer, {
        cta: (text: ReactNode[]) => (
          <AppLink to="/login" onClick={() => resetBrandingToDefaults()}>
            {text}
          </AppLink>
        ),
      })}
    >
      {errorCode === 'workspace_mismatch' && (
        <Banner status="error">
          {formatMessage(loginProviderFormText.workspaceMismatch, {
            provider,
          })}
        </Banner>
      )}

      {ssoProvider !== SSOProvider.Google && (
        <SSOButton
          isFullWidth
          provider={ssoProvider}
          flow="login"
          onClick={() => {
            handleSSOForLogin(ssoProvider);
          }}
        />
      )}
      {ssoProvider === SSOProvider.Google && (
        <div
          id="google-signin-button"
          className="col-span-1 rounded-md border border-gray-9"
        />
      )}
    </OnboardingContainer>
  );
}

function LoginWithSAML({
  samlID,
  samlText,
}: {
  samlText: string;
  samlID: string;
}) {
  const { formatMessage } = useIntl();
  const isMobileApp = userAuthStore.getState().isLoginViaMobileApp;

  trackRegistrationAction('ssoClicked', {
    ssoType: 'saml',
    samlEnforced: true,
    ssoEnforced: true,
  });
  return (
    <OnboardingContainer
      title={formatMessage(loginProviderFormText.samlTitle, { samlText })}
      description={formatMessage(loginProviderFormText.samlDescription, {
        samlText,
      })}
      footer={formatMessage(loginProviderFormText.footer, {
        cta: (text: ReactNode[]) => <AppLink to="/login">{text}</AppLink>,
      })}
    >
      <Button
        variation="secondaryLite"
        isFullWidth
        onClick={() => {
          window.location.href = `${
            config.domains.api
          }/external/v1/saml/login/${samlID}?isMobileApp=${Boolean(
            isMobileApp
          )}`;
        }}
      >
        <img
          className="h-fit w-fit"
          src={samlLogo}
          role="presentation"
          alt=""
        />
        {formatMessage(loginProviderFormText.samlCTA, { samlText })}
      </Button>
    </OnboardingContainer>
  );
}

export function LoginProviderRoute() {
  const { provider: providerName, workspaceSlug } = useParams();
  const loaderData = useLoaderData() as ReactRouterLoaderResponse<
    typeof loginProviderLoader
  >;
  const ssoProvider = lookupSSOProvider(providerName);

  const { samlDetails } = userAuthStore.getState();

  if (providerName?.toLowerCase() === 'saml' && samlDetails) {
    return (
      <LoginWithSAML samlText={samlDetails.text} samlID={samlDetails.id} />
    );
  }

  if (!ssoProvider || !workspaceSlug) {
    trackRegistrationError('ssoClicked', {
      ssoEnforced: true,
    });
    return null;
  }

  return (
    <LoginWithSSOProvider
      ssoProvider={ssoProvider}
      workspaceSlug={workspaceSlug}
      assemblyId={loaderData.assemblyId}
    />
  );
}

export async function loginProviderLoader({
  params,
  request: { signal },
}: LoaderFunctionArgs) {
  const shortCode = params.workspaceSlug?.split('-').pop();

  if (!shortCode) {
    return redirect('/login');
  }

  const { data } =
    await unauthenticatedAssemblyAPI.get<AssemblyInfoAPIResponse>(
      APIEndpoints.assemblyInfoFromSlugShortCode(shortCode),
      { signal }
    );

  const { updateDetails } = onBoardingState.getState();

  updateDetails(data.name, data.logo);
  return data;
}
