import { type ReactNode, useEffect } from 'react';

import { timeouts } from '../../shared/dora/constants';
import { MemberChatMessage } from '../../shared/dora/MemberChatMessage';
import type { MemberAvatarProps } from '../types';

export function AnswerMessage(
  props: {
    children: ReactNode;
    isEditDisabled?: boolean;
    isEditing?: boolean;
    isFaded?: boolean;
    isSeen: boolean;
    isSet: boolean;
    markSeen: () => void;
    markSet: () => void;
    onEdit?: () => void;
    setShouldRequestInput: (shouldRequestInput: boolean) => void;
    shouldRequestInput: boolean;
  } & MemberAvatarProps
) {
  const {
    children,
    isEditDisabled,
    isEditing,
    isFaded,
    isSeen,
    isSet,
    markSeen,
    markSet,
    memberFullName,
    memberId,
    memberImage,
    onEdit,
    setShouldRequestInput,
    shouldRequestInput,
  } = props;

  useEffect(() => {
    if (shouldRequestInput) {
      setTimeout(() => {
        // When answer is shown, delay hiding the input
        setShouldRequestInput(false);
      }, timeouts.hideInput);
    }
  }, [setShouldRequestInput, shouldRequestInput]);

  useEffect(() => {
    if (!isSeen) {
      markSeen();
    }
  }, [isSeen, markSeen]);

  useEffect(() => {
    if (!shouldRequestInput && !isSet) {
      setTimeout(() => {
        markSet();
      }, timeouts.showNextMessage);
    }
  }, [isSet, markSet, shouldRequestInput]);

  return (
    <MemberChatMessage
      fullName={memberFullName}
      image={memberImage}
      isEditDisabled={isEditDisabled}
      isEditing={isEditing}
      isFaded={isFaded}
      memberId={memberId}
      onEdit={onEdit}
      shouldAnimate={!isSeen}
    >
      {children}
    </MemberChatMessage>
  );
}
