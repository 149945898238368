import { useDeviceInfo } from '@assembly-web/ui';

import { CloseToolbarButton } from '../FlowSummaryDrawer/CloseToolbarButton';
import { CollapseToolbarButton } from '../FlowSummaryDrawer/CollapseToolbarButton';
import { EnterFullScreenToolbarButton } from './EnterFullScreenToolbarButton';
import { ExitFullScreenToolbarButton } from './ExitFullScreenToolbarButton';

export function DefaultToolbar(props: {
  isOpen: boolean;
  isFullScreen: boolean;
  onCollapse: () => void;
  onClose: () => void;
  toggleFullScreen: () => void;
  hideCloseButton?: boolean;
}) {
  const {
    isOpen,
    isFullScreen,
    onCollapse,
    onClose,
    hideCloseButton,
    toggleFullScreen,
  } = props;

  const { deviceType } = useDeviceInfo();
  const isMobile = deviceType === 'mobile';

  const FullScreenToolbarButton = isFullScreen
    ? ExitFullScreenToolbarButton
    : EnterFullScreenToolbarButton;

  return (
    <>
      {Boolean(isOpen) && (
        <>
          <CollapseToolbarButton onClick={onCollapse} />
          {!isMobile && <FullScreenToolbarButton onClick={toggleFullScreen} />}
        </>
      )}
      {!hideCloseButton && <CloseToolbarButton onClick={onClose} />}
    </>
  );
}
