import type { FlowBuilderState } from '@assembly-web/services';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import {
  useAtLeastOneBlock,
  useFlowBuilderStore,
  useSetErrors,
} from '../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../context/EditorDataContext';
import {
  validatePointsPercent,
  validateQuestionText,
  validateScaleLabels,
  validateSelectBlockOptions,
  validateSelectBlockOptionText,
  validateSelectionRange,
  validateTitle,
} from '../utils/validate';

export const useIsBlocksValid = () => {
  const { id } = useEditorDataContext();

  const atLeastOneBlock = useAtLeastOneBlock(id);

  // add in the individual block validation here
  // ref: https://github.com/joinassembly/assembly_frontend/blob/master/src/hooks/useFlowBuilderValidation/index.ts

  return { isEmpty: !atLeastOneBlock };
};

export const useValidateBlocks = () => {
  const { id } = useEditorDataContext();
  const { formatMessage } = useIntl();

  const setErrors = useSetErrors(id);

  return useCallback(() => {
    const flow = useFlowBuilderStore.getState().flows[id];
    const errors: FlowBuilderState['errors'] = {};

    if (!flow) {
      return false;
    }

    validateTitle(errors, formatMessage, flow.flowName);
    validateQuestionText(
      errors,
      formatMessage,
      flow.blockData.CONTENT.contentBlocks
    );
    validateSelectBlockOptions(
      errors,
      formatMessage,
      flow.blockData.CONTENT.contentBlocks
    );
    validateSelectBlockOptionText(
      errors,
      formatMessage,
      flow.blockData.CONTENT.contentBlocks
    );
    validateScaleLabels(
      errors,
      formatMessage,
      flow.blockData.CONTENT.contentBlocks
    );
    validatePointsPercent(
      errors,
      formatMessage,
      flow.blockData.CONTENT.contentBlocks
    );
    validateSelectionRange(
      errors,
      formatMessage,
      flow.blockData.CONTENT.contentBlocks
    );

    setErrors(errors);

    return errors;
  }, [formatMessage, id, setErrors]);
};
