import {
  CriteriaRuleType,
  isUserAdmin,
  type ManagerCriteriaAPIResponse,
  type MemberCriteriaAPIResponse,
  MemberState,
  type NonMemberCriteriaAPIResponse,
  Operator,
  PermissionType,
  type ShareCriteria,
  useUserDetails,
} from '@assembly-web/services';
import { AvatarSize, type CriteriaItemProps } from '@assembly-web/ui';
import isEqual from 'lodash/isEqual';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import type { Updater } from 'use-immer';

import {
  getPermissions,
  getPermissionsForChallenge,
  getPermissionsForOwnerAndCollaborators,
  getPermissionsForPeopleSelector,
  sortInitialCriteria,
} from '../../services/criteriaUtil';
import {
  getOwnerAndCollaboratorPermissionOptions,
  getPeopleSelectorPermissionOptions,
  getPermissionOptions as getCollectionPermissionOptions,
} from '../../services/shareCollectionsData';
import {
  CriteriaCardIconMap,
  type DefaultPermission,
} from '../../services/shareSheetData';
import { getPermissionOptions as getChallengesPermissionOptions } from '../shareChallenge/useShareChallengesData';
import { messages } from './messages';
import { getCriteriaId, sortCurrentUserToTop } from './useShareSheet';

type UseShareSheetCriteriaRulesProps = {
  initialRules?: ShareCriteria;
  owner?: {
    ownerId?: string | null;
    ownerState?: MemberState;
  };
  transformedInitialRules?: CriteriaItemProps[] | null;
  rules: CriteriaItemProps[];
  setHasRulesChanged?: Dispatch<SetStateAction<boolean>>;
  setRules: Updater<CriteriaItemProps[]>;
  type: keyof typeof DefaultPermission;
};

export const useShareSheetCriteriaRules = ({
  initialRules: defaultRules,
  owner,
  rules,
  setHasRulesChanged,
  setRules,
  transformedInitialRules,
  type,
}: UseShareSheetCriteriaRulesProps) => {
  const { formatMessage } = useIntl();
  const { data } = useUserDetails();
  invariant(data, 'User details must be available');

  const permissionMap = useMemo(
    () =>
      ({
        challenge: getPermissionsForChallenge,
        collection: getPermissions,
        ownerAndCollaborators: getPermissionsForOwnerAndCollaborators,
        peopleSelector: getPermissionsForPeopleSelector,
      }) as const,
    []
  );

  const permissionOptionsMap = useMemo(
    () =>
      ({
        challenge: getChallengesPermissionOptions,
        collection: getCollectionPermissionOptions,
        ownerAndCollaborators: getOwnerAndCollaboratorPermissionOptions,
        peopleSelector: getPeopleSelectorPermissionOptions,
      }) as const,
    []
  );

  if (import.meta.env.DEV) {
    // crash in dev when the map is not updated for a new type
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!(type in permissionMap)) {
        throw new Error(`Missing permissionMap for type: ${type}`);
      }
      if (!(type in permissionOptionsMap)) {
        throw new Error(`Missing permissionOptionsMap for type: ${type}`);
      }
    }, [permissionMap, permissionOptionsMap, type]);
  }

  const getTooltipText = useCallback(
    ({
      hasEveryoneRule,
    }: {
      hasEveryoneRule?: boolean;
      hasNonMemberCriteriaOtherThanEveryone?: boolean;
    }) => {
      if (hasEveryoneRule)
        return formatMessage(messages.nonMemberCriteriaTooltipWithEveryone);

      return undefined;
    },
    [formatMessage]
  );

  const getEmailCriteriaCardFromAPIResponse = useCallback(
    ({
      emailRule,
      isExcluded = false,
      hasNonMemberCriteriaOtherThanEveryone,
    }:
      | {
          emailRule: Omit<MemberCriteriaAPIResponse, 'perm'>;
          isExcluded: true;
          hasNonMemberCriteriaOtherThanEveryone: boolean;
        }
      | {
          emailRule: MemberCriteriaAPIResponse;
          isExcluded: false;
          hasNonMemberCriteriaOtherThanEveryone: boolean;
        }) => ({
      id: getCriteriaId({
        value: emailRule.value,
        operator: emailRule.operator,
        field: CriteriaRuleType.Email,
      }),
      title: emailRule.meta.name,
      subtitle: emailRule.value,
      customIcon: {
        icon: CriteriaCardIconMap['pending'],
        iconBackgroundColor: 'bg-gray-4',
      },
      permission: (() => {
        return permissionMap[type]({
          perm: 'perm' in emailRule ? emailRule.perm : undefined,
          isExcluded,
          isOwner: false,
        });
      })(),
      permissionOptions: (() => {
        return permissionOptionsMap[type]({
          type: CriteriaRuleType.Email,
          isOwner: false,
          formatMessage,
          isAdmin: emailRule.meta.role
            ? isUserAdmin({ role: emailRule.meta.role })
            : false,
        });
      })(),
      metaData: {
        field: CriteriaRuleType.Email,
        operator: emailRule.operator,
        value: emailRule.value,
        email: emailRule.value,
      },
      tooltipText: getTooltipText({
        hasNonMemberCriteriaOtherThanEveryone,
      }),
    }),
    [formatMessage, getTooltipText, permissionMap, permissionOptionsMap, type]
  );

  const getManagerCriteriaCardFromAPIResponse = useCallback(
    ({
      criteria,
      isExcluded,
      hasEveryoneRule,
    }:
      | {
          criteria: Omit<ManagerCriteriaAPIResponse, 'perm'>;
          isExcluded: true;
          hasEveryoneRule: boolean;
        }
      | {
          criteria: ManagerCriteriaAPIResponse;
          isExcluded: false;
          hasEveryoneRule: boolean;
        }): CriteriaItemProps => ({
      id: getCriteriaId({
        value: criteria.value.toString(),
        operator: criteria.operator,
        field: CriteriaRuleType.ManagerStatus,
      }),
      title: formatMessage(messages.managerRule, {
        condition:
          criteria.operator === 'isManager'
            ? formatMessage(messages.isTheManagerOfTheParticipant)
            : criteria.value
              ? formatMessage(messages.isTrue)
              : formatMessage(messages.isFalse),
      }),
      customIcon: {
        icon: CriteriaCardIconMap[CriteriaRuleType.ManagerStatus],
      },
      permission: (() => {
        return permissionMap[type]({
          perm: 'perm' in criteria ? criteria.perm : undefined,
          isExcluded,
          isOwner: false,
        });
      })(),
      permissionOptions: (() => {
        return permissionOptionsMap[type]({
          type: CriteriaRuleType.ManagerStatus,
          formatMessage,
          isManagerOfParticipant: criteria.operator === 'isManager',
        });
      })(),
      metaData: {
        field: CriteriaRuleType.ManagerStatus,
        operator: criteria.operator,
        value: criteria.value.toString(),
      },
      tooltipText: getTooltipText({
        hasEveryoneRule,
      }),
    }),
    [formatMessage, getTooltipText, permissionMap, permissionOptionsMap, type]
  );

  const getMemberCriteriaCardFromAPIResponse = useCallback(
    ({
      member,
      isExcluded = false,
      hasNonMemberCriteriaOtherThanEveryone,
    }:
      | {
          member: Omit<MemberCriteriaAPIResponse, 'perm'>;
          isExcluded: true;
          hasNonMemberCriteriaOtherThanEveryone: boolean;
        }
      | {
          member: MemberCriteriaAPIResponse;
          isExcluded: false;
          hasNonMemberCriteriaOtherThanEveryone: boolean;
        }) => ({
      id: getCriteriaId({
        value: member.value,
        operator: member.operator,
        field: CriteriaRuleType.Member,
      }),
      title:
        member.meta.memberId === data.member.memberId
          ? formatMessage(messages.currentUserLabel, {
              currentUser: member.meta.name,
            })
          : member.meta.name,
      subtitle: member.meta.email,
      role: member.meta.role,
      state: member.meta.state,
      ...(member.meta.state === MemberState.Active
        ? {
            avatar: {
              image: member.meta.image,
              name: member.meta.name,
              memberID: member.value,
              size: AvatarSize.ExtraLarge,
            },
          }
        : {
            customIcon: {
              icon: CriteriaCardIconMap[MemberState.Active.toLowerCase()],
              iconBackgroundColor:
                member.meta.state === MemberState.Pending ? 'bg-gray-4' : '',
            },
          }),
      permission: (() => {
        return permissionMap[type]({
          perm: 'perm' in member ? member.perm : undefined,
          isExcluded,
          isOwner: member.meta.memberId === owner?.ownerId,
          isDeactivatedOwner: owner?.ownerState === MemberState.Deactivated,
        });
      })(),
      permissionOptions: (() => {
        return permissionOptionsMap[type]({
          type: CriteriaRuleType.Member,
          isOwner: member.meta.memberId === owner?.ownerId,
          formatMessage,
          isAdmin: member.meta.role
            ? isUserAdmin({ role: member.meta.role })
            : false,
          isDeactivatedOwner: owner?.ownerState === MemberState.Deactivated,
        });
      })(),
      metaData: {
        field: CriteriaRuleType.Member,
        operator: member.operator,
        value: member.value,
        name: member.meta.name,
        email: member.meta.email,
      },
      tooltipText: getTooltipText({
        hasNonMemberCriteriaOtherThanEveryone,
      }),
    }),
    [
      data.member.memberId,
      formatMessage,
      getTooltipText,
      owner,
      permissionMap,
      permissionOptionsMap,
      type,
    ]
  );

  const getNonMemberCriteriaCardFromAPIResponse = useCallback(
    ({
      criteria,
      isExcluded = false,
      field,
      hasEveryoneRule,
    }:
      | {
          criteria: Omit<NonMemberCriteriaAPIResponse, 'perm'>;
          isExcluded: true;
          field: string;
          hasEveryoneRule: boolean;
        }
      | {
          criteria: NonMemberCriteriaAPIResponse;
          isExcluded: false;
          field: string;
          hasEveryoneRule: boolean;
        }): CriteriaItemProps => ({
      id: getCriteriaId({
        value: criteria.value,
        operator: criteria.operator,
        field,
      }),
      title: formatMessage(messages[`${field}Rule` as keyof typeof messages], {
        condition:
          criteria.operator === Operator.Is
            ? formatMessage(messages.is)
            : formatMessage(messages.isNot),
        name: criteria.value,
      }),
      customIcon: {
        icon: CriteriaCardIconMap[field],
      },
      permission: (() => {
        return permissionMap[type]({
          perm: 'perm' in criteria ? criteria.perm : undefined,
          isExcluded,
          isOwner: false,
        });
      })(),
      permissionOptions: (() => {
        return permissionOptionsMap[type]({
          type: field as CriteriaRuleType,
          formatMessage,
        });
      })(),
      metaData: {
        field,
        operator: criteria.operator,
        value: criteria.value,
      },
      tooltipText: getTooltipText({
        hasEveryoneRule,
      }),
    }),
    [formatMessage, getTooltipText, permissionMap, permissionOptionsMap, type]
  );

  const [initialRules] = useState<CriteriaItemProps[]>(() => {
    if (transformedInitialRules) {
      return transformedInitialRules;
    }
    if (!defaultRules) {
      return [];
    }

    const initialShareCollectionRules = defaultRules;

    const hasEveryoneRule = [
      ...initialShareCollectionRules.include,
      ...initialShareCollectionRules.exclude,
    ].some((rule) => rule.field === CriteriaRuleType.Everyone);

    const hasNonMemberCriteriaOtherThanEveryone =
      initialShareCollectionRules.include.some(
        (rule) =>
          rule.field !== CriteriaRuleType.Member &&
          rule.field !== CriteriaRuleType.Email &&
          rule.field !== CriteriaRuleType.Everyone
      );

    const initialRules: CriteriaItemProps[] = [];
    sortInitialCriteria(initialShareCollectionRules).include.forEach(
      (includeRule) => {
        if (includeRule.field === CriteriaRuleType.Email) {
          includeRule.values.forEach((i) => {
            if (i.meta.state === MemberState.Deactivated) return;
            initialRules.push(
              getEmailCriteriaCardFromAPIResponse({
                emailRule: i,
                isExcluded: false,
                hasNonMemberCriteriaOtherThanEveryone,
              })
            );
          });
        } else if (includeRule.field === CriteriaRuleType.Member) {
          includeRule.values.forEach((i) => {
            initialRules.push(
              getMemberCriteriaCardFromAPIResponse({
                member: i,
                isExcluded: false,
                hasNonMemberCriteriaOtherThanEveryone,
              })
            );
          });
        } else if (includeRule.field === CriteriaRuleType.ManagerStatus) {
          includeRule.values.forEach((i) => {
            initialRules.push(
              getManagerCriteriaCardFromAPIResponse({
                criteria: i,
                isExcluded: false,
                hasEveryoneRule,
              })
            );
          });
        } else if (includeRule.field === CriteriaRuleType.Everyone) {
          initialRules.push({
            id: getCriteriaId({
              value: '',
              operator: includeRule.operator,
              field: CriteriaRuleType.Everyone,
            }),
            title: formatMessage(messages.everyoneInThisWorkspace),
            permission: (() => {
              return permissionMap[type]({
                perm: 'perm' in includeRule ? includeRule.perm : undefined,
                // 'everyone' rule cannot be excluded or be an owner
                isExcluded: false,
                isOwner: false,
              });
            })(),
            customIcon: {
              icon: CriteriaCardIconMap[includeRule.field],
            },
            permissionOptions: (() => {
              return permissionOptionsMap[type]({
                type: includeRule.field,
                formatMessage,
              });
            })(),
            metaData: {
              field: CriteriaRuleType.Everyone,
              operator: includeRule.operator,
              value: '',
            },
          });
        } else {
          includeRule.values.forEach((i) => {
            initialRules.push(
              getNonMemberCriteriaCardFromAPIResponse({
                criteria: i,
                isExcluded: false,
                field: includeRule.field,
                hasEveryoneRule,
              })
            );
          });
        }
      }
    );
    initialShareCollectionRules.exclude.forEach((excludeRule) => {
      if (excludeRule.field === CriteriaRuleType.Email) {
        excludeRule.values.forEach((i) => {
          initialRules.push(
            getEmailCriteriaCardFromAPIResponse({
              emailRule: i,
              isExcluded: true,
              hasNonMemberCriteriaOtherThanEveryone,
            })
          );
        });
      } else if (excludeRule.field === CriteriaRuleType.Member) {
        excludeRule.values.forEach((i) => {
          initialRules.push(
            getMemberCriteriaCardFromAPIResponse({
              member: i,
              isExcluded: true,
              hasNonMemberCriteriaOtherThanEveryone,
            })
          );
        });
      } else if (excludeRule.field === CriteriaRuleType.ManagerStatus) {
        excludeRule.values.forEach((i) => {
          initialRules.push(
            getManagerCriteriaCardFromAPIResponse({
              criteria: i,
              isExcluded: true,
              hasEveryoneRule,
            })
          );
        });
      } else {
        excludeRule.values.forEach((i) => {
          initialRules.push(
            getNonMemberCriteriaCardFromAPIResponse({
              criteria: i,
              isExcluded: true,
              field: excludeRule.field,
              hasEveryoneRule,
            })
          );
        });
      }
    });
    const owner = initialRules.find(
      (rule) => rule.permission?.id === PermissionType.Owner
    );
    const allRulesWithoutOwner = initialRules.filter(
      (rule) => rule.permission?.id !== PermissionType.Owner
    );
    let rulesToBeSet = owner
      ? [{ ...owner, tooltipText: undefined }, ...allRulesWithoutOwner]
      : initialRules;
    sortCurrentUserToTop(rulesToBeSet, data.member.memberId);
    setRules(rulesToBeSet);

    return rulesToBeSet;
  });

  useEffect(() => {
    setHasRulesChanged?.(!isEqual(rules, initialRules));
  }, [setHasRulesChanged, rules, initialRules]);
};
