import {
  type Nullable,
  type ReplyData,
  sanitizeHtml,
  useDebounceFn,
} from '@assembly-web/services';
import type { EditorData, EditorStateChangeArgs } from '@assembly-web/ui';
import { type MutableRefObject, useCallback, useState } from 'react';

import { useMultiDrawerStore } from '../../../../../../stores/useMultiDrawerStore';
import { cleanEditorState } from '../../PostDrawer/utils/util';
import type { Drawer } from '../../types';
import { useSaveChallenge } from './useSaveChallenge';

const AutoSaveDebounceTime = 3000;

export function useChallengeRepliesEditor({
  commentId,
  challengeId,
  containerRef,
  selectedReply,
  isMembersListLoading,
  onSelectedReplyChange,
}: {
  commentId?: string;
  challengeId: string;
  isMembersListLoading: boolean;
  selectedReply?: Nullable<ReplyData>;
  containerRef: MutableRefObject<HTMLElement | null>;
  onSelectedReplyChange: (
    reply: Nullable<ReplyData>,
    commentId: string
  ) => void;
}) {
  const findAndUpdateDrawerField = useMultiDrawerStore(
    (store) => store.findAndUpdateDrawerField
  );

  const drawers = useMultiDrawerStore((store) => store.getDrawers());
  const challengeDrawer = drawers.find((drawer) => drawer.id === challengeId);

  const [hasProfanity, setHasProfanity] = useState(false);
  const [characterLength, setCharacterLength] = useState(0);

  const [isMentionsMenuOpen, setIsMentionsMenuOpen] = useState(false);

  const {
    setDraft,
    addReply,
    saveDraft,
    savedDraft,
    draftData,
    updateReply,
    isAddReplyError,
    isUpdateReplyError,
    isDraftsLoading,
  } = useSaveChallenge({ challengeId, selectedReply });

  const handleOnChange = useCallback(
    (data: string) => {
      const isLoading = isMembersListLoading || isDraftsLoading;

      const hasUpdatedDraft =
        JSON.stringify(savedDraft.current) !== JSON.stringify(data);

      if (
        !isLoading &&
        !hasProfanity &&
        challengeDrawer &&
        hasUpdatedDraft &&
        !isMentionsMenuOpen
      ) {
        savedDraft.current = data;

        saveDraft({
          entityType: 'REPLY',
          replyData: {
            kind: 'CHALLENGE',
            postId: challengeId,
            messageTokens: data,
          },
        });
      }
    },
    [
      saveDraft,
      savedDraft,
      challengeId,
      hasProfanity,
      challengeDrawer,
      isDraftsLoading,
      isMentionsMenuOpen,
      isMembersListLoading,
    ]
  );

  const { debouncedFn: debouncedOnChange, cancel } = useDebounceFn(
    handleOnChange,
    AutoSaveDebounceTime
  );

  const resetEditedCommentId = useCallback(() => {
    if (!commentId) {
      return;
    }

    const update = (draft: Drawer) => {
      if (draft.type === 'challenges') {
        draft.data = {
          ...draft.data,
          challengeId,
        };
      }
    };

    findAndUpdateDrawerField(commentId, update);
  }, [challengeId, commentId, findAndUpdateDrawerField]);

  const handleOnEditorChange = useCallback(
    (args: EditorStateChangeArgs) => {
      const { plainText, errors, gifUrls } = args;

      if (hasProfanity && !errors.includes('HAS_PROFANITY')) {
        setHasProfanity(false);
      }

      setCharacterLength(plainText.length);

      setDraft({
        draft: {
          entityType: 'REPLY',
          replyData: {
            challengeId,
            kind: 'CHALLENGE',
            messageTokens: args.editorState,
          },
        },
      });

      if (
        (plainText.length !== 0 || gifUrls.length !== 0) &&
        !isMentionsMenuOpen
      ) {
        debouncedOnChange(args.editorState);
      } else {
        cancel();
        handleOnChange(args.editorState);
      }
    },
    [
      cancel,
      setDraft,
      challengeId,
      hasProfanity,
      handleOnChange,
      debouncedOnChange,
      isMentionsMenuOpen,
    ]
  );

  const handleCancelClick = useCallback(() => {
    onSelectedReplyChange(null, challengeId);
    cancel();
    handleOnChange('');
    setDraft({ draft: {} });
    resetEditedCommentId();
  }, [
    cancel,
    setDraft,
    challengeId,
    handleOnChange,
    onSelectedReplyChange,
    resetEditedCommentId,
  ]);

  const handleOnPostReplyClick = useCallback(
    (editorData: EditorData) => {
      const { errors, mentionIds, boost } = editorData;

      if (errors && errors.length > 0) {
        if (errors.includes('HAS_PROFANITY')) {
          setHasProfanity(true);
        }

        return;
      }

      const { html, json, plainText } = cleanEditorState(editorData);

      if (
        plainText.length === 0 &&
        !html.includes('<img') &&
        !html.includes('data-lexical-mention-id')
      ) {
        if (selectedReply) {
          // Delete the reply.
        }
        return;
      }

      cancel();
      handleOnChange('');

      if (selectedReply) {
        const hasContentChanged = json !== selectedReply.messageTokens;
        if (!hasContentChanged) {
          onSelectedReplyChange(null, challengeId);
          return;
        }

        updateReply({
          boost,
          plainText,
          messageTokens: json,
          mentions: mentionIds,
          messageHtml: sanitizeHtml(html),
          replyId: selectedReply.commentID,
        });

        onSelectedReplyChange(null, challengeId);
      } else {
        addReply({
          boost,
          plainText,
          challengeId,
          kind: 'CHALLENGE',
          messageTokens: json,
          mentions: mentionIds,
          messageHtml: sanitizeHtml(html),
        });

        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: containerRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      }

      setDraft({ draft: {} });
    },
    [
      cancel,
      addReply,
      setDraft,
      challengeId,
      updateReply,
      containerRef,
      handleOnChange,
      selectedReply,
      onSelectedReplyChange,
    ]
  );

  const initialDraft =
    draftData?.draft && 'replyData' in draftData.draft
      ? draftData.draft.replyData.messageTokens
      : null;

  return {
    hasProfanity,
    initialDraft,
    characterLength,
    isAddReplyError,
    isDraftsLoading,
    handleCancelClick,
    isUpdateReplyError,
    handleOnEditorChange,
    setIsMentionsMenuOpen,
    handleOnPostReplyClick,
  };
}
