import type {
  DateRange,
  DoraChatHistoryAPIResponse,
  FileType,
} from '@assembly-web/services';
import { useQueryClient } from '@tanstack/react-query';

import { useGetDoraChatHistoryQuery } from '../../../../hooks/dora/useChatHistoryQuery';
import { getDoraChatHistoryQueryKey } from '../../../../queries/getDoraChatHistoryQuery';
import { useAskDoraStore } from '../../../../stores/useAskDoraStore';

export type RefetchParams = {
  documentId?: string;
  fileTypes?: FileType[];
  dateRange?: DateRange;
};

export function useRefetchResponse(props: {
  onRefetch?: () => void;
  promptId?: string | null;
}) {
  const { onRefetch, promptId } = props;

  const queryClient = useQueryClient();

  const addQuestionAnswerBlock = useAskDoraStore(
    (store) => store.addQuestionAnswerBlock
  );
  const threadId = useAskDoraStore((store) => store.getThreadId());
  const { data: chatHistoryData } = useGetDoraChatHistoryQuery(threadId, {
    enabled: Boolean(threadId),
  });

  const updateChatHistoryCache = () => {
    const queryKey = getDoraChatHistoryQueryKey(threadId);
    const chatHistory =
      queryClient.getQueryData<DoraChatHistoryAPIResponse>(queryKey) ?? [];
    chatHistory.splice(-2);
    queryClient.setQueryData(queryKey, chatHistory);
  };

  const refetchResponse = (params: RefetchParams) => {
    const { documentId, fileTypes, dateRange = {} } = params;
    const lastUserBlock = chatHistoryData?.slice(-2)[0];
    if (lastUserBlock && promptId) {
      addQuestionAnswerBlock({
        question: lastUserBlock.content,
        dateRange,
        documentId,
        fileTypes,
        refetchPromptId: promptId,
      });
      updateChatHistoryCache();
    }
    onRefetch?.();
  };

  return refetchResponse;
}
