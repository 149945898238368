import { assemblyLogo, postIcon } from '@assembly-web/assets';
import type {
  AssemblyFileSource,
  ExternalFileSource,
  Source,
} from '@assembly-web/services';
import { mapHexCodeToEmoticon } from '@assembly-web/services';
import {
  Avatar,
  AvatarSize,
  getFileIconForFileCard as getFileIconForAssemblyFile,
  TextStyle,
} from '@assembly-web/ui';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { getFileIcon as getFileIconForExternalFile } from '../../services/files';
import { messages } from './messages';

export const defaultRecognitionFlowIcon = '1F389';

const buttonLabelSpacing = {
  '1': 'gap-1',
  '2': 'gap-2',
} as const;

type BaseButtonLabelProps = {
  index?: number;
  size?: 'sm' | 'md';
  label: string;
  spacing?: keyof typeof buttonLabelSpacing;
};

type ImageTypeProps = {
  type: 'image';
  src: string;
} & BaseButtonLabelProps;

type AvatarTypeProps = {
  type: 'avatar';
  avatar: {
    imageUrl?: string;
    name: string;
    id: string;
  };
} & BaseButtonLabelProps;

type SourceLabelProps = ImageTypeProps | AvatarTypeProps;

function getFileImageSource(
  answerSource: AssemblyFileSource | ExternalFileSource
) {
  if (answerSource.source === 'assembly' || !answerSource.source) {
    return getFileIconForAssemblyFile(answerSource.fileType);
  }

  const { icon, mimeType } = answerSource as ExternalFileSource;

  return getFileIconForExternalFile(mimeType) ?? icon;
}

const SourceLabel = (props: SourceLabelProps) => {
  const { index, label, size = 'md', spacing = 2 } = props;
  const labelVariant = size === 'sm' ? 'sm-regular' : 'base-regular';

  return (
    <div
      className={twMerge(
        'flex min-w-0 items-center',
        buttonLabelSpacing[spacing]
      )}
    >
      {Boolean(index) && <span className="flex-shrink-0">{index}</span>}
      <span className="flex-shrink-0">
        {props.type === 'image' ? (
          <img alt="" className="h-4 w-4 max-md:max-w-none" src={props.src} />
        ) : (
          <Avatar
            memberID={props.avatar.id}
            name={props.avatar.name}
            size={AvatarSize.ExtraSmall}
            image={props.avatar.imageUrl}
          />
        )}
      </span>
      <TextStyle
        className={twMerge(
          'inline-block truncate text-gray-9',
          size === 'sm' && 'text-gray-8'
        )}
        variant={labelVariant}
      >
        {label}
      </TextStyle>
    </div>
  );
};

export const DoraSourceLabel = (props: {
  source: Source;
  index?: number;
  size?: 'sm' | 'md';
}) => {
  const { index, size, source } = props;
  const { formatMessage } = useIntl();

  if (source.type === 'response' && source.fullName) {
    return (
      <SourceLabel
        index={index}
        label={formatMessage(messages.postByInFlowLabel, {
          flowName: source.name,
          authorName: source.fullName,
          emoji: mapHexCodeToEmoticon(
            source.icon ?? defaultRecognitionFlowIcon
          ),
        })}
        type="image"
        src={postIcon}
        size={size}
      />
    );
  } else if (source.type === 'response' && !source.fullName) {
    return (
      <SourceLabel
        index={index}
        label={formatMessage(messages.postedInFlowLabel, {
          flowName: source.name,
          emoji: mapHexCodeToEmoticon(
            source.icon ?? defaultRecognitionFlowIcon
          ),
        })}
        type="image"
        src={assemblyLogo}
        size={size}
      />
    );
  } else if (source.type === 'member') {
    return (
      <SourceLabel
        index={index}
        label={source.fullName}
        type="avatar"
        avatar={{
          id: source.id,
          name: source.fullName,
          imageUrl: source.profileImageUrl,
        }}
        size={size}
      />
    );
  } else if (source.type === 'file') {
    return (
      <SourceLabel
        index={index}
        label={
          source.source === 'assembly' && source.originalFileName
            ? source.originalFileName
            : source.name
        }
        type="image"
        src={getFileImageSource(source)}
        size={size}
      />
    );
  }
};
