import type { ContentBlockState } from '@assembly-web/services';
import { BlocksTable, EditorLoader } from '@assembly-web/ui';
import type { NamedExoticComponent } from 'react';

import {
  useBlockType,
  useListBlocks,
} from '../../../../../../stores/useFlowBuilderStore';
import { BlockIdProvider, useBlockIdContext } from '../context/BlockIdContext';
import { useEditorDataContext } from '../context/EditorDataContext';
import { FileUpload } from './block/FileUpload';
import { GIF } from './block/GIF';
import { GivePoints } from './block/GivePoints/GivePoints';
import { MultiChoiceBlock } from './block/MultiChoice/MultiChoice';
import { OpenEndedBlock } from './block/OpenEnded/OpenEnded';
import { PersonSelectionBlock } from './block/PersonSelection';
import { NPS } from './block/Scale/NPS';
import { Scale } from './block/Scale/Scale';
import { AddBlockMenu } from './BlocksMenu';

const map = {
  DROPDOWN: MultiChoiceBlock,
  MULTI_CHOICE: MultiChoiceBlock,
  OPEN_ENDED: OpenEndedBlock,
  PERSON_SELECTOR: PersonSelectionBlock,
  SCALE: Scale,
  NPS: NPS,
  GIF,
  FILE_UPLOAD: FileUpload,
  GIVE_POINTS_STACK: GivePoints,
} satisfies Record<ContentBlockState['type'], NamedExoticComponent>;

function Block() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();
  const blockType = useBlockType(id, blockId);

  if (!blockType) {
    return null;
  }

  const Comp = map[blockType];

  return <Comp />;
}

function BlocksLoader() {
  const { isLoading } = useEditorDataContext();

  if (!isLoading) {
    return null;
  }

  return Array.from({ length: 3 }).map((_, index) => (
    <BlocksTable.Loader key={index} />
  ));
}

function BlocksList() {
  const { id, isLoading } = useEditorDataContext();
  const blocks = useListBlocks(id);

  if (isLoading) {
    return null;
  }

  return blocks.map((blockId) => (
    <BlockIdProvider value={blockId} key={blockId}>
      <Block />
    </BlockIdProvider>
  ));
}

function TotalQuestion() {
  const { id, isLoading } = useEditorDataContext();
  const blocks = useListBlocks(id);

  if (isLoading) {
    return <EditorLoader className="h-8 w-28" />;
  }

  return <BlocksTable.TotalQuestions total={blocks.length} />;
}

export function Blocks() {
  return (
    <section className="flex flex-col gap-6">
      <BlocksTable.Root>
        <BlocksTable.Header />
        <BlocksTable.Body>
          <BlocksLoader />
          <BlocksList />
        </BlocksTable.Body>
        <BlocksTable.Footer left={<AddBlockMenu />} right={<TotalQuestion />} />
      </BlocksTable.Root>
    </section>
  );
}
