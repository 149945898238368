import {
  type ChallengeAPIResponse,
  formatToLocalDateTime,
  MemberState,
} from '@assembly-web/services';
import {
  AuthorButton,
  Avatar,
  AvatarSize,
  TextStyle,
  Toolbar,
  Tooltip,
  useToolbarState,
} from '@assembly-web/ui';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { useNavigateToUserFeed } from '../../../../../hooks/useNavigateToUserFeed';
import { useChallengeToolbarItems } from './hooks/useChallengeToolbarItems';

const messages = defineMessages({
  created: {
    defaultMessage: 'created',
    id: 'lr7wlb',
  },
  notLaunched: {
    defaultMessage: 'Not launched yet',
    id: '6n1njF',
  },
  createdAt: {
    defaultMessage: 'Created {timeCreatedAt}',
    id: 'Utroiq',
  },
  endedTimeAgo: {
    defaultMessage: 'Ended {timeAgo}',
    id: 'WQ0zA4',
  },
  launchedAgo: {
    defaultMessage: 'Launched {timeCreatedAt}',
    id: 'JGqEWq',
  },
  deactivatedUser: {
    defaultMessage: '(deactivated)',
    id: 'osOx6w',
  },
});

type ChallengeCardHeaderProps = {
  challengeDetails: ChallengeAPIResponse;
  isAdmin: boolean;
};

export function ChallengeCardHeader({
  challengeDetails,
  isAdmin,
}: ChallengeCardHeaderProps) {
  const { formatMessage } = useIntl();

  const { createdBy, state, title, canEdit, challengeId, isMuted, points } =
    challengeDetails;

  const { getToolbarProps } = useToolbarState({ hideToolbar: false });

  const { toolbarItems, onToolbarMenuItemClick: onMenuItemClick } =
    useChallengeToolbarItems({
      title,
      state,
      canEdit,
      isAdmin,
      challengeId,
      isMuted,
      showChallengeNotificationPreference: true,
      points,
      hideReactions: challengeDetails.interactionSettings?.hideReactions,
      hideReplies: challengeDetails.interactionSettings?.hideReplies,
    });

  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  return (
    <header className="flex justify-between">
      <div className="flex flex-nowrap">
        <Avatar
          name={createdBy.name}
          size={AvatarSize.Large}
          image={createdBy.image}
          memberID={createdBy.memberID}
          className={twJoin(
            createdBy.memberState === MemberState.Deactivated && 'grayscale'
          )}
        />
        <div className="flex flex-col justify-between pl-2">
          <div className="grid grid-cols-[auto_auto_1fr] align-baseline text-sm font-normal">
            <AuthorButton
              respondent={createdBy}
              onClick={() => {
                navigateToUserFeed(createdBy.memberID);
              }}
            />
            {formatMessage(messages.created)}
            <TextStyle
              variant="sm-medium"
              className="ml-1 line-clamp-1 text-gray-9"
            >
              {challengeDetails.title}
            </TextStyle>
          </div>
          <div className="flex items-center gap-1 align-middle text-sm text-gray-8">
            {state === 'CLOSED' && Boolean(challengeDetails.endedAt) && (
              <Tooltip
                tooltipText={
                  challengeDetails.endedAt
                    ? formatToLocalDateTime(challengeDetails.endedAt)
                    : ''
                }
              >
                <span className="cursor-pointer">
                  {formatMessage(messages.endedTimeAgo, {
                    timeAgo: challengeDetails.endedAt
                      ? dayjs(challengeDetails.endedAt).fromNow()
                      : '',
                  })}
                </span>
              </Tooltip>
            )}
            {state === 'ACTIVE' && (
              <Tooltip
                tooltipText={formatToLocalDateTime(challengeDetails.launchedAt)}
              >
                <span className="cursor-pointer">
                  {formatMessage(messages.launchedAgo, {
                    timeCreatedAt: dayjs(challengeDetails.launchedAt).fromNow(),
                  })}
                </span>
              </Tooltip>
            )}
            {(state === 'QUEUED' || state === 'INACTIVE') &&
              (challengeDetails.createdAt ? (
                <Tooltip
                  tooltipText={formatToLocalDateTime(
                    challengeDetails.createdAt
                  )}
                >
                  <span className="cursor-pointer">
                    {formatMessage(messages.createdAt, {
                      timeCreatedAt: challengeDetails.createdAt
                        ? dayjs(challengeDetails.createdAt).fromNow()
                        : '',
                    })}
                  </span>
                </Tooltip>
              ) : (
                <TextStyle variant="sm-regular" className="text-gray-8">
                  {formatMessage(messages.notLaunched)}
                </TextStyle>
              ))}
          </div>
        </div>
      </div>
      <div>
        <Toolbar
          {...getToolbarProps({ onMenuItemClick })}
          secondaryToolbarItems={toolbarItems}
          compact
          hideWhenDetached
        />
      </div>
    </header>
  );
}
