import { AnimatePresence } from 'framer-motion';

import type { IndividualBlocksInputProps } from '../../../../types';
import { Selector } from './Selector';

export const IndividualBlocksInput = (props: IndividualBlocksInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useIndividualBlocksSetting },
  } = props;

  const shouldRequestInput = useIndividualBlocksSetting(
    (store) => store.shouldRequestInput
  );

  const shouldRequestEdit = useEditSettingsStore(
    (store) => store.individualBlocks.shouldRequestInput
  );

  return (
    <AnimatePresence>
      {Boolean(shouldRequestInput || shouldRequestEdit) && (
        <Selector {...props} />
      )}
    </AnimatePresence>
  );
};
