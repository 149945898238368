import { TextStyle } from '@assembly-web/ui';

export const displayValuesMapper = {
  b: (content: React.ReactNode[]) => (
    <TextStyle as="span" variant="base-bold">
      {content}
    </TextStyle>
  ),
  li: (content: React.ReactNode[]) => <li>{content}</li>,
  p: (content: React.ReactNode[]) => <TextStyle>{content}</TextStyle>,
  quot: (content: React.ReactNode[]) => `"${content}"`,
  ul: (content: React.ReactNode[]) => (
    <ul className="ml-8 list-disc">{content}</ul>
  ),
};

export const markdownValuesMapper = {
  b: (content: string[]) => `**${content}**`,
  li: (content: string[]) => `- ${content}\n`,
  p: (content: string[]) => `${content}\n`,
  quot: (content: string[]) => `"${content}"`,
  ul: (content: string[]) => `\n${content}\n`,
};
