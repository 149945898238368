import {
  type BaseSelectableOption,
  SelectableList,
  type SelectableListProps,
} from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { BottomActionSheet } from '../../shared/dora/BottomActionSheet';

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Search',
    id: 'xmcVZ0',
  },
});

export const SelectableListInput = <T extends BaseSelectableOption>(
  props: Pick<
    SelectableListProps<T>,
    | 'onClearAllClick'
    | 'onDoneClick'
    | 'onSearchChange'
    | 'options'
    | 'renderOption'
    | 'selectedOptions'
  > & {
    isEditing?: boolean;
    onCancel?: () => void;
    shouldAnimateOnMount: boolean;
  }
) => {
  const { isEditing, onCancel, shouldAnimateOnMount, ...listProps } = props;
  const { formatMessage } = useIntl();

  return (
    <BottomActionSheet
      isEditing={isEditing}
      onCancel={onCancel}
      shouldAnimateOnMount={shouldAnimateOnMount}
    >
      <SelectableList<T>
        className={twMerge(
          'w-full',
          isEditing ? 'h-[226px] max-h-[226px]' : 'h-[264px] max-h-[264px]'
        )}
        isLoading={false}
        onLoadMore={() => {}}
        loader={null}
        placeholder={formatMessage(messages.placeholder)}
        showSearch
        showSelectedOnTop
        {...listProps}
      />
    </BottomActionSheet>
  );
};
