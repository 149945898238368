import { SVGReactComponents } from '@assembly-web/assets';
import { Button, IconButton, Tooltip } from '@assembly-web/ui';
import { ChevronDoubleLeftIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { setFullScreenDrawerId } from '../../../services/drawers';
import { ReportingCollections } from './ReportingCollections';

const messages = defineMessages({
  expand: {
    defaultMessage: 'View saved prompts & conversation history',
    id: '88It3+',
  },
  startNewConversationCTA: {
    defaultMessage: 'Start a new conversation',
    id: 'Lc72Gm',
  },
  collapse: {
    defaultMessage: 'Collapse this section',
    id: 'Mwp3rZ',
  },
  doraDrawerTitle: {
    defaultMessage: 'DoraAI Reporting',
    id: 'eE3c8F',
  },
});

function ChatViewSidebarCollapsedView({
  onSidebarToggle,
  onBeginNewConversation,
}: {
  onSidebarToggle: () => void;
  onBeginNewConversation: () => void;
}) {
  const { formatMessage } = useIntl();

  return (
    <>
      <Tooltip tooltipText={formatMessage(messages.expand)}>
        <button onClick={onSidebarToggle}>
          <SVGReactComponents.ExpandIcon className="h-6 w-6" />
        </button>
      </Tooltip>
      <Tooltip tooltipText={formatMessage(messages.startNewConversationCTA)}>
        <IconButton
          variation="secondaryEmphasized"
          onClick={onBeginNewConversation}
        >
          <SVGReactComponents.SparklesColoredIcon className="text-primary-6" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export function ChatViewSidebar({
  currentDrawerId,
  isSidebarOpen,
  toggleSidebar,
  closeCurrentDrawer,
  isLimitedReportingExperience,
  openUpgradeBanner,
}: {
  currentDrawerId: string;
  isLimitedReportingExperience: boolean;
  isSidebarOpen: boolean;
  toggleSidebar: (isOpen: boolean) => void;
  openUpgradeBanner: () => void;
  closeCurrentDrawer: () => void;
}) {
  const { formatMessage } = useIntl();

  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);

  const onBeginNewConversation = useCallback(
    function onBeginNewConversation() {
      const drawerId = createDrawer({
        type: 'doraChat',
        title: formatMessage(messages.doraDrawerTitle),
        data: {},
      });

      setFullScreenDrawerId(drawerId); // Open in full-screen mode by default
      closeCurrentDrawer();
    },
    [createDrawer, closeCurrentDrawer, formatMessage]
  );

  return (
    <div
      className={twMerge(
        'flex h-full flex-col items-center justify-between border-r-[1px] border-r-gray-5 bg-gray-2 py-2',
        isSidebarOpen ? 'w-[22rem]' : 'px-2'
      )}
    >
      {isSidebarOpen ? (
        <div className="relative flex h-full w-full flex-col items-center px-2 py-1">
          <button
            className="absolute right-1 p-1"
            onClick={() => toggleSidebar(false)}
          >
            <ChevronDoubleLeftIcon className="h-4 w-4" />
          </button>
          <div className="h-[calc(100%-2.5rem)] w-full">
            <ReportingCollections
              isLoadedInSidebar
              currentDrawerId={currentDrawerId}
              isLimitedReportingExperience={isLimitedReportingExperience}
              openUpgradeBanner={openUpgradeBanner}
            />
          </div>
          <Button
            variation="secondaryEmphasized"
            isFullWidth
            onClick={onBeginNewConversation}
          >
            <SVGReactComponents.SparklesColoredIcon className="text-primary-6" />
            {formatMessage(messages.startNewConversationCTA)}
          </Button>
        </div>
      ) : (
        <ChatViewSidebarCollapsedView
          onSidebarToggle={() => toggleSidebar(true)}
          onBeginNewConversation={onBeginNewConversation}
        />
      )}
    </div>
  );
}
