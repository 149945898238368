import { Button, Carousel, TextStyle } from '@assembly-web/ui';

type SuggestedQuestionsCarouselProps = {
  prompt: string;
  disabled?: boolean;
  suggestedQuestions: string[];
  onSuggestedQuestionClick: (question: string) => void;
};

export function SuggestedQuestionsModule(
  props: SuggestedQuestionsCarouselProps
) {
  const { disabled, onSuggestedQuestionClick, suggestedQuestions, prompt } =
    props;

  return (
    <div>
      <TextStyle variant="sm-regular" subdued className="mb-2">
        {prompt}
      </TextStyle>
      <Carousel slidesToScroll={1} buttonSize="sm" align="start" fullWidth>
        {suggestedQuestions.map((question) => (
          <Button
            key={question}
            onClick={() => onSuggestedQuestionClick(question)}
            variation="secondaryEmphasized"
            disabled={disabled}
          >
            <TextStyle as="span" variant="sm-medium">
              {question}
            </TextStyle>
          </Button>
        ))}
      </Carousel>
    </div>
  );
}
