import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useRef } from 'react';

export function TypingEffectText({ text }: { text: string }) {
  const count = useMotionValue(text.length);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const displayText = useTransform(rounded, (latest) => text.slice(0, latest));
  const isFirstRender = useRef(true);
  const previousText = useRef(text);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      // Set count to text length directly for the first render
      count.set(text.length);
    } else if (previousText.current !== text) {
      // Reset count to 0 before animating to the new text length on updates
      count.set(0);
      const controls = animate(count, text.length, {
        type: 'tween',
        duration: 1,
        ease: 'easeInOut',
      });
      previousText.current = text;
      return () => controls.stop();
    }
  }, [text, count]);

  return <motion.span>{displayText}</motion.span>;
}
