import type { AnnouncementsApiResponse } from '@assembly-web/services';
import type {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getAnnouncementsQuery } from '../queries/getAnnouncementsQuery';

export function useGetAnnouncementQuery(
  options?: UseInfiniteQueryOptions<AnnouncementsApiResponse>
): UseInfiniteQueryResult<AnnouncementsApiResponse> {
  return useInfiniteQuery(getAnnouncementsQuery(options));
}
