import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { timeouts } from '../../../../../shared/dora/constants';
import { DoraSingleMessage } from '../../../../../shared/dora/DoraSingleMessage';
import type { CustomTimePeriodQuestionProps } from '../../../../types';

const messages = defineMessages({
  chooseTimePeriod: {
    defaultMessage: 'Great! Choose a time period below:',
    id: 'yGMofP',
  },
});

export const Message = (props: CustomTimePeriodQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useCustomTimePeriodSetting },
    isError,
    isLoading,
  } = props;

  const { formatMessage } = useIntl();

  const isOrigQuestionSeen =
    useCustomTimePeriodSetting.getState().isQuestionSeen;

  const isEditQuestionSeen =
    useEditSettingsStore.getState().customTimePeriod.isQuestionSeen;

  const isEditing = useEditSettingsStore(
    (store) => store.settingEditing === 'customTimePeriod'
  );

  const isSeen = isEditing ? isEditQuestionSeen : isOrigQuestionSeen;

  const isOrigSet = useCustomTimePeriodSetting((store) => store.isSet);

  const isEditedSet = useEditSettingsStore(
    (store) => store.customTimePeriod.isSet
  );

  const isSet = (isEditing && isEditedSet) || (!isEditing && isOrigSet);

  const markOrigQuestionSeen = useCustomTimePeriodSetting(
    (store) => store.markQuestionSeen
  );

  const markEditQuestionSeen = useEditSettingsStore(
    (store) => store.markQuestionSeen
  );

  const requestOrigInput = useCustomTimePeriodSetting(
    (store) => store.setShouldRequestInput
  );

  const requestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  useEffect(() => {
    if (!isOrigQuestionSeen && !isEditing) {
      markOrigQuestionSeen();
    } else if (!isEditQuestionSeen && isEditing) {
      markEditQuestionSeen('customTimePeriod');
    }
  }, [
    isEditQuestionSeen,
    isEditing,
    isOrigQuestionSeen,
    markEditQuestionSeen,
    markOrigQuestionSeen,
  ]);

  useEffect(() => {
    const requestInput = isEditing
      ? (shouldRequestInput: boolean) =>
          requestEdit('customTimePeriod', shouldRequestInput)
      : requestOrigInput;

    if (!isSet && !isLoading && !isError) {
      setTimeout(() => {
        requestInput(true);
      }, timeouts.showInput);
    }
  }, [isEditing, isError, isLoading, isSet, requestEdit, requestOrigInput]);

  return (
    <DoraSingleMessage shouldAnimate={!isSeen}>
      <TextStyle className="text-gray-9">
        {formatMessage(messages.chooseTimePeriod)}
      </TextStyle>
    </DoraSingleMessage>
  );
};
