import {
  BlockLabel,
  Scale,
  ScaleLabels as ScaleLabelsImpl,
} from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetBlockType,
  useGetScaleLabel,
  useGetScaleRange,
  useSetScaleBlockData,
} from '../../../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../../../services/analytics';
import { useBlockIdContext } from '../../../context/BlockIdContext';
import { useEditorDataContext } from '../../../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../../../hooks/useAnyOccurrenceInProgress';
import { useFormattedBlockName } from '../../../hooks/useFormattedBlockName';

const messages = defineMessages({
  label: {
    defaultMessage: 'Scale labels',
    id: 'cFY9VJ',
  },
  npsLabel: {
    defaultMessage: 'NPS Scale labels',
    id: 'Ik8yoI',
  },
  leadingLabel: {
    defaultMessage: 'Add left label',
    id: 'Oeo2wS',
  },
  middleLabel: {
    defaultMessage: 'Add middle label',
    id: 'Amu3q/',
  },
  trailingLabel: {
    defaultMessage: 'Add right label',
    id: 'OTqFj/',
  },
  notLikelyAtAll: {
    defaultMessage: 'Not likely at all',
    id: 'Pj9dnq',
  },
  veryLikely: {
    defaultMessage: 'Very likely',
    id: 'jCQz0R',
  },
});

type Type = Parameters<typeof useGetScaleLabel>[2];

const alignmentMap = {
  lowLabel: 'start',
  middleLabel: 'center',
  highLabel: 'end',
} satisfies Record<
  Type,
  Parameters<typeof ScaleLabelsImpl.TextField>[0]['alignment']
>;

const npsLabelMap = {
  lowLabel: messages.notLikelyAtAll,
  middleLabel: {} as never,
  highLabel: messages.veryLikely,
} satisfies Record<Type, (typeof messages)[keyof typeof messages]>;

const placeholderMap = {
  lowLabel: messages.leadingLabel,
  middleLabel: messages.middleLabel,
  highLabel: messages.trailingLabel,
} satisfies Record<Type, (typeof messages)[keyof typeof messages]>;

function LabelField({ type }: { type: Type }) {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useGetBlockType(id, blockId);
  const value = useGetScaleLabel(id, blockId, type);
  const set = useSetScaleBlockData(id, blockId);

  const formattedBlockName = useFormattedBlockName();

  const isNPS = blockType === 'NPS';

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  if (isNPS && type === 'middleLabel') {
    return null;
  }

  return (
    <ScaleLabelsImpl.TextField
      alignment={alignmentMap[type]}
      disabled={blockType === 'NPS' || isOccurrenceInProgress}
      placeholder={formatMessage(placeholderMap[type])}
      {...(isNPS
        ? { defaultValue: formatMessage(npsLabelMap[type]) }
        : {
            value,
            onChange(value) {
              trackFlowEditorAction('blockDetailClicked', {
                blockType: formattedBlockName,
                detailType: `${formattedBlockName}${((): string => {
                  return (
                    {
                      highLabel: 'RightLabel',
                      lowLabel: 'LeftLabel',
                      middleLabel: 'MiddleLabel',
                    } satisfies Record<Type, string>
                  )[type];
                })()}`,
              });
              set((state) => {
                state[type] = value;

                return state;
              });
            },
          })}
    />
  );
}

function ScaleLabelsRow() {
  return (
    <ScaleLabelsImpl.Row>
      <LabelField type="lowLabel" />
      <LabelField type="middleLabel" />
      <LabelField type="highLabel" />
    </ScaleLabelsImpl.Row>
  );
}

function ScaleStrip() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useGetBlockType(id, blockId);
  const isNPS = blockType === 'NPS';

  const minRange = useGetScaleRange(id, blockId, 'min');
  const maxRange = useGetScaleRange(id, blockId, 'max');

  return (
    <Scale disabled end={isNPS ? 10 : maxRange} start={isNPS ? 0 : minRange} />
  );
}

function Label() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const blockType = useGetBlockType(id, blockId);

  if (blockType === 'NPS') {
    return <BlockLabel>{formatMessage(messages.npsLabel)}</BlockLabel>;
  }

  return <BlockLabel>{formatMessage(messages.label)}</BlockLabel>;
}

export function ScaleLabels() {
  return (
    <div className="flex flex-col gap-1">
      <Label />
      <ScaleStrip />
      <ScaleLabelsRow />
    </div>
  );
}
