import { Button, TextStyle } from '@assembly-web/ui';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

import { AnimateChat } from './AnimateChat';
import type { AnimateChatProps } from './types';

export const TryAgainMessage = (
  props: {
    onClick: () => void;
  } & AnimateChatProps
) => {
  const { children, onClick, shouldAnimateOnMount } = props;

  return (
    <>
      <AnimateChat shouldAnimateOnMount={shouldAnimateOnMount}>
        <div className="col-start-1 mt-1">
          <ArrowPathIcon className="h-8 w-8 text-primary-6" />
        </div>
      </AnimateChat>
      <AnimateChat shouldAnimateOnMount={shouldAnimateOnMount}>
        <div className="col-start-2 flex self-start">
          <Button
            className="rounded-lg border border-primary-6 bg-gray-1 px-3 py-3"
            onClick={onClick}
            variation="secondaryEmphasized"
          >
            <TextStyle as="span" className="text-gray-9">
              {children}
            </TextStyle>
          </Button>
        </div>
      </AnimateChat>
    </>
  );
};
