import {
  CriteriaRuleType,
  Operator,
  PermissionType,
} from '@assembly-web/services';
import {
  Button,
  ConfirmationModal,
  type ConfirmationModalProps,
  type CriteriaItemProps,
  PeopleWithAccess,
  type PeopleWithAccessProps,
  type PermissionOption,
} from '@assembly-web/ui';
import { type ReactNode, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';

import {
  useGetFlowOwner,
  useGetOwnerAndCollaborators,
} from '../../../../../../stores/useFlowBuilderStore';
import {
  operatorLabelMap,
  useShareSheet,
} from '../../../../hooks/shareModal/useShareSheet';
import { useShareSheetCriteriaRules } from '../../../../hooks/shareModal/useShareSheetCriteriaRules';
import { useShareSheetMemberSearch } from '../../../../hooks/shareModal/useShareSheetMemberSearch';
import { trackSharesheetAction } from '../../../../services/analytics';
import {
  CollaboratorPermission,
  OwnerPermission,
} from '../../../../services/shareCollectionsData';
import { useEditorDataContext } from '../context/EditorDataContext';
import { usePermissions, useSetPermissions } from '../store/useEditorMiscState';

const messages = defineMessages({
  title: {
    defaultMessage: 'Owners & Collaborators',
    id: 'il+9cV',
  },
  confirmCollaboratorToOwnerDescription: {
    defaultMessage:
      'The new owner will be notified, and you will no longer be able to delete this flow. The owner could remove or change your permissions.',
    id: 's9WaZR',
  },
  confirmOwnerToCollaboratorDescription: {
    defaultMessage:
      'You will need to select a new Owner, and you will no longer be able to delete this flow. The owner could remove or change your permissions.',
    id: '601JWa',
  },
  confirmCollaboratorToOwnerTitle: {
    defaultMessage: 'Make <em>{name}</em> the owner?',
    id: 'jfOc5A',
  },
  confirmOwnerToCollaboratorTitle: {
    defaultMessage: 'You are the Owner. Change to Collaborator?',
    id: 'hrMo/d',
  },
  cancelText: {
    defaultMessage: 'No',
    id: 'oUWADl',
  },
  confirmText: {
    defaultMessage: 'Yes',
    id: 'a5msuh',
  },
  emailLabel: {
    defaultMessage: 'Add "{email}" to this flow',
    id: 'aGZ8Dl',
  },
  placeholder: {
    defaultMessage: 'Type someone’s name to give access...',
    id: 'Ghf7cw',
  },
});

export function OwnerAndCollaboratorsShareSheet() {
  const { id } = useEditorDataContext();

  const { formatMessage } = useIntl();

  const initialOwnerAndCollaborators = useGetOwnerAndCollaborators(id);
  const [persistedInitialOwnerAndCollaborators] = useState(
    initialOwnerAndCollaborators
  );
  const [, setHasRulesChanged] = useState<boolean>(false);
  const [confirmPermissionChange, setConfirmPermissionChange] = useState<{
    id: string;
    currentRule: CriteriaItemProps;
    updatedPermission: PermissionOption | undefined;
  } | null>(null);

  const persistedPermissions = usePermissions(id);
  const persistPermissionOnChange = useSetPermissions(id);

  const [rules, setRules] = useImmer<CriteriaItemProps[]>(
    () => persistedPermissions ?? []
  );

  const owner = useGetFlowOwner(id);

  useEffect(() => {
    persistPermissionOnChange(rules);
  }, [persistPermissionOnChange, rules]);

  const {
    isLoadingMembers,
    hasNextPage,
    isFetchingNextPage,
    searchTerm,
    members,
    onMemberSearch,
    onLoadMore,
  } = useShareSheetMemberSearch({
    enabled: true,
  });

  const {
    customRuleOptions,
    onRulesPermissionChange,
    onRulesSelectionChange,
    handleOnEmailInvite,
  } = useShareSheet({
    enabled: true,
    rules,
    setRules,
    type: 'ownerAndCollaborators',
    onEmailInvite() {
      trackSharesheetAction({
        actionName: 'inviteMemberClicked',
        props: {
          criteriaSelected: `${CriteriaRuleType.Email}${
            operatorLabelMap[Operator.Is]
          }`,
          roleSelected: PermissionType.Viewer,
          ruleType: 'simple',
          isV3sharesheet: true,
          sharesheetType: 'flowEditor',
        },
      });
    },
    onRemoveRule({ currentRule }) {
      trackSharesheetAction({
        actionName: 'removeMemberClicked',
        props: {
          criteriaSelected: `${currentRule.metaData?.field}${
            currentRule.metaData?.operator &&
            operatorLabelMap[currentRule.metaData.operator]
          }`,
          roleSelected: currentRule.permission?.id,
          ruleType: 'simple',
          isV3sharesheet: true,
          sharesheetType: 'flowEditor',
        },
      });
    },
    customHandlePermissionChange({
      currentRule,
      ruleToUpdate,
      updatedPermission,
    }) {
      if (ruleToUpdate.permission?.id === updatedPermission?.id) {
        return true;
      }
      setConfirmPermissionChange({
        id: ruleToUpdate.id,
        currentRule,
        updatedPermission,
      });
      return true;
    },
  });

  useShareSheetCriteriaRules({
    rules,
    setRules,
    type: 'ownerAndCollaborators',
    initialRules: persistedInitialOwnerAndCollaborators,
    setHasRulesChanged,
    transformedInitialRules: persistedPermissions,
    owner: {
      ownerId: owner?.memberID,
      ownerState: owner?.state,
    },
  });

  const onCloseConfirmation = () => {
    setConfirmPermissionChange(null);
  };
  const onConfirmation = () => {
    if (!confirmPermissionChange) {
      return;
    }
    const { currentRule, id, updatedPermission } = confirmPermissionChange;
    setRules((draftRules) => {
      if (currentRule.permission?.id === PermissionType.Collaborator) {
        draftRules.forEach((rule) => {
          if (rule.id !== id) {
            rule.permission = CollaboratorPermission;
          } else {
            rule.permission =
              updatedPermission?.id === PermissionType.Owner
                ? OwnerPermission
                : updatedPermission;
          }
        });
      }
      if (currentRule.permission?.id === PermissionType.Owner) {
        draftRules.forEach((rule) => {
          if (rule.id === id) {
            rule.permission = updatedPermission;
          }
        });
      }
    });
    onCloseConfirmation();
  };

  const props: PeopleWithAccessProps = {
    view: 'peopleWithAccess',
    customRuleSelectorProps: {
      customRuleOptions,
      onCustomRulesAction: () => {},
      onCustomRuleSelect: () => {},
      ruleOptions: customRuleOptions,
      selectedCustomRule: null,
    },
    rulesListProps: {
      hasNextPageForRules: false,
      onRulesPermissionChange,
      rules,
    },
    searchableRulesDropdownProps: {
      canInviteEmail: false,
      handleOnEmailInvite,
      customRules: [],
      hasNextPageForMembers: hasNextPage ?? false,
      hasUserInvitePermission: false,
      members,
      onLoadMore,
      onMemberSearch,
      onRulesSelectionChange,
      searchTerm,
      isFetchingMoreMembers: isFetchingNextPage,
      isLoadingMembers,
      addEmailLabel: messages.emailLabel,
      placeholder: messages.placeholder,
    },
    peopleWithAccessHeaderProps: {
      title: formatMessage(messages.title),
      onPreviewButtonClick() {},
      hidePreviewButton: true,
      className: 'mb-4 pt-0',
    },
    className: 'px-0 flex-1 overflow-hidden',
    fullHeight: true,
  };

  const confirmationProps: ConfirmationModalProps = {
    open: Boolean(confirmPermissionChange),
    onClose: onCloseConfirmation,
    description: (() => {
      if (!confirmPermissionChange) {
        return null;
      }
      if (
        confirmPermissionChange.updatedPermission?.id === PermissionType.Owner
      ) {
        return formatMessage(messages.confirmCollaboratorToOwnerDescription);
      }
      return formatMessage(messages.confirmOwnerToCollaboratorDescription);
    })(),
    leftButton: (
      <Button
        variation="secondaryEmphasized"
        size="large"
        onClick={onCloseConfirmation}
        className="flex-1"
      >
        {formatMessage(messages.cancelText)}
      </Button>
    ),
    rightButton: (
      <Button
        variation="primary"
        size="large"
        onClick={onConfirmation}
        className="flex-1"
      >
        {formatMessage(messages.confirmText)}
      </Button>
    ),
    title: (() => {
      if (!confirmPermissionChange) {
        return null;
      }
      if (
        confirmPermissionChange.updatedPermission?.id === PermissionType.Owner
      ) {
        return formatMessage(messages.confirmCollaboratorToOwnerTitle, {
          name: confirmPermissionChange.currentRule.title,
          em: (text: ReactNode) => (
            <em className="not-italic text-primary-7">{text}</em>
          ),
        });
      }
      return formatMessage(messages.confirmOwnerToCollaboratorTitle);
    })(),
  };

  return (
    <>
      <PeopleWithAccess {...props} />
      <ConfirmationModal {...confirmationProps} />
    </>
  );
}
