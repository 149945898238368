import type { AssemblyInfoAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  getAPIErrorCode,
  SplitNames,
  unauthenticatedAssemblyAPI,
  useFeatureSplit,
  userAuthStore,
} from '@assembly-web/services';
import {
  AppLink,
  Button,
  RouterForm,
  TextField,
  validateForm,
} from '@assembly-web/ui';
import { type ReactNode, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useActionData,
  useNavigate,
  useNavigation,
  useRouteLoaderData,
} from 'react-router-dom';
import { z } from 'zod';

import {
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../../../services/analytics';
import type {
  ReactRouterActionResponse,
  ReactRouterLoaderResponse,
} from '../../../../../types/libs';
import { OnboardingContainer } from '../../../components/OnboardingContainer';
import { getSSODetailsFromError } from '../../../services/sso';
import { onBoardingState } from '../../../stores/onboardingStore';

const joinFormText = defineMessages({
  title: {
    defaultMessage: 'Welcome to {name}!',
    id: 'DBvi+R',
  },
  description: {
    defaultMessage:
      'Join your team by entering your work email address to confirm your account.',
    id: 'jyaXKN',
  },
  cta: {
    defaultMessage: 'Sign up',
    id: '8HJxXG',
  },
  footer: {
    defaultMessage:
      'Already using Assembly? <cta>Log into an existing workspace</cta>',
    id: 'gWiQ1v',
  },
  inputPlaceholder: {
    defaultMessage: 'Enter your email address',
    id: 'NgCT/u',
  },
});

const errorMessages = defineMessages({
  forbidden: {
    defaultMessage:
      'If you’d like to join, you’ll have to ask permission from your Assembly’s admin',
    id: 'wEHzuz',
  },
});

export function JoinWorkspaceSlugRoute() {
  const { formatMessage } = useIntl();

  const loaderData = useRouteLoaderData(
    'join-workspace-root'
  ) as ReactRouterLoaderResponse<typeof joinWorkspaceSlugLoader>;

  const navigation = useNavigation();

  const actionData = useActionData() as ReactRouterActionResponse<
    typeof joinWorkspaceSlugAction
  >;

  const isPageLoading = navigation.state === 'loading';
  const isSubmissionInProgress = navigation.state === 'submitting';

  const emailError = Object.keys(errorMessages).includes(
    actionData?.error ?? ''
  )
    ? formatMessage(
        errorMessages[actionData?.error as keyof typeof errorMessages]
      )
    : actionData?.error;

  const { resetBrandingToDefaults } = onBoardingState.getState();

  const { isTreatmentActive: isJoinUsingMagicLinkEnabled } = useFeatureSplit(
    SplitNames.EnableAssemblyMagicLink
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!isJoinUsingMagicLinkEnabled) {
      navigate('/login', { replace: true });
    }
  }, [isJoinUsingMagicLinkEnabled, navigate]);

  return (
    <OnboardingContainer
      title={formatMessage(joinFormText.title, { name: loaderData.name })}
      showLogo
      description={formatMessage(joinFormText.description)}
      footer={formatMessage(joinFormText.footer, {
        cta: (text: ReactNode[]) => (
          <AppLink to="/login" onClick={() => resetBrandingToDefaults()}>
            {text}
          </AppLink>
        ),
      })}
    >
      <RouterForm>
        <TextField
          name="email"
          type="email"
          label={formatMessage(joinFormText.inputPlaceholder)}
          hideLabel
          placeholder={formatMessage(joinFormText.inputPlaceholder)}
          autoComplete="email"
          spellCheck={false}
          invalidTextTestId="email-error"
          isInvalid={Boolean(emailError)}
          invalidText={emailError ?? ''}
          required
        />
        <input
          value={loaderData.assemblyId}
          name="assemblyId"
          readOnly
          hidden
        />
        <Button
          type="submit"
          isFullWidth
          isLoading={isSubmissionInProgress}
          disabled={isSubmissionInProgress || isPageLoading}
        >
          {formatMessage(joinFormText.cta)}
        </Button>
      </RouterForm>
    </OnboardingContainer>
  );
}

export async function joinWorkspaceSlugLoader({
  params,
  request: { signal },
}: LoaderFunctionArgs) {
  const shortCode = params.workspaceSlug?.split('-').pop();

  if (!shortCode) {
    return redirect('/login');
  }

  try {
    const { data } = await assemblyAPI.get<AssemblyInfoAPIResponse>(
      APIEndpoints.assemblyInfoFromSlugShortCode(shortCode),
      { signal }
    );
    const { updateDetails } = onBoardingState.getState();

    updateDetails(data.name, data.logo);
    return data;
  } catch (error) {
    const errorCode = getAPIErrorCode(error);
    if (['assembly_not_active', 'assembly_not_found'].includes(errorCode)) {
      return redirect(`/create-account?error=${errorCode}`);
    }

    throw error;
  }
}

export async function joinWorkspaceSlugAction({ request }: ActionFunctionArgs) {
  const invalidEmailCode = 'invalid_email';
  const formSchema = z.object({
    email: z.string().trim().email(invalidEmailCode),
    assemblyId: z.string().trim(),
  });

  const formData = await request.formData();
  const { pathname } = new URL(request.url);
  try {
    const { email, assemblyId } = validateForm(formSchema, formData);

    await unauthenticatedAssemblyAPI.post(
      APIEndpoints.registerMagicLinkUser,
      { email, assemblyId },
      { signal: request.signal }
    );

    trackRegistrationAction('emailEntered', {
      email,
    });

    return redirect(
      `${pathname}/check-email?email=${encodeURIComponent(email)}`.replace(
        '//',
        '/'
      )
    );
  } catch (error) {
    trackRegistrationError('emailEntered', {
      email: formData.get('email')?.toString(),
    });

    const errorCode = getAPIErrorCode(error);

    const enforcedLoginDetails = getSSODetailsFromError(error);

    if (enforcedLoginDetails) {
      return redirect(
        `${pathname}/validate/${enforcedLoginDetails.provider}`.replace(
          '//',
          '/'
        )
      );
    }

    if (errorCode === 'user_already_exists') {
      let redirectURL = '/login';

      if (userAuthStore.getState().msTeamsContext) {
        redirectURL = '/ms-teams/login';
      }
      return redirect(`${redirectURL}?error=user_already_exists`);
    }

    return { error: errorCode };
  }
}
