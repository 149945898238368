import { usePusherChannel, usePusherEvents } from '@assembly-web/pusher';
import { useUserDetails } from '@assembly-web/services';
import { useState } from 'react';

type NewReplyAddedEventPayload = {
  postId: string;
  flowId: string;
  responseId: string;
};

type ReplyEvent = {
  postId?: string;
  flowId?: string;
  responseId?: string;
  commentId: string;
  boost?: string[];
  fromMemberId?: string;
};

export function useNewReplyAddedEvent(payload: NewReplyAddedEventPayload) {
  const [count, setCount] = useState(0);

  const { data: userDetails } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  const reset = () => {
    setCount(0);
  };

  usePusherEvents<ReplyEvent>(assembly, ['NEW_COMMENT'], async (args) => {
    try {
      if (
        args.data?.fromMemberId &&
        args.data.fromMemberId !== userDetails?.member.memberId
      ) {
        if (payload.postId && args.data.postId === payload.postId) {
          setCount((prev) => prev + 1);
        }

        if (
          payload.flowId &&
          payload.responseId &&
          args.data.flowId === payload.flowId &&
          args.data.responseId === payload.responseId
        ) {
          setCount((prev) => prev + 1);
        }
      }
    } catch (error) {
      console.error('Error in useNewReplyAddedEvent', error);
    }
  });

  return { count, reset };
}
