import type { DateRange } from '@assembly-web/services';

export function getDefaultFlowOption(flowsSearchValue: string) {
  const giveRecognitionText = 'Give recognition'.toLowerCase();
  return !flowsSearchValue ||
    giveRecognitionText.includes(flowsSearchValue.toLowerCase())
    ? [
        {
          id: 'recognition',
          value: 'Give Recognition',
          emoticon: '1F389',
        },
      ]
    : [];
}

export function getIncludedEntityIds({
  selectedItems,
  currentUserId,
}: {
  selectedItems: string[];
  currentUserId: string;
}) {
  const isOwnerSelected = selectedItems.some((id) => id === currentUserId);
  const selectedItemsWithoutOwner = selectedItems.filter(
    (item) => item !== currentUserId
  );

  return isOwnerSelected
    ? [...selectedItemsWithoutOwner, currentUserId]
    : [currentUserId, ...selectedItems];
}

export function generateDateRange(
  value: string,
  startValue?: string,
  endValue?: string
) {
  const today = new Date();
  const startOfPeriod = new Date();
  const endOfPeriod = new Date(today);

  switch (value) {
    case 'today':
      startOfPeriod.setHours(0, 0, 0, 0);
      endOfPeriod.setHours(23, 59, 59, 999);
      break;
    case 'yesterday':
      startOfPeriod.setDate(today.getDate() - 1);
      startOfPeriod.setHours(0, 0, 0, 0);
      endOfPeriod.setDate(today.getDate() - 1);
      endOfPeriod.setHours(23, 59, 59, 999);
      break;
    case 'last7Days':
      startOfPeriod.setDate(today.getDate() - 7);
      startOfPeriod.setHours(0, 0, 0, 0);
      endOfPeriod.setHours(23, 59, 59, 999);
      break;
    case 'last30Days':
      startOfPeriod.setDate(today.getDate() - 30);
      startOfPeriod.setHours(0, 0, 0, 0);
      endOfPeriod.setHours(23, 59, 59, 999);
      break;
    case 'custom': {
      const parsedDate: DateRange = {};
      if (startValue) {
        const parsedStartDate = new Date(startValue);
        parsedStartDate.setHours(0, 1, 0, 0); // sets hours, minutes, seconds, and milliseconds
        parsedDate.gte = parsedStartDate.toISOString();
      }
      if (endValue) {
        const parsedEndDate = new Date(endValue);
        parsedEndDate.setHours(23, 59, 0, 0); // sets hours, minutes, seconds, and milliseconds
        parsedDate.lte = parsedEndDate.toISOString();
      }
      return parsedDate;
    }
  }

  return {
    gte: startOfPeriod.toISOString(),
    lte: endOfPeriod.toISOString(),
  };
}

export function getSecondaryFiltersQueryCacheKey(
  secondaryFilters: Record<string, string[] | undefined>
) {
  return Object.fromEntries(
    Object.entries(secondaryFilters)
      .filter(([, value]) => value && value.length > 0)
      .map(([key, value]) =>
        Array.isArray(value) ? [key, value.sort()] : [key, value]
      )
  );
}
