import type { BlocksGroupQuestionProps } from '../../../../types';
import { Message } from './Message';

export const BlocksGroupQuestion = (props: BlocksGroupQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useIndividualRespondentsSetting },
  } = props;

  const isRespondentsSet = useIndividualRespondentsSetting(
    (store) => store.isSet
  );

  const isEditingPrevSetting = useEditSettingsStore((store) =>
    store.isEditingRespondents()
  );

  return isRespondentsSet && !isEditingPrevSetting && <Message {...props} />;
};
