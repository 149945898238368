import {
  type ChallengeDetails,
  type ChallengeInteractionSettings,
  type ChallengeState,
  initProofClaimData,
  type ProofModalClaimData,
} from '@assembly-web/services';
import { lazy, type ReactNode, Suspense } from 'react';
import { createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ChallengeDefinition } from '../components/modals/ChallengeDefinition';
import { trackCardAction } from '../services/analytics';

const LaunchChallengeModal = lazy(() =>
  import('../components/modals/LaunchChallengeModal').then((module) => ({
    default: module.LaunchChallengeModal,
  }))
);
const EndChallengeModal = lazy(() =>
  import('../components/modals/EndChallengeModal').then((module) => ({
    default: module.EndChallengeModal,
  }))
);

const RemindParticipantChallengeModal = lazy(() =>
  import('../components/modals/RemindParticipantChallengeModal').then(
    (module) => ({
      default: module.RemindParticipantChallengeModal,
    })
  )
);

const ArchiveChallengeModal = lazy(() =>
  import('../components/modals/ArchiveChallengeModal').then((module) => ({
    default: module.ArchiveChallengeModal,
  }))
);
const UnarchiveChallengeModal = lazy(() =>
  import('../components/modals/UnArchiveChallengeModal').then((module) => ({
    default: module.UnArchiveChallengeModal,
  }))
);
const SubmitChallengeModal = lazy(() =>
  import('../components/modals/ChallengeSubmissionModal').then((module) => ({
    default: module.ChallengeSubmissionModal,
  }))
);
const SubmitChallengeSuccessModal = lazy(() =>
  import('../components/modals/SubmitChallengeSuccessModal').then((module) => ({
    default: module.SubmitChallengeSuccessModal,
  }))
);
const ClaimApprovalConfirmationModal = lazy(() =>
  import('../components/modals/ClaimApprovalConfirmationModal').then(
    (module) => ({
      default: module.ClaimApprovalConfirmationModal,
    })
  )
);
const ClaimDenialConfirmationModal = lazy(() =>
  import('../components/modals/ClaimDenialConfirmationModal').then(
    (module) => ({
      default: module.ClaimDenialConfirmationModal,
    })
  )
);

const ViewProofModal = lazy(() =>
  import('../components/modals/ViewProofModal').then((module) => ({
    default: module.ViewProofModal,
  }))
);

const ChallengeInsightsModal = lazy(() =>
  import('../components/modals/ChallengeInsights').then((module) => ({
    default: module.ChallengeInsights,
  }))
);

const ChallengeInteractionSettingsModal = lazy(() =>
  import('../components/modals/ChallengeInteractionSettingsModal').then(
    (module) => ({
      default: module.ChallengeInteractionSettingsModal,
    })
  )
);

const Noop = () => null;

export type ChallengeSummary = {
  id: string;
  title: string;
  state?: ChallengeState;
};

export type ChallengeArchiveProps = {
  id: string;
  title: string;
  points: number;
};

type OpenChallengeArchiveModalProps = ChallengeArchiveProps & {
  type: typeof MODAL_TYPES.ARCHIVE | typeof MODAL_TYPES.UNARCHIVE;
};

type ChallengeReminderType = ChallengeSummary & {
  reminderCount: number;
};

export type ClaimActionTriggerSource =
  | 'challengeInsightsModal'
  | 'challengesPage';

type ClaimConformationModalProps = {
  claimId: string;
  source: ClaimActionTriggerSource;
};

type OpenClaimConformationModalProps = {
  type: ClaimModalTypes;
} & ClaimConformationModalProps;

type ChallengeModalsContextType = {
  challengeModals: JSX.Element;
  openLaunchChallengeModal: ({ id, title }: ChallengeSummary) => void;
  openEndChallengeModal: ({ id, title }: ChallengeSummary) => void;
  openChallengeInsightsModal: ({ id, title, state }: ChallengeSummary) => void;
  openArchiveChallengeModal: (challenge: ChallengeArchiveProps) => void;
  openUnarchiveChallengeModal: (challenge: ChallengeArchiveProps) => void;
  openRemindChallengeParticipantModal: ({
    id,
    title,
    reminderCount,
  }: ChallengeReminderType) => void;
  openSubmitChallengeModal: (challenge: ChallengeDetails) => void;
  openSubmitChallengeSuccessModal: (challenge: ChallengeDetails) => void;
  openEditChallengeModal: (challengeId: string) => void;
  openViewProofModal: (claim: ProofModalClaimData) => void;
  openClaimDenialConfirmationModal: ({
    claimId,
    source,
  }: ClaimConformationModalProps) => void;
  openClaimApprovalConfirmationModal: ({
    claimId,
    source,
  }: ClaimConformationModalProps) => void;
  openDuplicateChallengeModal: (challenge: ChallengeArchiveProps) => void;
  openEditChallengeInteractionSettingsModal: (
    challenge: ChallengeInteractionSettings
  ) => void;
  closeModals: () => void;
  closeViewProofModal: () => void;
};

const MODAL_TYPES = {
  LAUNCH: 'LAUNCH',
  END: 'END',
  INSIGHTS: 'INSIGHTS',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  EDIT: 'EDIT',
  SUBMIT_CLAIM: 'SUBMIT_CLAIM',
  SUBMIT_CLAIM_SUCCESS: 'SUBMIT_CLAIM_SUCCESS',
  DUPLICATE: 'DUPLICATE',
  INTERACTION_SETTINGS: 'INTERACTION_SETTINGS',
} as const;

const CLAIM_MODAL_TYPE = {
  APPROVE: 'APPROVE',
  DENY: 'DENY',
} as const;

const SUB_MODAL_TYPES = {
  VIEW_PROOF: 'VIEW_PROOF',
  REMIND_ALL: 'REMIND_ALL',
} as const;

type ModalType = keyof typeof MODAL_TYPES;

type ClaimModalTypes = keyof typeof CLAIM_MODAL_TYPE;

type SubModalTypes = keyof typeof SUB_MODAL_TYPES;

export const ChallengeModalsContext = createContext<ChallengeModalsContextType>(
  {
    challengeModals: <Noop />,
    openLaunchChallengeModal: () => {},
    openChallengeInsightsModal: () => {},
    openEndChallengeModal: () => {},
    openArchiveChallengeModal: () => {},
    openUnarchiveChallengeModal: () => {},
    openSubmitChallengeModal: () => {},
    openSubmitChallengeSuccessModal: () => {},
    openEditChallengeModal: () => {},
    openViewProofModal: () => {},
    openClaimDenialConfirmationModal: () => {},
    openClaimApprovalConfirmationModal: () => {},
    openDuplicateChallengeModal: () => {},
    closeModals: () => {},
    openRemindChallengeParticipantModal: () => {},
    openEditChallengeInteractionSettingsModal: () => {},
    closeViewProofModal: () => {},
  }
);

export const ChallengeModalsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [selectedChallengeSummary, setSelectedChallengeSummary] =
    useState<ChallengeSummary>({
      id: '',
      title: '',
    });
  const [selectedArchiveChallenge, setSelectedArchiveChallenge] =
    useState<ChallengeArchiveProps | null>(null);
  const [selectedInteractionSettings, setSelectedInteractionSettings] =
    useState<ChallengeInteractionSettings | null>(null);
  const [reminderAllData, setReminderAllData] = useState<ChallengeReminderType>(
    {
      id: '',
      title: '',
      reminderCount: 0,
    }
  );
  const [selectedChallenge, setSelectedChallenge] =
    useState<ChallengeDetails | null>(null);
  const [selectedChallengeId, setSelectedChallengeId] = useState('');
  const [selectedClaimData, setSelectedClaimData] =
    useState<ProofModalClaimData>(initProofClaimData);
  const [selectedClaimId, setSelectedClaimId] = useState('');

  const [selectedModalType, setSelectedModalType] = useState<ModalType | null>(
    null
  );
  const [claimModalType, setClaimModalType] = useState<ClaimModalTypes | null>(
    null
  );

  const [subModalTypes, setSubModalTypes] = useState<SubModalTypes | null>(
    null
  );

  const [source, setSource] = useState<ClaimActionTriggerSource>(
    'challengeInsightsModal'
  );

  const openLaunchChallengeModal = ({ id, title }: ChallengeSummary) => {
    trackCardAction('launchChallengeClicked', {
      challengeId: id,
      challengeName: title,
    });

    setSelectedChallengeSummary({ id, title });
    setSelectedModalType(MODAL_TYPES.LAUNCH);
  };

  const openEndChallengeModal = ({ id, title }: ChallengeSummary) => {
    trackCardAction('endChallengeClicked', {
      challengeId: id,
      challengeName: title,
    });

    setSelectedChallengeSummary({ id, title });
    setSelectedModalType(MODAL_TYPES.END);
  };

  const openChallengeInsightsModal = ({
    id,
    title,
    state,
  }: ChallengeSummary) => {
    setSelectedChallengeSummary({ id, title, state });
    setSelectedModalType(MODAL_TYPES.INSIGHTS);
    trackCardAction('viewChallengeInsightsClicked', {
      challengeId: id,
      challengeName: title,
    });
  };

  const openArchiveChallengeModal = ({
    id,
    title,
    points,
    type,
  }: OpenChallengeArchiveModalProps) => {
    trackCardAction(
      type === MODAL_TYPES.ARCHIVE
        ? 'archiveChallengeClicked'
        : 'unarchiveChallengeClicked',
      {
        challengeId: id,
        challengeTitle: title,
        challengePoints: points,
      }
    );

    setSelectedArchiveChallenge({
      id,
      title,
      points,
    });
    setSelectedModalType(type);
  };

  const openSubmitChallengeModal = (challenge: ChallengeDetails) => {
    setSelectedChallenge(challenge);
    setSelectedModalType(MODAL_TYPES.SUBMIT_CLAIM);
  };

  const openSubmitChallengeSuccessModal = (challenge: ChallengeDetails) => {
    setSelectedChallenge(challenge);
    setSelectedModalType(MODAL_TYPES.SUBMIT_CLAIM_SUCCESS);
  };

  const openEditChallengeModal = (challengeId: string) => {
    trackCardAction('editChallengeClicked', {
      challengeId,
    });

    setSelectedChallengeId(challengeId);
    setSelectedModalType(MODAL_TYPES.EDIT);
  };

  const openViewProofModal = (claim: ProofModalClaimData) => {
    setSelectedClaimData(claim);
    setSubModalTypes(SUB_MODAL_TYPES.VIEW_PROOF);
  };

  const openClaimConfirmationModal = ({
    claimId,
    source,
    type,
  }: OpenClaimConformationModalProps) => {
    setSelectedClaimId(claimId);
    setClaimModalType(type);
    setSource(source);
  };

  const openRemindChallengeParticipantModal = ({
    id,
    title,
    reminderCount,
  }: ChallengeReminderType) => {
    setReminderAllData({ id, title, reminderCount });
    setSubModalTypes(SUB_MODAL_TYPES.REMIND_ALL);
  };

  const openDuplicateChallengeModal = (challenge: ChallengeArchiveProps) => {
    trackCardAction('duplicateChallengeClicked', {
      challengeId: challenge.id,
      challengeTitle: challenge.title,
      challengePoints: challenge.points,
    });
    setSelectedChallengeId(challenge.id);
    setSelectedArchiveChallenge(challenge);
    setSelectedModalType(MODAL_TYPES.DUPLICATE);
  };

  const openEditChallengeInteractionSettingsModal = ({
    id,
    title,
    hideReactions,
    hideReplies,
  }: ChallengeInteractionSettings) => {
    setSelectedChallengeId(id);
    setSelectedInteractionSettings({
      id,
      title,
      hideReactions,
      hideReplies,
    });
    setSelectedModalType(MODAL_TYPES.INTERACTION_SETTINGS);
  };

  const closeModals = () => {
    setSelectedModalType(null);
    setClaimModalType(null);
    setSubModalTypes(null);
    setTimeout(() => setSelectedChallenge(null), 300); // delay for modal animation
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const challengeModals = (
    <Suspense fallback={null}>
      <LaunchChallengeModal
        onEditChallengeClick={() => {
          openEditChallengeModal(selectedChallengeSummary.id);
        }}
        name={selectedChallengeSummary.title}
        open={selectedModalType === MODAL_TYPES.LAUNCH}
        onClose={closeModals}
        challengeId={selectedChallengeSummary.id}
      />

      <EndChallengeModal
        name={selectedChallengeSummary.title}
        open={selectedModalType === MODAL_TYPES.END}
        onClose={closeModals}
        challengeId={selectedChallengeSummary.id}
      />

      <RemindParticipantChallengeModal
        key={reminderAllData.id + 'remindAll'}
        name={reminderAllData.title}
        open={
          Boolean(subModalTypes === SUB_MODAL_TYPES.REMIND_ALL) &&
          Boolean(reminderAllData.id)
        }
        onClose={() => setSubModalTypes(null)}
        challengeId={reminderAllData.id}
        reminderCount={reminderAllData.reminderCount}
      />

      <ArchiveChallengeModal
        name={selectedArchiveChallenge?.title ?? ''}
        open={selectedModalType === MODAL_TYPES.ARCHIVE}
        onClose={closeModals}
        challengeId={selectedArchiveChallenge?.id ?? ''}
        rewardValue={selectedArchiveChallenge?.points ?? 0}
      />

      <UnarchiveChallengeModal
        name={selectedArchiveChallenge?.title ?? ''}
        open={selectedModalType === MODAL_TYPES.UNARCHIVE}
        onClose={closeModals}
        challengeId={selectedArchiveChallenge?.id ?? ''}
        rewardValue={selectedArchiveChallenge?.points ?? 0}
      />

      {selectedChallenge ? (
        <>
          <SubmitChallengeModal
            title={selectedChallenge.title}
            open={selectedModalType === MODAL_TYPES.SUBMIT_CLAIM}
            onClose={closeModals}
            challengeDetails={selectedChallenge}
            onSuccess={() => {
              setSelectedModalType(MODAL_TYPES.SUBMIT_CLAIM_SUCCESS);
            }}
          />
          <SubmitChallengeSuccessModal
            open={selectedModalType === MODAL_TYPES.SUBMIT_CLAIM_SUCCESS}
            onClose={closeModals}
            challengeDetails={selectedChallenge}
          />
        </>
      ) : null}

      <ChallengeDefinition
        key={selectedChallengeId + selectedModalType}
        isDuplicate={selectedModalType === MODAL_TYPES.DUPLICATE}
        isOpen={
          selectedModalType === MODAL_TYPES.EDIT ||
          selectedModalType === MODAL_TYPES.DUPLICATE
        }
        onClose={closeModals}
        challengeId={selectedChallengeId}
      />

      <ChallengeInsightsModal
        key={selectedChallengeSummary.id + 'insights' + selectedModalType}
        isOpen={selectedModalType === MODAL_TYPES.INSIGHTS}
        onClose={closeModals}
        challengeId={selectedChallengeSummary.id}
        challengeTitle={selectedChallengeSummary.title}
        challengeState={selectedChallengeSummary.state ?? 'ACTIVE'}
      />

      {selectedInteractionSettings !== null && (
        <ChallengeInteractionSettingsModal
          key={selectedChallengeId + 'interactionSettings' + selectedModalType}
          isOpen={
            Boolean(selectedChallengeId) &&
            selectedModalType === MODAL_TYPES.INTERACTION_SETTINGS
          }
          onClose={closeModals}
          challengeInteractionSettings={selectedInteractionSettings}
        />
      )}

      <ViewProofModal
        open={subModalTypes === SUB_MODAL_TYPES.VIEW_PROOF}
        onClose={() => {
          setSubModalTypes(null);
          searchParams.delete('claimProofId');
          setSearchParams(searchParams);
        }}
        claim={selectedClaimData}
      />

      <ClaimApprovalConfirmationModal
        source={source}
        open={
          claimModalType === CLAIM_MODAL_TYPE.APPROVE &&
          Boolean(selectedClaimId)
        }
        onClose={() => {
          setClaimModalType(null);
          setSubModalTypes(null);
        }}
        onCancel={() => {
          setClaimModalType(null);
        }}
        claimId={selectedClaimId}
      />

      <ClaimDenialConfirmationModal
        source={source}
        open={
          claimModalType === CLAIM_MODAL_TYPE.DENY && Boolean(selectedClaimId)
        }
        onClose={() => {
          setClaimModalType(null);
          setSubModalTypes(null);
        }}
        onCancel={() => {
          setClaimModalType(null);
        }}
        claimId={selectedClaimId}
      />
    </Suspense>
  );

  return (
    <ChallengeModalsContext.Provider
      value={{
        challengeModals,
        openLaunchChallengeModal,
        openEditChallengeInteractionSettingsModal,
        openEndChallengeModal,
        openChallengeInsightsModal,
        openRemindChallengeParticipantModal,
        openArchiveChallengeModal: (props) => {
          openArchiveChallengeModal({
            ...props,
            type: MODAL_TYPES.ARCHIVE,
          });
        },
        openUnarchiveChallengeModal: (props) => {
          openArchiveChallengeModal({
            ...props,
            type: MODAL_TYPES.UNARCHIVE,
          });
        },
        openSubmitChallengeModal,
        openSubmitChallengeSuccessModal,
        openEditChallengeModal,
        openViewProofModal,
        openClaimApprovalConfirmationModal: (props) =>
          openClaimConfirmationModal({
            ...props,
            type: CLAIM_MODAL_TYPE.APPROVE,
          }),
        openClaimDenialConfirmationModal: (props) =>
          openClaimConfirmationModal({
            ...props,
            type: CLAIM_MODAL_TYPE.DENY,
          }),
        openDuplicateChallengeModal,
        closeModals,
        closeViewProofModal: () => setSubModalTypes(null),
      }}
    >
      {children}
    </ChallengeModalsContext.Provider>
  );
};
