import type { FlowBuilderState } from '@assembly-web/services';
import {
  getBaseEmojiFromIcon,
  getContent,
  getTrigger,
  getVisibility,
  mapCriteriaResponseToBlockData,
} from '@assembly-web/services';

import { useGetFlowBuilderDetailsById } from '../../../../hooks/flows/useGetFlowBuilderDetailsById';
import { useCurrentUserDetail } from '../../../../hooks/useCurrentUserDetail';
import type { FlowCreationDrawer as FlowCreationEditorProps } from '../../types';
import { formatUserToFlowCollaborator } from '../utils/user';

export const useFetchFlow = (
  flowId: string,
  type: FlowCreationEditorProps['data']['type'],
  enabled = true
) => {
  const owner = useCurrentUserDetail();

  return useGetFlowBuilderDetailsById(flowId, {
    enabled: enabled && (type === 'edit' || type === 'duplicate'),
    select(data): FlowBuilderState {
      return {
        currentSchedule: data.schedule,
        description: data.description,
        flowId: data.flowId,
        flowName: data.name,
        emoji: getBaseEmojiFromIcon(data.icon.value || ''),
        collaborators: type === 'edit' ? (data.collaborators ?? []) : [],
        inEditMode: type === 'edit',
        templateId: data.templateId,
        isFlowDataChangeConfirmed: false,
        showDataChangeConfirm: false,
        owner:
          type === 'edit'
            ? [formatUserToFlowCollaborator(data.owner)]
            : [formatUserToFlowCollaborator(owner)],
        templateName: '',
        blockData: {
          PARTICIPANTS: {
            errors: null,
            participantsCriteria: data.participation
              ? mapCriteriaResponseToBlockData(data.participation, true)
              : undefined,
          },
          ACTION: {
            errors: null,
            actionType: 'SEND_FORM',
          },
          CONTENT: getContent(data.action ?? {}),
          VISIBILITY: getVisibility(data),
          TRIGGER: getTrigger(data),
        },
        canValidate: false,
        errors: null,
        newUsersToInvite: [],
      };
    },
  });
};
