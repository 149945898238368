import { EditorLoader, TextField } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import {
  useGetFlowDescription,
  useSetFlowDescription,
} from '../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';

const messages = defineMessages({
  flowDescription: {
    defaultMessage: 'Add Description:',
    id: 'l7wtR+',
  },
  placeholder: {
    defaultMessage: 'Ex. Show us your cutest pet photos!',
    id: 'B6QHAg',
  },
});

export function FlowDescription() {
  const { formatMessage } = useIntl();
  const { id, isLoading } = useEditorDataContext();

  const description = useGetFlowDescription(id);
  const setDescription = useSetFlowDescription(id);

  const occurrenceInProgress = useAnyOccurrenceInProgress();

  if (isLoading) {
    return <EditorLoader className="h-[40px] w-full" />;
  }

  return (
    <TextField
      label={formatMessage(messages.flowDescription)}
      labelClassName="text-sm font-medium"
      inputClassName="text-gray-9 text-sm font-normal"
      className="flex flex-col gap-1"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      placeholder={formatMessage(messages.placeholder)}
      onClick={() => {
        trackFlowEditorAction('flowDescriptionClicked');
      }}
      disabled={occurrenceInProgress}
    />
  );
}
