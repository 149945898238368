import type { ReactNode } from 'react';

import { AnimateChat } from './AnimateChat';
import { DoraAvatar } from './DoraAvatar';
import { DoraMessageBubble } from './DoraMessageBubble';

export const DoraSingleMessage = (props: {
  children: ReactNode;
  isFaded?: boolean;
  footer?: ReactNode;
  notUpgraded?: boolean;
  shouldAnimate: boolean;
}) => {
  const { children, isFaded, footer, notUpgraded, shouldAnimate } = props;

  const content = (
    <div className="col-start-2 flex self-start">
      <DoraMessageBubble isFaded={isFaded} footer={footer}>
        {children}
      </DoraMessageBubble>
    </div>
  );

  return (
    <>
      <DoraAvatar
        isFaded={isFaded}
        shouldAnimate={shouldAnimate}
        notUpgraded={notUpgraded}
      />
      <AnimateChat shouldAnimateOnMount={shouldAnimate}>{content}</AnimateChat>
    </>
  );
};
