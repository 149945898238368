import { FlowEditorContainers } from '@assembly-web/ui';

import { FlowDescription } from './FlowDescription';
import { FlowOwnerAndCollaborators } from './FlowOwnerAndCollaborators';
import { FlowTitle } from './FlowTitle';
import { OccurrenceInProgressBanner } from './OccurrenceInProgressBanner';

export function Header() {
  return (
    <>
      <FlowEditorContainers.EditorHeaderContainer className="gap-4 border-b border-gray-5">
        <OccurrenceInProgressBanner />
        <FlowTitle />
        <FlowDescription />
      </FlowEditorContainers.EditorHeaderContainer>
      <FlowEditorContainers.EditorHeaderContainer className="pt-2 @[700px]/body:!pt-0">
        <FlowOwnerAndCollaborators />
      </FlowEditorContainers.EditorHeaderContainer>
    </>
  );
}
