import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { timeouts } from '../../../../../shared/dora/constants';
import { DoraSingleMessage } from '../../../../../shared/dora/DoraSingleMessage';
import type { FocusOptionsQuestionProps } from '../../../../types';

const messages = defineMessages({
  prompt: {
    defaultMessage:
      'Would you like me to focus the summary on anything in particular? I can analyze sentiment, segment the summary by respondents, or take any other guidance from you.',
    id: 'd73uMy',
  },
  thanks: {
    defaultMessage: "Thanks, I'll include that content in your summary.",
    id: 'rsr6Q3',
  },
});

export const Message = (props: FocusOptionsQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useFocusTypeSetting },
  } = props;

  const { formatMessage } = useIntl();
  const isSeen = useFocusTypeSetting.getState().isQuestionSeen;
  const isSet = useFocusTypeSetting((store) => store.isSet);
  const markSeen = useFocusTypeSetting((store) => store.markQuestionSeen);

  const setShouldRequestInput = useFocusTypeSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useFocusTypeSetting(
    (store) => store.shouldRequestInput
  );

  const value = useFocusTypeSetting((store) => store.value);

  const isEditingOtherSetting = useEditSettingsStore(
    (store) =>
      store.settingEditing === 'customFocus' || store.isEditingTimePeriod()
  );

  useEffect(() => {
    if (!isSeen) {
      markSeen();
    }
  }, [isSeen, markSeen]);

  useEffect(() => {
    if (!value && !isSet && !shouldRequestInput) {
      setTimeout(() => {
        setShouldRequestInput(true);
      }, timeouts.showInput);
    }
  }, [isSet, setShouldRequestInput, shouldRequestInput, value]);

  return (
    <DoraSingleMessage isFaded={isEditingOtherSetting} shouldAnimate={!isSeen}>
      <TextStyle className="mb-6 text-gray-9">
        {formatMessage(messages.thanks)}
      </TextStyle>
      <TextStyle className="text-gray-9">
        {formatMessage(messages.prompt)}
      </TextStyle>
    </DoraSingleMessage>
  );
};
