import { TextStyle, Tooltip } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextButton } from '../../../../../components/TextButton';
import { trackDoraAction } from '../../../services/analytics';

const messages = defineMessages({
  promptLimitLeftMessage: {
    defaultMessage:
      '{remainingPrompts, plural, one {{remainingPrompts} prompt} other {{remainingPrompts} prompts}} left in conversation. Conversation {currentConversationCount} of {totalConversationsForMonth} this month.<button>Upgrade to full version</button>',
    id: 'mV389C',
  },
  reachedPromptLimit: {
    defaultMessage:
      'You’ve reached this conversation’s limit. You have {remainingConversationsForMonth, plural, one {{remainingConversationsForMonth} conversation} other {{remainingConversationsForMonth} conversations}} left this month.<button>Upgrade to full version</button>',
    id: '4Mqifd',
  },
  limitDisclaimer: {
    defaultMessage:
      'Monthly limits may change for accounts with limited AI Reporting',
    id: 'lE0cCH',
  },
});

type PromptLimitBannerProps = {
  remainingPrompts: number;
  currentConversationCount: number;
  remainingConversationsForMonth: number;
  totalConversationsForMonth: number;
  onUpgradeCTAClicked: () => void;
};

export function PromptLimitBanner({
  remainingPrompts,
  currentConversationCount,
  remainingConversationsForMonth,
  totalConversationsForMonth,
  onUpgradeCTAClicked,
}: PromptLimitBannerProps) {
  const { formatMessage } = useIntl();

  if (remainingPrompts === 0) {
    return (
      <section className="mx-auto mb-3 flex w-[35rem] items-center space-x-2 rounded-lg bg-gray-3 p-2 py-1 text-center">
        <TextStyle variant="sm-regular" className="select-none">
          {formatMessage(messages.reachedPromptLimit, {
            remainingPrompts,
            remainingConversationsForMonth,
            totalConversationsForMonth,
            button: (node: ReactNode) => (
              <TextButton onClick={onUpgradeCTAClicked}>
                <TextStyle variant="sm-bold">{node}</TextStyle>
              </TextButton>
            ),
          })}
        </TextStyle>
      </section>
    );
  }

  return (
    <section className="mx-auto mb-3 flex w-[35rem] items-center space-x-2 rounded-sm bg-warning-2 p-2 py-1">
      <Tooltip
        align="start"
        contentClassName="max-w-lg -ml-14"
        tooltipText={formatMessage(messages.limitDisclaimer)}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99992 1.3335C4.31792 1.3335 1.33325 4.31816 1.33325 8.00016C1.33325 11.6822 4.31792 14.6668 7.99992 14.6668C11.6819 14.6668 14.6666 11.6822 14.6666 8.00016C14.6666 4.31816 11.6819 1.3335 7.99992 1.3335ZM7.99992 11.3335C7.63192 11.3335 7.33325 11.0348 7.33325 10.6668V8.00016C7.33325 7.63216 7.63192 7.3335 7.99992 7.3335C8.36792 7.3335 8.66659 7.63216 8.66659 8.00016V10.6668C8.66659 11.0348 8.36792 11.3335 7.99992 11.3335ZM8.33325 6.00016H7.66659C7.48259 6.00016 7.33325 5.85083 7.33325 5.66683V5.00016C7.33325 4.81616 7.48259 4.66683 7.66659 4.66683H8.33325C8.51725 4.66683 8.66659 4.81616 8.66659 5.00016V5.66683C8.66659 5.85083 8.51725 6.00016 8.33325 6.00016Z"
            fill="#FAAD14"
          />
        </svg>
      </Tooltip>
      <TextStyle variant="xs-regular" className="select-none">
        {formatMessage(
          remainingPrompts !== 0
            ? messages.promptLimitLeftMessage
            : messages.reachedPromptLimit,
          {
            currentConversationCount,
            remainingPrompts,
            remainingConversationsForMonth,
            totalConversationsForMonth,
            button: (node: ReactNode) => (
              <TextButton
                onClick={() => {
                  trackDoraAction('upgradeOnConversationCounterClicked');
                  onUpgradeCTAClicked();
                }}
              >
                <TextStyle variant="sm-bold">{node}</TextStyle>
              </TextButton>
            ),
          }
        )}
      </TextStyle>
    </section>
  );
}
