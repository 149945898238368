import { TextStyle } from '@assembly-web/ui';

import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { useIsBottomSheetInputActive } from '../../../../hooks/useIsBottomSheetInputActive';
import { AnswerMessage } from '../../../../shared/AnswerMessage';
import {
  type EditSettingsStoreType,
  type EventSourceStoreType,
  FocusType,
  type FormSettingsStoreType,
  type MemberAvatarProps,
} from '../../../../types';

export const CustomFocusAnswer = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    eventSourceStore: EventSourceStoreType;
    formSettingsStore: FormSettingsStoreType;
  }
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { useEventSourceStore },
    formSettingsStore,
    ...messageProps
  } = props;

  const {
    hideActiveInput,
    useCustomFocusSetting,
    useFocusTypeSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const focusTypeValue = useFocusTypeSetting((store) => store.value);
  const customFocusValue = useCustomFocusSetting((store) => store.value);
  const isSeen = useCustomFocusSetting.getState().isAnswerSeen;
  const isSet = useCustomFocusSetting((store) => store.isSet);
  const markSeen = useCustomFocusSetting((store) => store.markAnswerSeen);
  const markSet = useCustomFocusSetting((store) => store.markSet);

  const setShouldRequestInput = useCustomFocusSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useCustomFocusSetting(
    (store) => store.shouldRequestInput
  );

  const isEditingPrevSetting = useEditSettingsStore(
    (store) =>
      store.isEditingRespondents() ||
      store.isEditingBlocks() ||
      store.settingEditing === 'focusType'
  );

  const isEditingCurSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'customFocus'
  );

  const isEditingOtherSetting = useEditSettingsStore((store) =>
    store.isEditingTimePeriod()
  );

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const setShouldRequestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const handleEdit = () => {
    trackDoraAction('summaryInputEdited', { doraSummaryInput: 'Focus' });
    setSettingEditing('customFocus');
    hideActiveInput();

    setTimeout(() => {
      setShouldRequestEdit('customFocus', true);
    }, timeouts.showInput);
  };

  const isSettingsConfirmed = useSettingsConfirmationSetting((store) =>
    Boolean(store.value)
  );

  const isInputActive = useIsBottomSheetInputActive(formSettingsStore);
  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    focusTypeValue === FocusType.Custom &&
    !isEditingPrevSetting &&
    customFocusValue && (
      <AnswerMessage
        isEditDisabled={
          !isSummaryError &&
          (isEditingCurSetting ||
            isEditingOtherSetting ||
            isSettingsConfirmed ||
            !isInputActive)
        }
        isEditing={isEditingCurSetting}
        isFaded={isEditingOtherSetting}
        isSeen={isSeen}
        isSet={isSet}
        markSeen={markSeen}
        markSet={markSet}
        onEdit={handleEdit}
        setShouldRequestInput={setShouldRequestInput}
        shouldRequestInput={shouldRequestInput}
        {...messageProps}
      >
        {customFocusValue.split('\n').map((line, index) => (
          <TextStyle key={index} className={index > 0 ? 'mt-6' : undefined}>
            {line}
          </TextStyle>
        ))}
      </AnswerMessage>
    )
  );
};
