import type { RefObject } from 'react';

import type { createEventSourceStore } from '../../../stores/flowSummaryStore/eventSourceStore';
import type {
  createEditSettingsStore,
  createFormSettingsStore,
} from '../../../stores/flowSummaryStore/formSettingsStore';

export type EditSettingsStoreType = ReturnType<typeof createEditSettingsStore>;
export type EventSourceStoreType = ReturnType<typeof createEventSourceStore>;
export type FormSettingsStoreType = ReturnType<typeof createFormSettingsStore>;

export enum BlocksGroup {
  FullResponse = 'full',
  Individual = 'individual',
}

export type BlocksGroupInputProps = {
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  formSettingsStore: FormSettingsStoreType;
};

export type BlocksGroupQuestionProps = {
  editSettingsStore: EditSettingsStoreType;
  formSettingsStore: FormSettingsStoreType;
  isPrevQuestionSkipped: boolean;
};

export type ConfirmActionsProps = {
  formSettingsStore: FormSettingsStoreType;
  onCancel: () => void;
};

export type CustomFocusInputProps = {
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  formSettingsStore: FormSettingsStoreType;
};

export type CustomFocusQuestionProps = {
  editSettingsStore: EditSettingsStoreType;
  formSettingsStore: FormSettingsStoreType;
};

export type CustomTimePeriodInputProps = {
  drawerRef?: RefObject<HTMLDivElement>;
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  flowId: string;
  flowPostInterval?: Date[];
  formSettingsStore: FormSettingsStoreType;
  isAnonymous?: boolean;
  isError: boolean;
  isLoading: boolean;
  onTryAgain: () => void;
  respondentIds?: string[];
};

export type CustomTimePeriodQuestionProps = {
  editSettingsStore: EditSettingsStoreType;
  formSettingsStore: FormSettingsStoreType;
  isError: boolean;
  isLoading: boolean;
};

export type FlowBlockProps = {
  blockId: string;
  title: string;
  type: string;
};

export type FlowLinkProps = {
  flowHref: string;
  flowTitle: string;
};

export type FlowRespondentProps = {
  image: string;
  memberID: string;
  name: string;
};

export type FocusOptionsInputProps = {
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  formSettingsStore: FormSettingsStoreType;
};

export type FocusOptionsQuestionProps = {
  editSettingsStore: EditSettingsStoreType;
  formSettingsStore: FormSettingsStoreType;
};

export enum FocusType {
  Custom = 'CUSTOM',
  SegmentByBlock = 'SEGMENT_BY_BLOCK',
  SegmentByRespondent = 'SEGMENT_BY_RESPONDENT',
  Sentiment = 'SENTIMENT',
}

export type IndividualBlocksInputProps = {
  flowBlocks?: FlowBlockProps[];
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  formSettingsStore: FormSettingsStoreType;
  isError: boolean;
  isLoading: boolean;
  onTryAgain: () => void;
};

export type IndividualBlocksQuestionProps = {
  editSettingsStore: EditSettingsStoreType;
  formSettingsStore: FormSettingsStoreType;
};

export type IndividualFlowBlock = Pick<FlowBlockProps, 'blockId' | 'title'>;

export type IndividualFlowRespondent = {
  fullName: string;
  memberId: string;
};

export type IndividualRespondentsInputProps = {
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  flowRespondents?: FlowRespondentProps[];
  formSettingsStore: FormSettingsStoreType;
  isError: boolean;
  isLoading: boolean;
  onTryAgain: () => void;
};

export type IndividualRespondentsQuestionProps = {
  editSettingsStore: EditSettingsStoreType;
  formSettingsStore: FormSettingsStoreType;
};

export type MemberAvatarProps = {
  memberFullName: string;
  memberId: string;
  memberImage?: string;
};

export type PostsCreatedRange = {
  endDate: string;
  startDate: string;
};

export type FlowTimePeriodOption = {
  formattedLabel: string;
  hasPosts: boolean;
  range: PostsCreatedRange;
  rawLabel: string;
};

export enum RespondentsGroup {
  All = 'all',
  Anonymous = 'anonymous',
  Individual = 'individual',
}

export type RespondentsGroupInputProps = {
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  formSettingsStore: FormSettingsStoreType;
  isError: boolean;
  isLoading: boolean;
  onTryAgain: () => void;
  shouldShowAnonymousOption: boolean;
};

export type RespondentsGroupQuestionProps = FlowLinkProps & {
  editSettingsStore: EditSettingsStoreType;
  formSettingsStore: FormSettingsStoreType;
  hasOnlyAnonymousResponses: boolean;
  isQuestionSkipped: boolean;
};

export type TimePeriodOptionsInputProps = {
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  formSettingsStore: FormSettingsStoreType;
  isError: boolean;
  isLoading: boolean;
  onTryAgain: () => void;
  predefinedTimePeriods: FlowTimePeriodOption[];
};

export type TimePeriodOptionsQuestionProps = {
  editSettingsStore: EditSettingsStoreType;
  formSettingsStore: FormSettingsStoreType;
};

export type TimePeriodParams = {
  duration: string;
  endDate?: string;
  startDate?: string;
};
