import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

import type { Connection, ListConnectionsResponse } from '../types';

export const getConnectionsQueryKey = () => ['listConnections'];

export function getConnectionsQuery(): UseQueryOptions<Connection[]> {
  return {
    queryKey: getConnectionsQueryKey(),
    queryFn: async () => {
      const response = await assemblyAPI.get<ListConnectionsResponse>(
        APIEndpoints.listConnections,
        {
          params: {
            category: 'filestorage',
          },
        }
      );

      return response.data.data;
    },
  };
}
