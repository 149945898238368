import { doraAPI, DoraAPIEndpoints } from '@assembly-web/services';
import { useMutation } from '@tanstack/react-query';

import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';
import type { DoraChatDrawerData } from '../../stores/doraChatStore';

export function useChatReportTitle() {
  const findAndUpdateDrawerField = useMultiDrawerStore(
    (store) => store.findAndUpdateDrawerField
  );

  return useMutation(
    async ({ threadId }: { threadId: string; drawerId: string }) => {
      const { data } = await doraAPI.post<{ threadName: string }>(
        DoraAPIEndpoints.generateReportThreadTitle,
        { threadId }
      );

      return data.threadName;
    },
    {
      onSettled(threadName, _, { drawerId, threadId }) {
        findAndUpdateDrawerField(drawerId, (draft) => {
          if (threadName && draft.type === 'doraChat') {
            draft.title = threadName;
            (draft.data as DoraChatDrawerData).isDefaultTitle = false;
            (draft.data as DoraChatDrawerData).threadId = threadId;
          }
        });
      },
    }
  );
}
