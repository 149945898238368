import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import type { QueryClient } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { defineMessages, useIntl } from 'react-intl';

import { getConnectionsQueryKey } from '../queries/getConnectionsQuery';
import { trackAppConnectionError } from '../services/analytics';
import { type Connection, ConnectionState } from '../types';

const messages = defineMessages({
  errorDisconnecting: {
    defaultMessage: 'Failed to disconnect app. Please try again.',
    id: 'AiQ5FT',
  },
});

const queryKey = getConnectionsQueryKey();

const updateConnectionInCache = (
  queryClient: QueryClient,
  connectionId: string,
  connectionState: ConnectionState
) => {
  const cachedConnectionsList = queryClient.getQueryData<
    Connection[] | undefined
  >(queryKey);

  if (cachedConnectionsList) {
    const updatedConnectionsList = [...cachedConnectionsList];

    const indexOfMatchingAppInCache = updatedConnectionsList.findIndex(
      (app) => app.connectionId === connectionId
    );

    if (indexOfMatchingAppInCache !== -1) {
      const matchingApp = updatedConnectionsList[indexOfMatchingAppInCache];

      if (matchingApp.connectionState !== ConnectionState.Syncing) {
        updatedConnectionsList[indexOfMatchingAppInCache] = {
          ...matchingApp,
          connectionState,
        };

        queryClient.setQueryData(queryKey, updatedConnectionsList);
      }
    }
  }
};

export function useDisconnectingApps() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { showErrorToast } = useToastStore();

  const { mutate: disconnectApp } = useMutation({
    mutationFn: async (variables: {
      appName: string;
      connectionId: string;
    }) => {
      const { connectionId } = variables;

      await assemblyAPI.put(APIEndpoints.disconnectApp(connectionId));
    },
    onError: (err: unknown, variables) => {
      const errorInfo = err instanceof Error ? err : undefined;

      showErrorToast(formatMessage(messages.errorDisconnecting));

      logger.error(
        'Error disconnecting external app',
        { ...variables, error: errorInfo },
        errorInfo
      );

      trackAppConnectionError('disconnectAppError', {
        disconnectedAppName: variables.appName,
      });

      // Revert to connected state.
      updateConnectionInCache(
        queryClient,
        variables.connectionId,
        ConnectionState.Connected
      );
    },
    onMutate: ({ connectionId: disconnectingAppId }) => {
      updateConnectionInCache(
        queryClient,
        disconnectingAppId,
        ConnectionState.Disconnecting
      );
    },
  });

  return {
    disconnectApp,
  };
}
