import { defineMessages, useIntl } from 'react-intl';

import { useGetFlowName } from '../../../../../../stores/useFlowBuilderStore';
import { useGetFlowBuilderDetailsById } from '../../../../hooks/flows/useGetFlowBuilderDetailsById';
import type { FlowCreationData } from '../../types';

const messages = defineMessages({
  editModeTitle: {
    defaultMessage: 'Editing "{flowName}"',
    id: 'oIsEK/',
  },
  newFlowTitle: {
    defaultMessage:
      'New Flow: {name, select, untitled {Untitled} other {{name}}}',
    id: 'bY6AOa',
  },
});

export const useEditorDrawerHeaderTitle = (data: FlowCreationData) => {
  const { formatMessage } = useIntl();

  const flowData = useGetFlowBuilderDetailsById(data.id, {
    enabled: data.type === 'edit',
    select: (data) =>
      formatMessage(messages.editModeTitle, { flowName: data.name }),
  });
  const flowTitle = useGetFlowName(data.id);

  return data.type === 'edit' && flowData.status === 'success'
    ? flowData.data
    : formatMessage(messages.newFlowTitle, { name: flowTitle || 'untitled' });
};
