import {
  APIEndpoints,
  assemblyAPI,
  config,
  getUserDetailsQuery,
  MobileJWTTokenQueryParamKey,
  MobileRefreshTokenQueryParam,
  userAuthStore,
} from '@assembly-web/services';
import { AssemblyLoadingIndicator, Button, Modal } from '@assembly-web/ui';
import type { QueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { redirect, useLoaderData } from 'react-router-dom';

import { waitForRedirection } from '../../../../../services/waitForRedirection';
import { AccountCreationLoadingIndicator } from '../../../components/AccountCreationLoadingIndicator';
import { useUserSetup } from '../../../hooks/useAccountSetup';
import type { LaunchWorkspaceAPIResponse } from '../../../types/workspaces';

type AccountCreationLoaderType = {
  isMobileApp: boolean;
  jwt: string;
  refreshToken?: string;
};

const messages = defineMessages({
  header: { defaultMessage: 'Your account is set up!', id: 'zAiYHE' },
  body: {
    defaultMessage:
      'Congrats, and welcome to Assembly -- get ready to join your team. We’ll set you up with some flows and give you time to explore!',
    id: 'p+Fk7h',
  },
  cta: { defaultMessage: 'Explore Assembly', id: 'Pjo3sT' },
});

export function UserAccountCreationSuccessRoute() {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const loaderData = useLoaderData() as AccountCreationLoaderType;

  const redirectToHome = useCallback(
    async function () {
      setIsModalOpen(false);

      if (loaderData.isMobileApp) {
        window.location.href = `${config.domains.mobileApp}home?${new URLSearchParams(
          [
            [MobileJWTTokenQueryParamKey, loaderData.jwt],
            ...(loaderData.refreshToken
              ? [[MobileRefreshTokenQueryParam, loaderData.refreshToken]]
              : []),
          ]
        )}`;
      } else {
        const url = new URL(config.domains.app);
        url.pathname = '/a/discover';
        window.location.href = url.toString();
      }

      await waitForRedirection();
    },
    [loaderData]
  );

  const isUserSetup = useUserSetup();

  useEffect(
    function autoRedirectToHome() {
      if (!isUserSetup) {
        return;
      }
      let timeLeft = 5;
      const interval = setInterval(() => {
        if (timeLeft <= 0) {
          return redirectToHome();
        }
        timeLeft = timeLeft - 1;
      }, 1000);
      return () => clearInterval(interval);
    },
    [isUserSetup, redirectToHome]
  );

  if (!isModalOpen) {
    return <AssemblyLoadingIndicator />;
  }

  if (!isUserSetup) {
    return <AccountCreationLoadingIndicator />;
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => redirectToHome()}
      title={formatMessage(messages.header)}
      ctaSection={
        <Button type="button" onClick={() => redirectToHome()} isFullWidth>
          {formatMessage(messages.cta)}
        </Button>
      }
    >
      <p>{formatMessage(messages.body)}</p>
    </Modal>
  );
}

export function userAccountCreationSuccessLoader(queryClient: QueryClient) {
  return async function userAccountCreationSuccessLoader() {
    try {
      if (!userAuthStore.getState().isLoginViaMobileApp) {
        return { isMobileApp: false };
      }

      let jwt = userAuthStore.getState().jwtToken;
      let refreshToken = userAuthStore.getState().refreshToken;
      if (!jwt || !refreshToken) {
        const { assembly } = await queryClient.fetchQuery(
          getUserDetailsQuery()
        );

        const {
          data: { jwtToken, refreshToken: fetchedRefreshToken },
        } = await assemblyAPI.post<LaunchWorkspaceAPIResponse>(
          APIEndpoints.loginToWorkspace,
          { assemblyId: assembly.assemblyId }
        );

        jwt = jwtToken;
        refreshToken = fetchedRefreshToken;
      }

      return { isMobileApp: true, jwt, refreshToken };
    } catch (error) {
      return redirect('/login?error=server_error');
    }
  };
}
