import { PopoutDrawerTemplate } from '../PopoutDrawerTemplate';
import type { FlowCreationDrawer as FlowCreationDrawerProps } from '../types';
import { FlowCreationDrawerBody } from './FlowCreationDrawerBody';

export const FlowCreationDrawer = (props: FlowCreationDrawerProps) => {
  return (
    <PopoutDrawerTemplate {...props}>
      <FlowCreationDrawerBody {...props} />
    </PopoutDrawerTemplate>
  );
};
