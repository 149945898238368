import {
  type ContentBlockState,
  useDisplayCurrency,
} from '@assembly-web/services';
import {
  Banner,
  BlockActions,
  BlockIndicator as BlockIndicatorImpl,
  BlockLabel,
  BlockOptionsDropdown,
  BlocksTable,
  IconButton,
  TextField as BaseTextField,
  TextStyle,
} from '@assembly-web/ui';
import { PencilIcon } from '@heroicons/react/24/outline';
import { createContext, type ReactNode, useContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useBlockIndex,
  useBlockType,
  useCanDuplicate,
  useCanMoveBlock,
  useDeleteBlock,
  useDuplicateBlock,
  useGetBlockConfigurationError,
  useGetBlockIsLinked,
  useGetBlockSummary,
  useGetBlockTitle,
  useGetBlockTitleError,
  useIsRequiredBlock,
  useMoveBlock,
  useSetBlockTitle,
  useSetIsRequiredBlock,
} from '../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useBlockIdContext } from '../context/BlockIdContext';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';
import { useFlowData } from '../hooks/useFlowData';
import { useFormattedBlockName } from '../hooks/useFormattedBlockName';
import {
  useExpandedBlockId,
  useSetExpandedBlockId,
} from '../store/useEditorMiscState';
import { BlockTypeDropdown, InsertBlockMenu } from './BlocksMenu';
import { ConfirmChangeQuestion } from './ConfirmChangeQuestion';

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Write your question or statement here...',
    id: 'KX0KKD',
  },
  type: { defaultMessage: 'Type', id: '+U6ozc' },
  linkedBlockRequiredTooltip: {
    defaultMessage:
      'Not all Assembly members have {currency} to give, so this can’t be made a required block.',
    id: 'j+D7CV',
  },
  linkedPersonSelectBlockBanner: {
    defaultMessage:
      'This <b>Person Selection</b> block is linked to a <b>Give Points</b> block. Whomever the respondent selects will receive the {currency} specified in the linked block.',
    id: 'Hj1qsB',
  },
  linkedGivePointsBlockBanner: {
    defaultMessage:
      'This <b>Give Points</b> block is linked to a <b>Person Selection</b> block.',
    id: 'DA94pK',
  },
  moveDown: {
    defaultMessage: 'Move question down',
    id: '9Ilj8U',
  },
  moveUp: {
    defaultMessage: 'Move question up',
    id: '0ZEEut',
  },
  deleteRow: {
    defaultMessage: 'Delete question',
    id: 'FatYKS',
  },
  duplicateRow: {
    defaultMessage: 'Duplicate question',
    id: 'Bj19lA',
  },
  configurationErrorBanner: {
    defaultMessage:
      'There is an error in the configuration details of this question.',
    id: 'IHSB1M',
  },
});

const bannerMessageMap = {
  GIVE_POINTS_STACK: 'linkedGivePointsBlockBanner',
  PERSON_SELECTOR: 'linkedPersonSelectBlockBanner',
} satisfies Record<
  Extract<ContentBlockState['type'], 'PERSON_SELECTOR' | 'GIVE_POINTS_STACK'>,
  Extract<
    keyof typeof messages,
    'linkedPersonSelectBlockBanner' | 'linkedGivePointsBlockBanner'
  >
>;

const MenuContext = createContext<boolean>(false);

const useIsMenu = () => {
  return useContext(MenuContext);
};

function DeleteRow() {
  const [confirmDelete, setConfirmDelete] =
    useState<ReturnType<typeof useBlockType>>(null);

  const { formatMessage } = useIntl();

  const { id, type } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const isMenu = useIsMenu();

  const blockType = useBlockType(id, blockId);
  const isLinkedBlock = useGetBlockIsLinked(id, blockId);

  const deleteHandler = useDeleteBlock(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();
  const { data: newestPostsData } = useFlowData(id, type);

  const handler = () => {
    if (
      (type === 'edit' && newestPostsData?.data.length) ||
      blockType === 'GIVE_POINTS_STACK' ||
      isLinkedBlock
    ) {
      setConfirmDelete(blockType);
      document.dispatchEvent(new Event('capture-actions-focus'));
    } else {
      deleteHandler();
      trackFlowEditorAction('blockDeleted', { blockType });
      document.dispatchEvent(new Event('clear-actions-focus'));
    }
  };

  const handleClose = () => {
    setConfirmDelete(null);
  };
  const handleConfirm = () => {
    deleteHandler();
    trackFlowEditorAction('blockDeleted', { blockType });
    setConfirmDelete(null);
  };

  return (
    <>
      <BlockActions.Delete
        {...(isMenu
          ? {
              type: 'menu',
              label: formatMessage(messages.deleteRow),
              onSelect: handler,
              value: 'duplicate',
            }
          : {
              type: 'button',
              'aria-label': formatMessage(messages.deleteRow),
              onClick: handler,
            })}
        disabled={isOccurrenceInProgress}
      />
      <ConfirmChangeQuestion
        confirmationType="delete_question"
        handleClose={handleClose}
        handleConfirmation={handleConfirm}
        open={Boolean(confirmDelete)}
      />
    </>
  );
}

function DuplicateRow() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const isMenu = useIsMenu();

  const canDuplicate = useCanDuplicate(id, blockId);
  const duplicateHandler = useDuplicateBlock(id, blockId);

  const formattedBlockName = useFormattedBlockName();
  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handler = () => {
    duplicateHandler();
    trackFlowEditorAction('blockDuplicated', { blockType: formattedBlockName });
  };

  return (
    <BlockActions.Duplicate
      {...(isMenu
        ? {
            type: 'menu',
            label: formatMessage(messages.duplicateRow),
            onSelect: handler,
            value: 'duplicate',
          }
        : {
            type: 'button',
            'aria-label': formatMessage(messages.duplicateRow),
            onClick: handler,
          })}
      disabled={isOccurrenceInProgress || !canDuplicate}
    />
  );
}

function MoveRow({ direction }: { direction: 'up' | 'down' }) {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const canMoveBlock = useCanMoveBlock(id, blockId);
  const blockIndex = useBlockIndex(id, blockId);

  const isMenu = useIsMenu();

  const moveBlock = useMoveBlock(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleMoveUp = () => {
    moveBlock({ fromIndex: blockIndex, toIndex: blockIndex - 1 });
    trackFlowEditorAction('blockMovedUp');
  };
  const handleMoveDown = () => {
    moveBlock({ fromIndex: blockIndex, toIndex: blockIndex + 1 });
    trackFlowEditorAction('blockMovedDown');
  };

  return direction === 'up' ? (
    <BlockActions.MoveUp
      {...(isMenu
        ? {
            type: 'menu',
            label: formatMessage(messages.moveUp),
            onSelect: handleMoveUp,
            value: 'move-up',
          }
        : {
            type: 'button',
            'aria-label': formatMessage(messages.moveUp),
            onClick: handleMoveUp,
          })}
      disabled={isOccurrenceInProgress || !canMoveBlock.moveUp}
    />
  ) : (
    <BlockActions.MoveDown
      {...(isMenu
        ? {
            type: 'menu',
            label: formatMessage(messages.moveDown),
            onSelect: handleMoveDown,
            value: 'move-down',
          }
        : {
            type: 'button',
            'aria-label': formatMessage(messages.moveDown),
            onClick: handleMoveDown,
          })}
      aria-label={formatMessage(messages.moveDown)}
      onClick={() =>
        moveBlock({ fromIndex: blockIndex, toIndex: blockIndex + 1 })
      }
      disabled={isOccurrenceInProgress || !canMoveBlock.moveDown}
    />
  );
}

function BlockIndicator() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const index = useBlockIndex(id, blockId);
  const blockType = useBlockType(id, blockId);

  return (
    <BlockIndicatorImpl index={index + 1} type={blockType ?? 'OPEN_ENDED'} />
  );
}

function RequiredToggle() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const isRequired = useIsRequiredBlock(id, blockId);
  const setIsRequired = useSetIsRequiredBlock(id, blockId);
  const isLinkedBlock = useGetBlockIsLinked(id, blockId);
  const formattedBlockName = useFormattedBlockName();
  const occurrenceInProgress = useAnyOccurrenceInProgress();

  const currency = useDisplayCurrency();

  return (
    <BlocksTable.RequiredToggle
      checked={isRequired}
      onCheckedChange={(checked) => {
        trackFlowEditorAction('requiredClicked', {
          blockType: formattedBlockName,
          required: checked,
        });
        setIsRequired(checked);
      }}
      disabled={occurrenceInProgress}
      {...(isLinkedBlock && {
        disabled: true,
        tooltipText: formatMessage(messages.linkedBlockRequiredTooltip, {
          currency,
        }),
      })}
    />
  );
}

function TextArea() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const setBlockTitle = useSetBlockTitle(id, blockId);
  const value = useGetBlockTitle(id, blockId);
  const isRequired = useIsRequiredBlock(id, blockId);
  const formattedBlockName = useFormattedBlockName();
  const error = useGetBlockTitleError(id, blockId);

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  return (
    <BaseTextField
      hideLabel
      onChange={(e) => setBlockTitle(e.target.value)}
      value={value}
      placeholder={formatMessage(messages.placeholder)}
      required={isRequired}
      inputClassName="px-4 py-2.5 text-gray-9 text-sm font-normal rounded-lg"
      disabled={isOccurrenceInProgress}
      {...(Boolean(error && !error.showBanner) && {
        isInvalid: true,
        invalidText: error?.message,
      })}
      onClick={() => {
        trackFlowEditorAction('blockTitleClicked', {
          blockType: formattedBlockName,
        });
      }}
    />
  );
}

export function EditRowToggle() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const expandedBlockId = useExpandedBlockId(id);
  const setExpandedBlockId = useSetExpandedBlockId(id);

  return (
    <BlocksTable.EditButton
      pressed={blockId === expandedBlockId}
      onPressedChange={(pressed) => {
        trackFlowEditorAction('editDetailsClicked');
        if (pressed) {
          setExpandedBlockId(blockId);
        } else {
          setExpandedBlockId(null);
        }
      }}
    />
  );
}

function BlockSummary() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const summary = useGetBlockSummary(id, blockId);
  const setExpandedBlockId = useSetExpandedBlockId(id);

  const error = useGetBlockConfigurationError(id, blockId);

  if (!summary) {
    return null;
  }

  return (
    <div className="flex items-center gap-1">
      <TextStyle
        variant="xs-regular"
        subdued
        className={error?.showBanner ? '!text-error-6' : undefined}
      >
        {summary}
      </TextStyle>
      <IconButton
        className="flex-shrink-0"
        size="xSmall"
        variation="tertiaryLite"
        onClick={() => setExpandedBlockId(blockId)}
      >
        <PencilIcon />
      </IconButton>
    </div>
  );
}

function BlockConfigurationError() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const error = useGetBlockConfigurationError(id, blockId);

  if (!error?.showBanner) {
    return null;
  }

  return (
    <Banner status="error">
      {formatMessage(messages.configurationErrorBanner)}
    </Banner>
  );
}

function BlockActionsDropdown() {
  return (
    <BlockOptionsDropdown.Menu
      align="end"
      trigger={<BlockOptionsDropdown.MenuTrigger variant="overflow" />}
    >
      <MenuContext.Provider value={true}>
        <BlockOptionsDropdown.UnstyledMenuItem
          onSelect={(e) => e.preventDefault()}
          className="flex cursor-pointer items-center justify-between gap-2 px-3 py-2 focus:outline-none data-[highlighted]:bg-gray-3"
        >
          <RequiredToggle />
        </BlockOptionsDropdown.UnstyledMenuItem>
        <BlockOptionsDropdown.MenuSeparator />
        <MoveRow direction="up" />
        <MoveRow direction="down" />
        <DuplicateRow />
        <DeleteRow />
      </MenuContext.Provider>
    </BlockOptionsDropdown.Menu>
  );
}

export function Question() {
  return (
    <BlocksTable.QuestionCellLayout
      blockIndicator={<BlockIndicator />}
      overflowMenu={<BlockActionsDropdown />}
      requiredToggle={<RequiredToggle />}
      textArea={<TextArea />}
      blockSummary={<BlockSummary />}
      blockConfigurationError={<BlockConfigurationError />}
    />
  );
}

function BannerMessage() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const isLinkedBlock = useGetBlockIsLinked(id, blockId);
  const blockType = useBlockType(id, blockId);

  const currency = useDisplayCurrency();

  if (
    blockType === 'GIVE_POINTS_STACK' ||
    (blockType === 'PERSON_SELECTOR' && isLinkedBlock)
  ) {
    return (
      <Banner status="info">
        <TextStyle as="span" variant="xs-regular">
          {formatMessage(messages[bannerMessageMap[blockType]], {
            b: (msg) => (
              <TextStyle as="span" variant="xs-medium">
                {msg}
              </TextStyle>
            ),
            currency,
          })}
        </TextStyle>
      </Banner>
    );
  }

  return null;
}

function BlockSelectorForSmallViewport() {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-col items-start gap-1 @[600px]/table:hidden">
      <BlockLabel>{formatMessage(messages.type)}</BlockLabel>
      <BlockTypeDropdown triggerVariant="border" />
    </div>
  );
}

function Container({
  children,
  expansionRow,
}: {
  children: ReactNode;
  expansionRow: ReactNode;
}) {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();
  const expandedBlockId = useExpandedBlockId(id);

  const expanded = blockId === expandedBlockId;

  return (
    <BlocksTable.Row
      highlightRow={expanded}
      leftStack={
        <>
          <MoveRow direction="up" />
          <MoveRow direction="down" />
        </>
      }
      rightStack={
        <>
          <DeleteRow />
          <DuplicateRow />
        </>
      }
      addBlockMenu={<InsertBlockMenu />}
    >
      {children}
      {Boolean(expanded) && (
        <BlocksTable.ExpansionContainer>
          <BannerMessage />
          <BlockSelectorForSmallViewport />
          {expansionRow}
        </BlocksTable.ExpansionContainer>
      )}
    </BlocksTable.Row>
  );
}

export function DefaultBlockRow({ children }: { children: ReactNode }) {
  return (
    <Container expansionRow={children}>
      <EditRowToggle />
      <Question />
      <BlockTypeDropdown />
    </Container>
  );
}
