import {
  APIEndpoints,
  type PlanFeaturesAPIResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import type { UseQueryOptions } from '@tanstack/react-query';

export function getPlanFeaturesQuery(
  options?: UseQueryOptions<PlanFeaturesAPIResponse>
): UseQueryOptions<PlanFeaturesAPIResponse> {
  return {
    ...options,
    queryKey: ['planFeatures'],
    queryFn: async () => {
      const response = await assemblyAPI.get<PlanFeaturesAPIResponse>(
        APIEndpoints.getPlanFeatures
      );
      return response.data;
    },
  };
}
