import { useCallback } from 'react';

import { useMultiDrawerStore } from '../../../../../../stores/useMultiDrawerStore';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useClearPermissions } from '../store/useEditorMiscState';

export const useClearPersistedPermission = () => {
  const { id } = useEditorDataContext();

  const clearPermissionState = useClearPermissions(id);
  const updateDrawer = useMultiDrawerStore((store) => store.updateDrawerField);

  return useCallback(() => {
    updateDrawer(id, (state) => {
      if (state.type !== 'flowCreation') return state;
      state.data.view = 'editor';
    });
    clearPermissionState();
  }, [clearPermissionState, id, updateDrawer]);
};
