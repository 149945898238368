import {
  APIEndpoints,
  assemblyAPI,
  type ChallengeSearchResult,
  type MemberAPIResponse,
  MemberState,
  type Nullable,
  type ShareCriteria,
  type UserFeedApiResponse,
  useUserDetails,
  VisibilityTypes,
} from '@assembly-web/services';
import {
  type InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { produce } from 'immer';

import { CHALLENGE_STATUS } from '../stores/queryKeyStore';
import { useChallengeFeedQueryKey } from './useChallengeFeedQueryKey';
import { isCurrentUserParticipant } from './useUpdateChallengeMutation';

export type CreateChallengePayload = {
  title: string;
  description?: {
    plainText: string;
    messageTokens: string;
    messageHtml: string;
  };
  points: number;
  totalClaimLimit: number;
  individualClaimLimit: number;
  proof: {
    isRequired: boolean;
    content: {
      type: string;
      promptText: string;
    }[];
  };
  image?: {
    name: string;
    size: number;
    location: string;
  };
  imageUrl?: Nullable<string>;
  claimButtonText: string;
  interactionSettings?: {
    hideReplies: boolean;
    hideReactions: boolean;
  };
  notificationSettings: {
    channels: string[];
  };
  launchNow: boolean;
  sharingRules?: ShareCriteria;
  createdBy?: string;
  duplicateOf?: string;
};

export function useCreateChallengeMutation({
  onSuccess,
  onError,
}: {
  onSuccess: (payload: CreateChallengePayload) => void;
  onError: (err: unknown, payload: CreateChallengePayload) => void;
}) {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  const {
    queryKey,
    allChallengesQueryKey,
    statusFilter: statusFilterArray,
  } = useChallengeFeedQueryKey();

  return useMutation({
    mutationFn: async (payload: CreateChallengePayload) => {
      return assemblyAPI.post(APIEndpoints.createChallenge, payload);
    },
    onSuccess: async (data, payload) => {
      const isValidState = statusFilterArray.length
        ? [
            payload.launchNow
              ? CHALLENGE_STATUS.ACTIVE
              : CHALLENGE_STATUS.QUEUED,
          ].some((item) => statusFilterArray.includes(item))
        : true;

      await queryClient.cancelQueries({ queryKey });

      if (isValidState && userDetails) {
        queryClient.setQueryData(
          queryKey,
          produce((draft: InfiniteData<UserFeedApiResponse> | undefined) => {
            draft?.pages[0].data.unshift(
              getOptimisticData({
                payload,
                id: data.data.challengeId,
                userDetails,
              })
            );
          })
        );
      }
      onSuccess(payload);
    },
    onError: (err, payload) => {
      onError(err, payload);
    },
    onSettled: () => {
      // Invalidate the cache after 5 seconds to sync with ES
      setTimeout(() => {
        queryClient.invalidateQueries(allChallengesQueryKey);
      }, 5000);
    },
  });
}

const getOptimisticData = ({
  payload,
  id,
  userDetails,
}: {
  payload: CreateChallengePayload;
  id: string;
  userDetails: MemberAPIResponse;
}) => {
  const createdAt = new Date().toISOString();
  const {
    title,
    description,
    points,
    claimButtonText,
    interactionSettings,
    launchNow,
    image,
    imageUrl,
    sharingRules,
    individualClaimLimit,
  } = payload;

  const {
    name,
    firstName,
    lastName,
    username,
    email,
    memberId,
    pointsGiven,
    totalPointsGiven,
    memberState,
    role,
    department,
    status,
    profile,
  } = userDetails.member;

  return {
    id,
    score: 2,
    employerId: '',
    source: 'assembly',
    type: 'challenge',
    state: launchNow ? 'ACTIVE' : 'INACTIVE',
    content: '',
    tags: ['challenges'],
    createdAt,
    updatedAt: createdAt,
    cardDetails: {
      challengeId: id,
      title,
      description,
      points: 1,
      state: launchNow ? 'ACTIVE' : 'QUEUED',
      type: 'STANDALONE',
      createdAt,
      image: {
        name: image?.name ?? '',
        location: imageUrl ?? '',
        size: image?.size ?? 0,
        type: 'image',
        createdAt: new Date().toISOString(),
        originalName: image?.name ?? '',
        thumbnails: {
          '32': '',
          '256': '',
          '512': '',
        },
      },
      createdBy: {
        name,
        firstName,
        lastName,
        username,
        email,
        memberID: memberId,
        pointsGiven,
        totalPointsGiven,
        memberState,
        role,
        department,
        image: profile.image,
        jobTitle: '',
        profileStatus: status,
        pronouns: '',
      },
      claimButtonText,
      showTurnOffSettings: true,
      interactionSettings: interactionSettings || {
        hideReplies: false,
        hideReactions: false,
      },
      launchedAt: '',
      claimsRemaining: -1,
      selfClaimDetails: sharingRules?.include.some((item) => {
        return item.field === 'member'
          ? isCurrentUserParticipant({ values: item.values, memberId })
          : false;
      })
        ? {
            claimLimit: individualClaimLimit,
            totalEarnedPoints: 0,
            claims: [],
          }
        : null,
      canEdit: true,
      reactions: [],
      proof: {
        isRequired: false,
        content: [],
      },
      commentsCount: 0,
      hasReplyDraft: false,
      replySummary: {
        count: 0,
        respondentsCount: 0,
        lastRepliedAt: '',
        initialRespondents: [],
      },
      // TODO: Fix the type to not include flow details
      responseId: 'some-response-id',
      responses: [],
      flow: {
        name: '',
        flowId: '',
        version: 0,
        kind: '',
        icon: {
          kind: '',
          value: '',
        },
      },
      visibility: VisibilityTypes.Private,
      isPrivate: true,
      canDelete: true,
      kind: '',
      isBoostAllowed: false,
      respondent: null,
    },
    _meta: {
      type: 'STANDALONE',
      entityId: id,
      title,
      collectionsRef: [],
      state: launchNow ? 'ACTIVE' : 'QUEUED',
      points,
      claimButtonText,
      description: '',
      createdByRef: {
        department: '',
        entityId: memberId,
        fullName: '',
        jobTitle: '',
        location: '',
        managerIds: [''],
        profileImageUrl: '',
        profileStatus: status,
        roles: role,
        collectionsRef: [],
        isManager: false,
        aboutMe: 'string',
        email,
        state: MemberState.Active,
        firstName,
        lastName,
        username,
      },
    },
    highlight: {
      '_meta.title': [],
      '_meta.description': [],
      '_meta.createdByRef:fullName': [],
      content: [],
      searchableContent: [],
    },
  } satisfies ChallengeSearchResult;
};
