import { TextStyle } from '@assembly-web/ui';
import type { ReactElement } from 'react';

export const SearchFilter = (props: {
  label: string;
  icon?: ReactElement;
  filterResult: string;
}) => {
  const { label, icon, filterResult } = props;
  return (
    <div className="flex items-center gap-1">
      <TextStyle variant="sm-medium" className="text-gray-9">
        {label}
      </TextStyle>
      {icon}
      <TextStyle variant="sm-regular" className="text-gray-9">
        {filterResult}
      </TextStyle>
    </div>
  );
};
