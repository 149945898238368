import type {
  Announcement,
  AnnouncementFeedDetails,
  AnnouncementsApiResponse,
} from '@assembly-web/services';
import {
  type AnnouncementFormValues,
  GlobalFilterOption,
  useToastStore,
} from '@assembly-web/ui';
import type { InfiniteQueryObserverResult } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  trackAnnouncementAction,
  trackCardAction,
} from '../services/analytics';
import { useEndAnnouncementMutation } from './useEndAnnouncementMutation';
import { useGetAnnouncementById } from './useGetAnnouncementById';
import { useGetAnnouncementQuery } from './useGetAnnouncementQuery';
import { useGlobalFilter } from './useGlobalFilter';
import { useSaveAnnouncementsMutation } from './useSaveAnnouncementsMutation';

const messages = defineMessages({
  announcementSaveSuccessMessage: {
    defaultMessage: 'Success! Your announcement is live',
    id: 'fWmGrn',
  },
  announcementSaveErrorMessage: {
    defaultMessage: 'Something went wrong. Please try again',
    id: '4YYziq',
  },
  announcementEndedToastLabel: {
    defaultMessage: 'Success! Announcement ended',
    id: 'Thq4YZ',
  },
});

export const defaultAnnouncementFormValues: AnnouncementFormValues = {
  title: '',
  description: '',
  backgroundColor: 'gray',
  allowPostReplies: false,
  isMentionsEnabled: false,
  allowPostReactions: false,
  isPushNotificationEnabled: true,
  isEmailNotificationEnabled: true,
};

type openAnnouncementPayload = {
  flowId?: string;
  responseId: string;
  allowReplies: boolean;
  allowReactions: boolean;
  activeAnnouncement?: AnnouncementFeedDetails;
};

type AnnouncementMessageType =
  | 'END_ANNOUNCEMENT'
  | 'EDIT_ANNOUNCEMENT'
  | 'CREATE_ANNOUNCEMENT'
  | 'OPEN_ANNOUNCEMENT_INSIGHTS_MODAL';

type AnnouncementPayload = {
  flowId: string;
  responseId: string;
  hideReplies: boolean;
  hideReactions: boolean;
  activeAnnouncement: {
    color: string;
    title: string;
    viewsCount: number;
    description: string;
    showInsights: boolean;
    announcementId: string;
    notificationSettings: {
      email: boolean;
      mobile: boolean;
    };
  };
};

function useAnnouncements() {
  const queryClient = useQueryClient();

  const filter = useGlobalFilter();
  const isMyFeedPage = filter === GlobalFilterOption.All || !filter;

  const [announcementFormValues, setAnnouncementFormValues] =
    useState<AnnouncementFormValues>(defaultAnnouncementFormValues);

  const [flowId, setFlowId] = useState<string | null>(null);
  const [responseId, setResponseId] = useState<string | null>(null);
  const [isAnnouncementsModalOpen, setIsAnnouncementsModalOpen] =
    useState(false);
  const [selectedAnnouncementId, setSelectedAnnouncementId] =
    useState<string>('');

  const { formatMessage } = useIntl();

  const {
    isError,
    isSuccess,
    isLoading: isSaving,
    mutate: announcementsMutate,
  } = useSaveAnnouncementsMutation();

  const { mutate: endAnnouncement } = useEndAnnouncementMutation();
  const { data: selectedAnnouncement, isLoading: isLoadingResponseSettings } =
    useGetAnnouncementById(announcementFormValues.announcementId ?? '');

  const { showSuccessToast, showErrorToast } = useToastStore();

  const { isLoading, data, error, hasNextPage, fetchNextPage } =
    useGetAnnouncementQuery({
      enabled: isMyFeedPage,
    });

  const announcements: Announcement[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.reduce<Announcement[]>(
      (acc, page) => [...acc, ...page.data.data],
      []
    );
  }, [data]);

  useEffect(() => {
    if (selectedAnnouncement) {
      setAnnouncementFormValues({
        ...announcementFormValues,
        allowPostReplies: !selectedAnnouncement.responseSettings?.hideReplies,
        allowPostReactions:
          !selectedAnnouncement.responseSettings?.hideReactions,
      });
    }
    // This is intentional. We want to update the form values when the selected announcement changes
  }, [selectedAnnouncement]);

  useEffect(() => {
    if (isSuccess) {
      setIsAnnouncementsModalOpen(false);
      showSuccessToast(formatMessage(messages.announcementSaveSuccessMessage));
    }
  }, [formatMessage, isSuccess, showSuccessToast]);

  useEffect(() => {
    if (isError) {
      showErrorToast(formatMessage(messages.announcementSaveErrorMessage));
    }
  }, [formatMessage, isError, showErrorToast]);

  useEffect(() => {
    function handleMessage({
      data: { type, payload },
    }: MessageEvent<{
      type: AnnouncementMessageType;
      payload: AnnouncementPayload;
    }>) {
      switch (type) {
        case 'CREATE_ANNOUNCEMENT':
          {
            openAnnouncementModal({
              flowId: payload.flowId,
              responseId: payload.responseId,
              allowReplies: !payload.hideReplies,
              allowReactions: !payload.hideReactions,
            });
          }
          break;
        case 'EDIT_ANNOUNCEMENT':
          {
            openAnnouncementModal({
              flowId: payload.flowId,
              responseId: payload.responseId,
              allowReplies: !payload.hideReplies,
              allowReactions: !payload.hideReactions,
              activeAnnouncement: {
                ...payload.activeAnnouncement,
                notificationSettings: {
                  email: payload.activeAnnouncement.notificationSettings.email,
                  push: payload.activeAnnouncement.notificationSettings.mobile,
                },
              },
            });
          }

          break;
        case 'END_ANNOUNCEMENT':
          {
            endAnnouncement({
              flowId: payload.flowId,
              responseId: payload.responseId,
              announcementId: payload.activeAnnouncement.announcementId,
            });

            showSuccessToast(
              formatMessage(messages.announcementEndedToastLabel)
            );

            trackCardAction('endAnnouncementClicked', {
              flowId: payload.flowId,
              responseId: payload.responseId,
              announcementId: payload.activeAnnouncement.announcementId,
            });
          }
          break;
        case 'OPEN_ANNOUNCEMENT_INSIGHTS_MODAL':
          {
            setSelectedAnnouncementId(
              payload.activeAnnouncement.announcementId
            );
          }
          break;
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [endAnnouncement, formatMessage, showSuccessToast]);

  const handleOnSaveAnnouncementClick = async (
    value: AnnouncementFormValues
  ) => {
    if (!responseId) {
      return;
    }

    trackAnnouncementAction(
      value.announcementId ? 'announcementChangesSaved' : 'announcementCreated',
      {
        ...value,
      }
    );

    announcementsMutate({
      entity: {
        flowId,
        id: responseId,
        type: 'RESPONSE',
      },
      type: 'RESPONSE',
      color: value.backgroundColor,
      title: value.title.trim(),
      description: value.description.trim(),
      announcementId: value.announcementId ?? undefined,
      notificationSettings: {
        email: value.isEmailNotificationEnabled,
        mobile: value.isPushNotificationEnabled,
      },
      responseSettings: {
        hideReplies: !value.allowPostReplies,
        hideReactions: !value.allowPostReactions,
      },
    });
  };

  const openAnnouncementModal = (payload: openAnnouncementPayload) => {
    queryClient.removeQueries([
      'announcement',
      announcementFormValues.announcementId,
    ]);
    setFlowId(payload.flowId ?? null);
    setResponseId(payload.responseId);
    setAnnouncementFormValues({
      ...defaultAnnouncementFormValues,
      allowPostReplies: payload.allowReplies,
      allowPostReactions: payload.allowReactions,
    });

    if (payload.activeAnnouncement) {
      setAnnouncementFormValues({
        ...defaultAnnouncementFormValues,
        allowPostReplies: payload.allowReplies,
        title: payload.activeAnnouncement.title,
        allowPostReactions: payload.allowReactions,
        backgroundColor: payload.activeAnnouncement.color,
        description: payload.activeAnnouncement.description,
        announcementId: payload.activeAnnouncement.announcementId,
        isEmailNotificationEnabled:
          payload.activeAnnouncement.notificationSettings.email,
        isPushNotificationEnabled:
          payload.activeAnnouncement.notificationSettings.push,
      });
    }

    setIsAnnouncementsModalOpen(true);

    if (payload.activeAnnouncement) {
      trackCardAction('editAnnouncementClicked', {
        responseId: payload.responseId,
        ...payload.activeAnnouncement,
      });
    } else {
      trackCardAction('shareAsAnnouncementClicked', {
        responseId: payload.responseId,
      });
    }
  };

  const closeAnnouncementModal = () => {
    setIsAnnouncementsModalOpen(false);
  };

  const closeAnnouncementsInsightsModal = () => {
    setSelectedAnnouncementId('');
  };

  const handleOnGetHelpButtonClick = () => {
    window.open(
      `https://www.joinassembly.com/articles/creating-announcements`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  const openAnnouncementsInsightsModal = (announcementId: string) => {
    setSelectedAnnouncementId(announcementId);

    trackCardAction('viewInsightsClicked', {
      responseId,
      announcementId,
    });
  };

  return {
    error,
    isSaving,
    isLoading,
    announcements,
    openAnnouncementModal,
    announcementFormValues,
    closeAnnouncementModal,
    isAnnouncementsModalOpen,
    openAnnouncementsInsightsModal,
    closeAnnouncementsInsightsModal,
    disableResponseSettings:
      (isLoadingResponseSettings &&
        Boolean(announcementFormValues.announcementId)) ||
      selectedAnnouncement?.responseSettings?.canEditResponseSettings === false,
    handleOnGetHelpButtonClick,
    handleOnSaveAnnouncementClick,
    hasMoreAnnouncements: hasNextPage,
    fetchMoreAnnouncements: fetchNextPage,
    selectedAnnouncementId,
  };
}

type AnnouncementContextType = ReturnType<typeof useAnnouncements>;

export const AnnouncementsContext = createContext<AnnouncementContextType>({
  error: null,
  isSaving: false,
  isLoading: false,
  announcements: [],
  hasMoreAnnouncements: false,
  disableResponseSettings: false,
  isAnnouncementsModalOpen: false,
  openAnnouncementModal: () => {},
  closeAnnouncementModal: () => {},
  fetchMoreAnnouncements: async () => {
    return {} as InfiniteQueryObserverResult<AnnouncementsApiResponse>;
  },
  handleOnGetHelpButtonClick: () => {},
  closeAnnouncementsInsightsModal: () => {},
  announcementFormValues: defaultAnnouncementFormValues,
  handleOnSaveAnnouncementClick: async () => {},
  openAnnouncementsInsightsModal: () => {},
  selectedAnnouncementId: '',
});

export const AnnouncementsProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const value = useAnnouncements();

  return (
    <AnnouncementsContext.Provider value={value}>
      {children}
    </AnnouncementsContext.Provider>
  );
};
