import type { ChallengeDetailsAPIResponse } from '@assembly-web/services';
import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { getChallengeDetailsQuery } from '../queries/getChallengeDetailsQuery';

export function useGetChallengeDetailsQuery({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}): UseQueryResult<ChallengeDetailsAPIResponse> {
  return useQuery(getChallengeDetailsQuery(id, enabled));
}
