import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

export type JobStatus = 'QUEUED' | 'PROCESSING' | 'ERROR' | 'SUCCESS';
type JobDetail = {
  data: {
    id: string;
    status: JobStatus;
    name: string;
  };
};

export function useJobStatusQuery({
  jobId,
  options,
}: {
  jobId: string;
  options?: UseQueryOptions<JobDetail>;
}) {
  return useQuery<JobDetail>({
    queryKey: ['jobStatus', jobId],
    queryFn: async () => {
      const { data } = await assemblyAPI.get<JobDetail>(
        APIEndpoints.jobStatus(jobId)
      );
      return data;
    },
    ...options,
  });
}
