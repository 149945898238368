import { TextStyle } from '@assembly-web/ui';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../services/analytics';
import {
  convertMarkDownResponseToHTML,
  removeBlinkingCursor,
} from '../../../../../services/dora';
import { DoraFeedbackSection } from '../../../../dora/DoraFeedbackSection';
import { DoraLoadingLabel } from '../../../../dora/DoraLoadingLabel';
import { DoraAvatar } from '../../../shared/dora/DoraAvatar';
import { DoraMessageBubble } from '../../../shared/dora/DoraMessageBubble';
import { DoraSingleMessage } from '../../../shared/dora/DoraSingleMessage';
import { TryAgainMessage } from '../../../shared/dora/TryAgainMessage';
import type {
  EditSettingsStoreType,
  EventSourceStoreType,
  FormSettingsStoreType,
} from '../../types';

const messages = defineMessages({
  answeringNow: {
    defaultMessage: 'Answering now',
    id: '8h6QdP',
  },
  error: {
    defaultMessage:
      "I'm sorry! I had an issue summarizing this flow. Please wait for a short while and try again.",
    id: 'jtseDa',
  },
  tryAgain: {
    defaultMessage: 'Try again to get my summary',
    id: 'gJwA9Z',
  },
  feedbackLabel: {
    defaultMessage: 'How was this summary?',
    id: 'O0XHIP',
  },
});

export const SummaryResponse = (props: {
  children: ReactNode;
  editSettingsStore: EditSettingsStoreType;
  eventSourceStore: EventSourceStoreType;
  flowId: string;
  formSettingsStore: FormSettingsStoreType;
}) => {
  const {
    children: feedbackMessage,
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { fetchFlowSummary, useEventSourceStore },
    flowId,
    formSettingsStore: {
      useCustomFocusSetting,
      useCustomTimePeriodSetting,
      useFocusTypeSetting,
      useIndividualBlocksSetting,
      useIndividualRespondentsSetting,
      usePredefinedTimePeriodSetting,
      useSettingsConfirmationSetting,
    },
  } = props;

  const { formatMessage } = useIntl();
  const streamCalled = useRef(false);

  const isCustomFocusSet = useCustomFocusSetting((store) => store.isSet);
  const customFocus = useCustomFocusSetting((store) => store.value);
  const customTimePeriod = useCustomTimePeriodSetting((store) => store.value);
  const focusType = useFocusTypeSetting((store) => store.value);
  const blocks = useIndividualBlocksSetting((store) => store.value);

  const isRespondentsSet = useIndividualRespondentsSetting(
    (store) => store.isSet
  );

  const respondents = useIndividualRespondentsSetting((store) => store.value);

  const predefinedTimePeriod = usePredefinedTimePeriodSetting(
    (store) => store.value
  );

  const isSeen = useSettingsConfirmationSetting.getState().isAnswerSeen;

  const markSeen = useSettingsConfirmationSetting(
    (store) => store.markAnswerSeen
  );

  const isConfirmed = useSettingsConfirmationSetting((store) => store.value);

  const isFetchingSummary = useEventSourceStore(
    (store) => store.isFetchingSummary
  );

  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  const isSummaryFetched = useEventSourceStore(
    (store) => store.isSummaryFetched
  );

  const promptId = useEventSourceStore((store) => store.promptId);

  const streamedHtmlData = useEventSourceStore((store) => {
    if (!store.streamedData) {
      return null;
    }

    const html = convertMarkDownResponseToHTML(store.streamedData) as string;

    if (store.isSummaryFetched) {
      return removeBlinkingCursor(html);
    }
    return html;
  });

  const streamedMarkdownData = useEventSourceStore(
    (store) => store.streamedData
  );

  const summaryErrorMsg = useEventSourceStore((store) => store.summaryErrorMsg);

  const requestFlowSummary = useCallback(() => {
    if (
      focusType &&
      isCustomFocusSet &&
      isRespondentsSet &&
      predefinedTimePeriod
    ) {
      const blockIds = (blocks ?? []).map((block) => block.blockId);

      const endDate =
        customTimePeriod?.endDate ?? predefinedTimePeriod.endDate ?? '';

      const startDate =
        customTimePeriod?.startDate ?? predefinedTimePeriod.startDate ?? '';

      const respondedBy = (respondents ?? []).map(
        (respondent) => respondent.memberId
      );

      fetchFlowSummary({
        blockIds,
        customFocus,
        endDate,
        flowId,
        focusType,
        respondedBy,
        startDate,
      });
    }
  }, [
    blocks,
    customFocus,
    customTimePeriod,
    fetchFlowSummary,
    flowId,
    focusType,
    isCustomFocusSet,
    isRespondentsSet,
    respondents,
    predefinedTimePeriod,
  ]);

  useEffect(() => {
    if (!streamCalled.current && isConfirmed && !isSummaryFetched) {
      streamCalled.current = true; // Make sure that stream is invoked once on mount only.
      markSeen();
      requestFlowSummary();
    }
  }, [isConfirmed, isSummaryFetched, markSeen, requestFlowSummary]);

  useEffect(() => {
    // For the edge case that the drawer gets reset to a different flow while the it is still open
    if (!isConfirmed) {
      streamCalled.current = false;
    }
  }, [isConfirmed]);

  const isEditing = useEditSettingsStore((store) =>
    Boolean(store.settingEditing)
  );

  if (isEditing) {
    return null;
  }

  if (isFetchingSummary) {
    return (
      <DoraSingleMessage shouldAnimate={!isSeen}>
        <DoraLoadingLabel label={formatMessage(messages.answeringNow)} />
      </DoraSingleMessage>
    );
  }

  let content;

  if (isSummaryError) {
    content = (
      <div className="flex">
        <ExclamationTriangleIcon className="mr-2 mt-1 h-4 w-4 text-error-6" />
        <TextStyle className="flex-1 text-error-6" variant="base-regular">
          {summaryErrorMsg ?? formatMessage(messages.error)}
        </TextStyle>
      </div>
    );
  } else if (streamedHtmlData) {
    content = (
      <TextStyle as="span" className="space-y-2" html={streamedHtmlData} />
    );
  }

  return (
    content && (
      <>
        <DoraAvatar shouldAnimate={!isSeen} />
        <div className="col-start-2 flex flex-col gap-y-1 self-start">
          <DoraMessageBubble
            footer={
              Boolean(promptId) && (
                <DoraFeedbackSection
                  feedbackLabel={formatMessage(messages.feedbackLabel)}
                  markdownResponse={streamedMarkdownData}
                  onCopy={() => {
                    trackDoraAction('flowSummaryCopied');
                  }}
                  promptId={promptId}
                />
              )
            }
          >
            {content}
          </DoraMessageBubble>
          {!isSummaryError && feedbackMessage}
        </div>
        {Boolean(isSummaryError) && (
          <TryAgainMessage
            onClick={requestFlowSummary}
            shouldAnimateOnMount={!isSeen}
          >
            {formatMessage(messages.tryAgain)}
          </TryAgainMessage>
        )}
      </>
    )
  );
};
