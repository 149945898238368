import { type Nullable, userAuthStore } from '@assembly-web/services';
import { config } from '@assembly-web/services';

import { waitForRedirection } from '../../../services/waitForRedirection';

export async function redirectToAmazonAdminForAuthorization(
  isAdmin: boolean,
  assemblyId: Nullable<string>
) {
  const searchParams = new URLSearchParams(window.location.search);
  const amazonCallbackURI =
    searchParams.get('amazon_callback_uri') ??
    userAuthStore.getState().amazonCallbackURI;
  const amazonState =
    searchParams.get('amazon_state') ?? userAuthStore.getState().amazonState;
  const applicationId =
    searchParams.get('applicationId') ?? userAuthStore.getState().amazonState;

  if (amazonCallbackURI && amazonState && applicationId) {
    if (assemblyId) {
      const adminRedirectURL = `${config.domains.adminApp}/rewards/amazon?amazon_callback_uri=${amazonCallbackURI}&amazon_state=${amazonState}&applicationId=${applicationId}`;
      const nonAdminRedirectURL = `${amazonCallbackURI}?amazon_state=${amazonState}`;
      window.location.href = isAdmin ? adminRedirectURL : nonAdminRedirectURL;
      userAuthStore.getState().clearAmazonAuthWorkflowSession();
      return await waitForRedirection();
    } else {
      userAuthStore
        .getState()
        .setAmazonAuthWorkflowDetails(
          amazonCallbackURI,
          amazonState,
          applicationId
        );
    }
  }
}
