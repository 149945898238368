import type { DoraReportingCategory } from '@assembly-web/services';
import { DropdownCategoryOption } from '@assembly-web/ui';
import {
  ChatBubbleLeftRightIcon,
  GiftIcon,
  TableCellsIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

export function ConversationCardIcon({
  reportType,
}: {
  reportType: DoraReportingCategory;
}) {
  const commonClasses = 'h-7 w-7 rounded-lg';

  return reportType === 'recognition' ? (
    <DropdownCategoryOption.Icon
      className={twMerge(commonClasses, 'from-error-2 to-magenta-3')}
    >
      <TrophyIcon className="h-5 w-5 stroke-magenta-9 stroke-2" />
    </DropdownCategoryOption.Icon>
  ) : reportType === 'reward_redemption' ? (
    <DropdownCategoryOption.Icon
      className={twMerge(commonClasses, 'from-purple-3 to-primary-2')}
    >
      <GiftIcon className="h-5 w-5 stroke-purple-9 stroke-2" />
    </DropdownCategoryOption.Icon>
  ) : reportType === 'flows' ? (
    <DropdownCategoryOption.Icon
      className={twMerge(commonClasses, 'from-cyan-3 to-blue-2')}
    >
      <ChatBubbleLeftRightIcon className="h-5 w-5 stroke-cyan-9 stroke-2" />
    </DropdownCategoryOption.Icon>
  ) : (
    <DropdownCategoryOption.Icon
      className={twMerge(commonClasses, 'from-success-3 to-cyan-2')}
    >
      <TableCellsIcon className="h-5 w-5 stroke-success-9 stroke-2" />
    </DropdownCategoryOption.Icon>
  );
}
