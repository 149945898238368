import { Button, TextStyle } from '@assembly-web/ui';
import { RocketLaunchIcon } from '@heroicons/react/24/outline';

export function UpgradeButton({
  onUpgradeButtonClick,
  text,
}: {
  onUpgradeButtonClick: () => void;
  text: string;
}) {
  return (
    <Button onClick={onUpgradeButtonClick} size="small" variation="upgrade">
      <RocketLaunchIcon className="h-4 w-4 stroke-2" />
      <TextStyle as="span" variant="sm-medium">
        {text}
      </TextStyle>
    </Button>
  );
}
