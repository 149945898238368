import { logger, type Nullable, type ReplyData } from '@assembly-web/services';
import { isAxiosError } from 'axios';
import { useRef } from 'react';

import {
  useAddChallengeReplyMutation,
  useUpdateChallengeReplyMutation,
} from '../../../../hooks/challenges/useChallengeRepliesQueries';
import { useSaveDraftsMutation } from '../../../../hooks/replies/useSaveDraftsMutation';
import {
  useGetRepliesDrafts,
  useSetRepliesDrafts,
} from '../../../../hooks/useGetRepliesDrafts';
import { trackReplyDrawerError } from '../../../../services/analytics';

export function useSaveChallenge({
  challengeId,
  selectedReply,
}: {
  challengeId: string;
  selectedReply?: Nullable<ReplyData>;
}) {
  const setDraft = useSetRepliesDrafts(challengeId);
  const savedDraft = useRef<string | null>(null);

  const { data: draftData, isLoading: isDraftsLoading } = useGetRepliesDrafts(
    challengeId,
    {
      enabled: !selectedReply,
    }
  );

  const { mutate: saveDraft } = useSaveDraftsMutation({
    onError: (error: unknown) => {
      const errorObj = isAxiosError(error) ? error : undefined;
      trackReplyDrawerError({
        errorType: errorObj?.response?.data?.message ?? 'Error on save draft',
      });

      logger.error('Draft Save Error', {}, errorObj);
    },
  });

  const { mutate: addReply, isError: isAddReplyError } =
    useAddChallengeReplyMutation({ challengeId });

  const { mutate: updateReply, isError: isUpdateReplyError } =
    useUpdateChallengeReplyMutation({ challengeId });

  return {
    setDraft,
    addReply,
    saveDraft,
    draftData,
    savedDraft,
    updateReply,
    isAddReplyError,
    isDraftsLoading,
    isUpdateReplyError,
  };
}
