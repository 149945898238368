import { PostCardActions } from '@assembly-web/ui';

export function getURLForAssemblyPostActions({
  action,
  slug,
  id,
  flowId,
  responseId,
}: {
  action: PostCardActions;
  slug: string;
  id?: string;
  flowId?: string;
  responseId?: string;
}) {
  switch (action) {
    case PostCardActions.PersonClicked: {
      if (id) {
        return `/a/${slug}/user/${id}`;
      }
      return undefined;
    }

    case PostCardActions.FlowNameClicked: {
      return `/a/${slug}/flows/${flowId}`;
    }

    case PostCardActions.RepliesBarClicked: {
      if (flowId === 'recognition') {
        return `/a/${slug}/post/flow/recognition?showReplies=true&showDirectMessage=false&postId=${responseId}&type=recognition`;
      } else {
        return `/a/${slug}/post/flow/flows?flowId=${flowId}&responseId=${responseId}&showReplies=true&type=flow`;
      }
    }

    case PostCardActions.TaskClicked:
      return `/a/${slug}/flows/${flowId}?showReplies=false&showDirectMessage=false&flowId=${flowId}&responseId=${responseId}&type=flow&taskId=${id}`;
  }
}
