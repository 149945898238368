import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { Option } from '../../../../shared/Option';
import { OptionsSelectorInput } from '../../../../shared/OptionsSelectorInput';
import { type FocusOptionsInputProps, FocusType } from '../../../../types';

const messages = defineMessages({
  custom: {
    defaultMessage: 'I want to focus on something else',
    id: 'lHVMts',
  },
  segmentByBlock: {
    defaultMessage: 'Segment summary by question',
    id: '4vOjok',
  },
  segmentByRespondent: {
    defaultMessage: 'Segment summary by respondent',
    id: 'gqd0xT',
  },
  sentiment: {
    defaultMessage: 'Analyze sentiment',
    id: 'E7mlA1',
  },
});

export const Selector = (props: FocusOptionsInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore,
    formSettingsStore: { useCustomFocusSetting, useFocusTypeSetting },
  } = props;

  const { formatMessage } = useIntl();
  const isCustomFocusSet = useCustomFocusSetting((store) => store.isSet);
  const markCustomFocusSet = useCustomFocusSetting((store) => store.markSet);
  const isNewInputSeen = useFocusTypeSetting.getState().isInputSeen;
  const markNewInputSeen = useFocusTypeSetting((store) => store.markInputSeen);
  const setFocusType = useFocusTypeSetting((store) => store.setValue);
  const value = useFocusTypeSetting((store) => store.value);

  const isEditing = useEditSettingsStore(
    (store) => store.focusType.shouldRequestInput
  );

  const isEditInputSeen = useEditSettingsStore.getState().focusType.isInputSeen;

  const markEditInputSeen = useEditSettingsStore(
    (store) => store.markInputSeen
  );

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const exitEditMode = useEditSettingsStore((store) => store.exitEditMode);
  const markEditedSet = useEditSettingsStore((store) => store.markSet);
  const editValue = useEditSettingsStore((store) => store.setValue);

  const requestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  useEffect(() => {
    if (!isNewInputSeen && !isEditing) {
      markNewInputSeen();
    } else if (!isEditInputSeen && isEditing) {
      markEditInputSeen('focusType');
    }
  }, [
    isEditInputSeen,
    isEditing,
    isNewInputSeen,
    markEditInputSeen,
    markNewInputSeen,
  ]);

  useEffect(() => {
    return () => {
      if (!isCustomFocusSet && value && value !== FocusType.Custom) {
        setTimeout(() => {
          markCustomFocusSet();
        }, timeouts.showNextMessage);
      }
    };
  }, [isCustomFocusSet, markCustomFocusSet, value]);

  const handleCancel = () => {
    trackDoraAction('summaryInputEditCanceled');
    exitEditMode();
  };

  const handleSentimentClick = () => {
    if (value === FocusType.Sentiment) {
      exitEditMode();
    } else {
      setFocusType(FocusType.Sentiment);

      if (isEditing) {
        useCustomFocusSetting.setState({ value: undefined });
        eventSourceStore.reset();
        exitEditMode();
      }
    }

    trackDoraAction(
      isEditing ? 'summaryInputEditConfirmed' : 'summaryFocusSelected',
      {
        doraSummaryInput: 'Analyze sentiment',
      }
    );
  };

  const handleSegmentByRespondentClick = () => {
    if (value === FocusType.SegmentByRespondent) {
      exitEditMode();
    } else {
      setFocusType(FocusType.SegmentByRespondent);

      if (isEditing) {
        useCustomFocusSetting.setState({ value: undefined });
        eventSourceStore.reset();
        exitEditMode();
      }
    }

    trackDoraAction(
      isEditing ? 'summaryInputEditConfirmed' : 'summaryFocusSelected',
      {
        doraSummaryInput: 'Segment summary by respondent',
      }
    );
  };

  const handleSegmentByBlockClick = () => {
    if (value === FocusType.SegmentByBlock) {
      exitEditMode();
    } else {
      setFocusType(FocusType.SegmentByBlock);

      if (isEditing) {
        useCustomFocusSetting.setState({ value: undefined });
        eventSourceStore.reset();
        exitEditMode();
      }
    }

    trackDoraAction(
      isEditing ? 'summaryInputEditConfirmed' : 'summaryFocusSelected',
      {
        doraSummaryInput: 'Segment summary by question',
      }
    );
  };

  const handleCustomClick = () => {
    if (value === FocusType.Custom) {
      exitEditMode();

      trackDoraAction('summaryInputEditConfirmed', {
        doraSummaryInput: 'I want to focus on something else',
      });
    } else if (isEditing) {
      editValue('focusType', FocusType.Custom);
      requestEdit('focusType', false);

      setTimeout(() => {
        setSettingEditing('customFocus');
        markEditedSet('focusType');
      }, timeouts.showNextMessage);
    } else {
      setFocusType(FocusType.Custom);

      trackDoraAction('summaryFocusSelected', {
        doraSummaryInput: 'I want to focus on something else',
      });
    }
  };

  return (
    <OptionsSelectorInput
      isEditing={isEditing}
      onCancel={handleCancel}
      shouldAnimateOnMount={
        (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen)
      }
    >
      <Option key={FocusType.Sentiment} onClick={handleSentimentClick}>
        {formatMessage(messages.sentiment)}
      </Option>
      <Option
        key={FocusType.SegmentByRespondent}
        onClick={handleSegmentByRespondentClick}
      >
        {formatMessage(messages.segmentByRespondent)}
      </Option>
      <Option
        key={FocusType.SegmentByBlock}
        onClick={handleSegmentByBlockClick}
      >
        {formatMessage(messages.segmentByBlock)}
      </Option>
      <Option key={FocusType.Custom} onClick={handleCustomClick}>
        {formatMessage(messages.custom)}
      </Option>
    </OptionsSelectorInput>
  );
};
