import { browserUtils, config, userAuthStore } from '@assembly-web/services';
import { DisabledCookiesNotice, useDeviceInfo } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useLegacyPathStore } from './modules/discover/stores/legacyPathStore';
import { onBoardingState } from './modules/onboarding/stores/onboardingStore';
import { trackPageViewAction } from './services/analytics';

const messages = defineMessages({
  cookieBannerHeader: {
    defaultMessage:
      'Please enable cookies in your browser to log into Assembly',
    id: 'oCWe9W',
  },
  cookieBannerHelpText: {
    defaultMessage:
      'Your browser currently has all cookies disabled. You won’t be able to log in until they’re enabled.',
    id: 's8Jmp7',
  },
  mainAppDefaultTitle: {
    defaultMessage: 'Assembly - Empower your team',
    id: '8j6QKd',
  },
});

export function Root({ children }: { children?: ReactNode }) {
  const { setDeviceDetails } = onBoardingState.getState();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    trackPageViewAction(location.pathname);
  }, [location]);
  const source = searchParams.get('source');

  const isMobileApp = userAuthStore.getState().isLoginViaMobileApp;

  const { boot, shutdown } = useIntercom();

  const isMobileView = useDeviceInfo().deviceType === 'mobile';

  const shouldShowIntercom =
    !isMobileApp && !isMobileView && import.meta.env.PROD;

  useEffect(() => {
    if (shouldShowIntercom) {
      boot({
        actionColor: 'blue',
        hideDefaultLauncher: true,
      });
    } else {
      shutdown();
    }
  }, [boot, shutdown, shouldShowIntercom]);

  useEffect(() => {
    if (source?.toLowerCase() === 'MS_TEAMS') {
      setDeviceDetails('ms_teams');
    } else if (browserUtils.isMobile) {
      setDeviceDetails(browserUtils.isiPhone ? 'ios' : 'android');
    } else {
      setDeviceDetails('web');
    }
  }, [setDeviceDetails, source]);

  const { formatMessage } = useIntl();

  const { previousUrls, setPreviousUrls } = useLegacyPathStore();

  useEffect(() => {
    if (
      location.pathname !== previousUrls[previousUrls.length - 1] &&
      !location.pathname.includes('/template/')
    ) {
      const url = new URL(config.domains.legacyApp);

      url.pathname = location.pathname;
      searchParams.forEach((value, key) => url.searchParams.set(key, value));
      setPreviousUrls([
        ...previousUrls,
        url.toString().replace(url.origin, ''),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, searchParams, setPreviousUrls]);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Assembly"
        defaultTitle={formatMessage(messages.mainAppDefaultTitle)}
      ></Helmet>
      {children}
      {!navigator.cookieEnabled && (
        <DisabledCookiesNotice
          title={formatMessage(messages.cookieBannerHeader)}
          body={formatMessage(messages.cookieBannerHelpText)}
        />
      )}
    </HelmetProvider>
  );
}
