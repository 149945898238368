import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { Option } from '../../../../shared/Option';
import { OptionsSelectorInput } from '../../../../shared/OptionsSelectorInput';
import { BlocksGroup, type BlocksGroupInputProps } from '../../../../types';

const messages = defineMessages({
  entireResponse: {
    defaultMessage: 'Summarize entire response',
    id: 'ccN3lR',
  },
  specificResponses: {
    defaultMessage: 'Choose specific answers',
    id: 'O8gtpE',
  },
});

export const Selector = (props: BlocksGroupInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore,
    formSettingsStore: {
      useBlocksGroupSetting,
      useIndividualBlocksSetting,
      repromptTimePeriod,
    },
  } = props;

  const { formatMessage } = useIntl();
  const isNewInputSeen = useBlocksGroupSetting.getState().isInputSeen;

  const markNewInputSeen = useBlocksGroupSetting(
    (store) => store.markInputSeen
  );

  const setBlocksGroup = useBlocksGroupSetting((store) => store.setValue);
  const value = useBlocksGroupSetting((store) => store.value);
  const isBlocksSet = useIndividualBlocksSetting((store) => store.isSet);
  const markBlocksSet = useIndividualBlocksSetting((store) => store.markSet);

  const isEditing = useEditSettingsStore(
    (store) => store.blocksGroup.shouldRequestInput
  );

  const requestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const isEditInputSeen =
    useEditSettingsStore.getState().blocksGroup.isInputSeen;

  const markEditInputSeen = useEditSettingsStore(
    (store) => store.markInputSeen
  );

  const markEditedSet = useEditSettingsStore((store) => store.markSet);
  const editValue = useEditSettingsStore((store) => store.setValue);

  useEffect(() => {
    if (!isNewInputSeen && !isEditing) {
      markNewInputSeen();
    } else if (!isEditInputSeen && isEditing) {
      markEditInputSeen('blocksGroup');
    }
  }, [
    isEditInputSeen,
    isEditing,
    isNewInputSeen,
    markEditInputSeen,
    markNewInputSeen,
  ]);

  useEffect(() => {
    return () => {
      if (!isBlocksSet && value === BlocksGroup.FullResponse) {
        setTimeout(() => {
          markBlocksSet();
        }, timeouts.showNextMessage);
      }
    };
  }, [isBlocksSet, markBlocksSet, value]);

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const exitEditMode = useEditSettingsStore((store) => store.exitEditMode);

  const handleCancel = () => {
    trackDoraAction('summaryInputEditCanceled');
    exitEditMode();
  };

  const handleAllBlocksOptionClick = () => {
    if (value === BlocksGroup.FullResponse) {
      exitEditMode();

      trackDoraAction('summaryInputEditConfirmed', {
        doraSummaryInput: 'Summarize entire response',
      });
    } else if (isEditing) {
      useIndividualBlocksSetting.getState().reset();
      markBlocksSet();
      repromptTimePeriod();

      setBlocksGroup(BlocksGroup.FullResponse);
      eventSourceStore.reset();
      exitEditMode();

      trackDoraAction('summaryInputEditConfirmed', {
        doraSummaryInput: 'Summarize entire response',
      });
    } else {
      setBlocksGroup(BlocksGroup.FullResponse);

      trackDoraAction('blocksToIncludeSelected', {
        doraSummaryInput: 'Summarize entire response',
      });
    }
  };

  const handleIndividualBlocksClick = () => {
    if (value === BlocksGroup.Individual) {
      exitEditMode();
    } else if (isEditing) {
      editValue('blocksGroup', BlocksGroup.Individual);
      requestEdit('blocksGroup', false);

      setTimeout(() => {
        setSettingEditing('individualBlocks');
        markEditedSet('blocksGroup');
      }, timeouts.showNextMessage);
    } else {
      setBlocksGroup(BlocksGroup.Individual);

      trackDoraAction('blocksToIncludeSelected', {
        doraSummaryInput: 'Choose specific answers',
      });
    }
  };

  return (
    <OptionsSelectorInput
      isEditing={isEditing}
      onCancel={handleCancel}
      shouldAnimateOnMount={
        (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen)
      }
    >
      <Option onClick={handleAllBlocksOptionClick}>
        {formatMessage(messages.entireResponse)}
      </Option>
      <Option onClick={handleIndividualBlocksClick}>
        {formatMessage(messages.specificResponses)}
      </Option>
    </OptionsSelectorInput>
  );
};
