import { getUserDetailsQuery } from '@assembly-web/services';
import type { QueryClient } from '@tanstack/react-query';
import { redirect } from 'react-router-dom';

export function userDetailsLoader(queryClient: QueryClient) {
  return async function userDetailsLoader() {
    // we need this for magic link flow
    try {
      const {
        assembly: {
          workspaceSlug: { name, shortCode },
        },
      } = await queryClient.fetchQuery(getUserDetailsQuery());
      return redirect(`/join/${name}-${shortCode}/user-details`);
    } catch (error) {
      return redirect('/login?error=server_error');
    }
  };
}
