import {
  APIEndpoints,
  type FlowPostResponse,
  type ImportantActivitiesApiResponse,
  type UserFeedApiResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import {
  type InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from './useSearchIndex';

type UpdateAssemblyPostNotificationsPreferencePayload = {
  cardId?: string;
  flowId: string;
  responseId: string;
  postType: 'POST' | 'RESPONSE';
  type: 'SUBSCRIBE' | 'UNSUBSCRIBE';
};

export function useUpdateAssemblyPostNotificationMutationQuery() {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: UpdateAssemblyPostNotificationsPreferencePayload) => {
      const { flowId, responseId, type, postType } = payload;
      await assemblyAPI.put(
        postType === 'POST'
          ? APIEndpoints.updatePostNotification(responseId)
          : APIEndpoints.updateFlowResponseNotification(flowId, responseId),
        {
          type,
        }
      );
    },
    {
      onMutate: async (
        payload: UpdateAssemblyPostNotificationsPreferencePayload
      ) => {
        const { cardId, type, postType } = payload;
        const queryCacheKey = ['importantCards'];
        const previousCards =
          queryClient.getQueryData<
            InfiniteData<ImportantActivitiesApiResponse>
          >(queryCacheKey);

        if (previousCards) {
          const updatedActivities = produce(previousCards, (draft) => {
            draft.pages.forEach((page) => {
              page.data.data = page.data.data.map((card) => {
                if (
                  card.cardId === cardId &&
                  postType === 'POST' &&
                  (card.type === 'POST_MENTIONS' ||
                    card.type === 'OWNER_POST_REPLY' ||
                    card.type === 'POST_THREAD_REPLIES' ||
                    card.type === 'BIRTHDAY_CELEBRATION_ACTIVITY' ||
                    card.type === 'ANNIVERSARY_CELEBRATION_ACTIVITY')
                ) {
                  card.entity.isMuted = type === 'UNSUBSCRIBE';
                }
                if (
                  card.cardId === cardId &&
                  postType === 'RESPONSE' &&
                  (card.type === 'RESPONSE_MENTIONS' ||
                    card.type === 'OWNER_RESPONSE_REPLY' ||
                    card.type === 'RESPONSE_THREAD_REPLIES')
                ) {
                  card.entity.isMuted = type === 'UNSUBSCRIBE';
                }
                return card;
              });
            });
          });

          queryClient.setQueryData(queryCacheKey, updatedActivities);
        }

        return { previousCards };
      },
      onSuccess: async (_, payload) => {
        const queryCache = queryClient.getQueryCache();
        const postQueryKey = [
          'assemblyFlowPost',
          payload.flowId,
          payload.responseId,
        ];
        const previousPost =
          queryClient.getQueryData<FlowPostResponse>(postQueryKey);
        if (previousPost) {
          const updatedPost = produce(previousPost, (draft) => {
            if (draft.isMuted) {
              draft.isMuted = undefined;
            } else {
              draft.isMuted = true;
            }
          });

          queryClient.setQueryData(postQueryKey, updatedPost);
        }

        const userFeedKeys = queryCache
          .findAll(['userFeed'])
          .map((query) => query.queryKey);
        const searchFeedKeys = queryCache
          .findAll(['searchResults'])
          .map((query) => query.queryKey);

        userFeedKeys.forEach((key) => {
          const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
            queryClient.getQueryData(key);
          if (userFeedData) {
            const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
              ...userFeedData,
              pages: userFeedData.pages.map((page) => {
                return {
                  ...page,
                  data: page.data.map((post) => {
                    if (
                      post.type === 'post' &&
                      post.cardDetails?.post?.postID === payload.responseId
                    ) {
                      return produce(post, (draft) => {
                        if (draft.cardDetails?.post) {
                          draft.cardDetails.post.isMuted =
                            payload.type === 'UNSUBSCRIBE' ? true : undefined;
                        }
                      });
                    }

                    if (
                      post.type === 'response' &&
                      post.cardDetails?.responseId === payload.responseId
                    ) {
                      return produce(post, (draft) => {
                        if (draft.cardDetails) {
                          draft.cardDetails.isMuted =
                            payload.type === 'UNSUBSCRIBE' ? true : undefined;
                        }
                      });
                    }
                    return post;
                  }),
                };
              }),
            };
            queryClient.setQueryData(key, mutatedUserFeedData);
          }
        });

        searchFeedKeys.forEach((queryKey) => {
          const searchData =
            queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
              queryKey
            );
          if (searchData) {
            const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
              ...searchData,
              pages: searchData.pages.map((page) => {
                return {
                  ...page,
                  data: {
                    ...page.data,
                    data: page.data.data.map((result) => {
                      if (
                        result.type === 'post' &&
                        result.cardDetails?.post?.postID === payload.responseId
                      ) {
                        return produce(result, (draft) => {
                          if (draft.cardDetails?.post) {
                            draft.cardDetails.post.isMuted =
                              payload.type === 'UNSUBSCRIBE' ? true : undefined;
                          }
                        });
                      }

                      if (
                        result.type === 'response' &&
                        result.cardDetails?.responseId === payload.responseId
                      ) {
                        return produce(result, (draft) => {
                          if (draft.cardDetails) {
                            draft.cardDetails.isMuted =
                              payload.type === 'UNSUBSCRIBE' ? true : undefined;
                          }
                        });
                      }
                      return result;
                    }),
                  },
                };
              }),
            };
            queryClient.setQueryData(queryKey, mutatedSearchData);
          }
        });
      },
      onError: (_, __, context) => {
        const queryCacheKey = ['importantCards'];
        queryClient.setQueryData(queryCacheKey, context?.previousCards);
      },
    }
  );
}
