import { AppLink, TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { AnimateChat } from '../../../../../shared/dora/AnimateChat';
import { timeouts } from '../../../../../shared/dora/constants';
import { DoraMessageBubble } from '../../../../../shared/dora/DoraMessageBubble';
import type { RespondentsGroupQuestionProps } from '../../../../types';

const messages = defineMessages({
  anonymous: {
    defaultMessage:
      "Since this is an anonymous flow, I'll include all anonymous responses in my summary.",
    id: 'R8T4v1',
  },
});

export const Message = (props: RespondentsGroupQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    flowHref,
    flowTitle,
    formSettingsStore: {
      useIndividualRespondentsSetting,
      useRespondentsGroupSetting,
    },
    hasOnlyAnonymousResponses,
    isQuestionSkipped,
  } = props;

  const { formatMessage } = useIntl();

  const isRespondentsSet = useIndividualRespondentsSetting(
    (store) => store.isSet
  );

  const markRespondentsSet = useIndividualRespondentsSetting(
    (store) => store.markSet
  );

  const setRespondents = useIndividualRespondentsSetting(
    (store) => store.setValue
  );

  const isSeen = useRespondentsGroupSetting.getState().isQuestionSeen;

  const markSeen = useRespondentsGroupSetting(
    (store) => store.markQuestionSeen
  );

  const setShouldRequestInput = useRespondentsGroupSetting(
    (store) => store.setShouldRequestInput
  );

  const shouldRequestInput = useRespondentsGroupSetting(
    (store) => store.shouldRequestInput
  );

  const respondentsGroupValue = useRespondentsGroupSetting(
    (store) => store.value
  );

  useEffect(() => {
    if (!isSeen) {
      markSeen();
    }
  }, [isSeen, markSeen]);

  useEffect(() => {
    if (!respondentsGroupValue && !shouldRequestInput && !isQuestionSkipped) {
      setTimeout(() => {
        setShouldRequestInput(true);
      }, timeouts.showInput);
    }
  }, [
    hasOnlyAnonymousResponses,
    isQuestionSkipped,
    respondentsGroupValue,
    setShouldRequestInput,
    shouldRequestInput,
  ]);

  useEffect(() => {
    if (!isRespondentsSet && hasOnlyAnonymousResponses) {
      setTimeout(() => {
        markRespondentsSet();
      }, timeouts.showNextMessage);
    }
  }, [
    hasOnlyAnonymousResponses,
    isRespondentsSet,
    markRespondentsSet,
    setRespondents,
  ]);

  const isEditingOtherSetting = useEditSettingsStore((store) =>
    Boolean(store.settingEditing)
  );

  let content;

  if (isQuestionSkipped) {
    content = (
      <DoraMessageBubble isFaded={isEditingOtherSetting}>
        <TextStyle className="text-gray-9">
          {formatMessage(messages.anonymous)}
        </TextStyle>
      </DoraMessageBubble>
    );
  } else {
    content = (
      <DoraMessageBubble isFaded={isEditingOtherSetting}>
        <TextStyle className="text-gray-9">
          <FormattedMessage
            defaultMessage="Whose posts within <flow>{flowTitle}</flow> would you like to summarize?"
            id="Ra+0rs"
            values={{
              flow: (text) => <AppLink to={flowHref}>{text}</AppLink>,
              flowTitle,
            }}
          />
        </TextStyle>
      </DoraMessageBubble>
    );
  }

  return <AnimateChat shouldAnimateOnMount={!isSeen}>{content}</AnimateChat>;
};
