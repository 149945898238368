import { PromptWithChoiceBox } from '@assembly-web/ui';
import { useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { DistributionConfiguration } from './DistributionConfiguration';

const messages = defineMessages({
  teammatesRespondToFlowLabel: {
    defaultMessage: 'When do you want your teammates to respond to this flow?',
    id: '9EkU2w',
  },
  anyTimeLabel: {
    defaultMessage: 'Anytime',
    id: 'AwhX1o',
  },
  anyTimeDescription: {
    defaultMessage: 'Free flowing communication',
    id: 'd3M3Fx',
  },
  onADeadlineLabel: {
    defaultMessage: 'On a deadline',
    id: '/aBpxY',
  },
  onADeadlineDescription: {
    defaultMessage: 'Restrict time periods',
    id: 'TlMz3X',
  },
});

export function Distributions() {
  const { formatMessage } = useIntl();

  const choices = useMemo(
    () => [
      {
        id: 'anytime',
        label: formatMessage(messages.anyTimeLabel),
        description: formatMessage(messages.anyTimeDescription),
      },
      {
        id: 'onADeadline',
        label: formatMessage(messages.onADeadlineLabel),
        description: formatMessage(messages.onADeadlineDescription),
      },
    ],
    [formatMessage]
  );

  // ToDo: This will be moved to React Context
  // After implementing the subsequent steps
  const [selectedOption, setSelectedOption] = useState(choices[0].id);

  return (
    <DistributionConfiguration.Root>
      <PromptWithChoiceBox.Root>
        <PromptWithChoiceBox.Row>
          <PromptWithChoiceBox.Prompt>
            {formatMessage(messages.teammatesRespondToFlowLabel)}
          </PromptWithChoiceBox.Prompt>
          <PromptWithChoiceBox.Choices
            choices={choices}
            defaultValue={selectedOption}
            onValueChange={setSelectedOption}
          />
        </PromptWithChoiceBox.Row>
      </PromptWithChoiceBox.Root>
    </DistributionConfiguration.Root>
  );
}
