import type { FilterPayload } from '../queries/getTemplatesQuery';

type EventSourceType = {
  isEmbeddedPublicly: boolean;
};

type FilterOptions = {
  searchParam: string;
};

export function getEventSource({ isEmbeddedPublicly }: EventSourceType) {
  return isEmbeddedPublicly ? 'public' : 'private';
}

export function getParsedFilterOptions(options: FilterOptions) {
  const { searchParam } = options;
  const searchParams = new URLSearchParams(searchParam);

  const searchString = searchParams.toString();
  const isEmbeddedInApp = searchString.includes('isEmbedded');
  const isEmbeddedPublicly = searchString.includes('publicView');

  const parseCommaSeparatedSearchParam = (key: string) =>
    searchParams.get(key)?.toString().split(',') ?? [];

  const receivedCategories = parseCommaSeparatedSearchParam('categories');
  const receivedJobTypes = parseCommaSeparatedSearchParam('jobTypes');
  const receivedUseCases = parseCommaSeparatedSearchParam('useCases');
  const receivedActions = parseCommaSeparatedSearchParam('actions');
  const receivedSelectedFilters =
    parseCommaSeparatedSearchParam('selectedFilters');

  const filter: FilterPayload = {};

  const isCategoriesFilterApplied = receivedCategories.length > 0;
  const isJobTypesFilterApplied = receivedJobTypes.length > 0;
  const isUseCaseFilterApplied = receivedUseCases.length > 0;
  const isActionFilterApplied = receivedActions.length > 0;

  const hasFilterApplied =
    isCategoriesFilterApplied ||
    isJobTypesFilterApplied ||
    isUseCaseFilterApplied ||
    isActionFilterApplied;

  if (isCategoriesFilterApplied) {
    filter.categories = receivedCategories;
  }

  if (isJobTypesFilterApplied) {
    filter.jobTypes = receivedJobTypes;
  }

  if (isUseCaseFilterApplied) {
    filter.useCases = receivedUseCases;
  }

  if (isActionFilterApplied) {
    filter.actions = receivedActions;
  }

  return {
    filter,
    isEmbeddedInApp,
    hasFilterApplied,
    isEmbeddedPublicly,
    isActionFilterApplied,
    isUseCaseFilterApplied,
    isJobTypesFilterApplied,
    receivedSelectedFilters,
    isCategoriesFilterApplied,
  };
}
