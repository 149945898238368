import type { DoraChatHistoryAPIResponse } from '@assembly-web/services';
import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import { getDoraChatHistoryQuery } from '../../queries/getDoraChatHistoryQuery';

export function useGetDoraChatHistoryQuery(
  threadId: string,
  options?: UseQueryOptions<DoraChatHistoryAPIResponse>
): UseQueryResult<DoraChatHistoryAPIResponse> {
  return useQuery(getDoraChatHistoryQuery(threadId, options));
}
