import type { AssistantHistoryItem } from '@assembly-web/services';
import { config, useUserDetails } from '@assembly-web/services';
import { IconButton, OpenTextEditor, Tooltip } from '@assembly-web/ui';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ScrollToBottom from 'react-scroll-to-bottom';
import { twMerge } from 'tailwind-merge';

import { useGetDoraChatHistoryQuery } from '../../../hooks/dora/useChatHistoryQuery';
import { useGetPlanFeaturesQuery } from '../../../hooks/useGetPlanFeaturesQuery';
import { trackDiscoverAction } from '../../../services/analytics';
import {
  convertMarkDownResponseToHTML,
  isDoraIncludedInWorkspacePlan,
} from '../../../services/dora';
import { isAdminMember } from '../../../services/member';
import { useAskDoraStore } from '../../../stores/useAskDoraStore';
import { BottomActionSheet } from '../shared/dora/BottomActionSheet';
import { ChatHistoryError } from '../shared/dora/ChatHistoryError';
import { ChatHistoryLoading } from '../shared/dora/ChatHistoryLoading';
import { MemberChatMessage } from '../shared/dora/MemberChatMessage';
import type { AskDoraDrawer as AskDoraDrawerProps } from '../types';
import { DoraChatMessage } from './DoraChatMessage';
import { FilterBottomSheet } from './FilterBottomSheet';
import { QALimit } from './hooks/useDoraEventSource';
import { InfoTooltip } from './InfoTooltip';
import { QuestionAnswerBlock } from './QuestionAnswerBlock';
import { SuggestedQuestionsModule } from './SuggestedQuestionsModule';

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Ask something',
    id: 'Yh9fcS',
  },
  maxHitPlaceholder: {
    defaultMessage: `You have hit your message limit for a single DoraAI conversation. If you'd like to keep chatting, please open a new conversation.`,
    id: 'DX9xTm',
  },
  infoLabel: {
    defaultMessage: 'Info',
    id: 'we4Lby',
  },
  prompt: {
    defaultMessage: 'Try one of these questions next:',
    id: 'ubXs48',
  },
});

const getFilterCount = (data: AssistantHistoryItem) => {
  if (
    'toolCalls' in data &&
    data.toolCalls?.[0]?.function.name === 'ask_question'
  ) {
    const args = data.toolCalls[0]?.function.arguments;
    const argTypes = Object.keys(args);

    let filterTypes = [];
    if (
      argTypes.includes('dateRange') &&
      args.dateRange &&
      Object.keys(args.dateRange).length > 0
    ) {
      filterTypes.push('dateRange');
    }
    if (argTypes.includes('fileTypes')) {
      filterTypes.push('fileTypes');
    }

    return filterTypes.length;
  }
};

export const AskDoraDrawerBody = (props: AskDoraDrawerProps) => {
  const { formatMessage } = useIntl();

  const { isLoading: isPlanFeaturesLoading, data: planFeatureDetails } =
    useGetPlanFeaturesQuery();

  const threadId = useAskDoraStore((store) => store.getThreadId());

  const {
    isError: isChatHistoryError,
    isLoading: isChatHistoryLoading,
    data: chatHistoryData,
    refetch: refetchChatHistory,
  } = useGetDoraChatHistoryQuery(threadId, { enabled: Boolean(threadId) });

  const currentBlock = useAskDoraStore((store) => store.getCurrentBlockState());
  const suggestedQuestions = useAskDoraStore((store) =>
    store.getSuggestedQuestions()
  );
  const addQuestionAnswerBlock = useAskDoraStore(
    (store) => store.addQuestionAnswerBlock
  );

  const [value, setValue] = useState('');
  const [isFilterSheetOpen, setIsFilterSheetOpen] = useState(false);
  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const onEditFiltersClick = () => {
    setIsFilterSheetOpen(true);
    setIsTooltipActive(false);
  };

  const handleIsTooltipActive = (val?: boolean) => {
    if (val !== undefined) {
      setIsTooltipActive(val);
    } else {
      setIsTooltipActive(!isTooltipActive);
    }
  };

  const handleSuggestionClick = (question: string) => {
    addQuestionAnswerBlock({ question });
  };

  const handleOnUpgradeButtonClick = useCallback(() => {
    window.open(
      `${config.domains.adminApp}/billing/account/`,
      '_self',
      'noopener,noreferrer'
    );
  }, []);

  const handleOnSubmit = () => {
    addQuestionAnswerBlock({ question: value });
    setValue('');
  };

  useEffect(() => {
    if ((chatHistoryData?.length ?? 0) === 0 && isFilterSheetOpen) {
      handleFilterSheetClose();
    }
  }, [chatHistoryData?.length, isFilterSheetOpen]);

  const { data: userDetails } = useUserDetails();
  if (!userDetails) {
    return;
  }
  const isAdmin = isAdminMember(userDetails.member);
  const isDoraIncludedInPlan =
    isDoraIncludedInWorkspacePlan(planFeatureDetails);

  const numBlocks =
    (chatHistoryData?.filter((item) => item.role === 'user') ?? []).length +
      (currentBlock.question ? 1 : 0) || 0;
  const initialQuestion =
    Array.isArray(chatHistoryData) && chatHistoryData.length === 0;
  const maxQAReached = numBlocks >= QALimit;
  let refetchPromptId;
  let existingFileTypes;
  let existingDateRange;

  const handleFilterSheetClose = () => {
    setIsFilterSheetOpen(false);
  };

  const onAdjustAnswerSettingsClick = () => {
    if (!isFilterSheetOpen) {
      trackDiscoverAction('answerSettingsClicked');
    }
    setIsFilterSheetOpen(!isFilterSheetOpen);
  };

  if (isChatHistoryLoading && threadId) {
    return <ChatHistoryLoading />;
  } else if (isChatHistoryError) {
    return <ChatHistoryError onTryAgain={refetchChatHistory} />;
  }

  return (
    <>
      <div className="flex h-full flex-col justify-end bg-gray-1">
        <ScrollToBottom
          className="overflow-auto"
          followButtonClassName="hidden"
          initialScrollBehavior="auto"
        >
          <section className="grid grid-cols-[max-content_minmax(0,1fr)] gap-x-2 gap-y-4 overflow-auto p-6">
            {chatHistoryData?.map((block, index) => {
              if (block.role === 'user') {
                let functionArgs;
                if (chatHistoryData[index + 1]?.role === 'assistant') {
                  const associatedAssistantBlock = chatHistoryData[index + 1];
                  if (
                    'toolCalls' in associatedAssistantBlock &&
                    associatedAssistantBlock.toolCalls?.[0]?.function.name ===
                      'ask_question'
                  ) {
                    functionArgs =
                      associatedAssistantBlock.toolCalls[0]?.function.arguments;
                  }
                }
                const isLastAnswer = index === chatHistoryData.length - 2;

                return (
                  <MemberChatMessage
                    key={`${props.id}-User-${index}`}
                    fullName={userDetails.member.profile.fullName}
                    image={userDetails.member.profile.image}
                    memberId={userDetails.member.memberId}
                    shouldAnimate={false}
                    notUpgraded={
                      !isDoraIncludedInPlan && !isPlanFeaturesLoading
                    }
                    tooltip={
                      isTooltipActive &&
                      (functionArgs?.fileTypes || functionArgs?.dateRange) ? (
                        <div className="absolute right-2 top-2">
                          <Tooltip
                            align="end"
                            side="bottom"
                            tooltipText={
                              <InfoTooltip
                                fileTypes={functionArgs.fileTypes}
                                dateRange={functionArgs.dateRange}
                                onEditFilters={
                                  isLastAnswer ? onEditFiltersClick : undefined
                                }
                              />
                            }
                            contentClassName="px-3 pt-2 pb-3 max-w-[320px]"
                          >
                            <IconButton
                              aria-label={formatMessage(messages.infoLabel)}
                              onTouchStart={(ev) => ev.stopPropagation()}
                              size="small"
                              variation="tertiaryLite"
                            >
                              <InformationCircleIcon className="h-4 w-4 text-gray-9" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : null
                    }
                    setIsTooltipActive={handleIsTooltipActive}
                  >
                    {block.content}
                  </MemberChatMessage>
                );
              } else {
                const isLastResponse = index === chatHistoryData.length - 1;
                const {
                  answerSources = [],
                  searchedSources = [],
                  toolCalls,
                  promptId,
                  content,
                } = block;
                if (isLastResponse) {
                  refetchPromptId = promptId;
                  existingFileTypes =
                    toolCalls?.[0]?.function.arguments.fileTypes;
                  existingDateRange =
                    toolCalls?.[0]?.function.arguments.dateRange;
                }

                return (
                  <DoraChatMessage
                    key={`${props.id}-Assistant-${index}`}
                    filterCount={
                      isLastResponse ? getFilterCount(block) : undefined
                    }
                    isAdmin={isAdmin}
                    isDoraIncludedInPlan={isDoraIncludedInPlan}
                    isPlanFeaturesLoading={isPlanFeaturesLoading}
                    onAdjustAnswerSettings={
                      isLastResponse && isDoraIncludedInPlan
                        ? onAdjustAnswerSettingsClick
                        : undefined
                    }
                    onRefetch={handleFilterSheetClose}
                    promptId={promptId}
                    htmlResponse={
                      convertMarkDownResponseToHTML(content, {
                        showCursor: false,
                      }) as string
                    }
                    markdownResponse={content}
                    notUpgraded={
                      !isDoraIncludedInPlan && !isPlanFeaturesLoading
                    }
                    sources={answerSources}
                    canSwapSource={
                      searchedSources.length > answerSources.length &&
                      Boolean(isLastResponse) &&
                      isDoraIncludedInPlan
                    }
                    searchedSources={searchedSources}
                  />
                );
              }
            })}
            {Boolean(currentBlock.question) && (
              <QuestionAnswerBlock
                initialQuestion={initialQuestion}
                isAdmin={isAdmin}
                isPlanFeaturesLoading={isPlanFeaturesLoading}
                onUpgradeButtonClick={handleOnUpgradeButtonClick}
                member={userDetails.member}
                metadata={initialQuestion ? props.data.metadata : undefined}
                staticResponse={currentBlock.staticResponse}
                threadId={threadId}
                refetchPromptId={currentBlock.refetchPromptId}
                dateRange={currentBlock.dateRange}
                documentId={currentBlock.documentId}
                fileTypes={currentBlock.fileTypes}
              />
            )}
          </section>
        </ScrollToBottom>
        {Boolean(isDoraIncludedInPlan) && (
          <BottomActionSheet shouldAnimateOnMount={false}>
            <div className="mx-6 my-2">
              <OpenTextEditor
                className={twMerge(
                  'max-h-[174px]',
                  maxQAReached && '!h-[72px]'
                )}
                isSubmitDisabled={
                  value.trim() === '' ||
                  currentBlock.isLoading ||
                  currentBlock.isStreaming
                }
                maxLength={500}
                onChange={(e) => setValue(e.target.value)}
                onSubmit={handleOnSubmit}
                placeholder={formatMessage(
                  maxQAReached
                    ? messages.maxHitPlaceholder
                    : messages.placeholder
                )}
                rows={1}
                shouldSubmitOnEnter
                value={value}
                disabled={maxQAReached}
                autoFocusInput
              />
            </div>
            <AnimatePresence>
              {suggestedQuestions.length > 0 &&
                Boolean(chatHistoryData?.length) &&
                !maxQAReached && (
                  <motion.div
                    animate={{ height: 'auto' }}
                    initial={{ height: 0 }}
                    exit={{ height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="px-6 py-2"
                  >
                    <SuggestedQuestionsModule
                      prompt={formatMessage(messages.prompt)}
                      onSuggestedQuestionClick={handleSuggestionClick}
                      suggestedQuestions={suggestedQuestions}
                      disabled={
                        currentBlock.isLoading || currentBlock.isStreaming
                      }
                    />
                  </motion.div>
                )}
            </AnimatePresence>
          </BottomActionSheet>
        )}
      </div>
      <AnimatePresence>
        {Boolean(isFilterSheetOpen) && (
          <FilterBottomSheet
            onClose={handleFilterSheetClose}
            promptId={refetchPromptId}
            existingFileTypes={existingFileTypes}
            existingDateRange={existingDateRange}
          />
        )}
      </AnimatePresence>
    </>
  );
};
