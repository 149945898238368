import type { SSOProvider } from '@assembly-web/services';
import { getProviderName } from '@assembly-web/services';
import type { ButtonProps } from '@assembly-web/ui';
import { Button } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { getLogoForSSOProvider } from '../services/sso';

type SSOButtonProps = {
  provider: SSOProvider;
  flow: 'login' | 'signup';
} & ButtonProps;

const messages = defineMessages({
  login: {
    defaultMessage: 'Log in with {provider}',
    id: 'BXwisy',
  },
  signup: {
    defaultMessage: 'Sign up with {provider}',
    id: 'aqubXm',
  },
});

export function SSOButton({ provider, flow, ...props }: SSOButtonProps) {
  const { formatMessage } = useIntl();

  const text = formatMessage(
    flow === 'login' ? messages.login : messages.signup,
    {
      provider: getProviderName(provider),
    }
  );

  return (
    <Button {...props} variation="secondaryLite">
      <img
        className="h-fit w-fit"
        src={getLogoForSSOProvider(provider)}
        role="presentation"
        alt=""
      />
      {text}
    </Button>
  );
}
