import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { DoraSingleMessage } from '../../../shared/dora/DoraSingleMessage';
import type { FormSettingsStoreType } from '../../types';

const messages = defineMessages({
  customize: {
    defaultMessage:
      "If I don't provide specific information in a format you like, you can always ask me to focus on something else! Ready?",
    id: 'NTBaMH',
  },
  ready: {
    defaultMessage: "Great! I'm ready to get started.",
    id: 'C9bxOz',
  },
});

export const ConfirmMessage = (props: {
  formSettingsStore: FormSettingsStoreType;
}) => {
  const {
    formSettingsStore: { useSettingsConfirmationSetting },
  } = props;

  const { formatMessage } = useIntl();
  const isSeen = useSettingsConfirmationSetting.getState().isQuestionSeen;

  const markSeen = useSettingsConfirmationSetting(
    (store) => store.markQuestionSeen
  );

  useEffect(() => {
    if (!isSeen) {
      markSeen();
    }
  }, [isSeen, markSeen]);

  return (
    <DoraSingleMessage shouldAnimate={!isSeen}>
      <TextStyle className="mb-6 text-gray-9">
        {formatMessage(messages.ready)}
      </TextStyle>
      <TextStyle className="text-gray-9">
        {formatMessage(messages.customize)}
      </TextStyle>
    </DoraSingleMessage>
  );
};
