import {
  APIEndpoints,
  assemblyAPI,
  type GetFlowTemplateResponse,
} from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

export function useGetTemplateDetailsById<TSelect>(
  templateId: string,
  options?: UseQueryOptions<GetFlowTemplateResponse, unknown, TSelect>
) {
  return useQuery<GetFlowTemplateResponse, unknown, TSelect>({
    ...options,
    retry: false,
    queryKey: ['templateDetails', templateId],
    queryFn: async () => {
      const response = await assemblyAPI.get<GetFlowTemplateResponse>(
        APIEndpoints.getTemplate(templateId)
      );

      return response.data;
    },
  });
}
