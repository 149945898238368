import { memo } from 'react';

import { DefaultBlockRow } from '../DefaultBlockRow';
import { Description } from '../Description';
import { Participants } from '../Participants';
import { SelectionRange } from '../SelectionRangeSettings';

export const PersonSelectionBlock = memo(function PersonSelectionBlock() {
  return (
    <DefaultBlockRow>
      <Description />
      <Participants />
      <SelectionRange />
    </DefaultBlockRow>
  );
});
