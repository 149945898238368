import type { AnnouncementsApiResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';

export function getAnnouncementsQuery(
  options?: UseInfiniteQueryOptions<AnnouncementsApiResponse>
): UseInfiniteQueryOptions<AnnouncementsApiResponse> {
  return {
    ...options,
    queryKey: ['announcements'],
    queryFn: async ({ pageParam = '' }) =>
      await assemblyAPI.get(APIEndpoints.getAnnouncements, {
        ...(pageParam ? { params: { cursor: pageParam } } : {}),
      }),
    getNextPageParam: (page: AnnouncementsApiResponse) =>
      page.data.metadata?.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page: AnnouncementsApiResponse) => {
      return page.data.metadata?.pagination.cursor.previous;
    },
  };
}
