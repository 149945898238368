import {
  config,
  getFeatureFlagTreatmentAsync,
  SplitNames,
} from '@assembly-web/services';

export enum GoogleReCaptchaActionTypes {
  SignUp = 'SIGNUP',
}

export const generateReCaptchaToken = async (
  action: GoogleReCaptchaActionTypes
) => {
  const treatment = await getFeatureFlagTreatmentAsync(
    SplitNames.GoogleRecaptcha
  );

  if (treatment === 'on') {
    return await window.grecaptcha.enterprise.execute(config.recaptchaSiteKey, {
      action,
    });
  }

  return null;
};
