import { TextStyle } from '@assembly-web/ui';
import { useEffect, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextButton } from '../../../../../../../../../components/TextButton';
import { trackDoraAction } from '../../../../../../../services/analytics';
import {
  ErrorBottomActionSheet,
  LoadingBottomActionSheet,
} from '../../../../../shared/dora/BottomActionSheet';
import { timeouts } from '../../../../../shared/dora/constants';
import { SelectableListInput } from '../../../../shared/SelectableListInput';
import {
  BlocksGroup,
  type IndividualBlocksInputProps,
} from '../../../../types';

type FlowBlockOption = {
  id: string;
  disabled?: boolean;
  position: number;
  type?: string;
  value: string;
};

const messages = defineMessages({
  notSummarizable: {
    defaultMessage: "({blockType} block, Can't be summarized)",
    id: '25VCSH',
  },
});

export const Selector = (props: IndividualBlocksInputProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore,
    flowBlocks,
    formSettingsStore: {
      repromptTimePeriod,
      useBlocksGroupSetting,
      useIndividualBlocksSetting,
    },
    isError,
    isLoading,
    onTryAgain,
  } = props;

  const { formatMessage, locale } = useIntl();
  const isNewInputSeen = useIndividualBlocksSetting.getState().isInputSeen;

  const markNewInputSeen = useIndividualBlocksSetting(
    (store) => store.markInputSeen
  );

  const setIndividualBlocks = useIndividualBlocksSetting(
    (store) => store.setValue
  );

  const blocksGroupValue = useBlocksGroupSetting((store) => store.value);
  const setBlocksGroup = useBlocksGroupSetting((store) => store.setValue);
  const [searchValue, setSearchValue] = useState('');

  const origSelectedBlocks = useIndividualBlocksSetting((store) => store.value);

  const blockNumMap = useMemo(() => {
    const map = new Map<string, number>();

    if (flowBlocks) {
      flowBlocks.forEach((block, index) => {
        map.set(block.blockId, index);
      });
    }

    return map;
  }, [flowBlocks]);

  const [selectedBlocks, setSelectedBlocks] = useState<FlowBlockOption[]>(
    (origSelectedBlocks ?? []).map((block, index) => ({
      id: block.blockId,
      position: blockNumMap.get(block.blockId) ?? index,
      value: block.title,
    }))
  );

  const allOptions = useMemo(() => {
    if (flowBlocks) {
      const numberFormatter = new Intl.NumberFormat(locale);

      return flowBlocks.reduce<FlowBlockOption[]>((all, block, index) => {
        if (
          block.title
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        ) {
          all.push({
            id: block.blockId,
            disabled: block.type === 'GIF',
            position: index,
            type: block.type,
            value: `${numberFormatter.format(index + 1)}. ${block.title}`,
          });
        }
        return all;
      }, []);
    }
    return [];
  }, [flowBlocks, locale, searchValue]);

  const isEditing = useEditSettingsStore(
    (store) => store.individualBlocks.shouldRequestInput
  );

  const exitEditMode = useEditSettingsStore((store) => store.exitEditMode);

  const handleDoneClick = (chosenBlocks: FlowBlockOption[]) => {
    if (chosenBlocks.length === 0) {
      return;
    }

    setSelectedBlocks(chosenBlocks);

    const blocksSortedByPosition = chosenBlocks.sort(
      (a, b) => a.position - b.position
    );

    setIndividualBlocks(
      blocksSortedByPosition.map((block) => ({
        blockId: block.id,
        title: block.value,
      }))
    );

    if (isEditing) {
      const origChosenBlocks = new Set(
        origSelectedBlocks?.map((b) => b.blockId)
      );

      const hasNewBlocksChosen =
        chosenBlocks.length !== origChosenBlocks.size ||
        chosenBlocks.some((b) => !origChosenBlocks.has(b.id));

      if (hasNewBlocksChosen) {
        eventSourceStore.reset();
        repromptTimePeriod();
      }

      if (blocksGroupValue !== BlocksGroup.Individual) {
        setBlocksGroup(BlocksGroup.Individual);
        setTimeout(exitEditMode, timeouts.hideInput);
      } else {
        exitEditMode();
      }
    }

    trackDoraAction(
      isEditing ? 'summaryInputEditConfirmed' : 'blocksIncludedConfirmed',
      {
        doraSummaryInput: blocksSortedByPosition.map((block) => block.value),
      }
    );
  };

  const isEditInputSeen =
    useEditSettingsStore.getState().individualBlocks.isInputSeen;

  const markEditInputSeen = useEditSettingsStore(
    (store) => store.markInputSeen
  );

  useEffect(() => {
    if (!isNewInputSeen && !isEditing) {
      markNewInputSeen();
    } else if (!isEditInputSeen && isEditing) {
      markEditInputSeen('individualBlocks');
    }
  }, [
    isEditInputSeen,
    isEditing,
    isNewInputSeen,
    markEditInputSeen,
    markNewInputSeen,
  ]);

  const shouldAnimateOnMount =
    (isEditing && !isEditInputSeen) || (!isEditing && !isNewInputSeen);

  const handleCancel = () => {
    trackDoraAction('summaryInputEditCanceled');
    exitEditMode();
  };

  const intermediaryProps = {
    className: 'h-[328px]',
    isEditing,
    onCancel: handleCancel,
    shouldAnimateOnMount,
  };

  if (isLoading) {
    return <LoadingBottomActionSheet {...intermediaryProps} />;
  }

  if (isError) {
    return (
      <ErrorBottomActionSheet {...intermediaryProps}>
        <FormattedMessage
          defaultMessage="Sorry, I had an issue fetching the list of blocks for this flow. Please <button>try again</button>."
          id="PlO6vV"
          values={{
            button: (text) => (
              <TextButton
                className="!text-gray-8"
                onClick={onTryAgain}
                underline
              >
                {text}
              </TextButton>
            ),
          }}
        />
      </ErrorBottomActionSheet>
    );
  }

  return (
    <SelectableListInput<FlowBlockOption>
      isEditing={isEditing}
      onCancel={handleCancel}
      onClearAllClick={() => setSelectedBlocks([])}
      onDoneClick={handleDoneClick}
      onSearchChange={setSearchValue}
      options={allOptions}
      renderOption={(option) => (
        <TextStyle
          className={twMerge(
            'flex flex-row overflow-hidden',
            option.disabled && 'text-gray-7'
          )}
        >
          <TextStyle as="span" className="flex-1 truncate">
            {option.value}
          </TextStyle>
          {Boolean(option.disabled) && (
            <TextStyle as="span" className="ml-0.5">
              {formatMessage(messages.notSummarizable, {
                blockType: option.type,
              })}
            </TextStyle>
          )}
        </TextStyle>
      )}
      selectedOptions={selectedBlocks}
      shouldAnimateOnMount={shouldAnimateOnMount}
    />
  );
};
