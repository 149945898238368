import type { AxiosError } from 'axios';

export const getErrorMessage = (
  { response }: AxiosError<{ message: string; body: string }>,
  customErrorMessage?: string
) => {
  if (response?.status === 409 && response.data.message) {
    return response.data.message;
  }
  if (response?.status !== 500 && response?.data.body) {
    return response.data.body;
  }
  if (response?.status !== 500 && response?.data.message) {
    return response.data.message;
  }
  return customErrorMessage;
};
