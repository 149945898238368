import type { CriteriaItemProps } from '@assembly-web/ui';
import { useCallback } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';

type AccordionType = 'blocks' | 'settings';

type EditorMiscState = {
  flows: Record<
    string,
    | {
        expandedBlockId: string | null;
        expandedAccordionValue: AccordionType;
        permissions: CriteriaItemProps[] | null;
      }
    | undefined
  >;
  showSwitchTypeConfirmationModal: boolean;
};

type EditorMiscActions = {
  setExpandedBlockId: (id: string, blockId: string | null) => void;
  setShowSwitchTypeConfirmationModal: (show: boolean) => void;
  setExpandedAccordionValue: (id: string, value: AccordionType) => void;
  setPermissions: (id: string, permissions: CriteriaItemProps[] | null) => void;
};

export const useEditorMiscState = create<EditorMiscState & EditorMiscActions>()(
  immer((set) => ({
    flows: {},
    showSwitchTypeConfirmationModal: true as boolean,
    setExpandedBlockId(id, blockId) {
      set((state) => {
        if (!(id in state.flows)) {
          state.flows[id] = {
            expandedBlockId: null,
            expandedAccordionValue: 'blocks',
            permissions: null,
          };
        }
        const flow = state.flows[id];
        if (flow) {
          flow.expandedBlockId = blockId;
        }
      });
    },
    setShowSwitchTypeConfirmationModal(show) {
      set((state) => {
        state.showSwitchTypeConfirmationModal = !show;
      });
    },
    setExpandedAccordionValue(id, value) {
      set((state) => {
        if (!(id in state.flows)) {
          state.flows[id] = {
            expandedBlockId: null,
            expandedAccordionValue: 'blocks',
            permissions: null,
          };
        }
        const flow = state.flows[id];
        if (flow) {
          flow.expandedAccordionValue = value;
        }
      });
    },
    setPermissions(id, permissions) {
      set((state) => {
        if (!(id in state.flows)) {
          state.flows[id] = {
            expandedBlockId: null,
            expandedAccordionValue: 'blocks',
            permissions: null,
          };
        }
        const flow = state.flows[id];
        if (!flow) {
          return;
        }
        flow.permissions = permissions;
      });
    },
  }))
);

export const useExpandedBlockId = (id: string) => {
  return useEditorMiscState(
    (state) => state.flows[id]?.expandedBlockId ?? null
  );
};

export function useSetExpandedBlockId(): (
  id: string,
  blockId: string | null
) => void;
export function useSetExpandedBlockId(
  id: string
): (blockId: string | null) => void;
export function useSetExpandedBlockId(id?: string) {
  const setExpandedBlockId = useEditorMiscState(
    (state) => state.setExpandedBlockId
  );

  return useCallback(
    (id1: string | null, id2?: string | null) => {
      if (
        typeof id === 'undefined' &&
        typeof id1 === 'string' &&
        typeof id2 === 'string'
      ) {
        setExpandedBlockId(id1, id2);
      } else if (typeof id === 'string') {
        setExpandedBlockId(id, id1);
      } else {
        throw new Error('Invalid arguments');
      }
    },
    [id, setExpandedBlockId]
  );
}

export const useSetSwitchTypeConfirmationModal = () => {
  const setShowSwitchTypeConfirmationModal = useEditorMiscState(
    (state) => state.setShowSwitchTypeConfirmationModal
  );

  return useCallback(
    (show: boolean) => setShowSwitchTypeConfirmationModal(show),
    [setShowSwitchTypeConfirmationModal]
  );
};

export const useSetExpandedAccordionValue = (id: string) => {
  const setExpandedAccordionValue = useEditorMiscState(
    (state) => state.setExpandedAccordionValue
  );

  return useCallback(
    (value: AccordionType) => setExpandedAccordionValue(id, value),
    [id, setExpandedAccordionValue]
  );
};

export const useShowSwitchTypeConfirmationModal = () => {
  return useEditorMiscState(
    useShallow((state) => state.showSwitchTypeConfirmationModal)
  );
};

export const useExpandedAccordionValue = (id: string) => {
  return useEditorMiscState(
    (state) => state.flows[id]?.expandedAccordionValue ?? 'blocks'
  );
};

export const usePermissions = (id: string) => {
  return useEditorMiscState((state) => state.flows[id]?.permissions ?? null);
};

export const useSetPermissions = (id: string) => {
  const setPermissions = useEditorMiscState((state) => state.setPermissions);

  return useCallback(
    (mutate: Parameters<typeof setPermissions>['1']) =>
      setPermissions(id, mutate),
    [id, setPermissions]
  );
};

export const useClearPermissions = (id: string) => {
  const setPermissions = useEditorMiscState((state) => state.setPermissions);

  return useCallback(() => setPermissions(id, null), [id, setPermissions]);
};
