import type { GlobalFilterOption } from '@assembly-web/ui';
import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useState,
} from 'react';

import { defaultAggregates } from '../constants';

type Aggregations = Record<GlobalFilterOption, number>;

type AggregationsContext = {
  aggregations: Aggregations;
  setAggregationsData: Dispatch<SetStateAction<Aggregations>>;
};

export const DiscoverPageFiltersAggregationContext =
  createContext<AggregationsContext>({
    aggregations: defaultAggregates,
    setAggregationsData: () => {},
  });

export function DiscoverPageFiltersProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [aggregations, setAggregationsData] =
    useState<Aggregations>(defaultAggregates);

  return (
    <DiscoverPageFiltersAggregationContext.Provider
      value={{ aggregations, setAggregationsData }}
    >
      {children}
    </DiscoverPageFiltersAggregationContext.Provider>
  );
}
