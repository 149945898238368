import type { DrawerConfig } from '../configuration';
import type {
  ChallengeData,
  ChallengesDrawer as ChallengesDrawerProp,
} from '../types';
import { ChallengeDrawerBody } from './ChallengeDrawerBody';
import { ChallengeDrawerHeader } from './ChallengeDrawerHeader';
import { ChallengesDrawerOverflowCard } from './ChallengesDrawerOverflowCard';
import { ChallengesDrawer } from './index';

export const challengesDrawerConfiguration = {
  drawer: ChallengesDrawer,
  body: ChallengeDrawerBody,
  allowMultipleInstance: false,
  header: ChallengeDrawerHeader,
  resetOnCreate: true,
  overflowCard: ChallengesDrawerOverflowCard,
  shouldConfirmOnClose: () => {
    return false;
  },
  getId: (data) => {
    return data.challengeId;
  },
  onClose: () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('commentId');
    searchParams.delete('challengeId');

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${searchParams.toString()}`
    );
  },
} satisfies DrawerConfig<ChallengesDrawerProp, ChallengeData>;
