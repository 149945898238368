import {
  templatesGalleryBgImage,
  templatesGalleryBgMobImage,
} from '@assembly-web/assets';
import { Button } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import type { OnFilterClickProps } from '../types';
import { CalendarIcon, ConversationIcon, LeaderBoardIcon, PeopleIcon } from '.';

const quickFilters = defineMessages({
  heading: {
    defaultMessage: 'What are you looking to solve?',
    id: 'lR79UV',
  },
  employeeManagement: {
    defaultMessage: 'Employee Engagement',
    id: 'JFxTO6',
  },
  teamCommunications: {
    defaultMessage: 'Team Communications',
    id: 'bcGw5K',
  },
  gamesIceBreaker: {
    defaultMessage: 'Games & Icebreakers',
    id: 'LDamsf',
  },
  meetingTools: {
    defaultMessage: 'Meeting Tools',
    id: 'lC5inS',
  },
  forManagers: {
    defaultMessage: 'For Managers',
    id: '4hcxxh',
  },
  forHr: {
    defaultMessage: 'For HR',
    id: 'wFY9wv',
  },
  forCs: {
    defaultMessage: 'For CS',
    id: 'nvh46h',
  },
  forProduct: {
    defaultMessage: 'For Product',
    id: 'GdG5+h',
  },
});

type RenderQuickFiltersProps = {
  onClick: (filterItem: OnFilterClickProps) => void;
};

export const QuickFilters = (props: RenderQuickFiltersProps) => {
  const { onClick } = props;
  const { formatMessage } = useIntl();
  return (
    <>
      <h2 className="mt-4 px-6 text-base font-medium text-gray-9 lg:px-12">
        {formatMessage(quickFilters.heading)}
      </h2>
      <div className={`relative mx-0 md:m-4 md:mx-0 md:mt-0`}>
        <img
          src={templatesGalleryBgImage}
          className="absolute hidden h-[160px] w-full object-cover md:block"
          alt=""
        />
        <img
          src={templatesGalleryBgMobImage}
          className="absolute h-[160px] w-full object-fill md:hidden"
          alt=""
        />
        <div className="relative grid grid-cols-4 gap-4 px-6 pb-8 pt-4 lg:px-12">
          <div className="group hidden md:col-span-2 md:block xl:col-auto">
            <Button
              variation="secondaryLite"
              isFullWidth
              onClick={() =>
                onClick({
                  filterName: 'category',
                  id: 'EMPLOYEE_ENGAGEMENT',
                  value: true,
                })
              }
              data-testid="employeeEngagement"
            >
              <PeopleIcon
                className={'mr-2 h-[16px] w-[16px] group-hover:fill-primary-6'}
              />
              {formatMessage(quickFilters.employeeManagement)}
            </Button>
          </div>
          <div className="group hidden md:col-span-2 md:block xl:col-auto">
            <Button
              variation="secondaryLite"
              isFullWidth
              onClick={() =>
                onClick({
                  filterName: 'category',
                  id: 'TEAM_COMMUNICATIONS',
                  value: true,
                })
              }
            >
              <ConversationIcon
                className={'mr-2 h-[16px] w-[16px] group-hover:fill-primary-6'}
              />
              {formatMessage(quickFilters.teamCommunications)}
            </Button>
          </div>
          <div className="group hidden md:col-span-2 md:block xl:col-auto">
            <Button
              variation="secondaryLite"
              isFullWidth
              onClick={() =>
                onClick({
                  filterName: 'category',
                  id: 'GAMES_ICEBREAKERS',
                  value: true,
                })
              }
            >
              <LeaderBoardIcon
                className={'mr-2 h-[16px] w-[16px] group-hover:fill-primary-6'}
              />
              {formatMessage(quickFilters.gamesIceBreaker)}
            </Button>
          </div>
          <div className="group hidden md:col-span-2 md:block xl:col-auto">
            <Button
              variation="secondaryLite"
              isFullWidth
              onClick={() =>
                onClick({
                  filterName: 'action',
                  id: 'MEETINGS',
                  value: true,
                })
              }
            >
              <CalendarIcon
                className={'mr-2 h-[16px] w-[16px] group-hover:fill-primary-6'}
              />
              {formatMessage(quickFilters.meetingTools)}
            </Button>
          </div>
          <div className="group col-span-2 md:col-span-1">
            <Button
              variation="secondaryLite"
              isFullWidth
              onClick={() =>
                onClick({
                  filterName: 'jobType',
                  id: 'MANAGER',
                  value: true,
                })
              }
            >
              {formatMessage(quickFilters.forManagers)}
            </Button>
          </div>
          <div className="group col-span-2 md:col-span-1">
            <Button
              variation="secondaryLite"
              isFullWidth
              onClick={() =>
                onClick({
                  filterName: 'jobType',
                  id: 'HR',
                  value: true,
                })
              }
            >
              {formatMessage(quickFilters.forHr)}
            </Button>
          </div>
          <div className="group col-span-2 md:col-span-1">
            <Button
              variation="secondaryLite"
              isFullWidth
              onClick={() =>
                onClick({
                  filterName: 'jobType',
                  id: 'CUSTOMER_SERVICE',
                  value: true,
                })
              }
            >
              {formatMessage(quickFilters.forCs)}
            </Button>
          </div>
          <div className="group col-span-2 md:col-span-1">
            <Button
              variation="secondaryLite"
              isFullWidth
              onClick={() =>
                onClick({
                  filterName: 'jobType',
                  id: 'PRODUCT_AND_DESIGN',
                  value: true,
                })
              }
            >
              {formatMessage(quickFilters.forProduct)}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
