import { logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { trackAppConnectionError } from '../services/analytics';
import { useGetLinkTokenQuery } from './useGetLinkTokenQuery';
import { useSendPublicTokenMutation } from './useSendPublicTokenMutation';

const messages = defineMessages({
  errorOpeningModal: {
    defaultMessage: 'Failed to access connectable apps list. Please try again.',
    id: 'NfmvCM',
  },
});

export function useOpenMergeModal() {
  const {
    data: linkToken,
    error: errorFetchingToken,
    isFetching: isFetchingToken,
    refetch: fetchLinkToken,
    remove: clearToken,
  } = useGetLinkTokenQuery();

  const { formatMessage } = useIntl();
  const { showErrorToast } = useToastStore();

  const { mutate: sendPublicToken } = useSendPublicTokenMutation();

  const {
    error: errorLoadingMergeScript,
    isReady: isMergeModalReady,
    open: openMergeModal,
  } = useMergeLink({
    linkToken,
    onSuccess: (publicToken: string) => {
      sendPublicToken({ publicToken });
    },
  });

  useEffect(() => {
    const isTokenSet = Boolean(linkToken);
    const hasErrorLoadingMergeScript = Boolean(errorLoadingMergeScript);

    if (isTokenSet && isMergeModalReady && !hasErrorLoadingMergeScript) {
      clearToken();
      openMergeModal();
    }
  }, [
    clearToken,
    errorLoadingMergeScript,
    isMergeModalReady,
    linkToken,
    openMergeModal,
  ]);

  useEffect(() => {
    if (errorFetchingToken) {
      const errorInfo =
        errorFetchingToken instanceof Error ? errorFetchingToken : undefined;

      showErrorToast(formatMessage(messages.errorOpeningModal));

      logger.error(
        'Error fetching link token to open Merge Link',
        { error: errorInfo },
        errorInfo
      );
      trackAppConnectionError('openMergeModalError');
    }
  }, [errorFetchingToken, formatMessage, showErrorToast]);

  useEffect(() => {
    if (errorLoadingMergeScript) {
      const errorInfo =
        errorLoadingMergeScript.error instanceof Error
          ? errorLoadingMergeScript.error
          : undefined;

      showErrorToast(formatMessage(messages.errorOpeningModal));

      logger.error(
        "Error loading Merge's script to open Merge Link",
        { error: errorInfo },
        errorInfo
      );
      trackAppConnectionError('openMergeModalError');
    }
  }, [errorLoadingMergeScript, formatMessage, showErrorToast]);

  return {
    fetchLinkToken,
    isFetchingToken,
  };
}
