import { defineMessages, useIntl } from 'react-intl';

import { trackDoraAction } from '../../../../../../../services/analytics';
import { timeouts } from '../../../../../shared/dora/constants';
import { useIsBottomSheetInputActive } from '../../../../hooks/useIsBottomSheetInputActive';
import { AnswerMessage } from '../../../../shared/AnswerMessage';
import type {
  EditSettingsStoreType,
  EventSourceStoreType,
  FormSettingsStoreType,
  MemberAvatarProps,
} from '../../../../types';
import { FocusType } from '../../../../types';

const messages = defineMessages({
  custom: {
    defaultMessage: 'I want to focus on something else',
    id: 'lHVMts',
  },
  segmentByBlock: {
    defaultMessage: 'Segment summary by question',
    id: '4vOjok',
  },
  segmentByRespondent: {
    defaultMessage: 'Segment summary by respondent',
    id: 'gqd0xT',
  },
  sentiment: {
    defaultMessage: 'Analyze sentiment',
    id: 'E7mlA1',
  },
});

const getMessage = (focusType: FocusType) => {
  switch (focusType) {
    case FocusType.Custom:
      return messages.custom;
    case FocusType.SegmentByBlock:
      return messages.segmentByBlock;
    case FocusType.SegmentByRespondent:
      return messages.segmentByRespondent;
    case FocusType.Sentiment:
      return messages.sentiment;
  }
};

export const FocusOptionsAnswer = (
  props: MemberAvatarProps & {
    editSettingsStore: EditSettingsStoreType;
    eventSourceStore: EventSourceStoreType;
    formSettingsStore: FormSettingsStoreType;
  }
) => {
  const {
    editSettingsStore: useEditSettingsStore,
    eventSourceStore: { useEventSourceStore },
    formSettingsStore,
    ...messageProps
  } = props;

  const {
    hideActiveInput,
    useFocusTypeSetting,
    useSettingsConfirmationSetting,
  } = formSettingsStore;

  const { formatMessage } = useIntl();
  const origFocusTypeValue = useFocusTypeSetting((store) => store.value);
  const isSeen = useFocusTypeSetting.getState().isAnswerSeen;
  const isSet = useFocusTypeSetting((store) => store.isSet);
  const markSeen = useFocusTypeSetting((store) => store.markAnswerSeen);
  const markSet = useFocusTypeSetting((store) => store.markSet);

  const setShouldRequestInput = useFocusTypeSetting(
    (store) => store.setShouldRequestInput
  );

  const editedFocusTypeValue = useEditSettingsStore(
    (store) => store.focusType.value
  );

  const value = editedFocusTypeValue ?? origFocusTypeValue;

  const shouldRequestInput = useFocusTypeSetting(
    (store) => store.shouldRequestInput
  );

  const isEditingPrevSetting = useEditSettingsStore(
    (store) => store.isEditingRespondents() || store.isEditingBlocks()
  );

  const isEditingCurSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'focusType'
  );

  const isEditingOtherSetting = useEditSettingsStore((store) => {
    const isEditingExistingCustomFocus =
      store.settingEditing === 'customFocus' && !store.focusType.value;

    return isEditingExistingCustomFocus || store.isEditingTimePeriod();
  });

  const setSettingEditing = useEditSettingsStore(
    (store) => store.setSettingEditing
  );

  const setShouldRequestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  const isSettingsConfirmed = useSettingsConfirmationSetting((store) =>
    Boolean(store.value)
  );

  const resetEdited = useEditSettingsStore((store) => store.reset);

  const handleEdit = () => {
    trackDoraAction('summaryInputEdited', { doraSummaryInput: 'Focus' });
    setSettingEditing('focusType');
    hideActiveInput();
    resetEdited();

    setTimeout(() => {
      setShouldRequestEdit('focusType', true);
    }, timeouts.showInput);
  };

  const isInputActive = useIsBottomSheetInputActive(formSettingsStore);
  const isEditing = useEditSettingsStore((store) =>
    Boolean(store.settingEditing)
  );
  const isSummaryError = useEventSourceStore((store) => store.isSummaryError);

  return (
    value &&
    !isEditingPrevSetting && (
      <AnswerMessage
        isEditDisabled={
          !isSummaryError &&
          (isEditingCurSetting ||
            isEditingOtherSetting ||
            isSettingsConfirmed ||
            (!isInputActive && !isEditing))
        }
        isEditing={isEditingCurSetting}
        isFaded={isEditingOtherSetting}
        isSeen={isSeen}
        isSet={isSet}
        markSeen={markSeen}
        markSet={markSet}
        onEdit={handleEdit}
        setShouldRequestInput={setShouldRequestInput}
        shouldRequestInput={shouldRequestInput}
        {...messageProps}
      >
        {formatMessage(getMessage(value))}
      </AnswerMessage>
    )
  );
};
