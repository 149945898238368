import {
  copyToClipboard,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import {
  Button,
  Heading,
  IconButton,
  TextStyle,
  Tooltip,
} from '@assembly-web/ui';
import { SparklesIcon } from '@heroicons/react/20/solid';
import {
  BookmarkIcon,
  CalendarDaysIcon,
  PaperAirplaneIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { type ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { trackDoraAction } from '../../../services/analytics';

const messages = defineMessages({
  title: {
    id: 'Y1MKTB',
    defaultMessage: 'Upgrade {logo} DoraAI Reporting & Insights',
  },
  conversationLimitsTitle: {
    defaultMessage: 'Sky-high conversation limits',
    id: 'jpmRiv',
  },
  conversationLimitsDescription: {
    defaultMessage: 'Use DoraAI with fewer limits and never miss a beat',
    id: 'rhgnTA',
  },
  savePromptsTitle: {
    defaultMessage: 'Save prompts & conversation histories',
    id: 'DOSHcm',
  },
  savePromptsDescription: {
    defaultMessage: 'Find and run critical reports with ease',
    id: 'ZE5Mi4',
  },
  scheduleInsightsTitle: {
    defaultMessage: 'Schedule & automate insights',
    id: 'd7Vq6n',
  },
  scheduleInsightsDescription: {
    defaultMessage:
      'Automate DoraAI reports to your inbox, or send scheduled reports to other admins or managers at your company',
    id: 'HPHhjZ',
  },
  contactSuccessTeamTitle: {
    defaultMessage: 'Reach out to your customer success team to upgrade',
    id: 'XoEl2k',
  },
  contactSuccessTeamDescription: {
    defaultMessage: 'Copy email address: success@joinassembly.com',
    id: 'QJLlUi',
  },
  copySuccess: {
    defaultMessage: 'Link copied to clipboard',
    id: '2yCGR2',
  },
  closeCTA: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

function ReportingUpgradeBannerItem({
  isFullScreen,
  icon,
  title,
  description,
}: {
  isFullScreen: boolean;
  title: string;
  description: string;
  icon: ReactNode;
}) {
  return (
    <div className="inline-flex items-start gap-1">
      {icon}
      <div className="flex flex-col">
        <Heading as={isFullScreen ? 'h5' : 'h6'}>{title}</Heading>
        <TextStyle variant="sm-regular" subdued className="w-96">
          {description}
        </TextStyle>
      </div>
    </div>
  );
}

export function ReportingUpgradeBanner({
  closeBanner,
  isFullScreen,
}: {
  isFullScreen: boolean;
  closeBanner: () => void;
}) {
  const { formatMessage } = useIntl();
  const [showCopySuccessToast, setShowCopySuccessToast] = useState(false);

  const copyCTAText = formatMessage(
    showCopySuccessToast
      ? messages.copySuccess
      : messages.contactSuccessTeamDescription
  );
  return (
    <div
      className={twMerge(
        'flex h-full w-full flex-col items-center',
        isFullScreen ? 'gap-16' : 'gap-10'
      )}
    >
      <IconButton
        variation="tertiaryLite"
        className="absolute right-4 top-11"
        onClick={closeBanner}
      >
        <Tooltip
          tooltipText={formatMessage(messages.closeCTA)}
          contentClassName="p-2 z-[11]"
        >
          <XMarkIcon className="h-4 w-4 text-gray-8 hover:bg-gray-3" />
        </Tooltip>
      </IconButton>

      <Heading
        as={isFullScreen ? 'h4' : 'h5'}
        className={twMerge(
          'inline-flex items-center gap-1',
          isFullScreen ? 'mt-14' : 'mt-10'
        )}
      >
        {formatMessage(messages.title, {
          logo: (
            <div className="h-6 w-6 rounded bg-gradient-to-r from-[#1d3fc8] via-purple-7 to-[#a309ca] p-1">
              <SparklesIcon className="h-4 w-4 text-gray-1" />
            </div>
          ),
        })}
      </Heading>
      <div
        className={twMerge(
          'mx-auto flex flex-col',
          isFullScreen ? 'gap-9' : 'gap-4'
        )}
      >
        <ReportingUpgradeBannerItem
          isFullScreen={isFullScreen}
          icon={
            <PaperAirplaneIcon
              className={twMerge(
                'text-gray-9',
                isFullScreen ? 'h-[30px] w-[30px]' : 'h-6 w-6'
              )}
            />
          }
          title={formatMessage(messages.conversationLimitsTitle)}
          description={formatMessage(messages.conversationLimitsDescription)}
        />
        <ReportingUpgradeBannerItem
          isFullScreen={isFullScreen}
          icon={
            <BookmarkIcon
              className={twMerge(
                'text-gray-9',
                isFullScreen ? 'h-[30px] w-[30px]' : 'h-6 w-6'
              )}
            />
          }
          title={formatMessage(messages.savePromptsTitle)}
          description={formatMessage(messages.savePromptsDescription)}
        />
        <ReportingUpgradeBannerItem
          isFullScreen={isFullScreen}
          icon={
            <CalendarDaysIcon
              className={twMerge(
                'text-gray-9',
                isFullScreen ? 'h-[30px] w-[30px]' : 'h-6 w-6'
              )}
            />
          }
          title={formatMessage(messages.scheduleInsightsTitle)}
          description={formatMessage(messages.scheduleInsightsDescription)}
        />
      </div>

      <div className="flex items-start">
        <div className="flex flex-col items-center gap-3">
          <Heading as={isFullScreen ? 'h5' : 'h6'}>
            {formatMessage(messages.contactSuccessTeamTitle)}
          </Heading>
          <Button
            variation="secondaryEmphasized"
            className="w-96 whitespace-nowrap"
            onClick={withoutDefaultEventBehavior(() => {
              setShowCopySuccessToast(true);
              copyToClipboard('success@joinassembly.com');
              trackDoraAction('copySuccessEmailClicked');
              setTimeout(() => setShowCopySuccessToast(false), 1500);
            })}
          >
            {copyCTAText}
          </Button>
        </div>
      </div>
    </div>
  );
}
