import { createContext, useContext } from 'react';

const OptionIdContext = createContext<string | null>(null);

export const OptionIdProvider = OptionIdContext.Provider;

export const useOptionIdContext = () => {
  const context = useContext(OptionIdContext);

  if (!context) {
    throw new Error(
      'useOptionIdContext must be used within a OptionIdProvider'
    );
  }

  return context;
};
