import type { AdditionalInfoData, UpdateData } from '@assembly-web/services';
import {
  config,
  DoraAPIEndpoints,
  logger,
  zustandLocalStorage,
} from '@assembly-web/services';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import type { FocusType } from '../../components/Drawers/FlowSummaryDrawer/types';
import { anonymousMemberId } from '../../components/Drawers/shared/dora/constants';
import { trackDoraAction } from '../../services/analytics';
import { DoraRateLimitError, streamingTimeout } from '../../services/dora';

type EventSourceStore = {
  isFetchingSummary: boolean;
  isSummaryError: boolean;
  isSummaryFetched: boolean;
  promptId: string | null;
  streamedData: string | null;
  summaryErrorMsg?: string;
};

export const createEventSourceStore = (persistKey: string) => {
  let abortController: AbortController | null = null;

  const useEventSourceStore = create<EventSourceStore>()(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set) => ({
        isFetchingSummary: false,
        isSummaryError: false,
        isSummaryFetched: false,
        promptId: null,
        streamedData: null,
      }),
      {
        name: `${persistKey}-eventSource`,
        storage: createJSONStorage(() => zustandLocalStorage),
      }
    )
  );

  const fetchFlowSummary = (params: {
    customFocus?: string;
    blockIds?: string[];
    endDate: string;
    flowId: string;
    focusType: FocusType;
    respondedBy?: string[];
    startDate: string;
  }) => {
    const {
      blockIds = [],
      customFocus = '',
      endDate,
      flowId,
      focusType,
      respondedBy = [],
      startDate,
    } = params;

    const isAnonymous = respondedBy.some(
      (respondentMemberId) => respondentMemberId === anonymousMemberId
    );

    const respondedMembers = respondedBy.filter(
      (respondentMemberId) => respondentMemberId !== anonymousMemberId
    );

    let streamedData = '';

    useEventSourceStore.setState({
      isFetchingSummary: true,
      isSummaryError: false,
      streamedData: '',
    });

    abortController?.abort();

    const url = new URL(config.domains.doraApi);

    url.pathname = DoraAPIEndpoints.flowSummary;
    url.searchParams.set('additional_prompt', customFocus);
    url.searchParams.set('block_ids', blockIds.join(','));
    url.searchParams.set('end_date', endDate);
    url.searchParams.set('flow_id', flowId);
    url.searchParams.set('is_anonymous', isAnonymous.toString());
    url.searchParams.set('responded_by', respondedMembers.join(','));
    url.searchParams.set('start_date', startDate);
    url.searchParams.set('summary_type', focusType);

    const controller = new AbortController();
    const signal = controller.signal;

    abortController = controller;

    const abortTimer = setTimeout(() => {
      logger.warn(
        `Timing out Dora request for GET ${DoraAPIEndpoints.flowSummary}`,
        {
          ...params,
          timeout: streamingTimeout,
        }
      );

      controller.abort();

      useEventSourceStore.setState({
        isSummaryError: true,
        isFetchingSummary: false,
      });
    }, streamingTimeout);

    fetchEventSource(url.href, {
      credentials: 'include',

      async onopen(resp) {
        clearTimeout(abortTimer);

        if (!resp.ok) {
          let responseBody;

          if (resp.headers.get('content-type') === 'application/json') {
            responseBody = await resp.json();
          } else {
            responseBody = await resp.text();
          }

          logger.error(
            `Dora request for GET ${DoraAPIEndpoints.flowSummary} failed`,
            { flowId, responseBody, statusCode: resp.status, url: resp.url }
          );

          if (resp.status === 429) {
            const body = (await resp.json()) as { message: string };

            throw new DoraRateLimitError(body.message);
          }
          throw new Error('Dora Flow Summary');
        }
      },

      onerror(err) {
        useEventSourceStore.setState((state) => ({
          isFetchingSummary: false,
          isSummaryError: true,
          isSummaryFetched: true,
          summaryErrorMsg:
            err instanceof DoraRateLimitError
              ? err.message
              : state.summaryErrorMsg,
        }));

        throw err;
      },

      async onmessage(msg) {
        if (msg.event === 'update') {
          const response = JSON.parse(msg.data) as UpdateData;

          streamedData += response.data;

          useEventSourceStore.setState((state) => ({
            isFetchingSummary: false,
            streamedData: state.streamedData ? streamedData : response.data,
          }));
        } else if (msg.event === 'additional_info') {
          const response = JSON.parse(msg.data) as AdditionalInfoData;
          useEventSourceStore.setState({
            promptId: response.data.prompt_id,
          });
        } else if (msg.event === 'end') {
          abortController?.abort();

          useEventSourceStore.setState((state) => ({
            isSummaryFetched: true,
            streamedData: state.streamedData,
          }));

          trackDoraAction('flowSummaryExecuted', {
            doraSummaryParameters: JSON.stringify(
              Object.fromEntries(url.searchParams.entries())
            ),
            doraSummaryText: streamedData,
          });
        }
      },

      openWhenHidden: true,
      signal,
    });
  };

  const reset = () => {
    abortController?.abort();
    useEventSourceStore.setState({
      isFetchingSummary: false,
      isSummaryError: false,
      isSummaryFetched: false,
      promptId: null,
      streamedData: null,
    });
  };

  return {
    fetchFlowSummary,
    reset,
    useEventSourceStore,
  };
};
