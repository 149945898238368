import type { Nullable, ReplyData } from '@assembly-web/services';
import { create } from 'zustand';

type PostDrawerState = {
  repliesToEditMap: Record<string, Nullable<ReplyData>>;
  updateRepliesToEditMap: (
    reply: Nullable<ReplyData>,
    drawerID: string
  ) => void;
};

export const usePostDrawerStore = create<PostDrawerState>((set, get) => ({
  repliesToEditMap: {},

  updateRepliesToEditMap: (reply: Nullable<ReplyData>, drawerID: string) => {
    const currentSelectedRepliesToEdit = get().repliesToEditMap;
    const updatedRepliesToEdit = { ...currentSelectedRepliesToEdit };
    updatedRepliesToEdit[drawerID] = reply;
    if (updatedRepliesToEdit[drawerID] === null) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- we need to delete the key from the object
      delete updatedRepliesToEdit[drawerID];
    }
    set(() => ({ repliesToEditMap: updatedRepliesToEdit }));
  },
}));
