import { useDeviceInfo } from '@assembly-web/ui';
import { useCallback, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';
import { CloseDrawerProvider } from '../../contexts/CloseDrawerContext';
import { useNavStore } from '../../hooks/nav/useNavStore';
import { useIsFullScreenLegacyPage } from '../../hooks/useIsFullScreenLegacyPage';
import { trackPopoutShow } from '../../services/analytics';
import { MultiViewDrawer } from './MultiViewDrawer';
import { OverflowMenu } from './OverflowMenu';
import { useDrawerCleanUpByFF } from './shared/hooks/useDrawerCleanupByFF';
import { accessDrawer, type Drawer } from './types';

const minDrawerWidth = 180;
const openDrawerWidth = 512;
const drawerGapWidth = 8;
const navWidth = 288;
const maxWindowWidthMultiView = 1020;

const displayDrawers = (drawers: Drawer[]) => {
  return drawers.map((drawer) => {
    return accessDrawer(drawer, drawer.type, (config, drawer) => {
      const DrawerComponent = config.drawer;
      return <DrawerComponent key={`drawer-${drawer.id}`} {...drawer} />;
    });
  });
};

export function DrawerDock() {
  const drawerDock = useRef<HTMLDivElement>(null);

  const drawers = useMultiDrawerStore((store) => store.getDrawers());
  const overflowDrawers = useMultiDrawerStore((store) =>
    store.getOverflowDrawers()
  );
  const isOverflowOpen = useMultiDrawerStore((store) => store.isOverflowOpen);
  const isMultiViewDrawer = useMultiDrawerStore(
    (store) => store.isMultiViewDrawer
  );
  const setIsMultiViewDrawer = useMultiDrawerStore(
    (store) => store.setIsMultiViewDrawer
  );
  const drawerIsOpen =
    isOverflowOpen || (overflowDrawers.length === 0 && drawers[0]?.isOpen);
  const toggleIsOverflowOpen = useMultiDrawerStore(
    (store) => store.toggleIsOverflowOpen
  );
  const closeDrawer = useMultiDrawerStore((store) => store.closeDrawer);
  const moveDrawerToOverflow = useMultiDrawerStore(
    (store) => store.moveDrawerToOverflow
  );
  const moveOldestDrawerOutOfOverflow = useMultiDrawerStore(
    (store) => store.moveOldestDrawerOutOfOverflow
  );
  const isNavPinned = useNavStore((store) => store.isPinned);
  const navTriggerPosition = useNavStore((store) => store.navTriggerPosition);

  const { deviceType } = useDeviceInfo();
  const isMobile = deviceType === 'mobile';
  const windowWidth = useRef(window.innerWidth);
  const existingDrawers = useRef(drawers);
  const existingOpenDrawers = useRef(drawers.filter((drawer) => drawer.isOpen));
  const existingNavPinned = useRef(isNavPinned);
  const hasLoaded = useRef(false);
  const isResizeEvent = useRef(false);

  useDrawerCleanUpByFF();

  useEffect(() => {
    if (!hasLoaded.current) {
      trackPopoutShow({ numPopouts: drawers.length + overflowDrawers.length });
    }
  }, [drawers.length, overflowDrawers.length]);

  const closeAndMoveDrawers = useCallback(
    ({
      drawerDockWidth,
      minDrawersWidth,
    }: {
      drawerDockWidth: number;
      minDrawersWidth: number;
    }) => {
      existingOpenDrawers.current.forEach((drawer) => {
        closeDrawer(drawer.id);
      });

      let newMinDrawersWidth =
        minDrawersWidth -
        existingOpenDrawers.current.length * openDrawerWidth +
        existingOpenDrawers.current.length * minDrawerWidth;

      // loop till it fits or till we run out of drawers to move
      let drawerCount = existingDrawers.current.length;
      while (drawerDockWidth < newMinDrawersWidth && drawerCount > 0) {
        moveDrawerToOverflow();
        newMinDrawersWidth -= minDrawerWidth - drawerGapWidth;
        drawerCount--;
      }
    },
    [closeDrawer, moveDrawerToOverflow]
  );

  // check spacing when...
  // 1. On load
  // 2. New drawer added to dock
  // 3. Closed drawer opened in dock
  // 4. Overflow menu opened
  // 5. Nav is pinned
  useEffect(() => {
    if (!hasLoaded.current && (isMultiViewDrawer || isResizeEvent.current)) {
      hasLoaded.current = true;
    }

    if (isMultiViewDrawer) {
      return;
    }
    // do not run on resize event
    if (isResizeEvent.current) {
      isResizeEvent.current = false;
      return;
    }

    const newOpenDrawers = drawers.filter((drawer) => drawer.isOpen);
    if (drawerDock.current) {
      const newNumOpenDrawers = newOpenDrawers.length;
      const itemsInDock = drawers.length + (overflowDrawers.length > 0 ? 1 : 0);
      let minDrawersWidth =
        overflowDrawers.length === 0
          ? 0
          : isOverflowOpen
            ? openDrawerWidth
            : minDrawerWidth;
      minDrawersWidth +=
        newNumOpenDrawers * openDrawerWidth +
        (drawers.length - newNumOpenDrawers) * minDrawerWidth;
      minDrawersWidth += (itemsInDock - 1) * drawerGapWidth; // spacing between drawers

      const drawerDockRect = drawerDock.current.getBoundingClientRect();
      const pinnedNavWidth = isNavPinned ? navWidth : 0;
      const drawerDockWidth = drawerDockRect.width - pinnedNavWidth;

      // does not fit
      if (drawerDockWidth < minDrawersWidth) {
        // 1. on load
        if (!hasLoaded.current) {
          hasLoaded.current = true;
          closeAndMoveDrawers({ drawerDockWidth, minDrawersWidth });

          // 2. New drawer added to dock
        } else if (existingDrawers.current.length < drawers.length) {
          if (existingOpenDrawers.current.length > 0) {
            closeAndMoveDrawers({ drawerDockWidth, minDrawersWidth });
          } else if (existingDrawers.current.length > 0) {
            // moveDrawerToOverflow will move 2 drawers if no overflowDrawers exist
            // to account for this, start at count = 1 if there's no overflowDrawers
            let count = overflowDrawers.length > 0 ? 0 : 1;
            while (count < existingDrawers.current.length) {
              moveDrawerToOverflow();
              count++;
            }
            // check sizing, if still no space, close overflow
            const newMinDrawersWidth =
              minDrawersWidth -
              (existingDrawers.current.length - minDrawerWidth) +
              openDrawerWidth;

            if (
              drawerDockRect.width - pinnedNavWidth < newMinDrawersWidth &&
              isOverflowOpen
            ) {
              toggleIsOverflowOpen();
            }
          } else if (isOverflowOpen) {
            toggleIsOverflowOpen();
            const newMinDrawersWidth =
              minDrawerWidth - openDrawerWidth + minDrawerWidth;
            if (drawerDockRect.width - pinnedNavWidth < newMinDrawersWidth) {
              closeAndMoveDrawers({ drawerDockWidth, minDrawersWidth });
            }
          }

          // 3. Closed drawer opened in dock
        } else if (existingOpenDrawers.current.length < newNumOpenDrawers) {
          if (existingOpenDrawers.current.length > 0) {
            existingOpenDrawers.current.forEach((drawer) => {
              closeDrawer(drawer.id);
            });
          } else if (isOverflowOpen) {
            toggleIsOverflowOpen();
          } else {
            closeAndMoveDrawers({ drawerDockWidth, minDrawersWidth });
          }

          // 4. Overflow menu opened
        } else if (isOverflowOpen && overflowDrawers.length > 0) {
          closeAndMoveDrawers({ drawerDockWidth, minDrawersWidth });

          // 5. Nav is pinned
        } else if (isNavPinned && !existingNavPinned.current) {
          existingNavPinned.current = true;
          closeAndMoveDrawers({ drawerDockWidth, minDrawersWidth });
        }
      } else if (!hasLoaded.current) {
        hasLoaded.current = true;
      }
    }
    existingDrawers.current = drawers;
    existingOpenDrawers.current = newOpenDrawers;
  }, [
    closeAndMoveDrawers,
    closeDrawer,
    drawers,
    isMultiViewDrawer,
    isNavPinned,
    isOverflowOpen,
    moveDrawerToOverflow,
    overflowDrawers.length,
    toggleIsOverflowOpen,
  ]);

  const handleResize = useCallback(() => {
    if (drawerDock.current) {
      const drawerDockRect = drawerDock.current.getBoundingClientRect();
      const firstChild = drawerDock.current.firstElementChild;
      if (!isMultiViewDrawer) {
        if (firstChild) {
          const firstChildRect = firstChild.getBoundingClientRect();
          const newWidth = window.innerWidth;
          const navWidthInView = isNavPinned ? navWidth : 0;
          // if window is shrinking
          if (windowWidth.current > newWidth) {
            if (
              (navTriggerPosition === 'right' &&
                Math.round(firstChildRect.right) >
                  drawerDockRect.right - navWidthInView) ||
              (navTriggerPosition === 'left' &&
                Math.round(firstChildRect.left) <
                  drawerDockRect.left + navWidthInView)
            ) {
              moveDrawerToOverflow();
              isResizeEvent.current = true;
            }
            // if window is expanding + there's an overflow menu
          } else if (overflowDrawers.length > 0) {
            const maxWidth = drawerDockRect.width - navWidthInView;
            let minDrawersWidth = isOverflowOpen
              ? openDrawerWidth
              : minDrawerWidth;
            drawers.forEach((drawer) => {
              if (drawer.isOpen) {
                minDrawersWidth += openDrawerWidth;
              } else {
                minDrawersWidth += minDrawerWidth;
              }
            });
            minDrawersWidth += minDrawerWidth;
            if (minDrawersWidth <= maxWidth) {
              moveOldestDrawerOutOfOverflow();
              isResizeEvent.current = true;
            }
          }
          windowWidth.current = newWidth;
        }
        existingDrawers.current = drawers;
      } else if (drawers.length > 0) {
        moveDrawerToOverflow(true);
        isResizeEvent.current = true;
      }

      const shouldBeMultiView =
        isMobile ||
        (isNavPinned && window.innerWidth <= maxWindowWidthMultiView);
      if (!isMultiViewDrawer && shouldBeMultiView) {
        setIsMultiViewDrawer(true);
      } else if (isMultiViewDrawer && !shouldBeMultiView) {
        setIsMultiViewDrawer(false);
      }
    }
  }, [
    isMobile,
    isNavPinned,
    navTriggerPosition,
    moveDrawerToOverflow,
    overflowDrawers.length,
    drawers,
    isMultiViewDrawer,
    isOverflowOpen,
    moveOldestDrawerOutOfOverflow,
    setIsMultiViewDrawer,
  ]);

  useEffect(() => {
    if (!isNavPinned) {
      existingNavPinned.current = false;
    }
  }, [isNavPinned]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    setIsMultiViewDrawer(
      isMobile || (isNavPinned && window.innerWidth <= maxWindowWidthMultiView)
    );
  }, [isMobile, isNavPinned, setIsMultiViewDrawer]);

  useEffect(() => {
    if (isMultiViewDrawer && drawers.length > 0) {
      moveDrawerToOverflow(true);
    }
  }, [drawers.length, isMultiViewDrawer, moveDrawerToOverflow]);

  const shouldHide = useIsFullScreenLegacyPage();

  return (
    <CloseDrawerProvider>
      <aside
        ref={drawerDock}
        className={twMerge(
          'fixed bottom-0 z-10 mx-4 flex h-0 w-[calc(100%-32px)] items-end justify-end gap-1 overflow-visible',
          navTriggerPosition === 'right' && 'flex-row-reverse',
          isMobile && 'mx-0 w-full',
          isMobile && drawerIsOpen && 'z-[11]',
          isNavPinned && navTriggerPosition === 'left' && 'md:pl-[288px]',
          isNavPinned && navTriggerPosition === 'right' && 'md:pr-[288px]',
          shouldHide && 'hidden'
        )}
      >
        {isMultiViewDrawer ? (
          <MultiViewDrawer />
        ) : (
          <>
            {displayDrawers(drawers)}
            {overflowDrawers.length > 0 && <OverflowMenu />}
          </>
        )}
      </aside>
    </CloseDrawerProvider>
  );
}
