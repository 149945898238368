import type { ImportantActivitiesApiResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseInfiniteQueryOptions } from '@tanstack/react-query';

export function getImportantActivitiesQuery(
  options?: UseInfiniteQueryOptions<ImportantActivitiesApiResponse>
): UseInfiniteQueryOptions<ImportantActivitiesApiResponse> {
  return {
    ...options,
    queryKey: ['importantCards'],
    queryFn: async ({ pageParam = '' }) =>
      await assemblyAPI.get(APIEndpoints.getImportantActivities, {
        ...(pageParam ? { params: { cursor: pageParam } } : {}),
      }),
    getNextPageParam: (page: ImportantActivitiesApiResponse) =>
      page.data.metadata?.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page: ImportantActivitiesApiResponse) => {
      return page.data.metadata?.pagination.cursor.previous;
    },
  };
}
