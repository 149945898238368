import {
  type Member,
  MemberState,
  PermissionType,
} from '@assembly-web/services';
import { AvatarSize } from '@assembly-web/ui';
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  ClipboardDocumentListIcon,
  HomeIcon,
  IdentificationIcon,
  UserGroupIcon,
} from '@heroicons/react/20/solid';
import { UserCircleIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';

export const ViewerPermission = {
  id: PermissionType.Viewer,
  label: 'Viewer',
  displayLabel: 'Viewer',
};

export const OwnerPermission = {
  id: PermissionType.Owner,
  label: 'Owner',
  displayLabel: 'Owner',
};

export const ExcludedPermission = {
  id: PermissionType.Excluded,
  label: 'Excluded',
  displayLabel: 'Excluded',
};

export const CollaboratorPermission = {
  id: PermissionType.Collaborator,
  label: 'Collaborator',
  displayLabel: 'Collaborator',
};

export const ParticipantPermission = {
  id: PermissionType.Participant,
  label: 'Participant',
  displayLabel: 'Participant',
};

export const ApproverPermission = {
  id: PermissionType.Approver,
  label: 'Approver',
  displayLabel: 'Approver',
};

export const ParticipantApproverPermission = {
  id: PermissionType.ParticipantApprover,
  label: 'Participant and Approver',
  displayLabel: 'Participant and Approver',
};

export const PeopleSelectorPermission = {
  id: PermissionType.Custom,
  label: 'Selectable person',
  displayLabel: 'Selectable person',
};

export const DefaultPermission = {
  collection: ViewerPermission,
  challenge: ParticipantPermission,
  ownerAndCollaborators: CollaboratorPermission,
  peopleSelector: PeopleSelectorPermission,
} as const;

export const CriteriaCardIconMap: Record<string, ReactNode> = {
  department: (
    <BriefcaseIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />
  ),
  homeLocation: <HomeIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />,
  workLocation: (
    <BuildingOfficeIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />
  ),
  jobTitle: (
    <IdentificationIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />
  ),
  manager: (
    <ClipboardDocumentListIcon
      className="h-6 w-6 text-gray-1"
      aria-hidden="true"
    />
  ),
  pending: <UserPlusIcon className="h-6 w-6 text-gray-9" aria-hidden="true" />,
  active: <UserCircleIcon className="h-6 w-6 text-gray-9" aria-hidden="true" />,
  everyone: (
    <UserGroupIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />
  ),
};

export const mapMemberData = (member: Member) => {
  const { memberID, name, email, image, memberState, role } = member;
  const data = {
    id: memberID,
    title: name,
    subtitle: email,
    role,
    state: memberState,
    ...(memberState === MemberState.Active
      ? {
          avatar: {
            image,
            memberID,
            name,
            size: AvatarSize.ExtraLarge,
          },
        }
      : {
          customIcon: {
            icon: CriteriaCardIconMap[MemberState.Active.toLowerCase()],
            iconBackgroundColor:
              memberState === MemberState.Pending ? 'bg-gray-4' : '',
          },
        }),
    permission: undefined,
  };

  return { id: memberID, data };
};
