import { Button, ConfirmationModal } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  title: {
    defaultMessage:
      "''{collectionName}' is a big collection - are you sure you want to open all of these at once?",
    id: 'bo0Dp/',
  },
  description: {
    defaultMessage:
      'If you still want to open them, expect to wait about 30 seconds to a minute before being active in your browser.',
    id: '3/eFNT',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  label: {
    defaultMessage: 'Open all links dialog',
    id: '6DwGuv',
  },
  openCta: {
    defaultMessage: 'Open all {numLinks} links',
    id: 'dneahZ',
  },
});

type LinksWarningModalProps = {
  numLinks: number;
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  title: string;
};

export const LinksWarningModal = (props: LinksWarningModalProps) => {
  const { numLinks, onClose, open, onSubmit, title } = props;
  const { formatMessage } = useIntl();

  return (
    <ConfirmationModal
      label={formatMessage(messages.label)}
      title={formatMessage(messages.title, { collectionName: title })}
      description={formatMessage(messages.description)}
      leftButton={
        <Button isFullWidth onClick={onClose} variation="secondaryLite">
          {formatMessage(messages.cancel)}
        </Button>
      }
      onClose={onClose}
      open={open}
      rightButton={
        <Button isFullWidth onClick={onSubmit} variation="primary">
          {formatMessage(messages.openCta, { numLinks })}
        </Button>
      }
    />
  );
};
