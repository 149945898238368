import type { ReactNode } from 'react';
import { createContext, useState } from 'react';

import type { FilePreviewModalProps } from '../components/FilePreviewModal';
import { FilePreviewModal } from '../components/FilePreviewModal';

export const FilePreviewModalContext = createContext<{
  openModal: (props: Omit<FilePreviewModalProps, 'onClose'>) => void;
}>({
  openModal: () => {},
});

export const FilePreviewModalProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [modalProps, setModalProps] = useState<
    Omit<FilePreviewModalProps, 'onClose'> | undefined
  >();

  const closeModal = () => setModalProps(undefined);

  return (
    <FilePreviewModalContext.Provider value={{ openModal: setModalProps }}>
      {modalProps ? (
        <FilePreviewModal {...modalProps} onClose={closeModal} />
      ) : null}
      {children}
    </FilePreviewModalContext.Provider>
  );
};
