import { PermissionType } from '@assembly-web/services';
import { Banner, Button, FlowEditorContainers } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { useSetFlowPermissions } from '../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useClearPersistedPermission } from '../hooks/useClearPersistedPermission';
import { usePermissions } from '../store/useEditorMiscState';
import { formatUserToFlowCollaborator } from '../utils/user';

const messages = defineMessages({
  save: {
    defaultMessage: 'Save',
    id: 'jvo0vs',
  },
  ownerPermissionWarn: {
    defaultMessage: 'Select a new Owner to save changes and continue.',
    id: 'khIORV',
  },
});

export const PermissionsFooter = () => {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();

  const permissions = usePermissions(id);

  const handleCloseClick = useClearPersistedPermission();
  const updateFlow = useSetFlowPermissions(id);

  const handleDoneClick = () => {
    updateFlow((state) => {
      return {
        collaborators:
          permissions
            ?.filter(
              (permission) =>
                permission.permission?.id === PermissionType.Collaborator
            )
            .map((permission) => formatUserToFlowCollaborator(permission)) ??
          [],
        owner: (() => {
          const owner = permissions?.find(
            (permission) => permission.permission?.id === PermissionType.Owner
          );
          if (!owner) {
            return state.owner;
          }
          return [formatUserToFlowCollaborator(owner)];
        })(),
      };
    });
    handleCloseClick();
  };

  const atLeastOneOwner = permissions?.some(
    (permission) => permission.permission?.id === PermissionType.Owner
  );
  return (
    <FlowEditorContainers.Footer className="gap-3">
      {!atLeastOneOwner && (
        <Banner status="alert">
          {formatMessage(messages.ownerPermissionWarn)}
        </Banner>
      )}
      <Button
        variation="primary"
        size="large"
        onClick={handleDoneClick}
        disabled={!atLeastOneOwner}
      >
        {formatMessage(messages.save)}
      </Button>
    </FlowEditorContainers.Footer>
  );
};
