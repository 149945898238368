import { CalendarDate } from '@internationalized/date';

export const getEndOfDay = (date: Date) => {
  const endOfDay = new Date(date);

  endOfDay.setHours(23, 59, 59, 999);

  return endOfDay;
};

export const getCalendarDate = (date: Date) => {
  return new CalendarDate(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate()
  );
};

export const isSameCalendarDates = (a: Date, b: Date) => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};
