import { appConnectionsIllustration } from '@assembly-web/assets';
import { Button, Heading, LoadingSpinner, TextStyle } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

import { useOpenMergeModal } from '../hooks/useOpenMergeModal';
import { trackAppConnectionAction } from '../services/analytics';

const messages = defineMessages({
  header: {
    defaultMessage: 'Save time by searching through your work in one place.',
    id: '01aSSq',
  },
  connectFirstApp: {
    defaultMessage: 'Connect your first app',
    id: 'oZlSMt',
  },
  problemStatement: {
    defaultMessage:
      'On average, people spend up to one day a week searching for important information. Switching apps and losing concentration takes a toll!',
    id: 'qxPFtj',
  },
  solution: {
    defaultMessage:
      'By connecting your most important work tools, Assembly will help you centralize your work-related information and find things faster.',
    id: 'woB/2r',
  },
});

export function EmptyConnections() {
  const { formatMessage } = useIntl();
  const { fetchLinkToken, isFetchingToken } = useOpenMergeModal();

  const addNewAppConnection = () => {
    trackAppConnectionAction('connectAppClicked', { connectedAppName: [] });
    fetchLinkToken();
  };

  return (
    <section className="flex flex-col items-center justify-center">
      <img alt="" src={appConnectionsIllustration} />
      <div className="mt-10 max-w-[591px] text-center">
        <Heading as="h5">{formatMessage(messages.header)}</Heading>
        <TextStyle className="mt-2.5">
          {formatMessage(messages.problemStatement)}
        </TextStyle>
        <TextStyle className="mt-6">
          {formatMessage(messages.solution)}
        </TextStyle>
        <Button
          className="mt-3"
          isLoading={isFetchingToken}
          onClick={addNewAppConnection}
        >
          {Boolean(isFetchingToken) && <LoadingSpinner />}
          {formatMessage(messages.connectFirstApp)}
        </Button>
      </div>
    </section>
  );
}
