import type { Announcement } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

export function useGetAnnouncementById(
  announcementId: string
): UseQueryResult<Announcement> {
  return useQuery(
    ['announcement', announcementId],
    async () => {
      const { data } = await assemblyAPI.get(
        APIEndpoints.getAnnouncementById(announcementId)
      );
      return data;
    },
    {
      cacheTime: 0,
      enabled: Boolean(announcementId),
    }
  );
}
