import type { MarkedExtension, Tokens } from 'marked';

type Cell = {
  text: string;
  align?: string;
};

type TableToken = {
  type: 'spanTable';
  raw: string;
  header: Cell[][];
  align: (string | null)[];
  rows: Cell[][];
};

function isTableToken(token: Tokens.Generic): token is TableToken {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (token as TableToken).type === 'spanTable';
}

export const markedTablePlugin: MarkedExtension = {
  extensions: [
    {
      name: 'spanTable',
      level: 'block',
      start(src: string): number | undefined {
        return src.match(/^\n *([^\n ].*\|.*)\n/)?.index;
      },
      tokenizer(src: string): TableToken | undefined {
        const regex = new RegExp(
          '^ *([^\\n ].*\\|.*\\n(?: *[^\\s].*\\n)*?)' +
            ' {0,3}(?:\\| *)?(:?-+:? *(?:\\| *:?-+:? *)*)(?:\\| *)?' +
            '(?:\\n((?:(?! *\\n| {0,3}((?:- *){3,}|(?:_ *){3,}|(?:\\* *){3,})' +
            '(?:\\n+|$)| {0,3}#{1,6} | {0,3}>| {4}[^\\n]| {0,3}(?:`{3,}' +
            '(?=[^`\\n]*\\n)|~{3,})[^\\n]*\\n| {0,3}(?:[*+-]|1[.)]) |' +
            '<\\/?(?:address|article|aside|base|basefont|blockquote|body|' +
            'caption|center|col|colgroup|dd|details|dialog|dir|div|dl|dt|fieldset|figcaption|figure|footer|form|frame|frameset|h[1-6]|head|header|hr|html|iframe|legend|li|link|main|menu|menuitem|meta|nav|noframes|ol|optgroup|option|p|param|section|source|summary|table|tbody|td|tfoot|th|thead|title|tr|track|ul)(?: +|\\n|\\/?>)|<(?:script|pre|style|textarea|!--)).*(?:\\n|$))*)\\n*|$)'
        );
        const cap = regex.exec(src);
        if (cap) {
          return {
            type: 'spanTable',
            raw: cap[0],
            header: parseRows(cap[1]),
            align: parseAlignment(cap[2]),
            rows: parseRows(cap[3] || '').filter(
              (row) => row.length > 1 || row[0]?.text !== ''
            ), // Filter out empty rows
          };
        }
      },
      renderer(token: Tokens.Generic): string {
        if (!isTableToken(token)) {
          throw new Error('Token is not a TableToken');
        }

        let output =
          '<div class="overflow-hidden sm:rounded-lg border border-gray-5 rounded-lg"><div class="overflow-x-auto"><div class="inline-block min-w-full align-middle"><div class="border-b border-gray-5"><table class="min-w-full border-collapse">';
        output += '<thead class="bg-gray-3"><tr>';
        token.header.forEach((headerRow) => {
          headerRow.forEach((cell, cellIndex) => {
            const isFirstColumn = cellIndex === 0;
            const dividerClass = isFirstColumn ? '' : 'border-l border-gray-5';
            output += `<th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-9 ${dividerClass} border-b border-gray-5">${cell.text}</th>`;
          });
        });
        output += '</tr></thead><tbody class="bg-gray-1">';
        token.rows.forEach((row) => {
          output += `<tr>`;
          row.forEach((cell, cellIndex) => {
            const isFirstColumn = cellIndex === 0;
            const dividerClass = isFirstColumn ? '' : 'border-l border-gray-5';
            output += `<td class="whitespace-nowrap py-4 px-3 text-sm ${dividerClass} border-b border-gray-5">${cell.text}</td>`;
          });
          output += '</tr>';
        });
        output += '</tbody></table></div></div></div></div>';
        return output;
      },
    },
  ],
};

function parseRows(rowStr: string): Cell[][] {
  return rowStr
    .split('\n')
    .map((row) => {
      const cells = [...row.matchAll(/(?:[^|\\]|\\.?)+(?:\|+|$)/g)].map(
        (x) => x[0]
      );

      if (!cells[0]?.trim()) {
        cells.shift();
      }
      if (!cells[cells.length - 1]?.trim()) {
        cells.pop();
      }

      return cells.map((cell) => ({
        text: cell.replaceAll('|', '').trim(),
        align: 'left',
      }));
    })
    .filter((row) => row.some((cell) => cell.text.trim() !== ''));
}

function parseAlignment(alignStr: string): (string | null)[] {
  return alignStr.split('|').map((align) => {
    if (/^ *-+: *$/.test(align)) return 'right';
    else if (/^ *:-+: *$/.test(align)) return 'center';
    else if (/^ *:-+ *$/.test(align)) return 'left';
    return 'left';
  });
}
