import { TextStyle } from '@assembly-web/ui';
import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { timeouts } from '../../../../../shared/dora/constants';
import { DoraSingleMessage } from '../../../../../shared/dora/DoraSingleMessage';
import type { IndividualBlocksQuestionProps } from '../../../../types';

const messages = defineMessages({
  specifyAnswers: {
    defaultMessage:
      "Great! Specify which answers you'd like me to summarize for you:",
    id: '2Vq5c4',
  },
});

export const Message = (props: IndividualBlocksQuestionProps) => {
  const {
    editSettingsStore: useEditSettingsStore,
    formSettingsStore: { useIndividualBlocksSetting },
  } = props;

  const { formatMessage } = useIntl();

  const isEditingCurSetting = useEditSettingsStore(
    (store) => store.settingEditing === 'individualBlocks'
  );

  const isEditingOtherSetting = useEditSettingsStore(
    (store) => store.isEditingFocus() || store.isEditingTimePeriod()
  );

  const isOrigQuestionSeen =
    useIndividualBlocksSetting.getState().isQuestionSeen;

  const isEditQuestionSeen =
    useEditSettingsStore.getState().individualBlocks.isQuestionSeen;

  const isSeen = isEditingCurSetting ? isEditQuestionSeen : isOrigQuestionSeen;

  const isOrigSet = useIndividualBlocksSetting((store) => store.isSet);

  const isEditedSet = useEditSettingsStore(
    (store) => store.individualBlocks.isSet
  );

  const isSet =
    (isEditingCurSetting && isEditedSet) || (!isEditingCurSetting && isOrigSet);

  const markOrigQuestionSeen = useIndividualBlocksSetting(
    (store) => store.markQuestionSeen
  );

  const markEditQuestionSeen = useEditSettingsStore(
    (store) => store.markQuestionSeen
  );

  const requestOrigInput = useIndividualBlocksSetting(
    (store) => store.setShouldRequestInput
  );

  const requestEdit = useEditSettingsStore(
    (store) => store.setShouldRequestInput
  );

  useEffect(() => {
    if (!isOrigQuestionSeen && !isEditingCurSetting) {
      markOrigQuestionSeen();
    } else if (!isEditQuestionSeen && isEditingCurSetting) {
      markEditQuestionSeen('individualBlocks');
    }
  }, [
    isEditQuestionSeen,
    isEditingCurSetting,
    isOrigQuestionSeen,
    markEditQuestionSeen,
    markOrigQuestionSeen,
  ]);

  useEffect(() => {
    const requestInput = isEditingCurSetting
      ? (shouldRequestInput: boolean) =>
          requestEdit('individualBlocks', shouldRequestInput)
      : requestOrigInput;

    if (!isSet) {
      setTimeout(() => {
        requestInput(true);
      }, timeouts.showInput);
    }
  }, [isSet, isEditingCurSetting, requestOrigInput, requestEdit]);

  return (
    <DoraSingleMessage isFaded={isEditingOtherSetting} shouldAnimate={!isSeen}>
      <TextStyle className="text-gray-9">
        {formatMessage(messages.specifyAnswers)}
      </TextStyle>
    </DoraSingleMessage>
  );
};
