import { GlobalFilterOption } from '@assembly-web/ui';

export const defaultAggregates = {
  [GlobalFilterOption.All]: 0,
  [GlobalFilterOption.Collections]: 0,
  [GlobalFilterOption.Files]: 0,
  [GlobalFilterOption.Flows]: 0,
  [GlobalFilterOption.People]: 0,
  [GlobalFilterOption.Rewards]: 0,
  [GlobalFilterOption.Tasks]: 0,
  [GlobalFilterOption.Notifications]: 0,
  [GlobalFilterOption.Challenges]: 0,
};

// Discounts constants
const discountsUsLink = `https://www.memberdeals.com/joinassembly/?login=1`;
const discountsInternationalLink = `https://www.memberdeals.com/joinassembly/international-cities.php?login=1`;
const discountsHelpLink =
  'https://www.joinassembly.com/articles/rewards-discounts';

export const discountsLinks = {
  us: discountsUsLink,
  international: discountsInternationalLink,
  help: discountsHelpLink,
};

// Challenges constants
export const challengesLink = `https://help.joinassembly.com/en/articles/9367321-challenges`;
