import { successImage } from '@assembly-web/assets';
import {
  APIEndpoints,
  getAPIErrorCode,
  unauthenticatedAssemblyAPI,
} from '@assembly-web/services';
import {
  Button,
  RouterForm,
  TextStyle,
  validateForm,
  validateFormSafely,
} from '@assembly-web/ui';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useNavigation,
  useRouteLoaderData,
  useSearchParams,
  useSubmit,
} from 'react-router-dom';
import { z } from 'zod';

import type { ReactRouterLoaderResponse } from '../../../../../types/libs';
import { OnboardingContainer } from '../../../components/OnboardingContainer';
import { getSSODetailsFromError } from '../../../services/sso';
import type { joinWorkspaceSlugLoader } from '.';

const checkMailFormText = defineMessages({
  title: {
    defaultMessage: 'Almost done, check your email',
    id: 'uPFZiI',
  },
  description: {
    defaultMessage:
      "Click on the link in your email to finish signing up -- Don't forget to check your spam/junk.",
    id: 'wwvc88',
  },
  footer: {
    defaultMessage:
      'Keep this window open while checking your email. If you can’t find it, check your spam or junk folder.',
    id: 'f/rpuL',
  },
  cta: {
    defaultMessage: 'Resend email',
    id: '5q4xKF',
  },
  sentMailCTA: {
    defaultMessage: 'Sent',
    id: 'gcuDcH',
  },
});

export function CheckEmailRoute() {
  const [searchParams] = useSearchParams();

  const loaderData = useRouteLoaderData(
    'join-workspace-root'
  ) as ReactRouterLoaderResponse<typeof joinWorkspaceSlugLoader>;

  const { formatMessage } = useIntl();

  const navigation = useNavigation();

  const isPageLoading = navigation.state === 'loading';
  const isSubmissionInProgress = navigation.state === 'submitting';

  const assemblyId = loaderData.assemblyId;
  const email = searchParams.get('email') ?? '';

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const submit = useSubmit();

  return (
    <OnboardingContainer
      title={formatMessage(checkMailFormText.title)}
      description={formatMessage(checkMailFormText.description)}
    >
      <RouterForm method="post">
        <input value={assemblyId} name="assemblyId" readOnly hidden />

        <input value={email} name="email" readOnly hidden />

        {Boolean(isFirstLoad) && (
          <Button
            type="submit"
            isFullWidth
            isLoading={isSubmissionInProgress}
            disabled={isSubmissionInProgress || isPageLoading}
            onClick={() => {
              submit({ assemblyId, email }, { method: 'post' });
              setIsFirstLoad(false);
            }}
          >
            {formatMessage(checkMailFormText.cta)}
          </Button>
        )}

        {isFirstLoad || (
          <div className="flex w-full justify-center text-center align-middle">
            <img src={successImage} className="mr-1 mt-1 h-4 w-4" alt="" />
            <span className="text-success-1">
              {formatMessage(checkMailFormText.sentMailCTA)}
            </span>
          </div>
        )}
      </RouterForm>
      <TextStyle subdued className="pt-1">
        {formatMessage(checkMailFormText.footer)}
      </TextStyle>
    </OnboardingContainer>
  );
}

export async function checkEmailLoader({ request }: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);

  const { success } = validateFormSafely(
    z.object({
      email: z.string().trim().email('invalid_email'),
    }),
    searchParams
  );
  if (!success) {
    return redirect('/login');
  }

  return null;
}

export async function checkEmailAction({ request }: ActionFunctionArgs) {
  const formSchema = z.object({
    email: z.string().trim().email('invalid_email'),
    assemblyId: z.string().trim(),
  });
  const { pathname } = new URL(request.url);
  try {
    const { email, assemblyId } = validateForm(
      formSchema,
      await request.formData()
    );

    await unauthenticatedAssemblyAPI.post(
      APIEndpoints.registerMagicLinkUser,
      { email, assemblyId },
      { signal: request.signal }
    );

    return redirect(
      `${pathname}?email=${encodeURIComponent(email)}&assemblyId=${assemblyId}`
    );
  } catch (error) {
    const errorCode = getAPIErrorCode(error);

    const enforcedLoginDetails = getSSODetailsFromError(error);

    if (enforcedLoginDetails) {
      return redirect(
        `${pathname}/validate/${enforcedLoginDetails.provider}`.replace(
          '//',
          '/'
        )
      );
    }

    return { error: errorCode };
  }
}
