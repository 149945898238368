import { memo } from 'react';

import { DefaultBlockRow } from '../../DefaultBlockRow';
import { Description } from '../../Description';
import { MoreSettings } from './MoreSettings';

export const GivePoints = memo(function GivePoints() {
  return (
    <DefaultBlockRow>
      <Description />
      <MoreSettings />
    </DefaultBlockRow>
  );
});
