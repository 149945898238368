import { BlockDescription } from '@assembly-web/ui';

import {
  useGetBlockDescription,
  useSetBlockDescription,
} from '../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useBlockIdContext } from '../context/BlockIdContext';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';
import { useFormattedBlockName } from '../hooks/useFormattedBlockName';

export function Description() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const value = useGetBlockDescription(id, blockId);
  const setBlockDescription = useSetBlockDescription(id, blockId);
  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleClick = () => {
    trackFlowEditorAction('blockDetailClicked', {
      blockType: formattedBlockName,
      detailType: `${formattedBlockName}Description`,
    });
  };

  return (
    <BlockDescription
      onChange={(e) => setBlockDescription(e.target.value)}
      onClick={handleClick}
      value={value}
      disabled={isOccurrenceInProgress}
    />
  );
}
