import type {
  DownloadSecureFileResponse,
  FileDownloadRequest,
} from '@assembly-web/services';
import {
  APIEndpoints,
  AppAction,
  AppTargetEvent,
  assemblyAPI,
  FileType,
  postMessageToMobileApp,
  userAuthStore,
} from '@assembly-web/services';
import axios from 'axios';

export function fileDownload(
  fileName: string,
  url: string | URL,
  blob: Blob | MediaSource
) {
  const req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.responseType = 'blob';
  req.onload = function () {
    const url = window.URL;
    let link = url.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('download', fileName);
    a.setAttribute('href', link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  req.send();
}

export const downloadFeedFile = async (request: FileDownloadRequest) => {
  const { data: fileToDownload } =
    await assemblyAPI.get<DownloadSecureFileResponse>(
      APIEndpoints.getFileDownloadURL(request)
    );

  if (userAuthStore.getState().isMobileAppV3) {
    postMessageToMobileApp({
      action: AppAction.FileDownload,
      targetEvent: AppTargetEvent.Mobile,
      payload: {
        id: request.fileName,
        linkURL: fileToDownload.url,
      },
    });
  } else {
    const response = await axios.get(fileToDownload.url, {
      responseType: 'blob',
    });

    fileDownload(request.fileName, fileToDownload.url, response.data);
  }
};

export const getFilesTypeMap = (
  filesTypeResponse: Record<string, string>[]
) => {
  const fileTypes: Record<FileType, string[]> = {
    [FileType.Document]: [],
    [FileType.Image]: [],
    [FileType.Presentation]: [],
    [FileType.Pdf]: [],
    [FileType.Spreadsheet]: [],
    [FileType.Audio]: [],
    [FileType.Video]: [],
    [FileType.Zip]: [],
    [FileType.GenericFile]: [],
  };

  filesTypeResponse.forEach((item) => {
    const fileToCompare = item.id.charAt(0).toLowerCase() + item.id.slice(1);
    const file: FileType =
      fileToCompare === 'others'
        ? FileType.GenericFile
        : (fileToCompare as FileType);
    fileTypes[file].push(...item.mimeTypes);
  });

  return fileTypes;
};
