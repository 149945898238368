import type {
  FlowPostResponse,
  GetRepliesResponse,
  OpenEndedBlockResponse,
  PersonSelectorBlockResponse,
  ReplyData,
} from '@assembly-web/services';
import type {
  MentionedUserInReply,
  SerializedEditorState,
  SerializedElementNode,
} from '@assembly-web/ui';
import type { InfiniteData } from '@tanstack/react-query';
import uniqueBy from 'lodash/uniqBy';

import type { PostData } from '../../../../../../types/postsAndReplies';

type PostDetails = {
  flowId: string;
  responseId: string;
  postType: 'RESPONSE' | 'POST';
};

export function getPostDetailsFromPostData(data: PostData): PostDetails {
  const isFlow = data.type === 'flow';

  return {
    postType: isFlow ? 'RESPONSE' : 'POST',
    flowId: isFlow ? data.flowId : 'recognition',
    responseId: isFlow ? data.responseId : data.postId,
  };
}

export function getReplyByReplyId(
  repliesData: InfiniteData<GetRepliesResponse> | undefined,
  replyId: string
): ReplyData | undefined {
  return repliesData?.pages
    .flatMap((page) => page.data)
    .find((reply) => reply.commentID === replyId);
}
export function getMentionedMembersInFlowResponse(
  value: FlowPostResponse | undefined
) {
  if (!value) {
    return [];
  }

  const extractMembersFromPersonSelectorBlock = (
    response: PersonSelectorBlockResponse
  ) => {
    return response.persons.map((person) => ({
      name: `${person.name.firstName.toString()} ${(
        person.name.lastName ?? ''
      ).toString()}`,
      image: person.image,
      id: person.memberId,
      email: person.email,
      firstName: person.firstName,
      lastName: person.lastName,
      role: person.role,
      status: person.status,
    }));
  };

  const extractMembersFromOpenEndedBlock = (
    response: OpenEndedBlockResponse
  ) => {
    return response.mentions
      ? response.mentions.map((person) => ({
          name: `${person.firstName.toString()} ${person.lastName.toString()}`,
          image: person.image,
          id: person.memberId,
          email: person.email,
          firstName: person.firstName,
          lastName: person.lastName,
          role: person.role,
          status: person.status,
        }))
      : [];
  };

  const members: MentionedUserInReply[] = value.responses.flatMap((block) => {
    const response = block.response;
    if (!response) {
      return [];
    }
    switch (block.type) {
      case 'PERSON_SELECTOR': {
        return extractMembersFromPersonSelectorBlock(
          response as PersonSelectorBlockResponse
        );
      }
      case 'OPEN_ENDED': {
        return extractMembersFromOpenEndedBlock(
          response as OpenEndedBlockResponse
        );
      }
      default:
        return [];
    }
  });
  return uniqueBy(members, 'id');
}

export const cleanEditorState = ({
  html,
  json: editorState,
  plainText,
}: {
  html: string;
  plainText: string;
  json: string;
}) => {
  // regex explanation: https://regexr.com/7si4d
  html = html.replace(
    /((^<p><\/p>)|(^<p><br><\/p>))(<p><br><\/p>)+|(?:(<p><br><\/p>)+)?(?:(<p><\/p>$)|(<p(?: dir="ltr")?><br><\/p>$))/gm,
    ''
  );
  plainText = plainText.trim();

  const json = (() => {
    try {
      let json = JSON.parse(editorState) as SerializedEditorState;
      json = {
        ...json,
        root: {
          ...json.root,
          children: (() => {
            let start = 0;
            let tail = json.root.children.length - 1;
            while (
              start < json.root.children.length &&
              json.root.children[start].type === 'paragraph' &&
              (json.root.children[start] as SerializedElementNode).children
                .length === 0
            ) {
              start++;
            }

            while (
              tail >= 0 &&
              json.root.children[tail].type === 'paragraph' &&
              (json.root.children[tail] as SerializedElementNode).children
                .length === 0
            ) {
              tail--;
            }

            return json.root.children.slice(start, tail + 1);
          })(),
        },
      };

      return JSON.stringify(json);
    } catch {
      return editorState;
    }
  })();

  return { html, json, plainText };
};

export function removeNewLinesAndTabs(plainText: string) {
  return plainText.replace(/(\r\n|\n|\r|\t)/gm, '');
}
