import type { AssistantHistoryItem } from '@assembly-web/services';
import { AskDoraOverflowCard } from '@assembly-web/ui';
import { useMemo } from 'react';

import { useGetDoraChatHistoryQuery } from '../../../hooks/dora/useChatHistoryQuery';
import {
  getDoraChatStore,
  getPersistKeyForDoraChatDrawer,
} from '../../../stores/doraChatStore';
import type { Drawer } from '../types';

export function DoraChatDrawerOverflowCard(
  props: Drawer & {
    onClick: () => void;
    onClose: () => void;
  }
) {
  const { hasNewActivity = false, onClick, onClose, title } = props;

  const persistKey = getPersistKeyForDoraChatDrawer(props);

  const { doraChatStore } = useMemo(
    () => getDoraChatStore(persistKey),
    [persistKey]
  );

  const useDoraChatStore = doraChatStore;

  const threadId = useDoraChatStore(
    (store) => store.reportingInsights?.threads?.[0].id ?? ''
  );

  const numAdditionalMessages = useDoraChatStore((state) => {
    const introMessage = state.reportingInsights?.activeCategory ? 1 : 0;

    const messagesFromActivePrompt = state.reportingInsights?.recentBlock
      ?.prompt
      ? 2
      : 0;

    return introMessage + messagesFromActivePrompt;
  });

  const { data: chatHistoryData, isLoading } = useGetDoraChatHistoryQuery(
    threadId,
    {
      enabled: Boolean(threadId),
    }
  );

  const numPreviousMessages = chatHistoryData?.length ?? 0;

  const lastMessageTS = chatHistoryData
    ?.filter(
      (message): message is AssistantHistoryItem => message.role === 'assistant'
    )
    .pop()?.createdAt;

  return (
    <AskDoraOverflowCard
      hasNewActivity={hasNewActivity}
      isLoading={Boolean(isLoading && threadId)}
      lastMessageTS={lastMessageTS}
      numMessages={numPreviousMessages + numAdditionalMessages}
      onClose={onClose}
      onClick={onClick}
      title={title}
    />
  );
}
