import type {
  ChallengeAPIResponse,
  ChallengeCardDetails,
  CollectionItemsAPIResponse,
  FlowPostResponse,
  GetRepliesResponse,
  ImportantActivitiesApiResponse,
  MemberDetails,
  Reaction,
  ReactionDetails,
  UserFeedApiResponse,
} from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  deserializeReactions,
} from '@assembly-web/services';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import {
  updateCollectionItemFeedReactions,
  updateImportantSectionReactions,
  updateReactionInReplyDrawer,
  updateSearchFeedReactions,
  updateSearchFeedReactionsForChallengeCard,
  updateUserFeedReactions,
  updateUserFeedReactionsForChallengeCard,
} from '../modules/discover/hooks/replies/utils';
import type { SearchIndexApiResponse } from '../modules/discover/hooks/useSearchIndex';

export function useReactionMutation({
  flowId,
  responseId,
  currentUser,
  onError,
}: {
  flowId: string;
  responseId: string;
  currentUser: MemberDetails | undefined;
  onError?: (e: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const { mutate: reactionMutate } = useMutation({
    mutationFn: async ({
      payload,
      action,
    }: {
      payload: Reaction;
      action: string;
    }) => {
      const response = await assemblyAPI.put(
        APIEndpoints.assemblyPostReaction({ flowId, responseId, action }),
        payload
      );
      return response.data;
    },
    onMutate: async (body) => {
      const { payload, action } = body as unknown as {
        payload: ReactionDetails;
        action: string;
      };
      /** We have three different caches.
       * 1. Search feed cache
       * 2. User feed cache
       * 3. Assembly flow post (notifications etc..) cache
       * Since the post is present in multiple places, we need to update the reactions in all the places.
       * We can't use the same query key for all the three caches, since the query key is different for each cache.
       * So we need to find all the query keys and update the reactions in all the places.
       */

      const queriesData = queryClient.getQueryData<
        FlowPostResponse | undefined
      >(['assemblyFlowPost', flowId, responseId]);

      if (queriesData) {
        const mutatedQueriesData = produce(queriesData, (draft) => {
          const reactions = draft.reactions;
          deserializeReactions({
            payload,
            currentUser,
            reactions,
            action,
          });
        });
        queryClient.setQueryData(
          ['assemblyFlowPost', flowId, responseId],
          mutatedQueriesData
        );
      }

      const searchFeedQueryKeys = queryCache
        .findAll(['searchResults'])
        .map((query) => query.queryKey);
      searchFeedQueryKeys.forEach((queryKey) => {
        const searchData =
          queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
            queryKey
          );
        if (searchData) {
          const mutatedSearchData: InfiniteData<SearchIndexApiResponse> = {
            ...searchData,
            pages: searchData.pages.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  data: page.data.data.map((result) => {
                    if (
                      (result.type === 'post' || result.type === 'response') &&
                      (result.cardDetails?.responseId === responseId ||
                        result.cardDetails?.post?.postID === responseId)
                    ) {
                      const updatedResult = produce(result, (draft) => {
                        if (draft.cardDetails) {
                          const reactions = draft.cardDetails.post
                            ? draft.cardDetails.post.reactions
                            : draft.cardDetails.reactions;
                          deserializeReactions({
                            payload,
                            currentUser,
                            reactions,
                            action,
                          });
                        }
                      });
                      return updatedResult;
                    }
                    return result;
                  }),
                },
              };
            }),
          };
          queryClient.setQueryData(queryKey, mutatedSearchData);
        }
      });

      const userFeedQueryKeys = queryCache
        .findAll(['userFeed'])
        .map((query) => query.queryKey);
      userFeedQueryKeys.forEach((queryKey) => {
        const userFeedData: InfiniteData<UserFeedApiResponse> | undefined =
          queryClient.getQueryData(queryKey);
        if (userFeedData) {
          const mutatedUserFeedData: InfiniteData<UserFeedApiResponse> = {
            ...userFeedData,
            pages: userFeedData.pages.map((page) => {
              return {
                ...page,
                data: page.data.map((post) => {
                  if (
                    (post.type === 'post' || post.type === 'response') &&
                    (post.cardDetails?.responseId === responseId ||
                      post.cardDetails?.post?.postID === responseId)
                  ) {
                    const updatedPost = produce(post, (draft) => {
                      if (draft.cardDetails) {
                        const reactions = draft.cardDetails.post
                          ? draft.cardDetails.post.reactions
                          : draft.cardDetails.reactions;
                        deserializeReactions({
                          payload,
                          currentUser,
                          reactions,
                          action,
                        });
                      }
                    });
                    return updatedPost;
                  }
                  return post;
                }),
              };
            }),
          };
          queryClient.setQueryData(queryKey, mutatedUserFeedData);
        }
      });
    },
    onError,
  });

  return reactionMutate;
}

export function useCarouselPostCardReactionMutation({
  flowId,
  responseId,
  cardId,
  currentUser,
}: {
  flowId: string;
  responseId: string;
  cardId: string;
  currentUser: MemberDetails | undefined;
}) {
  const queryClient = useQueryClient();
  const { mutate: reactionMutate } = useMutation({
    mutationFn: async ({
      payload,
      action,
    }: {
      payload: Reaction;
      action: string;
    }) => {
      const response = await assemblyAPI.put(
        APIEndpoints.assemblyPostReaction({ flowId, responseId, action }),
        payload
      );
      return response.data;
    },
    onMutate: async (body) => {
      const { payload, action } = body as unknown as {
        payload: ReactionDetails;
        action: string;
      };

      const queryKey = ['carouselCards', cardId];
      const repliesData: FlowPostResponse[] | undefined =
        queryClient.getQueryData(queryKey);
      if (repliesData) {
        const updatedResult = repliesData.map((result) => {
          if (result.responseId === responseId) {
            const upResult = produce(result, (draft) => {
              const reactions = draft.reactions;
              deserializeReactions({
                payload,
                currentUser,
                reactions,
                action,
              });
            });
            return upResult;
          }
          return result;
        });
        queryClient.setQueryData(queryKey, updatedResult);
      }
    },
    onError: (err: unknown, body) => {
      const { payload } = body as unknown as {
        payload: ReactionDetails;
      };
      const queryKey = ['carouselCards', cardId];
      const repliesData: FlowPostResponse[] | undefined =
        queryClient.getQueryData(queryKey);

      if (repliesData) {
        const updatedResult = repliesData.map((result) => {
          if (result.responseId === responseId) {
            return {
              ...result,
              reactions: result.reactions.filter(
                (reaction) => reaction.name !== payload.name
              ),
            };
          }
          return result;
        });
        queryClient.setQueryData(queryKey, updatedResult);
      }
    },
  });

  return reactionMutate;
}

export function useCommentReactionMutation({
  currentUser,
  onError,
}: {
  currentUser: MemberDetails;
  onError?: (e: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const { mutate: reactionMutate } = useMutation({
    mutationFn: async ({
      payload,
      action,
      commentId,
    }:
      | {
          payload: Reaction;
          action: string;
          commentId: string;
          flowId: string;
          responseId: string;
        }
      | {
          payload: Reaction;
          action: string;
          commentId: string;
          challengeId: string;
        }) => {
      const response = await assemblyAPI.put(
        APIEndpoints.assemblyCommentReaction({
          commentId,
          action,
        }),
        payload
      );
      return response.data;
    },
    onMutate: async (body) => {
      let repliesData = null;
      const { payload, action, commentId } = body;

      if ('flowId' in body) {
        const { flowId, responseId } = body;
        repliesData = queryClient.getQueryData<
          InfiniteData<GetRepliesResponse>
        >(['replies', flowId, responseId]);
      } else {
        const { challengeId } = body;
        repliesData = queryClient.getQueryData<
          InfiniteData<GetRepliesResponse>
        >(['replies', challengeId]);
      }

      const userFeedKeys = queryCache
        .findAll(['userFeed'])
        .map((query) => query.queryKey);
      const searchFeedKeys = queryCache
        .findAll(['searchResults'])
        .map((query) => query.queryKey);

      const importantSectionQueryKey = ['importantCards'];
      const latestImportantSectionData = queryClient.getQueryData<
        InfiniteData<ImportantActivitiesApiResponse>
      >(importantSectionQueryKey);
      const updatedActivities = updateImportantSectionReactions({
        importantSectionQueryKey,
        queryClient,
        payload,
        currentUser,
        action,
        commentId,
      });
      queryClient.setQueryData(importantSectionQueryKey, updatedActivities);

      const latestUserFeedData: InfiniteData<UserFeedApiResponse> | undefined =
        queryClient.getQueryData(userFeedKeys[userFeedKeys.length - 1]);

      const latestSearchFeedData = queryClient.getQueryData<
        InfiniteData<SearchIndexApiResponse>
      >(searchFeedKeys[searchFeedKeys.length - 1]);

      updateUserFeedReactions({
        userFeedKeys,
        queryClient,
        payload,
        currentUser,
        action,
        commentId,
      });
      const collectionItemCachedData = updateCollectionItemFeedReactions({
        queryClient,
        payload,
        currentUser,
        action,
        commentId,
      });
      updateSearchFeedReactions({
        searchFeedKeys,
        queryClient,
        payload,
        currentUser,
        action,
        commentId,
      });
      const updatedReplies = updateReactionInReplyDrawer({
        repliesData,
        commentId,
        payload,
        currentUser,
        action,
      });

      if ('flowId' in body) {
        const { flowId, responseId } = body;
        queryClient.setQueryData(
          ['replies', flowId, responseId],
          updatedReplies
        );
      } else {
        const { challengeId } = body;
        queryClient.setQueryData(['replies', challengeId], updatedReplies);
      }

      return {
        repliesData,
        latestUserFeedData,
        latestSearchFeedData,
        latestImportantSectionData,
        collectionItemCachedData,
      };
    },
    onError: (err, body, context) => {
      onError?.(err);
      if ('flowId' in body) {
        const { flowId, responseId } = body;
        queryClient.setQueryData(
          ['replies', flowId, responseId],
          context?.repliesData
        );
      } else {
        const { challengeId } = body;
        queryClient.setQueryData(
          ['replies', challengeId],
          context?.repliesData
        );
      }

      queryClient.setQueryData(['userFeed'], context?.latestUserFeedData);
      queryClient.setQueryData(['searchFeed'], context?.latestSearchFeedData);
      queryClient.setQueryData(
        ['importantCards'],
        context?.latestImportantSectionData
      );
      context?.collectionItemCachedData.map(
        (item: {
          key: string[];
          data: CollectionItemsAPIResponse | undefined;
        }) => {
          queryClient.setQueryData(item.key, item.data);
        }
      );
    },
  });
  return reactionMutate;
}

export function useChallengeReactionMutation({
  challengeId,
  cardId,
  currentUser,
  onError,
}: {
  challengeId: string;
  cardId: string;
  currentUser: MemberDetails | undefined;
  onError?: (e: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const { mutate: reactionMutate } = useMutation({
    mutationFn: async ({
      payload,
      action,
    }: {
      payload: Reaction;
      action: string;
    }) => {
      const response = await assemblyAPI.put(
        APIEndpoints.assemblyChallengeReaction({ challengeId, action }),
        payload
      );
      return response.data;
    },
    onMutate: async (body) => {
      const { payload, action } = body as unknown as {
        payload: ReactionDetails;
        action: string;
      };

      const queryKey = ['carouselCards', cardId];
      const repliesData: ChallengeCardDetails[] | undefined =
        queryClient.getQueryData(queryKey);
      if (repliesData) {
        const updatedResult = repliesData.map((result) => {
          if (result.challengeId === challengeId) {
            const upResult = produce(result, (draft) => {
              const reactions = draft.reactions;
              deserializeReactions({
                payload,
                currentUser,
                reactions,
                action,
              });
            });
            return upResult;
          }
          return result;
        });
        queryClient.setQueryData(queryKey, updatedResult);
      }
      const userFeedKeys = queryCache
        .findAll(['userFeed'])
        .map((query) => query.queryKey);

      const searchFeedKeys = queryCache
        .findAll(['searchResults'])
        .map((query) => query.queryKey);

      const latestUserFeedData: InfiniteData<UserFeedApiResponse> | undefined =
        queryClient.getQueryData(userFeedKeys[userFeedKeys.length - 1]);

      const latestSearchFeedData = queryClient.getQueryData<
        InfiniteData<SearchIndexApiResponse>
      >(searchFeedKeys[searchFeedKeys.length - 1]);

      updateUserFeedReactionsForChallengeCard({
        userFeedKeys,
        queryClient,
        payload,
        currentUser,
        action,
        challengeId,
      });

      updateSearchFeedReactionsForChallengeCard({
        searchFeedKeys,
        queryClient,
        payload,
        currentUser,
        action,
        challengeId,
      });

      const challengeDetails = queryClient.getQueryData<ChallengeAPIResponse>([
        'challenge',
        challengeId,
      ]);

      if (challengeDetails) {
        const updatedChallengeDetails = produce<ChallengeAPIResponse>(
          challengeDetails,
          (draft: ChallengeAPIResponse) => {
            const reactions = draft.reactions;
            deserializeReactions({
              payload,
              currentUser,
              reactions,
              action,
            });
          }
        );
        queryClient.setQueryData(
          ['challenge', challengeId],
          updatedChallengeDetails
        );
      }

      return { repliesData, latestUserFeedData, latestSearchFeedData };
    },
    onError: (err, body, context) => {
      onError?.(err);
      const queryKey = ['carouselCards', cardId];
      queryClient.setQueryData(queryKey, context?.repliesData);
      queryClient.setQueryData(['userFeed'], context?.latestUserFeedData);
      queryClient.setQueryData(['searchFeed'], context?.latestSearchFeedData);
    },
  });

  return reactionMutate;
}
