import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import type { TemplateListsApiResponse } from '../types';

export type Filter = {
  actions: string[];
  jobTypes: string[];
  useCases: string[];
  categories: string[];
};

type GetTemplatesQueryPayload = Partial<{
  keyword: string;
  filter: Partial<Filter>;
}>;
export type FilterPayload = Partial<Filter>;

export function getTemplatesQuery(
  payload: GetTemplatesQueryPayload
): UseInfiniteQueryOptions<TemplateListsApiResponse> {
  const { keyword, filter } = payload;

  return {
    queryKey: ['templates', keyword, filter],
    queryFn: async ({ pageParam = '' }) =>
      await assemblyAPI.post(
        APIEndpoints.listTemplates,
        {
          limit: 20,
          cursor: pageParam,
          filter,
        },
        {
          params: {
            keyword,
          },
        }
      ),
    getNextPageParam: (page: TemplateListsApiResponse) =>
      page.data.metadata?.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page: TemplateListsApiResponse) => {
      return page.data.metadata?.pagination.cursor.previous;
    },
  };
}

export function useGetTemplatesQuery(
  payload: GetTemplatesQueryPayload
): UseInfiniteQueryResult<TemplateListsApiResponse> {
  return useInfiniteQuery(getTemplatesQuery(payload));
}
