import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { MemberChatMessage } from '../../../shared/dora/MemberChatMessage';
import type { FormSettingsStoreType } from '../../types';

const messages = defineMessages({
  ready: {
    defaultMessage: "I'm ready!",
    id: 'XJjmWS',
  },
});

export const ConfirmAnswer = (props: {
  formSettingsStore: FormSettingsStoreType;
  memberFullName: string;
  memberId: string;
  memberImage?: string;
}) => {
  const {
    formSettingsStore: { useSettingsConfirmationSetting },
    memberFullName,
    memberId,
    memberImage,
  } = props;

  const { formatMessage } = useIntl();
  const isSeen = useSettingsConfirmationSetting.getState().isAnswerSeen;

  const markSeen = useSettingsConfirmationSetting(
    (store) => store.markAnswerSeen
  );

  useEffect(() => {
    if (!isSeen) {
      markSeen();
    }
  }, [isSeen, markSeen]);

  return (
    <MemberChatMessage
      fullName={memberFullName}
      image={memberImage}
      memberId={memberId}
      shouldAnimate={!isSeen}
    >
      {formatMessage(messages.ready)}
    </MemberChatMessage>
  );
};
