import type { ReactElement } from 'react';

import { BottomActionSheet } from '../../shared/dora/BottomActionSheet';
import type { Option } from './Option';

export const OptionsSelectorInput = (props: {
  children: ReactElement<typeof Option>[];
  isEditing?: boolean;
  onCancel?: () => void;
  shouldAnimateOnMount: boolean;
}) => {
  const {
    children: options,
    isEditing = false,
    onCancel,
    shouldAnimateOnMount,
  } = props;

  return (
    <BottomActionSheet
      isEditing={isEditing}
      onCancel={onCancel}
      shouldAnimateOnMount={shouldAnimateOnMount}
    >
      {options}
    </BottomActionSheet>
  );
};
