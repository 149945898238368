import { classNames } from '@assembly-web/ui';
import type {
  AriaAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
} from 'react';

export type TextButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  AriaAttributes & {
    underline?: boolean;
  };

export function TextButton(props: TextButtonProps) {
  const { underline, ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      className={classNames(
        'inline-flex items-center justify-center text-primary-6 enabled:hover:text-primary-5 disabled:cursor-not-allowed',
        {
          underline,
          hidden: props.hidden,
        },
        props.className
      )}
    >
      {props.children}
    </button>
  );
}
