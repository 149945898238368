import { doraAPI, DoraAPIEndpoints } from '@assembly-web/services';
import { useMutation } from '@tanstack/react-query';

type DoraFeedbackPayload = {
  feedback?: string;
  ratings?: string[];
  prompt_id: string;
  type_is: 'positive' | 'negative' | 'unset';
};

export function useDoraFeedbackMutation() {
  return useMutation(async (payload: DoraFeedbackPayload) => {
    await doraAPI.post(DoraAPIEndpoints.collectFeedback, payload);
  });
}
