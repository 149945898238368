import { PopoutDrawerTemplate } from '../PopoutDrawerTemplate';
import type { ChallengesDrawer } from '../types';
import { ChallengeDrawerBody } from './ChallengeDrawerBody';

export function ChallengesDrawer(props: ChallengesDrawer) {
  return (
    <PopoutDrawerTemplate {...props}>
      <ChallengeDrawerBody {...props} />
    </PopoutDrawerTemplate>
  );
}
