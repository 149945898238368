import type { SearchSuggestedQuestionsApiResponse } from '@assembly-web/services';
import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

import { getSuggestedQuestionsQuery } from '../queries/getSuggestedQuestionsQuery';

export function useGetSuggestedQuestionsQuery(
  searchTerm: string,
  limit?: number,
  options?: UseQueryOptions<SearchSuggestedQuestionsApiResponse>
): UseQueryResult<SearchSuggestedQuestionsApiResponse> {
  return useQuery(getSuggestedQuestionsQuery(searchTerm, limit, options));
}
