import {
  APIEndpoints,
  type Member,
  type MetadataProps,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import {
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
} from '@tanstack/react-query';

export type SearchMembersForFlowReply = {
  type: 'viewers';
  keyword: string;
  flowId: string;
};

type SearchMembersForFlowResponse = {
  total: number;
  data: (Member & {
    isFlowViewer: boolean;
  })[];
  metadata: MetadataProps;
};

function getSearchMembersForFlow(
  payload: SearchMembersForFlowReply,
  options?: UseInfiniteQueryOptions<SearchMembersForFlowResponse>
): UseInfiniteQueryOptions<SearchMembersForFlowResponse> {
  const { type, keyword, flowId } = payload;
  return {
    ...options,
    queryKey: ['searchMembersForFlow', flowId, type, keyword],
    queryFn: async ({ pageParam }) => {
      const response = await assemblyAPI.get<SearchMembersForFlowResponse>(
        APIEndpoints.searchMembersForFlow(flowId),
        {
          params: {
            type,
            flowId,
            keyword,
            ...(pageParam ? { cursor: pageParam } : {}),
          },
        }
      );

      return response.data;
    },
    ...(!keyword && {
      getNextPageParam: (page) => page.metadata.pagination.cursor.next,
      getPreviousPageParam: (page) => page.metadata.pagination.cursor.previous,
    }),
  };
}

export function useSearchMembersForFlow(
  payload: SearchMembersForFlowReply,
  options?: UseInfiniteQueryOptions<SearchMembersForFlowResponse>
) {
  const result = useInfiniteQuery(getSearchMembersForFlow(payload, options));

  const members = result.data?.pages.flatMap((page) => page.data) ?? [];

  return {
    ...result,
    members,
  };
}
