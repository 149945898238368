import type {
  CollectionsAPIResponse,
  Icon,
  LinkItem,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { defineMessages, useIntl } from 'react-intl';

import { trackDiscoverError } from '../services/analytics';
import { navItemsQueryKey } from './nav/useNavData';
import type { SearchIndexApiResponse, SearchPayload } from './useSearchIndex';
import { getSearchCacheKey } from './useSearchIndex';

const messages = defineMessages({
  createError: {
    defaultMessage: 'Failed to create collection. Please try again.',
    id: 'HvT2g6',
  },
  createSuccess: {
    defaultMessage: "Successfully created ''{collectionName}'.",
    id: 'mzTAEW',
  },
  editError: {
    defaultMessage: "Failed to edit ''{collectionName}'. Please try again.",
    id: 'Iqazk7',
  },
  editSuccess: {
    defaultMessage: "Successfully edited ''{collectionName}'.",
    id: 'pBPnJ+',
  },
});

export type CollectionPayload = {
  name: string;
  color?: string;
  description?: string;
  icon?: Icon;
  collectionId?: string; // required for editing
  item?: {
    id?: string;
    type: string;
    link?: LinkItem;
    responseId?: string;
  };
  isPinned?: boolean;
};

export function useCollectionsMutation() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { showErrorToast, showSuccessToast } = useToastStore();

  return useMutation({
    mutationFn: async ({
      payload,
    }: {
      payload: CollectionPayload;
      query?: SearchPayload;
    }) => {
      return assemblyAPI.post(APIEndpoints.updateCollection, payload);
    },
    onSuccess: (data, { payload, query }) => {
      const queryKey = ['collections'];
      const previousCollections =
        queryClient.getQueryData<CollectionsAPIResponse>(queryKey);

      let updatedCollections;
      if (payload.collectionId) {
        // editing existing collection
        updatedCollections = produce(previousCollections, (draft) => {
          if (draft) {
            draft.data = draft.data.map((collection) => {
              if (collection.collectionId === payload.collectionId) {
                return {
                  ...collection,
                  ...payload,
                };
              }
              return collection;
            });
          }
        });

        if (query?.searchTerm) {
          const searchCacheKey = getSearchCacheKey(query);
          const previousCollections =
            queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
              searchCacheKey
            );
          if (payload.collectionId) {
            const updatedSearchCollections = produce(
              previousCollections,
              (draft) => {
                draft?.pages.forEach((page) => {
                  page.data.data = page.data.data.map((card) => {
                    if (
                      card.type === 'collection' &&
                      card.id === payload.collectionId
                    ) {
                      card._meta.details = {
                        ...card._meta.details,
                        ...payload,
                        icon: payload.icon?.value ?? card._meta.details.icon,
                      };
                    }
                    return card;
                  });
                });
              }
            );
            queryClient.setQueryData(searchCacheKey, updatedSearchCollections);
          }
        }

        showSuccessToast(
          formatMessage(messages.editSuccess, {
            collectionName: payload.name,
          })
        );
      } else {
        queryClient.refetchQueries(queryKey);
        // create new collection
        showSuccessToast(
          formatMessage(messages.createSuccess, {
            collectionName: payload.name,
          })
        );
      }

      queryClient.setQueryData(queryKey, updatedCollections);
      queryClient.invalidateQueries(navItemsQueryKey);
    },
    onError: (err: unknown, { payload }) => {
      const errorInfo = err instanceof Error ? err : undefined;

      logger.error(
        'Error on post to /collection',
        { ...payload, error: errorInfo },
        errorInfo
      );

      trackDiscoverError({
        action: payload.collectionId ? 'editCollection' : 'createCollection',
      });

      showErrorToast(
        formatMessage(
          payload.collectionId ? messages.editError : messages.createError,
          {
            collectionName: payload.name,
          }
        )
      );
    },
  });
}
