import {
  type SecondaryFiltersType,
  useDebounce,
  useUserDetails,
} from '@assembly-web/services';
import {
  GlobalFilterOption,
  type SelectableOptionProps,
} from '@assembly-web/ui';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getIncludedEntityIds } from '../../services/secondaryFilters';
import { useGetSecondaryFilterOptionsQuery } from '../useGetSecondaryFilterOptionsQuery';
import { useGlobalFilter } from '../useGlobalFilter';
import { useSecondaryFilterState } from './useSecondaryFilterState';

export function useGetPeopleSecondaryFilters({
  secondaryFilterTypes,
}: {
  secondaryFilterTypes: Record<string, SecondaryFiltersType>;
}) {
  const { data: userDetails } = useUserDetails({ suspense: false });

  const currentUserId = userDetails?.member.memberId ?? '';
  const [params] = useSearchParams();

  const {
    searchValues: { reportsToSearchValue },
  } = useSecondaryFilterState();

  // Department Filter
  const isPeopleTabSelected = useGlobalFilter() === GlobalFilterOption.People;
  const selectedDepartments = params.get('department')?.split(',') ?? [];

  const {
    isLoading: isDepartmentDataLoading,
    hasNextPage: hasMoreDepartments,
    fetchNextPage: fetchMoreDepartments,
    data: departmentsData,
    isFetchingNextPage: isFetchingMoreDepartments,
  } = useGetSecondaryFilterOptionsQuery({
    topLevelFilter: 'MEMBER',
    secondaryFilter: {
      filter: secondaryFilterTypes['department'],
      includeEntityIds: [],
    },
    enabled:
      Object.keys(secondaryFilterTypes).includes('department') &&
      isPeopleTabSelected,
    searchTerm: '',
  });

  const departmentOptions: SelectableOptionProps[] = useMemo(() => {
    if (!departmentsData) return [];
    const options = departmentsData.pages.reduce<SelectableOptionProps[]>(
      (acc, page) => {
        return [
          ...acc,
          ...page.DEPARTMENT.map((item) => {
            return {
              id: item.key.replace(/,/g, ''),
              value: item.key,
            };
          }),
        ];
      },
      []
    );

    return options.filter((item) => item.id !== '');
  }, [departmentsData]);

  const departmentSelectedOptions = departmentOptions.filter((item) =>
    selectedDepartments.includes(item.id)
  );

  // Job Title Filter
  const selectedJobTitle = params.get('jobTitle')?.split(',') ?? [];

  const {
    isLoading: isJobTitleDataLoading,
    hasNextPage: hasMoreJobTitles,
    fetchNextPage: fetchMoreJobTitles,
    data: jobTitleData,
    isFetchingNextPage: isFetchingMoreJobTitles,
  } = useGetSecondaryFilterOptionsQuery({
    topLevelFilter: 'MEMBER',
    secondaryFilter: {
      filter: secondaryFilterTypes['jobTitle'],
      includeEntityIds: [],
    },
    enabled:
      Object.keys(secondaryFilterTypes).includes('jobTitle') &&
      isPeopleTabSelected,
    searchTerm: '',
  });

  const jobTitleOptions: SelectableOptionProps[] = useMemo(() => {
    if (!jobTitleData) return [];
    const options = jobTitleData.pages.reduce<SelectableOptionProps[]>(
      (acc, page) => {
        return [
          ...acc,
          ...page.JOB_TITLE.map((item) => {
            return {
              id: item.key.replace(/,/g, ''),
              value: item.key,
            };
          }),
        ];
      },
      []
    );

    return options.filter((item) => item.id !== '');
  }, [jobTitleData]);

  const jobTitleSelectedOptions = jobTitleOptions.filter((item) =>
    selectedJobTitle.includes(item.id)
  );

  // Work Location Filter
  const selectedWorkLocations = params.get('workLocation')?.split(',') ?? [];

  const {
    isLoading: isWorkLocationDataLoading,
    hasNextPage: hasMoreWorkLocations,
    fetchNextPage: fetchMoreWorkLocations,
    data: workLocationData,
    isFetchingNextPage: isFetchingMoreWorkLocations,
  } = useGetSecondaryFilterOptionsQuery({
    topLevelFilter: 'MEMBER',
    secondaryFilter: {
      filter: secondaryFilterTypes['workLocation'],
      includeEntityIds: [],
    },
    enabled:
      Object.keys(secondaryFilterTypes).includes('workLocation') &&
      isPeopleTabSelected,
    searchTerm: '',
  });

  const workLocationOptions: SelectableOptionProps[] = useMemo(() => {
    if (!workLocationData) return [];
    const options = workLocationData.pages.reduce<SelectableOptionProps[]>(
      (acc, page) => {
        return [
          ...acc,
          ...page.WORK_LOCATION.map((item) => {
            return {
              id: item.key.replace(/,/g, ''),
              value: item.key,
            };
          }),
        ];
      },
      []
    );

    return options.filter((item) => item.id !== '');
  }, [workLocationData]);

  const workLocationSelectedOptions = workLocationOptions.filter((item) =>
    selectedWorkLocations.includes(item.id)
  );

  // Home Location Filter
  const selectedHomeLocations = params.get('homeLocation')?.split(',') ?? [];

  const {
    isLoading: isHomeLocationDataLoading,
    hasNextPage: hasMoreHomeLocations,
    fetchNextPage: fetchMoreHomeLocations,
    data: homeLocationData,
    isFetchingNextPage: isFetchingMoreHomeLocations,
  } = useGetSecondaryFilterOptionsQuery({
    topLevelFilter: 'MEMBER',
    secondaryFilter: {
      filter: secondaryFilterTypes['homeLocation'],
      includeEntityIds: [],
    },
    enabled:
      Object.keys(secondaryFilterTypes).includes('homeLocation') &&
      isPeopleTabSelected,
    searchTerm: '',
  });

  const homeLocationOptions: SelectableOptionProps[] = useMemo(() => {
    if (!homeLocationData) return [];
    const options = homeLocationData.pages.reduce<SelectableOptionProps[]>(
      (acc, page) => {
        return [
          ...acc,
          ...page.HOME_LOCATION.map((item) => {
            return {
              id: item.key.replace(/,/g, ''),
              value: item.key,
            };
          }),
        ];
      },
      []
    );

    return options.filter((item) => item.id !== '');
  }, [homeLocationData]);

  const homeLocationSelectedOptions = homeLocationOptions.filter((item) =>
    selectedHomeLocations.includes(item.id)
  );

  //Reports to Filter
  const selectedReportsTo = params.get('reportsTo')?.split(',') ?? [];
  const debouncedReportsToSearchValue = useDebounce(reportsToSearchValue, 500);
  const {
    isLoading: isReportsToDataLoading,
    hasNextPage: hasMoreReportsToData,
    fetchNextPage: fetchMoreReportsToData,
    data: reportsToData,
    isFetchingNextPage: isFetchingMoreReportsToData,
  } = useGetSecondaryFilterOptionsQuery({
    topLevelFilter: 'MEMBER',
    secondaryFilter: {
      filter: secondaryFilterTypes['reportsTo'],
      includeEntityIds: getIncludedEntityIds({
        selectedItems: selectedReportsTo,
        currentUserId,
      }),
    },
    enabled:
      Object.keys(secondaryFilterTypes).includes('reportsTo') &&
      isPeopleTabSelected,
    searchTerm: reportsToSearchValue ? debouncedReportsToSearchValue : '',
  });

  const reportsToOptions: SelectableOptionProps[] = useMemo(() => {
    if (!reportsToData) return [];
    return reportsToData.pages.reduce<SelectableOptionProps[]>((acc, page) => {
      return [
        ...acc,
        ...page.MANAGER.data.map((item) => {
          return {
            id: item.type === 'member' ? item._meta.entityId : '',
            value:
              item.type === 'member'
                ? item._meta.entityId === currentUserId
                  ? `${item._meta.fullName} (You)`
                  : item._meta.fullName
                : '',
            imageUrl: item.type === 'member' ? item._meta.profileImageUrl : '',
            state: item.type === 'member' ? item.state : 'ACTIVE',
          };
        }),
      ];
    }, []);
  }, [currentUserId, reportsToData]);

  const reportsToSelectedOptions = reportsToOptions.filter((item) =>
    selectedReportsTo.includes(item.id)
  );

  const departmentProps = {
    isDepartmentDataLoading,
    hasMoreDepartments,
    fetchMoreDepartments,
    isFetchingMoreDepartments,
    departmentOptions,
    departmentSelectedOptions,
    hasSelectedDepartments: selectedDepartments.length > 0,
  };

  const jobTitleProps = {
    isJobTitleDataLoading,
    hasMoreJobTitles,
    fetchMoreJobTitles,
    isFetchingMoreJobTitles,
    jobTitleOptions,
    jobTitleSelectedOptions,
    hasSelectedJobTitles: selectedJobTitle.length > 0,
  };

  const workLocationProps = {
    isWorkLocationDataLoading,
    hasMoreWorkLocations,
    fetchMoreWorkLocations,
    isFetchingMoreWorkLocations,
    workLocationOptions,
    workLocationSelectedOptions,
    hasSelectedWorkLocations: selectedWorkLocations.length > 0,
  };

  const homeLocationProps = {
    isHomeLocationDataLoading,
    hasMoreHomeLocations,
    fetchMoreHomeLocations,
    isFetchingMoreHomeLocations,
    homeLocationOptions,
    homeLocationSelectedOptions,
    hasSelectedHomeLocations: selectedHomeLocations.length > 0,
  };

  const reportsToProps = {
    isReportsToDataLoading,
    hasMoreReportsToData,
    fetchMoreReportsToData,
    isFetchingMoreReportsToData,
    reportsToOptions,
    reportsToSelectedOptions,
    hasSelectedReportsTo: selectedReportsTo.length > 0,
  };

  return {
    jobTitleProps,
    workLocationProps,
    homeLocationProps,
    reportsToProps,
    departmentProps,
  };
}
