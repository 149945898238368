import { config } from '@assembly-web/services';
import { Banner, TextStyle, Tooltip } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useChatReportHealth } from '../../../hooks/dora/useChatReportHealth';
import { trackDoraAction } from '../../../services/analytics';

const messages = defineMessages({
  missingData: {
    defaultMessage: 'Note: missing some employee data',
    id: 'pOxcUs',
  },
  intro1: {
    defaultMessage:
      'Your Assembly workspace is missing the employee profile information below. I can still provide valuable insights, and you can ask me directly who is missing data. ',
    id: 'z+t7kT',
  },
  intro2: {
    defaultMessage:
      'For the best experience, <a>update your Assembly employee profile information</a>.',
    id: '7RX3RA',
  },
  totalUsersCount: {
    defaultMessage: 'Total Users Counted: {count}',
    id: 'z6QlPb',
  },
  missingDepartment: {
    defaultMessage: 'Missing Department: {count} users lack department data.',
    id: 'WSQJDi',
  },
  missingLocation: {
    defaultMessage: 'Missing Location: {count} users lack location data.',
    id: 'X9irlV',
  },
  missingHireDate: {
    defaultMessage: 'Missing Hire Date: {count} users lack hire date data.',
    id: 'ATxrbl',
  },
  missingBirthday: {
    defaultMessage: 'Missing Birthday: {count} users lack birthday data.',
    id: '3XCAeV',
  },
  missingJobTitle: {
    defaultMessage: 'Missing Job title: {count} users lack job title data.',
    id: '1cyLXj',
  },
  managersWithNoReports: {
    defaultMessage:
      'Managers With No Reports: {count} managers do not have any reports listed.',
    id: 'IZ3a+n',
  },
  dismissBanner: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export function ChatReportHealthDisclaimer({
  isAdmin,
  onDismiss,
}: {
  isAdmin: boolean;
  onDismiss: () => void;
}) {
  const { data: reportHealth } = useChatReportHealth({ enabled: isAdmin });

  const { formatMessage } = useIntl();

  if (!isAdmin || !reportHealth?.hasMissingData) {
    return null;
  }

  return (
    <Banner
      status="alert"
      className="mb-3 w-full rounded py-1"
      dismissText={formatMessage(messages.dismissBanner)}
      onDismiss={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onDismiss();
      }}
    >
      <Tooltip
        align="start"
        contentClassName="max-w-lg -ml-14"
        tooltipText={
          <div className="pointer-events-auto space-y-4">
            <p>{formatMessage(messages.intro1)}</p>
            <p>
              {formatMessage(messages.intro2, {
                a: (text: ReactNode) => (
                  <a
                    href={`${config.domains.adminApp}/users/manage`}
                    target="_blank"
                    className="cursor-pointer font-bold underline"
                    rel="noreferrer"
                    onClick={() =>
                      trackDoraAction('missingDataAdminLinkClicked')
                    }
                  >
                    {text}
                  </a>
                ),
              })}
            </p>
            <div>
              <p className="font-bold">
                {formatMessage(messages.totalUsersCount, {
                  count: reportHealth.userCount,
                })}
              </p>
              {reportHealth.missingDepartment !== 0 && (
                <p>
                  {formatMessage(messages.missingDepartment, {
                    count: reportHealth.missingDepartment,
                  })}
                </p>
              )}
              {reportHealth.missingLocation !== 0 && (
                <p>
                  {formatMessage(messages.missingLocation, {
                    count: reportHealth.missingLocation,
                  })}
                </p>
              )}
              {reportHealth.missingHireDate !== 0 && (
                <p>
                  {formatMessage(messages.missingHireDate, {
                    count: reportHealth.missingHireDate,
                  })}
                </p>
              )}
              {reportHealth.missingBirthday !== 0 && (
                <p>
                  {formatMessage(messages.missingBirthday, {
                    count: reportHealth.missingBirthday,
                  })}
                </p>
              )}
              {reportHealth.missingJobTitle !== 0 && (
                <p>
                  {formatMessage(messages.missingJobTitle, {
                    count: reportHealth.missingJobTitle,
                  })}
                </p>
              )}
              {reportHealth.managersWithNoReports !== 0 && (
                <p>
                  {formatMessage(messages.managersWithNoReports, {
                    count: reportHealth.managersWithNoReports,
                  })}
                </p>
              )}
            </div>
          </div>
        }
      >
        <TextStyle variant="xs-regular" className="cursor-pointer">
          {formatMessage(messages.missingData)}
        </TextStyle>
      </Tooltip>
    </Banner>
  );
}
