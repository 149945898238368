import type {
  AnnouncementsApiResponse,
  EntityPermissionApiResponse,
  FlowPostResponse,
  UserFeedApiResponse,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import type { SearchIndexApiResponse } from './useSearchIndex';

type EndAnnouncementPayload = {
  flowId: string;
  responseId: string;
  announcementId: string;
};

export function useEndAnnouncementMutation(onError?: (error: unknown) => void) {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload: EndAnnouncementPayload) => {
      await assemblyAPI.put(
        APIEndpoints.endAnnouncement(payload.announcementId),
        {}
      );
    },
    {
      onMutate: async (payload) => {
        const { flowId, responseId } = payload;

        const announcementsQueryKey = ['announcements'];
        const previousAnnouncements = queryClient.getQueryData<
          InfiniteData<AnnouncementsApiResponse>
        >(announcementsQueryKey);

        const updatedAnnouncements = produce(previousAnnouncements, (draft) => {
          draft?.pages.forEach((page) => {
            page.data.data = page.data.data.filter((card) => {
              return card.announcementId !== payload.announcementId;
            });
          });
        });

        queryClient.setQueryData(announcementsQueryKey, updatedAnnouncements);

        const queryCache = queryClient.getQueryCache();

        const assemblyPostQuery = ['assemblyFlowPost', flowId, responseId];

        const previousAssemblyPost =
          queryClient.getQueryData<FlowPostResponse>(assemblyPostQuery);

        const updatedPost = produce(previousAssemblyPost, (draft) => {
          if (draft?.responseId === responseId) {
            draft.activeAnnouncement = undefined;
          }
        });

        queryClient.setQueryData(assemblyPostQuery, updatedPost);

        queryCache.getAll().map((query) => {
          const queryKey = query.queryKey;
          if (Array.isArray(queryKey) && queryKey[0] === 'userFeed') {
            const userFeedCacheKey = queryKey;
            const previousUserFeed =
              queryClient.getQueryData<InfiniteData<UserFeedApiResponse>>(
                userFeedCacheKey
              );

            const updatedCards = produce(previousUserFeed, (draft) => {
              draft?.pages.forEach((page) => {
                page.data = page.data.map((card) => {
                  if (card.type === 'response') {
                    if (card.id === payload.responseId && card.cardDetails) {
                      card.cardDetails.activeAnnouncement = undefined;
                    }
                  }
                  return card;
                });
              });
            });

            queryClient.setQueryData(userFeedCacheKey, updatedCards);
          }

          if (Array.isArray(queryKey) && queryKey[0] === 'searchResults') {
            const searchResultsCacheKey = queryKey;
            const previousSearchResults = queryClient.getQueryData<
              InfiniteData<SearchIndexApiResponse>
            >(searchResultsCacheKey);

            const updatedCards = produce(previousSearchResults, (draft) => {
              draft?.pages.forEach((page) => {
                page.data.data = page.data.data.map((card) => {
                  if (card.type === 'response') {
                    if (card.id === payload.responseId && card.cardDetails) {
                      card.cardDetails.activeAnnouncement = undefined;
                    }
                  }
                  return card;
                });
              });
            });

            queryClient.setQueryData(searchResultsCacheKey, updatedCards);
          }

          if (Array.isArray(queryKey) && queryKey[0] === 'entityPermission') {
            const permissionsCacheKey = queryKey;

            const permissionResults =
              queryClient.getQueryData<EntityPermissionApiResponse>(
                permissionsCacheKey
              );

            const updatedCards = produce(permissionResults, (draft) => {
              draft?.data.forEach((card) => {
                if (card.entityId === payload.responseId) {
                  card.canEndAnnouncement = false;
                  card.canEditAnnouncement = false;
                  card.canShareAsAnnouncement = true;
                }
              });
            });

            queryClient.setQueryData(permissionsCacheKey, updatedCards);
          }
        });
      },
      onError,
    }
  );
}
