import { workspacesIllustrationImage } from '@assembly-web/assets';
import type { ListWorkspacesAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  config,
  getAPIErrorCode,
  MobileJWTTokenQueryParamKey,
  MobileRefreshTokenQueryParam,
  SplitNames,
  useFeatureSplit,
  userAuthStore,
} from '@assembly-web/services';
import {
  Button,
  Heading,
  HorizontalRule,
  TextStyle,
  validateForm,
} from '@assembly-web/ui';
import isEmpty from 'lodash/isEmpty';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom';
import { redirect, useLoaderData, useSubmit } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';

import { TextButton } from '../../../components/TextButton';
import {
  registerUserPropsForTracking,
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../services/analytics';
import { waitForRedirection } from '../../../services/waitForRedirection';
import type { ReactRouterLoaderResponse } from '../../../types/libs';
import { CreateWorkspaceCard } from '../components/workspaces/CreateWorkspaceCard';
import type { WorkspaceCardAction } from '../components/workspaces/WorkspaceCard';
import { workspacesPageActions } from '../components/workspaces/WorkspaceCard';
import { WorkspacesSection } from '../components/workspaces/WorkspacesSection';
import { getSSODetailsFromError } from '../services/sso';
import type {
  JoinWorkspaceAPIResponse,
  LaunchWorkspaceAPIResponse,
  WorkspaceActionPayload,
} from '../types/workspaces';

const messages = defineMessages({
  header: {
    defaultMessage: 'We found a workspace for you!',
    id: '7/1ZDt',
  },
  workspaceDescription: {
    defaultMessage:
      'Join an existing workspace to connect with your team and stay engaged. Showing workspaces for {email}.',
    id: 'oKVSc7',
  },
  description: {
    defaultMessage:
      'Showing workspaces for {email}. Go on in, or start from scratch and create your own.',
    id: 'Ooeuxf',
  },
  invitedWorkspacesHeader: {
    defaultMessage: 'Workspaces you’ve been invited to',
    id: 'h4jN6H',
  },
  memberWorkspacesHeader: {
    defaultMessage: 'Workspaces you’re a part of',
    id: 'GHXs/R',
  },
  joinableWorkspacesHeader: {
    defaultMessage: 'Workspaces you can join',
    id: 'LF6Qyt',
  },
  createNewWorkspaceHeader: {
    defaultMessage: 'Create a new workspace',
    id: 'DNFOtk',
  },
  logoutCTA: {
    defaultMessage:
      'Not seeing your workspace? <cta>Log in with a different email</cta>',
    id: 'ONzVkq',
  },
  hasNoWorkspacesHeader: {
    defaultMessage: 'No workspaces associated with your email',
    id: 'zVmXXp',
  },
  hasNoWorkspacesDescription: {
    defaultMessage:
      '{email} is not part of any existing workspaces. Try a different email, or create a new workspace from scratch.',
    id: 'h+jpRb',
  },
  hasNoWorkspacesCreationDisabledDescription: {
    defaultMessage:
      '{email} is not part of any existing workspaces. Check with your Assembly admin to ensure you have been invited to your company’s Assembly account. If you have more than one email, try logging in with a different email.',
    id: 'OsJAG3',
  },
  hasNoWorkspacesLogoutCTA: {
    defaultMessage: 'Log in with a different email',
    id: 'FbOkvr',
  },
  horizontalSeparator: {
    defaultMessage: 'OR',
    id: 'INlWvJ',
  },
  goBackCTA: { defaultMessage: 'Go back', id: 'orvpWh' },
  workspacePageTitle: {
    defaultMessage: 'My Workspaces',
    id: '9BWtaE',
  },
});

export function WorkspacesRoute() {
  const { formatMessage } = useIntl();
  const submit = useSubmit();

  const { isTreatmentActive: allowAssemblyCreationTreatment } = useFeatureSplit(
    SplitNames.AllowAssemblyCreation
  );

  const handleWorkspaceCardClick = useCallback(
    function handleWorkspaceCardClick(payload: WorkspaceActionPayload) {
      submit(payload, { method: 'post' });
    },
    [submit]
  );

  const loaderData = useLoaderData() as ReactRouterLoaderResponse<
    typeof workspacesLoader
  >;

  const { email, allowList, invited, partOf, hasNoWorkspaces, canCreate } =
    loaderData;

  const logout = useCallback(
    function logout() {
      trackRegistrationAction('logInWithDifferentEmailClicked', {
        email,
      });
      submit({ shouldSignOut: 'true' }, { method: 'post' });
    },
    [email, submit]
  );

  return (
    <>
      <Helmet>
        <title>{formatMessage(messages.workspacePageTitle)}</title>
      </Helmet>
      <section className="container mx-auto max-w-[40rem] px-1 pb-10">
        <div className="flex items-start gap-6">
          <img
            src={workspacesIllustrationImage}
            className="hidden w-[85px] md:block"
            alt=""
          />
          <section className={hasNoWorkspaces ? 'mb-4' : 'mb-8'}>
            <Heading as="h4">
              {formatMessage(
                hasNoWorkspaces
                  ? messages.hasNoWorkspacesHeader
                  : messages.header
              )}
            </Heading>

            <TextStyle className="mt-2 text-gray-8">
              {formatMessage(
                hasNoWorkspaces
                  ? allowAssemblyCreationTreatment
                    ? messages.hasNoWorkspacesDescription
                    : messages.hasNoWorkspacesCreationDisabledDescription
                  : messages.workspaceDescription,
                {
                  email,
                  b: (text: ReactNode) => (
                    <TextStyle variant="base-medium" as="span">
                      {text}
                    </TextStyle>
                  ),
                }
              )}
            </TextStyle>
            {Boolean(hasNoWorkspaces) && (
              <Button
                variation="secondaryLite"
                className="mt-2"
                onClick={() => logout()}
              >
                {formatMessage(messages.hasNoWorkspacesLogoutCTA)}
              </Button>
            )}
          </section>
        </div>

        {!hasNoWorkspaces && (
          <>
            <section className="space-y-6">
              {!isEmpty(partOf) && (
                <WorkspacesSection
                  workspaces={partOf}
                  heading={formatMessage(messages.memberWorkspacesHeader)}
                  action="launch"
                  handleWorkspaceCardClick={handleWorkspaceCardClick}
                />
              )}
              {!isEmpty(invited) && (
                <WorkspacesSection
                  workspaces={invited}
                  heading={formatMessage(messages.invitedWorkspacesHeader)}
                  action="accept"
                  handleWorkspaceCardClick={handleWorkspaceCardClick}
                />
              )}
              {!isEmpty(allowList) && (
                <WorkspacesSection
                  workspaces={allowList}
                  heading={formatMessage(messages.joinableWorkspacesHeader)}
                  action="join"
                  handleWorkspaceCardClick={handleWorkspaceCardClick}
                />
              )}
            </section>
            <section className="mt-2 text-center md:text-left">
              {formatMessage(messages.logoutCTA, {
                cta: (text: ReactNode) => (
                  <TextButton onClick={() => logout()}> {text} </TextButton>
                ),
              })}
            </section>
          </>
        )}

        {Boolean(hasNoWorkspaces) && allowAssemblyCreationTreatment ? (
          <HorizontalRule>
            {formatMessage(messages.horizontalSeparator)}
          </HorizontalRule>
        ) : null}
        {Boolean(canCreate) && Boolean(allowAssemblyCreationTreatment) && (
          <section
            className={twMerge(
              'mt-6',
              hasNoWorkspaces ? 'space-y-4' : 'space-y-2'
            )}
          >
            <Heading as={hasNoWorkspaces ? 'h5' : 'h6'}>
              {formatMessage(messages.createNewWorkspaceHeader)}
            </Heading>

            <CreateWorkspaceCard
              email={email}
              hasNoWorkspaces={hasNoWorkspaces}
            />
          </section>
        )}
        <section className="mt-6 space-y-2 text-center">
          {Boolean(hasNoWorkspaces) && (
            <TextButton onClick={() => logout()} className="!text-gray-9">
              {formatMessage(messages.goBackCTA)}
            </TextButton>
          )}
        </section>
      </section>
    </>
  );
}

export async function workspacesLoader({
  request: { signal },
}: LoaderFunctionArgs) {
  try {
    const {
      data: { allowList, invited, partOf, email, canCreate },
    } = await assemblyAPI.post<ListWorkspacesAPIResponse>(
      APIEndpoints.listWorkspaces,
      null,
      { signal }
    );

    registerUserPropsForTracking({
      numWorkspacesActive: partOf.length,
    });

    const allWorkspaces = [...allowList, ...invited, ...partOf];
    const hasNoWorkspaces = allWorkspaces.length === 0;
    const { userAuthFlow } = userAuthStore.getState();

    if (hasNoWorkspaces && userAuthFlow === 'create-account') {
      return redirect('/onboarding/owner-details');
    }

    if (
      allowList.length === 0 &&
      invited.length === 0 &&
      partOf.length === 1 &&
      userAuthFlow !== 'create-account'
    ) {
      const isMobileApp = userAuthStore.getState().isLoginViaMobileApp;
      try {
        const { assemblyId } = partOf[0];

        const {
          data: { jwtToken, refreshToken },
        } = await assemblyAPI.post<{
          jwtToken: string;
          refreshToken?: string;
        }>(APIEndpoints.loginToWorkspace, { assemblyId }, { signal });

        userAuthStore.persist.clearStorage();
        userAuthStore.getState().setJwtToken(jwtToken);

        if (refreshToken) {
          userAuthStore.getState().setRefreshToken(refreshToken);
        }

        if (!isMobileApp) {
          return redirect('/a/discover');
        }
      } catch (error) {
        const enforcedLoginDetails = getSSODetailsFromError(error);

        if (enforcedLoginDetails) {
          return redirect(
            `/login/${enforcedLoginDetails.workspaceSlug}/${enforcedLoginDetails.provider}`
          );
        }
      }

      const jwtToken = userAuthStore.getState().jwtToken;
      const refreshToken = userAuthStore.getState().refreshToken;

      window.location.href =
        isMobileApp && jwtToken
          ? `${config.domains.mobileApp}home?${new URLSearchParams([
              [MobileJWTTokenQueryParamKey, jwtToken],
              ...(refreshToken
                ? [[MobileRefreshTokenQueryParam, refreshToken]]
                : []),
            ])}`
          : `${config.domains.legacyApp}/home${
              !isEmpty(userAuthStore.getState().msTeamsContext)
                ? '?fromMSTeams=true'
                : ''
            }`;

      await waitForRedirection();
    }

    return {
      email,
      canCreate,
      hasNoWorkspaces,
      allowList,
      invited,
      partOf,
    };
  } catch (error) {
    const errorCode = getAPIErrorCode(error);

    let redirectURL = '/login';
    if (userAuthStore.getState().msTeamsContext) {
      redirectURL = '/ms-teams/login';
    }

    if (errorCode === 'missing_required_parameters') {
      return redirect(redirectURL);
    }

    return redirect(`${redirectURL}?error=${errorCode}`);
  }
}

export async function workspacesAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  if (formData.get('shouldSignOut')) {
    try {
      await assemblyAPI.post(APIEndpoints.logout, { clearAllCookies: true });
    } catch {
      // this should always work!
    }
    return null;
  }

  const schema = z.object({
    assemblyId: z.string(),
    workspaceSlug: z.string().optional(),
    action: z.enum(workspacesPageActions),
    inviteToken: z.string().optional(),
  });

  try {
    const { assemblyId, action, workspaceSlug, inviteToken } = validateForm(
      schema,
      formData
    );

    let apiError;
    let isFirstLogin;

    switch (action as WorkspaceCardAction) {
      case 'accept': {
        trackRegistrationAction('workspaceClicked', {
          workspaceStatus: 'invited',
          assemblyId,
          ssoEnforced: userAuthStore.getState().isEnforcedAuth,
        });
        return redirect(`/join/${workspaceSlug}/account-invite/${inviteToken}`);
      }

      case 'launch':
        {
          try {
            const {
              data: { user, jwtToken, refreshToken },
            } = await assemblyAPI.post<LaunchWorkspaceAPIResponse>(
              APIEndpoints.loginToWorkspace,
              { assemblyId }
            );

            isFirstLogin = user.isFirstLogin;
            userAuthStore.getState().setJwtToken(jwtToken);
            userAuthStore.getState().setLastVisitedWorkspace(assemblyId);

            if (refreshToken) {
              userAuthStore.getState().setRefreshToken(refreshToken);
            }

            trackRegistrationAction('workspaceClicked', {
              workspaceStatus: 'existing',
              assemblyId,
              ssoEnforced: userAuthStore.getState().isEnforcedAuth,
            });
          } catch (error) {
            trackRegistrationError('workspaceClicked', {
              workspaceStatus: 'existing',
              assemblyId,
              ssoEnforced: userAuthStore.getState().isEnforcedAuth,
            });
            apiError = error;
          }
        }
        break;

      case 'join':
        {
          try {
            trackRegistrationAction('workspaceClicked', {
              workspaceStatus: 'whitelisted',
              assemblyId,
              ssoEnforced: userAuthStore.getState().isEnforcedAuth,
            });
            const {
              data: { user, jwtToken, refreshToken },
            } = await assemblyAPI.post<JoinWorkspaceAPIResponse>(
              APIEndpoints.joinWorkspace,
              { assemblyId }
            );
            isFirstLogin = user.isFirstLogin;
            userAuthStore.getState().setJwtToken(jwtToken);
            if (refreshToken) {
              userAuthStore.getState().setRefreshToken(refreshToken);
            }
          } catch (error) {
            trackRegistrationError('workspaceClicked', {
              workspaceStatus: 'whitelisted',
              assemblyId,
              ssoEnforced: userAuthStore.getState().isEnforcedAuth,
            });
            apiError = error;
          }
        }
        break;

      default:
        break;
    }

    if (apiError) {
      const enforcedLoginDetails = getSSODetailsFromError(apiError);

      if (enforcedLoginDetails) {
        if (action === 'join') {
          userAuthStore.getState().updateUserAuthFlow('create-account');
        }
        return redirect(
          `/login/${enforcedLoginDetails.workspaceSlug}/${enforcedLoginDetails.provider}`
        );
      }
    }

    if (isFirstLogin) {
      return redirect(`/join/${workspaceSlug}/user-details`);
    } else {
      const jwtToken = userAuthStore.getState().jwtToken;
      const refreshToken = userAuthStore.getState().refreshToken;
      const isMobileApp = userAuthStore.getState().isLoginViaMobileApp;

      if (isMobileApp && jwtToken) {
        const searchParams = new URLSearchParams([
          [MobileJWTTokenQueryParamKey, jwtToken],
        ]);

        if (refreshToken) {
          searchParams.append(MobileRefreshTokenQueryParam, refreshToken);
        }

        window.location.href = `${config.domains.mobileApp}home?${searchParams}`;
        await waitForRedirection();
        return null;
      } else {
        return redirect(`/a/discover`);
      }
    }
  } catch (error) {
    return getAPIErrorCode(error);
  }
}
