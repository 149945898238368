import { Button, Modal, TextStyle } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { createContext, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const messages = defineMessages({
  confirm: {
    defaultMessage: 'OK, I understand!',
    id: 'JaGuqq',
  },
  title: {
    defaultMessage: 'Searching Assembly for files in GDrive?',
    id: 'Qxu/RF',
  },
  workaround: {
    defaultMessage:
      'Make sure the files you want to find in Assembly are set to "Anyone in my company can view" or are shared directly with your email address.',
    id: 'U5RqLe',
  },
});

export const GoogleLimitationModalContext = createContext({
  openModal: () => {},
});

export const GoogleLimitationModalProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  return (
    <GoogleLimitationModalContext.Provider value={{ openModal }}>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        title={formatMessage(messages.title)}
      >
        <section>
          <TextStyle className="mb-4">
            {formatMessage(messages.workaround)}
          </TextStyle>
          <TextStyle>
            <FormattedMessage
              defaultMessage='Temporarily, <strong>files shared to an email distribution group, or Google Group ("managers@company.com"), are not available for search in Assembly</strong> unless you are the owner of that file.'
              id="IqEhgJ"
              values={{
                strong: (text) => (
                  <TextStyle as="span" variant="base-bold">
                    {text}
                  </TextStyle>
                ),
              }}
            />
          </TextStyle>
        </section>
        <Button isFullWidth className="mt-6" onClick={closeModal}>
          {formatMessage(messages.confirm)}
        </Button>
      </Modal>
      {children}
    </GoogleLimitationModalContext.Provider>
  );
};
