import type { ReactNode } from 'react';

import { DoraAvatar } from './DoraAvatar';

export function DoraStackedMessages(props: {
  children: ReactNode;
  shouldAnimateAvatar: boolean;
}) {
  const { children, shouldAnimateAvatar } = props;

  return (
    <>
      <DoraAvatar shouldAnimate={shouldAnimateAvatar} />
      <div className="col-start-2 flex flex-col items-start gap-y-1 self-start antialiased">
        {children}
      </div>
    </>
  );
}
