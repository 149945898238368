import { useNavigate } from 'react-router-dom';

import {
  generatePostDrawerPayload,
  removeFlowOrPostParamsFromUrl,
} from '../modules/discover/services/common';
import { useMultiDrawerStore } from '../stores/useMultiDrawerStore';
import type { PostData } from '../types/postsAndReplies';

export function useReplies() {
  const navigate = useNavigate();
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const handleReplyClicked = (replyData: PostData) => {
    const createDrawerPayload = generatePostDrawerPayload({
      flowId: replyData.type === 'flow' ? replyData.flowId : null,
      postId: replyData.type === 'post' ? replyData.postId : null,
      responseId: replyData.type === 'flow' ? replyData.responseId : null,
      commentId: replyData.commentId ?? null,
      editSelectedCommentId: replyData.editSelectedCommentId,
      onClose: async () => {
        if (location.pathname.includes('/discover')) {
          const updatedUrl = removeFlowOrPostParamsFromUrl();
          await navigate(updatedUrl, { replace: true });
        }
      },
    });
    createDrawer(createDrawerPayload);
  };

  return { onReplyClicked: handleReplyClicked };
}
